import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DownloadIcon from '../../../Sidebar/icons/DownloadIcon.png'
import _ from 'lodash';
import Modal from 'react-modal';
import './QuotJobDetails.scss'
import axios from 'axios'
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import { toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
function QuoteJobeDetails(props) {
    const Viewquote = props.userState.row.id
    const [view, setView] = useState()
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [QuotViews, setQuotViews] = useState({

    })
    const [quote, setQuote] = useState({
        message: "",
        term: "",
        currency: "",
        price: "",
        days: ""
    })
    const fetchViewQuotation = (id) => {

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const formData = new FormData();
        formData.append('id', Viewquote)
        Axios.post('/api/viewQuotation', formData,
            { headers: headers })
            .then((response) => {
                setView(response.data.data);
                setQuotViews(response.data.data.quotation)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }
    const QuotationTeacher = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const formData = new FormData();
        formData.append("id", Viewquote);
        formData.append("message", quote.message);
        formData.append("term", quote.term);
        formData.append("currency", quote.currency);
        formData.append("price", quote.price);
        formData.append("days", quote.days);
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')), }
        axios
            .post(
                '/api/quote', formData,
                { headers: headers },
            )
            .then((res) => {
                if (res.data.success) {
                    let user = _.get(res, "data.data.user")
                        ? JSON.stringify(res.data.data.user)
                        : undefined;
                    let token = _.get(res, "data.data.token")
                        ? JSON.stringify(res.data.data.token)
                        : undefined;
                    if (user) {
                        localStorage.setItem("user", JSON.stringify(res.data.data.user));
                    }
                    if (token) {
                        localStorage.setItem("token", (res.data.data.token));
                    }
                    // Show a success toast message
                    toast.success("Teacher Quote Successfully");
                    Backdashboard();
                    setTimeout(() => {
                        // Update your table or perform other actions here
                        setQuote(res.data.data);
                    }, 2000); // Delay for 2 seconds

                }

            })
            .catch((error) => {
                // Show an error toast message
                toast.error("Something went wrong");

            });
    }
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])

    const initializeObject = () => {
        setQuotViews({ ...QuotViews, ...props.userState.row })
    }

    useEffect(() => {
        // Pass the id as an argument when calling fetchViewQuotation
        fetchViewQuotation(QuotViews.id);
    }, []);
    const handleInput = (e) => {
        const { name, value } = e.target;
        // Update other fields in QuotationFreelance
        setQuote({ ...quote, [name]: value });
    };
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherQuotation' })
        }
    }
    
    const goToTeacherQuoteJobDetail = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherQuotation' })
        }
    }


    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'> Quotation Request</div>
            </div>
            <div className='main-schedule-head ms-xxl-5 ms-xl-5 ms-lg-3 ms-md-0' ><p className='Title-main-head '>Tuition Details</p></div>
            <div className='quote-job-details-div1'>
                <div class="container justify-content-centerquotation-details">
                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            Closing Date:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label ">
                            {QuotViews.closing_date}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label ">
                            Student Name:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            {QuotViews.student_id}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label ">
                            Category:
                        </div>
                        <div class="col-5 quote-content">
                            {QuotViews.category}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8  teacher-font-label">
                            Sub Category:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            {QuotViews.sub_category}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            Topic:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label ">
                            {QuotViews.topic !== null || QuotViews.topic === "" ? QuotViews.topic : "Null"}
                        </div></div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            Details:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            {QuotViews.details !== null || QuotViews.details === "" ? QuotViews.details : "Null"}
                        </div></div>


                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            Price:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            {QuotViews.price !== null || QuotViews.price === "" ? QuotViews.price : "Null"}
                        </div></div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label ">
                            Payment:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            {QuotViews.price !== null || QuotViews.price === "" ? QuotViews.price : "Null"}
                        </div></div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            Currency:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            {QuotViews.currency !== null || QuotViews.currency === "" ? QuotViews.currency : "Null"}
                        </div></div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            Attachments:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            {QuotViews.file !== null || QuotViews.file === "" ? QuotViews.file : "Null"}
                        </div></div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            Status:
                        </div>
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label">
                            <span style={{ color: QuotViews.status === '0' ? 'green' : 'red' }}>
                                {QuotViews.status === '0' ? "Active" : "Inactive"}
                            </span>
                        </div></div>

                    <div class="col-10">
                        <div class="row justify-content-center">
                            <div class="col-5 schedule-head">
                                Schedule:
                            </div>
                            {view?.scheduleTime?.map((item, index) => (
                                <div key={index} class="col-12">
                                    <div class="row">
                                        <div class="col-1">
                                            <input class="time-input" type="checkbox" />
                                        </div>
                                        <div class="col-1">
                                            {item.day}
                                        </div>
                                        <div class="col-1 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5">
                                            From
                                        </div>
                                        <div class="col-1">
                                            <input class="time-input " type="text" placeholder="AM/PM" value={item.from} />
                                        </div>
                                        <div class="col-1 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5">
                                            To
                                        </div>
                                        <div class="col-1">
                                            <input class="time-input" type="text" placeholder="AM/PM" value={item.to} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                </div>

                <div class="modal  " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content">
                            <div className='sub-Head1' >  <FontAwesomeIcon className='mt-1' icon={faChevronLeft} />
                                <div className='Sub-Head2 '> My Quote</div>
                            </div>
                            <div class="modal-body pt-5">

                                <div class="container text-start ">
                                    <div class="row mt-4 ">
                                        <div class="col">
                                            <label class="fs-6 fw-semibold">Message</label>
                                            <textarea class="form-control" placeholder="Leave a comment here" ></textarea>
                                        </div>

                                    </div>
                                    <div class="row mt-4 ">
                                        <div class="col">
                                            <label class="fs-6 fw-semibold">Price</label>
                                            <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>
                                        <div class="col">
                                            <label class="fs-6 fw-semibold">Currency</label>
                                            <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>
                                        <div class="col">
                                            <label class="fs-6 fw-semibold">Payment</label>
                                            <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>
                                    </div>
                                </div>



                                <div className="container text-center">
                                    <div className="row">
                                        <div className="col">
                                            <button
                                                type="submit"
                                                className="btn  text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                                                data-bs-dismiss="modal"
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <button className='quote-btn' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleOpen}>
                Quote
            </button>
        </div>
    )
}

export default QuoteJobeDetails