import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveStudent, fetchStudentList, getCategoryListData, studentData, studentLoading } from '../../../../../../redux/slice/studentSlice';
import { format } from 'date-fns';
import CSTComponent from './CST.component';
import { RotatingLines } from 'react-loader-spinner';
import { CSTData, getCSTData } from '../../../../../../redux/slice/SubjectSlice';

function AdminStudentComponent(props) {


    const dispatch = useDispatch();
    const stuData = useSelector(studentData);
    const data = useSelector(CSTData);

    const loading = useSelector(studentLoading);
    useEffect(() => {
        dispatch(fetchStudentList())
    }, []);
    const categoryOptions = data.map((e) => ({
        value: e.id,
        label: e.name
    }));
    const dateFunction = (date) => {
        return (format(new Date(date), 'dd-MM-yyyy'));
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontWeight: "bold"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.created_at,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {dateFunction(row.created_at)}
                    </div>
                )
            }
        },
        {
            name: 'Student',
            selector: row => row?.firstname,
            sortable: true
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row?.status === '1' ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}
                    </div>
                )
            }
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <button type="button" class="btn text-light px-3 py-1 bootBtn" onClick={() => handleClick(row)}>Details</button>
                </div>
            )
        }
    ];


    const goToAdminStudentDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminStudentDetails' })
        }
    }

    function handleClick(row) {
        goToAdminStudentDetails();
        dispatch(ActiveStudent(row.id));
    }
    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
        // dispatch(getCategoryListData());
    }, []);
    const [filterText, setFilterText] = useState('');
    const [categoryData, setCategoryData] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const filteredData1 = stuData.filter(item => {
            let res = false;

            // Check if the item's created_at field matches the filterText (date)
            if (item.created_at) {
                const date = new Date(item.created_at);
                res = date.getMonth() >= 0 && item.created_at.toLowerCase().includes(filterText.toLowerCase());
            }
            // Check if the item's id (category) matches the categoryData
            if (item.id) {
                res = res && item.id.toString().toLowerCase().includes(categoryData.toLowerCase());
            }
            return res;
        });
        setFilteredData(filteredData1);
    }, [filterText, categoryData, stuData])





    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >
                <div className='Sub-Head2'>Students</div>
            </div>
            <div className='px-5'>
                <div class="container text-start my-5">
                    <div className="row">
                        <div className="col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 ms-1">
                            <div>
                                <div className="container ">
                                    <div className='row'>
                                        <div className='col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12'>
                                            <div className="form-group">
                                                <label htmlFor="exampleDropdown" className="fs-5 fw-semibold">Date</label><br />
                                                <input
                                                    type='date'
                                                    className='admin-dropdown_date'
                                                    onChange={(e) => setFilterText(e.target.value)}
                                                    value={filterText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 ms-1">
                            <div className="container ">
                                <div className="form-group">
                                    <label htmlFor="categoryDropdown" className=" fw-semibold">Category</label>
                                    <select
                                        className="form-select admin-dropdown"
                                        id="categoryDropdown"
                                        name="category"

                                        onChange={(e) => setCategoryData(e.target.value)}
                                        value={categoryData}
                                    >
                                        <option value=""></option>
                                        {categoryOptions?.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="row">
                        <CSTComponent />
                    </div> */}
                    <div class="row">
                        {loading ? (
                            <div className='d-flex justify-content-center' style={{ height: '40vh' }}>
                                <RotatingLines
                                    strokeColor="#5C5AB6"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="50"
                                    height="50"
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <DataTable
                                className='dataTable mt-5'
                                data={filteredData || []}
                                columns={columns}
                                customStyles={customStyles}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                striped                            
                                persistTableHead
                                pagination
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminStudentComponent