import React, { useEffect, useState } from 'react'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import './Section1_Navbar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { IoTimeSharp } from "react-icons/io5";
import Rectangle4467 from "../../../images/landing_page_images/Rectangle4467.jpg"
import { useDispatch, useSelector } from 'react-redux';
import Footer from './Section5_footer';
import { useNavigate } from 'react-router-dom';
import { CSTData, getCSTData } from '../../../redux/slice/SubjectSlice';
import _ from 'lodash';
function Featured_Course(props) {
    const dispatch = useDispatch();
    const data = useSelector(CSTData);
    const navigate = useNavigate();

    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
    }, []);
    const categoryOptions = data?.map((e) => ({
        value: e.id,
        label: e.name
    }));
    const [featured, setFeatured] = useState(
        [
            {
                name: "course-1",
                id: 1,
                count: "925",
                duration: "14hrs",
                image: "."
            },
            {
                name: "course-2",
                id: 2,
                count: "919",
                duration: "12hrs",
                image: "Loretium risus euismod dictum egestas cursus orci in. Id sed montes."
            },
            {
                name: "course-3",
                id: 3,
                count: "911",
                duration: "20hrs",
                image: "Lorem ipsum dolor sit ametentas cursus orci in. Id sed montes."
            },
            {
                name: "course-4",
                id: 4,
                count: "905",
                duration: "15hrs",
                image: "orci in. Id sed montes."
            },
            {
                name: "course-5",
                id: 5,
                count: "903",
                duration: "20hrs",
                image: "orci in. Id sed montes."
            },
            {
                name: "course-6",
                id: 6,
                count: "879",
                duration: "17hrs",
                image: "orci in. Id sed montes."
            },
            {
                name: "course-7",
                id: 7,
                count: "899",
                duration: "25hrs",
                image: "orci in. Id sed montes."
            },
            {
                name: "course-8",
                id: 8,
                count: "865",
                duration: "28hrs",
                image: "orci in. Id sed montes."
            }

        ]
    );


    const goToViewSubject = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'ShowSubjects', })
        }
    }


    return (
        <div>
            <div className="profile-main d-flex py-2" onClick={() => navigate("/home")}>
                <div className="fa-chaveron"><ArrowBackIosIcon className='mb-1' /></div>
                <div className="profile-head" >Featured Courses</div>
            </div>
            <div className='container'>
                <p className='featured-subhead mt-3'>Categories</p>
                <div class="container text-start">
                    <div class="row">
                        <div class="col">
                            {categoryOptions?.map((e) => {
                                return <button className='pe-5 me-3 mt-3 ps-5 pt-2 pb-2 category-btn  border border-secondary text-uppercase'>{e.category}</button>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div class="container text-start my-5">
                <div class="row mt-5 mx-xxl-0 mx-xl-0 mx-lg-5">
                    {featured?.map((card, index) => (
                        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-4">
                            <div className="landing-card p-2">
                                <div >
                                    <img className="img-fluid" src={Rectangle4467} />
                                </div>
                                <p className="landing-card-head mt-2 ms-2 mb-0 ">{card.name}</p>
                                <div className="rating text-left">
                                    <small class="text-muted-rating ms-2 mt-2">
                                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782", }} />
                                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782", }} />
                                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782", }} />
                                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782", }} />
                                        <FontAwesomeIcon icon={faStar} style={{ color: "#C7CFE2", }} />
                                        <span className="ms-2 fw-bold">(4.5)</span></small>
                                    <p className="mb-0 fw-semibold ms-2 mt-2"><span className="me-1"><FontAwesomeIcon icon={faGraduationCap} style={{ width: "15px" }} /></span> {card.count} students</p>
                                    <p className="mb-0 fw-semibold ms-2"><span className="me-1 "><IoTimeSharp icon={faGraduationCap} style={{ width: "15px" }} /></span> Duration: {card.duration}</p>
                                    <div className="container text-center my-3">
                                        <div className="row">
                                            <div className="col">
                                                <button className="btn outline-btn-admin px-xxl-3 px-xl-3 px-lg-1 fw-bold rounded-pill text-center">View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />

        </div>
    )
}
export default Featured_Course