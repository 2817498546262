import React from 'react'
import './Client.scss'
import Card from 'react-bootstrap/Card';
import Slider from "react-slick";
import StarRatings from 'react-star-ratings';
import Rimg1 from "../../../../images/landing_page_images/Rimg1.png"
function Clients() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    className: "center",
    centerMode: true,
    centerPadding: "0px",
  };
  const items = [
    { id: 1, title: 'item #1', name: "Courtney Henry" },
    { id: 2, title: 'item #2', name: "Darrell Steward" },
    { id: 3, title: 'item #3', name: "Jerome Bell" },
    { id: 4, title: 'item #4', name: "Courtney Henry" },
    { id: 5, title: 'item #5', name: "Darrell Steward" },
    { id: 6, title: 'item #5', name: "Courtney Henry" }
  ]

  return (
    <div className='clients py-5'>
      <div>
        <h3 className='happy_clients '>HAPPY CLIENTS</h3>
        <h2 className='client-head'>WHAT OUR CLIENTS SAY</h2>
      </div>
      <div>
        <div class="row">
          <div class="col-12">
            <div className='all-client-card d-flex'>
              <Slider {...settings}>
                {items.map(item =>
                  <div className="sdf">
                    <Card className='client-card px-0 mt30 d-flex-center-column' >
                      <div className="client-img">
                        <img className='Rimg1 d-flex' src={Rimg1} />
                      </div>
                      <Card.Body className='client-body'>
                        <Card.Title> <strong> {item.name} </strong></Card.Title>
                        <Card.Text className='client-text'>
                          Some quick example text to build on the card title and make up the
                          bulk of the card's content.
                        </Card.Text>
                      </Card.Body>
                      <div class="client-card-footer ">
                        <StarRatings
                          className="sub-rating-star"
                          starRatedColor={"#F2DE25"}
                          starDimension={"10px"}
                          rating={2.403}
                          starHoverColor='black'
                          starSpacing="1px"
                        />
                      </div>
                    </Card>
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clients