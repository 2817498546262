import React, { useState } from "react"
import Dashboard from "./Components/Dashboard"
import EnrolledCourses from "./Components/EnrolledCourses"
import Assignment from "./Components/Assignment"
import Chats from "./Components/Chats"
import Wallets from "./Components/Wallet"
import Notification from "./Components/Notification"
import TestResults from "./Components/Tests"
import Quotation from "./Components/Quotation"
import EnrolledVideos from "./Components/EnrolledVideos"
// import Studentfreelance from "./Components/Studentfreelance"
function StudentSidebar({ ...props }) {

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="sidebar">
      <Dashboard {...props} />
      <EnrolledCourses {...props} />
      {/* <EnrolledVideos {...props} /> */}
      <Assignment {...props} />
      <Wallets {...props} />
      <Chats {...props} />
      <Notification {...props} />
      <TestResults {...props} />
      <Quotation {...props} />
      {/* < Studentfreelance {...props} /> */}
    </div>
  )
}

export default StudentSidebar
