import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const showSuccessToast = (message,duration = 2000) => {
    toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: duration
    });
    return <ToastContainer />
};

export const showErrorToast = (message) => {
    toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
    });
    return <ToastContainer />
};
