import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import wanteach from '../../../images/wanteach.png';
import { FaArrowRight } from "react-icons/fa";
import mainimg from '../../../images/landing_page_images/combined.jpg'
import './Section2_HeaderSearch.scss'
import peoplesearch from "../../../images/landing_page_images/peoplesearch.png"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Axios from "../../../util/apiUtil";

function HeadSectionBelowNav(props) {
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  useEffect(() => {
    console.log(props)
  }, [])

  const goToLink = (e, link) => {
    navigate(link);
  }
  const goToStudent = (e) => {
    navigate("/user/sign_up");
  }
  const goToShowsubjects = (e, link) => {
    navigate('/Showsubjects');
  }

  const setWindowDimensions = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  })
  // const [searchResults, setSearchResults] = useState([]);
  // const [searchQuery, setSearchQuery] = useState('');
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  // const gotosearch = async () => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     const response = await Axios.post('/api/globalSearch', { search: searchQuery });
  //     if (response.status !== 200) {
  //       throw new Error('Search failed');
  //     }
  //     if (response.data.success === false) {
  //       throw new Error(response.data.error.join(', '));
  //     }
  //     const result = response.data;
  //     setSearchResults(result.data.subject);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error during search:', error);
  //     setError(error.message);
  //     setLoading(false);
  //   }
  // };

  
  return (
    <div
      className='head_description '
      style={{ backgroundImage: `url(${mainimg})`, height: (dimensions.height - (dimensions.height > 1000 ? 400 : 10)) + "px" }}>
      <div class="container landing-home-txt">
        <div class="row">
          <div class="col">
            <p className="landing-main-txt">Looking for tuition? <span className="ms-3"><button onClick={() => navigate("/user/sign_in")} type="button" class="btn landing-btn text-light">Be a student</button></span></p>
          </div>

        
   

        </div>
        <div class="row">
          <div class="col">
            <p className="landing-main-txt">Want to be a tutor? <span className="ms-3"><button onClick={() => navigate("/user/sign_in")} type="button" class="btn landing-btn text-light">Be a teacher</button></span></p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p className="landing-main-txt">Are you a bright student or subject expert?</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p className="landing-main-sub-txt mb-0">Like to assist and support students in their academic projects </p>
          </div>
        </div>
        <div class="row">
          <div class="d-flex justify-content-end">

            {/* <div className="col-2 ">
              <button className="btn d-flex demo-button   p-2"
                id='volsavebtn'
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal">
                <span><div className="user-icon">
                  <img className='peoplesearch' src={peoplesearch} />
                </div></span> Start a Demo
              </button>
            </div> */}

            <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
              <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                  <div className='sub-Teach d-flex p-3' >  <FontAwesomeIcon className='mt-1' icon={faChevronLeft} />
                    <div className='Sub-Head2 '> Teach </div>
                  </div>
                  <div class="modal-body ">
                    <div class="container text-start ">
                      <div class="row mt-4 ">
                        <div class="col">
                          <p className='student-teacher-part'>Want to Teach,</p>
                        </div>
                      </div>
                      <div class="row  text-center mb-4">
                        <div class="col">
                          <img src={wanteach} alt="" className='img-fluid' />
                        </div>

                      </div>
                      <div className="row d-flex justify-content-center">
                        <label className="col-3 ps-0 m-0 teacher-font-label text-start mt-1">Select  Subject :</label>
                        <div className="col-4">
                          <input
                            type="text"
                            as="select"
                            className="form-select profile-field"
                            placeholder="Select  Subject"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <label className="col-3 ps-0 m-0 teacher-font-label text-start mt-3">Enter Topic:</label>
                        <div className="col-4">
                          <input
                            type="text"
                            as="select"
                            className="form-select profile-field mt-3"
                            placeholder="Select Topic"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <label className="col-3 ps-0 m-0 teacher-font-label text-start mt-3 ">Select  Language:</label>
                        <div className="col-4">
                          <input
                            type="text"
                            as="select"
                            className="form-select profile-field mt-3"
                            placeholder="Select  Language"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="container text-end">
                      <div className="row">
                        <div className="col">
                          <button
                            type="submit"
                            className="btn text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3 "
                            data-bs-dismiss="modal"
                            onClick={() => navigate('/Showsubjects')}
                            data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">
                            <p className='m-0'> Next<span className='ms-1' ><FaArrowRight /></span></p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeadSectionBelowNav