import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { RiAddCircleFill } from 'react-icons/ri';
import _ from 'lodash';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import img from '../../../../../../images/bi_image-fill.jpg';
import img1 from '../../../../../../images/Ellipse 797.png'

import { GrFormAdd } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { UploadCourse, UploadCourseList, uploadList } from '../../../../../../redux/slice/UploadSlice';
import { CSTData, SubData, SubjectData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from '../../../../../../redux/slice/SubjectSlice';

function CourseUploadComponent(props) {

    const dispatch = useDispatch();
    const [section, setSection] = useState(false);
    const [containers, setContainers] = useState([{ id: 1 }]);
    const [Content, setContent] = useState(false);
    const [Assignment, setAssignment] = useState(false);
    const [Resources, setResources] = useState(false);
    const data = useSelector(CSTData);
    const subCatData = useSelector(SubData);
    const TopicData = useSelector(topicData);


    console.log("---->", TopicData);

    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
    }, []);

    const addContainer = () => {
        const newContainer = { id: containers.length + 1 };
        setContainers([...containers, newContainer]);
    };

    const removeContainer = (id) => {
        const updatedContainers = containers.filter((container) => container.id !== id);
        setContainers(updatedContainers);
    };

    const validationSchema = Yup.object().shape({
        category: Yup.string().required('Category is required'),
        sub_category: Yup.string().required('Sub Category is required'),
        topic: Yup.string().required('Subject/Topic is required'),
        language: Yup.string().required('Teaching Language is required'),
        description: Yup.string().required('Description is required'),
        levels: Yup.string().required('Level is required'),
        price: Yup.number().typeError('Price must be a number').required('Price is required'),
        currency: Yup.string().required('Currency is required'),
        discount_percentage: Yup.number().typeError('Discount % must be a number').required('Discount % is required'),
        discount_amount: Yup.number().typeError('Discount Amount must be a number').required('Discount Amount is required'),
        promotionalVideo: Yup.string().url('Invalid URL format').required('Promotional Video Link is required'),
        image: Yup.mixed(),
        file: Yup.mixed().required('file is required'),
    });

    const initialValues = {
        category: '',
        sub_category: '',
        topic: '',
        language: '',
        description: '',
        levels: '',
        price: '',
        currency: '',
        discount_percentage: '',
        discount_amount: '',
        promotionalVideo: '',
        tittle: "",
        link: "",
        AssignmentTitle: "",
        AssignmentDescription: "",
        image: undefined,
        file: undefined,
        ChooseFile1: undefined,
        UploadVideo: undefined,
        UploadVideo2: undefined,
    };

    const goToAddSection = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'uploaderAddSection' });
        }
    };

    const handleSubmit = (values) => {
        setSection(true)
        console.log(values);
        if (section) {
            const payload = {
                "video_count": "1"
            }
            const mergedObject = { ...values, ...payload };
            dispatch(UploadCourse(mergedObject));
        }
    };


    const categoryOptions = data.map((e) => ({
        value: e.id,
        label: e.name
    }));


    const SubCategoryOptions = subCatData.map((e) => ({
        value: e.id,
        label: e.name
    }));

    const TopicOptions = TopicData.map((e) => ({
        value: e.id,
        label: e.name
    }));


    return (
        <div>
            <div className="main-divHead">
                <div className="sub-Head1">
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <div className="Sub-Head2">Subjects</div>
                </div>
                <div>
                    <h5 className="fw-bold px-4 pt-4 pb-2">Select / Add Subject</h5>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ touched, errors, handleSubmit, resetForm, isSubmitting, setFieldValue, values, handleBlur, handleChange }) => (
                            <Form>
                                {!section ?
                                    <div className="container mt-5 mb-3">
                                        <div className="row">
                                            <div class="col">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col">
                                                            <label class="fs-6 fw-semibold">Category</label>
                                                            <Field
                                                                as="select"
                                                                className={`form-select`}
                                                                name="category"
                                                                id="exampleDropdown"
                                                                value={values.category}
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    const selectedValue = e.target.value;
                                                                    const payload = {
                                                                        "type": 2,
                                                                        "category_id": selectedValue,
                                                                    };
                                                                    dispatch(getSubCategoryData(payload));
                                                                    setFieldValue('category', selectedValue);
                                                                }}
                                                            >
                                                                <option value=""></option>
                                                                {categoryOptions.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage name="category" component="div" className="text-danger" />
                                                        </div>
                                                        <div class='col-2 d-flex align-items-end ps-0'>
                                                            <RiAddCircleFill class='admin-add-icon mb-2' />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div className="form-group">
                                                                <label class="fs-6 fw-semibold">Sub Category / Class</label>
                                                                <Field
                                                                    as="select"
                                                                    className={`form-select`}
                                                                    name="sub_category"
                                                                    id="exampleDropdown"
                                                                    disabled={values.category == "" ? true : false}
                                                                    value={values.sub_category}
                                                                    onChange={(e) => {
                                                                        const selectedValue = e.target.value;
                                                                        const payload = {
                                                                            "type": 3,
                                                                            "sub_category_id": selectedValue,
                                                                        };
                                                                        dispatch(getSubTopicData(payload));
                                                                        setFieldValue('sub_category', selectedValue);
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                >
                                                                    <option value=""></option>
                                                                    {SubCategoryOptions.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                <ErrorMessage name="sub_category" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class='col-2 d-flex align-items-end ps-0'>
                                                            <RiAddCircleFill class='admin-add-icon mb-2' />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div className="form-group">
                                                                <div>
                                                                    <label class="fs-6 fw-semibold">Subject / Topic</label>
                                                                    <Field
                                                                        as="select"
                                                                        className={`form-select`}
                                                                        name="topic"
                                                                        id="exampleDropdown"
                                                                        value={values.topic}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        disabled={values.sub_category == "" ? true : false}
                                                                    >
                                                                        <option value=""></option>
                                                                        {TopicOptions.map((option) => (
                                                                            <option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </Field>
                                                                    <ErrorMessage name="topic" component="div" className="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class='col-2 d-flex align-items-end ps-0'>
                                                            <RiAddCircleFill class='admin-add-icon mb-2' />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div className="form-group">
                                                                <label class="fs-6 fw-semibold">Teaching Language</label>
                                                                <Field
                                                                    as="select"
                                                                    className={`form-select`}
                                                                    name="language"
                                                                    id="exampleDropdown"
                                                                >
                                                                    <option value=""></option>
                                                                    <option value="option1">English</option>
                                                                    <option value="option2">Malayalam</option>
                                                                    
                                                                </Field>
                                                                <ErrorMessage name="language" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class='col-2 d-flex align-items-end ps-0'>
                                                            <RiAddCircleFill class='admin-add-icon mb-2' />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div className="form-group">
                                                                <div>
                                                                    <label class="fs-6 fw-semibold">Levels</label>
                                                                    <Field
                                                                        as="select"
                                                                        className={`form-select`}
                                                                        name="levels"
                                                                        id="exampleDropdown"
                                                                    >
                                                                        <option value=""></option>
                                                                        <option value="option1">Level 1</option>
                                                                        <option value="option2">Level 2</option>
                                                                        <option value="option3">Level 3</option>
                                                                        <option value="option4">Level 4</option>
                                                                    </Field>
                                                                    <ErrorMessage name="levels" component="div" className="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class='col-2'></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <div className="form-group">
                                                                <div>
                                                                    <label class="fs-6 fw-semibold">Price</label>
                                                                    <Field
                                                                        type="text"
                                                                        name="price"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage name="price" component="div" className="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-5">
                                                            <div className="form-group">
                                                                <div>
                                                                    <label class="fs-6 fw-semibold">Currency</label>
                                                                    <Field
                                                                        as="select"
                                                                        className={`form-select`}
                                                                        name="currency"
                                                                        id="exampleDropdown"
                                                                    >
                                                                        <option value=""></option>
                                                                        <option value="option1">INR</option>
                                                                        
                                                                    </Field>
                                                                    <ErrorMessage name="currency" component="div" className="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class='col-2'></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <div className="form-group">
                                                                <div>
                                                                    <label class="fs-6 fw-semibold">Discount %</label>
                                                                    <Field
                                                                        type="text"
                                                                        name="discount_percentage"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage name="discount_percentage" component="div" className="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-5">
                                                            <div className="form-group">
                                                                <div>
                                                                    <label class="fs-6 fw-semibold">Discount Amount</label>
                                                                    <Field
                                                                        type="text"
                                                                        name="discount_amount"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage name="discount_amount" component="div" className="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class='col-2'></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div className="form-group">
                                                                <div>
                                                                    <label class="fs-6 fw-semibold">Attach Promotional Video Link</label>
                                                                    <Field
                                                                        type="text"
                                                                        name="promotionalVideo"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage name="promotionalVideo" component="div" className="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class='col-2'></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="admin-card admin-dotted-border m-5 p-5 text-center">
                                                    <img src={img} className="img-fluid rounded-top" alt="..." />
                                                    <p className="fs-6 mt-2">Upload course image</p>
                                                    <label htmlFor="fileInput" className="btn btn-primary px-4 py-1 bootBtn">
                                                        Browse File
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        name="file"
                                                        style={{ display: 'none' }}
                                                        onChange={(event) => {
                                                            setFieldValue('file', event.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    <ErrorMessage name="file" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 ms-2">
                                            <div className="col-7">
                                                <label className="fs-6 fw-semibold">Description</label>
                                                <Field as="textarea" name="description" className="form-control" placeholder="Leave a comment here" />
                                                <ErrorMessage name="description" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div> : <div>
                                        {containers.map((container) => (
                                            <div className="container text-start mt-5" key={container.id}>
                                                <div class="container text-start">
                                                    <div className='mx-5'>
                                                        <div class="row">
                                                            <div className='d-flex'>
                                                                <div class="col-11">
                                                                    <button type="button" className="btn btn-primary px-4 py-1 bootBtn-section mt-1" >Section : {container.id} </button>
                                                                </div>
                                                                <div>
                                                                    {containers.length > 1 && (
                                                                        <img src={img1} class="img-fluid rounded-top " alt="..." onClick={() => removeContainer(container.id)} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-11">
                                                                <div className='admin-card-section p-5'>
                                                                    <div className='d-flex'>
                                                                        <label className='fw-bold mt-1 me-2'>Title </label>
                                                                        <Field
                                                                            type="text"
                                                                            name="tittle"
                                                                            className="form-control"
                                                                        />
                                                                        <ErrorMessage name="tittle" component="div" className="text-danger" />
                                                                    </div>
                                                                    {Content && <div>
                                                                        <label className='fw-bold mt-4'>Upload Video</label>
                                                                        <div class="input-group mb-3">

                                                                            {/* <input
                                                                                type="file"
                                                                                id="ChooseFile1"
                                                                                name="ChooseFile1"
                                                                                // style={{ display: 'none' }}
                                                                                onChange={(event) => {
                                                                                    setFieldValue('ChooseFile1', event.currentTarget.files[0]);
                                                                                }}
                                                                            /> */}

                                                                            <input
                                                                                type="file"
                                                                                id="ChooseFile1"
                                                                                name="ChooseFile1"
                                                                                onChange={(event) => {
                                                                                    setFieldValue('ChooseFile1', event.currentTarget.files[0]);
                                                                                }}
                                                                                class="form-control" />
                                                                            <span class="input-group-text chooseFile-uploader" htmlFor="fileInput" >Choose File</span>
                                                                        </div>
                                                                        <hr className='my-5  admin-horizontal-line'></hr>
                                                                    </div>}
                                                                    {Assignment && <div>
                                                                        <h4 className='fw-bold'>Assignment :</h4>
                                                                        <div className='d-flex mt-4'>
                                                                            <label className='fw-bold mt-1'>Assignment Title  </label>
                                                                            <Field
                                                                                type="text"
                                                                                name="AssignmentTitle"
                                                                                className="form-control"
                                                                            />
                                                                            <ErrorMessage name="AssignmentTitle" component="div" className="text-danger" />
                                                                        </div>
                                                                        <div className='d-flex mt-4'>
                                                                            <label className='fw-bold mt-1 me-2'>Description  </label>
                                                                            <Field
                                                                                type="textarea"
                                                                                name="AssignmentDescription"
                                                                                className="form-control"
                                                                            />
                                                                            <ErrorMessage name="AssignmentDescription" component="div" className="text-danger" />
                                                                        </div>
                                                                        <label className='fw-bold mt-4'>Upload Video</label>
                                                                        <div class="input-group mb-3">
                                                                            <input
                                                                                type="file"
                                                                                id="UploadVideo"
                                                                                name="UploadVideo"
                                                                                // style={{ display: 'none' }}
                                                                                onChange={(event) => {
                                                                                    setFieldValue('UploadVideo', event.currentTarget.files[0]);
                                                                                }}
                                                                                class="form-control" />                                                                            <span class="input-group-text chooseFile-uploader" id="basic-addon2">Choose File</span>
                                                                        </div>
                                                                        <hr className='my-5  admin-horizontal-line'></hr>
                                                                    </div>}
                                                                    {Resources && <div>
                                                                        <h4 className='fw-bold'>Resources :</h4>
                                                                        <label className='fw-bold mt-4'>Upload Video</label>
                                                                        <div class="input-group mb-3">
                                                                            <input
                                                                                type="file"
                                                                                id="UploadVideo2"
                                                                                name="UploadVideo2"
                                                                                // style={{ display: 'none' }}
                                                                                onChange={(event) => {
                                                                                    setFieldValue('UploadVideo2', event.currentTarget.files[0]);
                                                                                }}
                                                                                class="form-control" />  </div>
                                                                        <label className='fw-bold mt-4'>link</label>
                                                                        <Field
                                                                            type="text"
                                                                            name="link"
                                                                            className="form-control"
                                                                            placeholder='https:'
                                                                        />
                                                                        <ErrorMessage name="link" component="div" className="text-danger" />
                                                                    </div>}
                                                                    <div className='d-flex gap-5'>
                                                                        {!Content && <button onClick={() => setContent(true)} className='my-5 px-3 py-1 addButton-upload'><span className='addButton-upload-text'><span><GrFormAdd /></span> Content </span></button>}
                                                                        {!Assignment && <button onClick={() => setAssignment(true)} className='my-5 px-3 py-1 addButton-upload'><span className='addButton-upload-text'><span><GrFormAdd /></span> Assignment </span></button>}
                                                                        {!Resources && <button onClick={() => setResources(true)} className='my-5 px-3 py-1 addButton-upload'><span className='addButton-upload-text'><span><GrFormAdd /></span> Resources </span></button>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <button type="button" className="btn btn-primary px-4 py-1 bootBtn m-5" onClick={addContainer} >Add Section </button>
                                    </div>}
                                <div className="container text-end me-5">
                                    <div className="row">
                                        <div className="col">
                                            <button
                                                type="submit"
                                                className="btn btn-primary px-4 py-1 bootBtn my-5 me-5"
                                            //  onClick={() => setSection()}
                                            >
                                                {!section ? "Next" : "Publish"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default CourseUploadComponent;



























