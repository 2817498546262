import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import DataTable from 'react-data-table-component';
import CSTComponent from './CST.component';

function AdminReportComponent() {

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const columns = [
        {
            name: 'Date',
            selector: row => row.starting_at,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true
        },
        {
            name: 'Sub Category',
            selector: row => row.sub_category,
            sortable: true
        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.status === 'Active' ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}
                    </div>
                )
            }
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <button type="button" className="btn text-light px-xxl-3 px-xl-3 px-lg-2 py-1 bootBtn" >Details</button>
                </div>
            )
        }
    ];

    const data = [
        {
            starting_at: '2023-09-28',
            category: 'Category 1',
            sub_category: 'Sub Category 1',
            topic: 'Topic 1',
            status: 'Active',
            batch_id: 'Batch 101',
        },
        {
            starting_at: '2023-10-10',
            category: 'Category 2',
            sub_category: 'Sub Category 2',
            topic: 'Topic 2',
            status: 'Inactive',
            batch_id: 'Batch 102',
        },
    ];

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Reports</div>
            </div>
            <div>
                <div class="container text-start my-5">
                    <div class="row mt-5">
                        <CSTComponent />
                    </div>
                    <div class="row mt-5">
                        <DataTable
                            className='dataTable mt-4'
                            data={data}
                            columns={columns}
                            fixedHeader={true}
                            fixedHeaderScrollHeight="600px"
                            striped
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminReportComponent