import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import axios from 'axios';
import { format } from 'date-fns';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function TeacherCouponView(props) {
    const [view, setView] = useState()
    const [data, setData] = useState({
    })

    const fetchstudentlist = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        // const createStudentList = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {}
        const formData = new FormData();
        // formData.append("email", _.get(createStudentList, 'email'));
        Axios.post('/api/listCoupon',

            formData, { headers })
            .then((response) => {
                if (_.get(response, 'data.data')) {
                    setData(response.data.data)
                }
            })
            .catch((error) => {
                console.error('Error fetching Coupons:', error)
            });
    };
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [data])

    const initializeObject = () => {
        setData({ ...data, ...props.userState.row })
    }

    useEffect(() => {
        fetchstudentlist()
    }, [])

    const formatDate = (date) => {
        try {
            const formattedDate = format(new Date(date), 'dd/MM/yyyy');
            return formattedDate;
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid Date'
        }
    }


    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachersubject' })
        }
    }

    return (
        <div className='main-divHead' >

            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Coupon</div>
            </div>


            <div className='view-assign-Head ms-5'>View Coupon</div>

            <div class="container text-start">
                <div class="row mt-4">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Coupon Name: </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.offer_name}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Discount Amount : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.value}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Subject : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.subject_id}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>limit : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.limit}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>start_date :</p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{formatDate(data.start_date)}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>End date : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{formatDate(data.end_date)}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>coupon Type  </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.coupon_type === '0' ? 'Private Coupon' : 'Public Coupon'}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Course Type :  </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.subject_all === '0' ? 'Assign to specific course' : 'Assign to all course'}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Discount Type :  </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.discount_type === '0' ? 'Percentage Discount' : 'Flat Discount'}</p>
                        </div>
                    </div>
                </div>
                </div>
                
           
        </div>
    )
}

export default TeacherCouponView