import React, { useState } from "react"
import _ from "lodash"
import { CiChat1 } from "react-icons/ci";


function ChatTeacher(props) {
    const [hoverEffect, setHoverEffect] = useState(false)

    const goToTeacherChat = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherChats' })
        }
    }
    return (
        <div onClick={(e) => goToTeacherChat(e)} className={`sidebar_content ${_.get(props, 'userState.subType') == 'TeacherChats' ? 'active' : ''} `}>

            <CiChat1 class="ms-3" />

            <p className='head-assignment ms-2' >Chats</p>
            {/* */}
        </div>
    )
}

export default ChatTeacher