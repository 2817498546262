import axios from 'axios';

const Axios = axios.create({
    // baseURL: 'http://tuition.techiesmarkets.com/backend/public/',
    baseURL: 'http://tuitionguruji.com/backend/public/',

});


Axios.interceptors.request.use((config) => {


    const Token = (localStorage.getItem('token'));
    if (Token) {
        config.headers.Authorization = `Bearer ${Token}`;
    }
    return config;
});

export default Axios;
