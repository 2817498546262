import React from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PaypalPaymentSettings, StripePaymentSettings, paymentSettings } from '../../../../../../redux/slice/settingsSlice';
import { useDispatch } from 'react-redux';

function AdminPaymentSettingsComponent() {

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        systemCurrency: Yup.string().required('system currency is required'),
        currencyPosition: Yup.string().required('currency position is required'),
    });

    const PaypalValidationSchema = Yup.object().shape({
        active: Yup.string().required('active is required'),
        mode: Yup.string().required('mode is required'),
        paypalCurrency: Yup.string().required('paypal currency is required'),
        clientIdSandbox: Yup.string().required('client ID (Sandbox) is required'),
        secretKeySandbox: Yup.string().required('secret Key (Sandbox) is required'),
        clientIdProduction: Yup.string().required('client ID (Production) is required'),
        secretKeyProduction: Yup.string().required('secret Key (Production) is required'),
    });

    const StripeValidationSchema = Yup.object().shape({
        active: Yup.string().required('active is required'),
        testMode: Yup.string().required('Test mode is required'),
        stripeCurrency: Yup.string().required('stripe currency is required'),
        testSecretKey: Yup.string().required('Test secret Key is required'),
        testPublicKey: Yup.string().required('Test public Key is required'),
        liveSecretKey: Yup.string().required('Live secret Key is required'),
        livePublicKey: Yup.string().required('Live public Key is required'),
    });

    return (
        <div>
            <div className='main-divHead' >
                <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Settings</div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class='d-flex justify-content-center'>
                            <div class="col-xx-8 col-xl-8 col-lg-10">
                                <div class='admin-card p-5 m-5'>
                                    <p class='admin-sub-head fw-bold'>Payment Settings</p>
                                    <Formik
                                        initialValues={{
                                            systemCurrency: '',
                                            currencyPosition: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, { resetForm }) => {
                                            const payload = {
                                                "currency_code": values.systemCurrency,
                                                "position": values.currencyPosition,
                                            }
                                            dispatch(paymentSettings(payload));
                                            resetForm();
                                        }}
                                    >
                                        {({ errors, touched }) => (
                                            <Form>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="d-flex justify-content-center">
                                                            <div className="col-10">
                                                                <label className="fs-6 fw-semibold mt-3">System Currency</label>
                                                                <Field as="select" name="systemCurrency" className="form-select" placeholder="Select currency">
                                                                    <option value="INR">INR</option>
                                                                    <option value="USD">USD</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="systemCurrency"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                                <label className="fs-6 fw-semibold mt-4">Currency Position</label>
                                                                <Field as="select" name="currencyPosition" className="form-select" placeholder="Select position">
                                                                    <option value="left">Left</option>
                                                                    <option value="right">Right</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="currencyPosition"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="container text-center">
                                                        <div className="row">
                                                            <div className="col">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary px-4 py-1 bootBtn mt-5"
                                                                >
                                                                    Update system currency
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                <div class='admin-card p-5 m-5'>
                                    <p class='admin-sub-head fw-bold'>Setup Paypal Settings</p>
                                    <Formik
                                        initialValues={{
                                            active: '',
                                            mode: '',
                                            paypalCurrency: '',
                                            clientIdSandbox: '',
                                            secretKeySandbox: '',
                                            clientIdProduction: '',
                                            secretKeyProduction: '',
                                        }}
                                        validationSchema={PaypalValidationSchema}
                                        onSubmit={(values, { resetForm }) => {

                                            const payload = {
                                                "type": 1,
                                                "status": values.active.toLowerCase() === 'yes' ? 1 : 0,
                                                "mode": values.mode,
                                                "currency": values.paypalCurrency,
                                                "client_id_sandbox": values.clientIdSandbox,
                                                "secret_key_sandbox": values.secretKeySandbox,
                                                "client_id": values.clientIdProduction,
                                                "secret_key": values.secretKeyProduction,
                                            }
                                            dispatch(PaypalPaymentSettings(payload));
                                            resetForm();
                                        }}
                                    >
                                        {({ errors, touched }) => (
                                            <Form>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="d-flex justify-content-center">
                                                            <div className="col-10">
                                                                <label className="fs-6 fw-semibold mt-3">Active</label>
                                                                <Field
                                                                    type="text"
                                                                    name="active"
                                                                    className="form-control"
                                                                    placeholder="Yes / No"
                                                                />
                                                                <ErrorMessage
                                                                    name="active"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                                <label className="fs-6 fw-semibold mt-4">Mode</label>
                                                                <Field
                                                                    type="text"
                                                                    name="mode"
                                                                    className="form-control"
                                                                    placeholder="Sandbox / Production"
                                                                />
                                                                <ErrorMessage
                                                                    name="mode"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Paypal Currency</label>
                                                                <Field
                                                                    type="text"
                                                                    name="paypalCurrency"
                                                                    className="form-control"
                                                                    placeholder="IND / USD"
                                                                />
                                                                <ErrorMessage
                                                                    name="paypalCurrency"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Client ID (Sandbox)</label>
                                                                <Field
                                                                    type="text"
                                                                    name="clientIdSandbox"
                                                                    className="form-control"
                                                                    placeholder="1234"
                                                                />
                                                                <ErrorMessage
                                                                    name="clientIdSandbox"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Secret Key (Sandbox)</label>
                                                                <Field
                                                                    type="text"
                                                                    name="secretKeySandbox"
                                                                    className="form-control"
                                                                    placeholder="1234"
                                                                />
                                                                <ErrorMessage
                                                                    name="secretKeySandbox"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Client ID (Production)</label>
                                                                <Field
                                                                    type="text"
                                                                    name="clientIdProduction"
                                                                    className="form-control"
                                                                    placeholder="1234"
                                                                />
                                                                <ErrorMessage
                                                                    name="clientIdProduction"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Secret Key (Production)</label>
                                                                <Field
                                                                    type="text"
                                                                    name="secretKeyProduction"
                                                                    className="form-control"
                                                                    placeholder="1234"
                                                                />
                                                                <ErrorMessage
                                                                    name="secretKeyProduction"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="container text-center">
                                                        <div className="row">
                                                            <div className="col">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary px-4 py-1 bootBtn mt-5"
                                                                >
                                                                    Update system currency
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                <div class='admin-card p-5 m-5'>
                                    <p class='admin-sub-head fw-bold'>Setup Stripe Settings</p>
                                    <Formik
                                        initialValues={{
                                            active: '',
                                            testMode: '',
                                            stripeCurrency: '',
                                            testSecretKey: '',
                                            testPublicKey: '',
                                            liveSecretKey: '',
                                            livePublicKey: '',
                                        }}
                                        validationSchema={StripeValidationSchema}
                                        onSubmit={(values, { resetForm }) => {

                                            const payload = {
                                                "type": 2,
                                                "status": values.active.toLowerCase() === 'yes' ? 2 : 0,
                                                "mode": values.testMode,
                                                "secret_key": values.testSecretKey,
                                                "currency": values.stripeCurrency,
                                                "public_key": values.testPublicKey,
                                                "test_secret_key": values.liveSecretKey,
                                                "test_public_key": values.livePublicKey,
                                            }
                                            dispatch(StripePaymentSettings(payload));
                                            resetForm();

                                        }}
                                    >
                                        {({ errors, touched }) => (
                                            <Form>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="d-flex justify-content-center">
                                                            <div className="col-10">
                                                                <label className="fs-6 fw-semibold mt-3">Active</label>
                                                                <Field
                                                                    type="text"
                                                                    name="active"
                                                                    className="form-control"
                                                                    placeholder="Yes / No"
                                                                />
                                                                <ErrorMessage
                                                                    name="active"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Test Mode</label>
                                                                <Field
                                                                    type="text"
                                                                    name="testMode"
                                                                    className="form-control"
                                                                    placeholder="Yes / No"
                                                                />
                                                                <ErrorMessage
                                                                    name="testMode"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Stripe Currency</label>
                                                                <Field
                                                                    type="text"
                                                                    name="stripeCurrency"
                                                                    className="form-control"
                                                                    placeholder="AUD"
                                                                />
                                                                <ErrorMessage
                                                                    name="stripeCurrency"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Test Secret Key</label>
                                                                <Field
                                                                    type="text"
                                                                    name="testSecretKey"
                                                                    className="form-control"
                                                                    placeholder="1234"
                                                                />
                                                                <ErrorMessage
                                                                    name="testSecretKey"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Test Public Key</label>
                                                                <Field
                                                                    type="text"
                                                                    name="testPublicKey"
                                                                    className="form-control"
                                                                    placeholder="1234"
                                                                />
                                                                <ErrorMessage
                                                                    name="testPublicKey"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Live Secret Key</label>
                                                                <Field
                                                                    type="text"
                                                                    name="liveSecretKey"
                                                                    className="form-control"
                                                                    placeholder="XXXXXXXXXXXXXXXXXXXX"
                                                                />
                                                                <ErrorMessage
                                                                    name="liveSecretKey"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />

                                                                <label className="fs-6 fw-semibold mt-4">Live Public Key</label>
                                                                <Field
                                                                    type="text"
                                                                    name="livePublicKey"
                                                                    className="form-control"
                                                                    placeholder="XXXXXXXXXXXXXXXXXXXX"
                                                                />
                                                                <ErrorMessage
                                                                    name="livePublicKey"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="container text-center">
                                                        <div className="row">
                                                            <div className="col">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary px-4 py-1 bootBtn mt-5"
                                                                >
                                                                    Update stripe keys
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPaymentSettingsComponent
