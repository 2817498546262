import React from 'react'
import dashboardWhiteIcon from "../../../icons/dashboardWhiteIcon.png"
import dashboardBlueIcon from "../../../icons/dashboardBlueIcon.png"
import _ from "lodash"
import { RxDashboard } from 'react-icons/rx'

function AdminDashboardComponent(props) {
    const goToDashboardTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'DashboardAdmin' })
        }
    }
    return (
        <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'DashboardAdmin' ? 'active' : ''} `} onClick={(e) => goToDashboardTeacher(e)}>
            <div className="dash d-flex">
                <RxDashboard class='ms-3' />
                <p className='head-dashboard ms-2' > Dashboard
                </p>
            </div>
        </div>
    )
}

export default AdminDashboardComponent