import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from '../../util/apiUtil';
import { showErrorToast, showSuccessToast } from '../../util/toastUtils';



export const WalletData = createAsyncThunk('freelance/WalletData', async (thunkApi) => {
    try {
        const response = await Axios.post('/api/wallet');
        console.log("response -> WalletData", response);
        return response.data.data;
        
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const fetchFreelancerList = createAsyncThunk('freelance/fetchFreelancerList', async (thunkApi) => {
    try {
        const response = await Axios.post('/api/listFreelancers');
        console.log("response -> fetchFreelancerList", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});


export const fetchFreelancerData = createAsyncThunk('freelance/fetchFreelancerData', async (payload, thunkApi) => {
    try {
        const response = await Axios.post('/api/viewFreelancer', payload);
        console.log("response -> fetchFreelancerData", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});


export const freelanceStatusChange = createAsyncThunk('freelance/freelanceStatusChange', async (payload, thunkApi) => {
    try {
        const response = await Axios.post('/api/changeFreelancerStatus', payload.payload);
        console.log("response -> freelanceStatusChange", response);
        if (response.data.success) {
            showSuccessToast("Status changed SuccessFully");
        }
        await thunkApi.dispatch(fetchFreelancerData(payload.viewFree));
        return response.data.data;
    } catch (error) {
        showErrorToast("Status change Failed");
        console.log("axios error", error);
        throw error;
    }
});


export const freelanceDocStatusChange = createAsyncThunk('freelance/freelanceDocStatusChange', async (payload, thunkApi) => {
    try {
        const response = await Axios.post('/api/changeFreelancerDocStatus', payload);
        console.log("response -> freelanceDocStatusChange", response);
        if (response.data.success) {
            showSuccessToast("Status changed SuccessFully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast("Status change Failed");
        console.log("axios error", error);
        throw error;
    }
});


// **************************************************************************************************************************************************



export const uploaderList = createAsyncThunk('freelance/uploaderList', async (thunkApi) => {
    try {
        const response = await Axios.post('/api/listvideoUploaders');
        console.log("response -> uploaderList", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});



export const uploaderData = createAsyncThunk('freelance/uploaderData', async (payload,thunkApi) => {
    try {
        const response = await Axios.post(`/api/viewVideouploader?uploader_id=${payload.uploaderID}`);
        console.log("response -> uploaderData", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});





export const freelanceSlice = createSlice({
    name: 'freelance',
    initialState: {
        isLoading: false,
        freelance: [],
        freelancerID: [],
        viewFreelance: [],
        walletData:[],
        uploadData:[],
        UploaderID:[],
        viewUploadData:[]
    },
    reducers: {
        ActiveFreelancerID: (state, action) => {
            return {
                ...state,
                freelancerID: action.payload
            }
        },
        uploaderID: (state, action) => {
            return {
                ...state,
                UploaderID: action.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFreelancerList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFreelancerList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.freelance = action.payload;
            })
            .addCase(fetchFreelancerList.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchFreelancerData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFreelancerData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.viewFreelance = action.payload;
            })
            .addCase(fetchFreelancerData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(WalletData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(WalletData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.walletData = action.payload;
            })
            .addCase(WalletData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(uploaderList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(uploaderList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.uploadData = action.payload;
            })
            .addCase(uploaderList.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(uploaderData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(uploaderData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.viewUploadData = action.payload;
            })
            .addCase(uploaderData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase( freelanceStatusChange.pending, (state) => {
                state.isLoading = true;
            })
            .addCase( freelanceStatusChange.fulfilled, (state, action) => {
                state.isLoading = false;
                state.viewUploadData = action.payload;
            })
            .addCase( freelanceStatusChange.rejected, (state) => {
                state.isLoading = false;
            })
    },
});


export const { ActiveFreelancerID , uploaderID } = freelanceSlice.actions;




export const freelanceList = (state) => state.freelance.freelance;
export const currentFreelancerId = (state) => state.freelance.freelancerID;
export const viewFreelance = (state) => state.freelance.viewFreelance;
export const walletDetails = (state) => state.freelance.walletData;
export const uploadListData = (state) => state.freelance.uploadData;
export const currentUploaderId = (state) => state.freelance.UploaderID;
export const viewUploaderData = (state) => state.freelance.viewUploadData;
export const freelanceLoading = (state) => state.freelance.isLoading;


export default freelanceSlice.reducer;

