import React from 'react'
import _ from "lodash"
import { FaUserGroup } from 'react-icons/fa6'


function TeacherComponent(props) {
    const goToTTeacherWallet = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminTeacher' })
        }
    }

    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'adminTeacher':
            case 'adminTeacherDetails':
            case 'TeacherCourse':
            case 'adminTeacherDocument':
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };

    return (
        <div className={determineClassName()}>
            <div className="dash d-flex">
                <FaUserGroup class='ms-3' />
                <p className='head-dashboard ms-2' onClick={(e) => goToTTeacherWallet(e)}> Teacher
                </p>
            </div>
        </div>
    )
}

export default TeacherComponent