import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { AiOutlineNotification } from "react-icons/ai";

function AnnouncementTeacher(props) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (!event.target.closest("#submenu2")) {
                setShow(false); // Close the announcement toggle when clicking outside of it
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setShow(!show);
    };

    const goToTeacherSchedule = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherschedule' });
        }
    };

    const goToTeacherScheduleNotification = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachernotification' });
        }
    };

    const goToTeacherAttendence = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherattendence' });
        }
    };

    return (
        <>
            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                <li>
                    <div className={`sidebar_content ${_.get(props, 'userState.subType') === 'adminSystemSettings' || _.get(props, 'userState.subType') === 'adminSMTPSettings' || _.get(props, 'userState.subType') === 'adminWebsiteSettings' || _.get(props, 'userState.subType') === 'adminPaymentSettings' ? 'active' : ''}`}>
                        <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle" onClick={toggleMenu}>
                            <i className=""></i>
                            <span className='head-assignment'>
                                <span><AiOutlineNotification className='ms-3' /></span>
                                <span className='head-dashboard ms-2'>Announcement</span>
                            </span>
                        </a>
                    </div>
                    <ul className={`collapse flex-column ms-1 ${show ? "show" : ""}`} id="submenu2" data-bs-parent="#menu" style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                        <li onClick={goToTeacherSchedule} className={`w-100 mt-2 ${props.userState.subType === "Teacherschedule" ? "Teacher_announcement_Active" : ""} py-1 `}>
                            <label className="ms-2 mt-1">
                                <input type="radio" name="announcementOption" className="ms-4 teacher_menu_radio_clr" />
                                <span className='head-dashboard ms-4 '>Schedule</span>
                            </label>
                        </li>
                        <li onClick={goToTeacherScheduleNotification} className={`w-100 mt-2 ${props.userState.subType === "Teachernotification" ? "Teacher_announcement_Active" : ""} py-1`}>
                            <label className="ms-2 mt-1">
                                <input type="radio" name="announcementOption" className="ms-4 " />
                                <span className='head-dashboard ms-4'>Notification</span>
                            </label>
                        </li>
                        <li onClick={goToTeacherAttendence} className={`w-100 mt-2 ${props.userState.subType === "Teacherattendence" ? "Teacher_announcement_Active" : ""} py-1`}>
                            <label className="ms-2 mt-1">
                                <input type="radio" name="announcementOption" className="ms-4" />
                                <span className='head-dashboard ms-4'>Attendance</span>
                            </label>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
}

export default AnnouncementTeacher;
