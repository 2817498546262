import React from 'react';
import img from '../../../../../../images/Ellipse 2 (2).jpg';
import { MdOutlineLogout, MdOutlineVerified } from 'react-icons/md';
import img2 from '../../../../../../images/logos_linkedin-icon.jpg';
import facebook from '../../../../../../images/logos_facebook.jpg';
import twitter from '../../../../../../images/skill-icons_twitter.jpg';
import instagram from '../../../../../../images/skill-icons_instagram.jpg';
import youtube from '../../../../../../images/logos_youtube-icon.jpg';
import { ProfileData } from '../../../../../../redux/slice/studentSlice';
import { useSelector } from 'react-redux';

function AdminProfileComponent() {
    const ProfileDetails = useSelector(ProfileData)

    return (
        <div>
            <div class="container ">
                <div class="row mt-4">
                    <div class="col ">
                        <div class='admin-card py-4 text-center'>
                            <div>
                                <img src={img} class="img-fluid " alt="" />
                            </div>
                            <p class='fw-bold fs-5 mt-3'>David <span><MdOutlineVerified class='admin-verified-btn' /></span></p>
                            <button type="button" class="btn admin-navbar-search-btn text-light px-4 py-1 mt-5 mb-3 "> <span class='me-2'><MdOutlineLogout /></span>Log Out</button>
                        </div>
                        <div class='admin-card mt-3 p-4'>
                            <p class='fs-5 fw-semibold '>Social Network</p>
                            <p>Add your social profiles links, they will be shown on your public profile.</p>
                            <div>
                                <label class='fw-semibold'>Linkedin</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-light" id="basic-addon1">
                                        <img src={img2} class="img-fluid " alt="" />
                                    </span>
                                    <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                                <label class='fw-semibold'>Facebook</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-light" id="basic-addon1">
                                        <img src={facebook} class="img-fluid " alt="" />
                                    </span>
                                    <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                                <label class='fw-semibold'>Twitter</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-light" id="basic-addon1">
                                        <img src={twitter} class="img-fluid " alt="" />
                                    </span>
                                    <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                                <label class='fw-semibold'>Instagram</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-light" id="basic-addon1">
                                        <img src={instagram} class="img-fluid " alt="" />
                                    </span>
                                    <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                                <label class='fw-semibold'>Youtube</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-light" id="basic-addon1">
                                        <img src={youtube} class="img-fluid " alt="" />
                                    </span>
                                    <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class='admin-card p-4'>
                            <p class='fs-5 fw-semibold'>Profile Info</p>
                            <div class="container text-start">
                                <div class="row">
                                    <div class="col">
                                        <label class=' mt-4 fw-semibold'>First Name</label>
                                        <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" value={ProfileDetails?.firstname}/>
                                    </div>
                                    <div class="col">
                                        <label class=' mt-4 fw-semibold'>Last Name</label>
                                        <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" value={ProfileDetails?.lastname}/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class=' mt-4 fw-semibold'>Bio</label>
                                        <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class=' mt-4 fw-semibold'>Email Address</label>
                                        <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1"  value={ProfileDetails?.email}/>
                                    </div>
                                    <div class="col">
                                        <label class=' mt-4 fw-semibold'>Mobile</label>
                                        <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class=' mt-4 fw-semibold'>Qualification</label>
                                        <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    <div class="col">
                                        <label class=' mt-4 fw-semibold'>Work</label>
                                        <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class=' mt-4 fw-semibold'>Country</label>
                                        <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    <div class="col">
                                    </div>
                                </div>
                            </div>

                            <div className="container text-end mt-5">
                                <div className="row">
                                    <div className="col">
                                        <button
                                            type="submit"
                                            className="btn btn-primary px-4 py-1 bootBtn my-5"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminProfileComponent