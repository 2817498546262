import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SavedQuestTestTeacher.scss'
import axios from 'axios';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';
function SavedQuestTestTeacher(props) {
    const [edit, setEdit] = useState()
    const initialTestState = {
        category: '',
        sub_category: '',
        topic: '',
        marks_question: '',
        batch_id: '',
        message: '',
        student_id: '',
        question: [
            {
                questions: '',
                a: '',
                b: '',
                c: '',
                d: '',
                answer: '',
            }
        ],
        doc1: '',
        date: '',
    }
    const [test, setTest] = useState(initialTestState);

    const resetTestState = () => {
        setTest(initialTestState);
    }
    const ToEdit = () => {
        // const createAssignment = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        // formData.append("email", _.get(createAssignment, 'email'));
        formData.append("category", test.category)
        formData.append("sub_category", test.sub_category)
        formData.append('topic', test.topic)
        formData.append('batch_id', test.batch_id)
        formData.append('marks_question', test.marks_question)
        formData.append('message', test.message)
        formData.append('student_id[]', test.student_id)
        formData.append('date', test.date)
        formData.append('questions[0][question]', test.question)
        formData.append('questions[0][a]', test.a)
        formData.append('questions[0][b]', test.b)
        formData.append('questions[0][c]', test.c)
        formData.append('questions[0][d]', test.d)
        formData.append('doc1', test.doc1)
        formData.append('answer[0][answer]', test.answer)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/editTest',
            formData,
            { headers: headers })
            .then((res) => {
                console.log(res)
                const updateData = res.data
                setEdit(updateData)
                Backdashboard()
            });
    }
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])
    const initializeObject = () => {
        setTest({ ...test, ...props.userState.row })
    }
    const [selectedOption, setSelectedOption] = useState('Select an option');
    const [showContent, setShowContent] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showContent0, setShowContent0] = useState(false);
    const [showContent1, setShowContent1] = useState(false);
    const [showContent2, setShowContent2] = useState(false);



    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleButtonClick = () => {
        setShowContent0(true);
    };

    const handleButtonClick1 = () => {
        setShowContent1(true);
    };

    const handleButtonClick2 = () => {
        setShowContent2(true);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        if (name === 'checkbox1') {
            setShowContent(checked);
        } else if (name === 'checkbox2') {
            setShowContent1(checked);
        }
    };


    const goToTeacherNewQuest = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherAddNewQuest' })
        }
    }


    const goToTeacherSavetest = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherTest' })
        }
    }

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        setShowContent(false);
    };
    const handleChange = (e) => {
        setTest({ ...test, [e.target.name]: e.target.value });
    };
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherTest' })
        }
    }
    const [inputFields, setInputFields] = useState(['Question 1'])

    const handleButtonClickNew = () => {
        // Add a new input field to the array
        setInputFields([...inputFields, '']);
    };

    return (
        <div className='main-divHead'>
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Test</div>
            </div>
            <div className='Main-head-test'>Add Test</div>
            <p className='sub-head-test'>Question</p>
            {inputFields.map((value, index) => (
                <div className='dropdown1'>
                    <button className='main-btn' onClick={toggleDropdown}>{`Question ${index + 1}`}</button>
                    {showDropdown && (
                        <div className='dropdown-content'>
                            {showContent0 ? (
                                <div>
                                    <p className='para1-test'>Question</p>
                                    <div className='para1-test1'>
                                        <input type='text' id='question-para' onChange={handleChange} value={test.question.questions} name='questions' />
                                    </div>
                                </div>
                            ) : (
                                <button className='sub-btn' onClick={() => handleButtonClick('Button 1')}>+ Question</button>
                            )}


                            {showContent1 ? (
                                <div>
                                    <p className='para1-test'>Resource</p>
                                    <div>
                                        <input type='file' className='test-file-inp' />
                                    </div>
                                </div>
                            ) : (
                                <button className='sub-btn' onClick={() => handleButtonClick1('Button 2')}>+ Resource</button>
                            )}


                            {showContent2 ? (
                                <div>
                                    <p className='para1-test'>Options</p>
                                    <div>
                                        <p className='options-test'>A</p>
                                        <label className='option-inp-test'>
                                            <input
                                                type="checkbox"
                                                name="checkbox1"
                                                onChange={handleCheckboxChange}
                                            />&nbsp;
                                            Demo 1
                                        </label>
                                    </div>

                                    <div>
                                        <p className='options-test'>B</p>
                                        <label className='option-inp-test'>
                                            <input
                                                type="checkbox"
                                                name="checkbox1"
                                                onChange={handleCheckboxChange}
                                            />&nbsp;
                                            Demo 2
                                        </label>
                                    </div>

                                    <div>
                                        <p className='options-test'>C</p>
                                        <label className='option-inp-test'>
                                            <input
                                                type="checkbox"
                                                name="checkbox1"
                                                onChange={handleCheckboxChange}
                                            />&nbsp;
                                            Demo 3
                                        </label>
                                    </div>

                                    <div>
                                        <p className='options-test'>D</p>
                                        <label className='option-inp-test'>
                                            <input
                                                type="checkbox"
                                                name="checkbox1"
                                                onChange={handleCheckboxChange}
                                            />&nbsp;
                                            Demo 4
                                        </label><br />
                                        <button className='cancel-test-btn'>Cancel</button>
                                        <button className='submit-test-btn' onClick={goToTeacherNewQuest}>save</button>
                                    </div>

                                </div>
                            ) : (
                                <button className='sub-btn' onClick={() => handleButtonClick2('Button 2')}>+ Options</button>
                            )}
                        </div>
                    )}
                </div>
            ))}

            <button className='Quest-test-btn1' onClick={handleButtonClickNew}>+ New Question</button>
            <button className='cancel-test-btn1' onClick={resetTestState}>Cancel</button>
            <button className='submit-test-btn' onClick={(e) => ToEdit()}>update</button>
        </div>
    )
}
export default SavedQuestTestTeacher