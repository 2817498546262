import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import DotsIcon from '../../../Sidebar/icons/DotsIcon.png'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import Modal from 'react-modal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
// import Delete from '../../../../../../images/Delete.png'
import './TeacherSchedule.scss'
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';
import { getCSTData } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch } from 'react-redux';
import { getCategoryListData } from '../../../../../../redux/slice/studentSlice';

function TeacherSchedule(props) {
    const dateTimeString = "2023-07-25 14:30:00";
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const [isactions, setActions] = useState(false)
    const [activeMenu, setActiveMenu] = useState(null)
    const dateTime = new Date(dateTimeString);
    const created_at = dateTime.toLocaleDateString();
    const [showView, setShowView] = useState(false)
    const [rows, setRows] = useState()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([{
        date: '',
        category: '',
        sub_category: '',
        topic: '',
        batch_id: ''
    }]);

    const handleClickActions = (event, rowId, action) => {
        setAnchorEl(event.currentTarget);
        setActions((prevState) => (prevState === rowId ? null : rowId))
        if (action === 'delete') {
            setActiveMenu(rowId);
        } else {
            setActiveMenu(null);
        }
    };

    const handleClose = () => {
        setAnchorEl(null)
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherschedule' })
        }
    }

    const goToTeacherCreateSchedule = (e, row, batch_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'createschedule', row: row, batch_id: batch_id })
        }
        setActions(false)
        setActiveMenu(null)
    }


    const goToTeacherViewSchedule = (e, row, batch_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewschedule', row: row })
        }
        setActions(false)
        setActiveMenu(null)
    }

    const handleIconClick = () => {
        setShowView(!showView);
    };


    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };


    // Data table structure
    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {

            name: 'Date',
            selector: row => row.from

        },
        {
            name: 'Category',
            selector: row => row.category,



        },
        {
            name: 'Sub category',
            selector: row => row.sub_category,


        },
        {
            name: 'Topic',
            selector: row => row.topic,


        },
        // {
        //     name: 'Batch',

        //     selector: row => row.batch_id
        // },
        {
            name: 'Action',
            cell: (row, batch_id) => (
                <div className={`Teacher test-actions ${isactions == row.id ? '' : 'active'}`}>
                    <Button className="action-subj d-flex" id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e) => handleClickActions(e, row.id, 'menu')}>
                        <MoreVertIcon />
                    </Button>

                    <div>
                    <Menu
                        className="dots-actions"
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                            style: {
                                width: '150px', // Adjust the width as needed
                              
                                
                            },
                        }}
                    >

                            <MenuItem onClick={(e) => goToTeacherViewSchedule(e, row, batch_id)} disableRipple className="ul-for-options pt0">
                                View
                            </MenuItem>
                            <MenuItem onClick={(e) => goToTeacherCreateSchedule(e, row, batch_id)} disableRipple className="ul-for-options">
                                Edit
                            </MenuItem>
                            <MenuItem disableRipple className="ul-for-options" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                Delete
                            </MenuItem>

                            {/* <button type="button" class="btn btn-primary" >
                                Delete
                            </button> */}

                        </Menu>
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content p-5">
                                    {/* <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="exampleModalLabel">Do you want Delete ?</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div> */}
                                    <div class=" justify-content-center ms-5">
                                        <h1 class="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want Delete ?</h1>
                                        <button type="button" class="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal" >Close</button>
                                        <button type="button" class="btn btn-primary mt-3 ms-5 px-4" data-bs-dismiss="modal" onClick={() => { deleteRow(row.batch_id) }}>Delete</button>
                                    </div>
                                </div>
                                {/* <div class="modal-footer">
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    ]

    useEffect(() => {

        fetchSchedule()

    }, [])

    useEffect(() => {
        getRows();
    }, [data]);


    const fetchSchedule = () => {

        // const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') }


        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/listSchedule', undefined, { headers: headers })
            .then((response) => {

                console.log(response);
                // setData(response.data.data);
                // setLoading(false);


                if (_.get(response, 'data.data')) {
                    setData(response.data.data);
                    setLoading(false);
                    
                }

                const formattedData = response.data.data.map((item) => ({
                    ...item,
                    from: format(new Date(item.from), 'dd-MM-yyyy'),
                }));

                setData(formattedData);
                setLoading(false);

            })

            .catch((error) => {
                console.error('Error fetching Subjects:', error);
                setLoading(false);

            })

    }


    const getRows = () => {
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        let res = [];
        res = data.map((item, index) => {
            return ({
                from: item.from,
                category: item.category,
                sub_category: item.sub_category,
                topic: item.topic,
                batch_id: item.batch_id,
            })
        })
        setRows(res);
    }
    const deleteRow = (batch_id) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/deleteSchedule', { batch_id }, { headers: headers })
            .then(() => {
                
                
                setData((prevData) => prevData.filter((item) => item.batch_id !== batch_id));

            })
            .catch((error) => {
                console.error('Error deleting row:', error);
            });
    };
    const dispatch = useDispatch();
    useEffect(() => {
        const payload = {
            "type": 1
        }
        dispatch(getCategoryListData(payload));
    }, []);

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Schedule</div>
            </div>
            <button className='btn-create-schedule' onClick={(e) => goToTeacherCreateSchedule(e)}>Create Schedule</button>

            <div  class="col-xxl-11 col-xl-12 col-lg-12 mt-3 ms-5 justify-content-center">

                <DataTable
                    data={data}
                    columns={columns}
                    customStyles={customStyles}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="600px"
                    striped
                    persistTableHead
                    pagination
                />

            </div>

        </div>
    )
}

export default TeacherSchedule
