import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SMTPSettings } from '../../../../../../redux/slice/settingsSlice';
import { useDispatch } from 'react-redux';

function AdminSMTPSettingsComponent() {

    const dispatch = useDispatch();
    const SmtpValidationSchema = Yup.object().shape({
        protocol: Yup.string().required('protocol is required'),
        smtpHost: Yup.string().required('SMTP Host is required'),
        smtpPort: Yup.number()
            .typeError('SMTP port must be a number')
            .required('SMTP port is required'),
        smtpUsername: Yup.string().required('SMTP username is required'),
        smtpPassword: Yup.string().required('SMTP password is required'),
        encryption: Yup.string().required('encryption is required')
    });


    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Settings</div>
            </div>
            <div>
                <Formik
                    initialValues={{
                        protocol: '',
                        smtpHost: '',
                        smtpPort: '',
                        smtpUsername: '',
                        smtpPassword: '',
                        encryption: ''
                    }}
                    validationSchema={SmtpValidationSchema}
                    onSubmit={(values, { resetForm }) => {
                        const payload = {
                            "host": values.smtpHost,
                            "port": values.smtpPort,
                            "username": values.smtpUsername,
                            "password": values.smtpPassword,
                            "encryption": values.encryption,
                            "from_address": values.protocol,
                        }
                        dispatch(SMTPSettings(payload));
                        resetForm();

                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="container text-start my-5">
                                <p className="ms-5 admin-sub-head fw-bold">SMTP Settings</p>
                                <div className="container">
                                    <div className="row">
                                        <div className="d-flex justify-content-center">
                                            <div className="col-xxl-6 col-xl-6 col-lg-10">
                                                <label className="fs-6 mt-4 fw-semibold">From Address</label>
                                                <Field
                                                    type="text"
                                                    name="protocol"
                                                    className="form-control"
                                                    placeholder="send mail"
                                                />
                                                <ErrorMessage
                                                    name="protocol"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                                <label className="fs-6 mt-4 fw-semibold">SMTP Host</label>
                                                <Field
                                                    type="text"
                                                    name="smtpHost"
                                                    className="form-control"
                                                    placeholder="ssl://smtp.googlemail.com"
                                                />
                                                <ErrorMessage
                                                    name="smtpHost"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                                <label className="fs-6 mt-4 fw-semibold">SMTP Port</label>
                                                <Field
                                                    type="text"
                                                    name="smtpPort"
                                                    className="form-control"
                                                    placeholder="102"
                                                />
                                                <ErrorMessage
                                                    name="smtpPort"
                                                    component="div"
                                                    className="text-danger"
                                                />

                                                <label className="fs-6 mt-4 fw-semibold">SMTP Username</label>
                                                <Field
                                                    type="text"
                                                    name="smtpUsername"
                                                    className="form-control"
                                                    placeholder="smtp-user"
                                                />
                                                <ErrorMessage
                                                    name="smtpUsername"
                                                    component="div"
                                                    className="text-danger"
                                                />

                                                <label className="fs-6 mt-4 fw-semibold">SMTP Password</label>
                                                <Field
                                                    type="text"
                                                    name="smtpPassword"
                                                    className="form-control"
                                                    placeholder="smtp-password"
                                                />
                                                <ErrorMessage
                                                    name="smtpPassword"
                                                    component="div"
                                                    className="text-danger"
                                                />


                                                <label className="fs-6 mt-4 fw-semibold">Encryption</label>
                                                <Field
                                                    type="text"
                                                    name="encryption"
                                                    className="form-control"
                                                    placeholder="encryption"
                                                />
                                                <ErrorMessage
                                                    name="encryption"
                                                    component="div"
                                                    className="text-danger"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container text-center">
                                    <div className="row">
                                        <div className="col">
                                            <button
                                                type="submit"
                                                className="btn text-light px-5 py-1 bootBtn my-5"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        </div>
    )
}

export default AdminSMTPSettingsComponent