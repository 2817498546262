import React, { useState } from "react"
import _ from "lodash"
import { GiNotebook } from "react-icons/gi";

function TestResults({ ...props }) {
  const goToTest = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Test' })
    }
  }
  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'Test' ? 'active' : ''} `} onClick={(e) => goToTest(e)}>
      <div className="dash d-flex">
      <GiNotebook className="ms-3"/>
        <p className='head-dashboard ms-2 mb-0' >My Test</p>
      </div>
    </div>
  )
}

export default TestResults
