import React, { useState, useEffect } from 'react';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import { ErrorMessage, Field, Form, Formik } from 'formik';

function AddTopic(props) {

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'addsubject' });
        }
    }

    return (
        <div className='main-divHead'>
            <div className='sub-Head1'>
                <FontAwesomeIcon icon={faChevronLeft} onClick={Backdashboard} />
                <div className='Sub-Head2' >Add Topic</div>
            </div>
            <div className='p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-4'>
                <h4 className='fw-bold'>Add Topic</h4>
                <Formik
                >
                    <Form>
                        <div className="container ">
                            <div className="row">
                                <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3'>
                                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                        <div className="form-group">
                                            <label className='admin-label mt-4' htmlFor="adminCategory">Category</label>
                                            <Field
                                                type="text"
                                                name="category"
                                                // value={currentCategoryName} // Set the value to the categoryName                                                             
                                                className="form-control admin-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3'>
                                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                        <div className="form-group">
                                            <label className='admin-label' htmlFor="subCategory">Sub Category / Class</label>
                                            <Field
                                                type="text"
                                                name="subCategory"
                                                // value={selectedItem.name} // Set the value to the categoryName                                                             
                                                className="form-control admin-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3'>
                                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                        <label className='admin-label'>Subject / Topic</label>
                                        <Field type="text" name="subjectTopic" className="form-control admin-input" />
                                        <ErrorMessage name="subjectTopic" component="div" className="text-danger fs-6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container text-center">
                            <div className="row">
                                <div className="col">
                                    <button
                                        type="submit"
                                        className="btn btn-primary text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                                    // disabled={loading}
                                    >
                                        {/* {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                                            strokeColor="white"
                                            strokeWidth="5"
                                            animationDuration="0.75"
                                            width="25"
                                            height="25"
                                            visible={true}
                                        /></div> : "Save"} */}
                                        save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default AddTopic