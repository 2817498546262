import React from 'react';
import DashboardFreelance from './components/DashboardFreelance';

function FreelanceSideBar({ ...props }) {

  return (
    <div>
      <DashboardFreelance {...props} />


    </div>
  )
}

export default FreelanceSideBar