import React from "react";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MainSection from "./LoggedInComponents/MainSection";
import Sidebar from "./LoggedInComponents/Sidebar";
import "./style.scss";
import _ from "lodash";

function LoggedIn({ props }) {
  const navigate = useNavigate();
  const location = useLocation();
  let state = useState(_.get(location, "state"));
  if (_.isUndefined(props)) {
    props = {};
    props.type = _.get(state[0], "type");
    props.subType = _.get(state[0], "subType");
  }

  const [userState, setUserState] = useState({
    type:
      _.get(props, "type") ||
      "Teacherpage" ||
      "courseupload" ||
      "Freelancepage" ||
      "admin" ||
      "FreelancerPage",
    subType: _.get(props, "subType") || "Dashboard",
  });

  return (
    <div className="loggedIn_container">
      <Sidebar className="Sidebar" userState={userState} setUserState={setUserState} />
      <MainSection className="MainSection" userState={userState} setUserState={setUserState} />
    </div>
  );
}

export default LoggedIn;
