import React from 'react'
import assignmentWhiteIcon from "../../../icons/assignmentWhiteIcon.png"
import assignmentBlueIcon from "../../../icons/assignmentBlueIcon.png"
import _ from 'lodash';
import { PiStudentBold } from 'react-icons/pi';

function StudentComponent(props) {
    const goToTeacherStudent = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminStudent' })
        }
    }
    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'adminStudent':
            case 'adminStudentDetails':
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };
    return (
        <div className={determineClassName()} >
            <PiStudentBold class='ms-3' />
            <div className='head-dashboard ms-2' onClick={(e) => goToTeacherStudent(e)}>Students</div>
        </div>
    )
}

export default StudentComponent