import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DataTable from 'react-data-table-component';
import { FaTriangleExclamation } from "react-icons/fa6";
import { fetchTaxList } from '../../../../../../redux/slice/settingsSlice';
import axios from 'axios';
import { format } from "date-fns";
import { showErrorToast, showSuccessToast } from '../../../../../../util/toastUtils';

function AdminTaxSettingsComponent(props) {

    const [loading, setLoading] = useState(true)
    const [tax, setTaxData] = useState([]);


    const goToAddTax = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminAddTax' });
        }
    };
    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    // useEffect(() => {
        
    //     dispatch(fetchTaxList());
    // }, []);
    
    const sampleData = [
        {
            id: 1,
            created_at: "2022-02-22",
            Tax: "Income Tax",
            value: 1000
        },
        {
            id: 1,
            created_at: "2022-02-22",
            Tax: "Income Tax",
            value: 1000
        },
        {
            id: 1,
            created_at: "2022-02-22",
            Tax: "Income Tax",
            value: 1000
        },
        {
            id: 1,
            created_at: "2022-02-22",
            Tax: "Income Tax",
            value: 1000
        },
        {
            id: 1,
            created_at: "2022-02-22",
            Tax: "Income Tax",
            value: 1000
        },
        // Add more sample data as needed
    ];
    const listTaxSettings = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        axios.post("http://tuition.techiesmarkets.com/backend/public/api/getTaxSettings", undefined, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    setTaxData(response.data.data);
                    setLoading(false)
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
                setLoading(false);
            });
    };
    const deleteTaxSettings = (index) => {
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
        const taxToDelete = tax[0].name;
        // Create FormData and append the tax name
        const formData = new FormData();
        formData.append('name', taxToDelete);
        axios.post("http://tuition.techiesmarkets.com/backend/public/api/deleteTaxSettings", formData, { headers: headers })
            .then((response) => {
                // Update tax data if needed
                setTaxData(response.data.data);
                setLoading(false);
                showSuccessToast("Tax deleted successfully");
                listTaxSettings();
            })
            .catch((error) => {
                console.error('Error deleting tax:', error);
                // Handle error appropriately, e.g., show error toast
                setLoading(false);
                // Show an error toast
                // showErrorToast("Failed to delete tax");
            });
    };




    const ChangeStatusTaxSettings = (index) => {
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
        const taxToDelete = tax[0].name;
        const taxToStatus = tax[0].status;
        // Create FormData and append the tax name
        const formData = new FormData();
        formData.append('name', taxToDelete);
        formData.append('status', taxToStatus);
        
        axios.post("http://tuition.techiesmarkets.com/backend/public/api/changeStatusTaxSettings", formData, { headers: headers })
            .then((response) => {
                // Update tax data and loading state
                setTaxData(response.data.data);
                setLoading(false);
                showSuccessToast("Change Status successfully");
                listTaxSettings();
            })
            .catch((error) => {
                console.error('Error changing tax status:', error);
                // Set loading state to false
                setLoading(false);
                // Show error toast
                showErrorToast("Failed to change status");
            });
    };
    
    useEffect(() => {
        listTaxSettings();
    }, []);
    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
                textAlign: "center"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontWeight: "bold"
            }
        },
    };
    const columns = [
        {
            name: 'Date',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.created_at ? dateFunction(row.created_at) : ""}
                    </div>
                );
            }
        },
        {
            name: 'Tax Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Tax Value',
            selector: row => row.value,
            sortable: true
        },
        {
            name: 'Action',
            sortable: false,
            cell: (row, subData, rowIndex, id) => (
                <div className='dropdown admin_toggle'>
                    <BsThreeDotsVertical data-bs-toggle="dropdown" aria-expanded="false" />
                    <ul className="dropdown-menu mt-3">
                        <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModalActive">Active</a></li>
                        <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModal" >Delete</a></li>
                    </ul>
                </div>
            )
        }
    ];

    return (
        <div className='main-divHead'>
            <div className='sub-Head1'>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Settings</div>
            </div>
            <div>
                <div className="container text-start my-5">
                    <p className='admin-sub-head fw-bold ms-5'>Tax Settings</p>
                    <div className='row ms-5'>
                        <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12'>
                            <button type="button" className="btn text-light px-4 py-1 bootBtn" onClick={(e) => goToAddTax()}>Create Tax</button>
                        </div>
                    </div>
                    <DataTable
                        className='dataTable mt-5'
                        data={tax}
                        columns={columns}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="600px"
                        striped
                        customStyles={customStyles}
                        persistTableHead
                        pagination
                    />
                </div>
                <div class="modal fade" id="exampleModalActive" tabindex="-1" aria-labelledby="exampleModal" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content p-5">
                            <div class=" justify-content-center ms-5">
                                <h1 class="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want Active?</h1>
                                <button type="button" class="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal" >close</button>
                                <button type="button" class="btn btn-primary mt-3 ms-5 px-4" data-bs-dismiss="modal"  onClick={(row) => ChangeStatusTaxSettings(row)}>Active</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content p-5">
                            <div class=" justify-content-center ms-5">
                                <h1 class="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want Delete ?</h1>
                                <button type="button" class="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary mt-3 ms-5 px-4" data-bs-dismiss="modal" onClick={(row) => deleteTaxSettings(row)}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AdminTaxSettingsComponent;