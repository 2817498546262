import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SelectWho from "./SelectWho";
import './Section3_ChooseWhoYouAre.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faStar, faUsers, faCalendarDays, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FaArrowRight } from "react-icons/fa";
import { IoTimeSharp } from "react-icons/io5";
import _ from "lodash";
import Rectangle4467 from "../../../images/landing_page_images/Rectangle4467.jpg";
import { Button, Grid, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import Axios from "../../../util/apiUtil";
import BASE_URL from "../../../BaseUrl";
import { TbMessageLanguage } from "react-icons/tb";
import { MdDescription } from "react-icons/md";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function DisplayContent(props) {
  const navigate = useNavigate();
  const [featured, setFeatured] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.post(`api/search`, { search: searchQuery });
      const data = await response.data; // No need to call response.json() as Axios already returns JSON data
      if (data.success) {
        setFeatured(data.data.subject);
        setError(null);
      } else {
        setError(data.error);
        setFeatured([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('An error occurred while fetching data.');
      setFeatured([]);
    }
  };
  const handleViewDetails = (videoLink) => {
    window.open(videoLink, '_blank');
  };
  const basePath = 'http://tuitionguruji.com/backend/public/';
  return (
    <>
      <div className="mt-5">
        <h4 className="who-you d-flex">What are you looking for?</h4>
      </div>
      <SelectWho />
      <div className="container my-5">
        <div className="row">
          <div className="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center">
            <div className="col-lg-4 mt-5">
              <div className="landing-card p-5 mx-5">
                <div className="d-flex justify-content-center">
                  <div className="note-icon">
                    <FontAwesomeIcon className="fa-note" style={{ color: "white" }} icon={faUsers} />
                  </div>
                </div>
                <div className="title ms-0">
                  <h2 className="card-head">Online Billing, & <br />Quotes</h2>
                  <div className="card-content">
                    <p className="card-p">
                      Amet minim mollit non deserunt ullamco est sit
                      aliqua dolor do amet sint.
                      Velit officia consequat duis enim velit mollit.
                      Exercitation veniam consequat sunt nostrud amet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-5">
              <div className="landing-card p-5 mx-5">
                <div className="d-flex justify-content-center">
                  <div className="note-icon-schedule">
                    <FontAwesomeIcon className="fa-calender ms-1" style={{ color: "#F5F5FC" }} icon={faCalendarDays} />
                  </div>
                </div>
                <div className="title ms-0">
                  <h2 className="card-head" style={{ color: "#00CBB8" }}>Easy Scheduling & <br /> Attending Classes</h2>
                  <div className="card-content">
                    <p className="card-p">
                      Amet minim mollit non deserunt ullamco est sit
                      aliqua dolor do amet sint.
                      Velit officia consequat duis enim velit mollit.
                      Exercitation veniam consequat sunt nostrud amet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="featured-class">
        <div className="featured-view d-flex ps-5 m-4">
          {/* <h2 className="featured-head">
            Featured Classes
          </h2> */}
          {/* <div className="view-all d-none d-xxl-flex d-xl-flex d-lg-flex d-md-flex pe-5">
            <Link>View All</Link>
            <div className="FaArrowRight"><FaArrowRight /></div>
          </div> */}
        </div>
        <div className="d-flex justify-content-center mt-5">
          <div className="col-3 me-5">
            <div className="both search-container">
              <div className="search">
                <input
                  name="text"
                  className="what-learn"
                  type="text"
                  placeholder="What do you want to learn?"
                  value={searchQuery}
                  onChange={handleInputChange}
                />
              </div>
              <div className="search_icon">
                <button
                  type="button"
                  className="btn-primary search-button"
                  id="volsavebtn"
                  onClick={handleSearch}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-start my-5">
          <div className="row mt-5 mx-xxl-0 mx-xl-0 mx-lg-5">
            {featured.length > 0 ? (
              featured.map((card, index) => (
                <div key={card.id} className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-4">
                  <div className="landing-card p-2">
                    <div>
                    <img className="img-fluid"  style={{ width: '750px', height: '200px' }} src={card.image ?`${basePath}${card.image}` : Rectangle4467} alt={card.name} />
                    </div>
                    <p className="landing-card-head mt-2 ms-2 mb-0 ">{card.name}</p>
                    <div className="rating text-left">
                     


                      <p className="mb-0 fw-semibold ms-2"><span className="me-1 "><TbMessageLanguage  icon={faGraduationCap} style={{ width: "15px" }} /></span> Language: {card.language}</p>
                      <p className="mb-0 fw-semibold ms-2"><span className="me-1 "><MdDescription   icon={faGraduationCap} style={{ width: "15px" }} /></span> Description: {card.description}</p>
                      <p className="mb-0 fw-semibold ms-2"><span className="me-1 "><MdDescription   icon={faGraduationCap} style={{ width: "15px" }} /></span> Tutor: {card.teacher}</p>
                      <div className="container text-center my-3">
                        <div className="row">
                        <div className="col">
                        <button
                            className="btn outline-btn-admin px-xxl-3 px-xl-3 px-lg-1 fw-bold rounded-pill text-center view-details-btn"
                            onClick={() => handleViewDetails(card.video_link)}
                          >Resources</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
            <p></p>
            )}
          </div>
        </div>
      </div>
      {error && (
        <div className="error-message text-center my-3">
          <p>{error}</p>
        </div>
      )}
    </>
  );
}

export default DisplayContent;
