// counterSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../util/apiUtil";
import { showErrorToast, showSuccessToast } from "../../util/toastUtils";

const initialState = {
  user: null,
  isAuthenticated: [],
};


export const UserLogin = createAsyncThunk(
  "auth/UserLogin",
  async (payload, thunkApi) => {
    try {
      const response = await Axios.post("/api/login", payload);
      console.log("response -> UserLogin", response);
      return response;
    } catch (error) {
      console.log("axios error", error);
      throw error;
    }
  }
);

export const userLogout = createAsyncThunk(
  "auth/userLogout",
  async (payload, thunkApi) => {
    try {
      const authToken = payload.authToken; // Assuming you pass the authToken in the payload
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await Axios.post("/api/logout", null, { headers });

      // Remove token from local storage
      localStorage.removeItem("token");
      // Remove user data from local storage
      localStorage.removeItem("user");
      showSuccessToast("Logout Successful");
      payload.navigate("/user/sign_in");
      return response;
    } catch (error) {
      console.log("axios error", error);
      throw error;
    }
  }
);

export const sendEmail = createAsyncThunk(
  "auth/sendEmail",
  async (payload, thunkApi) => {
    console.log("resend",payload);
    try {
      const response = await Axios.post("/api/sendEmailotp", payload);
      console.log("response -> UserLogin", response);
      // Check if there's an error and if it's related to the email being taken
      if (response.data.error && response.data.error.error && response.data.error.error.includes("The email has already been taken.")) {
        showErrorToast("The email has already been taken.");
      } else {
        showSuccessToast("OTP sent successfully");
        payload.navigate("/user/verify");
      }
      return response;
    } catch (error) {
      console.log("axios error", error);
      throw error;
    }
  }
);


export const EmailVerification = createAsyncThunk(
  "auth/EmailVerification",
  async (payload, thunkApi) => {
    try {
      const response = await Axios.post("/api/verifyEmailOtp", payload.payload);
      console.log("response -> EmailVerification", response);
      if (response.data.error.length > 0) {
        showErrorToast(response.data.error[0]);
      } else {
       
        showSuccessToast("OTP Verified Successfully");
        payload.navigate("/user/sign_up_as");
      }
      return response;
    } catch (error) {
      console.log("axios error", error);
      throw error;
    }
  }
);

export const studentRegistration = createAsyncThunk(
  "auth/StudentRegistration",
  async (payload, { dispatch, getState, extra, requestId, signal, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await Axios.post("/api/register", payload);
      console.log("response -> StudentRegistration", response.data);

      const { professional, token, user } = response.data.data;

      if (professional === 1) { // If professional is 1, it's a Teacher
        localStorage.setItem('token', `${token}`);
        localStorage.setItem('user', JSON.stringify(user));
        payload.navigate("/teacher", { state: { type: 'Teacherpage', subType: 'DashboardTeacher' } });
        return response.data; // Return the response data for handling success
      } else if (professional === 2) { // If professional is 2, it's a Student
        localStorage.setItem('token', `${token}`);
        localStorage.setItem('user', JSON.stringify(user));
        payload.navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
        return response.data; // Return the response data for handling success
      } else {
        return rejectWithValue("Invalid professional type"); // Return error message for invalid professional type
      }
    } catch (error) {
      return rejectWithValue("Register Failed"); // Return general error message for handling error
    }
  }
);






const authSlice = createSlice({
  name: "auth",
  initialState: {
    Token: "",
    userData: [],
    chatData: [],
    mail: [],
    register: [],
    logout: [],
  },
  reducers: {
    Login: (state, action) => {
      return {
        ...state,
        Token: action.payload,
      };
    },
    Logout: (state, action) => {
      return {
        ...state,
        Token: action.payload,
      };
    },
    Register: (state, action) => {
      return {
        ...state,
        Token: action.payload,
      };
    },

    UserData: (state, action) => {
      return {
        ...state,
        userData: action.payload,
      };
    },
    ChatData: (state, action) => {
      localStorage.setItem("chatData", JSON.stringify(action.payload))
      return {
        ...state,
        chatData: action.payload,
      };
    },
    currentEmail: (state, action) => {
      return {
        ...state,
        mail: action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(studentRegistration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(studentRegistration.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(studentRegistration.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(userLogout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(userLogout.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { Login, UserData, ChatData, currentEmail, Register, Logout } =
  authSlice.actions;

export const authToken = (state) => state.auth.Token;
export const LoginUserData = (state) => state.auth.userData;
export const UserChatData = (state) => state.auth.chatData;
export const UserMail = (state) => state.auth.mail;
export const UserRegister = (state) => state.auth.register;
export const UserDLogout = (state) => state.auth.logout;

export default authSlice.reducer;
