import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import './TeacherAddTest.scss'
import axios from "axios";
import { Grid } from "@mui/material";
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowRight } from "react-icons/fa6";
import { showErrorToast, showSuccessToast } from "../../../../../../util/toastUtils"
import { Formik, Field, Form, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import { InputGroup } from 'react-bootstrap';
import Select from "react-select";
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function TeacherAddTest(props) {

    const [Que, setQue] = useState(false);
    const [edit, setEdit] = useState()

    const [testQuestion, setTestQuestion] = useState([{}]);



    // Remove empty objects
    const newArrayWithoutEmpty = testQuestion?.filter((obj) => Object.keys(obj).length > 0);

    const removeDuplicates = (arr) => {
        const uniqueObjects = arr?.filter((obj, index, self) =>
            index === self.findIndex((o) => JSON.stringify(o) === JSON.stringify(obj))
        );
        return uniqueObjects;
    };
    // Remove duplicates
    const uniqueArray = removeDuplicates(newArrayWithoutEmpty);


    console.log("uniqueArray", uniqueArray);



    const [test, setTest] = useState({
        category: '',
        sub_category: '',
        topic: '',
        marks_question: '',
        batch_id: '',
        message: '',
        student_id: '',
        question: [
            {
                question: '',
                a: '',
                b: '',
                c: '',
                d: '',
                answer: '',
                doc: '',
            }
        ],
        date: '',
        question_id: ''
    })






    const TestValidationSchema = Yup.object().shape({
        category: Yup.string().required('category is required'),
        sub_category: Yup.string().required('sub Category is required'),
        topic: Yup.string().required('topic is required'),
        batch_id: Yup.string().required('batch is required'),
        message: Yup.string().required('message is required'),
        student_id: Yup.string().required('student is required'),
        marks_question: Yup.string().required(' marks_question is required'),
        question_id: Yup.string().required('question_id is required'),
        date: Yup.string().required(' date is required'),
        doc: Yup.string().required('doc is required'),
        a: Yup.string().required('a is required'),
        b: Yup.string().required('b is required'),
        c: Yup.string().required('c is required'),
        d: Yup.string().required('d is required'),
        question: Yup.string().required('question is required'),

    });
    const [topic, setTopic] = useState([{}])
    const [subCategories, setSubCategories] = useState([{}])
    const [categories, setCategories] = useState([{}])
    const [loading, setLoading] = useState(true)
    const [batches, setBatches] = useState([])
    const [studentList, setStudentList] = useState([])
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [totalList, setTotalList] = useState([]);
    const [question, setQuestion] = useState([])
    const [option, setOption] = useState([])
    const [answer, setAnswer] = useState([])
    const [subject, setSubject] = useState([])
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [topicOptions, setTopicOptions] = useState([]);

    console.log({ studentList });

    const listSubject = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }

        // const createSchedule = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/listSubject', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubjectObject = response.data.data;
                    const SubjectArray = Object.values(SubjectObject);
                    setSubject(SubjectArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
                setLoading(false);
            });
    };

   
    const listBatches = () => {
        // Make sure your token is properly retrieved and stored
        const token = localStorage.getItem('token');
        if (!token) {
            // Handle token not available
            return;
        }

        const headers = { Authorization: 'Bearer ' + token };

        Axios.post('/api/listBatch', null, { headers })
            .then((response) => {
                if (response.data.success) {
                    const batchData = response.data.data.batch;
                    setBatches(batchData);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching batches:', error);
                setLoading(false);
            });
    };

    const listStudents = (batchId) => {


        console.log("batchId", batchId);

        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const formData = new FormData();
        formData.append('id', batchId || 0);
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/listBatchStudents', formData, { headers: headers })
            .then((response) => {
                if (response.data.data) {
                    const studentListData = response.data.data;

                    // Convert the object of student names and IDs into an array of objects
                    const studentListArray = Object.keys(studentListData).map(studentName => ({
                        value: studentListData[studentName],
                        label: studentName,
                    }));
                    setStudentList(studentListArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching students:', error);
                setLoading(false);
            });
    };


    useEffect(() => {
        listBatches()
        listStudents()
        listSubject()
    }, [])




    const listSubCategories = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
        }

        const createSchedule = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {}
        const formData = new FormData()
        formData.append('type', 2)

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/getCategoryandTopicDetails', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubcategoriesObject = response.data.data;
                    const SubcategoriesArray = Object.values(SubcategoriesObject)
                    setSubCategories(SubcategoriesArray)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error)
                setLoading(false)
            })
    }



    const ToSubmit = () => {
        setQue(false);
        const CreateBatch = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("email", _.get(CreateBatch, 'data.email'));
        formData.append("category", test.category)
        formData.append("sub_category", test.sub_category)
        formData.append('topic', test.topic)
        formData.append('batch_id', test.batch_id)
        formData.append('marks_question', test.marks_question)
        formData.append('message', test.message)

        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("student_id[]", record.value || []);
        }

        for (let i = 0; i < uniqueArray.length; i++) {

            formData.append(`questions[${i}][question]`, uniqueArray[i].question)
            formData.append(`questions[${i}][a]`, uniqueArray[i].a)
            formData.append(`questions[${i}][b]`, uniqueArray[i].b)
            formData.append(`questions[${i}][c]`, uniqueArray[i].c)
            formData.append(`questions[${i}][d]`, uniqueArray[i].d)
            formData.append(`doc${i}`, uniqueArray[i].doc)
            formData.append(`answer[${i}][answer]`, uniqueArray[i].ans)
        }
        formData.append('date', test.date)

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/createTest',
            formData,
            { headers: headers })

            .then((res) => {

                if (res.data.success) {
                    const createData = _.get(res, 'data.data.createData') ? JSON.stringify(res.data.data.createData) : undefined;
                    const token = _.get(res, 'data.data.token') ? JSON.stringify(res.data.data.token) : undefined;
                    if (createData) {
                        localStorage.setItem("createData", JSON.stringify(res.data.data.createData));
                    }
                    if (token) {
                        localStorage.setItem("token", (res.data.data.token));
                    }
                    Backdashboard()
                    showSuccessToast("Test created successfully");
                }
                console.log(res.data)

            });
    };

    const ToEdit = () => {
        setQue(false);
        const createAssignment = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("email", _.get(createAssignment, 'email'));
        formData.append("category", test.category)
        formData.append("sub_category", test.sub_category)
        formData.append('topic', test.topic)
        formData.append('batch_id', test.batch_id)
        formData.append('marks_question', test.marks_question)
        formData.append('message', test.message)
        // formData.append('student_id[]', test.student_id)

        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("student_id[]", record.value || []);
        }

        console.log("uniqueArray", uniqueArray);

        for (let i = 0; i < uniqueArray.length; i++) {

            formData.append(`questions[${i}][question]`, uniqueArray[i].question)
            formData.append(`questions[${i}][a]`, uniqueArray[i].a)
            formData.append(`questions[${i}][b]`, uniqueArray[i].b)
            formData.append(`questions[${i}][c]`, uniqueArray[i].c)
            formData.append(`questions[${i}][d]`, uniqueArray[i].d)
            formData.append(`doc${i}`, uniqueArray[i].doc)
            formData.append(`answer[${i}][answer]`, uniqueArray[i].ans)
        }
        formData.append('date', test.date)

        formData.append('question_id', test.question_id)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/editTest',
            formData,
            { headers: headers })
            .then((res) => {
                console.log(res)
                const updateData = res.data
                setEdit(updateData)
                Backdashboard()
                showSuccessToast("Edit Test created successfully");

            });
    }

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])

    const initializeObject = () => {
        setTest({ ...test, ...props.userState.row })
    }

    useEffect(() => {
        ToSubmit()
    }, [])

    console.log('test', test)

    // const Backdashboard = () => {

    //     if (Que) {
    //         setQue(false);
    //     }

    //     else if (_.get(props, 'setUserState')) {
    //         props.setUserState({ ...props.userState, subType: 'TeacherTest' })
    //     }
    // }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherTest' })
        }
    }

    const goToTeacherAddQuest = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'addquest' })
        }
    }

    const [selectedOption, setSelectedOption] = useState('Select an option')
    const [showContent, setShowContent] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [showContent0, setShowContent0] = useState(false)
    const [showContent1, setShowContent1] = useState(false)
    const [showContent2, setShowContent2] = useState(false)



    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleButtonClick = () => {
        setShowContent0(true);
    };

    const handleButtonClick1 = () => {
        setShowContent1(true);
    };

    const handleButtonClick2 = () => {
        setShowContent2(true);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        if (name === 'checkbox1') {
            setShowContent(checked);
        } else if (name === 'checkbox2') {
            setShowContent1(checked);
        }
    };


    const goToTeacherNewQuest = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherAddNewQuest' })
        }
    }


    const goToTeacherSavetest = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherTest' })
        }
    }

    // const toggleDropdown = () => {
    //     setShowDropdown(!showDropdown);
    //     setShowContent(false);
    // };


    const Qfun = () => {
        console.log("test", test);
        console.log("*****************************");
        const payload = {
            "question": test.question,
            "a": test.a,
            "b": test.b,
            "c": test.c,
            "d": test.d,
            "ans": test.answer,
            "doc": test.doc
        }

        if (test.a) {
            setTestQuestion([...testQuestion, payload]);
        }


    }

    const toggleDropdown = (index) => {
        Qfun();
        setShowDropdown(!showDropdown);
        setOpenDropdownIndex(index);
    };



    const [errors, setErrors] = useState([""]);
    const CategoryOptions = ['category1', 'catgory2', 'category3', 'category4']
    const SubCategoryOptions = ['subcategory1', 'subcategory2', 'subcategory3', 'subcategory4']
    const TopicOptions = ['chapter1', 'chapter2', 'chapter3', 'chapter4']
    const BatchOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
    const StudentOptions = ['1', '2', '3', '4']
    const markOptions = ['10', '20', '30']

    const handleInputImage = (e) => {
        const { name, value, type, files } = e.target;
        if (type === "file" && files.length > 0) {
            // Handle file input changes and create File object
            const selectedFile = files[0];
            setTest({
                ...test,
                [name]: selectedFile, // Set the File object
            });
        } else {
            // Handle other input changes
            setTest({
                ...test,
                [name]: value,
            });
        }
        setErrors({ ...errors, [name]: " " });
    };
    const handleChange = (e) => {
        setTest({ ...test, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: " " });


        console.log("e.target.value", e.target.value);

        if (e.target.name === "batch_id") {
            // Update the student list based on the selected batch
            listStudents(e.target.value);
        }
    };


    const [showNewQuestion, setShowNewQuestion] = useState(false);
    const [showCancel, setShowCancel] = useState(false);

    const handleSaveClick = () => {
        setShowNewQuestion(true);
        setShowCancel(true);
    };

    const [inputFields, setInputFields] = useState(['Question 1'])

    const handleButtonClickNew = () => {
        // Add a new input field to the array
        setInputFields([...inputFields, '']);
    };

    const handleInputChange = (index, value) => {
        // Update the value of the input field at the specified index
        const updatedFields = [...inputFields];
        updatedFields[index] = value;
        setInputFields(updatedFields);
    };


    const dispatch = useDispatch();
    const data = useSelector(CSTData);
    const subCatData = useSelector(SubData);
    const TopicData = useSelector(topicData);




    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
    }, []);


    // const categoryOptions = data?.map((e) => ({
    //     value: e.id,
    //     label: e.name
    // }));


    const SubCategoryOptionsList = subCatData?.map((e) => ({
        value: e.id,
        label: e.name
    }));

    const TopicOptionsList = TopicData?.map((e) => ({
        value: e.id,
        label: e.name
    }));


    const handleMultipleChange = (selectList) => {
        console.log(selectList);
        setTotalList(selectList || []);
    };

    const handleSubjectChange = (e) => {
        const subjectId = parseInt(e.target.value);
        const selectedSubject = subject.find(sub => sub.id === subjectId);
        setSelectedSubject(selectedSubject);

        // Populate category options
        if (selectedSubject) {
            const categories = [{ value: selectedSubject.category, label: selectedSubject.category_name }];
            setCategoryOptions(categories);

            // Populate subcategory options
            const subCategories = [{ value: selectedSubject.sub_category, label: selectedSubject.sub_category_name }];
            setSubCategoryOptions(subCategories);

            // Populate topic options
            const topics = [{ value: selectedSubject.subject, label: selectedSubject.topic_name }];
            setTopicOptions(topics);
        }
    };

    const newArray = studentList.filter(mainItem => !totalList.some(selectItem => selectItem.value === mainItem.value && selectItem.label === mainItem.label));

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Test</div>
            </div>
            {!_.get(props, 'userState.row') && <div className='Main-head-test'>
                <h4 className='ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-4  my-4'>Add Test</h4>
            </div>}

            {_.get(props, 'userState.row') && <div className='Main-head-test'>
                <h4 className='ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-4  my-4'>Edit Test</h4>
            </div>}


            <Formik
                initialValues={{
                    category: '',
                    sub_category: '',
                    topic: '',
                    batch_id: '',
                    student_id: '',
                    course_link: '',
                    message: '',
                    from: '',
                }}
                validationSchema={TestValidationSchema}
                onSubmit={(values, { resetForm }) => {
                    const payload = {
                        "category": values.category,
                        "sub_category": values.sub_category,
                        "topic": values.topic,
                        "details": values.details,
                        "closing_date": values.closing_date,
                        "currency": values.currency,
                        "price": values.price,
                        "term": values.term,
                        "file": values.file,

                    }
                    ToSubmit();
                    resetForm();

                }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        {!Que ? <div>
                            <div class="container text-start">

                                <div class="row mt-3">
                                    <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center">
                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col me-xxl-5 me-xl-5 me-lg-5 me-md-5">
                                            <div className="form-group p-md-0">
                                                <label htmlFor="categoryDropdown" className="admin-cst-label mb-0">Course</label>
                                                <Field
                                                    className="form-select admin-dropdown"
                                                    id="categoryDropdown"
                                                    value={test?.subject}
                                                    as="select"
                                                    name="subject"
                                                    onChange={handleSubjectChange} >

                                                    <option value="" >Select an option</option>
                                                    {subject.map((subject, index) => (
                                                        <option key={index} value={subject.id}>
                                                            {subject.name}
                                                        </option>
                                                    ))}
                                                </Field>

                                                <ErrorMessage
                                                    name="category"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />

                                            </div>
                                        </div>
                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col ">
                                            <div className="form-group p-md-0">

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center">
                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col me-xxl-5 me-xl-5 me-lg-5 me-md-5">
                                            <div className="form-group p-md-0">
                                                <label htmlFor="categoryDropdown" className="admin-cst-label mb-0">Category</label>
                                                <Field
                                                    className="form-select admin-dropdown"
                                                    id="categoryDropdown"
                                                    name="category"
                                                    value={test.category}
                                                    as="select"
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        const payload = {
                                                            "type": 2,
                                                            "category_id": selectedValue,
                                                        };
                                                        dispatch(getSubCategoryData(payload));
                                                        setFieldValue("category", e.target.value)
                                                        handleChange(e);
                                                    }}
                                                >

                                                    {categoryOptions?.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Field>

                                                <ErrorMessage
                                                    name="category"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />

                                            </div>
                                        </div>
                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col ">
                                            <div className="form-group p-md-0">
                                                <label htmlFor="subCategoryDropdown" className="admin-cst-label mb-0">Sub Category</label>
                                                <Field
                                                    className="form-select admin-dropdown"
                                                    id="subCategoryDropdown"
                                                    name="sub_category"
                                                    value={test.sub_category}
                                                    as="select"
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        const payload = {
                                                            "type": 3,
                                                            "sub_category_id": selectedValue,
                                                        };
                                                        dispatch(getSubTopicData(payload));
                                                        setFieldValue("sub_category", e.target.value)
                                                        handleChange(e);
                                                    }}
                                                >

                                                    {subCategoryOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Field>

                                                <ErrorMessage
                                                    name="sub_category"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center">
                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col  me-xxl-5 me-xl-5 me-lg-5 me-md-5">
                                            <div className="form-group p-md-0">
                                                <label htmlFor="topicDropdown" className="admin-cst-label mb-0">Subject / Topic</label>
                                                <Field
                                                    className="form-select admin-dropdown"
                                                    id="topicDropdown"
                                                    name="topic"
                                                    as="select"
                                                    value={test.topic}
                                                    onChange={(e) => {
                                                        setFieldValue("topic", e.target.value)
                                                        handleChange(e)
                                                    }}
                                                >

                                                    {topicOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Field>

                                                <ErrorMessage
                                                    name="topic"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col">

                                            <div className="form-group p-md-0">
                                                <label htmlFor="topicDropdown" className="admin-cst-label mb-0">Batch</label>
                                                <Field
                                                    className="form-select admin-dropdown"
                                                    id="topicDropdown"
                                                    name="batch_id"
                                                    as="select"
                                                    value={test.batch_id}
                                                    onChange={(e) => {
                                                        setFieldValue("batch_id", e.target.value)
                                                        handleChange(e)
                                                    }}
                                                >
                                                    <option value="">--select an option--</option>
                                                    {batches.map((batch, index) => (
                                                        <option key={index} value={batch.id}>
                                                            {batch.name}
                                                        </option>
                                                    ))}
                                                </Field>

                                                <ErrorMessage
                                                    name="batch_id"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="row mt-4">
                                    <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center">
                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col  me-xxl-5 me-xl-5 me-lg-5 me-md-5">
                                            <div className="form-group p-md-0">

                                                {!_.get(props, 'userState.row') && <label htmlFor="topicDropdown" className="admin-cst-label mb-0">Test date</label>}<br />
                                                {!_.get(props, 'userState.row') && <Field type="date" className="form-control admin-dropdown" value={test.date} name='date' onChange={(e) => {
                                                    setFieldValue("date", e.target.value)
                                                    handleChange(e)
                                                }} required />}

                                                {!_.get(props, 'userState.row') && <ErrorMessage
                                                    name="date"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />}
                                                {_.get(props, 'userState.row') && <label htmlFor="topicDropdown" className="admin-cst-label mb-0">Test date</label>}<br />
                                                {_.get(props, 'userState.row') && <Field type="date" className="form-control admin-dropdown" value={test.date} name='date' onChange={(e) => {
                                                    setFieldValue("date", e.target.value)
                                                    handleChange(e)
                                                }} required />}

                                                {_.get(props, 'userState.row') && <ErrorMessage
                                                    name="date"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />}
                                            </div>
                                        </div>
                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col">

                                            <div className="form-group p-md-0">
                                                <label htmlFor="topicDropdown" className="admin-cst-label mb-0">Choose Student</label>

                                                <InputGroup>
                                                    <Select
                                                        value={totalList}
                                                        onChange={handleMultipleChange}
                                                        options={studentList}
                                                        className="ms-xxl-0 ms-xl-0 ms-lg-2 "
                                                        isMulti
                                                    />
                                                </InputGroup>

                                                <ErrorMessage
                                                    name="student_id"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center">
                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col  me-xxl-5 me-xl-5 me-lg-5 me-md-5">
                                            <div className="form-group p-md-0">
                                                <label htmlFor="topicDropdown" className="admin-cst-label mb-0">Mark Per Questions</label>
                                                <Field
                                                    className="form-select admin-dropdown"
                                                    id="topicDropdown"
                                                    name="marks_question"
                                                    as="select"
                                                    value={test.marks_question}
                                                    onChange={(e) => {
                                                        setFieldValue("marks_question", e.target.value)
                                                        handleChange(e)
                                                    }}
                                                >
                                                    <option value="">--select an option--</option>
                                                    {markOptions.map((mark, index) => (
                                                        <option key={index} value={mark}>{mark}</option>
                                                    ))}
                                                </Field>

                                                <ErrorMessage
                                                    name="marks_question"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />
                                            </div>
                                        </div>

                                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col">
                                            <div className="form-group p-md-0">
                                                <label htmlFor="topicDropdown" className="admin-cst-label mb-0">Message</label>
                                                <Field type="text" class="form-control" placeholder="Type Your Message" aria-label="Username" aria-describedby="basic-addon1" value={test.message} name="message" onChange={(e) => {
                                                    setFieldValue("message", e.target.value)
                                                    handleChange(e)
                                                }} />

                                                <ErrorMessage
                                                    name="message"
                                                    component="div"
                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container text-center">
                                <div className="row">
                                    <div className="col">
                                        <button
                                            type="submit"
                                            className="btn btn-primary px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                                            onClick={() => setQue(true)}
                                        >
                                            Next<span className='ms-3 mb-2'><FaArrowRight /></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> :
                            <div>
                                <div class="container text-start">
                                    <div class="row">
                                        {inputFields.map((value, index) => (
                                            <div class="d-flex justify-content-center">
                                                <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col mt-3">
                                                    <p className=' fw-semibold mb-0 '>Question {index + 1}</p>
                                                    <div key={index} className='dropdown1'>
                                                        <button className='main-btn' onClick={() => toggleDropdown(index)}>{`Question ${index + 1}`}</button>


                                                        {showDropdown && openDropdownIndex === index && (
                                                            <div className='m-xxl-5 m-xl-5 m-lg-5 m-md-5 m-sm-5  ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-5 '>
                                                                {showContent0 && (
                                                                    <div className=''>
                                                                        {!_.get(props, 'userState.row') && <p className='para1-test'>Question</p>}
                                                                        <div >
                                                                            {!_.get(props, 'userState.row') && <Field type='text' class="form-control" id='' onChange={handleChange} value={test.question.question} name='question' />}
                                                                            {_.get(props, 'userState.row') && <input type='text' class="form-control" id='' onChange={handleChange} value={test.question.question} name='question' />}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {showContent1 && (
                                                                    <div>
                                                                        <p className='para1-test '>Resource</p>
                                                                        <div>
                                                                            {!_.get(props, 'userState.row') && <input type='file' value={test.question.doc} name='doc' className='test-file-inp' onChange={handleInputImage} />}
                                                                            {_.get(props, 'userState.row') && <input type='file' className='test-file-inp' value={test.question.doc} name='doc' onChange={handleInputImage} />}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {showContent2 && (
                                                                    <div>
                                                                        <p className='para1-test'>Options</p>


                                                                        <div class="container text-start">
                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <div>
                                                                                        <p className='options-test'>A</p>
                                                                                        <label className='option-inp-test-v '>

                                                                                            {!_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.a} name='a' />}
                                                                                            {_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.a} name='a' />}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <div>
                                                                                        <p className='options-test'>B</p>
                                                                                        <label className='option-inp-test-v '>

                                                                                            {!_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.b} name='b' />}
                                                                                            {_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.b} name='b' />}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <div>
                                                                                        <p className='options-test'>C</p>
                                                                                        <label className='option-inp-test-v '>

                                                                                            {!_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.c} name='c' />}
                                                                                            {_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.c} name='c' />}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <div>
                                                                                        <p className='options-test'>D</p>
                                                                                        <label className='option-inp-test-v '>

                                                                                            {!_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.d} name='d' />}
                                                                                            {_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.d} name='d' />}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <div>
                                                                                        <p className='options-test mt-3'>Ans: </p> <br />
                                                                                        <label className='option-inp-test-v '>
                                                                                            {!_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.answer} name='answer' />}
                                                                                            {_.get(props, 'userState.row') && <input type='text' id='boder' onChange={handleChange} value={test.question.answer} name='answer' />}
                                                                                        </label><br />
                                                                                        <button className='cancel-test-btn-v ms-5'>Cancel</button>
                                                                                        {!_.get(props, 'userState.row') && <button className='submit-test-btn' onClick={toggleDropdown}>save</button>}
                                                                                        {_.get(props, 'userState.row') && <button className='submit-test-btn' onClick={toggleDropdown}>save</button>}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                )}

                                                                <div className='row my-4'>
                                                                    <div class="col ms-xxl-4">
                                                                        {!showContent0 && <div className='col  mt-lg-3 mt-md-3 mt-sm-3 mt-3'> <button className='sub-btn-v px-4' onClick={() => handleButtonClick('Button 1')}>+ Question</button></div>
                                                                        }
                                                                        {!showContent1 && <div className='col mt-lg-3 mt-md-3 mt-sm-3 mt-3'> <button className='sub-btn-v px-4' onClick={() => handleButtonClick1('Button 2')}>+ Resource</button></div>}
                                                                        {!showContent2 && <div className='col  mt-lg-3 mt-md-3 mt-sm-3 mt-3'>  <button className='sub-btn-v px-4' onClick={() => handleButtonClick2('Button 2')}>+ Options</button></div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div class="d-xxl-flex justify-content-center">
                                        <div className='col-8'>
                                            <button className='Quest-test-btn1 ms-2' onClick={handleButtonClickNew}>+ Add Question</button>
                                            <button className='cancel-test-btn1-v ms-2'>Cancel</button>
                                            {!_.get(props, 'userState.row') && <button className='submit-test-btn' type='submit' onClick={(e) => ToSubmit()} >Publish</button>}
                                            {_.get(props, 'userState.row') && <button type='button' id="create-assign-drop7" onClick={(e) => ToEdit()} >update</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default TeacherAddTest