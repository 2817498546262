import React, { useState } from "react"
import membershipWhiteIcon from "../../../icons/membershipWhiteIcon.png"
import membershipBlueIcon from "../../../icons/membershipBlueIcon.png"
import _ from "lodash"

function Notification({ ...props }) {
  const [hoverEffect, setHoverEffect] = useState(false)
  const goToNotification = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Notification' })
    }
  }
  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'Notification' ? 'active' : ''} `}
    >
      <img
        src={_.get(props, 'userState.subType') != 'Notification' ? membershipWhiteIcon : membershipBlueIcon}
        alt='Membership'
      />
      <p className='side_heading' onClick={(e) => goToNotification(e)}>Notification</p>
    </div>
  )
}

export default Notification
