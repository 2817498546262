import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';

import { format, getMonth } from 'date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'top',
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
            barPercentage: 0.1,
        },
        y: {
            beginAtZero: true,
            ticks: {
                stepSize: 1,
            },
            grid: {
                display: true,
            },
        },
    },
};


function ChartComponent() {

    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                fill: true,
                label: 'Sales Report',
                data: [],
                borderColor: '#00b3ff',
                backgroundColor: '#ffffff',
            },
        ],
    });

    useEffect(() => {
        const fetchDashboard = () => {
            if (!localStorage.getItem('token')) {
                return;
            }
    
            const headers = {
                'Authorization': 'Bearer ' + (localStorage.getItem('token')),
            };
    
            axios
                .post("http://tuition.techiesmarkets.com/backend/public/api/admin/dashboard", undefined, { headers })
                .then((response) => {
                    const responseData = response.data.data;
                    // Get the current month and year
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth() + 1;
    
                    // Calculate the range of months to display
                    const startMonth = Math.max(currentMonth - 6, 1); // Show data for the previous 6 months
                    const endMonth = currentMonth;
    
                    const labels = [];
                    const salesData = [];
    
                    for (let month = startMonth; month <= endMonth; month++) {
                        const monthName = format(new Date(currentDate.getFullYear(), month - 1), 'MMM'); // Adjust the year as needed
                        labels.push(monthName);
                        salesData.push(responseData.salesgraph[monthName] || 0);
                    }
    
                    setData({
                        labels,
                        datasets: [
                            {
                                fill: true,
                                label: 'Sales Report',
                                data: salesData,
                                borderColor: '#00b3ff',
                                backgroundColor: '#ffffff',
                            },
                        ],
                    });
                })
                .catch((error) => {
                    console.error('Error fetching dashboard:', error);
                });
        };
    
        fetchDashboard();
    }, []);
    

    return (
        <div className='admin-card p-3'>
            {/* <Line options={options} data={data} /> */}
            {data ? <Line options={options} data={data} /> : <p>Loading...</p>}
        </div>
    );
}

export default ChartComponent;
