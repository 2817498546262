import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Tabs, Tab } from 'react-bootstrap';
import { FaBell } from "react-icons/fa";
import { CiCircleChevDown, CiSettings } from "react-icons/ci";
import { CgProfile } from 'react-icons/cg';
import { LuLogOut } from 'react-icons/lu';
import _ from 'lodash';
import { userLogout } from "../../../../../../redux/slice/AuthSlice";
import profile from '../../../../../../images/profile.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Axios from '../../../../../../util/apiUtil';

function AdminNavbar(props) {
    const navigate = useNavigate();
    const data = localStorage.getItem('user');
    const userData = JSON.parse(data);
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.auth.Token);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const basePath = 'http://tuitionguruji.com/backend/public/';
    const gotosearch = async () => {
        if (!searchQuery.trim()) {
            setSearchResults(null);
            return;
        }
        try {
            const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
            const response = await Axios.post('/api/globalSearch', { search: searchQuery }, { headers });

            if (response.status !== 200) {
                throw new Error('Search failed');
            }

            const result = response.data;
            setSearchResults(result);
        } catch (error) {
            console.error('Error during search:', error);
        }
    };

    const handleLogout = () => {
        dispatch(userLogout({ authToken, navigate }));
    };

    const gotoNotification = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachernotification' });
        }
    };

    const gotoProfile = () => {
        navigate('/teacher/profile');
    };

    const gotoSettings = () => {
        navigate('/teacher/settings');
    };

    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (!value.trim()) {
            setSearchResults(null);
        }
    };

    const goToTeacherViewSubject = (e, row, subject_id) => {
        e.preventDefault();
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'ViewSubject', row: row });
        }
    };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
    };
    return (
        <div className='py-2 admin-navbar'>
            <div className="container text-center">
                <div className="row">
                    <div className='d-flex justify-content-between'>
                        <div className="col-5">
                            <div className='d-flex mb-0'>
                                <input
                                    type="text"
                                    className="form-control rounded-pill"
                                    placeholder="search ... "
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                />
                                <button
                                    type="button"  // Ensure button type is set to 'button'
                                    className="btn admin-navbar-search-btn text-light px-3 ms-3"
                                    onClick={gotosearch}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className='col-2 col-md-3 col-lg-3 col-xl-2 d-flex justify-content-end ms-5'>
                            <FaBell style={{ color: "#5C5AB6" }} onClick={gotoNotification} />
                        </div>
                        <div className="col-2 col-md-3 col-lg-3 col-xl-2">
                            <div className="container text-start">
                                <div className="row">
                                    <div className="col-4 px-0">
                                        <img src={profile} className="img-fluid rounded-top" alt="Profile" />
                                    </div>
                                    <div className="col-6 px-0">
                                        <p className='mb-0 fw-bold'>{userData.firstname}</p>
                                        <p className='mb-0 fs-6'>Teacher</p>
                                    </div>
                                    <div className="col px-0 align-self-center dropdown">
                                        <CiCircleChevDown
                                            className='admin-nav-icon'
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <ul className="dropdown-menu mt-3" aria-labelledby="dropdownMenuButton">
                                            <li><a className="dropdown-item" onClick={gotoProfile}><CgProfile className='me-2 mb-1' />Profile</a></li>
                                            <li><a className="dropdown-item" onClick={gotoSettings}><CiSettings className='me-2 mb-1' />Settings</a></li>
                                            <li><a className="dropdown-item" onClick={handleLogout}><LuLogOut className='me-2 mb-1' />Logout</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {searchResults && (
                <div className="container mt-3">
                    <Tabs defaultActiveKey="batch" id="search-results-tabs" className="custom-tabs">
                        <Tab eventKey="batch" title="Batch" className='search-batch'>
                            <div className="row">
                                {searchResults.data.batch.map((item) => (
                                    <div className="col-4 mb-3" key={item.id}>
                                        <div className="card p-3">
                                            <h3>{item.name}</h3>
                                            <p>Category: {item.category_name}</p>
                                            <p>Sub-category: {item.sub_category_name}</p>
                                            <p>Topic: {item.topic_name}</p>
                                            <p className='d-flex justify-content-end' style={{color:'#C0C0C0'}}>{formatDate(item.starting_at)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="students" title="Students">
                            <div className="row">
                                {searchResults.data.students.map((student) => (
                                    <div className="col-4 mb-3" key={student.student_id}>
                                        <div className="card p-3">
                                            <h3>{student.firstname}</h3>
                                            <p>Subject: {student.subject_name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Tab>
                        <Tab eventKey="subjects" title="Subjects" className='para-abt1'>
                            <div className="row">
                                {searchResults.data.subjects.map((subject) => (
                                    <div className="col-4 mb-3" key={subject.id}>
                                        <div className="card p-3">
                                            <div className='row'>
                                            <div className='col-7'>
                                            <h3>{subject.name}</h3>
                                            <p>Category: {subject.category_name}</p>
                                            <p>Sub-category: {subject.sub_category_name}</p>
                                            <p>Topic: {subject.topic_name}</p>
                                            {/* <button
                                                type="button"  // Ensure button type is set to 'button'
                                                className="btn btn-primary mt-2"
                                                onClick={(e) => goToTeacherViewSubject(e, subject, subject.id)}
                                            >
                                                Details
                                            </button> */}
                                        </div>
                                        <div className='col-5'>
                                        <img className="img-fluid" src={`${basePath}${subject.image}`} alt={subject.name} />
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            )}
        </div>
    );
}

export default AdminNavbar;
    