import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DotsIcon from '../../../Sidebar/icons/DotsIcon.png'
import axios from 'axios';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from "react-data-table-component";
import { format } from 'date-fns';
import Modal from 'react-modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import './TeacherNotification.scss'
import { useHistory } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import MoreVertIcon from '@mui/icons-material/MoreVert';

function TeacherNotification(props) {
  const DateOption = ['20-09-2023', '21-09-2023', '22-09-2023', '23-09-2023', '24-09-2023']
  const [activeButton, setActiveButton] = useState('create');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl)
  const [isactions, setActions] = useState(false)
  const [activeMenu, setActiveMenu] = useState(null)
  const [menuVisibility, setMenuVisibility] = useState([]);
  const [notificationToUpdate, setNotificationToUpdate] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [view, setView] = useState(false)
  const [editId, setEditId] = useState(null);
  const [rows, setRows] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null);
  const [data, setData] = useState([{
    Date: '',
    category: '',
    sub_category: '',
    des: '',
    batch: '',
    id: '',
  }]);


  const [dummy, setDummy] = useState([
    {
      create_at: '2023-09-20',
      message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      id: 1 // Add an ID for each row
    },
  ]);

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const handleClickActions = (event, rowId, action) => {
    setAnchorEl(event.currentTarget);
    setActions((prevState) => (prevState === rowId ? null : rowId))
    if (action === 'delete') {
      setActiveMenu(rowId);
    } else {
      setActiveMenu(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null)
  }


  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleMenuToggle = (rowIndex) => {
    const newMenuVisibility = [...menuVisibility];
    newMenuVisibility[rowIndex] = !newMenuVisibility[rowIndex];
    setMenuVisibility(newMenuVisibility);
  };

  // Data table structure
  const customStyles = {
    headRow: {
      style: {
        color: '#fff',
        backgroundColor: '#5C5AB6',
        fontSize: "13px"
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff"
      }
    },
  }
  const columns = [
    {
      name: 'Date',
      selector: row => row.created_at,
      sortable: true
    },
    {
      name: 'Category',
      selector: row => row.category,
      sortable: true

    },
    {
      name: 'Sub category',
      selector: row => row.sub_category,
      sortable: true

    },
    {
      name: 'Topic',
      selector: row => row.des,
      sortable: true

    },
    {
      name: 'Batch',
      selector: row => row.batch,
      sortable: true
    },
    {
      name: 'Action',
      cell: (row, rowIndex, id) => (
        <div className={`Teacher test-actions ${isactions == row.id ? '' : 'active'}`}>
          <Button className="action-subj d-flex" id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(e) => handleClickActions(e, row.id, 'menu')}>
            <MoreVertIcon />
          </Button>

          <div>
            <Menu
              className="dots-actions"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={(e) => goToTeacherViewNotification(e, row, id)} disableRipple className="ul-for-options pt0">
                View
              </MenuItem>
              <MenuItem onClick={(e) => goToTeacherNotification(e, row, id)} disableRipple className="ul-for-options">
                Edit
              </MenuItem>
              <MenuItem disableRipple className="ul-for-options" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Delete
              </MenuItem>

            </Menu>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content p-5">
                  <div class=" justify-content-center ms-5">
                    <h1 class="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want Delete ?</h1>
                    <button type="button" class="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal" >Close</button>
                    <button type="button" class="btn btn-primary mt-3 ms-5 px-4" data-bs-dismiss="modal" onClick={() => { deleteRow(row.id) }}>Delete</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )
    }

  ];

  useEffect(() => {
    getRows()
    fetchNotification()
    fetchTeacherNotifications();

  }, [])
  useEffect(() => {
    const visibility = new Array(data.length).fill(false);
    setMenuVisibility(visibility);
  }, [data]);
  const fetchNotification = () => {
    if (!localStorage.getItem('token')) {
      Backdashboard();
    }
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

    axios.post("http://tuition.techiesmarkets.com/backend/public/api/listnotification", undefined, { headers: headers })
      .then((response) => {
        if (_.get(response, 'data.data')) {
          setData(response.data.data);
          setLoading(false);
        }
        const newData = response.data.data.map((item) => ({
          ...item,
          created_at: format(new Date(item.created_at), 'dd-MM-yyyy'),

        }));
        setData(prevData => [...prevData, ...newData]);
        setData(newData);

      })
      .catch((error) => {
        console.error('Error fetching Subjects:', error);
        setLoading(false);

      })
  }

  const getRows = () => {
    const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    let res = [];
    res = data.map((item, index) => {
      return ({
        create_at: item.created_at,
        category: item.category,
        sub_category: item.sub_category,
        des: item.des,
        batch_id: item.batch
      })
    })
    setRows(res);
  }


  const Backdashboard = () => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Teachernotification' })
    }
  }

  const goToTeacherNotification = (e, row, Id) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'createnotification', row: row, id: Id })
    }
  }

  const goToAllNotification = (e, row, Id) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'allnotification', row: row, id: Id })
    }
  }

  const goToTeacherReplyNotification = (e, row, Id) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'notificationreply' })
    }
  }

  const goToTeacherViewNotification = (e, row, Id) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'viewnotification', row: row, id: Id })
    }
  }

  const deleteRow = (id) => {
    if (!localStorage.getItem('token')) {
      Backdashboard();
      return;
    }

    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

    axios.post(`http://tuition.techiesmarkets.com/backend/public/api/deletenotification`, { id }, { headers: headers })
      .then(() => {
        // Update the data state to remove the deleted item
        setData(prevData => prevData.filter(item => item.id !== id));
      })
      .catch((error) => {
        console.error('Error deleting row:', error);
      });


  };

  const [clickedMessageId, setClickedMessageId] = useState(null);

  const handleClickMessage = (messageId) => {
    setClickedMessageId(clickedMessageId === messageId ? null : messageId);
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'notificationreply' });
    }
  };

  // Data table structure
  const customStyle = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#5C5AB6",
        fontSize: "13px",
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
      },
    },
  };
  
  const column = [
    {
      name: "Date",
      selector: (row) => row.create_at,
    },
    {
      name: "Message",
      cell: (row) => (
        <div onClick={() => handleClickMessage(row.id)} style={{ color: clickedMessageId === row.id ? 'black' : (clickedMessageId === null ? (row.id % 2 === 0 ? 'blue' : 'red') : (clickedMessageId % 2 === 0 ? 'blue' : 'red')) }}>          {row.message}
        </div>
      ),
      sortable: true,
    },
  ];


  const fetchTeacherNotifications = () => {
    if (!localStorage.getItem('token')) {
      return;
    }

    const headers = {
      'Authorization': 'Bearer ' + (localStorage.getItem('token'))
    };

    axios.post(
      'http://tuition.techiesmarkets.com/backend/public/api/teachernotificaton',

      undefined,
      { headers: headers }
    )
      .then(response => {
        console.log('Teacher Notifications:', response.data);

      })
      .catch(error => {
        console.error('Error fetching Teacher Notifications:', error);
      })
  }

  return (
    <div className='main-divHead' >
      <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
        <div className='Sub-Head2'>Notification</div>
      </div>

      <button className={`btn-create-Notification1 ${activeButton === 'custom' ? 'active1' : ''}`}
        onClick={(e) => {

          setActiveButton('custom');
        }}
      >
        Recieved Notification </button>
      &nbsp;&nbsp;<button className={`btn-create-Notification ${activeButton === 'create' ? 'active1' : ''}`}
        onClick={(e) => {

          setActiveButton('create');
        }}
      >
        Send Notification</button>
      <br />

      {activeButton === 'create' && (
        <button className='btn-create-Notification2' onClick={(e) => { goToTeacherNotification(e) }}>Create Notification</button>
      )}

      {activeButton === 'create' && (
        <div class="col-xxl-11 col-xl-12 col-lg-12-px-2 mt-5 ms-5 justify-content-center">
          <DataTable
            data={data}
            columns={columns}
            customStyles={customStyles}
            onRowClicked={(row) => setView(row.id === view ? null : row.id)}
            pagination
            fixedHeader={true}
            fixedHeaderScrollHeight="600px"
            striped
            persistTableHead
          />

          <InfiniteScroll
            dataLength={data.length}
            next={fetchNotification}
            hasMore={hasMore}
          ></InfiniteScroll>
        </div>
      )}

      {activeButton === 'custom' && (
        <div>
          <div class="col-xxl-11 col-xl-12 col-lg-12 mt-5 ms-5 justify-content-center">
            <DataTable
              data={dummy}
              columns={column}
              customStyles={customStyle}
              fixedHeader={true}
              fixedHeaderScrollHeight="600px"
              striped
              persistTableHead
              pagination
            />

            <InfiniteScroll
              dataLength={data.length}
              next={fetchNotification}
              hasMore={hasMore}
            ></InfiniteScroll>
          </div>
        </div>
      )}

    </div>
  )
}

export default TeacherNotification