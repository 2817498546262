import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { enrolledCourses } from "../../../../../../../redux/displayer"
import Subjects from "./Subjects";
import Courses from "./Courses1";
import _ from 'lodash';


function EnrolledCourses({ ...props }) {
  const [showOrderParent, setshowOrderParent] = useState(false);

  const liveClass = () => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Chatscontent' })
    }
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const Backdashboard = () => {
    if (showOrderParent) {
      setshowOrderParent(false);
    } else {
      if (_.get(props, 'setUserState')) {
        props.setUserState({ ...props.userState, subType: 'Dashboard' })
      }
    }
  }
  return (
    <div className='Enroll-section' onClick={() => dispatch(enrolledCourses())}>
      <div className="">


        <p className=''> {_.get(props, 'userState.subType') == 'Chatscontent' ? 'Subjects' : ''} </p>
      </div>

      {_.get(props, 'userState.subType') == 'EnrolledCourses' && <Courses showOrderParent={showOrderParent} setshowOrderParent={setshowOrderParent} {...props} />}
      {_.get(props, 'userState.subType') == 'Chatscontent' && <Subjects {...props} />}
    </div>
  )
}

export default EnrolledCourses
