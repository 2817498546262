import React, { useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'



function LoadingSpinner() {

    return (
        <div className="spinner-container" >
            <RotatingLines
                strokeColor="#5C5AB6"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
                height="25"
                visible={true} />
        </div>
    )
}

export default LoadingSpinner