import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';
import './TeacherStuentQuest.scss'
import axios from 'axios';
import img from '../../../../../../images/bi_image-fill.jpg';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function TeacherStuentQuest(props) {

    const ViewBatch = props.userState.row.question_id || {};
    const [view, setView] = useState()

    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [testTeachers, setTestTeachers] = useState([{
    }])

    const baseUrl = 'http://tuitionguruji.com/backend/public/';

    const fetchViewDetailas = () => {

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const createViewTest = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        console.log("Question ID:", testTeachers.question_id);
        formData.append("question_id", ViewBatch);


        Axios.post('/api/viewTest', formData,
            { headers: headers })
            .then((response) => {
                console.log(response)
                const updateData = response.data.data.question.question[0]
                setView(updateData)
            })
            .catch((error) => {
                console.error("Error fetching test data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });

    }

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
        fetchViewDetailas();
    }, [])

    const initializeObject = () => {
        const questionId = _.get(props, 'userState.row.question_id');
        console.log('question_id:', questionId); // Check the value in the console
        setTestTeachers({
            ...testTeachers,
            ...props.userState.row,
            question_id: questionId,

        });
    }


    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherstudentdetails' })
        }
    }

    const isOptionCorrect = (correctOption, selectedOption) => {
        const correct = correctOption === selectedOption;
        console.log(`Correct Answer: ${correct}`);
        return correct;
    }




    return (
        <div className="main-divHead">
            <div className="sub-Head1" onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className="Sub-Head2">Test</div>
            </div>
            <div class="container">
                <div class="card col-10">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col">
                                <div className="main-head-quest">Question 1 <hr className="hr1" /></div>
                            </div>

                        </div>

                        <div class="row">

                            <div class="col-6">
                                <div className="Question1">Question</div>

                                <div className="Question-box">
                                    {view && view.question && <p className="first-question">{view.question}</p>}
                                </div>

                                <p className="main-head-quest">Answer:</p>

                                <div clasc="col-8">
                                    <label className="quest-opt-1">A.</label>
                                    <div className={`option-box-a ${isOptionCorrect(view?.answer, 'A') ? 'correct-answer' : ''}`}>
                                        {view && view.a && <p className="ans-1">{view.a}</p>}
                                    </div>
                                </div>

                                <div>
                                    <label className="quest-opt-1">B.</label>
                                    <div className={`option-box-a ${isOptionCorrect(view?.answer, 'B') ? 'correct-answer' : ''}`}>
                                        {view && view.b && <p className="ans-1">{view.b}</p>}
                                    </div>
                                </div>

                                <div>
                                    <label className="quest-opt-1">C.</label>
                                    <div className={`option-box-a ${isOptionCorrect(view?.answer, 'C') ? 'correct-answer' : ''}`}>
                                        {view && view.c && <p className="ans-1">{view.c}</p>}
                                    </div>
                                </div>

                                <div>
                                    <label className="quest-opt-1">D.</label>
                                    <div className={`option-box-a ${isOptionCorrect(view?.answer, 'D') ? 'correct-answer' : ''}`}>
                                        {view && view.d && <p className="ans-1">{view.d}</p>}
                                    </div>
                                </div>
                                <button className="btn-1-next">
                                    Next <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>

                            <div class="col-6 mt-5">
                                <div className="admin-card admin-dotted-border mt text-center ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0">
                                {view && view.attachment && view.attachment.toLowerCase().endsWith('.pdf') ? (
                                            // Display PDF using an iframe
                                            <iframe src={baseUrl + view?.attachment} title="PDF Document" className="pdf-iframe" />
                                        ) : (
                                            // Display image using an img tag
                                            <img src={baseUrl + view?.attachment} className="img-fluid rounded-top" alt="..." />
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeacherStuentQuest;