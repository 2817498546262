import React, { useState } from "react"
import _ from "lodash"
import { RxDashboard } from 'react-icons/rx'

const Dashboard = ({ ...props }) => {
  const [hoverEffect, setHoverEffect] = useState(false)
  const goToDashboard = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Dashboard' })
    }
  }
  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'Dashboard' ? 'active' : ''} `}>
      <div className="dash d-flex">
      <RxDashboard class='ms-3' />
        <p className='head-dashboard ms-2' onClick={(e) => goToDashboard(e)}>Dashboard</p>
      </div>
    </div>
  )
}

export default Dashboard
