import _ from "lodash"
import React from 'react'
import { RxDashboard } from 'react-icons/rx'

function UploadDashboardComponent(props) {
  const goToDashboardTeacher = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'DashboardUpload' })
    }
  }
  return (
    <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'DashboardUpload' ? 'active' : ''} `} onClick={(e) => goToDashboardTeacher(e)}>
      <div className="dash d-flex">
        <RxDashboard class='ms-3' />
        <p className='head-dashboard ms-2' > Dashboard
        </p>
      </div>
    </div>
  )
}

export default UploadDashboardComponent