import React from 'react'
import _ from "lodash"
import { BiSolidBriefcase } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

function SubjectComponent(props) {
    const navigate = useNavigate()
    const goToSubjectTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'AdminSubject' })
        }
    }


    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'CategoryDetailsPage':
            case 'adminDetailsPage':
            case 'adminAddSubCategory':
            case 'adminAddTopic':
            case 'adminAddCategory':
            case 'AdminSubject':
            case 'viewtopic':
            case 'viewsubcategory':
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };
    return (
        <div
            className={determineClassName()} >

            <div className="dash d-flex">
                <BiSolidBriefcase class='ms-3' />
                <p className='head-dashboard ms-2' onClick={(e) => goToSubjectTeacher(e)}> Category
                </p>
            </div>
        </div>
    )
}

export default SubjectComponent