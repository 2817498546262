import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DownloadIcon from '../../../Sidebar/icons/DownloadIcon.png';
import './StudentCompleteAssign.scss';
import axios from 'axios';
import { format } from 'date-fns';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function StudentCompleteAssign(props) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const baseURL = 'http://tuitionguruji.com/backend/public/';

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewstudentlist' });
        }
    }

    const downloadFile = (fileName) => {
        if (!fileName) {
            console.error('File name is missing.');
            return;
        }
    
        const fileURL = baseURL + fileName;
        const link = document.createElement('a');
    
        // Use a controlled and sanitized file name
        const sanitizedFileName = fileName.replace(/\s+/g, '_'); // Replace spaces with underscores
        link.href = fileURL;
        link.setAttribute('download', sanitizedFileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    

    const fetchAssignment = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }

        const headers = { 'Authorization': 'Bearer ' +(localStorage.getItem('token')) };
        Axios.post('/api/listAssignments', undefined, { headers: headers })
            .then((response) => {
                const newData = response.data.data.map((item) => ({
                    ...item,
                    starting_at: format(new Date(item.starting_at), 'dd-MM-yyyy'),
                    ending_at: format(new Date(item.ending_at), 'dd-MM-yyyy'),
                }))
                setData(newData)
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching Assignments:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchAssignment()
    }, []);

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Assignments</div>
            </div>
            <div class="container text-start">

                <div className='student-info-head'>
                    {data.map((assignment, index) => (
                        <div key={index}>
                            <div class="row mt-4">
                                <div className='d-flex justify-content-center'>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo '>Date: </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo ' >{assignment.starting_at}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div className='d-flex justify-content-center'>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo '>Student Name : </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo ' >{assignment.student_name}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div className='d-flex justify-content-center'>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo '>Category : </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo ' >{assignment.category}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div className='d-flex justify-content-center'>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo '>Sub Category : </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo ' >{assignment.sub_category}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div className='d-flex justify-content-center'>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo '>Batch : </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo ' >{assignment.batch_id}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div className='d-flex justify-content-center'>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo '>Topic : </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo ' >{assignment.topic}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div className='d-flex justify-content-center'>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo '>Closing Date : </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo ' >{assignment.ending_at}</p>
                                    </div>
                                </div>
                            </div>

                           

                            <div class="row mt-4">
                                <div className='d-flex justify-content-center'>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo '>Status : </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo ' ></p>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div className='d-flex justify-content-center'>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo '>Assignment File : </p>
                                    </div>
                                    <div class="col-xxl-4 col-xl-6 col-lg-4">
                                        <p className='techer-card-typo ' >

                                            <span
                                                role="button"
                                                onClick={() => downloadFile(assignment.assignment_doc)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img src={DownloadIcon} className='download-icon1' alt="downloadicon" />
                                                {assignment.assignment_doc}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default StudentCompleteAssign;
