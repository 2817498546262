import { Route, Routes, Navigate, Redirect } from "react-router-dom"
import LandingPage from "./components/LandingPage/LandingPage"
import QuickDemo from "./components/quickDemo/QuickDemo"
import LogPage from "./components/signInSignUp"

import StudentProfile from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/StudentProfile"
import ViewProfile from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/viewProfile"
import PlaceOrder from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/Placeorder"
import LoggedIn from "./components/LoggedIn"
import Settings from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/Settings"
import UserPageLogged from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/UserPageLogged"
import SubComponentMainSection from "./components/LoggedIn/LoggedInComponents/MainSection/components/SubComponentMainSection"
import "./styles/common/common.scss"
import Courses1 from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/Courses1"
import CourseUploadMain from "./components/LoggedIn/LoggedInComponents/Sidebar/SidebarComponents/courseUpload"
import Freelancer from "./components/LoggedIn/LoggedInComponents/Sidebar/SidebarComponents/freelaner"
import CourseUpload from "./components/LoggedIn/LoggedInComponents/Sidebar/SidebarComponents/courseUpload/components/CourseUpload"
import './App.css'
import Paymethod from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/Paymethod"
import ShowSubjects from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/ShowSubjects"
import AdminSideBar from "./components/LoggedIn/LoggedInComponents/Sidebar/SidebarComponents/admin"
import TestResults from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/Tests"
import LandingSignup from "./pages/landingSignup"
import LandingVerify from "./pages/landingVerify"
import Contact_us from "./components/LandingPage/contents/Contact_us"
import Featured_Course from "./components/LandingPage/contents/Featured_Course"
import TeacherProfile from "./components/LoggedIn/LoggedInComponents/MainSection/components/TeacherPage/TeacherProfile"
import SignUpAsComponent from "./components/signInSignUp/signUpAs.component"
import EnrolledCourses from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/EnrolledCourses"
import TeacherViewProfile from "./components/LoggedIn/LoggedInComponents/MainSection/components/TeacherPage/TeacherViewProfile"
import CouponCode from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/CouponCode"
import AdminSubjectComponent from "./components/LoggedIn/LoggedInComponents/MainSection/components/adminPage/adminSubject.component"
import RatingsComponent from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/Rating"
import Cart from "./components/LoggedIn/LoggedInComponents/MainSection/components/userPage/components/Cart"
import EditTeacherSubject from "./components/LoggedIn/LoggedInComponents/MainSection/components/TeacherPage/EditTeacherSubject"
import ForgotPassword from "./components/signInSignUp/ForgotPassword"
function App() {


  return (

    <Routes>
      <Route path='/' element={<Navigate to='/home' replace />} />
      <Route path='/home' element={<LandingPage />} />
      <Route path='/page_demo' element={<QuickDemo />} />
      <Route path='/home/contact_us' element={<Contact_us />} />
      <Route path='/home/Featured_Course' element={<Featured_Course />} />
      <Route path='/user/register' element={<LogPage />} />
      <Route path='/profile' element={<profile />} />
      <Route path='/ShowSubjects' element={<ShowSubjects />} />
      <Route path='/TestResults' element={<TestResults />} />
      <Route path='/user/sign_in' element={<LogPage />} />
      <Route path='/user/sign_up' element={<LandingSignup />} />
      <Route path='/user/verify' element={<LandingVerify />} />
      <Route path='/user/sign_up_as' element={<SignUpAsComponent />} />
      <Route path='/teacher/profile' element={<TeacherProfile />} />
      <Route path='/teacher/settings' element={<Settings />} />
      <Route path='/user/Navbar' element={<LogPage />} />
      <Route path='/ViewProfile' element={<ViewProfile />} />
      <Route path='/PlaceOrder' element={<PlaceOrder />} />
      <Route path='/applycoupon' element={<CouponCode />} />
      <Route path='/Courses1' element={<Courses1 />} />
      <Route path='/Settings' element={<Settings />} />
      <Route path='/Paymethod' element={<Paymethod />} />
      <Route path='/TeacherViewProfile' element={<TeacherViewProfile />} />
      <Route path='/StudentProfile' element={<StudentProfile />} />
      <Route path='/user/Choose_Who' element={<LogPage />} />
      <Route path='/student' element={<LoggedIn type="Student" subType="Dashboard" />} />
      <Route path='/teacher' element={<LoggedIn type="Teacherpage" subType='DashboardTeacher' />} />
      <Route path='/adminpages' element={<LoggedIn type="AdminPage" subType='adminDashboard' />} />
      {/* <Route path='/enrolled' element={<EnrolledCourses />} /> */}
      {/* <Route path="/Freelancer" element={<Freelancer />} /> */}
      <Route path="/freelancepage" element={<LoggedIn type="Freelancepage" subType="dashboardfreelance" />} />
      <Route path='/courseupload' element={<LoggedIn type="courseupload" subType="CourseDashboard" />} />
      <Route path='/admin' element={<LoggedIn type="adminPage" subType="DashboardAdmin" />} />
      {/* <Route path='/admin/category' element={<AdminSubjectComponent  />} /> */}

      <Route path='/Freelancer' element={<LoggedIn type="FreelancerPage" subType="DashboardFreelancer" />} />
      <Route path='/InstructorUpload' element={<LoggedIn type="InstructorUploadPage" subType="InstructorUploadDashboard" />} />
      <Route path="/UserPageLogged" element={<UserPageLogged />} />
      <Route path="/SubComponentMainSection" element={<SubComponentMainSection />} />
      <Route path="/enrolldsubject" element={<EnrolledCourses />} />
      <Route path="/rate" element={<RatingsComponent />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/teacher/editcourse" element={<EditTeacherSubject />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      {/* <Route path/> */}
    </Routes>
  )
}

export default App
