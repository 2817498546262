import React from 'react'
import SignUpVerificationComponent from '../components/signInSignUp/signUpverification.component'
import img from '../images/signup.png'


function LandingVerify() {
    return (
        <div><>
            <div className="container-fluid">
                <div className="row">
                    <div className="col px-0 d-none d-sm-block">
                        <img src={img} alt="Login image" className="img-fluid w-100 vh-100" />
                    </div>
                    <div className="col d-flex justify-content-center align-items-center">
                        <SignUpVerificationComponent />
                    </div>
                </div>
            </div>
        </></div>
    )
}

export default LandingVerify