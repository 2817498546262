import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { systemSettings } from '../../../../../../redux/slice/settingsSlice';

function AdminSystemSettingsComponent() {

    const dispatch = useDispatch();

    const initialValues = {
        websiteName: "",
        websiteTitle: "",
        websiteKeywords: "",
        websiteDescription: "",
        author: "",
        slogan: "",
        systemEmail: "",
        phone: "",
        youtubeApiKey: "",
        vimeoApiKey: "",
        purchaseCode: "",
        systemLanguage: "",
        studentEmailVerification: "",
        footerText: "",
        footerLink: ""
    };

    const validationSchema = Yup.object().shape({
        websiteName: Yup.string().required("website name is required"),
        websiteTitle: Yup.string().required("website title is required"),
        websiteKeywords: Yup.string().required("website keywords are required"),
        websiteDescription: Yup.string().required("website description is required"),
        author: Yup.string().required("author is required"),
        slogan: Yup.string().required("slogan is required"),
        systemEmail: Yup.string()
            .email("Invalid email format")
            .required("system email is required"),
        phone: Yup.string()
            .matches(/^\d{10}$/, "phone number must be 10 digits")
            .required("Phone is required"),
        youtubeApiKey: Yup.string().required("youTube API key is required"),
        vimeoApiKey: Yup.string().required("vimeo API key is required"),
        purchaseCode: Yup.string().required("purchase code is required"),
        systemLanguage: Yup.string().required("system language is required"),
        studentEmailVerification: Yup.string().required("student email verification is required"),
        footerText: Yup.string().required("Footer text is required"),
        footerLink: Yup.string().required("footer Link is required"),
    });

    const onSubmit = (values, { setSubmitting }) => {
        console.log(values);
        const payload = {
            "website_name": values.websiteName,
            "website_title": values.websiteTitle,
            "website_keywords": [values.websiteKeywords],
            "website_desc": values.websiteDescription,
            "author": [values.author],
            "slogan": values.slogan,
            "system_email": values.systemEmail,
            "phone": values.phone,
            "youtube_api_key": values.youtubeApiKey,
            "vimeo_api_key": values.vimeoApiKey,
            "purchase_code": values.purchaseCode,
            "system_language": values.systemLanguage,
            "student_email_verify": values.studentEmailVerification === "enable" ? "1" : "0",
            "footer_text": values.footerText,
            "footer_link": values.footerLink,
        }

        dispatch(systemSettings(payload));
        setSubmitting(false);
    };

    return (
        <div className='main-divHead'>
            <div className='sub-Head1'>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Settings</div>
            </div>
            <div>
                <div className="container text-start my-5">
                    <p className='admin-sub-head fw-bold ms-5'>System Settings</p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit} >
                        {({ touched, errors, handleSubmit, resetForm, isSubmitting, setFieldValue, values, handleChange, handleBlur }) => (
                            <>
                                <Form>
                                    <div className="container">

                                        {console.log("values",values)}
                                        <div className="row">
                                            <div className='d-flex justify-content-center'>
                                                <div className="col-xxl-6 col-xl-6 col-lg-10">
                                                    <div>
                                                        <label className='fs-6 mt-4 fw-semibold'>Website name</label>
                                                        <Field type="text" name="websiteName" className="form-control" placeholder="websitename" />
                                                        <ErrorMessage name="websiteName" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Website title</label>
                                                        <Field type="text" name="websiteTitle" className="form-control" placeholder="learning and teaching website" />
                                                        <ErrorMessage name="websiteTitle" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Website keywords</label>
                                                        <Field type="text" name="websiteKeywords" className="form-control" placeholder="" />
                                                        <ErrorMessage name="websiteKeywords" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Website description</label>
                                                        <Field type="text" name="websiteDescription" className="form-control" placeholder="Nice application" />
                                                        <ErrorMessage name="websiteDescription" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Author</label>
                                                        <Field type="text" name="author" className="form-control" placeholder="Creativeitem" />
                                                        <ErrorMessage name="author" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Slogan</label>
                                                        <Field type="text" name="slogan" className="form-control" placeholder="A course based video CMS" />
                                                        <ErrorMessage name="slogan" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>System email</label>
                                                        <Field type="text" name="systemEmail" className="form-control" placeholder="learn@gmail.com" />
                                                        <ErrorMessage name="systemEmail" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Phone</label>
                                                        <Field type="text" name="phone" className="form-control" placeholder="1234567890" />
                                                        <ErrorMessage name="phone" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Youtube API key</label>
                                                        <Field type="text" name="youtubeApiKey" className="form-control" placeholder="youtube-api-key" />
                                                        <ErrorMessage name="youtubeApiKey" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Vimeo API key</label>
                                                        <Field type="text" name="vimeoApiKey" className="form-control" placeholder="vimeo-api-key" />
                                                        <ErrorMessage name="vimeoApiKey" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Purchase code</label>
                                                        <Field type="text" name="purchaseCode" className="form-control" placeholder="your-purchase-code" />
                                                        <ErrorMessage name="purchaseCode" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>System language</label>
                                                        <Field as="select" name="systemLanguage" className="form-select" placeholder="English">
                                                            <option value="english">English</option>
                                                            <option value="malayalam">Malayalam</option>
                                                        </Field>

                                                        <ErrorMessage name="systemLanguage" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Student email verification</label>
                                                        <Field as="select" name="studentEmailVerification" className="form-select" placeholder="Enable">
                                                            <option value="enable">Enable</option>
                                                            <option value="disable">Disable</option>
                                                        </Field>
                                                        <ErrorMessage name="studentEmailVerification" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Footer text</label>
                                                        <Field type="text" name="footerText" className="form-control" placeholder="" />
                                                        <ErrorMessage name="footerText" component="div" className="text-danger" />
                                                        <label className='fs-6 mt-4 fw-semibold'>Footer link</label>
                                                        <Field type="text" name="footerLink" className="form-control" placeholder="" />
                                                        <ErrorMessage name="footerLink" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container text-center">
                                        <div className="row">
                                            <div className="col">
                                                <button type="submit" className="btn text-light px-5 py-1 bootBtn my-5">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </>)}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default AdminSystemSettingsComponent;
