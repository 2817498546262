import React, { useState } from "react"
import assignmentWhiteIcon from "../../../icons/assignmentWhiteIcon.png"
import assignmentBlueIcon from "../../../icons/assignmentBlueIcon.png"
import _ from 'lodash';

const Student = (props) => {
  const [hoverEffect, setHoverEffect] = useState(false)
  const goToStudentCour = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'studentcourse' })
    }
  }
  return (
    <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'studentcourse' ? 'active' : ''} `}>
      <img
        src={_.get(props, 'userState.subType') != 'studentcourse' ? assignmentWhiteIcon : assignmentBlueIcon}
        alt='Assignment'
      />
      <div className='head-quotation' onClick={(e) => goToStudentCour(e)}>Students</div>
    </div>
  )
}

export default Student
