import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import { showErrorToast, showSuccessToast } from './../../../../../../../util/toastUtils'
import axios from "axios"
import { useNavigate } from 'react-router';
import { RotatingLines } from 'react-loader-spinner';
import './Test.scss'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BASE_URL from '../../../../../../../BaseUrl';
import Axios from '../../../../../../../util/apiUtil';
const Questionnaire = ({ rowId, update, ...props }) => {
    const navigate = useNavigate();
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Dashboard' })
        }
    }
    const BacktoTestpage = () => {
        navigate("/Test");
    }
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [showTable, setshowTable] = useState(false);
    const Teststudent = props.Teststudent || [];
    const [currentTest, setCurrentTest] = useState({});
    const [loading, setLoading] = useState(true)
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [AnswerSubmit, setAnswerSubmit] = useState([]);
    const [answers, setAnswers] = useState({});
    const [dataTableData, setDataTableData] = useState([]); // State to store data for the data table
    const [showTestReport, setShowTestReport] = useState(false);
    const Clickreport = () => {
        setShowTestReport(false);
    }
    // Function to update the data table
    const updateDataTable = (newData) => {
        setDataTableData([...dataTableData, newData]);
    };

    const [question, setQuestion] = useState([]);

    useEffect(() => {
        let obj = _.find(Teststudent, student => student.id == rowId)
        setCurrentTest(obj || {});
    }, rowId)

    const ListExam = () => {
        setTimeout(() => {
            navigate("/student", { state: { type: 'Student', subType: 'TestResults' } });
        }, 2000); // Delay for 2 seconds

    }


    const TestStudentSubmit = () => {

        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const formData = new FormData();
        formData.append('question_id', currentTest.question_id)
        // Initialize a variable to track correct answers
        let correctAnswers = 0;
        // Loop through questions and answers
        currentTest.question.forEach((question, index) => {
            const answerKey = `question${index + 1}`;
            const selectedAnswer = answers[answerKey] || ''; // Provide an empty string if answer is not selected
            const correctAnswer = question.answer;

            formData.append(`answer[${answerKey}]`, selectedAnswer);

            // Check if the selected answer is correct
            if (selectedAnswer === correctAnswer) {
                correctAnswers++;
            }
        });
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')), }
        Axios
            .post(
             '/api/answerQuestion`', formData,
                { headers: headers },
            )
            .then((res) => {
                if (res.data.success) {
                    setAnswerSubmit(res.data.data);
                    showSuccessToast("successfully Testcompleted");
                    // Teststudent();
                    ListExam();
                    if (update) {
                        update();
                    }
                }
                else {
                    //     // Check for specific error conditions and show corresponding error messages
                    setTimeout(() => {
                        if (res.data.error === 'Already answered for this question') {
                            showErrorToast('Already answered for this question.');

                            update();
                        }
                        else {
                            showErrorToast('Already answered for this question.');
                        }
                    }, 2000);

                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const handleNextQuestion = () => {
        resetOptions()
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    };
    const [Columns, setColumns] = useState([])
    const [shouldAddColumn, setShouldAddColumn] = useState(false);
    const newColumn = {
        name: "New Column",
        selector: row => row.new_data_field,
        width: "160px",
    };
    function addColumn() {
        const newColumn = {
            name: "New Column",
            selector: row => row.new_data_field,
            width: "160px",
        };

        setColumns([...columns, newColumn]);
        setShouldAddColumn(false); // Reset the flag to prevent adding the column multiple times
    }
    const customStyles = {
        headRow: {
            style: {
                color: "#fff",
                backgroundColor: "#5C5AB6",
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
            },
        },
    };
    const columns = [
        {
            name: "Date",
            selector: row => row.date,
            width: "160px",
        },

        {
            name: "Category",
            selector: row => row.category,
            sortable: true,
            grow: 2,
            width: "120px",
        },
        {
            name: "Sub Category",
            selector: row => row.sub_category,
            sortable: true,
            grow: 2,
            width: "150px",
        },
        {
            name: "Topic",
            selector: row => row.topic,
            sortable: true,
            grow: 2,
            width: "140px",
        },
        {
            name: "Batch",
            selector: row => row.batch_id,
            width: "140px",
        },
        {
            name: " No.of Qu",
            selector: row => row.message,
            width: "160px",
        },
        {
            name: " Mark",
            selector: row => row.mark,
            width: "160px",
        },
        {
            name: " Status",
            selector: row => row.message,
            width: "160px",
        },
        {
            name: "Action",
            selector: row => "Completed",
            width: "140px",
        },
    ];
    const handleOptionChange = (changeEvent, id) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [`question${id}`]: changeEvent.target.value,
        }));
        // Mark that an option has been selected
        setIsOptionSelected(true);
        // Move to the next question
    };

    const [selectedOption, setSelectedOption] = useState(null);

    const handleRadioChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue);

        handleOptionChange(e, currentQuestion + 1);
    };

    const resetOptions = () => {
        setSelectedOption(null);
    };

    return (
        <div>
            {_.get(currentTest, 'question') ? (
                <div>
                    <div class="mx-5">
                        <div class="container ">
                            <div class="row">
                                <div class="d-flex justify-content-center p-0 mt-4">
                                    <div class="col-xxl-8 col-xl-8 col">
                                        <div class="admin-card p-2">
                                            <div class="container text-start">
                                                <div class="row">
                                                    <div class="">
                                                        <div class="col ">
                                                            <div >
                                                                <div className='ques-1'>
                                                                    {/* Teststudent[currentQuestion].question[0].a */}
                                                                    <p className='fw-bold mb-0'> Question {currentQuestion + 1}</p>
                                                                    <hr />
                                                                </div>
                                                                <p className='fw-bold mx-4 '>Question</p>
                                                                <div className='col-lg-10 col-md-10 d-flex'>
                                                                    <div className='des-1 mx-xxl-5 mx-xl-5 mx-lg-5 mx-md-5 mx-sm-5 mx-0'>
                                                                        <p className='des-2 d-flex'>{currentTest.question[currentQuestion].question}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='ques-ans fw-bold mx-5 mt-5'>Answers</div>
                                                                <div className='ques-opt ms-5 ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-5 ps-sm-5 ps-0 mt-3'>Options</div>
                                                                <ul className='test-ul mx-xxl-5 mx-xl-5 mx-lg-5 mx-md-5 mx-sm-5 mx-0   ps-0'>
                                                                    <div class="container text-start">
                                                                        <div class="row mt-4" >
                                                                            <div class="col-1 ">
                                                                                <label for="inputPassword6" class="col-form-label fw-bold">A.</label>
                                                                            </div>
                                                                            <div class="col">
                                                                                <div class="col-auto inpt-1 p-1">
                                                                                    <input type='radio' className='mx-2' value="a" name="option" onChange={handleRadioChange}
                                                                                        checked={selectedOption === 'a'} required></input>
                                                                                    {currentTest.question[currentQuestion].a}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mt-4 " >
                                                                            <div class="col-1">
                                                                                <label for="inputPassword6" class="col-form-label fw-bold">B.</label>
                                                                            </div>
                                                                            <div class="col">
                                                                                <div class="col-auto inpt-1 p-1">
                                                                                    <input type='radio' className='mx-2' value="b" name="option" onChange={handleRadioChange}
                                                                                        checked={selectedOption === 'b'}></input>
                                                                                    {currentTest.question[currentQuestion].b}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mt-4 " >
                                                                            <div class="col-1">
                                                                                <label for="inputPassword6" class="col-form-label fw-bold">C.</label>
                                                                            </div>
                                                                            <div class="col">
                                                                                <div class="col-auto inpt-1 p-1">
                                                                                    <input type='radio' className='mx-2' value="c" name="option" onChange={handleRadioChange}
                                                                                        checked={selectedOption === 'c'}></input>
                                                                                    {currentTest.question[currentQuestion].c}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mt-4 " >
                                                                            <div class="col-1">
                                                                                <label for="inputPassword6" class="col-form-label fw-bold">D.</label>
                                                                            </div>
                                                                            <div class="col">
                                                                                <div class="col-auto inpt-1 p-1">
                                                                                    <input type='radio' className='mx-2' value="d" name="option" onChange={handleRadioChange}
                                                                                        checked={selectedOption === 'd'}></input>
                                                                                    {currentTest.question[currentQuestion].d}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>{currentQuestion === _.get(currentTest, 'question.length') - 1 ? (
                                                <div>
                                                    {showTestReport ? (
                                                        <div>
                                                            {/* Add your eye icon here to show the test report */}
                                                            <button className='start-btn' onClick={Clickreport}>
                                                                <RemoveRedEyeIcon /> Show Test Report
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className="container text-end my-4">
                                                            <div className="row me-5">
                                                                <div className="col">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn  next-btn rounded text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                                                                        onClick={TestStudentSubmit}
                                                                        disabled={!isOptionSelected}
                                                                    >
                                                                        {!loading ? <div className='d-flex justify-content-center' >
                                                                            <RotatingLines
                                                                                strokeColor="white"
                                                                                strokeWidth="5"
                                                                                animationDuration="0.75"
                                                                                width="25"
                                                                                height="25"
                                                                                visible={true}
                                                                            /></div> : "Submit"}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="container text-end my-4">
                                                    <div className="row me-5 ">
                                                        <div className="col">
                                                            <div>{currentQuestion < _.get(currentTest, 'question.length') && (
                                                                <button className='next-btn rounded' onClick={handleNextQuestion} disabled={!isOptionSelected}>Next</button>
                                                            )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                showTable && <DataTable
                    columns={shouldAddColumn ? [...columns, newColumn] : columns}
                    data={Teststudent}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="600px"
                    striped
                    customStyles={customStyles}
                    persistTableHead
                    pagination />
            )}
        </div>
    );
};

export default Questionnaire;