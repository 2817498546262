import { ErrorMessage, Field, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { ChatData, Login, UserData, UserLogin } from '../../redux/slice/AuthSlice';
import { showErrorToast, showSuccessToast } from '../../util/toastUtils';
import img from '../../images/signup.jpg';
import { LuMail } from "react-icons/lu";
import Axios from '../../util/apiUtil';

function SignInComponent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [error, setError] = useState(null);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .min(5, 'Password must be at least 5 characters'),
    });

    const initialValues = {
        email: '',
        password: '',
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const res = await dispatch(UserLogin(values));
        if (res.payload.data.success) {
            localStorage.setItem("user", JSON.stringify(res.payload.data));
            let studentdetails = JSON.parse(localStorage.getItem("user"));
            let user = _.get(res.payload, 'data.data.user') ? JSON.stringify(res.payload.data.data.user) : undefined;
            let token = _.get(res.payload, 'data.data.token') ? JSON.stringify(res.payload.data.data.token) : undefined;
            if (user) {
                localStorage.setItem("user", JSON.stringify(res.payload.data.data.user));
            }
            if (token) {
                localStorage.setItem("token", (res.payload.data.data.token));
            }
            if (res.payload.data.data.user.professional === "Student") {
                const token = res.payload.data.data.token;
                dispatch(Login(token));
                dispatch(UserData(res.payload.data.data.user));
                dispatch(ChatData(res.payload.data.data));
                navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
            }
            if (res.payload.data.data.user.professional === "Teacher") {
                const token = res.payload.data.data.token;
                dispatch(Login(token));
                dispatch(ChatData(res.payload.data.data));
                dispatch(UserData(res.payload.data.data.user));
                navigate("/teacher", { state: { type: 'Teacherpage', subType: 'DashboardTeacher' } });
            }
            if (res.payload.data.data.user.professional === "Admin") {
                const token = res.payload.data.data.token;
                dispatch(Login(token));
                dispatch(UserData(res.payload.data.data.user));
                navigate("/admin", { state: { type: 'admin', subType: 'DashboardAdmin' } });
            }
            if (res.payload.data.data.user.professional === "Freelancer") {
                const token = res.payload.data.data.token;
                dispatch(Login(token));
                dispatch(UserData(res.payload.data.data.user));
                navigate("/Freelancer", { state: { type: "Freelance", subType: "DashboardFreelance" } });
            }
            if (res.payload.data.data.user.professional === "Uploader") {
                const token = res.payload.data.data.token;
                dispatch(Login(token));
                dispatch(UserData(res.payload.data.data.user));
                navigate("/InstructorUpload", { state: { type: "InstructorUpload", subType: "DashboardUpload" } });
            }
            showSuccessToast("Login Success");
            setLoading(false);
        } else {
            showErrorToast(res.payload.data.error[0] || res.payload.data.error.password[0]);
            setLoading(false);
        }
    };


    const initialValuesfor = {
        email: '',
     
    };

    const handleForgotPassword = async (values) => {
        const formData = new FormData();
        formData.append('email', values.email);
        try {
            const response = await Axios.post('api/forgetpassword', formData);
            const data = response.data;
            if (data.success) {
                showSuccessToast('Password reset code sent');
                navigate("/forgotPassword");
            } else {
                setError(data.error);
                showErrorToast(data.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('An error occurred while fetching data.');
            showErrorToast('An error occurred while fetching data.');
        }
    };
    return (
        <div>
            <div className="container text-start">
                {!forgotPassword ? (
                    <>
                        <h2 className="admin-signIn-text">Log in</h2>
                        <p className="admin-signIn-para">Enter your email and password to sign in</p>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <div className="row">
                                        <div className="col">
                                            <Field
                                                type="text"
                                                name="email"
                                                className={`form-control admin-signIn-input ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                placeholder="Email"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <Field
                                                type="password"
                                                name="password"
                                                className={`form-control admin-signIn-input ${errors.password && touched.password ? 'is-invalid' : ''}`}
                                                placeholder="Password"
                                            />
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <p onClick={() => setForgotPassword(true)}>Forgot Password?</p>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="form-check form-switch">
                                                <Field
                                                    type="checkbox"
                                                    name="rememberMe"
                                                    className="form-check-input mt-2 admin-signIn-switch py-2"
                                                />
                                                <label
                                                    className="form-check-label admin-signIn-Remember ms-2"
                                                    htmlFor="flexSwitchCheckDefault"
                                                >
                                                    Remember me
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <button
                                                type="submit"
                                                className="btn admin-signIn-btn"
                                                disabled={loading}
                                            >
                                                <span className="admin-signIn-btn-text">
                                                    {loading ? (
                                                        <div className='d-flex justify-content-center'>
                                                            <RotatingLines
                                                                strokeColor="white"
                                                                strokeWidth="5"
                                                                animationDuration="0.75"
                                                                width="40"
                                                                height="40"
                                                                visible={true}
                                                            />
                                                        </div>
                                                    ) : "Log in"}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="row mt-4">
                            <div className="col">
                                <p className="admin-signIn-account">
                                    Don't have an account?{' '}
                                    <span className="admin-signIn-account-signUp" onClick={() => navigate('/user/sign_up')}>Sign up</span>
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                    <h2 className="admin-signup-text">Reset Password</h2>
                    <div className='row'>
                        <div className='col'>
                            <hr />
                        </div>
                        <div className='col-1'>
                          
                        </div>
                        <div className='col'>
                            <hr />
                        </div>
                    </div>
                    <Formik
                        initialValues={initialValuesfor}
                        validationSchema={Yup.object({
                            email: Yup.string()
                                .email('Invalid email address')
                                .required('Email is required')
                        })}
                        onSubmit={handleForgotPassword} // Use handleForgotPassword for form submission
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <div className="row mt-5">
                                    <div className="col">
                                        <Field
                                            type="email"
                                            name="email"
                                            className={`form-control admin-signIn-input ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                            placeholder="Email"
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col">
                                        <button
                                            type="submit"
                                            className="btn admin-signIn-btn"
                                        >
                                            <span className="admin-signIn-btn-text">
                                                Send code <span className='me-2'><LuMail /></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div className="row mt-5">
                        <div className="col">
                            <p className="admin-signIn-account">
                                Remembered your password?{' '}
                                <span className="admin-signIn-account-signUp" onClick={() => setForgotPassword(false)}>Sign in</span>
                            </p>
                        </div>
                    </div>
                </>
            )}
       
            </div>

           
        </div>
    );
}

export default SignInComponent;
