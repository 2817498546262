import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import './ViewNotification.scss'
import axios from 'axios';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function ViewNotification(props) {
    const [view, setView] = useState()

    const ViewBatch = props.userState.row.id || {};
    const [viewNotification, setNotification] = useState([{
        id: '',
        totalStudents: [],
    }])

    const fetchNotification = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const CreateNotifi = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("id", ViewBatch)
        Axios.post('/api/viewNotification',
            formData,
            { headers: headers })
            .then((response) => {
                console.log(response)
                const { notification, totalStudents } = response.data.data;
                setView(notification);
                setNotification({ ...notification, totalStudents: Object.entries(totalStudents) });
            })
            .catch((error) => {
                console.error("Error fetching attendance data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });
    }
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])
    const initializeObject = () => {
        setNotification({ ...viewNotification, ...props.userState.row })
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachernotification' })
        }
    }
    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const columns = [
        {
            name: 'Student ID',
            selector: row => row[0], 
            sortable: true,
        },
        {
            name: 'Student Name',
            selector: row => row[1], 
            sortable: true,
        },

    ]

    useEffect(() => {
        fetchNotification();
    }, []);

    console.log("viewNotification",setNotification);
    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Notification</div>
            </div>

            <div className='Main-head-viewschedule'>View Notification</div>

            <div class="container text-start">
                {/* <div class="row mt-5">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{viewNotification.category}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Sub Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{viewNotification.sub_category}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Topic : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{viewNotification.des}</p>
                        </div>
                    </div>
                </div> */}
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Batch : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{viewNotification.batchname}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Mesage for students : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{viewNotification.message}</p>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center">
                    <div class="col-xxl-8   col-xl-10 col-lg-12   justify-content-center">
                        <div class="table-responsive">
                            <DataTable
                                className='dataTable'
                                data={viewNotification.totalStudents}
                                columns={columns}
                                customStyles={customStyles}
                                striped
                                persistTableHead
                                pagination
                                paginationPerPage={10}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewNotification