
import { Button } from "@mui/material";
import React from "react"
import { FaFacebook, FaYoutube, FaTwitter, FaInstagram, FaWhatsapp } from "react-icons/fa";
import './Section5_footer.scss'
import { RiFacebookCircleLine } from "react-icons/ri";
const Footer = () => {
  var whatsappNo = {
    phone: "91887 30607",
    name: "sojan p a"
  }

  return (
    <div className="footer">
      <p className="footer-head pt-5 ">
        Subscribe to get our Newsletter
      </p>
      <div className="container">
        <div className="row text-center">

        <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-flex justify-content-center">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12  text-center">
              <input type="email" className="form-control rounded-pill landing-email-inp" id="exampleFormControlInput1" placeholder="Your Email" />
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 ms-xxl-0 ms-xl-0 ms-lg-0 ms-md-3 ms-sm-3 ">
              <button type="button" className="footer-sub rounded-pill p-2 px-4 py-2">Subscribe</button>
            </div>
            </div>
        </div>
      </div>
      <div class="container text-center">
        <div class="row justify-content-center  my-4">
          <div class=" d-flex justify-content-center" >
          <div class="col-12">
            <p><span>
              <a class=" footer-head" href="#">Careers |</a></span><span >
                <a class=" ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-0 footer-head" href="#">Privacy Policy |</a></span> <span>
                <a class=" ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-0 footer-head" href="#">Terms & Conditions </a></span></p>
          </div>
          </div>
        </div>
      </div>
      <div className="row text-center">
      <div class=" d-flex justify-content-center">
        <div className="footer-copyright-bottom">
          © {new Date().getFullYear()} Global EyeT Software Solutions Pvt Ltd
        </div>
        </div>

        <div className="col d-flex justify-content-end">
          <div className="yt me-3 ">< FaYoutube /></div>
          <div className="fb me-3"> <RiFacebookCircleLine /></div>
          <div className="tw me-3">  < FaTwitter /></div>
          <div className="tw me-3">  < FaInstagram /></div>
          <div className="whats me-3">
            <FaWhatsapp />
          </div>
        </div>        
        </div>
    </div>
  )
}
export default Footer
