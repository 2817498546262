import React, { useState } from 'react'
import dashboardWhiteIcon from "../../../icons/dashboardWhiteIcon.png"
import dashboardBlueIcon from "../../../icons/dashboardBlueIcon.png"
import _ from "lodash"

function DashboardFreelance(props) {

    const [hoverEffect, setHoverEffect] = useState(false)

    const goToDashboardFreelance = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'dashboardfreelance' })
        }
    }


    return (
        <div
            className={`sidebar_content ${_.get(props, 'userState.subType') == 'dashboardfreelance' ? 'active' : ''} `}
        >
            <div className="dash d-flex">
                <img
                    src={_.get(props, 'userState.subType') != 'dashboardfreelance' ? dashboardWhiteIcon : dashboardBlueIcon}
                    alt='Dashboard'
                />
                <p className='head-dashboard' onClick={(e) => goToDashboardFreelance(e)}> Dashboard
                </p>
            </div>

        </div>
    )
}

export default DashboardFreelance