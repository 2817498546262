import React, { useState, useEffect, } from 'react'
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import Modal from 'react-modal';
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import './CreateNotification.scss'
import { showErrorToast, showSuccessToast } from "../../../../../../util/toastUtils"
import { InputGroup } from 'react-bootstrap';
import Select from "react-select";
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function CreateNotification(props) {
    const navigate = useNavigate()
    const [user, setUser] = useState([])
    const [Edit, setEdit] = useState()
    const [errors, setErrors] = useState([""]);

    const CategoryOptions = ['category1', 'catgory2', 'category3', 'category4']
    const SubCategoryOptions = ['subcategory1', 'subcategory2', 'subcategory3', 'subcategory4']
    const TopicOptions = ['chapter1', 'chapter2', 'chapter3', 'chapter4']
    const BatchOptions = ['1', '2', '3', '4']
    const StudentOptions = ['1', '2', '3', '4']
    const [batches, setBatches] = useState([])
    const [studentList, setStudentList] = useState([])
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [filteredBatches, setFilteredBatches] = useState([]);


    const handleChange = (e) => {
        setNotification({ ...notification, [e.target.name]: e.target.value })
        setErrors({ ...errors, [e.target.name]: " " })

        if (e.target.name === "batch") {
            // Update the student list based on the selected batch
            listStudents(e.target.value);
        }
    }



    const [topic, setTopic] = useState([{}])
    const [subCategories, setSubCategories] = useState([{}])
    const [categories, setCategories] = useState([{}])
    const [loading, setLoading] = useState(true)
    const [totalList, setTotalList] = useState([]);
    const [subject, setSubject] = useState([{}])
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [topicOptions, setTopicOptions] = useState([]);

    const NotificationValidationSchema = Yup.object().shape({
        category: Yup.string().required('category is required'),
        sub_category: Yup.string().required('sub Category is required'),
        des: Yup.string().required('topic is required'),
        batch: Yup.string().required('batch is required'),
        message: Yup.string().required('message is required'),
        id: Yup.string().required('id  is required'),
        students: Yup.string().required('student is required'),
    });



    const [notification, setNotification] = useState({
        email: '',
        category: '',
        sub_category: '',
        des: '',
        batch: '',
        students: '',
        message: '',
        id: '',
    })

    const listSubject = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }

        // const createSchedule = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/listSubject', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubjectObject = response.data.data;
                    const SubjectArray = Object.values(SubjectObject);
                    setSubject(SubjectArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
                setLoading(false);
            });
    };


    // const listBatches = (id) => {
    //     if (!localStorage.getItem('token')) {
    //         Backdashboard();
    //     }
    //     const formData = new FormData();
    //     const headers = { Authorization: 'Bearer ' + (localStorage.getItem('token')) };

    //     axios
    //         .post('http://tuition.techiesmarkets.com/backend/public/api/listBatch', formData, { headers: headers })
    //         .then((response) => {
    //             if (_.get(response, "data.data")) {
    //                 const BatchObject = response.data.data;
    //                 const BatchArray = Object.values(BatchObject);
    //                 setBatches(BatchArray);

    //                 if (BatchArray.length > 0) {
    //                     listSubCategories(BatchArray[0].category_id);
    //                     listStudents(BatchArray[0].id);

    //                 }
    //             }
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching batches:', error);
    //             setLoading(false);
    //         })
    // }


    useEffect(() => {
        listBatches();
    }, []);
    
    const listBatches = () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setLoading(false);
            return;
        }
    
        const headers = { Authorization: 'Bearer ' + token };
    
        Axios.post('/api/listBatch', null, { headers })
            .then((response) => {
                if (response.data.success) {
                    const batchData = response.data.data.batch;
                    setBatches(batchData);
                    setFilteredBatches(batchData); // Initialize filtered batches
                    console.log('Batches:', batchData); // Add log here
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching batches:', error);
                setLoading(false);
            });
    };
    

    const listStudents = (batchId) => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
        }
        const formData = new FormData()
        formData.append('id', batchId || 0)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post("/api/listBatchStudents", formData, { headers: headers })
            .then((response) => {
                if (response.data.data) {
                    const studentListData = response.data.data;

                    // Convert the object of student names and IDs into an array of objects
                    const studentListArray = Object.keys(studentListData).map(studentName => ({
                        value: studentListData[studentName],
                        label: studentName,
                    }));
                    setStudentList(studentListArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching students:', error);
                setLoading(false);
            })
    }

    useEffect(() => {
        listBatches()
        listStudents()
        listSubject()
    }, [])



    const listSubCategories = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
        }

        const createSchedule = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {}
        const formData = new FormData()
        formData.append('type', 2)

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post("/api/getCategoryandTopicDetails", formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubcategoriesObject = response.data.data;
                    const SubcategoriesArray = Object.values(SubcategoriesObject)
                    setSubCategories(SubcategoriesArray)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error)
                setLoading(false)
            })
    }





    const ToSubmit = (values) => {
        const CreateNotifi = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("email", _.get(CreateNotifi, 'data.email'));
        formData.append("category", selectedSubject.category);
        formData.append("sub_category", selectedSubject.sub_category);
        formData.append("des", selectedSubject.des);
        formData.append("batch", notification.batch)
        // formData.append("students[]", notification.students)


        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("students[]", record.value || []);
        }

        formData.append("message", notification.message)
        formData.append("id", notification.id)

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post("/api/createnotification",
            formData,
            { headers: headers })
            .then((res) => {

                if (res.data.success) {
                    const createData = _.get(res, 'data.data.createData') ? JSON.stringify(res.data.data.createData) : undefined;
                    const token = _.get(res, 'data.data.token') ? JSON.stringify(res.data.data.token) : undefined;
                    if (createData) {
                        localStorage.setItem("createData", JSON.stringify(res.data.data.createData));
                    }
                    if (token) {
                        localStorage.setItem("token", (res.data.data.token));
                    }
                    showSuccessToast("Notification created successfully");
                    Backdashboard()
                }
                console.log(res.data)

            });
    };

    const ToEdit = (values) => {
        const CreateNotifi = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("email", _.get(CreateNotifi, 'data.email'));
        formData.append("category", selectedSubject.category);
        formData.append("sub_category", selectedSubject.sub_category);
        formData.append("des", selectedSubject.des);
        formData.append("batch", notification.batch)
        // formData.append("students[]", notification.students)
        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("students[]", record.value || []);
        }
        formData.append("message", notification.message)
        formData.append("id", notification.id)

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post("/api/updatenotification",
            formData,
            { headers: headers })

            .then((res) => {

                console.log(res)
                const updateData = res.data
                setEdit(updateData)
                console.log(res.data)

                Backdashboard()
                showSuccessToast("Edit Notification successfully");
            });
    };

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])
    const initializeObject = () => {
        setNotification({ ...notification, ...props.userState.row })
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachernotification' })
        }
    }

    const dispatch = useDispatch();
    const data = useSelector(CSTData);
    const subCatData = useSelector(SubData);
    const TopicData = useSelector(topicData);




    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
    }, []);


    // const categoryOptions = data?.map((e) => ({
    //     value: e.id,
    //     label: e.name
    // }));


    const SubCategoryOptionsList = subCatData?.map((e) => ({
        value: e.id,
        label: e.name
    }));

    const TopicOptionsList = TopicData?.map((e) => ({
        value: e.id,
        label: e.name
    }));


    const handleMultipleChange = (selectList) => {
        console.log(selectList);
        setTotalList(selectList || []);
    };


    const handleSubjectChange = (e) => {
        const subjectId = parseInt(e.target.value); // Convert to integer
        console.log('Selected Subject ID:', subjectId);
    
        const selectedSubject = subject.find(sub => sub.id === subjectId);
        setSelectedSubject(selectedSubject);
    
        if (selectedSubject) {
            // Filter batches by selected subject ID
            const filteredBatches = batches.filter(batch => parseInt(batch.subject_id) === subjectId); // Ensure type match
            setFilteredBatches(filteredBatches);
            console.log('Filtered Batches:', filteredBatches);
    
            // Populate category options
            const categories = [{ value: selectedSubject.category, label: selectedSubject.category_name }];
            setCategoryOptions(categories);
    
            // Populate subcategory options
            const subCategories = [{ value: selectedSubject.sub_category, label: selectedSubject.sub_category_name }];
            setSubCategoryOptions(subCategories);
    
            // Populate topic options
            const topics = [{ value: selectedSubject.subject, label: selectedSubject.topic_name }];
            setTopicOptions(topics);
        }
    };
    
    



    const newArray = studentList.filter(mainItem => !totalList.some(selectItem => selectItem.value === mainItem.value && selectItem.label === mainItem.label));
console.log('Selected Subject:', selectedSubject);
console.log('Filtered Batches:', filteredBatches);

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Notification</div>
            </div>

            <div className='container mt-3'>
                <div className="row">
                    <div className='col-12'>
                        {!_.get(props, 'userState.row') && <div className='main-schedule-head ms-xxl-5 ms-xl-5 ms-lg-3 ms-md-0' ><p className='Title-main-head'>Create Notification</p></div>}
                        {_.get(props, 'userState.row') && <div className='main-schedule-head ms-5'><p className='Title-main-head'>Edit Notification</p></div>}
                    </div>
                </div>
                <Formik

                    initialValues={{
                        category: '',
                        sub_category: '',
                        des: '',
                        batch: '',
                        students: '',
                        message: '',
                        id: '',
                    }}
                    validationSchema={NotificationValidationSchema}
                    onSubmit={(values, { resetForm }) => {

                        ToSubmit();
                        resetForm();

                    }}
                >

                    {({ errors, touched, setFieldValue }) => (
                        <Form>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>


                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4  subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Course</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={notification?.subject}
                                                as="select"
                                                name="subject"
                                                onChange={handleSubjectChange} >

                                                <option value="" >Select an option</option>
                                                {subject.map((subject, index) => (
                                                    <option key={index} value={subject.id}>
                                                        {subject.name}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}

                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Course</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={notification?.subject}
                                                as="select"
                                                name="subject"
                                                onChange={handleSubjectChange} >

                                                <option value="" >Select an option</option>
                                                {subject.map((subject, index) => (
                                                    <option key={index} value={subject.id}>
                                                        {subject.name}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>


                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={notification.category}
                                                name="category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 2,
                                                        "category_id": selectedValue,
                                                    };
                                                    dispatch(getSubCategoryData(payload));
                                                    setFieldValue("category", e.target.value)
                                                    handleChange(e);
                                                }}
                                            >

                                                {categoryOptions?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2  mb-0 teacher-font-label">Choose Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={notification.category}
                                                name="category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 2,
                                                        "category_id": selectedValue,
                                                    };
                                                    dispatch(getSubCategoryData(payload));
                                                    setFieldValue("category", e.target.value)
                                                    handleChange(e);
                                                }}
                                            >

                                                {categoryOptions?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 ms-md-0 mb-0 teacher-font-label">Choose Sub Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={notification.sub_category}
                                                name="sub_category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 3,
                                                        "sub_category_id": selectedValue,
                                                    };
                                                    dispatch(getSubTopicData(payload));
                                                    setFieldValue("sub_category", e.target.value)
                                                    handleChange(e);
                                                }}
                                            >

                                                {subCategoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="sub_category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label mb-0 teacher-font-label">Choose Sub Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={notification.sub_category}
                                                name="sub_category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 3,
                                                        "sub_category_id": selectedValue,
                                                    };
                                                    dispatch(getSubTopicData(payload));
                                                    setFieldValue("sub_category", e.target.value)
                                                    handleChange(e);
                                                }}
                                            >

                                                {subCategoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="sub_category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>


                            </div>
                            <br />

                            <div className='row px-xxl-5 px-xl-4 px-lg-3 px-md-2'>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Topic</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={notification.des}
                                                name="des"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("des", e.target.value)
                                                    handleChange(e)
                                                }}
                                            >

                                                {topicOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="des"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0  mb-0 teacher-font-field">Choose Topic</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label"
                                                value={notification.des}
                                                name="des"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("des", e.target.value)
                                                    handleChange(e)
                                                }}

                                                required=""
                                            >

                                                {topicOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="des"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                           <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 ms-md-0 mb-0 teacher-font-label">Choose Batch</label>
<Field
    className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
    value={notification.batch}
    name="batch"
    as="select"
    onChange={(e) => {
        handleChange(e);
        const selectedBatchId = e.target.value;
        listStudents(selectedBatchId);
        setFieldValue("batch", e.target.value);
    }}
    required=""
>
    <option value="">Select an option</option>
    {filteredBatches.map((batch, index) => (
        <option key={index} value={batch.id}>
            {batch.name}
        </option>
    ))}
</Field>

                                            <ErrorMessage
                                                name="batch"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-field">Choose Batch</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label"
                                                value={notification.batch}
                                                name="batch"
                                                as="select"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const selectedBatchId = e.target.value;
                                                    listStudents(selectedBatchId);
                                                    setFieldValue("batch", e.target.value)
                                                }}

                                                required=""
                                            >
                                                <option value="" >Select an option</option>
                                                {batches.map((batch, index) => (
                                                    <option key={index} value={batch.id}>
                                                        {batch.name}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="batch"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Student</label>
                                            {/* <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={notification.students}
                                                name="students"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("students", e.target.value)
                                                    handleChange(e)
                                                }}

                                                required=""
                                            >
                                                <option value="" >Select an option</option>
                                                {studentList.map((student, index) => (
                                                    <option key={index} value={student.id}>
                                                        {student.name}
                                                    </option>
                                                ))}
                                            </Field> */}

                                            <InputGroup>
                                                <Select
                                                    value={totalList}
                                                    onChange={handleMultipleChange}
                                                    options={studentList}
                                                    className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                    isMulti
                                                />

                                            </InputGroup>

                                            <ErrorMessage
                                                name="students"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Student</label>
                                            {/* <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={notification.students}
                                                name="students"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("students", e.target.value)
                                                    handleChange(e)
                                                }}

                                                required=""
                                            >
                                                <option value="" >Select an option</option>
                                                {studentList.map((student, index) => (
                                                    <option key={index} value={student.id}>
                                                        {student.name}
                                                    </option>
                                                ))}
                                            </Field> */}

                                            <InputGroup>
                                                <Select
                                                    value={totalList}
                                                    onChange={handleMultipleChange}
                                                    options={studentList}
                                                    className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                    isMulti
                                                />

                                            </InputGroup>

                                            <ErrorMessage
                                                name="students"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>

                            </div>

                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-3'>
                                <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-12 subtitle-headings'> <>
                                    {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >message For student</label>}<br />
                                    {!_.get(props, 'userState.row') && <Field type="textarea" class="form-control mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field" placeholder="Leave a comment here" value={notification.message} name='message' onChange={(e) => {
                                        setFieldValue("message", e.target.value)
                                        handleChange(e)
                                    }} required="" style={{ height: '100px' }} />}

                                    <ErrorMessage
                                        name="message"
                                        component="div"
                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                    />

                                </>
                                    {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2  mb-0 teacher-font-label" >message For student</label>}<br />
                                    {_.get(props, 'userState.row') && <input type='text' className='form-control ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-field' value={notification.message} name='message' onChange={(e) => {
                                        setFieldValue("message", e.target.value)
                                        handleChange(e)
                                    }} style={{ height: '100px' }} />}
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    {!_.get(props, 'userState.row') && <button className='btn text-light px-4 py-1 bootBtn my-5' type='submit' onClick={(e) => ToSubmit()}>Submit</button>}
                                    {_.get(props, 'userState.row') && <button className='btn text-light px-4 py-1 bootBtn my-5' type='submit' onClick={(e) => ToEdit()}>Update</button>}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>

    )
}

export default CreateNotification