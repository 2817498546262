import React, { useEffect, useState } from "react";

import UpcomingClasses from "./UpcomingClassess";

// import UserDetails from "./components/UserDetails"
// import { decrement, increment } from "../../../../../../redux/displayer"
import { useDispatch, useSelector } from "react-redux";
// import EnrolledCourses from "../../../PageComponents/EnrolledCourses"
import { logDOM } from "@testing-library/react";
// import { enrolledCourses } from "../../../../../../redux/displayer.js"
import "./UserPageLogged.scss";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
function UserDashboardContents() {
  const navigate = useNavigate();
  // const [enrolledCourses, setEnrolledCourses] = useState(false)
  // const count = useSelector((state) => state.pageDisplay.enrolledCourses)

  // console.log(count)

  // const dispatch = useDispatch()

  // useEffect(() => {
  //   setEnrolledCourses(count)
  // }, [count])
  // const navgatetohIRE = (e) => {
  //   navigate("/UserPageLogged")
  // }
  const customStyles = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#a7a7c1",
        fontSize: "13px",
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
      },
    },
    // cells: {
    //     style: {
    //        margin: '0px', // override the cell padding for data cells
    //         paddingRight: '0px',
    //     },
    // },
  };
  const [filterText, setFilterText] = useState('');

  const columns = [
    {
      name: "Date Starts",
      selector: (row) => row.no,
      width: "120px",
    },
    {
      name: "Date Ends",
      selector: (row) => row.jobseeker_regno,
      sortable: true,
      width: "120px",
    },
    {
      name: "Category",
      selector: (row) => row.jobseeker,
      sortable: true,
      grow: 2,
      width: "120px",
    },
    {
      name: "Sub Category",
      selector: (row) => row.JobfairID,
      sortable: true,
      grow: 2,
      width: "150px",
    },
    {
      name: "Topic",
      selector: (row) => row.reporting_time,
      sortable: true,
      grow: 2,
      width: "140px",
    },
    {
      name: "Batch",
      selector: (row) => row.volunteer,
      width: "90px",
    },
    {
      name: " Status",
      selector: (row) => row.status,
      width: "90px",
    },
    {
      name: "View",
      selector: (row) => row.AppliedStatus,
      width: "90px",
    },
  ];

  const [rows, setRows] = useState([]);
  const getRows = () => {
    let res = [];
    res 
      .filter(
        (item) =>
          (
            item.DateStarts
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (
            item.DateEnds.toLowerCase().includes(filterText.toLowerCase())) ||
          (
            item.category.toLowerCase().includes(filterText.toLowerCase())) ||
          (
            item.subcategory.toLowerCase().includes(filterText.toLowerCase())) ||
          (
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (
            item.topic.toLowerCase().includes(filterText.toLowerCase())) ||
          (
            item.batch.toLowerCase().includes(filterText.toLowerCase()))
      )
      .map((item, index) => {
        return {
          no: index + 1,
          DateStarts: item.DateStarts,
          DateEnds: item.DateEnds,
          category: item.category,
          status: item.status,
        };
      });
    setRows(res);
  };
  return (
    <div className="assignment-container">
      <DataTable
        columns={columns}
        data={rows}
        fixedHeader={true}
        fixedHeaderScrollHeight="600px"
        striped
        customStyles={customStyles}
        persistTableHead
        pagination
      />
    </div>
  );
}

export default UserDashboardContents;
