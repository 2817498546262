import React from 'react'
import { useNavigate, navigate } from 'react-router-dom';
import './Freelancer.css'

function Freelancer() {
  return (
    <div className='freelancer'>
      <div className='freelancer-nav'>
        <h2>Quotation</h2>
      </div>
      <div className='body'>
        <div className='container'>
          <h3 style={{textAlign:'center'}}>Get a Quote (Exam , Project , Homework etc)</h3>
          <form>
            <div className="form-row">
              <div className="form-group">
                <label for="first-name">Category</label>
                <input type="text" />
              </div>
              <div className="form-group">
                <label for="last-name">Sub Category</label>
                <input id="last-name" type="text" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label for="location">Topic</label>
                <input id="location" type="text" />
              </div>
              <div className="form-group">
                <label for="some-input">Quote Closing Date</label>
                <input id="some-input" type="text" />
              </div>  
            </div>
            <div>
              <label>Describe your proposal</label>
              <textarea rows="5" className='text-area'/>
            </div>
            <div>
              <label>Attach Files</label>
              <input style={{width:"180px"}}/>
            </div>
          </form>
        </div>
        <button className='freelancer-btn'>Request Quotes</button>
      </div>
    </div>
  )
}

export default Freelancer