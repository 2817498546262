import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { freelanceLoading, uploadListData, uploaderID, uploaderList } from '../../../../../../redux/slice/freelanceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import CSTComponent from './CST.component';
import { RotatingLines } from 'react-loader-spinner';
import { useSelect } from '@mui/base';

function AdminInstructorUploadsComponent(props) {

    const dispatch = useDispatch()
    const uploadData = useSelector(uploadListData)
    const loading = useSelector(freelanceLoading);

    useEffect(() => {
        dispatch(uploaderList());
    }, []);

    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.created_at,
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.created_at ? dateFunction(row.created_at) : ""}
                    </div>
                );
            }
        },
        {
            name: 'Uploader',
            selector: row => row.firstname,
            sortable: true
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.status === "1" ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}
                    </div>
                )
            }
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <button type="button" class="btn text-light px-3 py-1 bootBtn" onClick={() => handleClickCategory(row)}>Details</button>
                </div>
            )
        }
    ];


    const goToAdminInstructorDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminInstructorDetails' })
        }
    }

    function handleClickCategory(row) {
        goToAdminInstructorDetails();
        dispatch(uploaderID(row.id));
    }


    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >
                <div className='Sub-Head2'>Instructor Uploads</div>
            </div>
            <div>
                <div class="container text-start my-5 px-5">
                    <div class="row mt-5">
                        <CSTComponent />
                    </div>
                    <div class="row col-xxl-12 col-xl-12 col-lg-12 col-md-12-col-sm-12">
                        {loading ? <div className='d-flex justify-content-center' style={{ height: '40vh' }}><RotatingLines
                            strokeColor="#5C5AB6"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            height="50"
                            visible={true}
                        /></div> :
                            <DataTable
                                className='dataTable mt-5 '
                                data={uploadData}
                                columns={columns}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                striped
                                customStyles={customStyles}
                                persistTableHead
                                pagination
                            />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminInstructorUploadsComponent