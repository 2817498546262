import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component";
import './Wallet.scss'
import _ from 'lodash';
import { format } from 'date-fns';
import axios from "axios"
import LoadingSpinner from "../../../../../../Reactloader/LoadingSpinner";
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";
function Wallet({ ...props }) {
 

  const [loading, setLoading] = useState(true)
  const [totalCourses, setTotalCourses] = useState("");
  const [loader, setLoader] = useState(true);
  const [responseDataAmount, setResponseDataAmount] = useState(0); 
  const [TotalCourse, setTotalCourse] = useState(0); 
  const [wallet, setWallet] = useState([{

  }])
  const [walletlist, setWalletlist] = useState([{
    totalAmount: "",
    totalCourses: "",
    created_at: "",
    category: "",
    sub_category: "",
    subject: "",
    price: "",
    id: '',
    discount: "",
    payment: "",
    level: "",
    paid_status: "",
    purchase_id: "",
    course_amount: ''

}]);
  const customStyles = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#5C5AB6",
        fontSize: "13px",
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
      },
    },
  };
  const fetchWallet = () => {
    if (!localStorage.getItem('token')) {
        Backdashboard();
    }
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
    Axios.post('/api/wallet' , undefined, { headers: headers })
        .then((response) => {
            const responseData = response.data.data;
           
            setWallet(responseData);
            setLoading(false);
            const responsetrans = response.data.data.transactions;
           
            setWalletlist(responsetrans)
        })
        .catch((error) => {
            console.error('Error fetching wallet:', error);
            setLoading(false);
        })
}

  
  const columns = [
    {
      name: "Course Name",
      selector: row => row.date,

    },

    {
      name: "Course Name",
      selector: row => row.name,

    },
    {
      name: "Category",
      selector: row => row.category_name,

    },
    {
      name: "Sub Category",
      selector: row => row.sub_category_name,
    },
    {
      name: "Topic",
      selector: row => row.topic_name,
      sortable: true
    },
  
    
    {
      name: " Balance Amount",
      selector: row => row.course_amount,

    },
  

  ];

  useEffect(() => {
    fetchWallet();
    // Simulate loading delay with a setTimeout
    setTimeout(() => {
      setLoader(false);
      ; // Set Loader to false when data is loaded
    }, 500); // 2 seconds delay
  }, []);
  const [rows, setRows] = useState([
    { name: "dfd" },
  ]);
  const getRows = () => {
    let res = [];
    setRows(res);
  };

  const Backdashboard = () => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Dashboard' })
    }
  }
  return (
    <div className='sidebar_content'>
      {/* {loader ? <LoadingSpinner /> :  */}
      <div className="main-divHead">
        <div className="sub-Head1">
          <div className="Sub-Head2">My Wallet</div>
        </div>
        <div class="container text-start mt-3">
          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-lg-5 col-md-4 col">
              <div className="total-course p-xxl-4 p-xl-4 p-lg-4 p-md-4 ps-0">
                <button className="total border border-dark">
                  <p className="fw-semibold text-capitalize m-0 px-4 py-xxl-4 px-xl-4 px-lg-3 py-xl-4 py-lg-3 fs-6">Total Courses:{TotalCourse}</p>
                </button>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-lg-5 col-md-4 col">
              <div className="total-course p-xxl-4 p-xl-4 p-lg-4 p-md-4 ps-0">
                <button className="total border border-dark">
                  <p className="fw-semibold text-capitalize m-0 px-4 py-xxl-4 px-xl-4 px-lg-3 py-xl-4 py-lg-3 fs-6">Total Balance:{responseDataAmount}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5 mt-5 p-xxl-4 p-xl-4 p-md-4 p-sm-4">
            <DataTable
              columns={columns}
              data={walletlist}
              fixedHeader={true}
              fixedHeaderScrollHeight="600px"
              striped
              customStyles={customStyles}
              persistTableHead
              pagination
            />
          </div>
        </div>

      </div>
      {/* } */}
    </div>
  )
}

export default Wallet
