import React, { useEffect, useState } from "react";
import { useNavigate, } from "react-router-dom";
import "./Chats.scss";
import {
  showErrorToast,
  showSuccessToast,
} from "./../../../../../../../util/toastUtils";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import StarRatings from "react-star-ratings";
import _ from "lodash";
import { MdVideoCameraBack } from "react-icons/md";
import { RiArrowRightLine } from "react-icons/ri";
import { PiMonitorPlayBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { activeSubjectId, viewSubject, viewSubjectData } from "../../../../../../../redux/slice/studentSlice";
import { FaFacebook, FaImage, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import './subject.css'
import img1 from "../../../../../images/profile.jpg"
function Studentchatside({ Backdashboard, ...props }) {
  const ViewCourses = props.userState || {};
  const dispatch = useDispatch();
  const activeSub = useSelector(activeSubjectId);
  const subData = useSelector(viewSubjectData);
  useEffect(() => {
    const payload = {
      "subject_id": activeSub
    }
    dispatch(viewSubject(payload))
  }, []);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState();
  const navigate = useNavigate();
  const basePath = "http://tuitionguruji.com/backend/public/";

  const StudentPurchasecourse = () => {
    if (!localStorage.getItem("token")) {
      Backdashboard(false, true);
    }

    const amount = parseInt(_.get(ViewCourses.selectedCourse, "price") || 0);
    const tenPercentOfSubtotal = 0.1 * amount;
    const total = amount + tenPercentOfSubtotal
    console.log('price', amount);
    let formData = new FormData();

  };
  const actualPrice = _.get(subData, 'subject.price') || 0;
  const offerPrice = actualPrice * 0.9;
  const displayPrice = Number.isInteger(actualPrice) ? `${actualPrice}` : actualPrice;
  const BackTodashboard = () => {
    navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
  }
  useEffect(() => {
    // Simulate loading delay with a setTimeout
    setTimeout(() => {
      setLoader(false);
      // Set Loader to false when data is loaded
    }, 500); // 2 seconds delay
  }, []);
  const [loader, setLoader] = useState(true);

  const [Content, setcontent] = useState([
    {
      name: "Get Started",
      name1: "content1",
      name2: "content2",
      name3: "content3",
      name4: "content4",
      name5: "end course",
    },
  ]);
  const content = (item) => {
    setcontent([...content]);
  };

  const goToPlaceorder = (data) => {
    // StudentPurchasecourse();
    // // window.location =  ̰PlaceOrder";
    navigate("/PlaceOrder", {
      state: ViewCourses,
    });
  };
  const getTimeForday = (day) => {
    let res = "-";
    _.forEach(_.get(subData, 'subject.schedule_day.schedule_time'), time => {
      if (time.day == day) {
        res = time.from + "-" + time.to
      }
    })
    return res
  }
  const handlePreviewClick = () => {
    window.open(subData.subject.video_link, '_blank');
  };

  const handlefacebook = () => {
    if (subData.teacherProfile && subData.teacher.facebook) {
      window.open(subData.teacher.facebook, '_blank');
    } else {
      console.error("Facebook URL is not available.");
      // Optionally, you can handle this case in another way (e.g., display a message to the user)
    }
  };
  const handletwitter = () => {
    if (subData.teacher && subData.teacher.twitter) {
      window.open(subData.teacher.twitter, '_blank');
    } else {
      console.error("twitter URL is not available.");
      // Optionally, you can handle this case in another way (e.g., display a message to the user)
    }
  };
  const handlelinkedin = () => {
    if (subData.teacher && subData.teacher.linkedin) {
      window.open(subData.teacher.linkedin, '_blank');
    } else {
      console.error("linkedin URL is not available.");
      // Optionally, you can handle this case in another way (e.g., display a message to the user)
    }
  };
  const handleinstagram = () => {
    if (subData.teacher && subData.teacher.instagram) {
      window.open(subData.teacher.instagram, '_blank');
    } else {
      console.error("instagram  URL is not available.");
      // Optionally, you can handle this case in another way (e.g., display a message to the user)
    }
  };
  const handleyoutube = () => {
    if (subData.teacher && subData.teacher.youtube) {
      window.open(subData.teacher.youtube, '_blank');
    } else {
      console.error("youtube  URL is not available.");
      // Optionally, you can handle this case in another way (e.g., display a message to the user)
    }
  };

 





  console.log("data", subData);

  const ratings = _.get(subData, 'ratings', []);

  let rating = 0; // Define the rating variable and initialize it with a default value

  // Calculate average rating
  let totalRating = 0;
  let numberOfRatings = ratings.length;

  if (numberOfRatings > 0) {
    for (let i = 0; i < numberOfRatings; i++) {
      totalRating += parseFloat(ratings[i].ratings);
    }
    const averageRating = totalRating / numberOfRatings;
    rating = parseFloat(averageRating.toFixed(2)); // Keep only two decimal places
  }
  console.log("Rating", rating);

  const [showNext, setShowNext] = useState(false);
  const goToAllRating = (e, link) => {
    setShowNext(true);
  }

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = (index) => {
    if (selectedChapter === index) {
      setSelectedChapter(null); // Collapse if already clicked
    } else {
      setSelectedChapter(index); // Expand if not clicked
    }
  };

  // rating

  const EducationList = ({ education }) => {
    if (!education || education.length === 0) {
      return <p>No Education available</p>;
    }
  }
  const education = _.get(subData, 'teacherProfile.teacher.education');
  const imageUrl = subData.subject && subData.subject.image ? (basePath + subData.subject.image) : '';
  console.log('Image URL:', imageUrl);

  return (

    <div>

      <div className="student-main-head d-flex py-2" onClick={() => BackTodashboard()} >
        <div className="fa-chaveron"><IoIosArrowBack className='mb-1' /> </div>
      </div>
      <div>
        {!showNext &&
          <div class="container ">
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-7 col  mt-4">
                <div class="admin-card-sub text-center">
                  <div class="row">
                    <div class="col">
                      <img
                        className="img-fluid"
                        src={imageUrl}
                        alt="API Image"
                        style={{ width: '400px', height: '400px' }}
                      />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col p-0">
                      <p class=" sub-sidehead-course">About Course</p>
                    </div>
                    <div class="col ">
                      <div class="d-flex justify-content-center">
                        <button className="outline-btn-admin px-2 fw-bold mt-2" onClick={handlePreviewClick}>
                          <span>
                            <MdVideoCameraBack class="adminEyeIcon me-1 mb-1" />
                          </span>{" "}
                          Preview course{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div className="col ">
                      <p className="text-start sub-des-about mx-2"> {_.get(subData, 'subject.description')}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p class="fs-6 text-start ms-1">
                        Rating{" "}
                        <span>
                          <StarRatings
                            className="sub-rating-star"
                            starRatedColor={"#F2DE25"}
                            starDimension={"15px"}
                            rating={parseFloat(rating)}
                            starHoverColor="black"
                            starSpacing="1px"
                          />
                        </span>
                        <span className="fw-bold ms-2">{Number.isInteger(rating) ? rating.toFixed(1) : rating}</span>

                        {/* (240) */}
                        {" "}
                        <span class="fw-bold ms-2 viewall" onClick={() => goToAllRating()}>View All</span>


                      </p>
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col">
                      <hr></hr>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="container text-start">
                        {/* <div class="row">
                        <div className="d-flex justify-content-between"></div>
                        <div class="col">
                          <p class="fs-5 fw-semibold">Students</p>
                        </div>
                        <div class="col">
                          <p class="fs-5 ">{_.get(subData, 'totalStudents')}</p>
                        </div>
                      </div> */}
                        <div class="row">
                          <div className="d-flex justify-content-between"></div>
                          <div class="col">
                            <p class="fs-5 fw-semibold">Course Name</p>
                          </div>
                          <div class="col">
                            <p class="fs-5 "><b>{_.get(subData, 'subject.name')}</b></p>
                          </div>
                        </div>

                        <div class="row">
                          <div className="d-flex justify-content-between"></div>
                          <div class="col">
                            <p class="fs-5 fw-semibold">Category</p>
                          </div>
                          <div class="col">
                            <p class="fs-5 ">{_.get(subData, 'subject.category')}</p>
                          </div>
                        </div>
                        <div class="row">
                          <div className="d-flex justify-content-between">

                            <p class="fs-5 fw-semibold">Description</p>
                          </div>

                          <p class="fs-5">{_.get(subData, 'subject.description')}</p>

                        </div>
                        {/* <div class="row">
                        <div className="d-flex justify-content-between"></div>
                        <div class="col">
                          <p class="fs-5 fw-semibold">Topic</p>
                        </div>
                        <div class="col">
                          <p class="fs-5 ">{_.get(subData, 'subject.sub_category')}</p>
                        </div>
                      </div> */}

<div className="row">
  <div className="d-flex justify-content-between"></div>
  <div className="col">
    <p className="fs-5 fw-semibold">Price</p>
  </div>
  <div className="col">  <p className="fs-5">Rs {displayPrice}</p>
  </div>
</div>

                        {/* <div class="row">
                        <div className="d-flex justify-content-between"></div>
                        <div class="col">
                          <p class="fs-5 fw-semibold">Offer Price </p>
                        </div>
                        <div class="col">
                          <p class="fs-5 ">{offerPrice}</p>
                        </div>
                      </div> */}
                        <div class="row">
                          <div className="d-flex justify-content-between"></div>
                          <div class="col">
                            <p class="fs-5 fw-semibold">Teaching Language </p>
                          </div>
                          <div class="col">
                            <p class="fs-5 ">{_.get(subData, 'subject.language')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col">
                      <hr></hr>
                    </div>
                  </div>
                  <div class="row text-center">
                    <div class="col">

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-7 col mt-4">
                <div class="admin-card">
                  <h4 class="fs-5 fw-bold p-3">Live Class</h4>
                  <div class="container text-start">
                    <div class="row">
                      <div class="col-3" style={{ display: 'grid', placeItems: 'center' }}>
                        <div>
                          {subData?.teacherProfile?.user?.profile_pic ? (
                            <img
                              className="img-fluid"
                              src={(basePath + subData?.teacherProfile?.user?.profile_pic)} />
                          ) : (
                            <FaImage size={60} />
                          )
                          }
                        </div>

                        <div className="social-media-links ">

                          <FaFacebook className="social-media-icon fafacebook ms-3" onClick={handlefacebook} />

                          <FaTwitter className="social-media-icon ms-3 FaTwitter" onClick={handletwitter} />
                          <FaLinkedin className="social-media-icon ms-3 FaLinkedin" onClick={handlelinkedin} />
                          <FaInstagram className="social-media-icon ms-3 FaInstagram" onClick={handleinstagram} />
                          <FaYoutube className="social-media-icon ms-3 FaYoutube" onClick={handleyoutube} />
                        </div>
                      </div>
                      <div class="col">
                        <div className="row">
                          <div className="col">
                            {" "}
                            <p className="teacher-course-detail-head">
                              {_.get(subData, 'teacherProfile.user.firstname')}{""} {_.get(subData, 'teacherProfile.user.lastname')}
                            </p>
                          </div>
                          <div className="col">

                            <button
                              className="outline-btn-admin px-4 py-1 fw-bold"
                              onClick={() => goToPlaceorder()}
                            >
                              Purchase Now
                            </button>


                          </div>
                        </div>
                        <div className="row mt-4 mb-0">
                          <div className="col-1 px-0 ms-3">
                            <p className="fw-bold">Bio:</p>
                          </div>
                          <div className="col">
                            {_.get(subData, 'teacherProfile.teacher.bio') ? (
                              <p>{_.get(subData, 'teacherProfile.teacher.bio')}</p>

                            ) : (
                              <p className="fw-bold">No bio available</p>
                            )}
                            {_.get(subData, 'teacherProfile.teacher.education') ? (
                              <p>
                                {" "}
                                <div>
                                  <div className="col-1 px-0 ms-3">

                                  </div>
                                  {education.map((edu, index) => (
                                    <div className="row">
                                      <div key={index} className=" col">
                                        <p>{edu.degree}{" "}{edu.field_of_study}{" "}From{" "} {edu.organization}</p>

                                      </div>
                                    </div>
                                  ))}
                                </div>

                              </p>
                            ) : (
                              <p>No Education available</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row text-center my-1 mb-5">
                      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-sm-3 ms-3">
                        {_.get(subData, 'teacherProfile.teacher.education') ? (
                          <p>
                            {" "}
                            <div>
                              {education.map((edu, index) => (
                                <div key={index} className="mb-3">
                                  <h4>{edu.degree}</h4>
                                  <p><strong>Organization:</strong> {edu.organization}</p>
                                  <p><strong>Field of Study:</strong> {edu.field_of_study}</p>
                                  <p><strong>Year Completed:</strong> {edu.year_completed}</p>
                                </div>
                              ))}
                            </div>
                      
                          </p>
                        ) : (
                          <p>No Education available</p>
                        )}
                      </div>
                    </div> */}
                    {/* 
                    <div class="container text-start">
                      <div class="row text-start">
                        <div class="col">
                          <p> Days</p>
                        </div>
                        <div class="col">
                          <p> Timing</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p> Monday</p>
                        </div>
                        <div class="col">
                          <p>{getTimeForday('monday')}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p> Tuesday</p>
                        </div>
                        <div class="col">
                          <p>{getTimeForday('tuesday')}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p> Wednesday</p>
                        </div>
                        <div class="col">
                          <p>{getTimeForday('wednesday')}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p> Thursday</p>
                        </div>
                        <div class="col">
                          <p>{getTimeForday('thursday')}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p> Friday</p>
                        </div>
                        <div class="col">
                          <p>{getTimeForday('friday')}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p> Saturday</p>
                        </div>
                        <div class="col">
                          <p>{getTimeForday('saturday')}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p> Sunday</p>
                        </div>
                        <div class="col">
                          <p>{getTimeForday('sunday')}</p>
                        </div>
                      </div>*/}
                    <div className="col">
                      <div>
                        <h2 className="mt-2">Course Syllabus<hr className="col-4 px-3" /></h2>

                      </div>

                      <div className="col ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 mt-3 ">
                        <div className="row ms-3">
                          {subData?.subjectChapter && subData.subjectChapter.map((chapter, index) => (
                            <div key={index} className="col-12 mt-4   mt-3 mb-3 fw-bold " 
                            onClick={() => toggleCollapse(index)}
                            >
                              <h4 className="bold">{`${index + 1}. ${chapter.name}`}</h4><p className="d-flex justify-content-end">{chapter.duration}{" "}hours</p>
                              <p>{chapter.description}</p>
                              <div className="d-flex justify-content-end">
                              <button
                              className="outline-btn-admin px-4 py-1 fw-bold "
                           
                            >
                              chapters
                            </button>
                              </div>
                             
                              {selectedChapter === index && chapter.topics && chapter.topics.map((topic, topicIndex) => (
                                <div className=" mb-3
                      ">
                                  <div key={topicIndex}>
                                    <h5 className="">{`${index + 1}.${topicIndex + 1} ${topic.title || "Topic Title"}`}{" "}  </h5>
                                    <p className="d-flex justify-content-end">{topic.duration}{" "}hours</p>
                                    <p>{topic.description || "Topic Description"}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>




                      </div>
                    </div>









                    {/* <div className="row mt-4">
                      <div className="col">
                        <p className="fs-5 fw-semibold">Reviews</p>
                      </div>
                    </div> */}
                    {/* <div className="row">
                      {ratings.map((rating, index) => (

                        <div key={index} className="col-6">
                          <div className="admin-card admin-video-personCard p-3 m-4">
                            <div className="container mx-3">
                              <div className="row">
                                <div className="col-2">
                                  <img src={img1} className="img-fluid  rounded-circle" alt="" />
                                </div>
                                <div className="col">
                                  <h5>{rating.firstname}</h5>
                                  <p>{rating.created_at ? new Date(rating.created_at).toLocaleDateString() : ''}</p>
                                </div>
                              </div>
                              <div className="row text-start">
                                <div className="col">
                                  <p>{rating.review || "No review available"}</p>
                                </div>
                              </div>
                              <div className="row text-start">
                                <div className="col">
                                  <StarRatings
                                    className="sub-rating-star"
                                    starRatedColor={"#F2DE25"}
                                    starDimension={"20px"}
                                    rating={parseFloat(rating.ratings)}
                                    starHoverColor="black"
                                    starSpacing="1px"
                                  />
                                  <span className="fw-bold ms-2">{parseFloat(rating.ratings).toFixed(1)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      ))}
                    </div> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        }

      </div >

      {
        showNext && (
          <div className="rating-bg px-5 py-5" style={{ background: "#EAE9FB" }}>
            <div className="row">
              <h1 className="">Review and Ratings</h1>

              {ratings.map((rating, index) => (

                <div key={index} className="col-4">
                  <div className="card mb-5 mt-5 border-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h4>{rating.firstname}</h4>
                          <span>
                            <StarRatings
                              className="sub-rating-star"
                              starRatedColor={"#F2DE25"}
                              starDimension={"20px"}
                              rating={parseFloat(rating.ratings)}
                              starHoverColor="black"
                              starSpacing="1px"
                            />
                            <span className="fw-bold ms-2">{parseFloat(rating.ratings).toFixed(1)}</span>

                          </span>
                          <p className="p-rating-date mt-2">{rating.created_at ? new Date(rating.created_at).toLocaleDateString() : ''}</p>
                        </div>



                        <div className="col">
                          <h5>{rating.review || "No review available"}</h5>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ))}

            </div>
          </div>
        )
      }

    </div >

  );
}
export default Studentchatside;
