import React, { useState } from 'react'
import _ from "lodash"
import { MdSubject } from "react-icons/md";


function SubjectTeacher(props) {



    const goToSubjectTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachersubject' })
        }
    }
    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'Teachersubject':
            case 'addsubject':
            case 'ViewSubject':
            case 'studentlistcoupon':
            case 'couponusage':
            case 'createCoupon':
            case 'ViewCoupon':
            case 'subjectstudentdetails':
            case 'Teacherresource':
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };
    return (
        <div
            className={determineClassName()}>
            <div className="dash d-flex">
                <MdSubject className="ms-3" />
                <p className='head-dashboard ms-2' onClick={(e) => goToSubjectTeacher(e)}>My Course</p>
            </div>
        </div>
    )
}

export default SubjectTeacher