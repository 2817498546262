import React, { useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import './CouponCode.css'

function CouponCode() {
    const navigate = useNavigate()
    const [showNext, setShowNext] = useState()
    const BackTopage = () => {
        if (showNext) {
            setShowNext(false);
        } else {
            navigate('/Showsubjects');
        }
    }
    return (
        <>
         <div className='main-divHead'>
            <div className="student-main-head d-flex" >
                <div className="fa-chaveron"><ArrowBackIosIcon onClick={BackTopage} /></div>
                <div className="profile-head">Coupon</div>
            </div>
            <div>
            <div className=' mt-5'>
                <div className='row d-flex justify-content-center'>
            <div className=' col-12 col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-10  ms-5'>
            <input type="email" class="form-control border-0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Enter referral code'/>
            </div><div className='col-3  col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-2'>
            <button type="button" class="btn btn-light apply-btn">Apply</button>
            </div>
           
            </div>
            </div>
            </div>
            <div className='d-flex justify-content-center  mt-3'>
                <div class="card mt-3 col-9 border-0 shadow">
                    <div class="card-body">
                    
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default CouponCode