import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import _, { initial } from "lodash";
import Axios from '../../../../../../util/apiUtil';
import { MdAdd, MdDelete } from "react-icons/md";
import { showErrorToast, showSuccessToast } from '../../../../../../util/toastUtils';

function EditTeacherSubject(props) {
    const [visibleTopics, setVisibleTopics] = useState([[]]);


    const Backdashboard = () => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "Teachersubject" });
        }
    };

    const [viewSub, setViewSub] = useState({});
    const [subjectChapter, setSubjectChapter] = useState([]);
    const ViewBatch = props.userState.row.id || {};
    const sub_category = props.userState.row.sub_category || {};
    const [scheduleDay, setScheduleDay] = useState({
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
    });

    const fetchSubject = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        const formData = new FormData();
        formData.append("subject_id", ViewBatch);

        Axios.post('/api/viewSubject', formData, { headers: headers })
            .then((response) => {
                const updateData = response.data.data.subject;
                const subjectChapters = response.data.data.subjectChapter;
                const schedule = response.data.data.scheduleDay;
                setViewSub(updateData);
                setSubjectChapter(subjectChapters);
                const parsedScheduleDay = {
                    monday: parseInt(scheduleDay.monday, 10),
                    tuesday: parseInt(scheduleDay.tuesday, 10),
                    wednesday: parseInt(scheduleDay.wednesday, 10),
                    thursday: parseInt(scheduleDay.thursday, 10),
                    friday: parseInt(scheduleDay.friday, 10),
                    saturday: parseInt(scheduleDay.saturday, 10),
                    sunday: parseInt(scheduleDay.sunday, 10),
                };

                // Debugging: log the parsed schedule day
                console.log('Parsed Schedule Day:', parsedScheduleDay);

                setScheduleDay(parsedScheduleDay);
            })
            .catch((error) => {
                console.error("Error fetching subject data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });
    };
    useEffect(() => {
        console.log("Schedule Day State Updated:", scheduleDay);
    }, [scheduleDay]);
    useEffect(() => {
        fetchSubject();
    }, []);
    const [payment, setPayment] = useState(viewSub.payment || "1");

    useEffect(() => {
        setPayment(viewSub.payment);
    }, [viewSub]);
    const handlePaymentChange = (e) => {
        setPayment(e.target.value);
        // Optionally, you can update the viewSub state if needed
        setViewSub({ ...viewSub, payment: e.target.value });
    };
    const basePath = "http://tuitionguruji.com/backend/public/";
    // const imageUrl = viewSub.image ? `${basePath}${viewSub.image}` : '';

    const [chapters, setChapters] = useState([
        {
            name: '',
            duration: '',
            description: '',
            topics: [
                {
                    title: '',
                    description: '',
                    duration: ''
                }
            ]
        }
    ]);

    const handleChange = (e, index, field, subField) => {
        const updatedChapters = [...chapters];
        if (subField !== undefined) {
            updatedChapters[index][field][subField] = e.target.value;
        } else {
            updatedChapters[index][field] = e.target.value;
        }
        setChapters(updatedChapters);
    };


    const calculateModuleDuration = (topics) => {
        return topics.reduce((total, topic) => total + (parseFloat(topic.duration) || 0), 0);
    }

    const handleTopicChange = (e, chapterIndex, topicIndex, property) => {
        const { value } = e.target;

        // Update the topic property
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].topics[topicIndex][property] = value;
        setChapters(updatedChapters);

        // Recalculate chapter duration
        const newChapterDuration = calculateChapterDuration(chapterIndex);
        const updatedChapter = { ...updatedChapters[chapterIndex], duration: newChapterDuration };
        updatedChapters[chapterIndex] = updatedChapter;
        setChapters(updatedChapters);
    };

    const addTopic = (chapterIndex) => {
        // Add a new topic to the specified chapter
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].topics.push({
            title: '',
            duration: '',
            description: ''
        });
        setChapters(updatedChapters);

        // Recalculate chapter duration
        const newChapterDuration = calculateChapterDuration(chapterIndex);
        const updatedChapter = { ...updatedChapters[chapterIndex], duration: newChapterDuration };
        updatedChapters[chapterIndex] = updatedChapter;
        setChapters(updatedChapters);
    };

    const deleteChapter = (chapterIndex) => {
        const updatedChapters = [...chapters];
        updatedChapters.splice(chapterIndex, 1);
        setChapters(updatedChapters);

        const updatedVisibleTopics = [...visibleTopics];
        updatedVisibleTopics.splice(chapterIndex, 1);
        setVisibleTopics(updatedVisibleTopics);
    };

    const deleteTopic = (chapterIndex, topicIndex) => {
        console.log('Deleting topic:', chapterIndex, topicIndex); // Add this line
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].topics.splice(topicIndex, 1);
        setChapters(updatedChapters);

        const updatedVisibleTopics = [...visibleTopics];
        // Reset visibility to true if the first topic is deleted
        if (topicIndex === 0) {
            updatedVisibleTopics[chapterIndex][topicIndex] = true;
        } else {
            updatedVisibleTopics[chapterIndex].splice(topicIndex, 1);
        }
        setVisibleTopics(updatedVisibleTopics);
    };

    const calculateChapterDuration = (chapterIndex) => {
        let totalDuration = 0;
        chapters[chapterIndex].topics.forEach((topic) => {
            if (!isNaN(parseInt(topic.duration))) {
                totalDuration += parseInt(topic.duration);
            }
        });
        return totalDuration;
    };

    const addChapter = () => {
        setChapters([...chapters, { name: '', duration: '', description: '', topics: [{ title: '', description: '', duration: '' }] }]);
        setVisibleTopics([...visibleTopics, []]);
    };


    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setViewSub({ ...viewSub, [id]: value });
    };


    // const handleInputChangesyllabus = (e, index, field, subField) => {
    //     const updatedChapters = [...chapters];
    //     if (subField !== undefined) {
    //         updatedChapters[index][field][subField] = e.target.value;
    //     } else {
    //         updatedChapters[index][field] = e.target.value;
    //     }
    //     setChapters(updatedChapters);
    // };
    const handleInputChangesyllabus = (e, chapterIndex, topicIndex) => {
        const { id, value } = e.target;
        setSubjectChapter((prevChapters) => {
            const newChapters = [...prevChapters];
            if (topicIndex !== undefined) {
                newChapters[chapterIndex].topics[topicIndex] = {
                    ...newChapters[chapterIndex].topics[topicIndex],
                    [id]: value,
                };
            } else {
                newChapters[chapterIndex] = {
                    ...newChapters[chapterIndex],
                    [id]: value,
                };
            }
            return newChapters;
        });
    };



    const currencyOptions = "INR";
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const subject_id = viewSub.id

    const [imageUrl, setImageUrl] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        setSelectedFile(file);
        const fileName = file ? file.name : "No file chosen";
        document.getElementById('fileLabel').textContent = fileName;

        // Generate a temporary URL for the selected file
        const tempUrl = URL.createObjectURL(file);
        setImageUrl(tempUrl);
    };

    const handleUpdate = () => {
        const studentdetails = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};

        // Create a new FormData object
        let formData = new FormData();

        // Append existing form data fields
        formData.append("email", _.get(studentdetails, "email") || _.get(studentdetails, "data.email"));
        formData.append("subject_id", subject_id);
        formData.append("sub_category", sub_category);
        formData.append("category", viewSub.category);
        formData.append("subject", viewSub.subject);
        formData.append("level", viewSub.level);
        formData.append("language", viewSub.language);
        formData.append("price", viewSub.price);
        formData.append("payment", viewSub.payment);
        formData.append("period", viewSub.period);
        formData.append("duration", viewSub.duration);
        formData.append("currency", currencyOptions);
        formData.append("discount", viewSub.discount);
        formData.append("description", viewSub.description);
        formData.append("video_link", viewSub.video_link);
        formData.append("teacher_id", viewSub.teacher_id);
        formData.append("name", viewSub.name);
        formData.append("dates[monday]", days.monday);
        formData.append("dates[tuesday]", days.tuesday);
        formData.append("dates[wednesday]", days.wednesday);
        formData.append("dates[thursday]", days.thursday);
        formData.append("dates[friday]", days.friday);
        formData.append("dates[saturday]", days.saturday);
        formData.append("dates[sunday]", days.sunday);

        // Check if there's a new image selected
        if (selectedFile) {
            formData.append("image", selectedFile); // Append the new image file
        } else {
            formData.append("image", viewSub.image); // Append the existing image URL or identifier
        }

        // Append chapters and topics data
        subjectChapter.forEach((chapter, i) => {
            formData.append(`chapter[${i}][name]`, chapter.name);
            formData.append(`chapter[${i}][duration]`, chapter.duration);
            formData.append(`chapter[${i}][description]`, chapter.description);

            chapter.topics.forEach((topic, j) => {
                formData.append(`chapter[${i}][topics][${j}][title]`, topic.title);
                formData.append(`chapter[${i}][topics][${j}][description]`, topic.description);
                formData.append(`chapter[${i}][topics][${j}][duration]`, topic.duration);
            });
        });

        console.log("formData:", formData); // Log formData to check its structure

        const headers = {
            Authorization: "Bearer " + localStorage.getItem("token"),
        };

        Axios.post('api/editSubject', formData, { headers: headers })
            .then((res) => {
                if (res?.data?.success) {
                    let user = _.get(res, "data.data.user") ? JSON.stringify(res.data.data.user) : undefined;
                    let token = _.get(res, "data.data.token") ? JSON.stringify(res.data.data.token) : undefined;
                    if (user) {
                        localStorage.setItem("user", JSON.stringify(res.data.data.user));
                    }
                    if (token) {
                        localStorage.setItem("token", res.data.data.token);
                    }
                    const newImageUrl = res.data.image; // Assuming the API returns the image path
                    setImageUrl(`${basePath}${newImageUrl}`);
                    showSuccessToast("Course updated successfully");
                    // Move to backdashboard
                    Backdashboard();
                }
            })
            .catch((error) => {
                console.error("There was an error updating the subject!", error);
            });
    };


    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        setScheduleDay({ ...scheduleDay, [id]: checked ? 1 : 0 });
    };

    return (
        <>
            <div className="sidebar_content">
                <div className="main-divHead">
                    <div className="sub-Head1" onClick={Backdashboard}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <div className="Sub-Head2">Edit</div>
                    </div>
                    <div className='container mt-3'>
                        <div className="row">
                            <div className='col-12'>
                                <div className='main-schedule-head ms-5'>
                                    <p className='Title-main-head'>Edit Courses</p>
                                </div>
                            </div>
                        </div>
                        <form>
                            <div className='row ms-5'>
                                <div className='col-6 '>
                                    <div className="mb-3 col-10">
                                        <label htmlFor="description" className="form-label">Category</label>
                                        <input
                                            type='text'
                                            className="form-control"
                                            id="category"
                                            aria-describedby="descriptionHelp"
                                            value={viewSub.category || ''}
                                            name='category'
                                        />
                                    </div>
                                    <div className="mb-3 col-10">
                                        <label htmlFor="subCategory" className="form-label">SubCategory</label>
                                        <input
                                            type='text'
                                            className="form-control"
                                            id="sub_category"
                                            aria-describedby="descriptionHelp"
                                            value={viewSub.sub_category || ''}
                                            name='sub_category'
                                        />
                                    </div>
                                    <div className="mb-3 col-10">
                                        <label htmlFor="topic" className="form-label">Topic</label>
                                        <input
                                            type='text'
                                            className="form-control"
                                            id="subject"
                                            aria-describedby="descriptionHelp"
                                            value={viewSub.topic || ''}
                                            name='subject'
                                        />
                                    </div>
                                    <div className="mb-3 col-10">
                                        <label htmlFor="courseName" className="form-label">Course Name</label>
                                        <input
                                            type='text'
                                            className="form-control"
                                            id="name"
                                            aria-describedby="descriptionHelp"
                                            value={viewSub ? viewSub.name || '' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <div className="mb-3">
                                                <label htmlFor="price" className="form-label">Price</label>
                                                <input type="text" className="form-control" id="price" aria-describedby="priceHelp"
                                                    value={viewSub.price || ''}
                                                    onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        <div className='col-5 ms-1'>
                                            <div className="mb-3">
                                                <label htmlFor="language" className="form-label">Language</label>
                                                <input type="text" className="form-control" id="language" aria-describedby="languageHelp"
                                                    value={viewSub.language || ''}
                                                    onChange={handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className="mb-3 col-10">
                                                <label htmlFor="payment" className="form-label">Payment</label>
                                                <select
                                                    className="form-control"
                                                    id="payment"
                                                    aria-describedby="paymentHelp"
                                                    value={payment}
                                                    onChange={handlePaymentChange}
                                                >
                                                    <option value="1">Months</option>
                                                    <option value="2">Days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className="mb-3">
                                                <label htmlFor="period" className="form-label">Period</label>
                                                <input type="text" className="form-control" id="period" aria-describedby="periodHelp"
                                                    value={viewSub.period || ''}

                                                    onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        <div className='col-3 mt-2'>
                                            <div className="mb-3">
                                                <label htmlFor="extra" className="form-label"></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="extra"
                                                    aria-describedby="extraHelp"
                                                    value={payment === "1" ? "Months" : "Days"}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 col-10">
                                        <label htmlFor="videoLink" className="form-label">Attach promotional video Link</label>
                                        <input type='text' className="form-control" id="video_link" aria-describedby="videoLinkHelp"
                                            value={viewSub.video_link || ''}
                                            onChange={handleInputChange} />
                                    </div>
                                    <div className="mb-3 col-10">
                                        <label htmlFor="description" className="form-label">Description</label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            aria-describedby="descriptionHelp"
                                            value={viewSub.description || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='col-10'>
                                        <div className="mb-3">
                                            <div className="admin-card admin-dotted-border mt text-center ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 mb-0">
                                                <img
                                                    src={imageUrl ? imageUrl : (viewSub.image ? `${basePath}${viewSub.image}` : '')}
                                                    className="img-fluid rounded-top img_up"
                                                    alt={imageUrl ? 'Uploaded' : 'No Image Available'}
                                                />
                                            </div>
                                            <p className="fs-6 mt-2 d-flex justify-content-center" id="fileLabel">Upload course image</p>
                                            <div className='d-flex justify-content-center'>
                                                <label htmlFor="fileInput" className="btn btn-primary px-4 py-1 bootBtn ">
                                                    Browse File
                                                </label>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    name="image"
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                />
                              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-12 col-xl-10 col-lg-10 col-md-10 col-sm-10  mt-5 teacher-time-box container">
                                        <div className='row main_row mt-5'>
                                            <div className='col'>
                                                <div className='row text-center'>
                                                    <div className='col'>
                                                        <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 mb-4 teacher-font-label">
                                                            <strong>Scheduling Days</strong>
                                                        </label>
                                                    </div>
                                                </div>

                                                {/* Render checkboxes for each day based on scheduleDay state */}
                                                {/* <div className='row'>
                                                    <div className='col'>
                                                        <input type="checkbox" checked={scheduleDay && parseInt(scheduleDay.monday) === 1} />
                                                    </div>
                                                    <div className='col'>
                                                        <p className='dates_sched'>Monday</p>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <input type="checkbox" checked={scheduleDay && parseInt(scheduleDay.tuesday) === 1} />
                                                    </div>
                                                    <div className='col'>
                                                        <p className='dates_sched'>Tuesday</p>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <input type="checkbox" checked={scheduleDay && parseInt(scheduleDay.wednesday) === 1} />
                                                    </div>
                                                    <div className='col'>
                                                        <p className='dates_sched'>Wednesday</p>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <input type="checkbox" checked={scheduleDay && parseInt(scheduleDay.thursday) === 1} />
                                                    </div>
                                                    <div className='col'>
                                                        <p className='dates_sched'>Thursday</p>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <input type="checkbox" checked={scheduleDay && parseInt(scheduleDay.friday) === 1} />
                                                    </div>
                                                    <div className='col'>
                                                        <p className='dates_sched'>Friday</p>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <input type="checkbox" checked={scheduleDay && parseInt(scheduleDay.saturday) === 1} />
                                                    </div>
                                                    <div className='col'>
                                                        <p className='dates_sched'>Saturday</p>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <input type="checkbox" checked={scheduleDay && parseInt(scheduleDay.sunday) === 1} />
                                                    </div>
                                                    <div className='col'>
                                                        <p className='dates_sched'>Sunday</p>
                                                    </div>
                                                </div> */}
                     {days.map((day, index) => (
                        <div className="row" key={index}>
                            <div className="col">
                                <input 
                                    type="checkbox" 
                                    id={day.toLowerCase()}
                                    checked={scheduleDay[day.toLowerCase()] === 1}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <div className="col">
                                <p className="dates_sched">{day}</p>
                            </div>
                        </div>
                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div>

                                        <div style={{ backgroundColor: "white" }}>
                                            {subjectChapter.map((chapter, chapterIndex) => (
                                                <div key={chapterIndex}>
                                                    <p className="teacher-font-label d-flex justify-content-center mt-3">Module</p>
                                                    <input
                                                        className="form-control teacher-font-field mt-3"
                                                        type="text"

                                                        value={chapter.name || ''}
                                                        onChange={(e) => handleInputChangesyllabus(e, chapterIndex)}
                                                        placeholder="Module Name"
                                                        id='name'
                                                    />

                                                    {chapter.topics.length === 0 ? (
                                                        <input
                                                            className="form-control teacher-font-field mt-2"
                                                            type="text"

                                                            value={chapter.duration || ''}
                                                            onChange={(e) => handleInputChangesyllabus(e, chapterIndex)}
                                                            placeholder="Module Duration"
                                                            id='duration'
                                                        />
                                                    ) : (
                                                        <p className="form-control teacher-font-field mt-2">
                                                            {`Module Duration: ${calculateModuleDuration(chapter.topics)} hours`}
                                                        </p>
                                                    )}
                                                    <input
                                                        className="form-control teacher-font-field mt-2 mb-3"
                                                        type="text"

                                                        value={chapter.description || ''}
                                                        onChange={(e) => handleInputChangesyllabus(e, chapterIndex)}
                                                        placeholder="Module Description"
                                                        id='description'
                                                    />

                                                    <div className="d-flex justify-content-center">
                                                        <div className="border border-4 col-10 d-flex justify-content-center">
                                                            <div>
                                                                {chapter.topics.map((topic, topicIndex) => (
                                                                    <div key={topicIndex}>
                                                                        <div className="d-flex justify-content-center mt-3">
                                                                            <input
                                                                                className="col-12 ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field mt-2 px-2 py-1 d-flex justify-content-center"
                                                                                type="text"
                                                                                name="title"
                                                                                value={topic.title || ''}
                                                                                onChange={(e) => handleInputChangesyllabus(e, chapterIndex, topicIndex)}
                                                                                placeholder="Chapter Title"
                                                                                id={topic.title}
                                                                            />
                                                                        </div>
                                                                        <div className="d-flex justify-content-center">
                                                                            <input
                                                                                className="col-12 ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field mt-2 px-2 py-1"
                                                                                type="text"
                                                                                name="duration"
                                                                                value={topic.duration || ''}
                                                                                onChange={(e) => handleInputChangesyllabus(e, chapterIndex, topicIndex)}
                                                                                placeholder="Chapter Duration"
                                                                                id={topic.duration}
                                                                            />
                                                                        </div>
                                                                        <div className="d-flex justify-content-center">
                                                                            <input
                                                                                className="col-12 ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field mt-2 px-2 py-1"
                                                                                type="text"
                                                                                name="description"
                                                                                value={topic.description || ''}
                                                                                onChange={(e) => handleInputChangesyllabus(e, chapterIndex, topicIndex)}
                                                                                placeholder="Chapter Description"
                                                                                id={topic.description}
                                                                            />
                                                                        </div>
                                                                        <div className="d-flex justify-content-center">
                                                                            <button onClick={() => deleteTopic(chapterIndex, topicIndex)} className="btn ms-5"><MdDelete /></button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="d-flex justify-content-end">
                                                                    <button onClick={() => addTopic(chapterIndex)} className="btn ms-2 btn-success"><MdAdd /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-end">
                                                        <button onClick={addChapter} className="btn ms-2 btn-success"><MdAdd /></button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </form>
                        <div class="row px-5">
                            <div clas="col-12 d-flex justify-content-center">
                                <button
                                    type="button" // Use type="button" to prevent form submission
                                    className="btn text-light px-4 py-1 bootBtn my-5"
                                    onClick={handleUpdate}>
                                    Update</button>
                            </div></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditTeacherSubject;
