import React from 'react'
import RecordWhiteIcon from "../../../icons/RecordWhiteIcon.png"
import RecordBlueIcon from "../../../icons/RecordBlueIcon.png"
import _ from "lodash";
import { CgPlayButtonR } from 'react-icons/cg';

function InstructorUploadsComponent(props) {

    const goToTTeacherWallet = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminInsUpload' })
        }
    }
    return (
        <div
            className={`sidebar_content ${_.get(props, 'userState.subType') == 'adminInsUpload' ? 'active' : ''} `}
            onClick={(e) => goToTTeacherWallet(e)}>
            <div className="dash d-flex">
                <CgPlayButtonR class='ms-3'/>
                <p className='head-dashboard ms-2' > Instructor Uploads
                </p>
            </div>
        </div>
    )
}

export default InstructorUploadsComponent