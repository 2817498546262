import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { WebsiteSettings } from '../../../../../../redux/slice/settingsSlice';

function WebsiteSettingsComponent() {

    const dispatch = useDispatch();
    const initialValues = {
        title: '',
        subTitle: '',
        cookiesStatus: 'Active',
        cookieNote: '',
        cookiePolicy: '',
        aboutUs: '',
        termsAndConditions: '',
        privacyPolicy: '',
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        subTitle: Yup.string().required('sub title is required'),
        cookiesStatus: Yup.string().required('cookies status is required'),
        cookieNote: Yup.string().required('cookie note is required'),
        cookiePolicy: Yup.string().required('cookie policy is required'),
        aboutUs: Yup.string().required('about us is required'),
        termsAndConditions: Yup.string().required('Terms and Conditions is required'),
        privacyPolicy: Yup.string().required('privacy policy is required'),
    });

    const onSubmit = (values, { setSubmitting }) => {

        const payload = {
            "title": values.title,
            "sub_title": values.subTitle,
            "cookies": values.cookiesStatus === "option1" ? 1 : 0,
            "cookie_note": values.cookieNote,
            "cookie_policy": values.cookiePolicy,
            "about_us": values.aboutUs,
            "terms": values.termsAndConditions,
            "privacy": values.privacyPolicy,
        }
        dispatch(WebsiteSettings(payload));
        setSubmitting(false);
    };

    return (
        <div className="main-divHead">
            <div className="sub-Head1">
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className="Sub-Head2">Settings</div>
            </div>
            <div>
                <div className="container text-start my-5">
                    <p className="admin-sub-head ms-5 fw-bold">Website Settings</p>
                    
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        <Form>
                            <div className="container">
                                <div className="row">
                                    <div className="d-flex justify-content-center">
                                        <div className="col-xxl-6 col-xl-6 col-lg-10">
                                            <label className="fs-6 mt-4 fw-semibold" htmlFor="title">
                                                Title
                                            </label>
                                            <Field
                                                type="text"
                                                name="title"
                                                id="title"
                                                className="form-control"
                                                placeholder="Title name"
                                            />
                                            <ErrorMessage name="title" component="div" className="text-danger" />

                                            <label className="fs-6 mt-4 fw-semibold" htmlFor="subTitle">
                                                Sub Title
                                            </label>
                                            <Field
                                                type="text"
                                                name="subTitle"
                                                id="subTitle"
                                                className="form-control"
                                                placeholder="Sub title name"
                                            />
                                            <ErrorMessage name="subTitle" component="div" className="text-danger" />

                                            <label className='fs-6 mt-4 fw-semibold' htmlFor="cookiesStatus">
                                                Cookies Status
                                            </label>
                                            <div className='my-3'>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="cookiesStatus" id="inlineRadio1" value="option1" />
                                                    <label className="form-check-label fs-6 fw-semibold" htmlFor="inlineRadio1">Active</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="cookiesStatus" id="inlineRadio2" value="option2" />
                                                    <label className="form-check-label fs-6 fw-semibold" htmlFor="inlineRadio2">Inactive</label>
                                                </div>
                                            </div>

                                            <label className='fs-6 mt-4 fw-semibold' htmlFor="cookieNote">Cookie Note</label>
                                            <Field  as="textarea" name="cookieNote" className="form-control" placeholder="Cookie notes" />
                                            <ErrorMessage name="cookieNote" component="div" className="text-danger" />

                                            <label className='fs-6 mt-4 fw-semibold' htmlFor="cookiePolicy">Cookie Policy</label>
                                            <Field  as="textarea" name="cookiePolicy" className="form-control" placeholder="Cookie policy" />
                                            <ErrorMessage name="cookiePolicy" component="div" className="text-danger" />

                                            <label className='fs-6 mt-4 fw-semibold' htmlFor="aboutUs">About Us</label>
                                            <Field  as="textarea" name="aboutUs" className="form-control" placeholder="This is about us" />
                                            <ErrorMessage name="aboutUs" component="div" className="text-danger" />

                                            <label className='fs-6 mt-4 fw-semibold' htmlFor="termsAndConditions">Terms and Conditions</label>
                                            <Field  as="textarea" name="termsAndConditions" className="form-control" placeholder="Terms and Conditions" />
                                            <ErrorMessage name="termsAndConditions" component="div" className="text-danger" />

                                            <label className='fs-6 mt-4 fw-semibold' htmlFor="privacyPolicy">Privacy Policy</label>
                                            <Field  as="textarea" name="privacyPolicy" className="form-control" placeholder="Privacy Policy" />
                                            <ErrorMessage name="privacyPolicy" component="div" className="text-danger" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="container text-center">
                                <div className="row">
                                    <div className="col">
                                        <button type="submit" className="btn text-light px-5 py-1 bootBtn my-5">
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default WebsiteSettingsComponent;
