import React, { useState, useEffect } from 'react';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import './AddResource.scss';
import { showSuccessToast } from '../../../../../../util/toastUtils';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function AddResource(props) {

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachersubject' });
        }
    }

    const baseUrl = 'http://tuitionguruji.com/backend/public/';
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState(null);

    const [data, setData] = useState({
        id: '',
        count: 0,
        name: ''
    });

    const handleSaveClick = () => {
        setLoading(true);
        ToSubmit();
    };

    const ToSubmit = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        const formData = new FormData();

        if (!data.id) {
            data.id = 1; // You can set it to the default subject_id
        }
        formData.append('subject_id', data.id);
        formData.append('count', data.count);

        inputFields.forEach((field, index) => {
            if (field.file && field.name) {
                formData.append(`resource_file_${index + 1}`, field.file);
                formData.append(`title_${index + 1}`, field.name);
            }
        });

        Axios.post('/api/addResource', formData, { headers: headers })
            .then((response) => {
                console.log(response);
                const updateData = response.data;
                if (updateData.success) {
                    const link = updateData.data.subject.resourses[0].link;
                    const fullLink = baseUrl + link;
                    const updatedInputFields = [...inputFields];
                    updatedInputFields[data.count - 1].text = fullLink;
                    setInputFields(updatedInputFields);
                    setView(fullLink);
                    showSuccessToast("Document added successfully");
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching assignment data:", error);
                setShowAlert(true);
            });
    }

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, []);

    const initializeObject = () => {
        setData({ ...data, ...props.userState.row });
    }

    const [inputFields, setInputFields] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    const headingStyle = {
        fontSize: '18px',
        textAlign: 'center',
    }

    const centerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const buttonStyle = {
        height: '40px',
        width: '100px',
        marginTop: '10px',
        marginLeft: '10px',
    }

    const inputStyle = {
        height: '40px',
        width: '100%',
        marginRight: '10px',
    }

    const addInput = () => {
        if (inputFields.length < 5) {
            const nextTitle = `change file name_${inputFields.length + 1}`;
            setInputFields([...inputFields, { file: null, text: '', name: nextTitle }]);
            setData({ ...data, count: data.count + 1 });
        } else {
            // Optionally, you can display a message or toast indicating that the maximum number of files has been reached
        }
    }

    const handleFileChange = (event, index) => {
        const files = event.target.files;
        const updatedInputFields = [...inputFields];
        updatedInputFields[index].file = files[0];
        setInputFields(updatedInputFields);
    }

    const handleTextChange = (event, index) => {
        const value = event.target.value;
        const updatedInputFields = [...inputFields];
        updatedInputFields[index].name = value;
        setInputFields(updatedInputFields);
    }

    console.log("data", data);

    return (
        <div className='main-divHead'>
            <div className='sub-Head1'>
                <FontAwesomeIcon icon={faChevronLeft} onClick={Backdashboard} />
                <div className='Sub-Head2' >Course</div>
            </div>
            <div className='heading_resource' style={headingStyle}>Upload File </div>
            <div className='row' style={centerStyle}>
                {inputFields.map((field, index) => (
                    <div className='col-md-8 d-flex' key={index}>
                        <input
                            type='file'
                            className='form-control'
                            style={inputStyle}
                            onChange={(e) => handleFileChange(e, index)}
                        />
                        <input
                            type='text'
                            placeholder='Name of video'
                            className='form-control'
                            style={inputStyle}
                            value={field.name}
                            onChange={(e) => handleTextChange(e, index)}
                        /><br />
                    </div>
                ))}
                <div className='col-md-8 d-flex'>
                    <button
                        className='btn text-light px-4 py-1 bootBtn my-5 btn-font-teacher'
                        type='button'
                        style={buttonStyle}
                        onClick={addInput}
                        value={data.count}
                    >
                        Add File
                    </button>

                    <div className='col-md-8 d-flex'>
                        <button
                            className='btn text-light px-4 py-1 bootBtn my-5'
                            type='button'
                            style={buttonStyle}
                            onClick={handleSaveClick}
                        >
                            save
                        </button>
                        {showAlert && <div className="alert alert-warning mt-2">You can Add only Total 10 MB.</div>}
                        {loading && (
                            <div className="loading-spinner">
                                <RotatingLines type="TailSpin" color="#00BFFF" height={80} width={80} visible={true} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddResource;
