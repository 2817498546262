import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { format } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import "./Quotation.scss";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Navigate, useNavigate } from "react-router-dom";
import NavTution from "./Navtution"
import NavtutionOnline from "./NavtutionOnline";
import LoadingSpinner from "../../../../../../Reactloader/LoadingSpinner";
import QuotationDetailsComponent from './QuotationDetails';
import { showErrorToast, showSuccessToast } from './../../../../../../../util/toastUtils'
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";

function Quotation({ ...props }) {
  const navigate = useNavigate();
  const customStyles = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#5C5AB6",
        fontSize: "13px",
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
      },
    },
  };

  const [Quotation, setQuotation] = useState([{
    category: "",
    sub_category: "",
    details: "",
    topic: "",
    closing_date: "",
    student_id: "",
    type: "",
    currency: "",
    price: " ",
    term: "",
    file: "",
    status: "",
    updated_at: "",
    created_at: "",
    id: "",
    dates: {
      monday: { from: "", to: "" },
      tuesday: { from: "", to: "" },
      wednesday: { from: "", to: "" },
      thursday: { from: "", to: "" },
      friday: { from: "", to: "" },
      saturday: { from: "", to: "" },
      sunday: { from: "", to: "" }
    }

  }])
  const table = props.userState.dataTable || {};
  const [selectedOption, setSelectedOption] = useState("");
  const [type, settype] = useState("");
  const [hoverEffect, setHoverEffect] = useState(false);
  const [QuotationDetails, setQuotationDetails] = useState(false);
  const [details, setDetails] = useState(false);
  const [displayTable, setdisplayTable] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [id, setid] = useState(_.get(Quotation, 'id'));

  const [QuotationView, setQuotationView] = useState([{
    id: "",
    category: "",
    sub_category: "",
    details: "",
    topic: "",
    closing_date: "",
    student_id: "",
    type: "",
    currency: "",
    price: " ",
    term: "",
    file: "",
    status: "",
    updated_at: "",
    created_at: "",
    id: "",
    dates: {
      monday: { from: "", to: "" },
      tuesday: { from: "", to: "" },
      wednesday: { from: "", to: "" },
      thursday: { from: "", to: "" },
      friday: { from: "", to: "" },
      saturday: { from: "", to: "" },
      sunday: { from: "", to: "" }
    }

  }])

  const columns = [
    {
      name: "Closing Date",
      selector: (row) => row.closing_date,

    },

    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      grow: 2,

    },
    {
      name: "Sub Category",
      selector: (row) => row.sub_category,
      sortable: true,
      grow: 2,

    },
    {
      name: "Topic",
      selector: (row) => row.topic,
      sortable: true,
      grow: 2,

    },
    {
      name: "JobType",
      selector: (row) => {
        if (row.type === "1") {
          return "Online Tuition";
        } else if (row.type === "2") {
          return "Freelance Tuition";
        } else {
          return "Unknown Type";
        }
      },

    },
    {
      name: " Status",
      selector: (row) => row.status,
      cell: (row) => {
        const isActive = row.status === '1';
        return (
          <div className={isActive ? "active-row" : "inactive-row"}>
            {isActive ? "Active" : "Inactive"}
          </div>
        );
      },

    },
    {

      name: 'Action', selector: 'Action',

      cell: (row) => (
        <div>
          <button
            type="button"
            className="details-table-btn ps-2 pe-2 pt-1 pb-1"
            onClick={() => {
              console.log('Clicked with id:', row.id); // Add this line for debugging
              QuotationviewStudent(row.id);
              console.log(id);
            }}
          >
            Details
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true, sortable: true

    },
  ];

  const [showTable, setshowTable] = useState(false);
  const handleInput = (e) => {
    setQuotation({ ...Quotation, [e.target.name]: e.target.value });
  }
  const successCallback = (res) => {
    setSelectedOption("");
    QuotationlistStudent();
  }

  const Backdashboard = () => {
    if (selectedOption) {
      setSelectedOption("");
    } else {
      if (_.get(props, "setUserState")) {
        props.setUserState({ ...props.userState, subType: "Dashboard" });
      }
    }
  };

  const getTitleText = () => {
    switch (selectedOption) {
      case "": return "Quotation";
      case "online": return "Online Tution";
      case "freelance": return "Freelance Job";
      default: return "Quotation"
    }
  }

  const getDetailsProps = () => {
    return {
      loading: loading,
      setLoading: setLoading,
      setLoader: setLoader,
      details: details,
      selectedOption: selectedOption,
      setSelectedOption: setSelectedOption,
      QuotationView: QuotationView,
      setQuotationView: setQuotationView,
      QuotationDetails: QuotationDetails,
      setQuotationDetails: setQuotationDetails,
      QuotationlistStudent: QuotationlistStudent,
      QuotationviewStudent: QuotationviewStudent,
      setQuotation: setQuotation,
      Quotation: Quotation,
      loader: loader,
      showTable: showTable,
      setid: setid,
      id: id
    }
  }
  const QuotationlistStudent = () => {

    if (!localStorage.getItem('token')) {
      Backdashboard();
    }
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

    Axios.post(
      '/api/listQuotation', undefined, { headers: headers })
      .then((response) => {
        if (_.get(response, 'data.data')) {
          setQuotation(response.data.data);
          setLoading(false)
        }
        const formattedData = response.data.data.map((item) => ({
          ...item,
          closing_date: format(new Date(item.closing_date), 'dd-MM-yyyy'),
        }));

        setQuotation(formattedData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong");
        setLoading(false)
      })
  }

  const QuotationviewStudent = (id) => {
    if (!localStorage.getItem('token')) {
      Backdashboard();
    }

    const formData = new FormData();
    const quoteid = parseInt(id);  // Convert the id to an integer, default to 0 if not a valid integer

    // if (!isNaN(quoteid) && Number.isInteger(quoteid)) {
    formData.append("id", quoteid.toString());
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

    Axios.post(
      '/api/viewQuotation', formData, { headers: headers })
      .then((response) => {
        if (_.get(response, 'data.data')) {
          console.log(response.data.data);
          setQuotationView(response.data.data);
          setLoading(false)
          setQuotationDetails(!details);
          setid(_.get(Quotation[id], 'id'));
          const newData = response.data.data.map((item) => ({
            ...item,
            closing_date: format(new Date(item.closing_date), 'dd-MM-yyyy'),
          }));

          setQuotationView(newData); // Replace the previous data with the new data
          showSuccessToast("status updated")
        }


      })
      .catch((error) => {
        showErrorToast("Something Went Wrong");
        setLoading(false);
      });

  }
  useEffect(() => {
    QuotationlistStudent();

    // Simulate loading delay with a setTimeout
    setTimeout(() => {
      setLoader(false);
      ; // Set Loader to false when data is loaded
    }, 500); // 2 seconds delay
  }, []);

  const handleNewQuotation = () => {
    navigate('/netpage'); // Navigate to /netpage
  };

  return (
    <div className="sidebar_content">
      {/* {loader ? <LoadingSpinner /> : */}
      <div className="main-divHead">
        <div className="sub-Head1" onClick={Backdashboard}>
          <div className="Sub-Head2"> {getTitleText()} </div>
        </div>
        {!QuotationDetails && (
          <div className=" col-md-12 md-3">
            <div className="new-quotation">
            <select
                  onChange={(e) => (setSelectedOption(e.target.value))
                  }
                  className="quote">
                  <option value="" className="quote1" >
                    {" "}
                    New Quotation
                  </option>
                  <option value="online" className="quote2" >
                    Online Tution
                  </option>
                  {/* <option value="freelance" className="quote3">
                  Freelance Job
                </option> */}
                </select>
            </div>
            {!showTable && selectedOption !== "online" && selectedOption !== "freelance" &&
              <div className="p-xxl-4 p-xl-4 p-md-4 p-sm-4">
                <DataTable className="data-table1"
                  columns={columns}
                  data={Quotation}
                  fixedHeader={true}
                  fixedHeaderScrollHeight="600px"
                  striped
                  customStyles={customStyles}
                  persistTableHead
                  pagination
                />
              </div>

            }
          </div>
        )}
        {selectedOption == "" &&
          <div>
            {QuotationDetails && (
              <QuotationDetailsComponent {...getDetailsProps(props)} />
            )}
          </div>
        }
        {selectedOption == "online" && <NavTution successCallback={successCallback} {...props} >online</NavTution>}
        {/* {
        selectedOption == "freelance" && <NavtutionOnline {...props}>Freelance</NavtutionOnline>} */}
      </div>
      {/* // } */}
    </div>
  );
}
export default Quotation;
