import React, { useEffect } from 'react';
import img from '../../../../../../images/Icon (2).png';
import img1 from '../../../../../../DashImage/Graph.png'
import img2 from '../../../../../../images/Icon.png'
import img3 from '../../../../../../images/Icon (3).png'
import img4 from '../../../../../../images/Circle 2 (3).png'
import CalenderComponent from './calender.component';
import ChartComponent from './chart.component';
import DashboardSubjectTableComponent from './DashboardSubjectTable.component';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardData, fetchDashboardData } from '../../../../../../redux/slice/SubjectSlice';
import DashboardNotificationComponent from './dashboardNotification.component';
import { showSuccessToast } from '../../../../../../util/toastUtils';

function AdminDashboard() {

  const dispatch = useDispatch();
  const data = useSelector(DashboardData)
  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);

  return (
    <div >
      <h5 class='admin-head-dash px-4 pt-4 ms-2 pb-2'>Admin Dashboard</h5>
      <div class="container m-xxl-2 m-xl-2 m-lg-2 ">
        <div class="row">
          <div class="col-lg-3 col-12 mt-3 mt-lg-0">
            <div className='admin-card p-xxl-3 p-xl-2 p-lg-3'>
              <div class="container">
                <div class="row">
                  <div class="col px-lg-0">
                    <p class='admin-gray-text   '>Total Students</p>
                    <h4 class='fw-bold mb-lg-0'>{data?.totalStudents}</h4>
                  </div>
                  <div class="col-xxl-4 col-xl-3 col-lg-4 px-lg-0">
                    <img src={img} class="img-fluid  " alt="" />
                  </div>
                </div>
                <div class="row mt-3 ">
                  <div class="col  px-lg-0">
                    <p class='mb-0'><span><img src={img1} class="img-fluid " alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12 mt-3 mt-lg-0 ">
            <div className='admin-card p-xxl-3 p-xl-2 p-lg-3'>
              <div class="container">
                <div class="row">
                  <div class="col px-lg-0">
                    <p class='admin-gray-text'>Total Teachers</p>
                    <h4 class='fw-bold mb-lg-0'>{data?.totalTeachers}</h4>
                  </div>
                  <div class="col-xxl-4 col-xl-3 col-lg-4 px-lg-0">
                    <img src={img2} class="img-fluid  " alt="" />
                  </div>
                </div>
                <div class="row mt-3 ">
                  <div class="col  px-lg-0">
                    <p class='mb-0'><span><img src={img1} class="img-fluid " alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12 mt-3 mt-lg-0">
            <div className='admin-card p-xxl-3 p-xl-2 p-lg-3'>
              <div class="container">
                <div class="row">
                  <div class="col px-lg-0">
                    <p class='admin-gray-text'>Total Freelancers</p>
                    <h4 class='fw-bold mb-lg-0'>{data?.totalFreelancer}</h4>
                  </div>
                  <div class="col-xxl-4 col-xl-3 col-lg-4 px-lg-0">
                    <img src={img4} class="img-fluid  " alt="" />
                  </div>
                </div>
                <div class="row mt-3 ">
                  <div class="col  px-lg-0">
                    <p class='mb-0'><span><img src={img1} class="img-fluid " alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12 mt-3 mt-lg-0">
            <div className='admin-card p-xxl-3 p-xl-2 p-lg-3'>
              <div class="container">
                <div class="row">
                  <div class="col px-lg-0">
                    <p class='admin-gray-text'>Total Course Uploaders</p>
                    <h4 class='fw-bold mb-lg-0'>{data?.totalUploader}</h4>
                  </div>
                  <div class="col-xxl-4 col-xl-3 col-lg-4 px-lg-0 ">
                    <img src={img3} class="img-fluid  " alt="" />
                  </div>
                </div>
                <div class="row mt-3 ">
                  <div class="col  px-lg-0">
                    <p class='mb-0'><span><img src={img1} class="img-fluid " alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-xxl-5 mt-xl-5 mt-lg-4">
          <div class="col-lg-8 col">
            <ChartComponent />
          </div>
          <div class="col-lg-4 col mt-3 mt-lg-0">
            <CalenderComponent />
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-8 col">
            <DashboardSubjectTableComponent />
          </div>
          <div class="col-lg-4 col mt-3 mt-lg-0">
            <DashboardNotificationComponent />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard