// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
    name: 'counter',
    initialState: {
        value: 0,
        EditData: []
    },
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
        decrement: (state) => {
            state.value -= 1;
        },
        StateUpdate: (state, action) => {
            return {
                ...state,
                EditData: action.payload
            }
        },
    },
});

export const { increment, decrement, StateUpdate } = counterSlice.actions;

export default counterSlice.reducer;
