import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// import img from '../../../../../../images/Frame 135 (1).jpg'
// import img1 from '../../../../../../images/Frame 135.jpg'
import { useSelector } from 'react-redux';
import { uploadList, viewUploadCourseData } from '../../../../../../redux/slice/UploadSlice';

function RecorderCourseComponent() {


    const data = useSelector(viewUploadCourseData)

    console.log("data****", data);



    return (
        <div>
            <div className='main-divHead' >
                <div className='sub-Head1' >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Course Upload</div>
                </div>
                <div className='admin-card m-5 px-5 py-3'>
                    <h6 className='fw-bold'>Recorded Course</h6>
                    <video />
                    <div className='upload-course-card text-light p-3'>
                        <div class="container text-start">
                            <div class="row">
                                <div class="col-1">
                                    <div>
                                        {/* <img src={img} class="img-fluid rounded-top" alt="..." /> */}
                                    </div>
                                </div>
                                <div class="col">
                                    <h5 className='fw-bold mt-1'>Maths</h5>
                                    <p className='p-0'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container  mb-5 mt-4">
                        <div class="row">
                            <div class="col ">
                                <div className="container ">
                                    <div className="form-group ">
                                        <select className="form-select admin-input-view" id="exampleDropdown">
                                            <option value="Get Started">Get Started</option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div className="container ">
                                    <div className="form-group ">
                                        <select className="form-select admin-input-view" id="exampleDropdown">
                                            <option value="Content 1">Content 1</option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div className="container ">
                                    <div className="form-group ">
                                        <select className="form-select admin-input-view" id="exampleDropdown">
                                            <option value="Content 1">Content 2</option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div className="container ">
                                    <div className="form-group ">
                                        <select className="form-select admin-input-view" id="exampleDropdown">
                                            <option value="Content 1">Content 3</option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div className="container ">
                                    <div className="form-group ">
                                        <select className="form-select admin-input-view" id="exampleDropdown">
                                            <option value="Content 1">Content 4</option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div className="container ">
                                    <div className="form-group ">
                                        <select className="form-select admin-input-view" id="exampleDropdown">
                                            <option value="Content 1">End Course</option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5 className='fw-bold m-4'>About Course</h5>
                        <p className='mx-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id es Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to Nemo enim ipsam voluptatem quia voluptas sit aspernatur</p>
                        <p className='mx-4'>Ratings <span className='fw-bold'>4.5</span> (107) <span className='fw-bold'>View All</span></p>
                    </div>
                    <h5 className='fw-bold m-4'>Reviews</h5>
                    <div class="container text-center">
                        <div class="row">
                            <div class="col">
                                <div class='admin-card admin-video-personCard p-3 m-4'>
                                    <div class="container  text-start">
                                        <div class="row">
                                            <div class="col-4 my-2">
                                                {/* <img src={img1} class="img-fluid rounded-top " alt="" /> */}
                                            </div>
                                            <div class="col">
                                                <h6 className='fw-bold m-3'>Abilash</h6>
                                            </div>
                                        </div>
                                        <div class="row text-start">
                                            <div class="col">
                                                <p class='mb-0'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class='admin-card admin-video-personCard p-3 m-4'>
                                    <div class="container  text-start">
                                        <div class="row">
                                            <div class="col-4 my-2">
                                                {/* <img src={img1} class="img-fluid rounded-top " alt="" /> */}
                                            </div>
                                            <div class="col">
                                                <h6 className='fw-bold m-3'>Abilash</h6>
                                            </div>
                                        </div>
                                        <div class="row text-start">
                                            <div class="col">
                                                <p class='mb-0'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class='admin-card admin-video-personCard p-3 m-4'>
                                    <div class="container  text-start">
                                        <div class="row">
                                            <div class="col-4 my-2">
                                                {/* <img src={img1} class="img-fluid rounded-top " alt="" /> */}
                                            </div>
                                            <div class="col">
                                                <h6 className='fw-bold m-3'>Abilash</h6>
                                            </div>
                                        </div>
                                        <div class="row text-start">
                                            <div class="col">
                                                <p class='mb-0'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container text-center">
                        <div className="row">
                            <div className="col">
                                <button
                                    type="submit"
                                    className="btn btn-primary px-4 py-1 uploade-review-btn my-5"
                                >
                                    Show all Reviews
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RecorderCourseComponent