import React from 'react'
import membershipWhiteIcon from "../../../icons/membershipWhiteIcon.png"
import membershipBlueIcon from "../../../icons/membershipBlueIcon.png"
import _ from "lodash"
import { BsFillCreditCardFill } from 'react-icons/bs'

function WalletComponent(props) {
    const goToTTeacherWallet = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminWallet' })
        }
    }
    return (
        <div
            className={`sidebar_content ${_.get(props, 'userState.subType') == 'adminWallet' ? 'active' : ''} `}
            onClick={(e) => goToTTeacherWallet(e)}>
            <div className="dash d-flex">
                <BsFillCreditCardFill class='ms-3' />
                <p className='head-dashboard ms-2' > Wallet
                </p>
            </div>
        </div>
    )
}

export default WalletComponent