import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RiAddCircleFill } from 'react-icons/ri';
import React from 'react';
import img from '../../../../../../images/bi_image-fill.jpg'

function AddSubjectFreelanceComponent() {
    return (
        <div>
            <div className='main-divHead' >
                <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Subjects</div>
                </div>
                <div>
                    <h5 class='fw-bold px-4 pt-4 pb-2'>Select / Add Subject</h5>
                    <div class="container mt-5 mb-3">
                        <div class="row">
                            <div class="col">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <div className="form-group">
                                                <div>
                                                    <label class="fs-6 fw-semibold">Category</label>
                                                    <select className="form-select" id="exampleDropdown">
                                                        <option value=""></option>
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                        <option value="option4">Option 4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='col-2 d-flex align-items-end ps-0'>
                                            <RiAddCircleFill class='admin-add-icon mb-2' />
                                        </div>
                                        <div class="col">
                                            <div className="form-group">
                                                <label class="fs-6 fw-semibold">Sub Category / Class</label>
                                                <select className="form-select" id="exampleDropdown">
                                                    <option value=""></option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                    <option value="option4">Option 4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class='col-2 d-flex align-items-end ps-0'>
                                            <RiAddCircleFill class='admin-add-icon mb-2' />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div className="form-group">
                                                <div>
                                                    <label class="fs-6 fw-semibold">Subject / Topic</label>
                                                    <select className="form-select" id="exampleDropdown">
                                                        <option value=""></option>
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                        <option value="option4">Option 4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='col-2 d-flex align-items-end ps-0'>
                                            <RiAddCircleFill class='admin-add-icon mb-2' />
                                        </div>
                                        <div class="col">
                                            <div className="form-group">
                                                <label class="fs-6 fw-semibold">Teaching Language</label>
                                                <select className="form-select" id="exampleDropdown">
                                                    <option value=""></option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                    <option value="option4">Option 4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class='col-2 d-flex align-items-end ps-0'>
                                            <RiAddCircleFill class='admin-add-icon mb-2' />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <div className="form-group">
                                                <div>
                                                    <label class="fs-6 fw-semibold">Levels</label>
                                                    <select className="form-select" id="exampleDropdown">
                                                        <option value=""></option>
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                        <option value="option4">Option 4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='col-2'></div>
                                        <div class="col-5">
                                            <div className="form-group">
                                                <label class="fs-6 fw-semibold">Attach Promotional Video Link</label>
                                                <select className="form-select" id="exampleDropdown">
                                                    <option value=""></option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                    <option value="option4">Option 4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class='admin-card admin-dotted-border m-5 p-5 text-center'>
                                    <img src={img} class="img-fluid rounded-top" alt="..." />
                                    <p class='fs-6 mt-2'>Upload course image</p>
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-4 py-1 bootBtn" >
                                        Browse File
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class='row mt-4 ms-2'>
                            <div class='col-6'>
                                <label class="fs-6 fw-semibold">Subject / Topic</label>
                                <textarea class="form-control" placeholder="Leave a comment here" ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="container text-end me-5">
                        <div className="row">
                            <div className="col">
                                <button
                                    type="submit"
                                    className="btn btn-primary px-4 py-1 bootBtn my-5 me-5"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddSubjectFreelanceComponent