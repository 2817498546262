import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import './TeacherAttendence.scss';
import { showErrorToast, showSuccessToast } from "../../../../../../util/toastUtils"
import { InputGroup } from 'react-bootstrap';
import Select from "react-select";
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

// this is a schedule page

function TeacherAttendence(props) {
    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [errors, setErrors] = useState([""]);
    const [fileUpload, setFileUpload] = useState(null);
    const [edit, setEdit] = useState();
    const BatchOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    const StudentOptions = ['1', '2', '3', '4'];
    const FromOptions = ['', ''];
    const ToOptions = ['', ''];
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [selectedBatch, setSelectedBatch] = useState("")

    const { values, setFieldValue, } = props;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setScheduleValue({ ...scheduleValue, [name]: value });

        if (name === "batch_id") {
            // Update the student list based on the selected batch
            listStudents(value);
        }

    };



    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const ScheduleValidationSchema = Yup.object().shape({
        category: Yup.string().required('category is required'),
        sub_category: Yup.string().required('sub Category is required'),
        topic: Yup.string().required('topic is required'),
        batch_id: Yup.string().required('batch is required'),
        student_id: Yup.string().required('student is required'),
        course_link: Yup.string().required('course Link is required'),
        message: Yup.string().required('message is required'),
        from: Yup.string().required('from time is required'),
        to: Yup.string().required('to time is required'),
        date: Yup.string().required('date time is required'),
    });


    const [scheduleValue, setScheduleValue] = useState({
        email: '',
        category: '',
        sub_category: '',
        topic: '',
        batch_id: '',
        student_id: '',
        course_link: '',
        message: '',
        from: '',
        to: '',
        scheduledDate: '',
    })

    const [batch, setBatch] = useState([{}])
    const [topic, setTopic] = useState([{}])
    const [subCategories, setSubCategories] = useState([{}])
    const [categories, setCategories] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [batches, setBatches] = useState([])
    const [studentList, setStudentList] = useState([])
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [totalList, setTotalList] = useState([]);
    const [subject, setSubject] = useState([{}])
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [topicOptions, setTopicOptions] = useState([]);



    // const listBatches = (id) => {
    //     const token = localStorage.getItem('token');
    //     if (!token) {
    //         // Handle token not available
    //         return;
    //     }
    //     const formData = new FormData();
    //     const headers = { Authorization: 'Bearer ' + (localStorage.getItem('token')) };

    //     axios
    //         .post('http://tuition.techiesmarkets.com/backend/public/api/listBatch', formData, { headers: headers })
    //         .then((response) => {
    //             if (_.get(response, "data.data")) {
    //                 const BatchObject = response.data.data;
    //                 const BatchArray = Object.values(BatchObject);
    //                 setBatches(BatchArray);

    //                 if (BatchArray.length > 0) {
    //                     listSubCategories(BatchArray[0].category_id);
    //                     listStudents(BatchArray[0].id);

    //                 }
    //             }
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching batches:', error);
    //             setLoading(false);
    //         })
    // }


    const listBatches = (subjectId) => {
        // Make sure your token is properly retrieved and stored
        const token = localStorage.getItem('token');
        if (!token) {
            // Handle token not available
            return;
        }

        const headers = { Authorization: 'Bearer ' + token };
        Axios.post('/api/listBatch', { subjectId }, { headers })
            .then((response) => {
                if (response.data.success) {
                    const batchData = response.data.data.batch;
                    setBatches(batchData);

                    // Automatically select the first batch from the fetched batches
                    if (batchData.length > 0) {
                        setSelectedBatch(batchData[0]); // Assuming setSelectedBatch is the setter function for the selected batch state
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching batches:', error);
                setLoading(false);
            });
    };


    console.log("batches", batches);



    const listStudents = (batchId) => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
        }
        const formData = new FormData()
        formData.append('id', batchId || 0)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/listBatchStudents', formData, { headers: headers })
            .then((response) => {
                if (response.data.data) {
                    const studentListData = response.data.data;

                    // Convert the object of student names and IDs into an array of objects
                    const studentListArray = Object.keys(studentListData).map(studentName => ({
                        value: studentListData[studentName],
                        label: studentName,
                    }));
                    setStudentList(studentListArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching students:', error);
                setLoading(false);
            })
    }


    useEffect(() => {
        listBatches()
        listStudents()
    }, [])



    const listSubCategories = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
        }

        const createSchedule = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {}
        const formData = new FormData()
        formData.append('type', 2)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/getCategoryandTopicDetails', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubcategoriesObject = response.data.data;
                    const SubcategoriesArray = Object.values(SubcategoriesObject)
                    setSubCategories(SubcategoriesArray)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error)
                setLoading(false)
            })
    }



    const handleDateChange = (e) => {
        setScheduleValue((prevScheduleValue) => ({
            ...prevScheduleValue,
            scheduledDate: e.target.value,
        }));
    };

    const handleTimeChange = (e) => {
        setScheduleValue((prevScheduleValue) => ({
            ...prevScheduleValue,
            from: e.target.value,
        }));
    };


    const [successToastShown, setSuccessToastShown] = useState(false);
    const successToastShownRef = useRef(false);

    const ToSubmit = (values) => {

        const formData = new FormData()
        formData.append("category", selectedSubject.category);
        formData.append("sub_category", selectedSubject.sub_category);
        formData.append("topic", selectedSubject.subject);
        formData.append("batch_id", scheduleValue.batch_id)
        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("student_id[]", record.value || []);
        }
        formData.append("course_link", scheduleValue.course_link)
        formData.append("message", scheduleValue.message)
        formData.append('from', scheduleValue.from)
        formData.append('to', scheduleValue.to)
        formData.append('scheduledDate', date);

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/createSchedule', formData, { headers: headers })
            .then((res) => {
                if (res.data.success) {

                    if (res.data.success && !successToastShownRef.current) {
                        showSuccessToast("Create schedule created successfully");
                        successToastShownRef.current = true;
                    }

                    setLoading(true);
                    setTimeout(() => {
                        // After the operation is complete, set loading back to false
                        setLoading(false);
                        // Other logic after the operation, such as showing a success message
                    }, 2000);
                    Backdashboard();
                }
                console.log(res.data);
            })
            .catch((error) => {
                console.error('Error creating schedule:', error);
                showErrorToast("There is some issue in create schedule");
            });
    };

    useEffect(() => {
        // Reset successToastShown when the component unmounts
        return () => {
            successToastShownRef.current = false;
        };
    }, []);


    const ToEdit = (values) => {
        const createSchedule = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("email", _.get(createSchedule, 'data.email'));
        formData.append("category", selectedSubject.category);
        formData.append("sub_category", selectedSubject.sub_category);
        formData.append("topic", selectedSubject.subject);
        formData.append("batch_id", scheduleValue.batch_id);
        // formData.append("student_id[]", scheduleValue.student_id);

        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("student_id[]", record.value || []);
        }


        formData.append("course_link", scheduleValue.course_link);
        formData.append("message", scheduleValue.message);
        formData.append('from', scheduleValue.from);
        formData.append('to', scheduleValue.to);

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/editSchedule', formData, { headers: headers })
            .then((res) => {
                const updateData = res.data;
                setEdit(updateData);
                console.log(res.data);
                Backdashboard();
                showSuccessToast("Edit schedule created successfully");
            })
            .catch((error) => {
                console.error('Error updating schedule:', error);
            });
    };

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, []);

    const initializeObject = () => {
        setScheduleValue({ ...scheduleValue, ...props.userState.row });
    };

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherschedule' });
        }
    };


    const listSubject = (subjectId) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }

        // const createSchedule = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/listSubject', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubjectObject = response.data.data;
                    const SubjectArray = Object.values(SubjectObject);
                    setSubject(SubjectArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
                setLoading(false);
            });
    };




    useEffect(() => {

        listSubject()
    }, [])

    const dispatch = useDispatch();
    const data = useSelector(CSTData);
    const subCatData = useSelector(SubData);
    const TopicData = useSelector(topicData);




    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
    }, []);


    // const categoryOptions = data?.map((e) => ({
    //     value: e.id,
    //     label: e.name
    // }));


    const SubCategoryOptionsList = subCatData?.map((e) => ({
        value: e.id,
        label: e.name
    }));

    const TopicOptionsList = TopicData?.map((e) => ({
        value: e.id,
        label: e.name
    }));
   

    const handleMultipleChange = (selectList) => {
        console.log(selectList);
        setTotalList(selectList || []);
    };

    const handleSubjectChange = (e) => {
        const subjectId = parseInt(e.target.value);
        const selected = subject.find(sub => sub.id === subjectId);
        setSelectedSubject(selected);

        // Populate category options
        if (selectedSubject) {
            const categories = [{ value: selectedSubject.category, label: selectedSubject.category_name }];
            setCategoryOptions(categories);

            // Populate subcategory options
            const subCategories = [{ value: selectedSubject.sub_category, label: selectedSubject.sub_category_name }];
            setSubCategoryOptions(subCategories);

            // Populate topic options
            const topics = [{ value: selectedSubject.subject, label: selectedSubject.topic_name }];
            setTopicOptions(topics);

            // Fetch batches related to the selected subject
            setSelectedSubject(selected);
        }
    };


    const newArray = studentList.filter(mainItem => !totalList.some(selectItem => selectItem.value === mainItem.value && selectItem.label === mainItem.label));

    return (
        <div className='main-divHead'>
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Schedule</div>
            </div>


            <div className='container mt-3'>
                <div className="row">
                    <div className='col-12'>
                        {!_.get(props, 'userState.row') && <div className='main-schedule-head ms-xxl-5 ms-xl-5 ms-lg-3 ms-md-0' ><p className='Title-main-head '>Create Schedule</p></div>}
                        {_.get(props, 'userState.row') && <div className='main-schedule-head ml-2'><p className='Title-main-head '>Edit Schedule</p></div>}
                    </div>
                </div>
                <Formik
                    initialValues={{
                        category: '',
                        sub_category: '',
                        topic: '',
                        batch_id: '',
                        student_id: '',
                        course_link: '',
                        message: '',
                        from: '',
                    }}
                    validationSchema={ScheduleValidationSchema}
                    onSubmit={(values, { resetForm }) => {

                        ToSubmit();
                        resetForm();

                    }}

                >

                    {({ errors, touched, setFieldValue }) => (
                        <Form>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose subject</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={scheduleValue.subject}
                                                name="subject"
                                                as="select"
                                                onChange={handleSubjectChange} >

                                                <option value="">--select an option--</option>
                                                {subject.map((subject, index) => (
                                                    <option key={index} value={subject.id}>
                                                        {subject.name}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="subject_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Subject</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={scheduleValue.subject}
                                                name="subject"
                                                as="select"
                                                onChange={handleSubjectChange} >
                                                required=""

                                                <option value="">--select an option--</option>
                                                {subject.map((subject, index) => (
                                                    <option key={index} value={subject.id}>
                                                        {subject.name}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="subject_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>


                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4  subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={scheduleValue?.category}
                                                as="select"
                                                name="category"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 2,
                                                        "category_id": selectedValue,
                                                    };
                                                    dispatch(getSubCategoryData(payload));
                                                    setFieldValue("category", e.target.value)
                                                    handleChange(e);
                                                }}

                                                required=""
                                            >

                                                {categoryOptions?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={scheduleValue?.category}
                                                as="select"
                                                name="category"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 2,
                                                        "category_id": selectedValue,
                                                    };
                                                    dispatch(getSubCategoryData(payload));
                                                    setFieldValue("category", e.target.value)
                                                    handleChange(e);
                                                }}

                                                required=""
                                            >

                                                {categoryOptions?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 ms-md-0 mb-0 teacher-font-label">Choose Sub Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={scheduleValue.sub_category}
                                                name="sub_category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 3,
                                                        "sub_category_id": selectedValue,
                                                    };
                                                    dispatch(getSubTopicData(payload));
                                                    setFieldValue("sub_category", e.target.value)
                                                    handleChange(e);
                                                }}

                                            >

                                                {subCategoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="sub_category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label mb-0 teacher-font-label">Choose Sub Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={scheduleValue.sub_category}
                                                name="sub_category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 3,
                                                        "sub_category_id": selectedValue,
                                                    };
                                                    dispatch(getSubTopicData(payload));
                                                    setFieldValue("sub_category", e.target.value)
                                                    handleChange(e);
                                                }}
                                            >

                                                {subCategoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>


                                            <ErrorMessage
                                                name="sub_category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>


                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Topic</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={scheduleValue.topic}
                                                name="topic"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("topic", e.target.value)
                                                    handleChange(e)
                                                }}


                                            >

                                                {topicOptions.map((option) => (
                                                    <option key={option.vale} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="topic"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0  mb-0 teacher-font-field">Choose Topic</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label"
                                                value={scheduleValue.topic}
                                                name="topic"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("topic", e.target.value)
                                                    handleChange(e)
                                                }}


                                            >

                                                {topicOptions.map((option) => (
                                                    <option key={option.vale} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="topic"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div> <br />

                            </div>

                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 ms-md-0  mb-0 teacher-font-label">Choose Batch</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={scheduleValue.batch_id}
                                                name="batch_id"
                                                as="select"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldValue("batch_id", e.target.value);
                                                }}
                                                required=""
                                            >

                                                <option value="">Select a batch</option>
                                                {batches.map((batch, index) => (
                                                    <option key={index} value={batch.id}>
                                                        {batch.name}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="batch_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 ms-md-0  mb-0 teacher-font-label">Choose Batch</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={scheduleValue.batch_id}
                                                name="batch_id"
                                                as="select"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const selectedBatchId = e.target.value;
                                                    listStudents(selectedBatchId);
                                                    setFieldValue("batch_id", e.target.value)
                                                }}
                                                required=""
                                            >

                                                <option value="" >Select an option</option>
                                                {batches.map((batch, index) => (
                                                    <option key={index} value={batch.id}>
                                                        {batch.name}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="batch_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>


                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Student</label>

                                            <InputGroup>
                                                <Select
                                                    value={totalList}
                                                    onChange={handleMultipleChange}
                                                    options={studentList}
                                                    className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                    isMulti
                                                />

                                            </InputGroup>

                                            <ErrorMessage
                                                name="student_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Student</label>


                                            <InputGroup>
                                                <Select
                                                    value={totalList}
                                                    onChange={handleMultipleChange}
                                                    options={studentList}
                                                    className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                    isMulti
                                                />

                                            </InputGroup>

                                            <ErrorMessage
                                                name="student_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>









                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4  subtitle-headings'> <>
                                    {!_.get(props, 'userState.row') && <label className="form-label mt-xxl-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Course Link</label>}<br />
                                    {!_.get(props, 'userState.row') && <Field type='text' className='form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field' placeholder='paste link here' name='course_link' value={scheduleValue.course_link} onChange={(e) => {
                                        setFieldValue("course_link", e.target.value)
                                        handleChange(e)
                                    }} required="" />}

                                    <ErrorMessage
                                        name="course_link"
                                        component="div"
                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                    />

                                </>
                                    {_.get(props, 'userState.row') && <label className="form-label  ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >Course Link</label>}<br />
                                    {_.get(props, 'userState.row') && <Field type='text' className='form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field' name='course_link' value={scheduleValue.course_link} onChange={(e) => {
                                        setFieldValue("course_link", e.target.value)
                                        handleChange(e)
                                    }} />}


                                </div>
                            </div>

                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-12 subtitle-headings'> <>
                                    {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >message For student</label>}<br />
                                    {!_.get(props, 'userState.row') && <Field type="textarea" class="form-control mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field" placeholder="Leave a comment here" value={scheduleValue.message} name='message' onChange={(e) => {
                                        setFieldValue("message", e.target.value)
                                        handleChange(e)
                                    }} required="" style={{ height: '100px' }} />}

                                    <ErrorMessage
                                        name="message"
                                        component="div"
                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                    />

                                </>
                                    {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2  mb-0 teacher-font-label" >message For student</label>}<br />
                                    {_.get(props, 'userState.row') && <Field type="textarea" className='form-control ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-field' value={scheduleValue.message} name='message' onChange={(e) => {
                                        setFieldValue("message", e.target.value)
                                        handleChange(e)
                                    }} style={{ height: '100px' }} />}

                                </div>
                            </div>

                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-4'>
                                    {!_.get(props, 'userState.row') && (<label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Scheduled Date</label>)}<br />
                                    {!_.get(props, 'userState.row') && (<input type='date' className='form-select ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-field' value={scheduleValue.scheduledDate} name='scheduledDate' onChange={handleDateChange} />)}
                                    {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Scheduled Date</label>}<br />
                                    {_.get(props, 'userState.row') && <input type='date' className='form-select ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-field' value={scheduleValue.scheduledDate} name='scheduledDate' onChange={handleDateChange} />}
                                </div>


                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 mt-xxl-4 mt-xl-4 mt-lg-5 mt-md-5 mt-sm-4  subtitle-headings'>
                                    <>
                                        {!_.get(props, 'userState.row') && <label className="form-label mt-xxl-4 mt-xl-4 mt-lg-0 ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label">From : </label>}
                                        {!_.get(props, 'userState.row') && <Field
                                            type='time'
                                            className="form-label mt-xxl-4 mt-xl-4 mt-lg-0  ms-xxl-5 ms-xl-5 ms-lg-2 col-ms-lg-5 teacher-font-field"
                                            name='from'
                                            value={scheduleValue.from}
                                            onChange={(e) => {
                                                setScheduleValue((prevScheduleValue) => ({
                                                    ...prevScheduleValue,
                                                    from: e.target.value,
                                                }));
                                            }}
                                        />
                                        }
                                        <ErrorMessage
                                            name="from"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />

                                    </>

                                    {_.get(props, 'userState.row') && <label className="form-label mt-xxl-4 mt-xl-4 mt-lg-0 ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label">From : </label>}
                                    {_.get(props, 'userState.row') && <Field
                                        type='time'
                                        className="form-label mt-xxl-5 mt-xl-5 mt-lg-4  ms-xxl-5 ms-xl-5 ms-lg-2 col-ms-lg-5 teacher-font-field"
                                        name='from'
                                        value={scheduleValue.from}
                                        onChange={(e) => {
                                            setFieldValue("from", e.target.value)
                                            handleChange(e)
                                        }}
                                    />}


                                </div>

                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 mt-xxl-4 mt-xl-4 mt-lg-5 mt-md-5 mt-sm-4 subtitle-headings'>
                                    <>
                                        {!_.get(props, 'userState.row') && <label className="form-label mt-xxl-4 mt-xl-4 mt-lg-0 ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label">To : </label>}
                                        {!_.get(props, 'userState.row') && <Field
                                            type='time'
                                            name='to'
                                            className="form-label mt-xxl-4 mt-xl-4 mt-lg-0  ms-xxl-5 ms-xl-5 ms-lg-2 col-ms-lg-5 teacher-font-field"
                                            value={scheduleValue.to}
                                            onChange={(e) => {
                                                setFieldValue("to", e.target.value)
                                                handleChange(e)
                                            }}
                                        />
                                        }
                                        <ErrorMessage
                                            name="to"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />

                                    </>

                                    {_.get(props, 'userState.row') && <label className="form-label mt-xxl-4 mt-xl-4 mt-lg-0 ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label">To : </label>}
                                    {_.get(props, 'userState.row') && <input
                                        type='time'
                                        className="form-label mt-xxl-5 mt-xl-5 mt-lg-4  ms-xxl-5 ms-xl-5 ms-lg-2 col-ms-lg-5 teacher-font-field"
                                        name='to'
                                        value={scheduleValue.to}
                                        onChange={(e) => {
                                            setFieldValue("to", e.target.value)
                                            handleChange(e)
                                        }}
                                    />}
                                </div>
                            </div>

                            <div className='row px-5'>
                                <div className='col-12 d-flex justify-content-end'>
                                    {!_.get(props, 'userState.row') && <button className='btn text-light px-4 py-1 bootBtn my-5' type="submit" onClick={(e) => ToSubmit()}>Submit</button>}
                                    {_.get(props, 'userState.row') && <button className='btn text-light px-4 py-1 bootBtn my-5' type="submit" onClick={(e) => ToEdit()}>Update</button>}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>


        </div>
    );
}

export default TeacherAttendence
