import React, { useState, useEffect } from 'react';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { RotatingLines } from 'react-loader-spinner';
import { ActiveCategoryID, addSubCategory, getCSTData, getSubCategoryData, subjectLoading, viewCategoryDetails } from '../../../../../../redux/slice/SubjectSlice';
import { showErrorToast, showSuccessToast } from '../../../../../../util/toastUtils';
import axios from 'axios';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function AddSubCategory({...props}) {

    const [selectedItem, setSelectedItem] = useState(props?.userState|| '')

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'addsubject' });
        }
    }
    const loading = useSelector(subjectLoading);
   
    const currentCategoryName = props.userState.categoryName
    console.log("currentCategoryName",currentCategoryName);
    const currentCategoryId = props.userState.category?.id;
    console.log("currentCategoryId",currentCategoryId);
   
const ToSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
   
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
    Axios.post('/api/addSubCategory',
        formData,
        { headers: headers })
        .then((res) => {
            if (res.data.success) {
                
                resetForm();
                showSuccessToast("Account details Added Successfully");
            }
            else {
                showErrorToast("Sub topic is not added")
            }
        })
        .catch((error) => {

            console.error('Error fetching Coupons:', error)
        })
        .finally(() => {
            setSubmitting(false); // Ensure setSubmitting is called in any case
        });
};
 

    const goToSubCategory = (categoryName) => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "subcategory", categoryName: categoryName });
        }
    };

    return (
        <div className='main-divHead'>
            <div className='sub-Head1'>
                <FontAwesomeIcon icon={faChevronLeft} onClick={Backdashboard} />
                <div className='Sub-Head2' >Add Subcategory</div>
            </div>
            <div className=" p-5">
                <h4 className="fw-bold">Add Sub Category</h4>
                <Formik
                    initialValues={{ category: currentCategoryId || '', subCategory: '' }}
                    onSubmit={ToSubmit}>               
                    <Form>
                        <div className="container">
                            <div className="row">
                                <div className="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3">
                                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                        <div className="form-group p-0">
                                            <label className="admin-label mt-4">Category</label>
                                            <Field
                                                type="text"
                                                name="category"
                                                value={currentCategoryName}
                                                className="form-control admin-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-center mb-3">
                                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                        <label className="admin-label">Sub Category / Class</label>
                                        <Field
                                            type="text"
                                            name="name"
                                            className="form-control admin-input"
                                        />
                                        <ErrorMessage
                                            name="subCategory"
                                            component="div"
                                            className="text-danger fs-6 mt-1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container text-center">
                            <div className="row">
                                <div className="col">
                                    <button
                                        type="submit"
                                        className="btn btn-primary text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                                        disabled={loading}>
                                        {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                                            strokeColor="white"
                                            strokeWidth="5"
                                            animationDuration="0.75"
                                            width="25"
                                            height="25"
                                            visible={true}
                                        /></div> : "Save"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default AddSubCategory;
