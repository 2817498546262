import React, { useState } from "react"
import dashboardWhiteIcon from "../../../icons/dashboardWhiteIcon.png"
import dashboardBlueIcon from "../../../icons/dashboardBlueIcon.png"
import _ from "lodash"


const Dashboard = ({ ...props }) => {
  const [hoverEffect, setHoverEffect] = useState(false)
  const goToDashboard = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'CourseDashboard' })
    }
  }
  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'CourseDashboard' ? 'active' : ''} `}>
      <div className="dash d-flex">
        <img
          src={_.get(props, 'userState.subType') != 'CourseDashboard' ? dashboardWhiteIcon : dashboardBlueIcon}
          alt='Dashboard'
        />
        <p className='head-dashboard' onClick={(e) => goToDashboard(e)}>Dashboard</p></div>

    </div>
  )
}

export default Dashboard
