import _ from 'lodash'
import React from 'react'
import { LuLayoutDashboard } from 'react-icons/lu';

function FreelanceDashboardComponent(props) {
  
  const goToDashboardTeacher = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'DashboardFreelance' })
    }
  }

  return (
    <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'DashboardFreelance' ? 'active' : ''} `} onClick={(e) => goToDashboardTeacher(e)}>
      <div className="dash d-flex">
        <LuLayoutDashboard class='ms-2'/>
        <p className='head-dashboard ms-2' > Dashboard
        </p>
      </div>
    </div>
  )
}

export default FreelanceDashboardComponent