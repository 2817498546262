import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios"
import "./Notifications.scss";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import rectangle53 from '../../../../../../../images/Rectangle 53.jpg';
import { showErrorToast, showSuccessToast } from './../../../../../../../util/toastUtils'
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";
function Notification({ ...props }) {
  const [isNotification, setIsNotification] = useState(false);
  const [loading, setLoading] = useState(false)
  const onclickNotification = () => {
    setIsNotification(false);
  };
  const onclickSchedule = () => {
    setIsNotification(true);
  };
  const Backdashboard = () => {
    if (_.get(props, "setUserState")) {
      props.setUserState({ ...props.userState, subType: "Dashboard" });
    }
  };
  const [scheduledata, setScheduledata] = useState([{
    id: '',
    category: "",
    sub_category: "",
    batch: "",
    student_id: "",
    message: "",
    des: "",
    created_by: "",
    created_at: "",
    updated_at: ""
  }])

  const [notification, setNotification] = useState([
    {
      id: '',
      category: "",
      sub_category: "",
      batch: "",
      student_id: "",
      message: "",
      des: "",
      created_by: "",
      created_at: "",
      updated_at: ""
    }
  ])

  const ListNotification = () => {

    if (!localStorage.getItem('token')) {
      Backdashboard();
    }
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
    Axios.post('/api/studentnotificaton', undefined,
      { headers: headers })
      .then((response) => {
        if (_.get(response, 'data.data')) {
          setNotification(response.data.data);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        showErrorToast("Error fetching subject data:", error);
        // Handle the error here, such as displaying an error message to the user.
      });
  }
  const schedule = (item) => {
    setScheduledata([...scheduledata]);
  }
  const ListSchedule = () => {

    if (!localStorage.getItem('token')) {
      Backdashboard();
    }
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
    Axios.post('/api/listScheduleStudent', undefined,
      { headers: headers })
      .then((response) => {
        if (_.get(response, 'data.data')) {
          setScheduledata(response.data.data);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        showErrorToast("Error fetching subject data:", error);
        // Handle the error here, such as displaying an error message to the user.
      });
  }
  useEffect(() => {
    ListNotification();
    ListSchedule();

  }, []);
  return (
    <div className="sidebar_content">
      {/* {loader ? <LoadingSpinner /> :  */}
      <div className='main-divHead' >
        <div className='sub-Head1' >
          <div className='Sub-Head2'>Notification</div>
        </div>
        <div>
          <div className="container text-center mt-5">
            <div className="d-flex">
              <div className={`Notifi student-Notifi col-xxl-6 col-xl-7 col-lg-7 col-md-6 col-sm-8 ${isNotification ? "" : "active"} `}>
                <div className="notifi-stu" onClick={(e) => onclickNotification()}> Notifications</div> 
              </div>
           <div class={`Schedule student-Schedule col-5 ${isNotification ? "active" : ""} `}>
                <div className="schedule-stu" onClick={(e) => onclickSchedule()}> Schedule</div>
              </div>
            </div>
            {isNotification &&
              <>
                {scheduledata.map(item => (
                  <div className="row mt-2" key={item.id}>
                    <div className="d-flex justify-content-center mt-5">
                      <div className="col-1">
                        <AccountCircleIcon />
                      </div>
                      <div className="col-7 text-start ms-5">
                        <p classn='student-font-noti ms-xxl-0 ms-xl-0 ms-lg-0 ms-md-0 ms-sm-5'>{item.message}</p>
                      </div>
                    </div>
                    <div className="container text-center">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex justify-content-center ">
                            <hr className='admin-horizontalLine'></hr>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            }
            {!isNotification && <div class="row mt-1">
              <>
                {notification?.map(item => (
                  <div className="row mt-2" key={item.id}>
                    <div className="d-flex justify-content-center mt-5">
                    <div className="col-1">
                        <AccountCircleIcon />
                      </div>
                      <div className="col-7 text-start ms-5">
                        <p classn='student-font-noti  '>{item.message}</p>
                      </div>
                    </div>
                    <div className="container text-center">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex justify-content-center ">
                            <hr className='admin-horizontalLine'></hr>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Notification;
