
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../../../../../../util/toastUtils';
import * as Yup from 'yup';
import { ActiveCategoryID, changeCategory, viewCategoryData, viewCategoryDetails, subjectLoading, currentCategoryID, getCSTData, deleteCategory, } from '../../../../../../redux/slice/SubjectSlice';
import { AiOutlineLeft } from 'react-icons/ai';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import { RotatingLines } from 'react-loader-spinner';
import { HiDotsVertical } from "react-icons/hi";
import { categoryListData, getCategoryListData } from '../../../../../../redux/slice/studentSlice';
import { format } from 'date-fns';
import { RiAlertFill } from "react-icons/ri";
import axios from 'axios';

function CategoryDetailsComponent({...props}) {

    const loading = useSelector(subjectLoading);
    const currentId = useSelector(ActiveCategoryID)
    const categoryData = useSelector(viewCategoryData);
    const dispatch = useDispatch();
    const categoryList = useSelector(categoryListData);

    const categoryId = categoryData?.category?.id
    console.log("categoryId",categoryId);
    const categoryname = categoryData?.category?.name

    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    const goToAddSubCategory = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminAddSubCategory', categoryName: categoryData });
        }
    };
    const goToAddTopic = (row) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminAddTopic', categoryName: categoryData, });
        }
    };

    const initialValues = {
        status: 'Active',
    };

    const validationSchema = Yup.object({
        status: Yup.string().required('Status is required'),
    });

    const onSubmit = (values) => {
        const payload = {
            "category_id": currentId,
            "status": values.status == 'Active' ? "1" : "0",
        }

        const viewCategory = {
            "category_id": currentId
        }

        const valueToSend = {
            payload,
            viewCategory
        }
        dispatch(changeCategory(valueToSend))
    };

    const goToSubjectTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'AdminSubject' })
        }
    }

    const goToSubCategoryDetailsPage = (row) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewsubcategory', rowSelected: row }) // passing this to useSate so sending in riwSelected
        }
    }
    const goToTopicDetailsPage = (row) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewtopic', rowSelected: row })
        }
    }


    function handleClickSubCategory(row) {
        goToSubCategoryDetailsPage(row);
        const payload = {
            // id: row.id,
            category_id: categoryId,
            type: 1
        };
        dispatch(viewCategoryDetails(payload));
        // dispatch(currentCategoryID(row.category_id));
    }




    const filteredCategories = categoryList.filter(item => {
        return item.sub_category_name !== null;
    })



    const customStyle = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
                textAlign: "center"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontWeight: "bold"
            }
        },
    };

    const columns = [
        {
            name: 'Date',
            cell: (row,) => {
                return (
                    <div>
                        {row.created_at ? dateFunction(row.created_at) : ""}
                    </div>
                );
            },
            sortable: true,
        },
        {
            name: 'Sub Category',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: true
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, subData, rowIndex, id) => (
                <div>
                    {/* <HiDotsVertical class='admin-nav-icon' data-bs-toggle="dropdown" aria-expanded="false" /> */}
                    <div class="dropdown">
                        <button type="button" class="btn text-light px-xxl-3 px-xl-3 px-lg-3 px-md-2 py-1 bootBtn" onClick={() => handleClickSubCategory(row)}>Details</button>
                        {/* <ul class="dropdown-menu mt-3">
                            <li><a class="dropdown-item" onClick={() => handleClickSubCategory(row)}>Details</a></li>
                            <li><a class="dropdown-item" onClick={() => goToAddTopic(row)}>Add topic</a></li>
                        </ul> */}
                    </div>
                </div>
            )
        },
    ]
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const handleDelete = () => {
        // Check if category has associated subcategories or topics
        if (categoryData?.subCategory?.length > 0 || categoryData?.topic?.length > 0) {
            // Set error message
            setErrorMessage('Cannot delete category with associated subcategories or topics.');
        } else {
            const payload = {
                id: categoryId,
                type: 1
            };
            // Dispatch deleteCategory action
            dispatch(deleteCategory(payload))
                .then(() => {
                    // If deletion is successful, call getCategoryListData to update the table
                    const payload = {
                        type: 1
                    };
                    dispatch(getCSTData(payload));
                    goToSubjectTeacher();
                    setShowModal(false); // Close the modal after successful deletion
                })
                .catch((error) => {
                    // Handle any errors, such as failed deletion
                    setErrorMessage('Error deleting category: ' + error.message);
                    // Since an error occurred, keep the modal open
                    setShowModal(true);
                });
        }
    };


    const [editedName, setEditedName] = useState('');
    const [load, setLoading] = useState(false);
    const handleSave = () => {
        // Prepare the data for the API request
        const requestData = {
            id: categoryId,
            name: editedName,
        };
    
        // Prepare headers with the authorization token
        const headers = { 'Authorization': 'Bearer ' +(localStorage.getItem('token')) }
        // Make an HTTP request to the API endpoint
        axios.post('http://tuition.techiesmarkets.com/backend/public/api/editCategory', requestData, { headers })
            .then(response => {
                // Handle success response
                console.log('Category updated successfully:', response.data);
                showSuccessToast('Category updated successfully');
                // You may want to update the UI or take any other action upon successful update
            })
            .catch(error => {
                // Handle error response
                console.error('Error updating category:', error);
                // You may want to display an error message to the user or take any other action
            });
    };

   
    const openModal = () => {
        setShowModal(true);
        setErrorMessage(''); // Reset error message when modal is opened
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <div class='main-divHead'>
                <div className='sub-Head1' onClick={(e) => goToSubjectTeacher(e)} >
                    <div className="Sub-Head2" ><span><AiOutlineLeft className='mb-1 me-2' /></span>Category Details</div>
                </div>
                <div class='mx-5'>
                    <div class="container text-start">
                        <div className='row mt-5 mb-5'>
                            <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center">
                                <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 px-lg-0 mt-3 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                    <button type="button" className="btn text-light px-xxl-3 px-xl-3 px-lg-2 px-md-3 px-3 py-1 bootBtn" onClick={(e) => goToAddSubCategory()}>Add Sub Category</button>
                                </div>
                                {/* <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12 ps-lg-0 mt-3 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                    <button type="button" className="btn text-light px-xxl-5 px-xl-5 px-lg-4 px-md-3 px-5 py-1 bootBtn" onClick={(e) => goToAddTopic()}>Add Topic</button>
                                </div> */}
                            </div>
                        </div>
                        <div class="row">
                            <div class='d-flex justify-content-center'>
                                <div class="col-xxl-8 col-xl-8 col-lg-11">
                                    <div class='card adminSubCard'>
                                        <div class="container text-center">
                                            <div class="row">
                                                <div class='d-flex justify-content-center'>
                                                    <div class="col-2">
                                                        <p className='admin-sub-head-card'>Category :</p>
                                                    </div>
                                                    <div class="col-4">
                                                        <p className='admin-sub-head-card'>{categoryData?.category?.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='container'>
                                                <div className='d-flex justify-content-end'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <button className="btn text-light px-xxl-3 px-xl-3 px-lg-2 px-md-3 px-3 py-1 bootBtn" data-bs-toggle="modal" data-bs-target="#modal">Edit</button>
                                                        </div>
                                                        <div className='col'>
                                                            <button className="btn text-light px-xxl-3 px-xl-3 px-lg-2 px-md-3 px-3 py-1 bootBtn" onClick={openModal} data-bs-toggle="modal" data-bs-target="#exampleModal">Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`modal fade ${showModal ? 'show' : ''}`} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content p-4">
                                    <div className="container text-center">
                                        <div class="row">
                                            <div className="col">
                                                {errorMessage && <div className="error-message" ><RiAlertFill style={{ fontSize: "90px", color: "red" }} className="error-icon" /></div>}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div className="col">
                                                {errorMessage && <div className="error-message" >{errorMessage}</div>}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                {!errorMessage && <h1 className="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want to delete Category?</h1>}
                                                {!errorMessage && (
                                                    <>
                                                        <button type="button" className="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}>Cancel</button>
                                                        <button type="button" className="btn btn-danger mt-3 ms-5 px-4" data-bs-dismiss="modal" aria-label="Close" onClick={handleDelete} >Delete</button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Edit */}
                        <div class={`modal fade ${showModal ? 'show' : ''}`} id="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header  d-flex justify-content-center">
                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Edit Category</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div className='row'>
                                            <div className='col'>
                                                <p>Cureent Category </p>
                                            </div>
                                            <div className='col'>
                                                <input type='text' placeholder={categoryname} className='rounded-pill py-1 px-2' />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <p>Enter the New Category </p>
                                            </div>
                                            <div className='col'>
                                                <input type='text' placeholder='' className='rounded-pill py-1 px-2 editedname'   value={editedName}
                                                 onChange={(e) => setEditedName(e.target.value)}/>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary editcategory" onClick={handleSave} >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col mb-5'>
                        <DataTable
                            className='dataTable mt-5'
                            data={categoryData?.subCategory || []}
                            columns={columns}
                            fixedHeader={true}
                            fixedHeaderScrollHeight="600px"
                            striped
                            customStyles={customStyle}
                            persistTableHead
                            pagination
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CategoryDetailsComponent;
