import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { activeSubjectID, changeSubject, subjectLoading, viewSubject, viewSubjectData } from '../../../../../../redux/slice/SubjectSlice'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react'
import { format } from 'date-fns'
import { RotatingLines } from 'react-loader-spinner'
import { AiOutlineLeft } from 'react-icons/ai';
import _ from 'lodash'

function SubjectDetailsComponent(props) {

    const dispatch = useDispatch();
    const activeSubId = useSelector(activeSubjectID);
    const viewSubData = useSelector(viewSubject);
    const loading = useSelector(subjectLoading);

    const date = viewSubData?.subject?.created_at != undefined ? format(new Date(viewSubData?.subject?.created_at), 'dd-MM-yyyy') : "";
    const initialValues = {
        status: 'Active',
    };

    const validationSchema = Yup.object({
        status: Yup.string().required('Status is required'),
    });

    const onSubmit = (values) => {
        const payload = {
            "subject_id": activeSubId,
            "status": values.status == 'Active' ? "1" : "0",
        }
        const viewSub = {
            "subject_id": activeSubId
        }
        const valueToSend = {
            payload,
            viewSub
        }
        dispatch(changeSubject(valueToSend));
    };

    useEffect(() => {
        const payload = {
            "subject_id": activeSubId
        }
        dispatch(viewSubjectData(payload));
    }, []);

    const goToSubjectTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'AdminSubject' })
        }
    }

    return (
        <div>
            <div class='main-divHead'>
                <div className='sub-Head1'>
                    <div className="Sub-Head2" onClick={(e) => goToSubjectTeacher(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>Subjects</div>
                </div>
                <div class='mx-xxl-5 mx-xl-5 mx-lg-5 mx-md-5 '>
                    <div class="container">
                        <div className='row'>
                            <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center"><div className='col-xxl-8 col-xl-8 col-lg-11'>
                                <p class='admin-sub-head my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3 '>Subject Details</p>
                            </div></div>
                        </div>
                        <div class="row">
                            <div class='d-flex justify-content-center'>
                                <div class="col-xxl-8 col-xl-8 col-lg-11">
                                    <div class='card adminSubCard py-2 px-2'>
                                        <div class="container text-start">
                                            <div class="row">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p className='admin-sub-head-card'>Published Date :</p>
                                                    </div>
                                                    <div class="col-3">
                                                        <p className='admin-sub-head-card'>{date}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p className='admin-sub-head-card'>Category :</p>
                                                    </div>
                                                    <div class="col-3">
                                                        <p className='admin-sub-head-card'>{viewSubData?.subject?.category}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p className='admin-sub-head-card'>Sub Category :</p>
                                                    </div>
                                                    <div class="col-3">
                                                        <p className='admin-sub-head-card'>{viewSubData?.subject?.sub_category}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p className='admin-sub-head-card'>Topic :</p>
                                                    </div>
                                                    <div class="col-3">
                                                        <p className='admin-sub-head-card'>{viewSubData?.subject?.subject}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p className='admin-sub-head-card '>Status :</p>
                                                    </div>
                                                    <div class="col-3">
                                                        {viewSubData?.subject?.status === "0" ? <span class='text-danger fw-bold fs-5'>Inactive</span> : <span class='text-success fw-bold fs-5'>Active</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            <Form>
                                <div class="container ">
                                    <div class="row my-4 ">
                                        <div class="container ">
                                            <div class="row ms-lg-5 ms-xxl-0 ms-xl-0 ps-lg-5 ps-xxl-0 ps-xl-0">
                                                <div class="col-10 ms-5">
                                                    <div class='d-flex justify-content-end '>
                                                        <div class="col-xxl-1 col-xl-1 col-lg-2 mt-1">
                                                            <p className='admin-sub-head-card mb-0 mt-1'>Status:</p></div>
                                                        <div class="col-xxl-3 col-xl-3 col-lg-5">
                                                            <div className="form-group pe-xxl-5 pe-xl-5 pe-lg-0">
                                                                <Field as="select" name="status" className="form-select" id="exampleDropdown">
                                                                    <option value="Active">Active</option>
                                                                    <option value="DeActive">Inactive</option>
                                                                </Field>
                                                                <ErrorMessage name="status" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container text-center">
                                    <div class="row">
                                        <div class="col">
                                            <button type="submit"
                                                // disabled={loading ? true : false}
                                                class="btn text-light px-5 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-4">
                                                {loading ? <div className='d-flex justify-content-center'><RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="25"
                                                    height="25"
                                                    visible={true}
                                                /></div> : "Update"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubjectDetailsComponent;
