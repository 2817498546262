import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentStudent, changeStudent, studentLoading, studentStatusLoading, viewStudent, viewStudentData } from '../../../../../../redux/slice/studentSlice';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RotatingLines } from 'react-loader-spinner';
import _ from 'lodash';
import { AiOutlineLeft } from 'react-icons/ai';

function AdminStudentDetailsComponent(props) {
    const dispatch = useDispatch();
    const activeStudent = useSelector(CurrentStudent);
    const data = useSelector(viewStudentData);
    const date = data.created_at != undefined ? format(new Date(data.created_at), 'dd-MM-yyyy') : '';
    const loading = useSelector(studentStatusLoading);

    const initialValues = {
        status: data.status === '1' ? 'Active' : 'DeActive',
    };

    const validationSchema = Yup.object({
        status: Yup.string().required('Status is required'),
    });


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            const payload = {
                "student_id": activeStudent,
                "status": values.status == 'Active' ? "1" : "0",
            }
            const viewStudent = {
                "student_id": activeStudent
            }
            const valueToSend = {
                payload,
                viewStudent
            }
            dispatch(changeStudent(valueToSend));
        },
    });

    useEffect(() => {
        const payload = {
            student_id: activeStudent,
        };
        dispatch(viewStudent(payload));
    }, []);

    const goToTeacherStudent = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminStudent' })
        }
    }

    const goToWallet = () => {
        if (_.get(props, 'setUserState')) {
            
            props.setUserState({ ...props.userState, subType: 'adminWallet' });
        }
    };

    return (
        <div>
            <div class='main-divHead'>
                <div className='sub-Head1'>
                    <div className="Sub-Head2" onClick={(e) => goToTeacherStudent(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>Subjects</div>
                </div>
                <div class='mx-5'>
                    <div class='container text-start'>
                        <div className='row'>
                            <div class="d-flex justify-content-center"><div className='col-xxl-8 col-xl-8 col-lg-11'>
                                <p class='admin-sub-head my-5 '>Students Details</p>
                            </div>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='d-flex justify-content-center'>
                                <div class='col-xxl-8 col-xl-8 col-lg-11'>
                                    <div class=' adminSubCard p-3'>
                                        <div class='container text-start'>
                                            <div class='row mb-1 ms-3'>
                                                <div class='d-flex justify-content-between'>
                                                    <div class='col'>
                                                        <p class='fw-semibold fs-5 mb-0 '>Date : </p>
                                                    </div>
                                                    <div class='col-5'>
                                                        <p class='fw-semibold fs-5 mb-0 '>{date}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row mb-1  ms-3'>
                                                <div class='d-flex justify-content-between'>
                                                    <div class='col'>
                                                        <p class='fw-semibold fs-5 mb-0 '>Student Name : </p>
                                                    </div>
                                                    <div class='col-5'>
                                                        <p class='fw-semibold fs-5 mb-0 '>{data?.firstname}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-1  ms-3 ">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='fw-semibold fs-5 mb-0 '>Email : </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <p class='fw-semibold fs-5 mb-0 '></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-1 ms-3">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='fw-semibold fs-5 mb-0 '>Phone : </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <p class='fw-semibold fs-5 mb-0 '></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-1 ms-3">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='fw-semibold fs-5 mb-0 '>Country : </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <span class=''> <p class='fw-semibold fs-5 mb-0 '></p></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-1 ms-3">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='fw-semibold fs-5 mb-0 '>Course Name : </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <span class=''> <p class='fw-semibold fs-5 mb-0 '></p></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-1 ms-3">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='fw-semibold fs-5 mb-0 '>Teacher Name : </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <span class=''> <p class='fw-semibold fs-5 mb-0 '></p></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-1 ms-3">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='fw-semibold fs-5 mb-0 '>Date of Enrolment : </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <span class=''> <p class='fw-semibold fs-5 mb-0 '></p></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-1 ms-3 ">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='fw-semibold fs-5 mb-0  '>
                                                            Status : </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <p class='fw-semibold fs-5 mb-0 '>{data?.status == "1" ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>DeActive</span>}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class='d-flex justify-content-end'>
                                                <div class="row ms-3">
                                                    <div class="col">
                                                        <button className='btn text-light px-5 py-1 bootBtn my-5' onClick={(e) => goToWallet()}>Go To Wallet</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='row my-4  ms-lg-5 ms-xxl-0 ms-xl-0 ps-lg-5 ps-xxl-0 ps-xl-0'>
                                <div class='d-flex justify-content-end '>
                                    <div class='col-xxl-1 col-xl-1 col-lg-2 mt-1 mt-1'>
                                        <p className='admin-sub-head-card mt-1'>Status :</p></div>
                                    <div class='col-xxl-3 col-xl-3 col-lg-5'>
                                        <div className='form-group '>
                                            <select
                                                className='form-select'
                                                id='exampleDropdown'
                                                name='status'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.status}
                                            >
                                                <option value='Active'>Active</option>
                                                <option value='DeActive'>Deactive</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class='col-2'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='container text-center'>
                        <div class='row'>
                            <div class='col'>
                                <button type='submit' disabled={loading} class='btn btn-primary text-light px-5 py-1 bootBtn my-5' onClick={formik.handleSubmit}>
                                    {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                                        strokeColor="white"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="25"
                                        height="25"
                                        visible={true}
                                    /></div> : "Submit"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminStudentDetailsComponent;
