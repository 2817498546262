import React, { useState } from 'react';
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import './TeacherAddQuest.scss';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function TeacherAddQuest(props) {
    const [selectedOption, setSelectedOption] = useState('Select an option');
    const [errors, setErrors] = useState([""]);
    const [showContent, setShowContent] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showContent0, setShowContent0] = useState(false);
    const [showContent1, setShowContent1] = useState(false);
    const [showContent2, setShowContent2] = useState(false);


    const [stateObject, setStateObject] = useState({
        email: " ",
        category: '',
        sub_category: '',
        topic: ' ',
        batch_id: '',
        marks_question: '',
        student_id: '',
        message: ''
    });
    const [questions, setQuestions] = useState([
        {
            question: "",
            a: "",
            b: "",
            c: "",
            d: ""
        }
    ]);

    const ToSubmit = () => {
        // const createSchedule = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        // formData.append("email", _.get(createSchedule, 'data.email'));
        formData.append("category", stateObject.category)
        formData.append("sub_category", stateObject.sub_category)
        formData.append("topic", stateObject.topic)
        formData.append("batch_id", stateObject.batch_id)
        formData.append("student_id[]", stateObject.student_id)
        formData.append("message",stateObject.message)
       

        // formData.append('formatDate', scheduleValue.formatDate)
        // for (let previewkey in scheduleValue.dates) {
        //     formData.append(`formatDate[${previewkey}][from]`, scheduleValue.dates[previewkey]['from']);
        //     formData.append(`formatDate[${previewkey}][to]`, scheduleValue.dates[previewkey]['to']);
        // }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/createSchedule',
            formData,
            { headers: headers })

            .then((res) => {

                if (res.data.success) {

                    console.log(res)
                    const createData = _.get(res, 'data.data.createData') ? JSON.stringify(res.data.data.createData) : undefined;
                    const token = _.get(res, 'data.data.token') ? JSON.stringify(res.data.data.token) : undefined;

                    if (createData) {
                        localStorage.setItem("createData", JSON.stringify(res.data.data.createData));
                    }

                    if (token) {
                        localStorage.setItem("token", (res.data.data.token));
                    }
                    Backdashboard()
                }
                console.log(res.data)

            });
    };


    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleButtonClick = () => {
        setShowContent0(true);
    };

    const handleButtonClick1 = () => {
        setShowContent1(true);
    };

    const handleButtonClick2 = () => {
        setShowContent2(true);
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setStateObject({ ...stateObject, [name]: checked });
        setErrors({ ...errors, [e.target.name]: " " });

        if (name === 'checkbox1') {
            setShowContent(checked);
        } else if (name === 'checkbox2') {
            setShowContent1(checked);
        }
    };

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherTest' })
        }
    }

    const goToTeacherNewQuest = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherAddNewQuest' })
        }
    }


    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        setShowContent(false);
    };

    const handleChange = (e) => {
        setStateObject({ ...stateObject, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: " " });
    }

    return (
        <div className='main-divHead'>
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Test</div>
            </div>
            <div className='Main-head-test'>Add Test</div>
            <p className='sub-head-test'>Question</p>
            <div className='dropdown1'>
                <button className='main-btn' onClick={toggleDropdown}>Question 1</button>

                {showDropdown && (
                    <div className='dropdown-content'>
                        {showContent0 ? (
                            <div>
                                <p className='para1-test'>Question</p>
                                <div className='para1-test1'>
                                    <input type='text' className='inp-quest' placeholder='Question'  onChange={handleChange} />
                                </div>
                            </div>
                        ) : (
                            <button className='sub-btn' onClick={() => handleButtonClick('Button 1')}>+ Question</button>
                        )}

                        {showContent1 ? (
                            <div>
                                <p className='para1-test'>Resource</p>
                                <div>
                                    <input type='file' className='test-file-inp' />
                                </div>
                            </div>
                        ) : (
                            <button className='sub-btn' onClick={() => handleButtonClick1('Button 2')}>+ Resource</button>
                        )}

                        {showContent2 ? (
                            <div>
                                <p className='para1-test'>Options</p>
                                <div className="option-inp-test">
                                    <p className='options-test'>A</p> &nbsp;&nbsp;
                                    <label className="checkbox-label">
                                        <input
                                            type="checkbox"
                                            name="checkbox1"
                                            onChange={handleCheckboxChange}
                                        />

                                    </label>
                                    <input
                                        type="text"
                                        className="text-input"
                                        placeholder="Type here"
                                    />
                                </div>
                                <br />
                                <div className="option-inp-test">
                                    <p className='options-test'>B</p> &nbsp;&nbsp;
                                    <label className="checkbox-label">
                                        <input
                                            type="checkbox"
                                            name="checkbox1"
                                            onChange={handleCheckboxChange}
                                        />

                                    </label>
                                    <input
                                        type="text"
                                        className="text-input"
                                        placeholder="Type here"
                                    />
                                </div>
                                <br />

                                <div className="option-inp-test">
                                    <p className='options-test'>C</p> &nbsp;&nbsp;
                                    <label className="checkbox-label">
                                        <input
                                            type="checkbox"
                                            name="checkbox1"
                                            onChange={handleCheckboxChange}
                                        />

                                    </label>
                                    <input
                                        type="text"
                                        className="text-input"
                                        placeholder="Type here"
                                    />
                                </div>
                                <br />

                                <div className="option-inp-test">
                                    <p className='options-test'>D</p> &nbsp;&nbsp;
                                    <label className="checkbox-label">
                                        <input
                                            type="checkbox"
                                            name="checkbox1"
                                            onChange={handleCheckboxChange}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        className="text-input"
                                        placeholder="Type here"
                                    />
                                </div>
                                <button className='Quest-test-btn' onClick={goToTeacherNewQuest}>New Question</button>
                                <button className='cancel-test-btn'>Cancel</button>
                                <button className='submit-test-btn'>save</button>


                            </div>
                        ) : (
                            <button className='sub-btn' onClick={() => handleButtonClick2('Button 2')}>+ Options</button>
                        )}

                    </div>
                )}

            </div>

        </div>
    );

} export default TeacherAddQuest;