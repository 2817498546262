import React, { useState } from "react"
import dashboardWhiteIcon from "../../icons/dashboardWhiteIcon.png"
import dashboardBlueIcon from "../../icons/dashboardBlueIcon.png"
const FreelanceSidebarMain = () => {
  const [hoverEffect, setHoverEffect] = useState(false)

  return (
    <div
      className='side'
      
    >
  {/* <img
        // src={hoverEffect ? dashboardBlueIcon : dashboardWhiteIcon}
        // alt='Dashboard'
      /> 
* <p className='side_heading'>FreelanceDashboard</p>  */}
    </div>
  )
}

export default FreelanceSidebarMain
