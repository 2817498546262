import _ from 'lodash'
import React, { useState } from "react"
import { FaBookBookmark } from "react-icons/fa6"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { enrolledCourses } from "../../../../../../../redux/displayer"

function EnrolledCourses({ ...props }) {
  const [hoverEffect, setHoverEffect] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const clickEnrolled = () => {
    navigate("/SubComponentMainSection");
  }
  const goToEnrolled = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'EnrolledCourses' })
    }
  }
  const StuBackdashboard = () => {
    navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
}
  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'EnrolledCourses' ? 'active' : ''} `} onClick={(e) => goToEnrolled(e)}>
      <div className="dash d-flex" >
      <FaBookBookmark className="ms-3" />
      <p className='head-dashboard ms-2 '  onClick={() => StuBackdashboard()}>My Courses</p>
      </div>
    </div>
  )
}

export default EnrolledCourses
