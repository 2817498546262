import React, { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom"
import * as Yup from 'yup';
import img from '../../../../../../images/Ellipse 2.jpg';
import { CiCircleChevDown } from 'react-icons/ci';
import _ from 'lodash';
import { CgProfile } from 'react-icons/cg';
import { LuLogOut } from 'react-icons/lu';
import { CiSettings } from "react-icons/ci";
import "./Navbar.scss";
import { FaBell } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { CSTData, SubData, SubjectData, fetchCourse, fetchSubjectList, getCSTData, topicData } from '../../../../../../redux/slice/SubjectSlice';
import { Formik, Field, Form, ErrorMessage, } from 'formik';
import { ProfileData, getUserProfile, } from '../../../../../../redux/slice/studentSlice';
import { userLogout } from '../../../../../../redux/slice/AuthSlice';
import Axios from '../../../../../../util/apiUtil';


function StudentNavBar({ ...props }) {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [viewprofile, setViewProfile] = useState(false);
  const [teaProfile, setteaProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [initialValues, setInitialValues] = useState({
    education: "",
    other_relevant_courses: "",
    teaching_background: ""
  });

  const dispatch = useDispatch();
  const data = useSelector(CSTData);
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.auth.Token);
  const courselist = useSelector(SubjectData);


  // const register = useSelector(RegisterAsTeacher)
  const handleLogout = () => {
    dispatch(userLogout({ authToken, navigate }));
  };
  const ProfileDetails = useSelector(ProfileData)

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log("Form values:", values); // Log form values for debugging

      const payload = {
        "education": values.education,
        "other_relevant_courses": values.other_relevant_courses,
        "teaching_background": values.teaching_background,
        "professional": props.context === "Student" ? 2 : 1,
        navigate: navigate
      };

      console.log("Payload:", payload); // Log payload for debugging

      // Dispatch the RegisterAsTeacher action
      // await dispatch(RegisterAsTeacher(payload));

      // Reset the form if necessary
      resetForm();
      setInitialValues(values);
    } catch (error) {
      // Handle any errors, if needed
      console.error("Error during registration:", error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const payload = {
      type: 1
    };
    dispatch(getCSTData(payload));
  }, []);

  const categoryOptions = data?.map((e) => ({
    value: e.id,
    label: e.name
  }));

  const validationSchema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
    sub_category: Yup.string().required('Sub Category is required'),
    topic: Yup.string().required('Topic is required'),
    education: Yup.string().required('education is required'),
    other_relevant_courses: Yup.string().required(' other_relevant_courses is required'),
    teaching_background: Yup.string().required('teaching_background is required')
  });

  const basePath = "http://tuitionguruji.com/backend/public/";

  const goToNotification = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Notification' })
    }
  }


  const goToStudentProfile = () => {
    setteaProfile(true);
    setViewProfile(true);
    handleClose();
    navigate('/StudentProfile');
  };


  const gotosearch = (selectedValue, e) => {
    const payload = {
      id: selectedValue,
      type: 1
    };
    dispatch(fetchSubjectList(payload));
    navigate(`/ShowSubjects`);
  }


  const gotosettings = () => {
    navigate('/Settings');
    handleClose();
  }
  const [showFirstModal, setShowFirstModal] = useState(false);
  const handleNext = () => setShowFirstModal(true);

  useEffect(() => {
    dispatch(getUserProfile())
    dispatch(fetchSubjectList())
  }, []);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const gotosearchnew = async () => {
    setLoading(true); // Start loading
    try {
      const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
      const response = await Axios.post('/api/globalSearch', { search: searchQuery }, { headers });

      if (response.status !== 200) {
        throw new Error('Search failed');
      }

      const result = response.data;
      setSearchResults(result.data.subject); // Update search results with the nested data
      setLoading(false); // Stop loading
      // navigate(`/ShowSubjects`)
      // Example: Navigate to a search results page
      // navigate('/ShowSubjects', { state: { results: result } });
    } catch (error) {
      console.error('Error during search:', error);
      setLoading(false); // Stop loading in case of error
    }
  };
  return (

    <div class='py-2 admin-navbar '>
      <div class=" ">
        <div class="row">
          <div class='d-flex justify-content-between'>
            <div className="col-5">
              <div className='d-flex mb-0'>
                <Formik initialValues={{
                  category: '',
                }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    setSelectedCategory(values.category);
                    gotosearch();
                  }}>
                  {/* {({ errors, touched, setFieldValue }) => (
                    <Form >
                      <Field as="select"
                        name="category"
                        className="form-select rounded-pill main-search-category"
                        placeholder="Select Category"
                        onChange={(e) => {
                          console.log("category id", e.target.value);
                          const selectedValue = e.target.value;
                          const payload = {
                            "type": 2,
                            "category_id": selectedValue,
                          };
                          setFieldValue("category", e.target.value)
                        }}>
                        <option value="">Select Category</option>
                        {categoryOptions?.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </Form>
                  )} */}
                </Formik>
                <div className='col'>
                  <button type="button" className="btn admin-navbar-search-btn text-light  " onClick={(selectedValue) => gotosearch(selectedValue)}>Find your Course</button>
                </div>
                {/* <div className="col-5 me-5">
                  <div className='d-flex mb-0'>
                    <input
                      type="text"
                      className="form-control rounded-pill"
                      placeholder="search ... "
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button
                      type="button"
                      className="btn admin-navbar-search-btn text-light px-3 ms-3"
                      onClick={gotosearchnew}
                      
                    >
                      {loading ? 'Searching...' : 'Search'}
                    </button>
                  </div>
                  {searchResults.length > 0 && (
                    <ul className="list-group position-absolute w-100 mt-2" style={{ zIndex: 1000 }}>
                      {searchResults.map((result) => (
                        <li key={result.id} className="list-group-item">
                          {result.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div> */}
                </div>
                </div>
             
              <div className='col-2 col-md-3 col-lg-3 col-xl-2 d-flex justify-content-end ms-5'>
                <FaBell style={{ color: "#5C5AB6", width: "15px" }} onClick={(e) => goToNotification(e)} />
              </div>
              <div class="col-2 col-md-3 col-lg-3 col-xl-2">
                <div class="container text-start">
                  <div class="row">
                    <div class="col-4 px-0">
                      <img src={ProfileDetails?.profile_pic ? basePath + ProfileDetails?.profile_pic : ""} class="img-fluid rounded-circle " alt="" style={{ maxWidth: '50px', maxHeight: '50px' }} />
                    </div>
                    <div class="col-6 px-0">
                      <p class='mb-0 fw-bold text-uppercase'>{ProfileDetails?.firstname}</p>
                      <p class='mb-0 fs-6'>Student</p>
                    </div>
                    <div class="col px-0 align-self-center">
                      <CiCircleChevDown class='admin-nav-icon' data-bs-toggle="dropdown" aria-expanded="false" />
                      <div class="dropdown ">
                        <ul class="dropdown-menu mt-3">
                          <li><a class="dropdown-item" onClick={(e) => goToStudentProfile()} > <span><CgProfile className='me-2 mb-1' /></span>Profile</a></li>
                          <li><a class="dropdown-item" onClick={gotosettings}  > <span><CiSettings className='me-2 mb-1' /></span>settings</a></li>
                          <li><a class="dropdown-item" onClick={() => handleLogout()}> <span><LuLogOut className='me-2 mb-1' /></span>Logout</a></li>
                          <li> <hr className="diver-options ms-2 me-2" /></li>
                          <li> <p className="want-to-search text-center">
                            Want to Teach ?
                          </p>
                          </li>
                          <li className='d-flex justify-content-center'>
                            <button className='btn teach text-center pe-3 ps-3'
                              // data-bs-target="#exampleModalToggle" data-bs-toggle="modal"
                              onClick={() => navigate('/teacher/profile')}
                            >
                              Teach
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
}

      export default StudentNavBar;
