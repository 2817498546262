
import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import './CreateTeacherAssign.scss'
import { FileUpload } from '@mui/icons-material';
import { Formik, Field, Form, ErrorMessage, useFormik } from 'formik';
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from "../../../../../../util/toastUtils"
import { FaPlus } from "react-icons/fa"
import { InputGroup } from 'react-bootstrap';
import Select from "react-select";
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';



function CreateTeacherAssign(props) {
    const [edit, setEdit] = useState()

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherassignment' })
        }
    }

    const navigate = useNavigate()
    const [user, setUser] = useState([])
    const [errors, setErrors] = useState([""]);
    const [fileUpload, setFileUpload] = useState(null);

    const [subCategories, setSubCategories] = useState([{}])
    const [categories, setCategories] = useState([{}])
    const [loading, setLoading] = useState(true)
    const [batches, setBatches] = useState([])
    const [studentList, setStudentList] = useState([])
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [totalList, setTotalList] = useState([]);
    const [subject, setSubject] = useState([{}])
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [topicOptions, setTopicOptions] = useState([]);


    const handleInputImage = (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            // Handle file input changes and create File object
            const selectedFile = files[0];
            console.log('Selected File:', selectedFile);
            setCreate({
                ...create,
                [name]: selectedFile, // Set the File object
            });
        } else {
            // Handle other input changes
            setCreate({
                ...create,
                [name]: null, // Clear the File object if no file is selected
            });
        }
        setErrors({ ...errors, [name]: " " });
    };


    const listSubject = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }

        // const createSchedule = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/listSubject', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubjectObject = response.data.data;
                    const SubjectArray = Object.values(SubjectObject);
                    setSubject(SubjectArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
                setLoading(false);
            });
    };

 


    const listBatches = () => {
        // Make sure your token is properly retrieved and stored
        const token = localStorage.getItem('token');
        if (!token) {
            // Handle token not available
            return;
        }

        const headers = { Authorization: 'Bearer ' + token };

        Axios.post('/api/listBatch', null, { headers })
            .then((response) => {
                if (response.data.success) {
                    const batchData = response.data.data.batch;
                    setBatches(batchData);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching batches:', error);
                setLoading(false);
            });
    };

    const listStudents = (batchId) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const formData = new FormData();
        formData.append('id', batchId || 0);
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        axios.post('/api/listBatchStudents', formData, { headers: headers })
            .then((response) => {
                if (response.data.data) {
                    const studentListData = response.data.data;
                    // Convert the object of student names and IDs into an array of objects
                    const studentListArray = Object.keys(studentListData).map(studentName => ({
                        value: studentListData[studentName],
                        label: studentName,
                    }));
                    setStudentList(studentListArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching students:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        listBatches()
        listStudents()
        listSubject()
    }, [])

    const listSubCategories = (id) => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
        }
        const formData = new FormData()
        formData.append('type', 2)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/getCategoryandTopicDetails', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubcategoriesObject = response.data.data;
                    const SubcategoriesArray = Object.values(SubcategoriesObject)
                    setSubCategories(SubcategoriesArray)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error)
                setLoading(false)
            })
    }

    const [fileInputs, setFileInputs] = useState([0]);
    const [fields, setFields] = useState(['']);

    const handleFileChange = (index, event) => {
        const updatedFields = [...fields];
        updatedFields[index] = event.currentTarget.files[0];
        setFields(updatedFields);
    };
    const handlePlusButtonClick = () => {
        setFileInputs((prevInputs) => [...prevInputs, prevInputs.length]);
    }

    const AssignmentValidationSchema = Yup.object().shape({
        category: Yup.string().required('category is required'),
        sub_category: Yup.string().required('sub Category is required'),
        topic: Yup.string().required('topic is required'),
        batch_id: Yup.string().required('batch is required'),
        description: Yup.string().required(' description is required'),
        id: Yup.string().required('id  is required'),
        student_id: Yup.string().required('student is required'),
        starting_at: Yup.string().required('start date is required'),
        ending_at: Yup.string().required('end date is required'),
        ending_at: Yup.string().required('end date is required'),
        doc: Yup.string().required('doc is required'),

    });



    const [create, setCreate] = useState({

        email: " ",
        category: " ",
        sub_category: '',
        starting_at: '',
        ending_at: '',
        student_id: '',
        description: " ",
        doc: '',
        topic: '',
        subject_id: 2,
        batch_id: '',

    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCreate({ ...create, [name]: value });
        setErrors({ ...errors, [name]: " " });
        if (name === "batch_id") {
            // Update the student list based on the selected batch
            listStudents(value);
        }
    };

    const ToSubmit = () => {
        const createAssignment = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("email", _.get(createAssignment, 'email'));
        formData.append("category", selectedSubject.category);
        formData.append("sub_category", selectedSubject.sub_category);
        formData.append("topic", selectedSubject.subject);
        formData.append("starting_at", create.starting_at)
        formData.append("ending_at", create.ending_at)
        formData.append("batch_id", create.batch_id)
        // formData.append("student_id[]", create.student_id)

        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("student_id[]", record.value || []);
        }

        formData.append("description", create.description)
        formData.append("doc", create.doc,)
        formData.append('subject_id', create.subject_id)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/createAssignment',
            formData,
            { headers: headers })
            .then((res) => {
                if (res.data.success) {
                    console.log(res)
                    const createData = _.get(res, 'data.data.createData') ? JSON.stringify(res.data.data.createData) : undefined;
                    const token = _.get(res, 'data.data.token') ? JSON.stringify(res.data.data.token) : undefined;
                    if (createData) {
                        localStorage.setItem("createData", JSON.stringify(res.data.data.createData));
                    }
                    if (token) {
                        localStorage.setItem("token", (res.data.data.token));
                    }
                    showSuccessToast("Assignment created successfully");
                    Backdashboard()
                }
            })
    }

    const ToEdit = () => {
        const createAssignment = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("email", _.get(createAssignment, 'email'));
        formData.append("category", selectedSubject.category);
        formData.append("sub_category", selectedSubject.sub_category);
        formData.append("topic", selectedSubject.subject);
        formData.append("starting_at", create.starting_at)
        formData.append("ending_at", create.ending_at)
        formData.append("batch_id", create.batch_id)
        // formData.append("student_id[]", create.student_id)

        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("student_id[]", record.value || []);
        }
        formData.append("description", create.description)
        formData.append("doc", create.doc,)
        formData.append('subject_id', create.subject_id)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/editAssignment',
            formData,
            { headers: headers })
            .then((res) => {
                console.log(res)
                const updateData = res.data
                setEdit(updateData)
                Backdashboard()
                showSuccessToast("Edit assignment successfully");
            });
    }
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])
    const initializeObject = () => {
        setCreate({ ...create, ...props.userState.row })
    }



    const dispatch = useDispatch();
    const data = useSelector(CSTData);
    const subCatData = useSelector(SubData);
    const TopicData = useSelector(topicData);

    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
    }, []);

    // const categoryOptions = data?.map((e) => ({
    //     value: e.id,
    //     label: e.name
    // }));

    const SubCategoryOptionsList = subCatData?.map((e) => ({
        value: e.id,
        label: e.name
    }));

    const TopicOptionsList = TopicData?.map((e) => ({
        value: e.id,
        label: e.name
    }));


    const handleMultipleChange = (selectList) => {
        console.log(selectList);
        setTotalList(selectList || []);
    };

    const handleSubjectChange = (e) => {
        const subjectId = parseInt(e.target.value);
        const selectedSubject = subject.find(sub => sub.id === subjectId);
        setSelectedSubject(selectedSubject);

        // Populate category options
        if (selectedSubject) {
            const categories = [{ value: selectedSubject.category, label: selectedSubject.category_name }];
            setCategoryOptions(categories);

            // Populate subcategory options
            const subCategories = [{ value: selectedSubject.sub_category, label: selectedSubject.sub_category_name }];
            setSubCategoryOptions(subCategories);

            // Populate topic options
            const topics = [{ value: selectedSubject.subject, label: selectedSubject.topic_name }];
            setTopicOptions(topics);
        }
    };


    const newArray = studentList.filter(mainItem => !totalList.some(selectItem => selectItem.value === mainItem.value && selectItem.label === mainItem.label));

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Assignments</div>
            </div>
            <div className='container mt-3'>
                <div class="row">
                    {!_.get(props, 'userState.row') && <div className='main-schedule-head  ms-xxl-5 ms-xl-5 ms-lg-3 ms-md-0' ><p className='Title-main-head'>Create Assignment</p></div>}
                    {_.get(props, 'userState.row') && <div className='main-schedule-head ms-5'><p className='Title-main-head'>Edit Assignment</p></div>}
                </div>
                <Formik
                    initialValues={{
                        category: '',
                        sub_category: '',
                        topic: '',
                        batch_id: '',
                        student_id: '',
                        course_link: '',
                        message: '',
                        from: '',
                    }}
                    validationSchema={AssignmentValidationSchema}
                    onSubmit={(values, { resetForm }) => {
                        const payload = {
                            "category": values.category,
                            "sub_category": values.sub_category,
                            "topic": values.topic,
                            "details": values.details,
                            "closing_date": values.closing_date,
                            "currency": values.currency,
                            "price": values.price,
                            "term": values.term,
                            "file": values.file,
                        }
                        ToSubmit();
                        resetForm();
                    }}>
                    {({ errors, touched, setFieldValue }) => (
                        <Form >
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4  subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Course</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={create?.subject}
                                                as="select"
                                                name="subject"
                                                onChange={handleSubjectChange} >

                                                <option value="" >Select an option</option>
                                                {subject.map((subject, index) => (
                                                    <option key={index} value={subject.id}>
                                                        {subject.name}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}

                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Course</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={create?.subject}
                                                as="select"
                                                name="subject"
                                                onChange={handleSubjectChange} >

                                                <option value="" >Select an option</option>
                                                {subject.map((subject, index) => (
                                                    <option key={index} value={subject.id}>
                                                        {subject.name}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={create.category}
                                                name="category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 2,
                                                        "category_id": selectedValue,
                                                    };
                                                    dispatch(getSubCategoryData(payload));
                                                    setFieldValue("category", e.target.value)
                                                    handleChange(e);
                                                }}  >

                                                {categoryOptions?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2  mb-0 teacher-font-label">Choose Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={create.category}
                                                name="category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 2,
                                                        "category_id": selectedValue,
                                                    };
                                                    dispatch(getSubCategoryData(payload));
                                                    setFieldValue("category", e.target.value)
                                                    handleChange(e);
                                                }} >
                                                <option value="" >Select an option</option>
                                                {categoryOptions?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Sub Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={create.sub_category}
                                                name="sub_category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 3,
                                                        "sub_category_id": selectedValue,
                                                    };
                                                    dispatch(getSubTopicData(payload));
                                                    setFieldValue("sub_category", e.target.value)
                                                    handleChange(e);
                                                }}>

                                                {subCategoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="sub_category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label mb-0 teacher-font-label">Choose Sub Category</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={create.sub_category}
                                                name="sub_category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 3,
                                                        "sub_category_id": selectedValue,
                                                    };
                                                    dispatch(getSubTopicData(payload));
                                                    setFieldValue("sub_category", e.target.value)
                                                    handleChange(e);
                                                }}>

                                                {subCategoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="sub_category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>

                            </div>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Topic</label>
                                            <Field
                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={create.topic}
                                                name="topic"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("topic", e.target.value)
                                                    handleChange(e)
                                                }}  >

                                                {topicOptions.map((option) => (
                                                    <option key={option.vale} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="topic"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0  mb-0 teacher-font-field">Choose Topic</label>
                                            <Field
                                                className="form-control ms-5form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label"
                                                value={create.topic}
                                                name="topic"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("topic", e.target.value)
                                                    handleChange(e)
                                                }} >

                                                {topicOptions.map((option) => (
                                                    <option key={option.vale} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="topic"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>


                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 ms-md-0  mb-0 teacher-font-label">Choose Batch</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={create.batch_id}
                                                name="batch_id"
                                                as="select"
                                                required=""
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const selectedBatchId = e.target.value;
                                                    listStudents(selectedBatchId);
                                                    setFieldValue("batch_id", e.target.value)
                                                }}  >
                                                <option value="" >Select an option</option>
                                                {batches.map((batch, index) => (
                                                    <option key={index} value={batch.id}>
                                                        {batch.name}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="batch_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-field">Choose Batch</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label"
                                                value={create.batch_id}
                                                name="batch_id"
                                                required=""
                                                as="select"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const selectedBatchId = e.target.value;
                                                    listStudents(selectedBatchId);
                                                    setFieldValue("batch_id", e.target.value)
                                                }} >
                                                <option value="" >Select an option</option>
                                                {batches.map((batch, index) => (
                                                    <option key={index} value={batch.id}>
                                                        {batch.name}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="batch_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Student</label>
                                            <InputGroup>
                                                <Select
                                                    value={totalList}
                                                    onChange={handleMultipleChange}
                                                    options={studentList}
                                                    className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                    isMulti
                                                />
                                            </InputGroup>
                                            <ErrorMessage
                                                name="student_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Student</label>
                                            <InputGroup>
                                                <Select
                                                    value={totalList}
                                                    onChange={handleMultipleChange}
                                                    options={studentList}
                                                    className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                    isMulti
                                                />
                                            </InputGroup>
                                            <ErrorMessage
                                                name="student_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'> <>
                                    {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >Start Date</label>}<br />
                                    {!_.get(props, 'userState.row') && <Field type='date' class="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field" value={create.starting_at} name='starting_at' onChange={(e) => {
                                        setFieldValue("starting_at", e.target.value)
                                        handleChange(e)
                                    }} required="" />}
                                    {!_.get(props, 'userState.row') && <ErrorMessage
                                        name="starting_at"
                                        component="div"
                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                    />}
                                </>
                                    {_.get(props, 'userState.row') && <label className="form-label mt-0 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >Start Date</label>}<br />
                                    {_.get(props, 'userState.row') && <Field type='date' className='form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field' value={create.starting_at} name='starting_at' onChange={(e) => {
                                        setFieldValue("starting_at", e.target.value)
                                        handleChange(e)
                                    }} />}
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'> <>
                                    {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >End date</label>}<br />
                                    {!_.get(props, 'userState.row') && <Field type='date' class="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field" value={create.ending_at} name='ending_at' onChange={(e) => {
                                        setFieldValue("ending_at", e.target.value)
                                        handleChange(e)
                                    }} required="" />}
                                    {!_.get(props, 'userState.row') && <ErrorMessage
                                        name="ending_at"
                                        component="div"
                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                    />}
                                </>
                                    {_.get(props, 'userState.row') && <label className="form-label mt-0 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >End date</label>}<br />
                                    {_.get(props, 'userState.row') && <Field type='date' className='form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field' value={create.ending_at} name='ending_at' onChange={(e) => {
                                        setFieldValue("ending_at", e.target.value)
                                        handleChange(e)
                                    }} />}
                                </div>
                            </div>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 subtitle-headings '> <>
                                    {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >Message For student</label>}<br />
                                    {!_.get(props, 'userState.row') && <Field as="textarea"
                                        class="form-control mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                        placeholder="Leave a comment here"
                                        value={create.description}
                                        name='description' onChange={(e) => {
                                            setFieldValue("description", e.target.value)
                                            handleChange(e)
                                        }}
                                        maxLength={150}
                                        style={{ height: '100px', cursor: 'auto' }} />}
                                    <div className="d-flex justify-content-between">
                                        {!_.get(props, 'userState.row') &&
                                            <div><ErrorMessage
                                                name="description"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                            </div>}
                                        <div><p>{create.description.length}/ {150 - create.description.length}</p></div>
                                    </div>
                                </>
                                    {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >message For student</label>}<br />
                                    {_.get(props, 'userState.row') && <Field as="textarea"
                                        className='form-control mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field' id="floatingTextarea" value={create.description} name='description' onChange={(e) => {
                                            setFieldValue("description", e.target.value)
                                            handleChange(e)
                                        }} maxLength={150}
                                        style={{ height: '100px', cursor: 'auto' }} />}
                                    {_.get(props, 'userState.row') && <ErrorMessage
                                        name="description"
                                        component="div"
                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                    />}
                                </div>
                            </div>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                {fields.map((field, index) => (
                                    <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                        {!_.get(props, 'userState.row') && (
                                            <>
                                                <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 ms-md-0 mb-0 teacher-font-label">
                                                    Upload Course file
                                                </label>
                                                <br />
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        type='file'
                                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                        name="doc"
                                                        onChange={(event) => {
                                                            handleInputImage(event);
                                                        }}
                                                        required=""
                                                    />
                                                    {create.doc && <p>{create.doc.name}</p>}
                                                </div>
                                            </>
                                        )}
                                        {_.get(props, 'userState.row') && (
                                            <>
                                                <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-field">
                                                    Upload Course file
                                                </label>
                                                <br />
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        type='file'
                                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label"
                                                        name="assesment_doc"
                                                        onChange={(event) => {
                                                            setFieldValue("assesment_doc", event.currentTarget.files[0]);
                                                            handleInputImage(event)
                                                        }}
                                                        required=""
                                                    />
                                                    {fileUpload && <p>{fileUpload.name}</p>}
                                                    {/* Plus Button */}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className='row px-5'>
                                <div className='col-12 d-flex justify-content-end'>
                                    {!_.get(props, 'userState.row') && <button type='submit' className='btn text-light px-4 py-1 bootBtn my-5' onClick={(e) => ToSubmit()} >Submit</button>}
                                    {_.get(props, 'userState.row') && <button type='submit' className='btn text-light px-4 py-1 bootBtn my-5' onClick={(e) => ToEdit()} >update</button>}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}


export default CreateTeacherAssign