import React, { useEffect, useState } from 'react';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconImg from '../../../../../../images/Frame 67810.png'
import _ from 'lodash';
import img from '../../../../../../images/Howard (1).jpg';
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsFillSendFill } from "react-icons/bs";
import { FaRegSmileWink } from "react-icons/fa";
import { AiOutlinePaperClip } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { chatListData, listMyChat } from '../../../../../../redux/slice/chatSlice';
import Chat from '../adminPage/chat';
import { LoginUserData } from '../../../../../../redux/slice/AuthSlice';

function TeacherChat(props) {

    let userDatFromLocalStarge = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : [];

    let userData = useSelector(LoginUserData);
    if (_.isEmpty(userData)) {
      userData = userDatFromLocalStarge
    }
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherChats' })
        }
    }


    return (
        <div className='main-divHead'>
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Chats</div>
            </div>
            <Chat />
        </div>
    );
}

export default TeacherChat;
