import React, { useState } from "react"
import _ from "lodash"
import { CgNotes } from "react-icons/cg";
function Quotation({ ...props }) {
  const goToQuotation = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Quotation' })
    }
  }
  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'Quotation' ? 'active' : ''} `} onClick={(e) => goToQuotation(e)}>
      <div className="dash d-flex">
        <CgNotes className="ms-3" />
        <p className='head-dashboard ms-2 mb-0' >Quotation</p>
      </div>
    </div>
  )
}

export default Quotation
