import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
// import img from '../../../../../../images/Rectangle 120 (1).jpg'
import _ from 'lodash';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteUploadCourse, UploadCourseList, ViewUploadCourse, uploadList } from '../../../../../../redux/slice/UploadSlice';


function CourseUploaderComponent(props) {

    const dispatch = useDispatch();
    const data = useSelector(uploadList);

    useEffect(() => {
        dispatch(UploadCourseList());
    }, [])

    const goToViewCourseUploadPage = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'UploaderCourseUpload' });
        }
    };

    const goToViewCourse = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'UploaderViewCourse' });
        }
    };

    return (
        <div>
            <div className='main-divHead' >
                <div className='sub-Head1' >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Course Upload</div>
                </div>
                <div>
                    <div className="container text-start my-5">
                        <div>
                            <div className='row mt-5'>
                                <div className='col-3'>
                                    <button type="button" className="btn btn-primary px-4 py-1 bootBtn" onClick={() => goToViewCourseUploadPage()} >Course Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container text-start">
                        <div class="row">
                            {data.map((data) => (
                                <div class="col-3">
                                    <div className='admin-card m-4 p-4'>
                                        <div className="container ">
                                            <div className="row">
                                                <div className="col-11 p-0">
                                                    {/* <img src={img} class="img-fluid rounded-top" alt="..." /> */}
                                                </div>
                                                <div className="col-1 p-0">
                                                    <div class="dropdown dropend">
                                                        <BsThreeDotsVertical data-bs-toggle="dropdown" aria-expanded="false" />
                                                        <ul class="dropdown-menu ">
                                                            <li><a class="dropdown-item" onClick={() => {
                                                                return (
                                                                    dispatch(ViewUploadCourse({ "id": data.id })),
                                                                    goToViewCourse()
                                                                )
                                                            }
                                                            } >View</a></li>
                                                            <li><a class="dropdown-item" >Edit</a></li>
                                                            <li><a class="dropdown-item" onClick={() => dispatch(DeleteUploadCourse({ "id": data.id }))}>Delete</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='fs-5 fw-bold mt-2'>{data.topic}</p>
                                        <div class="container text-start">
                                            <div class="row">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p className='fw-semibold mb-0'>Price</p>
                                                    </div>
                                                    <div class="col-3">
                                                        ₹ {data.price}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p className='fw-semibold mb-0' >Category</p>
                                                    </div>
                                                    <div class="col-3">
                                                        {data.category}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p className='fw-semibold mb-0'>Sub Category</p>
                                                    </div>
                                                    <div class="col-3">
                                                        {data.sub_category}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p className='fw-semibold '>Discount</p>
                                                    </div>
                                                    <div class="col-3">
                                                        {data.discount_percentage}%
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class='col text-center'>
                                                    <button type="button" className="btn btn-primary px-4 py-1 bootBtn" onClick={() => {
                                                        return (
                                                            dispatch(ViewUploadCourse({ "id": data.id })),
                                                            goToViewCourse()
                                                        )
                                                    }
                                                    }>Open</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseUploaderComponent