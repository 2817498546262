import React, { useEffect, useState } from 'react';
import iconImg from '../../../../../../images/Frame 67810.png'
import img from '../../../../../../images/Howard (1).jpg';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlinePaperClip } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { chatListData, chatMsgData, listMessage, listMyChat, sendMessage, updateDeliveryStatus } from '../../../../../../redux/slice/chatSlice';
import { LoginUserData, UserChatData } from '../../../../../../redux/slice/AuthSlice';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Echo from "laravel-echo"
import { FaRegArrowAltCircleDown } from 'react-icons/fa';
import { TiTick } from "react-icons/ti";
import { VscCheckAll } from "react-icons/vsc";
import defaultProfilePic from '../../../../../../images/pro.jpg'
function Chat() {

    const convertToLocaleTime = (utcTimeString) => {
        const utcDate = new Date(utcTimeString);
        return utcDate.toLocaleString('en-US', {
            timeZone: 'Asia/Kolkata',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    const basePath = "http://tuitionguruji.com/backend/public/";
    const [dataset, setDataSEt] = useState();
    const [activeChat, setActiveChat] = useState(null);
    const [finalData, setFinalData] = useState()
    const [ws, SetWs] = useState(false);
    const dispatch = useDispatch();
    const listChat = useSelector(chatListData);
    const [activeName, setActiveName] = useState()
    const [activeType, setActiveType] = useState(false)
    let user = useSelector(UserChatData);
    if (user?.length === 0 && localStorage.getItem('chatData')) {
        user = JSON.parse(localStorage.getItem('chatData'));
    } const msgData = useSelector(chatMsgData);
    const reversedMsg = Array.isArray(msgData) && msgData.length !== 0 ? [...msgData].reverse() : [];

    console.log("listmessage***********", reversedMsg);
    const [selectedFile, setSelectedFile] = useState(null);
    useEffect(() => {
        const payload = {
            from: user?.student?.chat_id || user?.teacher?.chat_id,

        };
        dispatch(listMyChat(payload));
    }, []);
    useEffect(() => {
        window.Pusher = require('pusher-js');
        const EchoTest = new Echo({
            broadcaster: 'pusher',
            key: 'ABCDEFG',
            cluster: 'mt1',
            wsHost: "websocket.techiesmarkets.com",
            wsPort: 80,
            forceTLS: false,
            disableStats: true,
        });
        EchoTest.channel(user?.notifychannel || user?.groupchannel)
            .listen('.Chat', (e) => {
                setDataSEt(e);
            })
    }, [ws]);

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
        return Math.round(bytes / Math.pow(k, i), 2) + ' ' + sizes[i];
    };


    useEffect(() => {
        const listMsgPayload = {
            from: user?.student?.chat_id || user?.teacher?.chat_id,
            to: activeChat,
            type: activeType === true ? 5 : 4,
        };

        dispatch(listMessage(listMsgPayload))
            .then((response) => {
                const fromName = response.payload[0]?.from_name;

                if (fromName) {
                    setFromNames([fromName]);
                }
            })
            .catch((error) => {
                console.error("Error fetching messages:", error);
            });

        setDataSEt(null);
    }, [activeChat, ws]);



    const [fromNames, setFromNames] = useState([]);

    const [clickedChats, setClickedChats] = useState([]);
    const handleChatClick = async (chatObject) => {
        setActiveChat(chatObject.chat_id ? chatObject.chat_id : chatObject.batch_id);
        setActiveType(chatObject.chat_id ? false : true);
        setClickedChats((prevChats) => {
            // Check if the chat is already clicked
            if (prevChats.includes(chatObject.chat_id || chatObject.batch_id)) {
                // Chat is already clicked, remove it from clickedChats
                return prevChats.filter((chat) => chat !== (chatObject.chat_id || chatObject.batch_id));
            } else {
                // Chat is not clicked, add it to clickedChats
                return [...prevChats, chatObject.chat_id || chatObject.batch_id];
            }
        });
        // Extract the chat ID
        const chatId = chatObject.chat_id || chatObject.batch_id;

        try {
            // Fetch messages for the clicked chat from the backend
            const listMsgPayload = {
                from: user?.student?.chat_id || user?.teacher?.chat_id,
                to: chatId,
                type: chatObject.chat_id ? 5 : 4,
            };

            const response = await dispatch(listMessage(listMsgPayload));

            // Update the delivery status of each message to "3"
            response.payload.forEach((message) => {
                const payload = {
                    status: 3,
                    chat_id: message.id
                };
                dispatch(updateDeliveryStatus(payload));
            });
        } catch (error) {
            console.error("Error fetching messages:", error);
        }

        console.log("show[msg]***chat", chatObject);
    };



    const baseUrl = 'http://tuitionguruji.com/backend/public/';
 
   

    return (
        <div className='chat-card m-3'>
            <div class="text-start">
                <div class="row">
                    <div class="col-4 px-0">
                        <div className='chat-card-head py-4  text-center'>
                            <p className='chat-card-tittle mb-0'>Recent Messages</p>
                        </div>
                        <div className='chat-list pb-4'>
                            <div class="d-flex justify-content-center">
                                <div className='col'>
                                    <div className='chat-card-search mx-4 my-2 px-4 py-2'>
                                        <input type="text" class="form-control chat-search" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                            </div>
                            <div className='chat-scrollable-section' style={{ height: '500px', overflowY: 'auto' }}>

                                {listChat?.map((chat) => (
                                    <div
                                        key={chat.to}
                                        className={`px-3 py-2 ${activeChat === chat?.user?.firstname || activeChat === chat?.user?.name ? 'chat-list-user-card' : ''}`}
                                        onClick={() => handleChatClick({
                                            [chat?.user?.chat_id ? 'chat_id' : 'batch_id']: chat?.user?.chat_id || chat?.user?.id
                                        })}
                                    >
                                        <div className='row mx-3 my-2' onClick={() => setActiveName(chat?.user?.firstname || chat?.user?.name)}>
                                            <div className='col-3' style={{ width: '60px', height: '60px', overflow: 'hidden' }}>
                                            {chat?.user?.profile_pic || chat?.user?.image ?
                                                    <img src={baseUrl + (chat?.user?.profile_pic || chat?.user?.image)} className="img-fluid img-thumbnail chat-list-img " alt="" /> :
                                                    <img src={defaultProfilePic} className="img-fluid img-thumbnail chat-list-img rounded-circle" alt="Default Profile" />
                                                }
                                            </div>
                                            <div className='col'>
                                                <p className='chat-list-stu-name mb-0'>{chat?.user?.name ? chat?.user?.name : chat?.user?.firstname} <span className='chat-time-txt ms-3'>{convertToLocaleTime(chat?.user?.created_at)}</span></p>
                                                <span>
                                                    <p className='mb-0 chat-list-msg mt-2'>
                                                        {(!clickedChats.includes(chat?.user?.chat_id || chat?.user?.id) && chat?.lastMessage?.unread_count > 0) && (
                                                            <span className='chat-unread-txt ms-3 me-3'>{chat?.lastMessage?.unread_count}</span>
                                                        )}
                                                          {chat?.lastMessage?.message}

                                                          
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>

                    <div class="col px-0">
                        <div className='chat-card-head py-3 px-4 text-start'>
                            <div className='row'>
                                <div className='col'>

                                    {activeName && (
                                        <div className='row'>
                                            <div className='col-1'>
                                                <img src={baseUrl + (listChat.find(chat => chat?.user?.firstname === activeName || chat?.user?.name === activeName)?.user?.profile_pic || listChat.find(chat => chat?.user?.firstname === activeName || chat?.user?.name === activeName)?.user?.image)} className="img-fluid rounded-circle chat-top-img" alt="" />
                                            </div>
                                            <div className='col'>
                                                <p className='chat-list-stu-name mb-0 text-light'>{activeName}</p>
                                                <p className=' mb-0 chat-top-act-txt mt-1'>Active</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-1'>
                                    <BsThreeDotsVertical className='text-light mt-2' />
                                </div>
                            </div>
                        </div>
                        <div className='chat-scrollable-section' style={{ height: '500px', overflowY: 'auto' }}>
                            {reversedMsg?.map((item, index) => (
                                <div className={`row  mx-3 my-5 d-flex ${item.from === user?.student?.chat_id || item.from === user?.teacher?.chat_id ? 'justify-content-end' : 'justify-content-start'}`} key={index}>
                                    <div className={`col-8  message-box  d-flex ${item.from === user?.student?.chat_id || item.from === user?.teacher?.chat_id ? 'my-message' : 'friend-message'}`}>
                                        <div className={`row ${item.from === user?.student?.chat_id || item.from === user?.teacher?.chat_id ? 'justify-content-end ' : ' '}  `} >
                                            <div className={`${item.from === user?.student?.chat_id || item.from === user?.teacher?.chat_id ? 'col-9 ' : 'col-2'} `}>
                                                <img src={img} className={`img-fluid rounded-circle chat-list-img   ${item.from === user?.student?.chat_id || item.from === user?.teacher?.chat_id ? 'd-none' : ''} `} alt="" />

                                            </div>
                                            <div className='col-10' >
                                                {/* <p className='chat-list-stu-name mb-0'>{item.from === user?.student?.chat_id || item.from === user?.teacher?.chat_id ? "Me" : activeName}</p> */}
                                                <p className='mb-0 chat-list-msg mt-2'>
                                                    <div className='chat-msg-name'>{item?.from_name}</div>
                                                    <br />
                                                    {item?.message} &nbsp;
                                                    {item?.status === "3" ? (
                                                        <VscCheckAll className='unread_tick' />
                                                    ) : (
                                                        <VscCheckAll />
                                                    )}
                                                    <span className='chat-time-txt ms-3 text-end'>{convertToLocaleTime(item?.created_at)}</span>
                                                </p>

                                                {item.file && (
                                                    <div className='send-file'>

                                                        <div className={`mt-3   ${item.from === user?.student?.chat_id || item.from === user?.teacher?.chat_id ? 'send-file ' : 'receive-file'} `}>
                                                            <a href={item.file ? basePath + item.file : ""}
                                                                target="_blank" rel="noopener noreferrer" className='send-file-msg'><FaRegArrowAltCircleDown />
                                                                File</a>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {dataset &&
                                <div className={`row mx-3 my-5 ${dataset.from === user?.student?.chat_id || dataset.from === user?.teacher?.chat_id ? 'justify-content-end' : 'justify-content-start'}`}>

                                    <div className='col-8 d-flex justify-content-end'>
                                        <div className='row'>
                                            <div className='col-1'>

                                            </div>
                                            <div className='col'>
                                                <p className='chat-list-stu-name mb-0'>{dataset.from === user?.student?.chat_id || user?.teacher?.chat_id ? "Me" : dataset?.from} <span className='chat-time-txt ms-5'>{convertToLocaleTime(dataset?.created_at)}</span></p>
                                                <p className='mb-0 chat-list-msg mt-2'>{dataset?.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className='chat-int-card mt-2'>
                            <Formik
                                initialValues={{
                                    message: '',
                                    file: ''
                                }}
                                validationSchema={Yup.object({
                                    message: Yup.string().required('Required'),
                                })}
                                onSubmit={(values, { resetForm }) => {
                                    const formData = new FormData();
                                    formData.append('from', user?.student?.chat_id || user?.teacher?.chat_id);
                                    formData.append('to', activeChat);
                                    formData.append('message', values.message);
                                    formData.append('type', activeType === true ? 5 : 4);

                                    if (selectedFile) {
                                        formData.append('file', selectedFile);
                                    }
                                    dispatch(sendMessage(formData));
                                    SetWs(!ws);
                                    resetForm();
                                    setDataSEt(null);
                                    setSelectedFile(null);
                                }}
                            >
                                {({ touched, errors, handleSubmit, resetForm, isSubmitting, setFieldValue, values, handleChange, handleBlur }) => (
                                    <>
                                        <Form>
                                            <div className='row  '>
                                                <div className='col'>
                                                    <div className="input-group my-3">
                                                        <Field
                                                            type="text"
                                                            name="message"
                                                            className={`form-control chat-border-clr-wht`}
                                                            placeholder="Type something..."
                                                            aria-label="Recipient's username"
                                                            aria-describedby="basic-addon2"
                                                        />
                                                        <input
                                                            type="file"
                                                            name="file"
                                                            id="file"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => {
                                                                const file = e.currentTarget.files[0];
                                                                setFieldValue('file', file);
                                                                setSelectedFile(file);
                                                            }}
                                                            multiple
                                                        />
                                                        <label className="input-group-text chat-border-clr-wht mb-0" htmlFor="file">
                                                            <AiOutlinePaperClip className='ms-3' />
                                                        </label>
                                                        <span className="input-group-text chat-border-clr-wht mb-0" id="basic-addon2">
                                                            <button className='btn' type="submit">
                                                                <img src={iconImg} className="img-fluid rounded" alt="" />
                                                            </button>
                                                        </span>
                                                    </div>
                                                    <p className="selected-file-name">{selectedFile ? selectedFile.name : ''}</p>
                                                    <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                    <ErrorMessage name="file" component="div" className="invalid-feedback" />
                                                </div>
                                            </div>
                                        </Form>
                                    </>)}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat