// counterSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from '../../util/apiUtil';
import { showErrorToast, showSuccessToast } from '../../util/toastUtils';



export const systemSettings = createAsyncThunk('subject/systemSettings', async (payload, thunkApi) => {
    console.log("payload", payload);
    try {
        const response = await Axios.post('/api/saveSystemSettings', payload);
        if (response.data.success) {
            showSuccessToast("system settings successfully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast("all field is required")
        console.log("axios error", error);
        throw error;
    }
});



export const WebsiteSettings = createAsyncThunk('subject/WebsiteSettings', async (payload, thunkApi) => {
    console.log("payload", payload);
    try {
        const response = await Axios.post('/api/saveWebsiteSettings', payload);
        if (response.data.success) {
            showSuccessToast("website Settings Successfully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast(" all field is required")
        console.log("axios error", error);
        throw error;
    }
});



export const paymentSettings = createAsyncThunk('subject/paymentSettings', async (payload, thunkApi) => {
    console.log("payload", payload);
    try {
        const response = await Axios.post('/api/savePaymentSettings', payload);
        if (response.data.success) {
            showSuccessToast("payment settings successfully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast("all field is required")
        console.log("axios error", error);
        throw error;
    }
});




export const PaypalPaymentSettings = createAsyncThunk('subject/PaypalPaymentSettings', async (payload, thunkApi) => {
    console.log("payload", payload);
    try {
        const response = await Axios.post('/api/savePaymentVendorSettings', payload);
        if (response.data.success) {
            showSuccessToast("payment settings successfully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast("all field is required")
        console.log("axios error", error);
        throw error;
    }
});




export const StripePaymentSettings = createAsyncThunk('subject/StripePaymentSettings', async (payload, thunkApi) => {
    console.log("payload", payload);
    try {
        const response = await Axios.post('/api/savePaymentVendorSettings', payload);
        if (response.data.success) {
            showSuccessToast("payment settings successfully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast("all field is required")
        console.log("axios error", error);
        throw error;
    }
});


export const SMTPSettings = createAsyncThunk('subject/SMTPSettings', async (payload, thunkApi) => {
    console.log("payload", payload);
    try {
        const response = await Axios.post('/api/saveSMTPSettings', payload);
        if (response.data.success) {
            showSuccessToast("SMTP settings successfully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast("all field is required")
        console.log("axios error", error);
        throw error;
    }
});

export const TaxSettings = createAsyncThunk('settings/saveTaxSettings', async (payload, thunkApi) => {
    console.log("payload", payload);
    try {
        const response = await Axios.post('/api/saveTaxSettings', payload);
        if (response.data.success) {
            showSuccessToast("Tax settings successfully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast("all field is required")
        console.log("axios error", error);
        throw error;
    }
});

export const settingSlice = createSlice({
    name: 'settings',
    initialState: {
        isLoading: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(systemSettings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(systemSettings.fulfilled, (state, action) => {


                console.log("action", action.payload);
                {
                    state.isLoading = false;
                }
            })
            .addCase(systemSettings.rejected, (state) => {
                state.isLoading = false;
            })

            
    },


});



// list tax settings
export const fetchTaxList = createAsyncThunk('student/fetchTaxList', async () => {
    try {
        const response = await Axios.post('/api/getTaxSettings');
        console.log("response -> fetchTaxList", response);
        return response?.data?.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const settingLoading = (state) => state.settings.isLoading;


export default settingSlice.reducer;

