import React, { useState } from 'react'
import { RotatingLines } from 'react-loader-spinner';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai';
import { subjectLoading } from '../../../../../../redux/slice/SubjectSlice';
import { TaxSettings } from '../../../../../../redux/slice/settingsSlice';



function AdminAddTaxcomponent(props) {
    const validationSchema = Yup.object({
        // name: Yup.string().required('Name is required'),
        // value: Yup.string().required('Value is required'),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector(subjectLoading);

    const goToTaxpage = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminTaxSettings' })
        }
    }


    const [addTax, setAddTax] = useState({
        name: "",
        value: ""
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddTax({ ...addTax, [name]: value });
    };
    
    return (
        <div>
            <div className="container m-xxl-5 m-xl-5 m-lg-5 m-md-5 ">
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <div className="col-xxl-8 col-xl-8 col-lg-10 col">
                            <div className="main-divHead m-xxl-5 m-xl-5 m-lg-5 m-md-5 m-0">
                                <div className="sub-Head1">
                                    <div className="Sub-Head2" onClick={(e) => goToTaxpage(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>Tax</div>
                                </div>
                                <div className="p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-2">
                                    <h4 className="fw-bold">Add Tax</h4>
                                    <Formik
                                        initialValues={{
                                            name: "",
                                            value: ""
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, { resetForm }) => {
                                            const payload = {
                                                name: values.name,
                                                value: values.value
                                            }
                                            dispatch(TaxSettings(payload));
                                            resetForm();
                                            setTimeout(() => {
                                                goToTaxpage();
                                            }, 2000);
                                        }}>                                
                                        {({ errors, touched, setFieldValue }) => (
                                            <Form>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3">
                                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 me-4">
                                                                <label className="admin-label mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-4">Tax Name</label>
                                                                <Field
                                                                    type="text"
                                                                    name="name"
                                                                    className="form-control admin-input"
                                                                    onChange={(e) => {
                                                                        setFieldValue("name", e.target.value)
                                                                        handleInputChange(e)
                                                                    }}
                                                                />
                                                                <ErrorMessage
                                                                    name="name"
                                                                    component="div"
                                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                                />
                                                            </div>
                                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 me-3">
                                                                <label className="admin-label mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-4">Tax Value</label>
                                                                <Field
                                                                    type="text"
                                                                    name="value"
                                                                    className="form-control admin-input"
                                                                    onChange={(e) => {
                                                                        setFieldValue("value", e.target.value)
                                                                        handleInputChange(e)
                                                                    }}
                                                                />
                                                                <ErrorMessage
                                                                    name="value"
                                                                    component="div"
                                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container text-center">
                                                    <div className="row">
                                                        <div className="col">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                                                            >
                                                                {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                                                                    strokeColor="white"
                                                                    strokeWidth="5"
                                                                    animationDuration="0.75"
                                                                    width="25"
                                                                    height="25"
                                                                    visible={true}
                                                                /></div> : "Save"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminAddTaxcomponent