import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { BsFiletypePdf } from 'react-icons/bs';
import { AiFillEye, AiOutlineLeft } from 'react-icons/ai';
import { activeTeacherID, teacherDocumentStatusChange, viewTeacherData } from '../../../../../../redux/slice/teacherSlice';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

function AdminTeacherDocumentComponent(props) {

    const dispatch = useDispatch();
    const teacherId = useSelector(activeTeacherID);
    const teacherData = useSelector(viewTeacherData);
    const basePath = "http://tuitionguruji.com/backend/public/";


    const validationSchema = Yup.object().shape({
        checkbox1Checked: Yup.boolean().required('Please select an option'),
        checkbox2Checked: Yup.boolean().required('Please select an option'),
        checkbox3Checked: Yup.boolean().required('Please select an option'),
        checkbox4Checked: Yup.boolean().required('Please select an option'),
        checkbox5Checked: Yup.boolean().required('Please select an option'),
        checkbox6Checked: Yup.boolean().required('Please select an option'),
    });

    const handleCheckboxChange = (fieldName, setFieldValue, values) => (e) => {
        const value = e.target.checked;
        setFieldValue(fieldName, value);
        const pairNumber = parseInt(fieldName.match(/\d+/)[0]);
        const otherFieldName = `checkbox${pairNumber % 2 === 1 ? pairNumber + 1 : pairNumber - 1}Checked`;
        setFieldValue(otherFieldName, !value);
    };


    const goToAdminTeacherDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminTeacherDetails' })
        }
    }


    const handleOpenDocument = () => {
        if (teacherData.document) {
            const documentUrl = basePath + teacherData.document;
            // Open the document in a new tab
            window.open(documentUrl, '_blank');
        }
    };
    return (
        <Formik
            initialValues={{
                checkbox1Checked: true,
                checkbox2Checked: false,
                checkbox3Checked: true,
                checkbox4Checked: false,
                checkbox5Checked: true,
                checkbox6Checked: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                if (values.checkbox1Checked && values.checkbox3Checked && values.checkbox5Checked) {
                    const valueToSend = {
                        "teacher_id": teacherId,
                        "status": 1
                    }
                    dispatch(teacherDocumentStatusChange(valueToSend));
                }
                else {
                    const valueToSend = {
                        "teacher_id": teacherId,
                        "status": 0
                    }
                    dispatch(teacherDocumentStatusChange(valueToSend));
                }
            }}
        >
            {({ handleSubmit, setFieldValue, values }) => (
                <form onSubmit={handleSubmit}>
                    {/* <div className='main-divHead'> */}
                    {/* <div className='sub-Head1'>
                        <div className="Sub-Head2" onClick={(e) => goToAdminTeacherDetails(e)}><span><AiOutlineLeft className='mb-1 me-2'/></span>Teacher</div>
                        </div> */}
                    <div className='mx-5'>
                        <div className='row'>
                            <div className='col'>
                                <p className=' mt-5 mb-3 ms-5 admin-sub-doc-head'>Supporting Documents</p>
                            </div>

                            <div className='col'>
                                <p class='admin-sub-head-card mt-5 '><span class='text-success fw-bold me-2'>Approved</span></p>
                            </div>
                        </div>

                        <div className='container text-center my-5'>
                            <div className='container text-center'>
                                {Array.from({ length: 1 }, (_, index) => (
                                    <div className='row' key={index}>
                                        <div className='d-flex '>
                                            <div className='col-1'>
                                                <BsFiletypePdf className='adminPdfIcon' />
                                            </div>
                                            <div className='col'>
                                                <p className='fs-6'>{teacherData.document}</p>
                                            </div>
                                            <div className='col ms-5'>
                                                <div className='admin-card-eye' onClick={handleOpenDocument}>
                                                    <AiFillEye className='adminEyeIcon'  />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='form-check'>
                                                    <Field
                                                        type='checkbox'
                                                        name={`checkbox${index * 2 + 1}Checked`}
                                                        className={`form-check-input ${values[`checkbox${index * 2 + 1}Checked`] ? 'bg-success' : ''} border-success`}
                                                        onChange={handleCheckboxChange(
                                                            `checkbox${index * 2 + 1}Checked`,
                                                            setFieldValue,
                                                            values
                                                        )}
                                                    />
                                                    <Field
                                                        type='checkbox'
                                                        name={`checkbox${index * 2 + 2}Checked`}
                                                        className={`form-check-input ms-3 ${values[`checkbox${index * 2 + 2}Checked`] ? 'bg-danger' : ''}  border-danger`}
                                                        onChange={handleCheckboxChange(
                                                            `checkbox${index * 2 + 2}Checked`,
                                                            setFieldValue,
                                                            values
                                                        )}
                                                    />
                                                </div>
                                                <ErrorMessage
                                                    name={`checkbox${index * 2 + 1}Checked`}
                                                    component='div'
                                                    className='error-message'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='container text-center'>
                            <div className='row ms-5'>
                                <div className='d-flex justify-content-center'>
                                    <div className='col-xxl-3 col-xl-3 col-lg-4'>
                                        <button type='button' className='btn btn-outline adminOPbtn my-5 ' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Ask Clarification
                                        </button>
                                    </div>

                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5" id="exampleModalLabel">Ask Clarification</h1>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">

                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                    <button type="button" class="btn btn-primary">Reply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xxl-3 col-xl-3 col-lg-4'>
                                        <button type='submit' className='btn text-light px-5 py-1 bootBtn my-5'>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </form>
            )}
        </Formik>
    );
}

export default AdminTeacherDocumentComponent;
