import React from 'react'
import _ from "lodash"
import { AiFillSetting } from 'react-icons/ai'


function SettingsComponent(props) {

    const goToTeacherChat = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminSystemSettings' })
        }
    }

    const goToAdminSettings = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminSettings' })
        }
    }

    const goToSMTP = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminSMTPSettings' })
        }
    }

    const goToWebsite = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminWebsiteSettings' })
        }
    }

    const goToPayment = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminPaymentSettings' })
        }
    }
    const goToTax = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminTaxSettings' })
        }
    }
    return (
        <div>
            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                <li>
                    <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'adminSystemSettings' || _.get(props, 'userState.subType') == 'adminSMTPSettings' || _.get(props, 'userState.subType') == 'adminWebsiteSettings' || _.get(props, 'userState.subType') == 'adminPaymentSettings' ? 'active' : ''}`}>
                        <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                            <i className=""></i> <span className='head-assignment' ><span><AiFillSetting className='ms-3' /></span ><span className='head-dashboard ms-2'>Settings</span></span>
                        </a>
                    </div>
                    <ul className="collapse  flex-column ms-1 " id="submenu2" data-bs-parent="#menu" style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                        <li className={` w-100 mt-2 ${props.userState.subType === "adminSystemSettings" ? "settingsActive" : ""} py-1`}>
                            <a onClick={() => { goToTeacherChat() }}> <span className='head-dashboard ms-4 '>System Settings</span></a>
                        </li>
                        <li className={`w-100 mt-2 ${props.userState.subType === "adminWebsiteSettings" ? "settingsActive" : ""} py-1`}>
                            <a onClick={() => { goToWebsite() }}> <span className='head-dashboard ms-4'>Website Settings</span></a>
                        </li>
                        <li className={`w-100 mt-2 ${props.userState.subType === "adminSMTPSettings" ? "settingsActive" : ""} py-1`}>
                            <a onClick={() => { goToSMTP() }}> <span className='head-dashboard ms-4'>SMTP Settings</span></a>
                        </li>
                        <li className={`w-100 mt-2 ${props.userState.subType === "adminPaymentSettings" ? "settingsActive" : ""} py-1`}>
                            <a onClick={() => { goToPayment() }}> <span className='head-dashboard ms-4'>Payment Settings</span></a>
                        </li>
                        <li className={`w-100 mt-2 ${props.userState.subType === "adminTaxSettings" ? "settingsActive" : ""} py-1`}>
                            <a onClick={() => { goToTax() }}> <span className='head-dashboard ms-4'>Tax Settings</span></a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default SettingsComponent