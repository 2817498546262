import React, { useEffect, useState, } from "react"
import "./Navtution.scss";
import _ from "lodash";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from "../../../../../../../redux/slice/SubjectSlice";
import axios from "axios";
import { showErrorToast, showSuccessToast } from './../../../../../../../util/toastUtils'
import { useDispatch, useSelector } from "react-redux";
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";
function Navtution({ successCallback, ...props }) {
  const [loading, setLoading] = useState(true);
  const Backdashboard = () => {
    if (_.get(props, "setUserState")) {
      props.setUserState({ ...props.userState, subType: "Navtution", });
    }
  };
  const [showtable, setshowtable] = useState(false);
  const getUserState = () => {
    return { 'dataTable': showtable }
  }
  const handleDownload = () => {
    // Logic to generate or fetch the file URL
    const fileUrl = 'https://example.com/file.pdf';

    // Quotation a temporary link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'filename.pdf';

    // Dispatch a click event on the link to trigger the download
    link.dispatchEvent(new MouseEvent('click'));
  };

  const QuotationvalidationSchema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
    sub_category: Yup.string().required('Sub Category is required'),
    topic: Yup.string().required('Topic is required'),
    details: Yup.string().required('Details is required'),
    closing_date: Yup.string().required('Closing date is required'),
    // Add more validations for other fields
  });
  const [Quotation, setQuotation] = useState({
    id: "",
    category: "",
    sub_category: "",
    details: "",
    topic: "",
    closing_date: "",
    student_id: "",
    type: "",
    currency: "",
    price: " ",
    term: "",
    file: "",
    status: "",
    updated_at: "",
    created_at: "",
    id: "",
    dates: {
      monday: { from: "", to: "" },
      tuesday: { from: "", to: "" },
      wednesday: { from: "", to: "" },
      thursday: { from: "", to: "" },
      friday: { from: "", to: "" },
      saturday: { from: "", to: "" },
      sunday: { from: "", to: "" }
    }
  })
  const QuotationStudent = () => {
    if (!localStorage.getItem('token')) {
      Backdashboard();
    }
    const formData = new FormData();
    formData.append("category", Quotation.category);
    formData.append("sub_category", Quotation.sub_category);
    formData.append("details", Quotation.details);
    formData.append("topic", Quotation.topic);
    formData.append("closing_date", Quotation.closing_date);
    formData.append("student_id", Quotation.student_id);
    formData.append("type", Quotation.type);
    formData.append("currency", Quotation.currency);
    formData.append("price", Quotation.price);
    formData.append("term", Quotation.term);
    formData.append("file", Quotation.file);
    formData.append("status", Quotation.status);
    formData.append('dates[monday][from]', formatTime(Quotation.dates.monday.from));
    formData.append('dates[monday][to]', formatTime(Quotation.dates.monday.to));
    formData.append('dates[tuesday][from]', formatTime(Quotation.dates.tuesday.from));
    formData.append('dates[tuesday][to]', formatTime(Quotation.dates.tuesday.to));
    formData.append('dates[wednesday][from]', formatTime(Quotation.dates.wednesday.from));
    formData.append('dates[wednesday][to]', formatTime(Quotation.dates.wednesday.to));
    formData.append('dates[thursday][from]', formatTime(Quotation.dates.thursday.from));
    formData.append('dates[thursday][to]', formatTime(Quotation.dates.thursday.to));
    formData.append('dates[friday][from]', formatTime(Quotation.dates.friday.from));
    formData.append('dates[friday][to]', formatTime(Quotation.dates.friday.to));
    formData.append('dates[saturday][from]', formatTime(Quotation.dates.saturday.from));
    formData.append('dates[saturday][to]', formatTime(Quotation.dates.saturday.to));
    formData.append('dates[sunday][from]', formatTime(Quotation.dates.sunday.from));
    formData.append('dates[sunday][to]', formatTime(Quotation.dates.sunday.to));
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')), }
    Axios
      .post(
       '/api/createQuotationTuition', formData,
        { headers: headers },
      )
      .then((res) => {
        if (res.data.success) {
          let user = _.get(res, "data.data.user")
            ? JSON.stringify(res.data.data.user)
            : undefined;
          let token = _.get(res, "data.data.token")
            ? JSON.stringify(res.data.data.token)
            : undefined;
          if (user) {
            localStorage.setItem("user", JSON.stringify(res.data.data.user));
          }
          if (token) {
            localStorage.setItem("token", (res.data.data.token));
          }
          // Show a success toast message
          showSuccessToast("Quotation created successfully");
          setTimeout(() => {
            // Update your table or perform other actions here
            setQuotation(res.data.data);
            updateDataTable();
            if (successCallback) {
              successCallback(res.data.data);
            }
          }, 2000); // Delay for 2 seconds

        }

      })
      .catch((error) => {
        // Show an error toast message
        showErrorToast("Something went wrong");
        setLoading(false);
      });
  }
  const [dataTableData, setDataTableData] = useState([]); // State to store data for the data table

  // Function to update the data table
  const updateDataTable = (newData) => {
    setDataTableData([...dataTableData, newData]);
  };
  //to set the values in setQuotation
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('dates.monday')) {
      // Update the nested 'dates.monday' object
      setQuotation({
        ...Quotation,
        dates: {
          ...Quotation.dates,
          monday: {
            ...Quotation.dates.monday,
            [name.split('.').pop()]: value, // Update the specific field
          },
        },
      });
    }
    else if (name.startsWith('dates.tuesday')) {
      // Update the nested 'dates.tuesday' object
      setQuotation({
        ...Quotation,
        dates: {
          ...Quotation.dates,
          tuesday: {
            ...Quotation.dates.tuesday,
            [name.split('.').pop()]: value, // Update the specific field
          },
        },
      });
    }
    else if (name.startsWith('dates.wednesday')) {
      // Update the nested 'dates.wednesday' object
      setQuotation({
        ...Quotation,
        dates: {
          ...Quotation.dates,
          wednesday: {
            ...Quotation.dates.wednesday,
            [name.split('.').pop()]: value, // Update the specific field
          },
        },
      });
    }
    else if (name.startsWith('dates.thursday')) {
      // Update the nested 'dates.thursday' object
      setQuotation({
        ...Quotation,
        dates: {
          ...Quotation.dates,
          thursday: {
            ...Quotation.dates.thursday,
            [name.split('.').pop()]: value, // Update the specific field
          },
        },
      });
    }
    else if (name.startsWith('dates.friday')) {
      // Update the nested 'dates.friday' object
      setQuotation({
        ...Quotation,
        dates: {
          ...Quotation.dates,
          friday: {
            ...Quotation.dates.friday,
            [name.split('.').pop()]: value, // Update the specific field
          },
        },
      });
    }
    else if (name.startsWith('dates.saturday')) {
      // Update the nested 'dates.saturday' object
      setQuotation({
        ...Quotation,
        dates: {
          ...Quotation.dates,
          saturday: {
            ...Quotation.dates.saturday,
            [name.split('.').pop()]: value, // Update the specific field
          },
        },
      });
    }
    else if (name.startsWith('dates.sunday')) {
      // Update the nested 'dates.sunday' object
      setQuotation({
        ...Quotation,
        dates: {
          ...Quotation.dates,
          sunday: {
            ...Quotation.dates.sunday,
            [name.split('.').pop()]: value, // Update the specific field
          },
        },
      });
    }
    else {

      // Update other fields in Quotation
      setQuotation({ ...Quotation, [name]: value });
    }
  };
  const data = useSelector(CSTData);
  const dispatch = useDispatch();
  const subCatData = useSelector(SubData);
  const TopicData = useSelector(topicData);
  useEffect(() => {
    const payload = {
      type: 1
    };
    dispatch(getCSTData(payload));
  }, []);


  const categoryOptions = data?.map((e) => ({
    value: e.id,
    label: e.name
  }));

  const SubCategoryOptions = subCatData?.map((e) => ({
    value: e.id,
    label: e.name
  }));

  const TopicOptions = TopicData?.map((e) => ({
    value: e.id,
    label: e.name
  }));
  const handleChange = (e) => {
    setQuotation({ ...Quotation, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    if (_.get(props, "userState.row")) {
      initializeObject();
    }
    // listCategories();
  }, []);
  const initializeObject = () => {
    setQuotation({ ...Quotation, ...props.userState.row });
  };
  const formatTime = (time) => {
    // Assuming time is in HH:mm format
    return time ? `${time}:00` : ''; // Add seconds (":00") to match H:i:s format
  };
  return (
    <div >
      <div class="container text-start">
        <div class="row">
          <div className="d-flex justify-content-center">
            <div className="col-8" >
              <h4 className="student-quote-head mb-0">Get Quote for Online Tuition, Crash Course</h4>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            category: '',
            sub_category: '',
            topic: '',
            details: '',
            closing_date: '',
          }}
          validationSchema={QuotationvalidationSchema}
          onSubmit={(values, { resetForm }) => {
            const payload = {
              "category": values.category,
              "sub_category": values.sub_category,
              "topic": values.topic,
              "details": values.details,
              "closing_date": values.closing_date,
            }
            QuotationStudent();
            resetForm();

          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form >
              <div class="row">
                <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-center ">
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6 col mt-3 me-xxl-3 me-xl-3 me-lg-3 me-md-3 me-sm-3 me-0">
                    <label className="stu-free-quote">Category</label>
                    <Field
                      className="form-select admin-dropdown "
                      as="select"
                      name="category"
                      value={Quotation.category}
                      onChange={(e) => {                   
                        const selectedValue = e.target.value;
                        const payload = {
                          "type": 2,
                          "category_id": selectedValue,
                        };
                        dispatch(getSubCategoryData(payload));
                        setFieldValue("category", e.target.value)
                        handleInput(e);
                      }}
                    >
                      <option value=""></option>
                      {categoryOptions?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="category"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6 col mt-3">
                    <label className="stu-free-quote">Sub Category</label>
                    <Field
                      className="form-select admin-dropdown"
                      id="categoryDropdown"
                      as="select"
                      name="sub_category"
                      value={Quotation.sub_category}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        const payload = {
                          "type": 3,
                          "sub_category_id": selectedValue,
                        };
                        dispatch(getSubTopicData(payload));
                        setFieldValue("sub_category", e.target.value)
                        handleInput(e);
                      }}
                    >
                      <option value=""></option>
                      {SubCategoryOptions?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="sub_category"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-center">
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6 mt-3 me-xxl-3 me-xl-3 me-lg-3 me-md-3 me-sm-3 me-0">
                    <label className="stu-free-quote">Topic</label>
                    <Field
                      className="form-select admin-dropdown"
                      id="categoryDropdown"
                      as="select"
                      name="topic"
                      value={Quotation.topic}
                      onChange={(e) => {
                        setFieldValue("topic", e.target.value)
                        handleInput(e)
                      }
                      }
                    >
                      <option value=""></option>
                      {TopicOptions?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      // name="topic"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6  mt-3">
                    <label className="stu-free-quote">Quote closing Date:</label>
                    <div>
                      <Field className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col p-1 stu-free-date " type="Date"
                        required
                        autoFocus
                        value={Quotation.closing_date}
                        name="closing_date"
                        onChange={(e) => {
                          setFieldValue("closing_date", e.target.value)
                          handleInput(e)
                        }
                        } />
                      <ErrorMessage
                        name="closing_date"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-center">
                  <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-11 mt-3 me-xxl-3 me-xl-3 me-lg-3 me-md-3 me-sm-3 me-0 ">
                    <label className="stu-free-quote"> Describe your profile:</label>
                    <div>
                      <Field className="form-control admin-dropdown" type="textarea"
                        component="textarea"
                        required
                        autoFocus
                        value={Quotation.details}
                        name="details"
                        onChange={(e) => {
                          setFieldValue("details", e.target.value)
                          handleInput(e)
                        }
                        }
                      />
                      <ErrorMessage
                        name="details"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex  justify-content-center">
                  <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-11">
                    <div className="admin-card  my-5 p-3">
                      <p className="stud-online-time">Scheduling Days & Timings</p>
                      <div class="row ps-3">
                        <div class="col-3 p-0 m-0 mt-3 custom-control custom-checkbox">
                          <p className="stud-online-day"> <span className="me-2">
                            <input type="checkbox" className="custom-control-input" /></span>Monday</p>
                        </div>
                        <div class="col-5 p-0 d-flex align-self-center">
                          <p className=" stud-online-from mt-2">From</p>
                          <Field type="time"
                            id="appt"
                            className="time-box mx-1"
                            name="dates.monday.from"
                            value={formatTime(Quotation.dates.monday.from)}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="dates.monday.from"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.monday.from"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>
                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div class="col-4 p-0 m-0 d-flex">
                          <p className="stud-online-from mt-2">To</p>
                          <Field type="time" id="appt" className="time-box mx-1 " name="dates.monday.to"
                            value={Quotation.dates.monday.to}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="select time"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.monday.to"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>

                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div class="row ps-3">
                        <div class="col-3 p-0 m-0 mt-3 ">
                          <p className="stud-online-day"> <span className="me-2">
                            <input type="checkbox" /></span>tuesday</p>
                        </div>
                        <div class="col-5 p-0 d-flex align-self-center">
                          <p className=" stud-online-from mt-2">From</p>
                          <Field type="time" id="appt" className="time-box mx-1" name="dates.tuesday.from"
                            value={Quotation.dates.tuesday.from}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="dates.tuesday.from"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.tuesday.from"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>
                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div class="col-4 p-0 m-0 d-flex">
                          <p className="stud-online-from mt-2">To</p>
                          <Field type="time" id="appt" className="time-box mx-1 " name="dates.tuesday.to"
                            value={Quotation.dates.tuesday.to}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="select time"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.tuesday.to"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>

                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div class="row ps-3">
                        <div class="col-3 p-0 m-0 mt-3 ">
                          <p className="stud-online-day"> <span className="me-2">
                            <input type="checkbox" /></span>wednesday</p>
                        </div>
                        <div class="col-5 p-0 d-flex align-self-center">
                          <p className=" stud-online-from mt-2">From</p>
                          <Field type="time" id="appt" className="time-box mx-1" name="dates.wednesday.from"
                            value={Quotation.dates.wednesday.from}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="dates.wednesday.from"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.wednesday.from"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>
                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div class="col-4 p-0 m-0 d-flex">
                          <p className="stud-online-from mt-2">To</p>
                          <Field type="time" id="appt" className="time-box mx-1 " name="dates.wednesday.to"
                            value={Quotation.dates.wednesday.to}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="select time"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.wednesday.to"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>

                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div class="row ps-3">
                        <div class="col-3 p-0 m-0 mt-3 ">
                          <p className="stud-online-day"> <span className="me-2">
                            <input type="checkbox" /></span>Thursday</p>
                        </div>
                        <div class="col-5 p-0 d-flex align-self-center">
                          <p className=" stud-online-from mt-2">From</p>
                          <Field type="time" id="appt" className="time-box mx-1" name="dates.thursday.from"
                            value={formatTime(Quotation.dates.thursday.from)}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="dates.thursday.from"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.thursday.from"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>

                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div class="col-4 p-0 m-0 d-flex">
                          <p className="stud-online-from mt-2">To</p>
                          <Field type="time" id="appt" className="time-box mx-1 " name="dates.thursday.to"
                            value={Quotation.dates.thursday.to}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="select time"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.thursday.to"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>

                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div class="row ps-3">
                        <div class="col-3 p-0 m-0 mt-3 ">
                          <p className="stud-online-day"> <span className="me-2">
                            <input type="checkbox" /></span>friday</p>
                        </div>
                        <div class="col-5 p-0 d-flex align-self-center">
                          <p className=" stud-online-from mt-2">From</p>
                          <Field type="time" id="appt" className="time-box mx-1" name="dates.friday.from"
                            value={formatTime(Quotation.dates.friday.from)}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="dates.friday.from"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.friday.from"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>
                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div class="col-4 p-0 m-0 d-flex">
                          <p className="stud-online-from mt-2">To</p>
                          <Field type="time" id="appt" className="time-box mx-1 " name="dates.friday.to"
                            value={Quotation.dates.friday.to}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="select time"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.friday.to"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>

                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div class="row ps-3">
                        <div class="col-3 p-0 m-0 mt-3 ">
                          <p className="stud-online-day"> <span className="me-2">
                            <input type="checkbox" /></span>saturday</p>
                        </div>
                        <div class="col-5 p-0 d-flex align-self-center">
                          <p className=" stud-online-from mt-2">From</p>
                          <Field type="time" id="appt" className="time-box mx-1" name="dates.saturday.from"
                            value={formatTime(Quotation.dates.saturday.from)}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="dates.saturday.from"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.saturday.from"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>

                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div class="col-4 p-0 m-0 d-flex">
                          <p className="stud-online-from mt-2">To</p>
                          <Field type="time" id="appt" className="time-box mx-1 " name="dates.saturday.to"
                            value={Quotation.dates.saturday.to}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="select time"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.saturday.to"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>

                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div class="row ps-3">
                        <div class="col-3 p-0 m-0 mt-3 ">
                          <p className="stud-online-day"> <span className="me-2">
                            <input type="checkbox" /></span>sunday</p>
                        </div>
                        <div class="col-5 p-0 d-flex align-self-center">
                          <p className=" stud-online-from mt-2">From</p>
                          <Field type="time" id="appt" className="time-box mx-1" name="dates.sunday.from"
                            value={formatTime(Quotation.dates.sunday.from)}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="dates.sunday.from"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.sunday.from"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>
                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div class="col-4 p-0 m-0 d-flex">
                          <p className="stud-online-from mt-2">To</p>
                          <Field type="time" id="appt" className="time-box mx-1 " name="dates.sunday.to"
                            value={Quotation.dates.sunday.to}
                            onChange={handleInput} />
                          <ErrorMessage
                            name="select time"
                            component="div"
                            className="text-danger"
                          />
                          <Field className='time-box' name="Quotation.dates.sunday.to"
                            as="select"
                            placeholder='AM/PM'
                          >
                            <option onChange={(e) => {
                              setFieldValue("term", e.target.value)
                              handleInput(e)
                            }
                            } selected></option>
                            <option value="Hourly">AM</option>
                            <option value="Fixed">PM</option>

                          </Field>
                          <ErrorMessage
                            name="select"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-center mt-2">
                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-9 col-sm-10 col">
                      <div className="col">
                        <div class="row">
                          <div class="col text-end" >
                            <div class="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-sm-12 col">
                              <div className="align-submit-btn mt-5 d-xxl-flex d-xl-flex d-lg-flex d-md-flex ">
                                <button type="submit"
                                  className="btn btn-primary button-3 p-2"
                                  onClick={() => QuotationStudent()}
                                >
                                  Request Quotes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Navtution;
