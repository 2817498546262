import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import profileimg2 from "../../../../../../../images/landing_page_images/profileimg2.png"
import './viewProfile.scss'
import _ from 'lodash';
import axios from 'axios';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Rectangle4467 from "../../../../../../../images/landing_page_images/Rectangle4467.jpg"
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { FaBusinessTime } from "react-icons/fa";
import { IoTimeSharp } from "react-icons/io5";
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import BASE_URL from '../../../../../../../BaseUrl';
import Axios from '../../../../../../../util/apiUtil';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
function Viewprofile({ ...props }) {

    const data = localStorage.getItem('user');
    const userData = JSON.parse(data);

    const [Days, setDays] = useState([
        {
            day: "Days",
            day1: "Monday",
            day2: "Tuesday",
            day3: "Wednesday",
            day4: "Thursday",
            day5: "Friday",
            day6: "Saturday",
            day7: "Sunday",
        },
        {
            time: "Timing",
            time1: "12-1 PM",
            time2: "12-1 PM",
            time3: "12-1 PM",
            time4: "2-3 PM",
            time5: "12-1 PM",
            time6: "12-1 PM",
            time7: "12-4 PM"

        },
    ])
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true)
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'DashboardTeacher' })
        }
    }
    const listCountries = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/listCountries', undefined, { headers: headers })

            .then((response) => {
                if (_.get(response, "data.data")) {
                    const countriesObject = response.data.data;
                    // Convert the object into an array of country names
                    const countriesArray = Object.values(countriesObject);
                    setCountries(countriesArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching countries:', error);
                setLoading(false);

            })
    }
    const Day = (item) => {
        Days[item.id - 1].open = !item.open;
        setDays([...Days]);
    }


    const fetchUserProfile = () => {
        if (!localStorage.getItem('token')) {
            // Handle the case when there is no token
            return;
        }

        const headers = {
            'Authorization': 'Bearer ' + (localStorage.getItem('token'))
        };

        Axios.post(
           '/api/getUserProfile',
            // Add any request data here if needed, like request parameters or a request body
            undefined,
            { headers: headers }
        )
            .then(response => {

                console.log('User Profile:', response.data);

            })
            .catch(error => {

                console.error('Error fetching User Profile:', error);
            });
    };

    useEffect(() => {
        // Call the function to fetch the user profile data
        fetchUserProfile();
        // You can also call other functions or set up state as needed
    }, []);

    const [featured, setFeatured] = useState(
        [
            {
                name: "$130",
                id: 1,
                count: "925",
                duration: "14hrs",
                image: "."
            },
            {
                name: "$130",
                id: 2,
                count: "919",
                duration: "12hrs",
                image: "Loretium risus euismod dictum egestas cursus orci in. Id sed montes."
            },
            {
                name: "$130",
                id: 3,
                count: "911",
                duration: "20hrs",
                image: "Lorem ipsum dolor sit ametentas cursus orci in. Id sed montes."
            },


        ]
    );

    const cardOpen = (card) => {
        featured[card.id - 1].open = !card.open;
        setFeatured([...featured]);
    }

    return (
        <>
            <div className='view-container'>
                <div className="view-profile d-flex">
                    <div className="faChevronLeft-1">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <div className="details-nav">Teacher profile details</div>
                </div>
                <div className='d-flex'>
                    <div className='teacher-profile d-flex'>
                        <div className='img-cont d-flex'>
                            <img className="detail-img" src={profileimg2} />
                        </div>
                        <div className='teacher-1'>Meera</div>
                        <div className='card teacher-card'>
                            <div className='card-body teacher-body d-flex'>
                                Here you can send your quote direct to the teacher.
                            </div>
                            <div className='Quote-btn'>
                                <button variant="contained" className='btn btn-primary get-quote'>Get a Quote</button>
                            </div>
                        </div>
                        <div className='social-network'>
                            Social Network
                        </div>
                        linkedin
                        <div class="input-group mb-3 linked-in">
                            <span class="input-group-text" id="basic-addon1"><LinkedInIcon className='icon-linked' /></span>
                            <input type="text" value={userData.firstname} className='user-name' class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                        <div class="input-group mb-3 linked-in">
                            <span class="input-group-text" id="basic-addon1"><FacebookIcon className='icon-face' /></span>
                            <input type="text" className='user-name' class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                        <div class="input-group mb-3 linked-in">
                            <span class="input-group-text" id="basic-addon1"><TwitterIcon className='icon-twitter' /></span>
                            <input type="text" className='user-name' class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                        <div class="input-group mb-3 linked-in">
                            <span class="input-group-text" id="basic-addon1"><InstagramIcon className='icon-insta' /></span>
                            <input type="text" className='user-name' class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                        <div class="input-group mb-3 linked-in">
                            <span class="input-group-text" id="basic-addon1"><YouTubeIcon className='icon-youtube' /></span>
                            <input type="text" className='user-name' class="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                    </div>

                    <div className='card jerry-1'>
                        <div className='jerry-head d-flex'>
                            <div className='d-flex'>
                                <div className='jerry-teacher'>
                                    Jerry
                                </div>
                                <div><LabelImportantIcon />
                                </div>
                            </div>

                            <div>
                                <div className='teacher-2'>Teacher</div>
                                <Grid container spacing={2}>

                                    <Grid item xs={4}>
                                        <div className="bio-2">Bio:</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className='p2'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="bio-2"> Country :</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className='p2'>India
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="bio-2">Qualification:</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className='p2'>Bachelor of Education (B.Ed.)
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="bio-2">Experience :</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className='p2'>5 years
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="bio-2">Category :</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className='p2'>Category
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="bio-2">Sub Category :</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className='p2'>Sub Category
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="bio-2">Availability :</div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {Days.map((item) => (
                                            <div className="table-timing1">
                                                <table class="table" >
                                                    <thead>
                                                        <tr>
                                                            <th className='timing-th' scope="col">{item.day}</th>
                                                            <td>{item.day1}</td>
                                                            <td>{item.day2}</td>
                                                            <td>{item.day3}</td>
                                                            <td>{item.day4}</td>
                                                            <td>{item.day5}</td>
                                                            <td>{item.day6}</td>
                                                            <td>{item.day7}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">{item.time}</th>
                                                            <th scope="row">{item.time1}</th>
                                                            <th scope="row">{item.time2}</th>
                                                            <th scope="row">{item.time3}</th>
                                                            <th scope="row">{item.time4}</th>
                                                            <th scope="row">{item.time5}</th>
                                                            <th scope="row">{item.time6}</th>
                                                            <th scope="row">{item.time7}</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <h3 className='other-courses'>My other courses</h3>
                            </div>
                            <Grid className="all-featured d-flex">
                                {featured.map((card, index) => (
                                    <Grid item xs={4} className="mb20">
                                        <Item className="card feature-c" >
                                            <div lassName="card card_red feature">
                                                <div className="course1">
                                                    <img className="course1-img" src={Rectangle4467} />
                                                </div>
                                                <div className="viewdeatils text-center">
                                                    <button className="subject-name">Subject Name</button>
                                                </div>
                                                <div className="course text-left">
                                                    <div className="course-name"> <h2 className="course-head">{card.name} </h2></div>
                                                    <div className="count"> {card.open && <h2 className="course-head"> {card.count}</h2>}</div>

                                                </div>
                                                <div className="rating text-left">

                                                    <div className="d-flex align-base">
                                                        <div className="faGraduation-icon">
                                                            <FontAwesomeIcon icon={faGraduationCap} style={{ width: "15px" }} />
                                                        </div>
                                                        <div className="student">{card.count}students</div>
                                                        <div className='align-button'>
                                                            <button className='btn btn-primary button-1' >View</button>
                                                        </div>
                                                    </div>
                                                    <div className="FaBusinessTime-icon">
                                                        <FontAwesomeIcon icon={FaBusinessTime} /></div>

                                                    <div className="duration align-base d-flex">
                                                        <div className="faGraduation-icon">
                                                            <IoTimeSharp />
                                                        </div>
                                                        <div> Duration: </div>
                                                        <div> {card.duration} </div>
                                                    </div>
                                                    <div className="faGraduation-icon">
                                                        <FontAwesomeIcon icon={faGraduationCap} style={{ width: "15px" }} />students
                                                    </div>
                                                    <small class="text-muted-rating">
                                                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782", }} />
                                                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782", }} />
                                                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782", }} />
                                                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782", }} />
                                                        <FontAwesomeIcon icon={faStar} style={{ color: "#C7CFE2", }} />
                                                    </small>
                                                </div>

                                            </div>
                                        </Item>

                                    </Grid>
                                ))}
                            </Grid>

                            <div className='align-show'>
                                <button className='button-2'>show all</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
export default Viewprofile;
