import React from 'react'
import _ from "lodash";
import { SiFreelancer } from 'react-icons/si';

function FreelancersComponent(props) {
    const goToTTeacherWallet = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminFreelancer' })
        }
    }
    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'adminFreelancer':
            case 'adminFreelancerDetails':
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };
    return (
        <div
            className={determineClassName()}>
            <div className="dash d-flex">
                <SiFreelancer class='ms-3' />
                <p className='head-dashboard ms-2' onClick={(e) => goToTTeacherWallet(e)}> Freelancers
                </p>
            </div>
        </div>
    )
}

export default FreelancersComponent