import React, { useState, useEffect } from "react";
import _ from "lodash";
import "./Settings.scss";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  showErrorToast,
  showSuccessToast,
} from "./../../../../../../../util/toastUtils";
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";
function Settings({ ...props }) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [changePwd, setchangePwd] = useState({
    oldpassword: "",
    password: "",
    confirm_password: "",
  });
  const ChangePassword = () => {
    if (!localStorage.getItem("token")) {
    }
    const formData = new FormData();
    formData.append("oldpassword", changePwd.oldpassword);
    formData.append("password", changePwd.password);
    formData.append("confirm_password", changePwd.confirm_password);
    const headers = {
      Authorization: "Bearer " +  (localStorage.getItem("token")),
    };
    Axios
      .post(
       '/api/changePassword',
        formData,
        { headers: headers }
      )
      .then((res) => {
        if (res.data.success) {
          setchangePwd(res.data.data);
          showSuccessToast("password change successfully");
        }
      });
  };

  useEffect(() => {
    // Simulate loading delay with a setTimeout
    setTimeout(() => {
      setLoader(false); // Set Loader to false when data is loaded
    }, 500); // 2 seconds delay
  }, []);
  const handleChange = (e) => {
    setchangePwd({ ...changePwd, [e.target.name]: e.target.value });
  };
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: true,
  });

  const handleout = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const Backdashboard = () => {
    navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
  }

  return (
    <div>
      <div
        className="profile-main-head d-flex py-2"
        onClick={() => Backdashboard()}>
        <div className="fa-chaveron">
          <IoIosArrowBack className="mb-1" />{" "}
        </div>
        <div className="profile-head">Settings</div>
      </div>
      <div className="container ">
        <div className="row mt-4">
          <div className="col-2  ">
            <div className="admin-card  py-4 text-center">
              <p className=" settings-pwd text-center mt-3">ChangePassword</p>
              <hr className="diver-options ms-2 me-2" />
              <p className=" settings-pwd text-center mt-3">Notification</p>
              <hr className="diver-options ms-2 me-2" />
            </div>
          </div>
          <div className="col-8">
            <div className="admin-card p-4">
              <p className="fs-5 fw-semibold">Change Password</p>
              <div className="container text-start">
                <div className="row mt-4">
                  <div className="col">
                    <input
                      type="password"
                      id="old-Password"
                      name="oldpassword"
                      className="form-control pwd-label"
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="Current password"
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col">
                    <input
                      type="password"
                      id="newPassword"
                      name="password"
                      className="form-control pwd-label"
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
                      placeholder="New password"
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col">
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirm_password"
                      className="form-control pwd-label"
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="Confirm new password"
                    />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col">
                    <p className="pwd-require">Password requirements</p>
                    <p className="pwd-guide">
                      Please follow this guide for a strong passwords:
                    </p>
                    <ul>
                      <li>One special character</li>
                      <li>Min 6 characters</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container text-end mt-5">
                <div className="row">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-primary px-4 py-1 bootBtn my-5"
                      onClick={() => ChangePassword()}
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-card mt-4 py-4 text-start">
              <p className="fs-5 fw-semibold">Notification</p>
              <p className="settings-noti ms-3">
                Choose how you receive notifications. These notifications
                settings apply to the things you’re watching
              </p>
              <div className="row">
                <div className="col">
                  <p className="settings-noti-on ms-5">
                    Turn ON/OFF for notification
                  </p>
                </div>
                <div className="col">
                  <label class="switch">
                    <input type="checkbox" placeholder="on" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
