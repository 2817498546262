import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { AiFillEye, AiOutlineLeft } from 'react-icons/ai';
import { BsFiletypePdf } from 'react-icons/bs';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { currentFreelancerId, fetchFreelancerData, freelanceDocStatusChange, freelanceStatusChange, viewFreelance } from '../../../../../../redux/slice/freelanceSlice';
import { format } from 'date-fns';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { RotatingLines } from 'react-loader-spinner';
import { freelanceLoading } from '../../../../../../redux/slice/freelanceSlice';
function FreelancerDetailComponent(props) {

    const dispatch = useDispatch();
    const freelancerID = useSelector(currentFreelancerId);
    const data = useSelector(viewFreelance);
    const [doc, setDoc] = useState(false);
    const date = data?.created_at != undefined ? format(new Date(data?.created_at), 'dd-MM-yyyy') : "";
    const loading = useSelector(freelanceLoading);
    const initialValues = {
        status: 'Active',
    };

    const validationSchema = Yup.object().shape({
        status: Yup.string().required('Status is required'),
    });

    useEffect(() => {
        const payload = {
            freelancer_id: freelancerID
        };
        dispatch(fetchFreelancerData(payload));
    }, []);

    const DocValidationSchema = Yup.object().shape({
        checkbox1Checked: Yup.boolean().required('Please select an option'),
        checkbox2Checked: Yup.boolean().required('Please select an option'),
        checkbox3Checked: Yup.boolean().required('Please select an option'),
        checkbox4Checked: Yup.boolean().required('Please select an option'),
        checkbox5Checked: Yup.boolean().required('Please select an option'),
        checkbox6Checked: Yup.boolean().required('Please select an option'),
    });

    const handleCheckboxChange = (fieldName, setFieldValue, values) => (e) => {
        const value = e.target.checked;
        setFieldValue(fieldName, value);
        const pairNumber = parseInt(fieldName.match(/\d+/)[0]);
        const otherFieldName = `checkbox${pairNumber % 2 === 1 ? pairNumber + 1 : pairNumber - 1}Checked`;
        setFieldValue(otherFieldName, !value);
    };

    const goToTTeacherWallet = (e) => {
        if (doc) {
            setDoc(false);
        }
        else if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminFreelancer' });
        }
    }

    return (
        <div >
            <div class='main-divHead'>
                <div className='sub-Head1' >
                    <div className="Sub-Head2" onClick={(e) => goToTTeacherWallet(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>Freelancer</div>
                </div>
                {!doc ?
                    <div class='mx-5'>
                        <div class="container text-start">
                            <div className='row'>
                                <div class="d-flex justify-content-center"><div className='col-xxl-8 col-xl-8 col-lg-11'>
                                    <p class='admin-sub-head my-5 '>Freelancer Details</p>
                                </div></div>
                            </div>
                            <div class="row">
                                <div class='d-flex justify-content-center'>
                                    <div class="col-xxl-8 col-xl-8 col-lg-11">
                                        <div class='adminSubCard p-3'>
                                            <div class="container text-start">
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Date : </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <p class='fw-semibold fs-5'>{date}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row  ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Freelancer Name :  </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <p class='fw-semibold fs-5'>{data?.firstname}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row  ms-3 ">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Email : </p>
                                                        </div>
                                                        <div class="col-5 overflow-y-auto">
                                                            <p class='fw-semibold fs-5'>{data?.email}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Phone : </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <p class='fw-semibold fs-5'>{data?.phone_number}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Country : </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <span class=''> <p class='fw-semibold fs-5'>{data?.country}</p></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Category :  </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <span class=''> <p class='fw-semibold fs-5'>{data?.category}</p></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Sub Category :  </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <span class=''> <p class='fw-semibold fs-5'>{data?.sub_category}</p></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Topic :  </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <p class='fw-semibold fs-5'><span class=''>{data?.topic}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Qualification :  </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <p class='fw-semibold fs-5'><span class=''>{data?.education}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Experience :  </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <p class='fw-semibold fs-5'><span class=''>{data?.teaching_background}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Status : </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <p class='fw-semibold fs-5'>{data?.status === "1" ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row ms-3">
                                                    <div class='d-flex justify-content-between'>
                                                        <div class="col">
                                                            <p class='fw-semibold fs-5'>Supporting Documents :  </p>
                                                        </div>
                                                        <div class="col-5">
                                                            <p class='fw-semibold fs-5'><span class='text-success fw-bold'>Approved</span><span><button type="button" class="btn btn-outline adminOPbtn ms-xxl-4 ms-xl-4 ms-lg-2 px-xxl-4 px-xl-4 px-lg-2" onClick={(e) => setDoc(true)}  >View <span class='ms-2'><FaArrowRightLong /></span></button></span></p>
                                                        </div>
                                                        {/* <div class="col-5">
                                                            <p class='fw-semibold fs-5'><span class='text-success fw-bold'>Approved</span> <span><button type="button" class="btn btn-outline adminOPbtn ms-xxl-4 ms-xl-4 ms-lg-2 px-xxl-4 px-xl-4 px-lg-2" onClick={(e) => setDoc(true)}  >View <span class='ms-2'><FaArrowRightLong /></span></button></span></p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="row my-4 ">
                                <div class='d-flex justify-content-end '>
                                    <div class='col-1 mt-1'><p class='fw-semibold fs-5'>Status : </p> </div>
                                    <div class='col-2'>
                                        <div className="form-group ">
                                            <select className="form-select" id="exampleDropdown">
                                                <option value="Active">Active</option>
                                                <option value="option1">DeActive</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class='col-2'></div>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                const payload = {
                                    "freelancer_id": freelancerID,
                                    "status": values.status == 'Active' ? "1" : "0",
                                }
                                const viewFree = {
                                    "freelancer_id": freelancerID
                                }
                                const valueToSend = {
                                    payload,
                                    viewFree
                                }
                                dispatch(freelanceStatusChange(valueToSend));
                                resetForm();
                            }} >
                            <Form>
                                <div class="container ">
                                    <div class="row my-4 ">
                                        <div class="container ">
                                            <div class="row ms-lg-5 ms-xxl-0 ms-xl-0 ps-lg-5 ps-xxl-0 ps-xl-0">
                                                <div class="col-10 ms-5">
                                                    <div class='d-flex justify-content-end '>
                                                        <div class="col-xxl-1 col-xl-1 col-lg-2 mt-1">
                                                            <p className='admin-sub-head-card mb-0 mt-1'>Status:</p></div>
                                                        <div class="col-xxl-3 col-xl-3 col-lg-5">
                                                            <div className="form-group pe-xxl-5 pe-xl-5 pe-lg-0">
                                                                <Field as="select" name="status" className="form-select" id="exampleDropdown">
                                                                    <option value="Active">Active</option>
                                                                    <option value="DeActive">Inactive</option>
                                                                </Field>
                                                                <ErrorMessage name="status" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container text-center">
                                    <div class="row">
                                        <div class="col">
                                            <button type="submit" disabled={loading} className="btn btn-primary text-light px-4 py-1 bootBtn my-5">
                                                {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="25"
                                                    height="25"
                                                    visible={true}
                                                /></div> : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div> : <>
                        <Formik
                            initialValues={{
                                checkbox1Checked: true,
                                checkbox2Checked: false,
                                checkbox3Checked: true,
                                checkbox4Checked: false,
                                checkbox5Checked: true,
                                checkbox6Checked: false,
                            }}
                            validationSchema={DocValidationSchema}
                            onSubmit={(values) => {
                                if (values.checkbox1Checked && values.checkbox3Checked && values.checkbox5Checked) {
                                    const valueToSend = {
                                        "freelancer_id": freelancerID,
                                        "status": 1
                                    }
                                    dispatch(freelanceDocStatusChange(valueToSend));
                                }
                                else {
                                    const valueToSend = {
                                        "freelancer_id": freelancerID,
                                        "status": 0
                                    }
                                    dispatch(freelanceDocStatusChange(valueToSend));
                                }
                            }}>
                            {({ handleSubmit, setFieldValue, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='main-divHead'>
                                        {/* <div className='sub-Head1'>
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                        <div className='Sub-Head2 ms-5'>Teachers</div>
                                    </div> */}
                                        <div className='mx-5'>
                                            <p className='adminSubHead my-3 fw-semibold'>Supporting Documents</p>
                                            <div className='container text-center my-5'>
                                                <div className='container text-center'>
                                                    {Array.from({ length: 3 }, (_, index) => (
                                                        <div className='row' key={index}>
                                                            <div className='d-flex justify-content-center'>
                                                                <div className='col-1'>
                                                                    <BsFiletypePdf className='adminPdfIcon' />
                                                                </div>
                                                                <div className='col-xxl-2 col-xl-2 col-lg-4'>
                                                                    <p className='fs-6'>educationdocument.pdf</p>
                                                                </div>
                                                                <div className='col-1 ms-5'>
                                                                    <div className='admin-card-eye'>
                                                                        <AiFillEye className='adminEyeIcon' />
                                                                    </div>
                                                                </div>
                                                                <div className='col-1'>
                                                                    <div className='form-check'>
                                                                        <Field
                                                                            type='checkbox'
                                                                            name={`checkbox${index * 2 + 1}Checked`}
                                                                            className={`form-check-input ${values[`checkbox${index * 2 + 1}Checked`] ? 'bg-success' : ''} border-success`}
                                                                            onChange={handleCheckboxChange(
                                                                                `checkbox${index * 2 + 1}Checked`,
                                                                                setFieldValue,
                                                                                values
                                                                            )}
                                                                        />
                                                                        <Field
                                                                            type='checkbox'
                                                                            name={`checkbox${index * 2 + 2}Checked`}
                                                                            className={`form-check-input ms-3 ${values[`checkbox${index * 2 + 2}Checked`] ? 'bg-danger' : ''}  border-danger`}
                                                                            onChange={handleCheckboxChange(
                                                                                `checkbox${index * 2 + 2}Checked`,
                                                                                setFieldValue,
                                                                                values
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage
                                                                        name={`checkbox${index * 2 + 1}Checked`}
                                                                        component='div'
                                                                        className='error-message'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='container text-center'>
                                                <div className='row ms-5'>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='col-xxl-3 col-xl-3 col-lg-5'>
                                                            <button type='button' className='btn btn-outline adminOPbtn my-5 px-4'>
                                                                Ask Clarification
                                                            </button>
                                                        </div>
                                                        <div className='col-xxl-3 col-xl-3 col-lg-4'>
                                                            <button type='submit' className='btn text-light px-5 py-1 bootBtn my-5'>
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </>}
            </div>
        </div>
    )
}

export default FreelancerDetailComponent