import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import "./style/style.scss"
import SignInComponent from "./SignIn.component"
import img from '../../images/signup.jpg'


function LogPage() {

  const navigate= useNavigate();
  const [showSignIn, setShowSignIn] = useState(false)

  const [showSignUp, setShowSignUp] = useState(false)
  const [showRegisterPage, setShowRegisterPage] = useState(false)

  const [logPurpose, setLogPurpose] = useState("")

  const { params } = useParams()

  useEffect(() => {
    console.log(window.location.href)
    const locationSplit = window.location.href.split("/")
    const presentPurpose = locationSplit[locationSplit.length - 1]
    console.log(presentPurpose)
    setLogPurpose(presentPurpose)
  }, [window.location.href])

  useEffect(() => {
    if (logPurpose === "login") {
      setShowRegisterPage(false)
      setShowSignUp(false)
      setShowSignIn(true)
    }
    if (logPurpose === "sign_up") {
      setShowSignIn(false)
      setShowRegisterPage(false)
      setShowSignUp(true)
    }
    if (logPurpose === "sign_in") {

      setShowSignIn(true)
      setShowRegisterPage(false)
      setShowSignUp(false)
    }
    if (logPurpose === "register") {

      setShowSignIn(false)
      setShowSignUp(false)
      setShowRegisterPage(true)
    }
    if (logPurpose === "Navbar") {

      setShowSignIn(false)
      setShowSignUp(false)
      setShowRegisterPage(true)
    }
  }, [logPurpose])

  const registerPage = () => {
    setShowSignUp(false)
    setShowRegisterPage(true)
  }

  return (
   
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-none d-sm-block">
            <img src={img} alt="Login image" className="img-fluid w-100 vh-100" />
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <SignInComponent />
          </div>
        </div>
      </div>
    </>

  )
}

export default LogPage
