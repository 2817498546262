
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveTeacherID, fetchTeacherList, teacherList } from '../../../../../../redux/slice/teacherSlice';
import { format } from 'date-fns';
import CSTComponent from './CST.component';

function AdminTeacherComponent(props) {

    const dispatch = useDispatch();
    const TeacherData = useSelector(teacherList);

    useEffect(() => {
        dispatch(fetchTeacherList());
    }, []);


    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    function handleClick(row) {
        goToAdminTeacherDetails();
        dispatch(ActiveTeacherID(row.id));
    }




    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontWeight: "bold"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.created_at ? dateFunction(row.created_at) : ""}
                    </div>
                );
            }
        },
        {
            name: 'Teachers Username',
            selector: (row) => {
                const emailParts = row.email ? row.email.split('@') : ['', ''];
                return emailParts[0];
            },
        },
        {
            name: ' Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row && row?.status === 1 ? <span className='text-success fw-bold'>Active</span> : <span className='text-danger fw-bold'>Inactive</span>}
                    </div>
                )
            }
        },

        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <button type="button" class="btn text-light px-xxl-3 px-xl-3 px-lg-3 px-md-2 py-1 bootBtn" onClick={() => handleClick(row)}>Details</button>
                </div>
            )
        }
    ];




    const goToAdminTeacherDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminTeacherDetails' })
        }
    }


    const [filterText, setFilterText] = useState('');
    const filteredData = TeacherData.filter(item => {
        if (item.created_at) {
            const date = new Date(item.created_at);
            return date.getMonth() >= 0 && item.created_at.toLowerCase().includes(filterText.toLowerCase());
        }
        return false;
    });

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >
                <div className='Sub-Head2'>Teachers</div>
            </div>
            <div>
                <div class="container text-start my-5">
                    <div class="row mt-5">
                        {/* <CSTComponent /> */}
                    </div>
                    <div className="row">
                        <div className='col'>
                            <div className="form-group">
                                <label htmlFor="exampleDropdown" className="fs-5 fw-semibold">Date</label><br />
                                <input
                                    type='date'
                                    className='admin-dropdown_date'
                                    onChange={(e) => setFilterText(e.target.value)}
                                    value={filterText}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <DataTable
                            className='dataTable mt-5 '
                            data={filteredData}
                            columns={columns}
                            fixedHeader={true}
                            fixedHeaderScrollHeight="600px"
                            striped
                            customStyles={customStyles}
                            persistTableHead
                            pagination

                        />
                    </div>


                </div>
            </div>
        </div>
    )
}

export default AdminTeacherComponent