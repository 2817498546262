import React, { useState, useEffect } from 'react';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import axios from 'axios';
import './TeacherBankDetails.scss'

function TeacherBankDetails(props) {
    const [formData, setFormData] = useState({
        bank_name: '',
        branch_name: '',
        firstname: '',
        lastname: '',
        account_number: '',
        zipcode: '',
        country: '',
        currency: '',
        state: ''
    });

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'requestwithdrawl' });
        }
    }

    const goToTeacherWithdrawl = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'requestwithdrawl' });
        }
    }

    useEffect(() => {
        // Fetch and populate the formData based on the ID prop
        const id = _.get(props, 'userState.id');
        if (id) {
            axios.get(`http://your-api-url.com/your-endpoint/${id}`)
                .then((response) => {
                    const data = response.data;
                    setFormData(data); // Set the form data based on the fetched data
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [props.userState.id]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleUpdate = () => {
        // Send a PUT request to update the data using formData
        const id = _.get(props, 'userState.id');
        if (id) {
            axios.post(`http://your-api-url.com/your-endpoint/${id}`, formData)
                .then((response) => {
                    // Handle the successful update
                })
                .catch((error) => {
                    console.error('Error updating data:', error);
                });
        }
    };


    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Edit Bank Account Details</div>
            </div>

            <div className='inp-bank-detail'>
                <label id="head1-bank-detail">Bank Name</label><br />
                <select id="inp-select-details">
                    <option>Demo</option>
                    <option>Demo</option>
                </select>
            </div>
            <div className='inp-bank-detail2'>
                <label id="head1-bank-detail">Branch Name</label><br />
                <select id="inp-select-details">
                    <option>Demo</option>
                    <option>Demo</option>
                </select>
            </div>

            <div className='inp-bank-detail'>
                <label id="head1-bank-detail">First Name</label><br />
                <input type='text' id="inp-select-details" />
            </div>

            <div className='inp-bank-detail2'>
                <label id="head1-bank-detail">Last Name</label><br />
                <input type='text' id="inp-select-details" />
            </div>


            <div className='inp-bank-detail'>
                <label id="head1-bank-detail">Branch Number</label><br />
                <input type='text' id="inp-select-details" />
            </div>

            <div className='inp-bank-detail2'>
                <label id="head1-bank-detail">Zip Code</label><br />
                <input type='text' id="inp-select-details" />
            </div>
            <div className='inp-bank-detail'>
                <label id="head1-bank-detail">Country</label><br />
                <select id="inp-select-details">
                    <option>Demo</option>
                    <option>Demo</option>
                </select>
            </div>
            <div className='inp-bank-detail2'>
                <label id="head1-bank-detail">State</label><br />
                <select id="inp-select-details">
                    <option>Demo</option>
                    <option>Demo</option>
                </select>
            </div>
            <div className='inp-bank-detail3'>
                <label id="head1-bank-detail">Banke Account Currfghfghfghfghfgency</label><br />
                <select id="inp-select-details">
                    <option>Demo</option>
                    <option>Demo</option>
                </select>
            </div>
            <button className='btn-update' onClick={(e) => goToTeacherWithdrawl(e)}>Update</button>

        </div>
    )
}

export default TeacherBankDetails