import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewIcon from '../../../Sidebar/icons/ViewIcon.png'
import axios from 'axios'
import './TeacherStudentAssignList.scss'
import DataTable from 'react-data-table-component';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function TeacherStudentAssignList(props) {
    const [view, setView] = useState()

    const ViewBatch = props.userState.row.batch_id || {};
    const [viewAssignment, setViewAssignment] = useState([{
        email: " ",
        category: " ",
        sub_category: '',
        ending_at: '',
        topic: '',
        batch_id: '',
        students: [],
    }])

    const fetchstudentlist = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const formData = new FormData();
        formData.append("batch_id", ViewBatch)
        Axios.post('/api/viewAssignment', formData, { headers: headers })
            .then((response) => {
                const { students, ...assignmentData } = response.data.data;
                setViewAssignment({
                    ...assignmentData,
                    students: Object.entries(students).map(([studentId, studentName]) => ({ student_id: studentId, student_name: studentName })),
                });
            })
            .catch((error) => {
                console.error("Error fetching view schedule data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });
    }

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])
    const initializeObject = () => {
        setViewAssignment({ ...viewAssignment, ...props.userState.row })
    }

    useEffect(() => {
        fetchstudentlist()
    }, [])

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherassignment' })
        }
    }
    const goToTeacherViewStudentAssignment = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewstudentassignment' })
        }
    }

    const goToTeacherViewIncomplete = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewstudentIncompleteassignment' })
        }
    }
    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Student Id',
            selector: 'student_id',
            sortable: true,
        },
        {
            name: 'Student Name',
            selector: 'student_name',
            sortable: true,
        },
        {
            name: 'Status',
            selector: 'status', // Assuming there is a 'status' property in your data
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row,batch_id) => (
                <img
                    src={ViewIcon}
                    className='view-assignment'
                    alt="viewicon"
                    onClick={(e) => goToTeacherViewStudentAssignment(e,row,batch_id)}
                />
            ),
        },
    ];

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Assignments</div>
            </div>

            <div className='view-student-assign'>Student List</div>

            <div class="container text-start teacher-cardss">
                <div class="card col-12">
                    <div class="card-body ">
                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
                                    <p className='techer-card-typo '>Category : </p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
                                    <p className='techer-card-typo ' >{viewAssignment?.category}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo '>Sub Category : </p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo ' >{viewAssignment?.sub_category}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo '>Batch : </p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo ' >{viewAssignment?.batch_id}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo '>Topic : </p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo ' >{viewAssignment?.topic}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo '>End Date : </p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
                                    <p className='techer-card-typo ' >
                                        {new Date(viewAssignment.ending_at).toLocaleDateString('en-GB')}
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>

            <div class="d-flex justify-content-center">
                <div class="col-xxl-8   col-xl-10 col-lg-12   justify-content-center">
                    <div class="table-responsive">
                        <DataTable
                            className='dataTable'
                            data={viewAssignment?.students}
                            columns={columns}
                            customStyles={customStyles}
                            striped
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeacherStudentAssignList