import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import './CreateTeacherCoupon.scss'
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, useFormik } from 'formik';
import { showErrorToast, showSuccessToast } from "../../../../../../util/toastUtils"
import { InputGroup } from 'react-bootstrap';
import Select from "react-select";
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';


function CreateTeacherCoupon(props) {
    const [showSelect, setShowSelect] = useState(false);
    const [showCourseSelection, setShowCourseSelection] = useState(false);
    const [showStudent, setShowStudent] = useState(false);
    const [edit, setEdit] = useState()
    const [errors, setErrors] = useState([""]);
    const Subjectoption = ['1', '2', '3',]
    const Studentoption = ['1', '2', '3', '4']

    const TopicData = useSelector(topicData);
    const TopicOptionsList = TopicData.map((e) => ({
        value: e.id,
        label: e.name
    }));

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const [loading, setLoading] = useState(true)
    const [subject, setSubject] = useState([{}])
    const [studentList, setStudentList] = useState([{}]);
    const [batches, setBatches] = useState([])
    const [totalList, setTotalList] = useState([]);
    const convertedData = studentList.map(student => ({
        label: student.firstname,
        value: student.student_id
    }));

    useEffect(() => {
        listSubject()
        listStudents()
        listBatches()
    }, [])

    const listSubject = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }
        const formData = new FormData();
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/listSubject', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubjectObject = response.data.data;
                    const SubjectArray = Object.values(SubjectObject);
                    setSubject(SubjectArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching subject:', error);
                setLoading(false);
            });
    };
    const listBatches = (subjectId) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }

        const formData = new FormData();
        formData.append('subject_id', subjectId || 0);
        const headers = { Authorization: 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/listBatch', formData, { headers: headers })
            .then((response) => {
                if (response.data && response.data.data) {
                    const BatchObject = response.data.data.batch;  // Adjusted this to access correct nested object
                    const BatchArray = Object.values(BatchObject);
                    setBatches(BatchArray);

                    if (BatchArray.length > 0) {
                        listStudents(BatchArray[0].id);  // Ensure listStudents is defined elsewhere
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching batches:', error);
                setLoading(false);
            });
    };




    const listStudents = (batchId) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const formData = new FormData();
        formData.append('id', batchId || 0);
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/listBatchStudents', formData, { headers: headers })
            .then((response) => {
                if (response.data.data) {
                    const studentListData = response.data.data;

                    // Convert the object of student names and IDs into an array of objects
                    const studentListArray = Object.keys(studentListData).map(studentName => ({
                        value: studentListData[studentName],
                        label: studentName,
                    }));
                    setStudentList(studentListArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching students:', error);
                setLoading(false);
            });
    };

    const handleMultipleChange = (selectList) => {
        console.log(selectList);
        setTotalList(selectList || []);
    };

    const CouponValidationSchema = Yup.object().shape({
        student_id: Yup.string().required('student is required'),
        subjects: Yup.string().required('course is required'),
        value: Yup.string().required('discount amount is required'),
        offer_name: Yup.string().required('offer name is required'),
        code: Yup.string().required('code is required'),
        limit: Yup.string().required('limit is required'),
        start_date: Yup.string().required('start date is required'),
        end_date: Yup.string().required('end date is required'),



    });

    const [coupon, setCoupon] = useState({
        coupon_type: '',
        subject_all: '',
        subjects: '',
        discount_type: '',
        value: '',
        offer_name: '',
        code: '',
        limit: '',
        start_date: '',
        end_date: '',
        status: '',
        student_id: ''
    })

    const [couponType, setCouponType] = useState(coupon.coupon_type);
    const [discountType, setDiscountType] = useState(coupon.discount_type);
    const [subjectType, setSubjectType] = useState(coupon.subject_all);


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === "batch_id") {
            // Update the student list based on the selected batch
            listStudents(value);
        }

        if (type === 'checkbox') {
            if (name === 'coupon_type') {
                setCoupon({ ...coupon, coupon_type: checked ? parseInt(value) : '' });
                setCouponType(checked ? parseInt(value) : '');
                if (checked && value === '0') {
                    setShowStudent(true); // Show student when "Private Coupon" is checked
                } else {
                    setShowStudent(false); // Hide student otherwise
                }
            }
            else if (name === 'discount_type') {
                setCoupon({ ...coupon, discount_type: checked ? parseInt(value) : '' });
                setDiscountType(checked ? parseInt(value) : '');
            }
            else if (name === 'subject_all') {
                setCoupon({ ...coupon, subject_all: checked ? parseInt(value) : '' });
                setSubjectType(checked ? parseInt(value) : '');
                if (checked && value === '0') {
                    setShowCourseSelection(true); // Show student when "Private Coupon" is checked
                } else {
                    setShowCourseSelection(false); // Hide student otherwise
                }
            }
        } else {
            if (name === 'code') {
                if (value.length < 6 || value.length > 12) {
                    setErrors({ ...errors, [name]: "Coupon code must be between 6 and 12 characters." });
                } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    setErrors({ ...errors, [name]: "Coupon code must only contain letters and numbers." });
                } else {
                    setErrors({ ...errors, [name]: "" });
                }
            }
            else if (name === 'offer_name') {
                if (value.length < 3 || value.length > 50) {
                    setErrors({ ...errors, [name]: "Offer name must be between 3 and 50 characters." });
                } else {
                    setErrors({ ...errors, [name]: "" });
                }
            } else if (name === 'value') {
                // Validate discount percentage
                const discountValue = parseInt(value);
                if (isNaN(discountValue)) {
                    setErrors({ ...errors, [name]: "Discount amount must be a number." });
                } else if (discountValue < 0 || discountValue > 20) {
                    setErrors({ ...errors, [name]: "Discount percentage must be between 0 and 20." });
                } else {
                    setErrors({ ...errors, [name]: "" });
                }
            }
            setCoupon({ ...coupon, [name]: value });
        }
    };


    const statusText = coupon.status === '1' ? 'Active' : 'Inactive';

    const statusClassName = coupon.status === '1' ? 'active-status' : 'inactive-status';

    const ToSubmit = () => {
        const missingFields = [];

        if (!coupon.offer_name) {
            missingFields.push("Offer Coupon")
        }
        if (!coupon.code) {
            missingFields.push("Coupon Code")
        }
        if (!coupon.start_date) {
            missingFields.push("Start Date")
        }
        if (!coupon.end_date) {
            missingFields.push("End Date")
        }
        if (!coupon.limit) {
            missingFields.push("Coupon Limit")
        }

        if (coupon.code.length < 6 || coupon.code.length > 12) {
            alert("Coupon code must be between 6 and 12 characters.")
            return
        }
        if (coupon.offer_name.length < 3 || coupon.offer_name.length > 50) {
            alert("Coupon Name must be between 3 to 15 characters.")
            return
        }
        if (!/^[a-zA-Z0-9]+$/.test(coupon.code)) {
            alert("Coupon code must only contain letters and numbers.")
            return
        }
        if (missingFields.length > 0) {
            const missingFieldsText = missingFields.join(', ')
            alert(` ${missingFieldsText} is missing`)
            return;
        }

        const createCoupon = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append('email', _.get(createCoupon, 'data.email'))
        formData.append('coupon_type', coupon.coupon_type)
        formData.append('subject_all', coupon.subject_all)
    
        formData.append('subjects[]', coupon.subjects);
       
        formData.append('discount_type', coupon.discount_type)
        formData.append('value', coupon.value)
        formData.append('offer_name', coupon.offer_name)
        formData.append('code', coupon.code)
        formData.append('limit', coupon.limit)
        formData.append('start_date', coupon.start_date)
        formData.append('end_date', coupon.end_date)
        formData.append('status', coupon.status)

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/createCoupon',
            formData,
            { headers: headers })
            .then((res) => {
                if (res.data.success) {
                    const createData = _.get(res, 'data.data.createData') ? JSON.stringify(res.data.data.createData) : undefined;
                    const token = _.get(res, 'data.data.token') ? JSON.stringify(res.data.data.token) : undefined;
                    if (createData) {
                        localStorage.setItem("createData", JSON.stringify(res.data.data.createData))
                    }
                    if (token) {
                        localStorage.setItem("token", (res.data.data.token));
                    }
                    Backdashboard()
                    showSuccessToast("Coupon created successfully");
                }
                console.log(res.data)
            })
    }



    const ToEdit = () => {
        const missingFields = [];

        if (!coupon.offer_name) {
            missingFields.push("Offer Coupon")
        }
        if (!coupon.code) {
            missingFields.push("Coupon Code")
        }
        if (!coupon.start_date) {
            missingFields.push("Start Date")
        }
        if (!coupon.end_date) {
            missingFields.push("End Date")
        }
        if (!coupon.limit) {
            missingFields.push("Coupon Limit")
        }

        if (coupon.code.length < 6 || coupon.code.length > 12) {
            alert("Coupon code must be between 6 and 12 characters.")
            return
        }
        if (coupon.offer_name.length < 3 || coupon.offer_name.length > 50) {
            alert("Coupon Name must be between 3 to 15 characters.")
            return
        }
        if (!/^[a-zA-Z0-9]+$/.test(coupon.code)) {
            alert("Coupon code must only contain letters and numbers.")
            return
        }
        if (missingFields.length > 0) {
            const missingFieldsText = missingFields.join(', ')
            alert(` ${missingFieldsText} is missing`)
            return;
        }

        const createCoupon = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append('email', _.get(createCoupon, 'data.email'))
        formData.append('coupon_type', coupon.coupon_type)
        formData.append('subject_all', coupon.subject_all)
        formData.append('subjects[]', coupon.subjects)
        formData.append('discount_type', coupon.discount_type)
        formData.append('value', coupon.value)
        formData.append('offer_name', coupon.offer_name)
        formData.append('code', coupon.code)
        formData.append('limit', coupon.limit)
        formData.append('start_date', coupon.start_date)
        formData.append('end_date', coupon.end_date)
        formData.append('status', coupon.status)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/editCoupon',
            formData,
            { headers: headers })
            .then((res) => {
                console.log(res)
                const updateData = res.data
                setEdit(updateData)
                Backdashboard()
                showSuccessToast("coupon Edit successfully");
            })
    }

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])

    const initializeObject = () => {
        setCoupon({ ...coupon, ...props.userState.row })
        setCouponType(props.userState.row.coupon_type)
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachersubject' })
        }
    }

    return (
        <div className='main-divHead' >

            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Coupon</div>
            </div>
            <div className='container mt-3'>
                <div className="row">
                    <div className='col-12'>
                        {!_.get(props, 'userState.row') && <div className='main-schedule-head ms-5' ><p className='Title-main-head'>Create coupon</p></div>}
                        {_.get(props, 'userState.row') && <div className='main-schedule-head ml-2'><p className='Title-main-head'>Edit coupon</p></div>}
                    </div>
                </div>
                <Formik
                    initialValues={{
                        student_id: '',
                        subjects: '',
                        value: '',
                        offer_name: '',
                        code: '',
                        limit: '',
                        start_date: '',
                        end_date: '',
                    }}
                    validationSchema={CouponValidationSchema}
                    onSubmit={(values, { resetForm }) => {

                        ToSubmit();
                        resetForm();

                    }}
                >

                    {({ errors, touched, setFieldValue }) => (
                        <Form>
                            <div className='MainDiv-coupon'>
                                <div className='row ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-3 ms-sm-0'>
                                    <div className='col ms-5'>
                                        <p className='title-head-coupon ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-3 ms-sm-0 mt-5'>Course Selection</p> <br />
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-3 ">
                                        {!_.get(props, 'userState.row') &&
                                            <input type="checkbox"
                                                id="course-1"
                                                className='ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0'
                                                value={1}
                                                name='subject_all'
                                                checked={subjectType === 1}
                                                onChange={handleChange} />}

                                        {!_.get(props, 'userState.row') && <label className='ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-sm-3' id="label-coupon">Assign to all course</label>}

                                        {_.get(props, 'userState.row') &&
                                            <input type="checkbox"
                                                id="course-1"
                                                value={1}
                                                className='ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0'
                                                name='subject_all'
                                                checked={subjectType === 1}
                                                onChange={handleChange} />}

                                        {_.get(props, 'userState.row') && <label className='ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-sm-3' id="label-coupon">Assign to all course</label>}
                                    </div>

                                    <div class="col-xxl-0 col-xl-0 col-lg-6 col-md-12 col-sm-12">
                                        {!_.get(props, 'userState.row')
                                            && <input type="checkbox" id="course-2"
                                                value={0}
                                                className='ms-md-4 ms-sm-4'
                                                name='subject_all'
                                                checked={subjectType === 0}
                                                onChange={handleChange} />}

                                        {!_.get(props, 'userState.row') && <label className='ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-2 ms-sm-2' id="label-coupon">Assign to specific course</label>}
                                        {_.get(props, 'userState.row') && <input type="checkbox" id="course-2" value={0} name='subject_all' checked={subjectType === 0} onChange={handleChange} />}
                                        {_.get(props, 'userState.row') && <label className='ms-3' id="label-coupon">Assign to specific course</label>}
                                    </div>

                                </div>
                                {showCourseSelection && (
                                    <div class="row ms-5">
                                        <div class="col-4 ms-5 mt-5 mb-2 subtitle-headings">
                                            {!_.get(props, 'userState.row') && <p class='ms-5'>Choose Course</p>}<br />
                                            {!_.get(props, 'userState.row') &&
                                                <Field
                                                    className="form-select ms-5"
                                                    as="select"
                                                    value={coupon.subjects}
                                                    name='subjects'
                                                    onChange={(e) => {
                                                        const selectedSubjectId = parseInt(e.target.value);
                                                        setFieldValue("subjects", selectedSubjectId)
                                                        listStudents(selectedSubjectId);
                                                        handleChange(e)
                                                    }}
                                                    onClick={listSubject}
                                                >
                                                    <option value="">--select an option--</option>
                                                    {subject.map((subject, index) => (
                                                        <option key={index} value={subject.id}>
                                                            {subject.name}
                                                        </option>
                                                    ))}
                                                </Field>}

                                            {!_.get(props, 'userState.row') && <ErrorMessage
                                                name="subjects"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />}

                                            {_.get(props, 'userState.row') && <p id='label-coupon2'>Course Selection</p>}<br />
                                            {_.get(props, 'userState.row') &&
                                                <Field
                                                    className="form-select ms-5"
                                                    as="select"
                                                    value={coupon.subjects}
                                                    name='subjects'
                                                    onChange={(e) => {
                                                        const selectedSubjectId = parseInt(e.target.value, 10);
                                                        setFieldValue("subjects", selectedSubjectId)
                                                        listStudents(selectedSubjectId);
                                                        handleChange(e)

                                                    }}
                                                    onClick={listSubject}
                                                >
                                                    <option value="">--select an option--</option>
                                                    {subject.map((subject, index) => (
                                                        <option key={index} value={subject.id}>
                                                            {subject.name}
                                                        </option>
                                                    ))}
                                                </Field>}

                                            {_.get(props, 'userState.row') && <ErrorMessage
                                                name="subjects"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />}


                                        </div>
                                    </div>
                                )}

                                <div className='row ms-5'>
                                    <div className='col ms-5'>
                                        <p className='title-head-coupon ms-5 mt-5'>Coupon Type</p> <br />
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-3">

                                        {!_.get(props, 'userState.row') && <input
                                            type="checkbox"
                                            id="coupon-1"
                                            value={1}
                                            name='coupon_type'
                                            onChange={handleChange}
                                            checked={couponType === 1}
                                        />}

                                        {_.get(props, 'userState.row') && <input
                                            type="checkbox"
                                            id="coupon-1"
                                            value={1}
                                            name='coupon_type'
                                            onChange={handleChange}
                                            checked={couponType === 1}
                                            readOnly
                                        />}

                                        {!_.get(props, 'userState.row') && <label className='ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-sm-3' id="label-coupon mt-4 ">Public Coupon</label>}

                                        {_.get(props, 'userState.row') && <label className='ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-sm-3' id="label-coupon mt-4">Public Coupon</label>}
                                    </div>
                                    <div class="col-xxl-0 col-xl-0 col-lg-6 col-md-12 col-sm-12">
                                        {!_.get(props, 'userState.row') && <input
                                            type="checkbox"
                                            id="coupon-2"
                                            value={0}
                                            className='ms-md-3 ms-sm-0'
                                            name='coupon_type'
                                            onChange={handleChange}
                                            checked={couponType === 0}
                                            readOnly
                                        />}

                                        {_.get(props, 'userState.row') && <input
                                            type="checkbox"
                                            id="coupon-2"
                                            value={0}
                                            name='coupon_type'
                                            onChange={handleChange}
                                            checked={couponType === 0}
                                        />}
                                        {!_.get(props, 'userState.row') && <label className='ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-sm-3' id="label-coupon mt-4">Private Coupon</label>}
                                        {_.get(props, 'userState.row') && <label id="label-coupon">Private Coupon</label>}
                                    </div>

                                </div>
                                {showStudent && (
                                    <div className="row ms-5">
                                        {!_.get(props, 'userState.row') && (
                                            <div className='col-4 ms-5 mt-5 subtitle-headings'>
                                                <p className='ms-5'>Select Batch</p><br />
                                                <Field
                                                    className="form-select ms-5"
                                                    as="select"
                                                    name="batch_id"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        const selectedBatchId = e.target.value;
                                                        listBatches(selectedBatchId);
                                                        setFieldValue("batch_id", e.target.value);
                                                    }}
                                                >
                                                    <option value="">Select an option</option>
                                                    {batches.map((batch, index) => (
                                                        <option key={index} value={batch.id}>
                                                            {batch.name}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                        )}

                                        <div className="col-3 ms-5 mt-5 subtitle-headings">
                                            <div>
                                                <p className='ms-5'>Select Student</p><br />
                                                {!_.get(props, 'userState.row') && (
                                                    <InputGroup>
                                                        <Select
                                                            value={totalList}
                                                            onChange={handleMultipleChange}
                                                            options={studentList.map(student => ({ label: student.name, value: student.id }))}
                                                            className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                            isMulti
                                                        />
                                                    </InputGroup>
                                                )}

                                                {_.get(props, 'userState.row') && (
                                                    <InputGroup>
                                                        <Select
                                                            value={totalList}
                                                            onChange={handleMultipleChange}
                                                            options={studentList.map(student => ({ label: student.name, value: student.id }))}
                                                            className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                            isMulti
                                                        />
                                                    </InputGroup>
                                                )}

                                                <ErrorMessage
                                                    name="student_id"
                                                    component="div"
                                                    className="text-danger ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='row ms-5'>
                                    <div className='col ms-5'>
                                        <p className='title-head-coupon ms-5 mt-5'>Discount Type And Amount</p> <br />
                                    </div>
                                </div>

                                <div class="row text-center">

                                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-3">
                                        {!_.get(props, 'userState.row') && <input type="checkbox" id="discount-2" value={0} name='discount_type' checked={discountType === 0} onChange={handleChange} />}
                                        {!_.get(props, 'userState.row') && <label class="ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-sm-3">Percentage Discount</label>}

                                        {_.get(props, 'userState.row') && <input type="checkbox" id="discount-2" value={0} name='discount_type' checked={discountType === 0} onChange={handleChange} />}
                                        {_.get(props, 'userState.row') && <label class="ms-3">Percentage Discount</label>}
                                    </div>

                                    <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 subtitle-headings">
                                        {/* {!_.get(props, 'userState.row') && <p class=''>Discount Amount (%)</p>} <br /> */}
                                        {!_.get(props, 'userState.row') && <Field type='text' className="form-control mt-2 mb-2" value={coupon.value} name='value' onChange={(e) => {
                                            setFieldValue("value", e.target.value)
                                            handleChange(e)
                                        }} />}

                                        {!_.get(props, 'userState.row') && <ErrorMessage
                                            name="value"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}

                                        {/* {_.get(props, 'userState.row') && <p class='ms-5'>Discount Amount (%)</p>} <br /> */}
                                        {_.get(props, 'userState.row') && <Field type='text' className="form-control ms-5" value={coupon.value} name='value' onChange={(e) => {
                                            setFieldValue("value", e.target.value)
                                            handleChange(e)
                                        }} />}

                                        {_.get(props, 'userState.row') && <ErrorMessage
                                            name="value"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}
                                    </div>

                                </div>

                                <div class="row text-center mt-5 ">
                                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0 mt-5">
                                        {!_.get(props, 'userState.row') && <p class=''>Offer Name </p>} <br />
                                        {!_.get(props, 'userState.row') && <Field class="form-control ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0" type="text" value={coupon.offer_name} name='offer_name' onChange={(e) => {
                                            setFieldValue("offer_name", e.target.value)
                                            handleChange(e)
                                        }} />}

                                        {!_.get(props, 'userState.row') && <ErrorMessage
                                            name="offer_name"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}

                                        {_.get(props, 'userState.row') && <p class='ms-5'>Offer Name </p>} <br />
                                        {_.get(props, 'userState.row') && <Field type="text" class="form-control ms-5" value={coupon.offer_name} name='offer_name' onChange={(e) => {
                                            setFieldValue("offer_name", e.target.value)
                                            handleChange(e)
                                        }} />}

                                        {_.get(props, 'userState.row') && <ErrorMessage
                                            name="offer_name"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}
                                    </div>

                                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12  mt-5">
                                        {!_.get(props, 'userState.row') && <p class=''>Coupon Code</p>} <br />
                                        {!_.get(props, 'userState.row') && <Field type="text" class="form-control ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0" value={coupon.code} name='code' onChange={(e) => {
                                            setFieldValue("code", e.target.value)
                                            handleChange(e)
                                        }} />}
                                        {!_.get(props, 'userState.row') && <ErrorMessage
                                            name="code"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}

                                        {_.get(props, 'userState.row') && <p class='ms-5'>Coupon Code</p>} <br />
                                        {_.get(props, 'userState.row') && <Field type="text" class="form-control ms-5" value={coupon.code} name='code' onChange={(e) => {
                                            setFieldValue("code", e.target.value)
                                            handleChange(e)
                                        }} />}

                                        {_.get(props, 'userState.row') && <ErrorMessage
                                            name="code"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}
                                    </div>

                                    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 mt-5">
                                        {!_.get(props, 'userState.row') && <p class=''>Coupon Limit</p>}<br />
                                        {!_.get(props, 'userState.row') && <Field type="text" class="form-control ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0 " value={coupon.limit} name='limit' onChange={(e) => {
                                            setFieldValue("limit", e.target.value)
                                            handleChange(e)
                                        }} />}

                                        {!_.get(props, 'userState.row') && <ErrorMessage
                                            name="limit"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}


                                        {_.get(props, 'userState.row') && <p class='ms-5'>Coupon Limit</p>}<br />
                                        {_.get(props, 'userState.row') && <Field type="text" class="form-control ms-5" value={coupon.limit} name='limit' onChange={(e) => {
                                            setFieldValue("limit", e.target.value)
                                            handleChange(e)
                                        }} />}

                                        {_.get(props, 'userState.row') && <ErrorMessage
                                            name="limit"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0 ">
                                        {!_.get(props, 'userState.row') && <p class=''>Start Date</p>} <br />
                                        {!_.get(props, 'userState.row') && <Field type="date" class="form-select ms-5" value={coupon.start_date} name='start_date' onChange={(e) => {
                                            setFieldValue("start_date", e.target.value)
                                            handleChange(e)
                                        }} />} <br />

                                        {!_.get(props, 'userState.row') && <ErrorMessage
                                            name="start_date"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}

                                        {_.get(props, 'userState.row') && <p class='ms-5'>Start Date</p>} <br />
                                        {_.get(props, 'userState.row') && <Field
                                            type="text"
                                            class="form-control ms-5"
                                            value={_.get(coupon, 'start_date') ? formatDate(coupon.start_date) : ''}// Set the value based on the state
                                            name='start_date'
                                            onChange={(e) => {
                                                setFieldValue("start_date", e.target.value)
                                                handleChange(e)
                                            }} />}

                                        {_.get(props, 'userState.row') && <ErrorMessage
                                            name="start_date"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}
                                    </div>

                                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-0 ms-sm-0 ">
                                        {!_.get(props, 'userState.row') && <p class=''>End Date</p>}<br />
                                        {!_.get(props, 'userState.row') && <Field type="date" class="form-control ms-5" value={coupon.end_date} name='end_date' onChange={(e) => {
                                            setFieldValue("end_date", e.target.value)
                                            handleChange(e)
                                        }} />}<br />

                                        {!_.get(props, 'userState.row') && <ErrorMessage
                                            name="end_date"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}

                                        {_.get(props, 'userState.row') && <p class='ms-5'>End Date</p>}<br />
                                        {_.get(props, 'userState.row') && <Field
                                            type="text"
                                            class="form-control ms-5"
                                            value={_.get(coupon, 'end_date') ? formatDate(coupon.end_date) : ''}// Set the value based on the state
                                            name='end_date'
                                            onChange={(e) => {
                                                setFieldValue("end_date", e.target.value)
                                                handleChange(e)
                                            }} />}

                                        {_.get(props, 'userState.row') && <ErrorMessage
                                            name="end_date"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}


                                    </div>
                                </div>

                                <div class="row ">
                                    <div class="col">
                                        {_.get(props, 'userState.row') && <p className={`box-coupon ${statusClassName}`}>Status</p>}<br />
                                        {_.get(props, 'userState.row') && <input type="text" id='box-coupon1' value={statusText} name='status' readOnly className={statusClassName} />}
                                    </div>
                                </div>
                                <div class="row px-5">
                                    <div class="col-12 d-flex justify-content-end">
                                        {!_.get(props, 'userState.row') && <button className="btn text-light px-4 py-1 bootBtn my-5" onClick={(e) => ToSubmit()}>Submit </button>}
                                        {_.get(props, 'userState.row') && <button className="btn text-light px-4 py-1 bootBtn my-5" onClick={(e) => ToEdit()}>Update </button>}

                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        </div>

    )
}

export default CreateTeacherCoupon