import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import axios from 'axios';
import { format } from 'date-fns';
import './CouponUsage.scss'
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function Couponusage(props) {
    const [view, setView] = useState()
    const [data, setData] = useState({

        totalusagecount: '',
        offer_name: '',
        limit: '',
        start_date: '',
        end_date: '',
        coupon_type: '',
        discount_type: '',
        subject_all: '',
        status: '',
        subject_id: '',
        code: '',
    })

    const fetchstudentlist = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const formData = new FormData();
        formData.append('totalusagecount', data.totalusagecount)
        formData.append('offer_name', data.offer_name)
        formData.append('start_date', data.start_date)
        formData.append('end_date', data.end_date)
        formData.append('coupon_type', data.coupon_type)
        formData.append('discount_type', data.coupon_type)
        formData.append('subject_all', data.subject_all)
        formData.append('status', data.status)
        formData.append('subject_id', data.subject_id)
        formData.append('code', data.code)

        Axios.post('/api/listCouponUsage',

            formData, { headers })
            .then((response) => {
                if (_.get(response, 'data.data')) {
                    setData(response.data.data)
                }
            })
            .catch((error) => {
                console.error('Error fetching Coupons:', error)
            });
    };
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [data])

    const initializeObject = () => {
        setData({ ...data, ...props.userState.row })
    }

    useEffect(() => {
        fetchstudentlist()
    }, [])

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return format(date, 'dd-MM-yyyy');
    }

    const isCouponActive = () => {
        const currentDate = new Date();
        const endDate = new Date(data.end_date);

        return currentDate <= endDate;
    }


    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachersubject' })
        }
    }

    return (
        <div>
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Coupon</div>
            </div>

            <div className='view-student-assign'>Coupon Usage Details</div>

            <div class="container text-start">
                <div class="row mt-4">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Coupon Name: </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.offer_name}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Discount Amount : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.value}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Subject : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.subject_id}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>limit : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.limit}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>start_date :</p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{formatDate(data.start_date)}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>End date : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{formatDate(data.end_date)}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>coupon Type  </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.coupon_type === '0' ? 'Private Coupon' : 'Public Coupon'}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Course Type :  </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.subject_all === '0' ? 'Assign to specific course' : 'Assign to all course'}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Discount Type :  </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.discount_type === '0' ? 'Percentage Discount' : 'Flat Discount'}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Status:  </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className={` techer-card-typo  ${isCouponActive() ? 'active-status' : 'inactive-status'}`} >  {isCouponActive() ? 'Active' : 'Inactive'}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Code:  </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{data.code}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Couponusage