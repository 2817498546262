import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import './QuotationRequest.scss'
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { format } from 'date-fns';
import CSTComponent from '../adminPage/CST.component'
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';




function QuotationRequest({ ...props }) {
    const [list, setList] = useState([{}])
    const [errors, setErrors] = useState([""]);
    const [rows, setRows] = useState()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([{

    }])

console.log("data",data);

const filteredData = data.filter(item => item.type === '1' );


    const handleChange = (e) => {
        setList({ ...list, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: " " });
    };

    const [topic, setTopic] = useState([{}])
    const [subCategories, setSubCategories] = useState([{}])
    const [categories, setCategories] = useState([{}])

    const listCategories = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }

        const createSchedule = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {}
        const formData = new FormData();
        formData.append('type', 1);
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/getCategoryandTopicDetails', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const categoriesObject = response.data.data;
                    const categoriesArray = Object.values(categoriesObject)
                    setCategories(categoriesArray)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching categories:', error)
                setLoading(false)
            })
    }

    const listSubCategories = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
        }

        const createSchedule = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {}
        const formData = new FormData()
        formData.append('type', 2)

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/getCategoryandTopicDetails', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubcategoriesObject = response.data.data;
                    const SubcategoriesArray = Object.values(SubcategoriesObject)
                    setSubCategories(SubcategoriesArray)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error)
                setLoading(false)
            })
    }

    const listTopic = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
        }

        const createSchedule = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append('type', 3);

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/getCategoryandTopicDetails', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const TopicObject = response.data.data
                    const TopicArray = Object.values(TopicObject)
                    setTopic(TopicArray);
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching topics:', error)
                setLoading(false)
            });
    };
    useEffect(() => {
        getRows()
        fetchQuotation()
    }, [])

    const fetchQuotation = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        Axios.post('/api/listQuotation', undefined, { headers: headers })
            .then((response) => {
                const newData = response.data.data.map((item) => ({
                    ...item,
                    closing_date: format(new Date(item.closing_date), 'dd-MM-yyyy'),

                }));
                setData(newData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Quotation:', error);
                setLoading(false);
            });
    };

    const getRows = () => {
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        let res = [];
        res = data.map((item, index) => {
            return ({
                closing_date: item.closing_date,
                category: item.category,
                sub_category: item.sub_category,
                topic: item.topic,
                type: item.type,
                status: item.status
            })
        })
        setRows(res);
    }
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherQuotation' })
        }
    }

    const goToTeacherQuoteJobDetail = (e, row, id) => {
        console.log('goToTeacherQuoteJobDetail - id:', id);
        if (row && row.type) {
            if (_.get(props, 'setUserState')) {
                if (row.type === '1') {
                    props.setUserState({ ...props.userState, subType: 'QuoteJobDetails', row: row, id: id });
                } else if (row.type === '2') {
                    props.setUserState({ ...props.userState, subType: 'QuoteJobDetails2', row: row, id: id });
                }
            }
        }
    }

    const goToTeacherQuoteJobDetail2 = (e, row, id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'QuoteJobDetails2', row: row, id: id })
        }
    }


    const getStatusCellStyle = (status) => {
        if (status === '1') {
            return { color: 'green' }
        } else if (status === '0') {
            return { color: 'red' }
        }
        return {}
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.closing_date,

        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true

        },
        {
            name: 'Subcategory',
            selector: row => row.sub_category,
            sortable: true

        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true

        },
        {
            name: 'Job Types',
            selector: 'type',
            sortable: true,
            cell: (row) => (
                row.type === '1' ? 'Online Tuition' : row.type === '2' ? 'Freelance Job' : 'Unknown Type'
            ),
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            cell: (row) => (
                <div style={getStatusCellStyle(row.status)}>
                    {row.status === '1' ? 'Active' : 'Inactive'}
                </div>
            ),
        },
        {
            name: 'Actions',
            cell: (row, id) => (
                <button className='btn_detail' onClick={(e) => goToTeacherQuoteJobDetail(e, row, id)}>Details</button>
            ),
            button: true,
        },
    ]
    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'> Quotation Request</div>
            </div>
            <div className='m-5'>
                <CSTComponent />
            </div>
            <div className='table_head col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12'>
                <DataTable
                    columns={columns}
                    data={filteredData}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="600px"
                    striped
                    customStyles={customStyles}
                    persistTableHead
                    pagination
                />
            </div>
        </div>
    )
}

export default QuotationRequest