import _ from 'lodash';
import React from 'react';
import { BsChatSquareQuote } from 'react-icons/bs';
import { FaWallet } from 'react-icons/fa';

function FreelanceQuotedJobsComponent(props) {

    const goToDashboardTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'FreelanceQuotedJobs' })
        }
    }

    return (
        <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'FreelanceQuotedJobs' ? 'active' : ''} `} onClick={(e) => goToDashboardTeacher(e)}>
            <div className="dash d-flex">
                <BsChatSquareQuote class='ms-2' />
                <p className='head-dashboard ms-2' > Quoted Jobs
                </p>
            </div>
        </div>
    )
}

export default FreelanceQuotedJobsComponent