import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './TeacherResource.scss';
import Axios from '../../../../../../util/apiUtil';
import { showSuccessToast } from '../../../../../../util/toastUtils';

function TeacherResource(props) {
    const [inputFields, setInputFields] = useState([
        {
            link: '',
            title: '',
            description: ''
        }
    ]);

    const [view, setView] = useState();

    const [resource, setResource] = useState({
        id: '',
        resources: [
            {
                link: '',
                title: '',
                description: ''
            }
        ]
    });

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [props.userState]);

    const initializeObject = () => {
        setResource({ ...resource, ...props.userState.row });
    }

    const handleButtonClick = () => {
        const link = `url[url_${inputFields.length + 1}]`;
        setInputFields([...inputFields, { link, title: '', description: '' }]);
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherstudent' });
        }
    }

    const handleInputChange = (index, fieldName, value) => {
        const updatedFields = [...inputFields];
        updatedFields[index][fieldName] = value;
        setInputFields(updatedFields);
    }

    const ToSubmit = () => {
        const formData = new FormData();
        formData.append('batch_id', resource.id);
        inputFields.forEach((field, index) => {
            formData.append(`url[url_${index + 1}]`, field.link);
            formData.append(`title_${index + 1}`, field.title);
            formData.append(`description_${index + 1}`, field.description);
        });
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        Axios.post('/api/addBatchResource', formData, { headers: headers })
            .then((res) => {
                showSuccessToast("Succesfully Resource Added")
                if (res.data.success) {
                    Backdashboard();
                }
            });
    };

    return (
        <div className='main-divHead'>
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Resource</div>
            </div>

            <div className='resource-div'>
                <button className='btn-create-Notification22' onClick={handleButtonClick}>
                    Select link for batch
                </button>

                {inputFields.map((field, index) => (
                    <div key={index}>
                        <input
                            type="text"
                            placeholder={`Paste link here ${index + 1}`}
                            value={field.link}
                            name='link'
                            className='resource-add'
                            onChange={(e) => handleInputChange(index, 'link', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder={`Title ${index + 1}`}
                            value={field.title}
                            name='title'
                            className='resource-add'
                            onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder={`Description ${index + 1}`}
                            value={field.description}
                            name='description'
                            className='resource-add'
                            onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                        />
                    </div>
                ))}

                <button className='btn-create-Notification23' onClick={ToSubmit}>
                    Save
                </button>
                <button className='btn-create-Notification23'>Cancel</button>
            </div>
        </div>
    );
}

export default TeacherResource;
