import React, { Component } from 'react';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Log the error to the console (you can customize this)
    console.error(error, info);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Display the ErrorPage component when an error occurs
      return <ErrorPage message="Something went wrong. Please try again later." />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
