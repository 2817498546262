import React from "react"
import Navbar from "./components/navbar/Navbar"
import UserContents from "./components/userPage"
import { useSelector } from "react-redux"
import { LoginUserData } from "../../../../redux/slice/AuthSlice"
import AdminNavbar from "./components/adminPage/adminNavbar"
import _ from "lodash"
import  TeacherViewProfile from "./components/TeacherPage/TeacherViewProfile"
function MainSection({ ...props }) {
  let userDatFromLocalStarge = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : [];

  let userData = useSelector(LoginUserData);
  if (_.isEmpty(userData)) {
    userData = userDatFromLocalStarge
  }



  return (
    <div className='mainSection_container'>
      {userData.professional === "Admin" ? <AdminNavbar {...props} /> : userData.professional === "Uploader" ? <AdminNavbar {...props} /> : userData.professional === "Freelancer" ? <AdminNavbar {...props} /> : userData.professional === "Student" ? <Navbar {...props} /> : userData.professional === "Teacher" ? <TeacherViewProfile {...props} /> : ""}
      <UserContents {...props} />
    </div>
  )
}

export default MainSection
