import React, { useState } from "react"
import _ from 'lodash';
import { BsFillCreditCardFill } from 'react-icons/bs'


function Wallet({ ...props }) {
  const [hoverEffect, setHoverEffect] = useState(false)
  const goToWallet = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Wallet' })
    }
  }
  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'Wallet' ? 'active' : ''} `} onClick={(e) => goToWallet(e)} >
      <div className="dash d-flex">
      <BsFillCreditCardFill class='ms-3' />
        <p className='head-dashboard ms-2'  >Wallet</p>
      </div>
    </div>
  )
}

export default Wallet
