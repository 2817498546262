// counterSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from '../../util/apiUtil';


export const listMyChat = createAsyncThunk('chat/listMyChat', async (payload, thunkApi) => {
    try {
        const response = await Axios.post('/api/listMyChats', payload);
        console.log("response->listMyChat", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});



export const listMessage = createAsyncThunk('chat/listMessage', async (payload, thunkApi) => {

console.log("listChat",payload)

    try {
        const response = await Axios.post('/api/listChat', payload);
        console.log("response->listMessage",response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});



export const sendMessage = createAsyncThunk('chat/sendMessage', async (payload, thunkApi) => {


    console.log("payload",payload);


    try {
        const response = await Axios.post('/api/sendMessage', payload);
        console.log("response->sendMessage",response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const updateDeliveryStatus = createAsyncThunk('chat/updateStatus', async (payload, thunkApi) => {

    console.log("payload",payload);
    try {
        const response = await Axios.post('/api/updateStatus', payload);
        console.log("response->updateStatus",response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});
  


export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        isLoading: false,
        chatList:[],
        myMsg:[]
    },

    extraReducers: (builder) => {
        builder
            .addCase(listMyChat.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(listMyChat.fulfilled, (state, action) => {
                state.isLoading = false;
                state.chatList = action.payload;
            })
            .addCase(listMyChat.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(listMessage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(listMessage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.myMsg = action.payload;
            })
            .addCase(listMessage.rejected, (state) => {
                state.isLoading = false;
            })
    },
});


export const chatListData = (state) => state.chat.chatList;
export const chatMsgData = (state) => state.chat.myMsg;


export default chatSlice.reducer;

