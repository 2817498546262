// counterSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from '../../util/apiUtil';
import { showErrorToast, showSuccessToast } from '../../util/toastUtils';


export const UploadCourse = createAsyncThunk('upload/UploadCourse', async (payload) => {
    try {
        const response = await Axios.post('/api/uploadVideo', payload);
        showSuccessToast("Course Uploaded SuccessFully");
        console.log("response -> UploadCourse", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        showErrorToast("Course Upload Failed");
        throw error;
    }
});

export const DeleteUploadCourse = createAsyncThunk('upload/DeleteUploadCourse', async (payload, thunkApi) => {
    try {
        const response = await Axios.post('/api/deleteupload', payload);
        showSuccessToast("Course Delete SuccessFully");
        await thunkApi.dispatch(UploadCourseList());
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        showErrorToast("Course Delete Failed");
        throw error;
    }
});

export const UploadCourseList = createAsyncThunk('upload/UploadCourseList', async () => {
    try {
        const response = await Axios.post('/api/listUploads');
        console.log("response -> UploadCourseList", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const ViewUploadCourse = createAsyncThunk('upload/ViewUploadCourse', async (payload) => {
    try {
        const response = await Axios.post('/api/viewUploads', payload);
        console.log("response -> ViewUploadCourse", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const uploaderWallet = createAsyncThunk('upload/uploaderWallet', async (payload) => {
    try {
        const response = await Axios.post('/api/wallet', payload);
        console.log("response -> uploaderWallet", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});


export const AnnouncementList = createAsyncThunk('upload/AnnouncementList', async (payload) => {
    try {
        const response = await Axios.post('/api/listAnnoncement', payload);
        console.log("response -> AnnouncementList", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});



export const uploadSlice = createSlice({
    name: 'upload',
    initialState: {
        isLoading: false,
        uploadCourse: [],
        viewCourse: [],
        uploaderWalletData :[],
        listAnnouncement:[]
    },

    extraReducers: (builder) => {
        builder
            .addCase(UploadCourseList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(UploadCourseList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.uploadCourse = action.payload;
            })
            .addCase(UploadCourseList.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(uploaderWallet.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(uploaderWallet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.uploaderWalletData = action.payload;
            })
            .addCase(uploaderWallet.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(ViewUploadCourse.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(ViewUploadCourse.fulfilled, (state, action) => {
                state.isLoading = false;
                state.viewCourse = action.payload;
            })
            .addCase(ViewUploadCourse.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(AnnouncementList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(AnnouncementList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.listAnnouncement = action.payload;
            })
            .addCase(AnnouncementList.rejected, (state) => {
                state.isLoading = false;
            })
    },
});


export const uploadList = (state) => state.upload.uploadCourse;
export const viewUploadCourseData = (state) => state.upload.viewCourse;
export const walletDataUploader = (state) => state.upload.uploaderWalletData;
export const listAnnouncementData = (state) => state.upload.listAnnouncement;



export default uploadSlice.reducer;

