import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import ViewIcon from '../../../Sidebar/icons/ViewIcon.png'
import './CreateViewTeachAssign.scss'
import axios from 'axios';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function CreateViewTeachAssign(props) {
    const [view, setView] = useState()
    const [assignment, setAssignment] = useState([{
        email: " ",
        category: " ",
        sub_category: '',
        starting_at: '',
        ending_at: '',
        student_id: '',
        description: " ",
        assesment_doc: '',
        topic: '',
        batch_id: '',

    }])
    const basePath = "http://tuitionguruji.com/backend/public/";
    const fetchAsignment = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        // const createAssignment = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};


        const formData = new FormData();
        const batch = props.userState.row.batch_id
        formData.append("batch_id", batch)
        Axios.post('/api/viewAssignment', formData,
            { headers: headers })
            .then((response) => {
                console.log(response)
                const updateData = response.data
                setView(updateData)
            })
            .catch((error) => {
                console.error("Error fetching assignment data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });

    }
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])

    const initializeObject = () => {
        setAssignment({ ...assignment, ...props.userState.row })
    }

    useEffect(() => {
        fetchAsignment();
    }, []);

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherassignment' })
        }
    }



    const handleOpenDocument = () => {
        if (assignment.assesment_doc) {
            const documentUrl = basePath + assignment.assesment_doc;
            // Open the document in a new tab
            window.open(documentUrl, '_blank');
        }
    };;
    return (
        <div className="sidebar_content">

        <div className='chat-container' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Assignments</div>
            </div>
            <div className='view-assign-Head ms-5'>View Assignment</div>

            <div class="container  text-start">
                <div class="row mt-4">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{assignment?.category}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Sub Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{assignment?.sub_category}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Topic : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{assignment?.topic}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Batch : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{assignment?.batch_id}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Description : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{assignment?.description}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Starting at : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{assignment?.starting_at}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Ending at : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{assignment?.ending_at}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Upload Coure File : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >
                                <img
                                    className='view-assignment'
                                    src={ViewIcon}
                                    alt='View Icon'
                                    onClick={handleOpenDocument}
                                    style={{ cursor: 'pointer' }}
                                />


                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </div>
    )
}

export default CreateViewTeachAssign