// ErrorPage.js
import React from 'react';
import './ErrorPage.scss'

const ErrorPage = ({ message, onBackButtonClick }) => {
  return (
    <div className='main-div-error'>
      <p className='error-content'>Oops!<p className='error-content1'>The page is temporarily unavailable or refresh the page again </p></p>
      
    </div>
  );
};

export default ErrorPage;
