import { textAlign } from '@mui/system'
import React, { useState } from 'react'
import './DesiredTopic.scss'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowRight } from "react-icons/fa";
import wanteach from '../../../../images/wanteach.png';
import teach from "../../../../images/landing_page_images/teach.jpg"
import learn from "../../../../images/landing_page_images/learn.jpg"

function DesiredTopic() {


  const [showFirstModal, setShowFirstModal] = useState(false);
  const handleNext = () => setShowFirstModal(true);
  const handleClose = () => {
    setShowFirstModal(false);
    // Additional logic or state updates if needed
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  return (
    <div className='topic d-flex-center-row'>
      <div className='d-flex mb200'>
        <h1 className='desired-main-head'>
          Learn / Teach your Desired Topic
        </h1>
      </div>
      <div className='desired-box wp-100  d-flex-center-row'>
        <div class="container  my-5 ">
          <div class="row">
            <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center">
              <div class="col-lg-4 mx-xxl-4 mx-xl-4 mx-lg-4 mx-md-4 col-12">

                <div className='desired-card-left'>
                  {/* <div class="wsk-cp-img"> */}
                  <div className='aa'>
                    <div className='a'></div>
                    <div className='b'></div>
                    <div className='c'></div>
                  </div>
                  <img className='wsk-cp-img' src={teach} />
                  {/* </div> */}
                  <div class="title">
                    <div className="desired-content">
                      <div className='desired-head'>
                        <div className="sub-head">Teach whatever knowledge you have</div>
                      </div>
                      <p className="desired-p">
                        Be the skillful trainer among the experts here.
                      </p>
                    </div>
                    {/* <div className='left-join'>
                      <Button className='purple-btn px-5' data-bs-target="#exampleModalToggle" data-bs-toggle="modal" onClick={()=>handleOpen} > Teach </Button>
                    </div> */}
                    <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                      <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content">
                          <div className='sub-Teach d-flex p-3' >  <FontAwesomeIcon className='mt-1' icon={faChevronLeft} />
                            <div className='Sub-Head2 '> Teach </div>
                          </div>
                          <div class="modal-body ">
                            <div class="container text-start ">
                              <div class="row mt-4 ">
                                <div class="col">
                                  <p className='student-teacher-part'>Want to Teach,</p>
                                </div>
                              </div>
                              <div class="row  text-center mb-4">
                                <div class="col">
                                  <img src={wanteach} alt="" className='img-fluid' />
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center">
                                <label className="col-3 ps-0 m-0 teacher-font-label text-start mt-1">Select  Subject :</label>
                                <div className="col-4">
                                  <input
                                    type="text"
                                    as="select"
                                    className="form-select profile-field"
                                    placeholder="Select  Subject"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                  />
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center">
                                <label className="col-3 ps-0 m-0 teacher-font-label text-start mt-3">Enter Topic:</label>
                                <div className="col-4">
                                  <input
                                    type="text"
                                    as="select"
                                    className="form-select profile-field mt-3"
                                    placeholder="Select Topic"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                  />
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center">
                                <label className="col-3 ps-0 m-0 teacher-font-label text-start mt-3 ">Select  Language:</label>
                                <div className="col-4">
                                  <input
                                    type="text"
                                    as="select"
                                    className="form-select profile-field mt-3"
                                    placeholder="Select  Language"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="container text-end">
                              <div className="row">
                                <div className="col">
                                  <button
                                    type="submit"
                                    className="btn text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3 "
                                    data-bs-dismiss="modal"
                                    onClick={handleNext}
                                    data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">
                                    <p className='m-0'> Next<span className='ms-1' ><FaArrowRight /></span></p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                      <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content">
                          <div className='sub-Teach d-flex p-3' >  <FontAwesomeIcon className='mt-1' icon={faChevronLeft} />
                            <div className='Sub-Head2 '> Teacher Profile Details </div>
                          </div>
                          <div class="modal-body ">
                            <div class="container text-start ">
                              <div class="row mt-4 ">
                                <div class="col">
                                  <p className='student-teacher-part'>Additional Details, </p>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center">
                                <label className="col-3 ps-0 m-0 teacher-label-input text-start mt-1">Educational Background:</label>
                                <div className="col-4">
                                  <textarea
                                    type="text"
                                    className="profile-field"
                                    placeholder="Select  Subject"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                  />
                                  <div className='col'>
                                    <p className='valid-course'>*Degree / Major / University / Graduation Date</p>
                                  </div>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center">
                                <label className="col-3 ps-0 m-0 teacher-label-input text-start mt-3">Other Relevant Courses </label>
                                <div className="col-4">
                                  <textarea
                                    type="text"
                                    className="profile-field mt-3"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                  />
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center">
                                <label className="col-3 ps-0 m-0 teacher-label-input text-start mt-3 ">Teaching Background:</label>
                                <div className="col-4">
                                  <textarea
                                    type="text"
                                    className=" profile-field mt-3"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                  />
                                  <div className='col'>
                                    <p className='valid-course'>*Institution / Position / Start Date / End Date / Book Taught</p>
                                  </div>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center">
                                <label className="col-3 ps-0 m-0 teacher-label-input text-start mt-3 ">Attach any Supporting Documents:</label>
                                <div className="col-4">
                                  <input
                                    type="file"
                                    as="select"
                                    className=" profile-field mt-3"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="container text-end">
                              <div className="row text-end">
                                <div className="col">
                                  <button
                                    type="submit"
                                    className="btn text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3 "
                                    data-bs-dismiss="modal"
                                    data-bs-target="#exampleModalToggle" data-bs-toggle="modal" >
                                    <p className='m-0'> Submit <span className='ms-1' ></span></p>
                                  </button>
                                </div>
                                <div className="col-4">
                                  <button
                                    type="submit"
                                    className="btn px-4 py-1 back-btn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3 "
                                    data-bs-dismiss="modal"
                                    onClick={handleClose}
                                    data-bs-target="#exampleModalToggle" data-bs-toggle="modal" >
                                    <p className='m-0'> Back <span className='ms-1' ></span></p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mx-xxl-4 mx-xl-4 mx-lg-4 mx-md-4 col-12 landing-margin-top ">
                <div className='desired-card-right'>
                  <div className='aa'>
                    <div className='a'></div>
                    <div className='b'></div>
                    <div className='c'></div>
                  </div>
                  <img className='wsk-cp-img' src={learn} />
                  <div class="title">
                    <div className="desired-content">
                      <div className='desired-head'>
                        <div className='sub-head'>Learn whatever interest you</div>
                      </div>
                      <p className="desired-p">
                        Explore the courses which makes you interest.
                      </p>
                    </div>
                    <div className='left-join'>
                      {/* <Button className='purple-btn  px-5'> Learn </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesiredTopic