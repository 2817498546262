// counterSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from '../../util/apiUtil';
import { getCategoryListData } from './studentSlice';
import { showErrorToast, showSuccessToast } from '../../util/toastUtils';


export const fetchSubjectList = createAsyncThunk('subject/fetchSubjectList', async (payload) => {

    try {
        const response = await Axios.post('/api/listSubject', payload);
        console.log("response    -  > fetchSubjectList", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
})


export const fetchDashboardData = createAsyncThunk('subject/dashboardList', async (authToken, thunkApi) => {
    try {
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await Axios.post('/api/admin/dashboard', null, { headers });
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
})


export const viewSubjectData = createAsyncThunk('subject/viewSubData', async (payload) => {
    try {
        const response = await Axios.post('/api/viewSubject', payload);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});


export const getCSTData = createAsyncThunk('subject/getCSTData', async (payload) => {
    try {
        const response = await Axios.post('/api/getCategoryandTopicDetails', payload);
        return response?.data?.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const getSubCategoryData = createAsyncThunk('subject/getSubCategoryData', async (payload) => {
    try {
        const response = await Axios.post('/api/getCategoryandTopicDetails', payload);
        console.log("response -> getSubCategoryData ", response);
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            const category_id = response.data.data[0].category_id; // Assuming category_id is in the first object of the data array
            console.log("category_id:", category_id);
          } else {
            console.log("Data is empty or not in the expected format");
          }
        return response?.data?.data;                                                                                                                                                                                                                                                                                                                                                                                                                            
        
        
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});


export const getSubTopicData = createAsyncThunk('subject/getSubTopicData', async (payload) => {
    try {
        const response = await Axios.post('/api/getCategoryandTopicDetails', payload);
        console.log("response -> getSubTopicData ", response);
        return response?.data?.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});



export const viewCategoryDetails = createAsyncThunk('subject/viewCategoryDetails', async (payload) => {
    try {
        const response = await Axios.post('/api/viewCategoryDetails', payload);
        console.log("response -> viewCategoryDetails ", response);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});



export const changeSubject = createAsyncThunk('subject/changeSubject', async (payload, thunkApi) => {

    try {
        const response = await Axios.post('/api/changeSubjectStatus', payload.payload);
        showSuccessToast("change status successfully");
        await thunkApi.dispatch(viewSubjectData(payload.viewSub));
        return response.data.data;
    } catch (error) {
        showErrorToast("change status Failed");
        console.log("axios error", error);
        throw error;
    }
});



export const deleteCategory = createAsyncThunk('subject/deleteCategory', async (payload) => {
    try {
        const response = await Axios.post('/api/deleteCatSubTop', payload);
        if (response.data.success) {
            showSuccessToast("Deleted Successfully");
            return response.data.data;
        } else {
            showErrorToast(response.data.error[0] || "Add Category Failed");
            throw new Error("Add Category Failed");
        }
    } catch (error) {
        console.log("axios error", error);
        throw error; // Rethrow the error to be caught by handleDelete
    }
});








export const changeCategory = createAsyncThunk('subject/changeCategory', async (payload, thunkApi) => {

    try {
        const response = await Axios.post('/api/changeStatusCategory', payload.payload);
        if (response.data.success) {
            showSuccessToast("Status Changed Successfully");
        }
        await thunkApi.dispatch(viewCategoryDetails(payload.viewCategory));
        return response.data.data;
    } catch (error) {
        showErrorToast("change status Failed");
        console.log("axios error", error);
        throw error;
    }
});

export const addCategory = createAsyncThunk('subject/addCategory', async (payload, thunkApi) => {
    try {
        const response = await Axios.post('/api/addCategory', payload);            
        if (response.data.success) {
            // showSuccessToast("Added Successfully");
            return response.data.data;
        } else {
            showErrorToast(response.data.error.name[0] || "Add Category Failed");
            throw new Error("Add Category Failed");
        }
    } catch (error) {      
            console.log("axios error", error);
            throw error;        
    }
});




export const addSubCategory = createAsyncThunk('subject/addSubCategory', async (payload, thunkApi) => {

    try {
        const response = await Axios.post('/api/addSubCategory', payload);
        if (response.data.success) {
            // showSuccessToast("Added Successfully");
            return response.data.data;
        } else {
            showErrorToast(response.data.error.name[0] || "Add Sub category Failed");
        }
    } catch (error) {
        showErrorToast("Add Sub Category Failed");
        throw error;
    }
});


export const addTopic = createAsyncThunk('subject/addTopic', async (payload, thunkApi) => {

    try {
        const response = await Axios.post('/api/addtopic', payload);
        if (response.data.success) {
            showSuccessToast("Added Successfully");
            return response.data.data;
        } else {
            showErrorToast(response.data.error.name[0] || "Add topic Failed");
        }
    } catch (error) {
        showErrorToast("Add topic Failed");
        throw error;
    }
});

export const subjectSlice = createSlice({
    name: 'subject',
    initialState: {
        isLoading: false,
        fetchSubjectList: [],
        adminDashboardData: [],
        activeSubId: [],
        viewSubData: [],
        CSTData: [],
        SubData: [],
        TopicData: [],
        activeCategoryId: [],
        categoryData: [],
        subjectList: [],
    },

    reducers: {
        ActiveSub: (state, action) => {
            return {
                ...state,
                activeSubId: action.payload
            }
        },
        currentCategoryID: (state, action) => {
            return {
                ...state,
                activeCategoryId: action.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubjectList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSubjectList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.fetchSubjectList = action.payload;
            })
            .addCase(fetchSubjectList.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(addSubCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(addSubCategory.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(changeCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(changeCategory.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(changeCategory.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(changeSubject.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(changeSubject.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(changeSubject.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(addCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addCategory.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(addCategory.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(addTopic.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addTopic.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(addTopic.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchDashboardData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchDashboardData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.adminDashboardData = action.payload;
            })
            .addCase(fetchDashboardData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getSubTopicData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubTopicData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.TopicData = action.payload;
            })
            .addCase(getSubTopicData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(viewSubjectData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(viewSubjectData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.viewSubData = action.payload;
            })
            .addCase(viewSubjectData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getCSTData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCSTData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.CSTData = action.payload;
            })
            .addCase(getCSTData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getSubCategoryData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubCategoryData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.SubData = action.payload;
            })
            .addCase(getSubCategoryData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(viewCategoryDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(viewCategoryDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.categoryData = action.payload;
            })
            .addCase(viewCategoryDetails.rejected, (state) => {
                state.isLoading = false;
            }) 

            .addCase(deleteCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.categoryData = action.payload;
            })
            .addCase(deleteCategory.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { ActiveSub, currentCategoryID } = subjectSlice.actions;

export const subjectLoading = (state) => state.subject.isLoading;
export const DashboardData = (state) => state.subject.adminDashboardData;
export const SubjectData = (state) => state.subject.fetchSubjectList;
export const activeSubjectID = (state) => state.subject.activeSubId;
export const viewSubject = (state) => state.subject.viewSubData;
export const CSTData = (state) => state?.subject?.CSTData;
export const SubData = (state) => state?.subject?.SubData;
export const topicData = (state) => state?.subject?.TopicData;
export const ActiveCategoryID = (state) => state.subject.activeCategoryId;
export const viewCategoryData = (state) => state.subject.categoryData;



export default subjectSlice.reducer;

