import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { enrolledCourses } from "../../../../../../../redux/displayer"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import profileimg2 from "../../../../../../../images/landing_page_images/profileimg2.png"
import { faStar } from "@fortawesome/free-solid-svg-icons";
// import board from '../../../../../../../images/board.jpg';
import _ from 'lodash';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Button, Grid, Paper } from "@mui/material";
import LoadingSpinner from "../../../../../../Reactloader/LoadingSpinner";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
function EnrolledVideos({ ...props }) {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));
    const [hoverEffect, setHoverEffect] = useState(false)
    const [loading, setLoading] = useState(true)
    const [EnrolledSubjects, setEnrolledSubjects] = useState([""])
    const [loader, setLoader] = useState(true);
    const reactvideoplayer = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'ReactVideoPlayer' })
        }
    }

    // const liveClass = () => {
    //     if (_.get(props, 'setUserState')) {
    //         props.setUserState({ ...props.userState, subType: 'Chatscontent' })
    //     }
    // }
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'EnrolledVideos' })
        }
    }
    useEffect(() => {

        // Simulate loading delay with a setTimeout
        setTimeout(() => {
            setLoader(false);
            ; // Set Loader to false when data is loaded
        }, 500); // 2 seconds delay
    }, []);
    return (
        <div>
            <div className="sidebar_content">
                {loader ? <LoadingSpinner /> : <div className="chat-container p-2">
                    <div className="icon-name d-flex" onClick={Backdashboard} >
                        <div className="faChevronLeft">
                            <FontAwesomeIcon icon={faChevronLeft}  />
                        </div>
                        <div className="asgmt-nav">Enrolled Videos</div>
                    </div>
                    <form class=" d-flex row gy-3 gx-3 align-items-center ms-10">
                        <div class="ms-auto p-4" style={{ width: "250px" }}>
                            <div class="input-group">
                                <input type="text" class="form-control" id="autoSizingInputGroup" placeholder="Search" />
                                <div class="input-group-text"> <FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: "white", }} /></div>
                            </div>
                        </div>
                    </form>
                    <div class="card english">
                        <div className="book">
                            {/* <img className="book-imgs" src={board} /> */}
                            <div className="english-profile d-flex">
                                <div className="head-english"> English</div>
                                <div className="profile">
                                    <img className="profile-img" src={profileimg2} />
                                </div>
                            </div>
                            <Box className="progress-container" sx={{ flexGrow: 1 }}>
                                <br />
                                <BorderLinearProgress variant="determinate" value={50} />
                                <div className="progress-cent">23%</div>
                            </Box>
                            <div className="english-rs">$130</div>
                            <div className="fa-star">
                                <small class="text-muted-rating">
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#C7CFE2" }} />
                                </small>
                            </div>
                            <div className="open-button">
                                <button className="open" onClick={reactvideoplayer} >Open</button>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default EnrolledVideos;
