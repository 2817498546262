import React, { useState } from "react"
import _ from "lodash"
import { GiNotebook } from "react-icons/gi";


function TestTeacher(props) {
    const goToTeacherTest = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherTest' })
        }
    }



    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'TeacherTest':
            case 'TeacherViewTest':
            case 'TeacherAddNewQuest':
            case 'TeacherAddTest':
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };







    return (
        <div className={determineClassName()} >
            <div className="dash d-flex">
                <GiNotebook className="ms-3" />
                <p className='head-dashboard ms-2' onClick={() => goToTeacherTest()}>Test</p>
            </div>
        </div>
    )
}

export default TestTeacher