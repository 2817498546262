import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function MessageDetailComponent() {
    return (

        <div >
            <div class='main-divHead'>
                <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2 ms-5'>Messages</div>
                </div>
                <div class='mx-5'>
                    <p class='adminSubHead my-3 fw-semibold'>Details</p>
                    <div class="container text-center">
                        <div>

                        </div>
                        <div class="row">
                            <div class='d-flex justify-content-center'>
                                <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-10 col-sm-11 admin-font-message">
                                    <div class=' adminSubCard p-3'>
                                        <div class="container text-start">
                                            <div class="row mb-1 ms-3">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='admin-font-message mb-0 '>Date : </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <p class='admin-font-message '>23-11-2022</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-1  ms-3">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='admin-font-message mb-0 '>Student Name :  </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <p class='admin-font-message mb-0 '>Kevin</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-1  ms-3 ">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='admin-font-message mb-0 '>Message :  </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <p class=' admin-font-message mb-0 '>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            
                                            <div class="row mb-1 ms-3 ">
                                                <div class='d-flex justify-content-between'>
                                                    <div class="col">
                                                        <p class='admin-font-message mb-0  '>Supporting Documents :  </p>
                                                    </div>
                                                    <div class="col-5">
                                                        <p class=' admin-font-message mb-0 '><span class='text-success fw-bold'>Active</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="container text-center">
                        <div class="row">
                            <div class="col">
                                <button type="button" class="btn btn-primary px-5 py-1 bootBtn my-5" >Reply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessageDetailComponent