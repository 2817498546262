import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import { WalletData, walletDetails } from '../../../../../../redux/slice/freelanceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { BsEyeFill } from 'react-icons/bs';



function AdminWalletComponent(props) {

    const dispatch = useDispatch()
    const walletData = useSelector(walletDetails);



    useEffect(() => {
        dispatch(WalletData());
       ;
    }, []);


    const goTocreditDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'admincreditdetails' });
        }
    };

    const goToteachercreditDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminteachercreditdetails' });
        }
    };
    const goTostudentcreditDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminstudentcreditdetails' });
        }
    };


    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.created_at,
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.created_at ? dateFunction(row.created_at) : ""}
                    </div>
                );
            }
        },
        {
            name: 'Initial Amount',
            selector: row => row.initial_amount,
            sortable: true
        },
        {
            name: 'Total Amount',
            selector: row => row.total_amount,
            sortable: true
        },
        {
            name: 'Debit',
            selector: row => row.debit,
            sortable: true
        },
        {
            name: 'Credit',
            selector: row => row.credit,
            sortable: true
        },
        {
            name: 'Credit Details',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div className='ms-5 '>
                    <BsEyeFill className='eye-icon' onClick={(e) => goTocreditDetails()} />
                </div>
            )
        }
    ];

    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    const teacher = [
       
        {
            name: 'Teachers Name',
            selector: row => row.firstname,
            sortable: true
        },
        {
            name: 'Initial amount',
            selector: row => row.amount,
            sortable: true
        },

        {
            name: 'Credit Details',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div className='ms-5 '>
                    <BsEyeFill className='eye-icon' onClick={(e) => goToteachercreditDetails()} />
                </div>
            )
        }
    ]

    const student = [

        {
            name: 'Student Name',
            selector: row => row.firstname,
            sortable: true
        },

        {
            name: 'Initial amount',
            selector: row => row.amount,
            sortable: true
        },

        {
            name: 'Credit Details',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div className='ms-5 '>
                    <BsEyeFill className='eye-icon' onClick={(e) => goTostudentcreditDetails()} />
                </div>
            )
        }
    ];

    return (
        <>
            {<div className='main-divHead' >
                <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Wallet</div>
                </div>
                <div>
                    <div class="container text-start my-5">
                        <div class="row ">
                            <div class='col-xxl-3 col-xl-3 col-lg-4 '>
                                <div class='card adminCardBGW ms-0 py-xxl-3 py-xl-3 py-lg-2'>
                                    <p className='mb-0 fs-xxl-5 fs-xl-5 fs-lg-6 fw-semibold'>GROSS TOTAL : {walletData?.totalAmount}</p>
                                </div>
                            </div>
                            <div class='col-xxl-3 col-xl-3 col-lg-4 ps-0 '>
                                <div class='card adminCardBGW ms-0 py-xxl-3 py-xl-3 py-lg-2'>
                                    <p className='mb-0 fs-xxl-5 fs-xl-5 fs-lg-6 fw-semibold'>CHARGES(10%) : 92$</p>
                                </div>
                            </div>
                            <div class='col-xxl-3 col-xl-3 col-lg-4 '>
                                <div class='card adminCardBGR ms-0 text-light py-xxl-3 py-xl-3 py-lg-2'>
                                    <p className='mb-0 fs-xxl-5 fs-xl-5 fs-lg-6 fw-semibold'>NET AMOUNT : 828$</p>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <DataTable
                                className='dataTable mt-5 '
                                data={walletData?.transactions}
                                columns={columns}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                striped
                                customStyles={customStyles}
                                persistTableHead
                                pagination
                            />
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <p className='d-flex justify-content-center mt-5'><b>Total Teachers Details</b></p>
                                <DataTable
                                    className='dataTable mt-1 '
                                    data={walletData?.teacherWallet}
                                    columns={teacher}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    striped
                                    customStyles={customStyles}
                                    persistTableHead
                                    pagination
                                />
                            </div>
                            <div className='col-6'>
                                <p className='d-flex justify-content-center mt-5'><b>Total Students Details</b></p>
                                <DataTable
                                    className='dataTable mt-1 '
                                    data={walletData?.studentWallet}
                                    columns={student}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    striped
                                    customStyles={customStyles}
                                    persistTableHead
                                    pagination
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default AdminWalletComponent