import React, { useState, useEffect } from "react"
import ReactPlayer from 'react-player'
import _ from 'lodash';
import profileimg2 from "../../../../../../../images/landing_page_images/profileimg2.png"
import './Reactvideoplayer.scss'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Paper } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from "react-router-dom";
import pic from './../../../../../../../images/pic.jpeg';
import LoadingSpinner from "../../../../../../Reactloader/LoadingSpinner";
function ReactVideoPlayer({ ...props }) {
    const reactvideoplayer = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'ReactVideoPlayer' })
        }
    }
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));
    const [loader, setLoader] = useState(true);
    const [Review, setReview] = useState([
        {
            name: "Abhilash",
            description: "consequat  Exercitation veniam consequat sunt nostrud amet."
        },
        {
            name: "Abhilash",
            description: " enim velit  Exercitation veniam consequat sunt nostrud amet."
        },
        {
            name: "Leena",
            description: " oation veniam consequat sunt nostrud amet."
        },
    ])
    const goToView = (e, link) => {

        window.location = "/ViewProfile";
        // navigate('ViewProfile')
    }
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Dashboard' })
        }
    }
    const review = (index) => {
        Review[index.id - 1].open = !index.open;
        setReview([...Review]);
    }
    const [faq, setFaq] = useState(
        [
            {
                name: "Get Started",
                id: 1,
                description: "Introduction.ffdgfdfgfg"
            },
            {
                name: "Content1",
                id: 2,
                description: "Introduction."
            },
            {
                name: "Content2",
                id: 3,
                description: "Introduction."
            },
            {
                name: "Content3",
                id: 4,
                description: "Introduction."
            },
            {
                name: "End Course",
                id: 4,
                description: "Introduction."
            }

        ]
    );

    const toggleOpen = (question) => {
        faq[question.id - 1].open = !question.open;
        setFaq([...faq]);
    }
    useEffect(() => {
        // Simulate loading delay with a setTimeout
        setTimeout(() => {
            setLoader(false); // Set Loader to false when data is loaded
        }, 500); // 2 seconds delay
    }, []);
    return (
        <div>
            <div className="sidebar_content">
                {loader ? <LoadingSpinner /> :
                    <div className="chat-container p-2">
                        <div className="icon-name d-flex">
                            <div className="faChevronLeft">
                                <FontAwesomeIcon icon={faChevronLeft} onClick={Backdashboard} />
                            </div>
                            <div className="asgmt-nav">Video Player</div>
                        </div>
                        <div className="player-video">
                            <ReactPlayer controls={true} playIcon volume={0} url={"https://youtu.be/eaR5mt7znEw?si=uG1tWvHie3wXGabi"} height="600px" width="100%" />
                        </div>
                        <div className='profile-video-player d-flex'>
                            <div>
                                <img src={pic} className='viewprofile' />
                                <p className='course'>English</p>
                                <p className='about-course'>In publishing  placeholder text commonly used to demonstrate the visual form of a document
                                    without relying on meaningful content
                                    <div>  <p className='view-full' onClick={(e) => goToView()}>View profile</p></div> </p>

                            </div>
                        </div>
                        {faq.map((question) => (
                            <div>
                                <div className="content-1 ">
                                    <div className={`Get-Started  ${question.open ? 'active' : ''}`}>
                                        {question.name}
                                        <div className="faXmark-icon">
                                            {!question.open && <div className="faMinus">
                                                <KeyboardArrowDownIcon onClick={(e) => toggleOpen(question)} />
                                            </div>}
                                            {question.open && <div className="faXmark-icon">
                                                <FontAwesomeIcon icon={faXmark} style={{ color: "#5C5AB6", }} onClick={(e) => toggleOpen(question)} />
                                            </div>}
                                        </div>
                                    </div>
                                    {question.open && <div className="Question-1">
                                        <div className="Get-Started1">
                                            <FontAwesomeIcon icon={faVideo} /> Introduction
                                        </div>
                                        <div className="Get-Started1">
                                            <FontAwesomeIcon icon={faVideo} /> Maths1
                                        </div>
                                        <div className="Get-Started1">
                                            <FontAwesomeIcon icon={faVideo} />  Maths2
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        ))}

                        <div className='enroll-about'>
                            <h3>
                                About Course
                            </h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo con Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id es Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, to Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                            </p>
                            <div className="review-star">
                                Rating <small class="text-muted-rating">
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                </small>View all
                            </div>

                            <div className="name-card">
                                <table>
                                    <tr>
                                        <td className="name">category-</td>
                                    </tr>
                                    <tr>
                                        <td className="count">sub category-</td>
                                    </tr>
                                    <tr>
                                        <td className="count">subject-</td>
                                    </tr>
                                    <tr>
                                        <td className="count">Price-</td>
                                    </tr>
                                    <tr>
                                        <td className="count">Language-</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="d-flex">
                                {Review.map((index) => (
                                    <div class="card review d-flex">
                                        <div class="card-body">
                                            <div className="d-flex">
                                                <div className="review-img">
                                                    <img className="review-bio-img" src={profileimg2} />
                                                </div>
                                                <div className="name-rating">
                                                    <h5 class="card-title review-name">{index.name}</h5>

                                                    <div className="review-star">
                                                        <small class="text-muted-rating">
                                                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD782" }} />
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>


                                            <p class="card-text reiview-des">{index.description}</p>

                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="show-review d-flex">
                                <button className="show-all"> Show all Reviews</button>
                            </div>

                        </div>
                    </div>
                }
            </div>

        </div>

    )
}

export default ReactVideoPlayer