import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import fb2 from '../../images/Fb (2).png';
import fb1 from '../../images/Fb (1).png';
import fb from '../../images/Fb.png';
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { LuMail } from "react-icons/lu";
import img from '../../images/signup.jpg';
import { showErrorToast, showSuccessToast } from '../../util/toastUtils';
import Axios from '../../util/apiUtil';

function ForgotPassword() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const initialValues = {
        email: '',
        password: '',
        confirm_password: '',
        otp: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
        otp: Yup.number().required('OTP is required').positive('Invalid OTP').integer('Invalid OTP'),
    });

    const handleForgotPassword = async (values) => {
        const formData = new FormData();
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('confirm_password', values.confirm_password);
        formData.append('otp', values.otp);
        try {
            const response = await Axios.post('api/createpassword', formData);
            const data = response.data; // No need to call response.json() as Axios already returns JSON data
            if (data.success) {
                showSuccessToast('Password Reset Successfully');
                navigate("/user/sign_in");
            } else {
                setError(data.error);
                showErrorToast(data.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('An error occurred while fetching data.');
            showErrorToast('An error occurred while fetching data.');
        }
    };

    return (
        <>
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 px-0 d-none d-sm-block">
                            <img src={img} alt="Login image" className="img-fluid w-100 vh-100" />
                        </div>

                        <div className="col-6 text-start">
                            <h2 className="admin-signup-text">Reset Password</h2>

                            <div className='row'>
                                <div className='col'>
                                    <hr />
                                </div>
                                <div className='col-1'>
                                    or
                                </div>
                                <div className='col'>
                                    <hr />
                                </div>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleForgotPassword}
                            >
                                {({ errors, touched }) => (
                                    <Form>
                                        <div className="row mt-5 d-flex justify-content-center">
                                            <div className="col-8">
                                                <Field
                                                    type="number"
                                                    name="otp"
                                                    className={`form-control admin-signIn-input ${errors.otp && touched.otp ? 'is-invalid' : ''}`}
                                                    placeholder="Enter the OTP"
                                                />
                                                <ErrorMessage
                                                    name="otp"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-5 d-flex justify-content-center">
                                            <div className="col-8">
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    className={`form-control admin-signIn-input ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                    placeholder="Email"
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4 d-flex justify-content-center">
                                            <div className="col-8">
                                                <Field
                                                    type="password"
                                                    name="password"
                                                    className={`form-control admin-signIn-input ${errors.password && touched.password ? 'is-invalid' : ''}`}
                                                    placeholder="Password"
                                                />
                                                <ErrorMessage
                                                    name="password"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </div>

                                        </div>
                                        <div className="row mt-4 d-flex justify-content-center">
                                            <div className="col-8">
                                                <Field
                                                    type="password"
                                                    name="confirm_password"
                                                    className={`form-control admin-signIn-input ${errors.confirm_password && touched.confirm_password ? 'is-invalid' : ''}`}
                                                    placeholder="Confirm Password"
                                                />
                                                <ErrorMessage
                                                    name="confirm_password"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </div>

                                        </div>

                                        <div className="row mt-5 d-flex justify-content-center">
                                            <div className="col-8">
                                                <button
                                                    type="submit"
                                                    className="btn admin-signIn-btn"
                                                >
                                                    <span className="admin-signIn-btn-text">
                                                        {false ? (
                                                            <div className='d-flex justify-content-center'>
                                                                <RotatingLines
                                                                    strokeColor="white"
                                                                    strokeWidth="5"
                                                                    animationDuration="0.75"
                                                                    width="40"
                                                                    height="40"
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <>Update <span className='me-2'><LuMail /></span></>
                                                        )}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
