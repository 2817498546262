import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "./Test.scss";
import _ from "lodash";
import Questionnaire from "./TestMcq";
import { format } from "date-fns";
import axios from "axios";
import LoadingSpinner from "../../../../../../Reactloader/LoadingSpinner";
import { BsEyeFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { viewTest, viewTestData } from "../../../../../../../redux/slice/studentSlice";
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";

function TestResults({ successCallback, Clickreport, ...props }) {
  const [hoverEffect, setHoverEffect] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const Backdashboard = () => {
    if (_.get(props, "setUserState")) {
      props.setUserState({ ...props.userState, subType: "Dashboard" });
    }
  };
  const [loader, setLoader] = useState(true);
  const [showTest, setshowTest] = useState(false);
  const [loading, setLoading] = useState(true);
  const [Teststudent, setTeststudent] = useState([]);
  const [view, setView] = useState(false);

  const dispatch = useDispatch();
  const testData = useSelector(viewTestData);
  const objectCount = _.get(testData,'question.question.length');
  const [formattedDate, setFormattedDate] = useState('');
  useEffect(() => {
    const originalTime = _.get(testData, 'answer.created_at');

    if (originalTime) {
      // Create a Date object from the original time string
      const dateObject = new Date(originalTime);

      // Get the day, month, and year from the Date object
      const day = dateObject.getUTCDate();
      const month = dateObject.getUTCMonth() + 1; // Months are zero-based, so add 1
      const year = dateObject.getUTCFullYear();

      // Format the date as DD-MM-YYYY
      const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;

      // Set the formatted date in state
      setFormattedDate(formattedDate);
    }
  }, [testData]);
  
  useEffect(() => {
    TestStudent();
  }, []);
  const customStyles = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#5C5AB6",
        fontSize: "13px",
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
      },
    },
  };
  const TestStudent = () => {
    if (!localStorage.getItem("token")) {
      Backdashboard();
    }
    const headers = {
      Authorization: "Bearer " +  (localStorage.getItem("token")),
    };
    Axios
      .post(
     'api/mytest',
        undefined,
        { headers: headers }
      )
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setTeststudent(res.data.data);
          const formattedData = res.data.data.map((item) => ({
            ...item,
            date: format(new Date(item.date), "dd-MM-yyyy"),
          }));
          setTeststudent(formattedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error);
        setLoading(false);
      });
  };
  const update = () => {
    setshowTest(false);
  };
  const { answer } = testData;
  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,

    },

    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      grow: 2,

    },
    {
      name: "Sub Category",
      selector: (row) => row.sub_category,
      sortable: true,
      grow: 2,

    },
    {
      name: "Topic",
      selector: (row) => row.topic,
      sortable: true,
      grow: 2,
      width: "140px",
    },
    {
      name: "Batch",
      selector: (row) => row.batch_id,
    },
    {
      name: " Status",
  
      cell: (row) => {
        const isActive = row.status === 'pending';
        return (
          <div className={isActive ? "complete" : "pending"}>
            {isActive ? "pending" : "complete"}
          </div>
        );
      },
    },
    {
      name: " No of Questions",
      selector: (row) => row.question.length,
    },
    ,
    {
      name: "Action",
      selector: (row) => (
        <div>
          {row.question ? ( // Check if 'question' exists in the current row
            <>
              {row.status != "completed" ? <button
                className="btn text-light px-xxl-3 px-xl-3 px-lg-2 py-1 bootBtn"
                onClick={(e) => {
                  const questionId = row.question_id;
                  // Use the questionId as needed
                  setSelectedRow(row.id);
                  setshowTest(true);
                }}
              >
                Start
              </button> : <div className="ms-3" onClick={() => TestView(row)}>
                <BsEyeFill className='eye-icon' />
              </div>}
            </>
          ) : (
            // Handle the case when 'question' is missing or undefined
            <span>No question available</span>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    // Simulate loading delay with a setTimeout
    setTimeout(() => {
      setLoader(false); // Set Loader to false when data is loaded
    }, 500); // 2 seconds delay
  }, []);

  const BackTodashboard = () => {
    setView(false);

  };

  function TestView(row) {
    setView(true);
    console.log("row", row);
    dispatch(viewTest({ id: row.question_id }))
  }


  function getAnsTotalMarks(marks) {
    let mark = 0;
    _.forEach(marks, qust => {
      mark += parseInt(qust.marks)
    })
    return mark
  }

  const d = new Date()

  return (
    <div className="sidebar_content">
      {/* {loader ? (
        <LoadingSpinner />
      ) : ( */}
        <div className="main-divHead">
          <div className="sub-Head1">
            <div className="Sub-Head2">My Tests</div>           
          </div>
          <div>
            {!showTest && (
              <div className="m-xxl-5 m-xl-5 m-lg-5 m-mg-5 m-sm-5 m-0 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                {!view ? <DataTable
                  columns={columns}
                  data={Teststudent}
                  fixedHeader={true}
                  fixedHeaderScrollHeight="600px"
                  striped
                  customStyles={customStyles}
                  persistTableHead
                  pagination
                /> : <>
                  <div className=" px-5 pt-3 pb-5 m-5">
                    <p class='test-head d-flex justify-content-center'>Test Report</p>
                    <div class="container text-start">
                      <div class="row mx-5 mt-5">
                        <div class='d-flex justify-content-center'>
                          <div class="col-7">
                            <p className='admin-sub-head-card'>Date : </p>
                          </div>
                          <div class="col-3">
                            <p className='admin-sub-head-card'>{formattedDate}</p>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row mx-5">
                        <div class='d-flex justify-content-center'>
                          <div class="col-7">
                            <p className='admin-sub-head-card'>Student Name : </p>
                          </div>
                          <div class="col-3">
                            <p className='admin-sub-head-card'>{_.get(testData,'test.firstname')}   {_.get(testData,'test.lastname')}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-5">
                        <div class='d-flex justify-content-center'>
                          <div class="col-7">
                            <p className='admin-sub-head-card'>Category : </p>
                          </div>
                          <div class="col-3">
                            <p className='admin-sub-head-card'>{_.get(testData,'test.category')}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-5">
                        <div class='d-flex justify-content-center'>
                          <div class="col-7">
                            <p className='admin-sub-head-card'>Sub Category : </p>
                          </div>
                          <div class="col-3">
                            <p className='admin-sub-head-card'>{_.get(testData,'test.sub_category')}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-5">
                        <div class='d-flex justify-content-center'>
                          <div class="col-7">
                            <p className='admin-sub-head-card'>Topic : </p>
                          </div>
                          <div class="col-3">
                            <p className='admin-sub-head-card'>{_.get(testData,'test.topic')}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-5">
                        <div class='d-flex justify-content-center'>
                          <div class="col-7">
                            <p className='admin-sub-head-card'>Batch :  </p>
                          </div>
                          <div class="col-3">
                            <p className='admin-sub-head-card'>{_.get(testData,'test.batch_id')}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-5">
                        <div class='d-flex justify-content-center'>
                          <div class="col-7">
                            <p className='admin-sub-head-card'>No. of questions : </p>
                          </div>
                          <div class="col-3">
                            <p className='admin-sub-head-card'>{objectCount}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row mx-5">
                        <div class='d-flex justify-content-center'>
                          <div class="col-7">
                            <p className='admin-sub-head-card'>Marks:  </p>
                          </div>
                          <div class="col-3">
                            <p className='admin-sub-head-card'> {getAnsTotalMarks(_.get(testData,'answer.marks'))} / {objectCount * _.get(testData, 'test.marks_question')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>}
              </div>
            )}
            {showTest && (
              <Questionnaire
                Teststudent={Teststudent}
                rowId={selectedRow}
                update={update}
                Clickreport={Clickreport}
              />
            )}
          </div>
        </div>
      {/* )} */}
    </div>
  );
}

export default TestResults;
