import React, { useState } from 'react'
import _ from 'lodash';
import { AiOutlineLeft } from 'react-icons/ai';
import { deleteCategory, getCSTData, viewCategoryData, viewCategoryDetails } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../../../../../../util/toastUtils';
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import { RiAlertFill } from 'react-icons/ri';
import axios from 'axios';
function ViewSubCategorycomponent({...props}) {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState(props?.userState?.rowSelected || '')

    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const index = 0;
    const categoryData = useSelector(viewCategoryData);
    const SubcategoryName = selectedItem?.subCategory?.[index]
    const TopicName = selectedItem?.topic
    const [editedName, setEditedName] = useState('');
    const goToCategoryDetail = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'CategoryDetailsPage' })
        }
    }
    const goToSubCategoryDetailsPage = (row) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewsubcategory', rowSelected: row }) // passing this to useSate so sending in riwSelected
        }
    }
    const categoryId = categoryData?.category?.id
    const id =  selectedItem?.id
  
    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };
    const goToTopicDetailsPage = (row) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewtopic', rowSelected: row })
        }
    }

    function handleClickTopic(row) {
        goToTopicDetailsPage(row);
        const payload = {
            // id: row.id,
            category_id: categoryId,
            type: 1
        };
        dispatch(viewCategoryDetails(payload));
    }
    const handleDelete = () => {
        // Check if category has associated subcategories or topics
        if (categoryData?.topic?.length > 0) {
            // Display error message
            setErrorMessage('Cannot delete category with associated subcategories or topics.');
        } else {
            const payload = {
                id: selectedItem.id,
                type: 2
            };
            // Dispatch deleteCategory action
            dispatch(deleteCategory(payload))
                .then(() => {
                    // If deletion is successful, call getCategoryListData to update the table
                    const payload = {
                        type: 2,
                        category_id: categoryId
                    };
                    dispatch(viewCategoryDetails({ ...payload, category_id: categoryId }));
                    goToCategoryDetail();
                    setShowModal(false);
                })
                .catch((error) => {
                    // Handle any errors, such as failed deletion
                    setErrorMessage('Error deleting category:', error);
                    setShowModal(true);
                });
        }
    };

    const openModal = () => {
        setShowModal(true);
        setErrorMessage(''); // Reset error message when modal is opened n
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const customStyle = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
                textAlign: "center"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontWeight: "bold"
            }
        },
    };
    const column = [
        {
            name: 'Date',
            cell: (row,) => {
                return (
                    <div>
                        {row.created_at ? dateFunction(row.created_at) : ""}
                    </div>
                );
            },
            sortable: true,
        },
        {
            name: 'Topic',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: true
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <button type="button" class="btn text-light px-xxl-3 px-xl-3 px-lg-3 px-md-2 py-1 bootBtn" onClick={() => handleClickTopic(row)}>Details</button>
                </div>
            )
        }
    ]
    const getSubTopicData = () => {
        return categoryData?.topic?.filter(m => m.sub_category_id == props?.userState?.rowSelected?.id)
    }
    const goToAddTopic = (row) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminAddTopic', categoryName: categoryData });
        }
    };


    const handleSave = () => {
        // Prepare the data for the API request
        const requestData = {
            category_id: categoryId,
            name: editedName,
            id:id
        };
    
        // Prepare headers with the authorization token
        const headers = { 'Authorization': 'Bearer ' +(localStorage.getItem('token')) }
        // Make an HTTP request to the API endpoint
        axios.post('http://tuition.techiesmarkets.com/backend/public/api/editSubCategory', requestData, { headers })
            .then(response => {
                // Handle success response
                console.log('Category updated successfully:', response.data);
                showSuccessToast('subcategory updated successfully');
                // You may want to update the UI or take any other action upon successful update
            })
            .catch(error => {
                // Handle error response
                console.error('Error updating category:', error);
                // You may want to display an error message to the user or take any other action
            });
    };

    return (
        <div>
            <div class='main-divHead'>
                <div className='sub-Head1'>
                    <div className="Sub-Head2" onClick={(e) => goToCategoryDetail(e)} >
                        <span><AiOutlineLeft className='mb-1 me-2' /></span>SubCategory Details</div>
                </div>
                <div className='row mt-5 mb-5'>
                    <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center">
                        <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 px-lg-0 mt-3 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0'>
                            <button type="button" className="btn text-light px-xxl-3 px-xl-3 px-lg-2 px-md-3 px-3 py-1 bootBtn" onClick={(e) => goToAddTopic()}>Add Topic</button>
                        </div>
                        {/* <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12 ps-lg-0 mt-3 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                    <button type="button" className="btn text-light px-xxl-5 px-xl-5 px-lg-4 px-md-3 px-5 py-1 bootBtn" onClick={(e) => goToAddTopic()}>Add Topic</button>
                                </div> */}
                    </div>
                </div>
                <div class="row mt-5">
                    <div class='d-flex justify-content-center'>
                        <div class="col-xxl-8 col-xl-8 col-lg-11">
                            <div class='card adminSubCard'>
                                <div class="container text-start">
                                    <div class="row">
                                        <div class='d-flex justify-content-center'>
                                            <div class="col">
                                                <p className='admin-sub-head-card'>Category :</p>
                                            </div>
                                            <div class="col">
                                                <p className='admin-sub-head-card'>{categoryData?.category?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class='d-flex justify-content-center'>
                                            <div class="col">
                                                <p className='admin-sub-head-card'>Sub Category :</p>
                                            </div>
                                            <div class="col admin-sub-head-card">
                                                <ul className='admin-sub-head-card'>
                                                    {selectedItem?.name}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="row">
                                    <div class='d-flex justify-content-center'>
                                        <div class="col">
                                            <p className='admin-sub-head-card'>Topic :</p>
                                        </div>
                                        <div class="col">
                                            <ul className='admin-sub-head-card'>
                                                {categoryData?.topic?.map((e, index) => (
                                                    <li key={index}>
                                                        {e.name}
                                                        {index < categoryData?.topic?.length - 1}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                                    {/* <div class="row">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p className='admin-sub-head-card'>Status :</p>
                                        </div>
                                        <div class="col-3">
                                            {categoryData?.category?.status === "0" ? <span class='text-danger fw-bold fs-5'>Inactive</span> : <span class='text-success fw-bold fs-5'>Active</span>}
                                        </div>
                                    </div>
                                </div> */}
                                    <div className='container'>
                                        <div className='d-flex justify-content-end'>
                                            <div className='row'>
                                                <div className='col'>
                                                    <button className="btn text-light px-xxl-3 px-xl-3 px-lg-2 px-md-3 px-3 py-1 bootBtn" data-bs-toggle="modal" data-bs-target="#exampleModal1">Edit</button>
                                                </div>
                                                <div className='col'>
                                                    <button className="btn text-light px-xxl-3 px-xl-3 px-lg-2 px-md-3 px-3 py-1 bootBtn" onClick={openModal} data-bs-toggle="modal" data-bs-target="#exampleModal" >Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`modal fade ${showModal ? 'show' : ''}`} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content p-4">
                            <div className="container text-center">
                                <div class="row">
                                    <div className="col">
                                        {errorMessage && <div className="error-message" ><RiAlertFill style={{ fontSize: "90px", color: "red" }} className="error-icon" /></div>}
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col">
                                        {errorMessage && <div className="error-message" >{errorMessage}</div>}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        {!errorMessage && <h1 className="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want to delete Category?</h1>}
                                        {!errorMessage && (
                                            <>
                                                <button type="button" className="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}>Cancel</button>
                                                <button type="button" className="btn btn-danger mt-3 ms-5 px-4" data-bs-dismiss="modal" aria-label="Close" onClick={handleDelete} >Delete</button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Sub Category</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="modal-body">
                                    <div className='row'>
                                        <div className='col'>
                                            <p> Category </p>
                                        </div>
                                        <div className='col'>
                                            <input type='text' placeholder= {categoryData?.category?.name} className='rounded-pill py-1 px-2' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <p>Current Sub Category </p>
                                        </div>
                                        <div className='col'>
                                            <input type='text' placeholder= {selectedItem?.name} className='rounded-pill py-1 px-2 editedname'
                                               />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <p>Enter New Sub Category </p>
                                        </div>
                                        <div className='col'>
                                            <input type='text'  className='rounded-pill py-1 px-2 editedname'
                                                onChange={(e) => setEditedName(e.target.value)} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" onClick={handleSave}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col mb-5'>
                        <DataTable
                            className='dataTable mt-5'
                            data={TopicName || getSubTopicData()}
                            columns={column}
                            fixedHeader={true}
                            fixedHeaderScrollHeight="600px"
                            striped
                            customStyles={customStyle}
                            persistTableHead
                            pagination
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewSubCategorycomponent