import React, { useEffect } from 'react'
import { CiCircleChevDown } from 'react-icons/ci';
import _ from 'lodash';
import { CgProfile } from 'react-icons/cg';
import { LuLogOut } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { userLogout } from '../../../../../../redux/slice/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileData, getUserProfile } from '../../../../../../redux/slice/studentSlice';
import { FaBell } from "react-icons/fa";
function AdminNavbar(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const goToDetailsPage = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminProfilePage' });
        }
    };

    const gotoNotofication = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminMessages' })
        }
    }
    const authToken = useSelector((state) => state.auth.Token);
    const handleLogout = () => {
        dispatch(userLogout({ authToken, navigate }));
    };
    useEffect(() => {
        dispatch(getUserProfile())
    }, [])
    const ProfileDetails = useSelector(ProfileData)
    return (
        <div class='py-2 admin-navbar '>
            <div class="container text-center">
                <div class="row">
                    <div class='d-flex justify-content-between'>
                        <div class="col-5">
                            <div class='d-flex mb-0'>
                                <input
                                    type="text"
                                    className="form-control rounded-pill"
                                    placeholder="search ... "
                                />
                                <button type="button" class="btn admin-navbar-search-btn text-light px-3 ms-3">Search</button>
                            </div>
                        </div>
                        <div className='col-2 col-md-3 col-lg-3 col-xl-2 d-flex justify-content-end ms-5'>
                            <FaBell style={{ color: "#5C5AB6" }} onClick={() => gotoNotofication()} />
                        </div>
                        <div class="col-2 col-md-3 col-lg-3 col-xl-2">
                            <div class="container text-start">
                                <div class="row">
                                    <div class="col-4 px-0">
                                        {/* <img src={img} class="img-fluid rounded-top" alt="..."  /> */}
                                    </div>
                                    <div class="col px-0">
                                        {/* <p class='mb-0 fw-bold'>David</p> */}
                                        <p class='mb-0 fs-6 fw-bold text-uppercase'>{ProfileDetails?.firstname}</p>
                                    </div>
                                    <div class="col px-0 align-self-center">
                                        <CiCircleChevDown class='admin-nav-icon' data-bs-toggle="dropdown" aria-expanded="false" />
                                        <div class="dropdown ">
                                            <ul class="dropdown-menu mt-3">
                                                <li><a class="dropdown-item" onClick={() => goToDetailsPage()} > <span><CgProfile className='me-2 mb-1' /></span>Profile</a></li>
                                                <li><a class="dropdown-item" onClick={() => handleLogout()}> <span><LuLogOut className='me-2 mb-1' /></span>Logout</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminNavbar