import React, { useState } from 'react'
import _ from 'lodash';
import { MdOutlineAssignment } from "react-icons/md";


function AssignmentTeacher(props) {

    const goToTeacherAssignment = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherassignment' })
        }
    }
    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'Teacherassignment':
            case 'createnewassignment':
            case 'viewassignment':
            case 'viewstudentlist':
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };
    return (
        <div className={determineClassName()}>
            <div className="dash d-flex">
                <MdOutlineAssignment class="ms-3" />
                <p className='head-dashboard ms-2' onClick={(e) => goToTeacherAssignment(e)}>Assignments</p>
            </div>
        </div >
    )
}

export default AssignmentTeacher