import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { ActiveTeacherID, TeacherVideoRec, activeTeacherID, teacherLoading, teacherStatusChange, viewTeacherData, viewTeacherList } from '../../../../../../redux/slice/teacherSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { AiOutlineLeft } from 'react-icons/ai';
import { RotatingLines } from 'react-loader-spinner';
import AdminTeacherDocumentComponent from './adminTeacherDocument.component';
import DataTable from 'react-data-table-component';
import { FaEye } from 'react-icons/fa';
import { BsFiletypePdf } from 'react-icons/bs';
import Axios from '../../../../../../util/apiUtil';
import { getCSTData } from '../../../../../../redux/slice/SubjectSlice';
import { showErrorToast, showSuccessToast } from '../../../../../../util/toastUtils';

function AdminTeacherDetailsComponent(props) {
    const dispatch = useDispatch();
    const teacherId = useSelector(activeTeacherID);
    const teacherData = useSelector(viewTeacherData);
    const loading = useSelector(teacherLoading);

    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    useEffect(() => {
        const payload = {
            "teacher_id": teacherId
        }
        dispatch(viewTeacherList(payload));
    }, [dispatch, teacherId]);

    const formik = useFormik({
        initialValues: {
            status: teacherData?.status === 'Inactive' ? 0 : 1,
        },
        validationSchema: Yup.object({
            status: Yup.string().required('Status is required'),
        }),
        onSubmit: (values) => {
            const payload = {
                "teacher_id": teacherId,
                "status": values.status === 'Inactive' ? 0 : 1,
            }
            console.log("payload", payload);
            const teacher = {
                "teacher_id": teacherId,
            }
            const valueToSend = {
                payload,
                teacher
            }
            dispatch(teacherStatusChange(valueToSend));
        },
    });

    const goToDocument = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminTeacherDocument' });
        }
    };

    const goToWallet = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminWallet' });
        }
    };

    const goToRecordVideo = (data) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminRecordVideo' });
        }
    };

    const goToTTeacherWallet = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminTeacher' })
        }
    }

    const goToTeacherCourseDetails = (row) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherCourse', rowSelected: row })
        }
    }

    function handledetails(row) {
        const payload = {
            "teacher_id": teacherId
        }
        dispatch(viewTeacherList(payload));
        goToTeacherCourseDetails(row);
    }

    const customStyle = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const column = [
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true
        },
        {
            name: 'Sub Category',
            selector: row => row.sub_category,
            sortable: true
        },
        {
            name: 'Topic',
            selector: row => row.subject,
            sortable: true
        },
        {
            name: 'Course Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Details',
            sortable: true,
            cell: (row) => (
                <div>
                    <button type="button" className="btn text-light px-xxl-3 px-xl-3 px-lg-3 px-md-2 py-1 bootBtn" onClick={() => handledetails(row)}>Details</button>
                </div>
            )
        }
    ]
    const openDocument = (url) => {
        console.log(`Opening document at: ${url}`);
        const encodedUrl = encodeURI(url);
        const newWindow = window.open(encodedUrl, '_blank');
        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
            // Pop-up was blocked
            alert('Pop-up blocker is enabled! Please allow pop-ups for this website.');
        }
    };

    const basePath = "http://tuitionguruji.com/backend/public/";

    const [categoryData, setCategoryData] = useState([]);
    const [approvedCategories, setApprovedCategories] = useState({});

    const handleApprove = (categoryId) => {
        console.log("categoryId", categoryId)
        setApprovedCategories((prevApproved) => ({
            ...prevApproved,
            [categoryId]: !prevApproved[categoryId],
        }));
    };

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await Axios.post('api/getCategoryandTopicDetails', { type: 1 });
                if (response.data.success) {
                    setCategoryData(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching category data:', error);
            }
        };

        fetchCategoryData();
    }, []);


    const getCategoryNameById = (id) => {
        const category = categoryData.find(cat => cat.id === id);
        console.log("category", category);
        return category ? category.name : 'Unknown Category';

    };


    const handleQualificationApproval = async (categoryId) => {
        // Convert IDs to integers
        const teacher_id = teacherData.id
        const qualification_id = teacherData.pending_qualification.length > 0 ? teacherData.pending_qualification[0].id : null;


        // Rest of your code remains the same
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
        const formData = new FormData();
        formData.append('qualification_id', qualification_id);
        formData.append('teacher_id', teacher_id);
        formData.append('status', 1);
        try {
            const response = await Axios.post('api/changeTeacherQaulificationStatus', formData, { headers });
            if (response.status >= 200 && response.status < 300) {
                // Handle success
                showSuccessToast('Qualification status updated successfully');
            } else {
                // Handle error
                console.error('Failed to update qualification status');
            }
        } catch (error) {
            // Handle network error
            console.error('Network error:', error);
        }
    };
    const handleQualificationdocumentApproval = async (categoryId) => {
        // Convert IDs to integers
        const teacher_id = teacherData.id
        const qualification_id = teacherData.pending_qualification.length > 0 ? teacherData.pending_qualification[0].id : null;
        console.log("qualification_id", qualification_id);


        // Rest of your code remains the same
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
        const formData = new FormData();
        formData.append('qualification_id', qualification_id);
        formData.append('teacher_id', teacher_id);
        formData.append('status', 1);
        formData.append('document', 1);
        try {
            const response = await Axios.post('api/changeTeacherQaulificationStatus', formData, { headers });
            if (response.ok) {
                // Handle success
                console.log('Qualification Approved');
            } else {
                // Handle error
                console.error('Failed to update qualification status');
            }
        } catch (error) {
            // Handle network error
            console.error('Network error:', error);
        }
    };
    const handleCategoryApproval = async (categoryId) => {
        // Convert IDs to integers
        const teacher_id = teacherData.id;
        const qualification_id = teacherData.pending_qualification.length > 0 ? teacherData.pending_qualification[0].id : null;
        const user_cat_id = teacherData.pending_category.length > 0 ? teacherData.pending_category[0].id : null;


        // Rest of your code remains the same
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
        const formData = new FormData();
        formData.append('user_cat_id', user_cat_id);
        formData.append('teacher_id', teacher_id);
        formData.append('status', 1);

        try {
            const response = await Axios.post('api/changeTeacherCategoryStatus', formData, { headers });

            // Check if the response status is in the range 200-299
            if (response.status >= 200 && response.status < 300) {
                // Handle success
                showSuccessToast('category Approved');
            } else {
                // Handle error
                showErrorToast("Approve Minimum One Qualification with Document");
            }
        } catch (error) {
            // Handle network error
            console.error('Network error:', error);
            showErrorToast('Network error: ' + error.message);
        }
    };




    return (
        <div>
            <div className="main-divHead">
                <div className="sub-Head1">
                    <div className="Sub-Head1" ><span><AiOutlineLeft className='mb-1 me-2' onClick={goToTTeacherWallet} /></span>Teachers</div>
                </div>
                <div className="mx-5">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="container text-start">
                            <div className='row'>
                                <div className="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center"><div className='col-xxl-9 col-xl-9 col-lg-12'>
                                    <p className='admin-sub-head my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3'>Teacher Details</p>
                                </div></div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-center">
                                    <div className="col-xxl-9 col-xl-9 col-lg-12">
                                        <div className="adminSubCard p-3">
                                            <div>
                                                <div className="container text-start">
                                                    <div className="row ms-3">
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="col">
                                                                <p className='admin-sub-head-card'>Date : </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className='admin-sub-head-card'>{teacherData?.created_at ? dateFunction(teacherData.created_at) : ""}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row  ms-3">
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="col">
                                                                <p className='admin-sub-head-card'>Teacher Name : </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className='admin-sub-head-card'>{teacherData?.firstname}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row  ms-3 ">
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="col">
                                                                <p className='admin-sub-head-card'>Email : </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className='admin-sub-head-card'>{teacherData?.email}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row ms-3">
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="col">
                                                                <p className='admin-sub-head-card'>Phone : </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className='admin-sub-head-card'>{teacherData?.phone_number}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row ms-3">
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="col">
                                                                <p className='admin-sub-head-card'>Country : </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <span className=''><p className='admin-sub-head-card'>India</p></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row ms-3">
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="col">
                                                                <p className='admin-sub-head-card'>Teaching Language : </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className='admin-sub-head-card'><span className=''>English</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row ms-3">
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="col">
                                                                <p className='admin-sub-head-card'>Teaching Experience : </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className='admin-sub-head-card'><span className=''>{teacherData?.experience}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row ms-3">
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="col">
                                                                <p className='admin-sub-head-card'>Education :  </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className='admin-sub-head-card'><span className=''>{teacherData?.education}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='admin-sub-head-card d-flex justify-content-center mt-3 text-decoration-underline'>Other Qualification</p>
                                                    <div className="row ms-3">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="col">
                                                                <p className="admin-sub-head-card">Category:</p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className="admin-sub-head-card">
                                                                    <span>
                                                                        {teacherData?.pending_category?.map((category, index) => (
                                                                            <div key={index} className="mt-3">
                                                                                {getCategoryNameById(parseInt(category.category_id))}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-success ms-3"
                                                                                    onClick={() => handleApprove(category.id)}
                                                                                >
                                                                                    Details
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-success ms-3"
                                                                                    onClick={() => handleCategoryApproval()}
                                                                                >
                                                                                    Approve
                                                                                </button>
                                                                            </div>

                                                                        ))}
                                                                    </span>

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {Object.keys(approvedCategories).map((categoryId, values) =>
                                                        approvedCategories[categoryId] ? (
                                                            
                                                            <div key={categoryId} className="row ms-3">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="col">
                                                                        <p className="admin-sub-head-card">Qualification:</p>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <p className="admin-sub-head-card">
                                                                            <span>
                                                                                {teacherData?.pending_qualification
                                                                                    ?.filter((qualification) => qualification.cat_id === categoryId)
                                                                                    .map((qualification) => (
                                                                                        <div key={qualification.id}>
                                                                                            <div>{qualification.name}</div>

                                                                                        </div>
                                                                                    ))}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="col">
                                                                        <p className="admin-sub-head-card">Course Name:</p>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <p className="admin-sub-head-card">
                                                                            <span>
                                                                                {teacherData?.pending_qualification
                                                                                    ?.filter((qualification) => qualification.cat_id === categoryId)
                                                                                    .map((qualification) => (
                                                                                        <div key={qualification.id}>
                                                                                            <div>{qualification.name}</div>

                                                                                        </div>
                                                                                    ))}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="col">
                                                                        <p className="admin-sub-head-card">Organisation Name:</p>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <p className="admin-sub-head-card">
                                                                            <span>
                                                                                {teacherData?.pending_qualification
                                                                                    ?.filter((qualification) => qualification.cat_id === categoryId)
                                                                                    .map((qualification) => (
                                                                                        <div key={qualification.id}>
                                                                                            <div>{qualification.org_name}</div>

                                                                                        </div>
                                                                                    ))}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <p className='admin-sub-head-card d-flex justify-content-center mt-3 text-decoration-underline'>Supporting Documents </p>
                                                                <div className="row ms-3">
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div className="col">
                                                                            <p className='admin-sub-head-card'> <BsFiletypePdf className='adminPdfIcon me-3 ms-5' />{" "}{" "}Experience Certificate   </p>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-4">
                                                                                <p className='admin-sub-head-card'> <span>
                                                                                    {teacherData?.pending_qualification
                                                                                        ?.filter((qualification) => qualification.cat_id === categoryId)
                                                                                        .map((file) => (
                                                                                            <p key={file.id} className="admin-sub-head-card">
                                                                                                <span className="">
                                                                                                    <FaEye
                                                                                                        onClick={() => openDocument(`${basePath}${file.exp_document || file.document}`)} // Use exp_document or document based on your logic
                                                                                                        className="mb-1 me-3 cursor-pointer"
                                                                                                    />
                                                                                                </span>
                                                                                            </p>
                                                                                        ))}
                                                                                </span>


                                                                                </p>
                                                                            </div>
                                                                            <div className='col'>
                                                                                <div className='form-check'>
                                                                                    <input
                                                                                        type='checkbox'

                                                                                        className={`form-check-input  border-success`}
                                                                                        onChange={() => handleQualificationdocumentApproval()}
                                                                                    />
                                                                                    <input
                                                                                        type='checkbox'

                                                                                        className={`form-check-input ms-3   border-danger`}
                                                                                        onChange={() => handleQualificationdocumentApproval()}
                                                                                    />
                                                                                </div>
                                                                                {/* <ErrorMessage
                                                                                    name={`checkbox${index * 2 + 1}Checked`}
                                                                                    component='div'
                                                                                    className='error-message'
                                                                                /> */}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="row ms-3">
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div className="col">
                                                                            <p className='admin-sub-head-card'> <BsFiletypePdf className='adminPdfIcon me-3 ms-5' />{" "}{" "}Course Certificate   </p>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-5">
                                                                                <p className='admin-sub-head-card'> <span>
                                                                                    {teacherData?.pending_qualification
                                                                                        ?.filter((qualification) => qualification.cat_id === categoryId)
                                                                                        .map((file) => (
                                                                                            <p key={file.id} className="admin-sub-head-card">
                                                                                                <span className="">
                                                                                                    <FaEye
                                                                                                        onClick={() => openDocument(`${basePath}${file.document || file.document}`)} // Use exp_document or document based on your logic
                                                                                                        className="mb-1 me-3 cursor-pointer"
                                                                                                    />
                                                                                                </span>
                                                                                            </p>
                                                                                        ))}
                                                                                </span></p>
                                                                            </div>
                                                                            <div className='col'>
                                                                                <div className='form-check'>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        name='document'
                                                                                        className={`form-check-input  border-success`}
                                                                                        onChange={() => handleQualificationdocumentApproval()}
                                                                                    />
                                                                                    <input
                                                                                        type='checkbox'

                                                                                        className={`form-check-input ms-3   border-danger`}
                                                                                        onChange={() => handleQualificationdocumentApproval()}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button type="button" class="btn btn-success" onClick={() => handleQualificationApproval(categoryId)}>Qualification Approved</button>
                                                            </div>
                                                        ) : null
                                                    )}




                                                    {/* <div class="row ms-3">
                                                        <div class='d-flex justify-content-between'>
                                                            <div class="col">
                                                                <p class='admin-sub-head-card'>Status : </p>
                                                            </div>
                                                            <div class="col-5">
                                                                <p class='admin-sub-head-card'>{teacherData.status === '1' ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}</p>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div class="row ms-3">
                                                        {/* <div class='d-flex justify-content-between'>
                                                            <div class="col">
                                                                <p class='admin-sub-head-card'>Supporting Documents :  </p>
                                                            </div>
                                                            <div class="col-5">
                                                                <p class='admin-sub-head-card '><span class='text-success fw-bold me-2'>Approved</span>
                                                                    <span>
                                                                        <button type="button" class="btn btn-outline adminOPbtn ms-xxl-4 ms-xl-4 ms-lg-4 px-xxl-4  px-lg-2"   >View <span class='ms-2'><FaArrowRightLong /></span></button></span></p>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    {/* <div class="row ms-3">
                                                        <div class='d-flex justify-content-between'>
                                                            <div class="col">
                                                                <p class='admin-sub-head-card'>Resources :  </p>
                                                            </div>
                                                            <div class="col-5">
                                                                <p class='admin-sub-head-card'><span class='text-warning fw-bold'>Pending</span><span>
                                                                    {/* <button type="button" class="btn btn-outline adminOPbtn ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5  px-xxl-4  px-lg-2" 
                                                                    onClick={(e) => goToRecordVideo(e)}
                                                                    >View <span class='ms-2'><FaArrowRightLong /></span>
                                                                    </button> */}
                                                    {/* </span></p>
                                                            </div>
                                                        </div>
                                                    </div>  */}
                                                    {/* <div class='d-flex justify-content-end'>
                                                        <div class="row ms-3">
                                                            <div class="col">
                                                                <button className='btn text-light px-5 py-1 bootBtn my-5' onClick={(e) => goToWallet()}>Go To Wallet</button>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='adminSubCard mt-5'>
                                            <AdminTeacherDocumentComponent rowSelected={props.userState.rowSelected} />
                                        </div> */}
                                        {/* <div className="row ms-lg-5 ms-xxl-0 ms-xl-0 ps-lg-5 ps-xxl-0 ps-xl-0 my-4">
                                            <div className="d-flex justify-content-end">
                                                <div className="col-xxl-1 col-xl-1 col-lg-2 mt-1 mt-1">
                                                    <p className='admin-sub-head-card mt-1'>Status:</p></div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-5">
                                                    <div className="form-group">
                                                        <select
                                                            className="form-select"
                                                            id="status"
                                                            name="status"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.status}
                                                        >
                                                            <option value="Active">Active</option>
                                                            <option value="DeActive">Inactive</option>
                                                        </select>
                                                        {formik.touched.status && formik.errors.status ? (
                                                            <div className="text-danger">{formik.errors.status}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-1"></div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div class='container text-center'>
                                <div class='row'>
                                    <div class='col'>
                                        <button type='submit' disabled={loading} class='btn btn-primary text-light px-5 py-1 bootBtn my-5' onClick={formik.handleSubmit}>
                                            {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                                                strokeColor="white"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="25"
                                                height="25"
                                                visible={true}
                                            /></div> : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </form >
                </div >
                {/* <div class="row">
                    <DataTable
                        className='dataTable mt-5 '
                        data={namesArray}
                        columns={column}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="600px"
                        striped
                        customStyles={customStyle}
                        persistTableHead
                        pagination
                    />
                </div> */}
            </div >
        </div >
    );
}

export default AdminTeacherDetailsComponent;
