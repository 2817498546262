import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import axios from 'axios';
import profile from '../../../../../../images/profile.png'
import StarRatings from "react-star-ratings";
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function Review(props) {

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'ViewSubject' })
        }
    }
    const [viewSub, setViewSub] = useState([{

    }])
    const [view, setView] = useState()
    const ViewBatch = props.userState.row.id || {};

    const fetchSubject = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const CreateNotifi = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("subject_id", ViewBatch)
        Axios.post('/api/viewSubject',
            formData,
            { headers: headers })
            .then((response) => {
                const updateData = response.data.data
                setView(updateData)
            })
            .catch((error) => {
                console.error("Error fetching subject data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });
    }
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])

    const initializeObject = () => {
        setViewSub({ ...viewSub, ...props.userState.row })
    }

    useEffect(() => {
        fetchSubject();
    }, []);

    const ratings = _.get(view, 'ratings', []);

    let rating = 0; // Define the rating variable and initialize it with a default value

    // Calculate average rating
    let totalRating = 0;
    let numberOfRatings = ratings.length;

    if (numberOfRatings > 0) {
        for (let i = 0; i < numberOfRatings; i++) {
            totalRating += parseFloat(ratings[i].ratings);
        }
        const averageRating = totalRating / numberOfRatings;
        rating = parseFloat(averageRating.toFixed(2)); // Keep only two decimal places
    }
    console.log("Rating", rating);

    const [showNext, setShowNext] = useState(false);
    const goToAllRating = (e, link) => {
        setShowNext(true);
    }


    return (
        <div className='main-divHead' >

            <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} onClick={Backdashboard} />
                <div className='Sub-Head2'>Review</div>
            </div>



            <div className="rating-bg px-5 py-5" style={{ background: "#EAE9FB" }}>
                <h1 className="review_heading">Review and Ratings</h1>
                <div className="row">
                    {ratings.map((rating, index) => (
                        <div key={index} className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                            <div className="card mb-5 mt-5 border-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-0 col-xl-0 col-lg-0 col-md-12 col-sm-12">
                                            <p className='p_review' >{rating.firstname}</p>
                                            <span>
                                                <StarRatings
                                                    className="sub-rating-star"
                                                    starRatedColor={"#F2DE25"}
                                                    starDimension={"20px"}
                                                    rating={parseFloat(rating.ratings)}
                                                    starHoverColor="black"
                                                    starSpacing="1px"
                                                />
                                                <span className="fw-bold ms-2">{parseFloat(rating.ratings).toFixed(1)}</span>

                                            </span>
                                            <div className='col'>
                                                <p className="p-rating-date mt-2">{rating.created_at ? new Date(rating.created_at).toLocaleDateString() : ''}</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col">
                                            <p className='p_review'>{rating.review || "No review available"}</p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Review