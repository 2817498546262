import React from 'react'
import { BsArrowRight } from 'react-icons/bs'

function DashboardNotificationComponent() {
    return (
        <div>
            <div class='admin-card p-3'>
                <div class="container text-center">
                    <div class="row">
                        <div class="col">
                            <p class='fw-bold mb-0'>Notifications</p>
                        </div>
                        <div class="col">
                            <p class='mb-0'>See all <span><BsArrowRight className='ms-2' /></span></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <hr></hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardNotificationComponent