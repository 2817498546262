import React from "react"
import "../../styles/landing_page.scss"
import Navbar from "./contents/Section1_Navbar"
import HeadSectionBelowNav from "./contents/Section2_HeadSearch"
import WhoYouAre from "./contents/Section3_ChooseWhoYouAre"
import Provisions from "./contents/Section4_DesiredTopic"
import Footer from "./contents/Section5_footer"
// import provisionsDataHomepage from "../../components/data/homeProvisions"
import Partner from "./contents/Section4.1_Partner"
import Clients from "./contents/miniComponent/Clients"
import LandingPageImage from "../../images/landing_page_images/combined.jpg"
import PartnerBGImage from "../../images/partner.jpg"
import DesiredTopic from "./contents/miniComponent/DesiredTopic"
import PartnerLanding from "./partner"

function LandingPage() {
  return (
    <div className='landing_page'>
      <Navbar />
      <HeadSectionBelowNav img={LandingPageImage} input={true} />
      <PartnerLanding />
      <WhoYouAre />
      <DesiredTopic />
      <Clients />
      {/* <Provisions provisionsData={provisionsDataHomepage} /> */}0++
      <Partner partner={PartnerBGImage} />
      <Footer />e
    </div>
  )
}

export default LandingPage
