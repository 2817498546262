import React, { useState } from "react"
import _ from 'lodash';
import { PiStudent } from "react-icons/pi";


function StudentTeacher(props) {
    const goToTeacherStudent = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherstudent' })
        }
    }
    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'Teacherstudent':
            case 'createbatch':
            case 'teacherstudentview':
            case 'teacherstudentedit':
            case 'addresource':
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };
    return (
        <div className={determineClassName()} >
            <PiStudent class="ms-3" />
            <div className='head-dashboard   ms-2'  onClick={()=>goToTeacherStudent()}>Students</div>
        </div>
    )
}
export default StudentTeacher