import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BsFiletypePdf } from 'react-icons/bs';
import { AiFillEye, AiOutlineLeft } from 'react-icons/ai';
import { activeTeacherID, teacherDocumentStatusChange } from '../../../../../../redux/slice/teacherSlice';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

function InstructorDocumentDetailsComponent(props) {

    const dispatch = useDispatch();
    const teacherId = useSelector(activeTeacherID);
    

    const validationSchema = Yup.object().shape({
        checkbox1Checked: Yup.boolean().required('Please select an option'),
        checkbox2Checked: Yup.boolean().required('Please select an option'),
        checkbox3Checked: Yup.boolean().required('Please select an option'),
        checkbox4Checked: Yup.boolean().required('Please select an option'),
        checkbox5Checked: Yup.boolean().required('Please select an option'),
        checkbox6Checked: Yup.boolean().required('Please select an option'),
    });

    const handleCheckboxChange = (fieldName, setFieldValue, values) => (e) => {
        const value = e.target.checked;
        setFieldValue(fieldName, value);
        const pairNumber = parseInt(fieldName.match(/\d+/)[0]);
        const otherFieldName = `checkbox${pairNumber % 2 === 1 ? pairNumber + 1 : pairNumber - 1}Checked`;
        setFieldValue(otherFieldName, !value);
    };


    const goToAdminInstructorDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminInstructorDetails' });
        }
    };


    return (
        <Formik
            initialValues={{
                checkbox1Checked: true,
                checkbox2Checked: false,
                checkbox3Checked: true,
                checkbox4Checked: false,
                checkbox5Checked: true,
                checkbox6Checked: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                if (values.checkbox1Checked && values.checkbox3Checked && values.checkbox5Checked) {
                    const valueToSend = {
                        "teacher_id": teacherId,
                        "status": 1
                    }
                    // dispatch(teacherDocumentStatusChange(valueToSend));
                }
                else {
                    const valueToSend = {
                        "teacher_id": teacherId,
                        "status": 0
                    }
                    // dispatch(teacherDocumentStatusChange(valueToSend));
                }
            }}
        >
            {({ handleSubmit, setFieldValue, values }) => (
                <form onSubmit={handleSubmit}>
                    <div className='main-divHead'>
                        <div className='sub-Head1'>
                        <div className="Sub-Head2" onClick={(e) => goToAdminInstructorDetails(e)}><span><AiOutlineLeft className='mb-1 me-2'/></span>Uploader</div>
                        </div>
                        <div className='mx-5'>
                            <p className='adminSubHead my-3 fw-semibold'>Supporting Documents</p>
                            <div className='container text-center my-5'>
                                <div className='container text-center'>
                                    {Array.from({ length: 3 }, (_, index) => (
                                        <div className='row' key={index}>
                                            <div className='d-flex justify-content-center'>
                                                <div className='col-1'>
                                                    <BsFiletypePdf className='adminPdfIcon' />
                                                </div>
                                                <div className='col-xxl-2 col-xl-2 col-lg-4'>
                                                    <p className='fs-6'>educationdocument.pdf</p>
                                                </div>
                                                <div className='col-1 ms-5'>
                                                    <div className='admin-card-eye'>
                                                        <AiFillEye className='adminEyeIcon' />
                                                    </div>
                                                </div>
                                                <div className='col-1'>
                                                    <div className='form-check'>
                                                        <Field
                                                            type='checkbox'
                                                            name={`checkbox${index * 2 + 1}Checked`}
                                                            className={`form-check-input ${values[`checkbox${index * 2 + 1}Checked`] ? 'bg-success' : ''} border-success`}
                                                            onChange={handleCheckboxChange(
                                                                `checkbox${index * 2 + 1}Checked`,
                                                                setFieldValue,
                                                                values
                                                            )}
                                                        />
                                                        <Field
                                                            type='checkbox'
                                                            name={`checkbox${index * 2 + 2}Checked`}
                                                            className={`form-check-input ms-3 ${values[`checkbox${index * 2 + 2}Checked`] ? 'bg-danger' : ''}  border-danger`}
                                                            onChange={handleCheckboxChange(
                                                                `checkbox${index * 2 + 2}Checked`,
                                                                setFieldValue,
                                                                values
                                                            )}
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        name={`checkbox${index * 2 + 1}Checked`}
                                                        component='div'
                                                        className='error-message'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='container text-center'>
                                <div className='row ms-5'>
                                    <div className='d-flex justify-content-center'>
                                        <div className='col-xxl-3 col-xl-3 col-lg-4'>
                                            <button type='button' className='btn btn-outline adminOPbtn my-5 px-4'>
                                                Ask Clarification
                                            </button>
                                        </div>
                                        <div className='col-xxl-3 col-xl-3 col-lg-4'>
                                            <button type='submit' className='btn btn-primary px-5 py-1 bootBtn my-5'>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}

export default InstructorDocumentDetailsComponent;
