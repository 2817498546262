import React from "react"
import Navbar from "../LandingPage/contents/Section1_Navbar"
import "../../styles/quick_demo.scss"
import HeadSectionBelowNav from "../LandingPage/contents/Section2_HeadSearch"
// import quickDemoHead from "../../images/head_quickDemo.png"
import Provisions from "../LandingPage/contents/Section4_DesiredTopic"
// import quickDemoProvisions from "../data/quickDemoProvisions"
import Footer from "../LandingPage/contents/Section5_footer"

function QuickDemo() {
  return (
    <div className='quick_demo_page'>
      <Navbar />
      <HeadSectionBelowNav
        // img={quickDemoHead}
        input={false}
        style={{ margin: "0 0 100px 0", border: "1px solid blue" }}
      />
      {/* <Provisions provisionsData={quickDemoProvisions} /> */}
      <Footer />
    </div>
  )
}

export default QuickDemo
