import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import assignmentWhiteIcon from "../../../icons/assignmentWhiteIcon.png"
import assignmentBlueIcon from "../../../icons/assignmentBlueIcon.png"
import _ from "lodash"

function Chats({ ...props }) {
  const navigate = useNavigate()
  // const [hoverEffect, setHoverEffect] = useState(false)
  const goToChat = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Chats' })
    }
  }
  return (
    <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'Chats' ? 'active' : ''} `}>

      <img
        src={_.get(props, 'userState.subType') != 'Chats' ? assignmentWhiteIcon : assignmentBlueIcon}
        alt='Chats'
      />
      <p className='head-assignment' onClick={(e) => goToChat(e)}>Chats</p>
      {/* */}
    </div>
  )
}

export default Chats;
