import React from 'react'
import { AiOutlineLeft } from 'react-icons/ai'
import SelectWho from '../LandingPage/contents/SelectWho'
import '../LandingPage/contents/Section3_ChooseWhoYouAre.scss'
import { useNavigate } from 'react-router-dom'

function SignUpAsComponent() {
const navigate =useNavigate ()
    return (   
        <div>
            <div className="sub-Head1">
                <div className="Sub-Head2" ><span><AiOutlineLeft className='mb-1 me-2' onClick={()=>navigate("/user/verify")} /></span>Sign up</div>
            </div>
            <p className='signUp_txt m-5'>Sign up as,</p>
            <SelectWho />
        </div>
    )
}

export default SignUpAsComponent