import React from "react";
import { Link, useNavigate } from "react-router-dom"
import "./SubComponentMainSection.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import bookimg from "../../../../../images/landing_page_images/bookimg.png";
import profileimg2 from "../../../../../images/landing_page_images/profileimg2.png"
const SubComponentMainSection = () => {
  const navigate = useNavigate()
  const liveClass = () => {
    navigate("/5.Studentchatside");
  }
  return (
    <div className="sub_component_section">
      <div className="head-enroll d-flex">
        <div className="faChevronLeft">
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      </div>
    </div>
  );
};

export default SubComponentMainSection;
