import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import ViewIcon from '../../../Sidebar/icons/ViewIcon.png'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import BASE_URL from '../../../../../../BaseUrl';

function TeacherTestStudentList(props) {

    const ViewBatch = props.userState.row.question_id || {};
    const [view, setView] = useState()
    const [testTeachers, setTestTeachers] = useState([{
    }])
    console.log("student!!!!", testTeachers);
    const fetchViewDetailas = () => {

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const formData = new FormData();
        formData.append("question_id", ViewBatch);

        axios.post(`${BASE_URL}/viewTest`, formData,
            { headers: headers })
            .then((response) => {
                const updateData = response.data.data
                setTestTeachers(updateData)
            })
            .catch((error) => {
                console.error("Error fetching test data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });

    }


    useEffect(() => {
        // Fetch data and get rows after the component mounts
        fetchViewDetailas()
    }, []);
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])
    const initializeObject = () => {
        setTestTeachers({ ...testTeachers, ...props.userState.row })
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Student Name',
            selector: row => row.firstname,

        },
        {
            name: 'Student Id',
            selector: row => row.student_id,
            sortable: true

        },
        {
            name: 'status',
            selector: row => row.completed,
            sortable: true

        },
        {
            name: 'Action',
            cell: (row, question_id) => (
                <img
                    src={ViewIcon}
                    className='view-assignment'
                    alt="viewicon"
                    onClick={(e) => goToTeacherViewStudentDetails(e, row, question_id)}
                />
            ),
        },
    ]


    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherTest' })
        }
    }

    const goToTeacherViewStudentDetails = (e, row) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherviewstudentdetails', })
        }
    }


    const formatDateWithoutTime = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    };


    const category = _.get(testTeachers, 'data.question[0].category', '');
    const subCategory = _.get(testTeachers, 'data.question[0].sub_category', '');
    const batch = _.get(testTeachers, 'data.batch_id', '');
    const topic = _.get(testTeachers, 'data.topic', '');
    const testDate = _.get(testTeachers, 'data.date', '');


    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}><FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Test</div>
            </div>

            <div className='view-student-assign'>Student List</div>


            <div class="container text-start teacher-cardss">
                <div class="card col-12">
                    <div class="card-body ">
                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
                                    <p className='techer-card-typo '>Category : </p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
                                <p className='techer-card-typo ' >{testTeachers.test?.[0]?.category}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <p className='techer-card-typo'>Sub category</p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo ' >{testTeachers.test?.[0]?.sub_category}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo '>Batch : </p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <p className='techer-card-typo ' >{testTeachers.test?.[0]?.batch_id}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo '>Topic : </p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <p className='techer-card-typo ' >{testTeachers.test?.[0]?.topic}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo '>Test Date : </p>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
                                <p className='techer-card-typo ' >{formatDateWithoutTime(testTeachers.test?.[0]?.date)}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div class="row ms-3 ">
                <div class="d-flex justify-content-center">
                    <div class="col-xxl-8 col-xl-10 col-lg-12 justify-content-center">
                        {_.get(testTeachers, 'test') && (
                            <DataTable
                                className='dataTable'
                                data={testTeachers.test}
                                columns={columns}
                                customStyles={customStyles}
                                pagination
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeacherTestStudentList