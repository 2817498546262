import React, { useState } from 'react'
import _ from "lodash"
import { HiMiniWallet } from "react-icons/hi2";


function WalletTeacher(props) {
    const goToTTeacherWallet = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherwallet' })
        }
    }

    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'Teacherwallet':
            case 'creditdetails':
            case 'requestwithdrawl':
            case 'addteacherbank':          
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };


    return (
        <div  className={determineClassName()}>
            <div className="dash d-flex">
                <HiMiniWallet class=" ms-3" />
                <p className='head-dashboard ms-2' onClick={()=>goToTTeacherWallet()} > Wallet
                </p>
            </div>
        </div>
    )
}

export default WalletTeacher