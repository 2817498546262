import React, { useState } from "react"
import dashboardWhiteIcon from "../../icons/dashboardWhiteIcon.png"
import dashboardBlueIcon from "../../icons/dashboardBlueIcon.png"

const TeacherSidebarMain = () => {
  const [hoverEffect, setHoverEffect] = useState(false)

  return (
    <div
      className='sidebar_content'
      //  onMouseOver={() => setHoverEffect(true)}
      // onMouseOut={() => setHoverEffect(false)}
    >
      <img
        src={hoverEffect ? dashboardBlueIcon : dashboardWhiteIcon}
        alt='Dashboard'
      />
      <p className='side_heading'>Dashboard</p> 
    </div>
  )
}

export default TeacherSidebarMain
