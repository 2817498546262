import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import './ViewAttendence.scss'
import axios, { Axios } from 'axios';
import { format } from 'date-fns';
import BASE_URL from '../../../../../../BaseUrl';


function ViewAttendence({ ...props }) {
    const ViewBatch = props.userState.row.batch_id || {};
    const ViewDate = props.userState.row.date || {}; // Add this line to access the date
    const [rows, setRows] = useState()
    const [view, setView] = useState()
    const [attendence, setAttendence] = useState([{
        student_id: "",
        firstname: "",
        batch_id: '',
        date: '',
        category: '',
        sub_category: '',
        topic: '',
        email: '',
        attendance: '',
        present: '',
        absent: '',
    }])


    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const fetchAttendenceData = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        // const requestData = {
        //     batch_id: '',
        //     date: '',
        // };
        const CreateNotifi = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();


        formData.append("batch_id", ViewBatch)
        formData.append("date", ViewDate)

        Axios.post('/api/viewAttendance', formData,
            { headers: headers })
            .then((response) => {
                console.log(response)
                const updateData = response.data.data
                setAttendence(updateData)
            })
            .catch((error) => {
                console.error("Error fetching attendance data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });
    }
    useEffect(() => {
        setAttendence({ ...attendence, ...props.userState.row })
    }, [])

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const StatusCell = ({ row }) => {
        const statusColor = row.attendance === '1' ? 'green' : 'red';

        const buttonStyle = {
            backgroundColor: statusColor,
            color: 'white',
            border: 'none',
            padding: '5px 10px',
            borderRadius: '5px',
            cursor: 'pointer',
        };

        return (
            <button style={buttonStyle}>
                {row.attendance === '1' ? 'Present' : 'Absent'}
            </button>
        );
    };


    const columns = [
        {
            name: 'Student ID',
            selector: row => row.student_id,
            sortable: true,
        },
        {
            name: 'Student Name',
            selector: row => row.firstname,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => <StatusCell row={row} />,
            sortable: true,
        }
    ]
    useEffect(() => {
        fetchAttendenceData();
    }, []);
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherattendence' })
        }
    }
    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Attendence</div>
            </div>

            <div className='Main-head-viewschedule'>View Attendence</div>

            <div class="container text-start">
                <div class="row mt-5">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{attendence.category}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Sub Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{attendence.sub_category}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Topic : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{attendence.topic}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Batch : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{attendence.batch_id}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Mesage for students : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{attendence.message}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Student Present : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{attendence.present}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Student Absent : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{attendence.absent}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Attendence Date : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{formatDate(attendence.date)}</p>
                        </div>
                    </div>
                </div>

            </div>


            <div class="d-flex justify-content-center">
                <div class="col-xxl-8   col-xl-10 col-lg-12   justify-content-center">
                    <div class="table-responsive">
                        {_.get(attendence, 'students') && (
                            <DataTable
                                className='dataTable'
                                data={attendence.students}
                                columns={columns}
                                customStyles={customStyles}
                                striped
                                persistTableHead
                                pagination
                                paginationPerPage={10}
                            />
                        )}

                    </div>
                </div>

            </div>



        </div>
    )
}

export default ViewAttendence