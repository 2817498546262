import React, { useState } from "react"
import { CiBellOn } from "react-icons/ci";
import _ from "lodash"
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IoNotificationsOutline } from "react-icons/io5"




function Notification({ ...props }) {
  const [hoverEffect, setHoverEffect] = useState(false)
  const goToNotification = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Notification' })
    }
  }
  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'Notification' ? 'active' : ''} `} onClick={(e) => goToNotification(e)}>
      <div className="dash d-flex">
      <IoNotificationsOutline class='ms-3' />
        <p className='head-dashboard ms-2 mb-0' >Notification</p>
      </div>
    </div>
  )
}

export default Notification
