import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "./UserPageLogged.scss";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";

import _ from "lodash";
import {
  showErrorToast,
  showSuccessToast,
} from "./../../../../../../../util/toastUtils";
import LoadingSpinner from "../../../../../../Reactloader/LoadingSpinner";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";
function Assignment({ ...props }) {
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const [showassign, setShowassign] = useState(true);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const [fields, setFields] = useState([""]); // Initialize with one empty field

  // Function to add a new input field
  const addField = () => {
    setFields([...fields, ""]);
  };

  // Function to handle changes in input fields
  const handleFieldChange = (event, index) => {
    setSelectedFile(event.target.value);
    const updatedFields = [...fields];
    updatedFields[index] = event.target.files[0].name;
    setFields(updatedFields);
    handleFileChange(event);
  };
  const [showInputBox, setShowInputBox] = useState(false);
  const toggleInputBox = () => {
    setShowInputBox(!showInputBox);
  };
  const dispatch = useDispatch();
  const BackTodashboard = () => {
    if (showDetails) {
      setShowDetails(false);
    } else if (_.get(props, "setUserState")) {
      props.setUserState({ ...props.userState, subType: "Dashboard" });
    }
  };
  const [Assignments, setAssignments] = useState([
    {
      starting_at: "",
      category: "",
      ending_at: "",
      topic: "",
      sub_category: "",
      subject: "",
      price: "",
      id: "",
      description: "",
      batch_id: "",
      status: "",
      file: "",



    },
  ]);
  const goToStudentSubmitAssignments = (e, row, Id) => {
    if (_.get(props, "setUserState")) {
      props.setUserState({ ...props.userState, subType: "Assignment", id: Id });
    }
  };
  const [doc_file, setDoc_file] = useState("url");
  const [SubmitAssignments, setSubmitAssignments] = useState([
    {
      assignment_id: "",
      doc: URL,
    },
  ]);
  const [Assignment_id, setAssignment_id] = useState();

  // function for list Assigments in dataTable
  const ListStudentAssignments = () => {
    if (!localStorage.getItem("token")) {
      Backdashboard();
    }
    const headers = {
      Authorization: "Bearer " + (localStorage.getItem("token")),
    };
    Axios
      .post(
        '/api/listStudentAssignments',
        undefined,
        { headers: headers }
      )
      .then((response) => {
        if (_.get(response, "data.data")) {
          setAssignments(response.data.data);
          setLoading(false);
          const newData = response.data.data.map((item) => {
            if (item.starting_at) {
              return {
                ...item,
                starting_at: format(new Date(item.starting_at), "dd-MM-yyyy"),
                ending_at: format(new Date(item.ending_at), "dd-MM-yyyy"),
              };
            }
          });
          setAssignments(newData);
        }
      })
      .catch((error) => {
        showErrorToast("Assignment Submitted", error);
        setLoading(false);
      });
  };

  // function for Submit Assigments in dataTable
  const SubmitStudentAssignments = () => {
    if (!localStorage.getItem("token")) {
      Backdashboard();
    }
    const formData = new FormData();
    const id = parseInt(Assignment_id);
    formData.append("assignment_id", id);
    formData.append("doc", selectedFile); // Make sure selectedFile is not null
  
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    };
    Axios
      .post(
       '/api/submitAssignment',
        formData,
        { headers: headers }
      )
      .then((res) => {
        if (res.data.success) {
          setSubmitAssignments(res.data);
          //move to backdashboard
          goToStudentSubmitAssignments();
          Backdashboard();
          const formattedData = res.data.data.map((item) => ({
            ...item,
            closing_date: format(new Date(item.closing_date), "dd-MM-yyyy"),
          }));
          setSubmitAssignments(formattedData);
          ListStudentAssignments();
          showSuccessToast("successfully updated assignment");
        }
      })
      .catch((error) => {
        showErrorToast("successfully updated assignment", error);
        // setLoading(false);
      });
  };
  
  useEffect(() => {
    ListStudentAssignments();
    // Simulate loading delay with a setTimeout
    setTimeout(() => {
      setLoader(false); // Set Loader to false when data is loaded
    }, 500); // 2 seconds delay
  }, []);

  const Backdashboard = () => {
    setShowDetails(false);
  };

  
  const customStyles = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#5C5AB6",
        fontSize: "13px",
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
      },
    },
  };
  const columns = [
    {
      name: "Date Starts",
      selector: (row) => row.starting_at,
    },
    {
      name: "Date Ends",
      selector: (row) => row.ending_at,
      sortable: true,
    },
    
    {
      name: "Batch",
      selector: (row) => row.batchname,
    },
    {
      name: " Status",
      selector: (row) => row.assignment_doc,
      cell: (row) => {
        const isActive = !row.assignment_doc;
        return (
          <div className={isActive ? "inactive-row" : " active-row"}>
            {isActive ? "Not Complete" : "Complete"}
          </div>
        );
      },
    },
    {
      name: "View",
      selector: (row) => (
        <button
          className="details-table-assignment"
          onClick={(e) => showDetailsFn(row)}
        >
          Details
        </button>
      ),
    },
  ];
  const showDetailsFn = (row) => {
    setShowDetails(row);
    setAssignment_id(row.id);
    setShowassign(false);
  };
  const [fileUrl, setFileUrl] = useState(null);
  const basePath = "http://tuitionguruji.com/backend/public/";
  const handleDownload = (index) => {
    // Assuming that QuotationView.quotation.file contains the URL of the file to download
    if (_.get(showDetails, 'assesment_doc')) {
      setFileUrl(showDetails.assesment_doc);
    }
  };
  return (
    <div>
      <div className="sidebar_content">
        {/* {loader ? (
          <LoadingSpinner />
        ) : ( */}
          <div className="main-divHead">
             <div className='sub-Head1' >
          <div className='Sub-Head2'>Assigments</div>
        </div>
            {/* To show Assignment details in dataTable */}
            {!showDetails && (
              <div className="data-table p-xxl-4 p-xl-4 p-md-4 p-sm-4">
                <DataTable
                  columns={columns}
                  data={Assignments}
                  fixedHeader={true}
                  fixedHeaderScrollHeight="600px"
                  striped
                  customStyles={customStyles}
                  persistTableHead
                  pagination
                />
              </div>
            )}
            {/* After click Details button show deatils */}
            {showDetails && !showassign && (
              <div className="assignment-details-table mt-5">
                <div className="mx-5">
                  <div className="container ">
                    <div className="row">
                      <div className="d-flex justify-content-center p-0">
                        <div className="col-xxl-8 col-xl-8 col">
                          <div className="admin-card p-4 adminSubCard">
                            <div className="container text-start">
                              <div className="row">
                                <div className="d-flex justify-content-between">
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      Starting Date :
                                    </p>
                                  </div>
                                  <div className="col-xxl-8 col-xl-9 col-lg-10 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      {_.get(showDetails, 'starting_at')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="d-flex justify-content-between">
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      Category:{" "}
                                    </p>
                                  </div>
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      {_.get(showDetails, 'category')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="d-flex justify-content-between">
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      Sub Category :{" "}
                                    </p>
                                  </div>
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      {_.get(showDetails, 'sub_category')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="d-flex justify-content-between">
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      Topic :{" "}
                                    </p>
                                  </div>
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      {_.get(showDetails, 'topic')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="d-flex justify-content-between">
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      Batch:{" "}
                                    </p>
                                  </div>
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      {_.get(showDetails, 'batchname')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row d-flex">
                                <div className="d-flex justify-content-between">
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10  col-sm-11">
                                    <p className="student-font-label">
                                      Assignment for student :{" "}
                                    </p>
                                  </div>
                                  {/* <div className="row"> */}
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4  col-sm-4">
                                    <p className="student-font-label">
                                      {_.get(showDetails, 'description')}
                                    </p>
                                  </div>
                                  {/* </div> */}
                                </div>
                              </div>
                              <div className="row">
                                <div className="d-flex justify-content-between">
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      Ending Date :{" "}
                                    </p>
                                  </div>
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      {_.get(showDetails, 'ending_at')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="d-flex justify-content-between">
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      Supporing Documents :{" "}
                                    </p>
                                  </div>
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11 d-flex">
                                    <a
                                      className="pr-5"
                                      href={fileUrl ? basePath + fileUrl : ""}
                                      download="{fileurl}.pdf">                                 
                                      <DownloadForOfflineIcon
                                        // index={index}
                                        className="ass-btn"
                                        onClick={handleDownload}
                                      />
                                    </a>
                                    <p className="student-font-label">
                                      Download
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="d-flex justify-content-between">
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p className="student-font-label">
                                      Status :{" "}
                                    </p>
                                  </div>
                                  <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                    <p
                                      className="student-font-label"
                                      style={{
                                        color: showDetails.status
                                          ? "green"
                                          : "red",
                                      }}
                                    >
                                      {showDetails.status
                                        ? "Complete"
                                        : "Not Complete"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-center mx-5 mt-2">
                    <div className="col-xxl-8 col-xl-8 col">
                      <div className="container text-start">
                        <div className="row">
                          <div className="d-flex p-0 justify-content-between">
                            <div className="col">
                              <p className="fs-5 fw-semibold">
                                Upload Assignment Files
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {fields.map((field, index) => (<div key={index} className="col">
                        <div class="row d-flex">
                          <div class="col-4">
                            <input
                              type="file"
                              className="choose-file"
                              // value={field}
                              onChange={(e) => handleFieldChange(e, index)}
                              accept=".pdf, .doc, .docx"
                            />
                          </div>
                          <div className="col-1 text-start mt-1 ">
                            <div className="handle-Upload ">
                              <button onClick={addField} className="button-4">
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mb-1 row">
                          <div className="d-flex justify-content-center">
                            <div className="col">
                              <p className="file-size fw-semibold">
                                File size (pdf,jpg) maximum 3MB
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      ))}
                      <div className="row">
                        <div className="col text-end">
                          <div className="col-11">
                            <div className="align-submit-btn d-flex">
                              <button
                                className=" button-3 p-2"
                                onClick={() => SubmitStudentAssignments()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        {/* )} */}
      </div>
    </div>
  );
}
export default Assignment;
