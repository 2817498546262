import React from 'react'
import TeacherDashboard from './Components/TeacherDashboard'
import AnnouncementTeacher from './Components/AnnouncementTeacher'
import AssignmentTeacher from './Components/AssignmentTeacher'
import SubjectTeacher from './Components/SubjectTeacher'
import StudentTeacher from './Components/StudentTeacher'
import WalletTeacher from './Components/WalletTeacher'
import RecordTeacher from './Components/RecordTeacher'
import ChatTeacher from './Components/ChatTeacher'
import NotificationTeacher from './Components/NotificationTeacher'
import QuotationTeacher from './Components/QuotationTeacher'
import TestTeacher from './Components/TestTeacher'
import ScheduleTeacher from './Components/ScheduleTeacher'

 
function TeacherSidebar ({ ...props }) {
  return (
    <div>
    <TeacherDashboard { ...props } />
    <SubjectTeacher {...props}/>
    <AnnouncementTeacher {...props}/>
    <AssignmentTeacher {...props}/>
   
    <StudentTeacher {...props}/>
    <WalletTeacher {...props}/>
    {/* <RecordTeacher {...props}/> */}
    <ChatTeacher {...props}/>
    {/* <NotificationTeacher {...props}/> */}
    <QuotationTeacher {...props}/>
    <TestTeacher {...props}/>

    </div>
  )
}

export default TeacherSidebar