import { Wallet } from "@mui/icons-material"
import React, {useState,useEffect} from "react"
import Dashboard from "./components/Dashboard"
import EnrolledCourses from "./components/EnrolledCourses"
import EnrolledVideos from "./components/EnrolledVideos"
import Assignment from "./components/Assignment"
import Chats from "./components/Chats"
import Wallets from "./components/Wallet"
import Notification from "./components/Notification"
import TestResults from "./components/Tests"
import Quotation from "./components/Quotation"
import Freelancer from "../../../../../Freelancer/Freelancer"
import Navtution from "./components/Navtution"
import NavtutionOnline from "./components/NavtutionOnline"
import StudentProfile from "./components/StudentProfile"
import _ from "lodash"
import Courses1 from "./components/Courses1"
import DashboardTeacher from "../TeacherPage/DashboardTeacher"
import TeacherAssignment from "../TeacherPage/TeacherAssignment"
import CreateTeacherAssign from "../TeacherPage/CreateTeacherAssign"
import CreateViewTeachAssign from "../TeacherPage/CreateViewTeachAssign"
import TeacherStudentAssignList from "../TeacherPage/TeacherStudentAssignList"
import StudentCompleteAssign from "../TeacherPage/StudentCompleteAssign"
import TeacherSchedule from "../TeacherPage/TeacherSchedule"
import TeacherNotification from "../TeacherPage/TeacherNotification"
import TeacherAttendence from "../TeacherPage/TeacherAttendence"
import ViewSchedule from "../TeacherPage/ViewSchedule"
import CreateNotification from "../TeacherPage/CreateNotification"
import ViewNotification from "../TeacherPage/ViewNotification"
import TeacherNewAttendence from "../TeacherPage/TeacherNewAttendence"
import CreateAttendence from "../TeacherPage/CreateAttendence"
import ViewAttendence from "../TeacherPage/ViewAttendence"
import TeacherSubject from "../TeacherPage/TeacherSubject"
import CreateTeacherCoupon from "../TeacherPage/CreateTeacherCoupon"
import SubjectStudentDetails from "../TeacherPage/SubjectStudentDetails"
import TeacherStudent from "../TeacherPage/TeacherStudent"
import CreateTeacherBatch from "../TeacherPage/CreateTeacherBatch"
import ViewStudentlist from "../TeacherPage/ViewStudentlist"
import TeacherBatchView from "../TeacherPage/TeacherBatchView"
import TeacherWallet from "../TeacherPage/TeacherWallet"
import TeacherWithdrawl from "../TeacherPage/TeacherWithdrawl"
import TeacherBankDetails from "../TeacherPage/TeacherBankDetails"
import TeacherTest from "../TeacherPage/TeacherTest"
import AddAnotherBankAccnt from "../TeacherPage/AddAnotherBankAccnt"
import QuotationRequest from "../TeacherPage/QuotationRequest"
import QuoteJobeDetails from "../TeacherPage/QuoteJobeDetails"
import QuoteJobFreelance from "../TeacherPage/QuoteJobFreelance"
import TeacherTestStudentList from "../TeacherPage/TeacherTestStudentList"
import TeacherStuentQuest from "../TeacherPage/TeacherStuentQuest"
import TeacherAddTest from "../TeacherPage/TeacherAddTest"
import TeacherAddQuest from "../TeacherPage/TeacherAddQuest"
import SavedQuestTestTeacher from "../TeacherPage/SavedQuestTestTeacher"
import TestTeacherView from "../TeacherPage/TestTeacherView"
import ViewDetails from "../TeacherPage/ViewDetails"
import EnrolledVideoPlayers from "./components/ReactVideoPlayer"
import TeacherResource from "../TeacherPage/TeacherResource"
import AdminDashboard from "../adminPage/AdminDashboard"
import AdminSubjectComponent from "../adminPage/adminSubject.component"
import AdminTeacherComponent from "../adminPage/adminTeacher.component"
import AdminStudentComponent from "../adminPage/adminStudent.component"
import AdminFreelancerComponent from "../adminPage/adminFreelancer.component"
import AdminInstructorUploadsComponent from "../adminPage/adminInstructorUploads.component"
import AdminWalletComponent from "../adminPage/adminWallet.component"
import AdminReportComponent from "../adminPage/adminReport.component"
import AddCategoryComponent from "../adminPage/addCategory.component"
import AddSubCategoryComponent from "../adminPage/addSubCategory.component"
import AddTopicComponent from "../adminPage/addTopic.component"
import SubjectDetailsComponent from "../adminPage/subjectDetails.component"
import AdminTeacherDetailsComponent from "../adminPage/adminTeacherDetails.component"
import AdminTeacherDocumentComponent from "../adminPage/adminTeacherDocument.component"
import AdminRecordVideoComponent from "../adminPage/adminRecordVideo.component"
import AdminVideoViewComponent from "../adminPage/adminVideoView.component"
import AdminStudentDetailsComponent from "../adminPage/adminStudentDetails.component"
import FreelancerDetailComponent from "../adminPage/freelancerDetail.component"
import InstructorDetailsComponent from "../adminPage/instructorDetails.component"
import InstructorDocumentDetailsComponent from "../adminPage/InstructorDocumentDetails.component"
import InstructorVideoTableComponent from "../adminPage/instructorVideoTable.component"
import AdminMessageComponent from "../adminPage/adminMessage.component"
import MessageDetailComponent from "../adminPage/messageDetail.component"
import CouponStudentList from '../TeacherPage/CouponStudentList'
import TeacherCouponView from "../TeacherPage/TeacherCouponView"
import Couponusage from "../TeacherPage/Couponusage"
import AdminSystemSettingsComponent from "../adminPage/adminSystemSettings.component"
import WebsiteSettingsComponent from "../adminPage/websiteSettings.component"
import AdminPaymentSettingsComponent from "../adminPage/adminPaymentSettings.component"
import AdminSMTPSettingsComponent from "../adminPage/adminSMTPSettings.component"
import CategoryDetailsComponent from "../adminPage/categoryDetails.component"
import AdminProfileComponent from "../adminPage/adminProfile.component"
import FreelanceDashboardComponent from "../freelancerPage/freelanceDashboard.component"
import FreelanceJobComponent from "../freelancerPage/freelancejob.component"
import FreelanceSubjectComponent from "../../../Sidebar/SidebarComponents/freelance/components/freelanceSubject.component"
import FreelanceSubjectPageComponent from "../freelancerPage/freelanceSubjectPage.component"
import FreelanceWalletComponent from "../freelancerPage/freelanceWallet.component"
import FreelanceQuotedJobsComponent from "../../../Sidebar/SidebarComponents/freelance/components/freelanceQuotedJobs.component"
import FreelanceQuotedJobsPageComponent from "../freelancerPage/freelanceQuotedJobsPage.component"
import FreelanceNotificationComponent from "../freelancerPage/freelanceNotification.component"
import FreelanceSubjectDetailsComponent from "../freelancerPage/freelanceSubjectDetails.component"
import FreelanceJobDetailsComponent from "../freelancerPage/freelanceJobDetails.component"
import AddSubjectFreelanceComponent from "../freelancerPage/addSubjectFreelance.component"
import AddResource from "../TeacherPage/AddResource"
import QuoteJobViewComponent from "../freelancerPage/quoteJobView.component"
import UploaderDashboardComponent from "../InstructorUploadPages/UploaderDashboard.component"
import AnnouncementComponent from "../InstructorUploadPages/Announcement.component"
import StudentUploaderComponent from "../InstructorUploadPages/StudentUploader.component"
import WalletUploaderComponent from "../InstructorUploadPages/walletUploader.component"
import CourseUploaderComponent from "../InstructorUploadPages/CourseUploader.component"
import CourseUploadComponent from "../InstructorUploadPages/courseUpload.component"
import AddSectionComponent from "../InstructorUploadPages/addSection.component"
import RecorderCourseComponent from "../InstructorUploadPages/recorderCourse.component"
import UploaderNotificationComponent from "../InstructorUploadPages/uploaderNotification.component"
import TeacherAllNotification from "../TeacherPage/TeacherAllNotification"
import TeacherAddSubject from "../TeacherPage/TeacherAddSubject"
import TeacherChat from "../TeacherPage/TeacherChat"
import AdminTaxSettingsComponent from "../adminPage/adminTaxSettings.component"
import AdminAddTaxcomponent from "../adminPage/adminAddTax.component"
import ViewSubCategorycomponent from "../adminPage/ViewSubCategory.component"
import ViewTopiccomponent from "../adminPage/ViewTopic.component"
import AdminTeacherCoursecomponent from "../adminPage/adminTeacherCourse.component"
import AdminWalletCreditComponent from "../adminPage/AdminWalletCreditComponent"
import AdminTeacherWalletDetail from "../adminPage/AdminTeacherWalletDetail"
import AdminStudentWallet from "../adminPage/AdminStudentWallet"
import AdminBatchwiseStudent from "../adminPage/adminBatchwiseStudent.component"
import AddSubCategory from "../TeacherPage/AddSubCategory"
import AddTopic from "../TeacherPage/AddTopic"
import Review from "../TeacherPage/Review"
import EditTeacherSubject from "../TeacherPage/EditTeacherSubject"


function StudentMainContent({ ...props }) {

  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    // Retrieve the current page from localStorage upon component mount
    const savedPage = localStorage.getItem("currentPage");
    if (savedPage) setCurrentPage(savedPage);
  }, []);

  useEffect(() => {
    // Save the current page to localStorage whenever it changes
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  return (
    <div className="detailed-content">
      {_.get(props, 'userState.subType') == 'Dashboard' && _.get(props, 'userState.type') == 'Student' && <Dashboard key="student" {...props} />}
      {/* {_.get(props, 'userState.subType') == 'EnrolledVideos' && _.get(props, 'userState.type') == 'Student' && <EnrolledVideos {...props} />} */}
      {_.get(props, 'userState.subType') == 'EnrolledCourses' && _.get(props, 'userState.type') == 'Student' && <EnrolledCourses {...props} />}
      {_.get(props, 'userState.subType') == 'Assignment' && _.get(props, 'userState.type') == 'Student' && <Assignment {...props} />}
      {_.get(props, 'userState.subType') == 'Wallet' && _.get(props, 'userState.type') == 'Student' && <Wallets {...props} />}
      {_.get(props, 'userState.subType') == 'Chats' && _.get(props, 'userState.type') == 'Student' && <Chats {...props} />}
      {_.get(props, 'userState.subType') == 'Notification' && _.get(props, 'userState.type') == 'Student' && <Notification {...props} />}
      {_.get(props, 'userState.subType') == 'NavtutionOnline' && _.get(props, 'userState.type') == 'Student' && <NavtutionOnline {...props} />}
      {_.get(props, 'userState.subType') == 'Courses1' && _.get(props, 'userState.type') == 'Student' && <Courses1 {...props} />}
      {_.get(props, 'userState.subType') == 'settings' && _.get(props, 'userState.type') == 'Student' && <settings {...props} />}
      {_.get(props, 'userState.subType') == 'StudentProfile' && _.get(props, 'userState.type') == 'Student' && <StudentProfile {...props} />}
      {_.get(props, 'userState.subType') == 'Test' && _.get(props, 'userState.type') == 'Student' && <TestResults {...props} />}
      {_.get(props, 'userState.subType') == 'Navtution' && _.get(props, 'userState.type') == 'Student' && <Navtution {...props} />}
      {_.get(props, 'userState.subType') == 'Quotation' && _.get(props, 'userState.type') == 'Student' && <Quotation {...props} />}
      {_.get(props, 'userState.subType') == 'Freelancer' && _.get(props, 'userState.type') == 'Student' && <Freelancer {...props} />}
      {_.get(props, 'userState.subType') == 'Chatscontent' && _.get(props, 'userState.type') == 'Student' && <EnrolledCourses {...props} />}
      {_.get(props, 'userState.subType') == 'ReactVideoPlayer' && _.get(props, 'userState.type') == 'Student' && <EnrolledVideoPlayers {...props} />}
      {_.get(props, 'userState.subType') == 'ShowSubjects' && _.get(props, 'userState.type') == 'Student' && <EnrolledCourses {...props} />}

      {_.get(props, 'userState.subType') == 'DashboardTeacher' && _.get(props, 'userState.type') == 'Teacherpage' && <DashboardTeacher {...props} />}
      {_.get(props, 'userState.subType') == 'Teacherschedule' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherSchedule  {...props} />}
      {_.get(props, 'userState.subType') == 'notificationreply' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherAllNotification  {...props} />}
      {_.get(props, 'userState.subType') == 'Teachernotification' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherNotification {...props} />}
      {_.get(props, 'userState.subType') == 'Teacherattendence' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherNewAttendence {...props} />}
      {_.get(props, 'userState.subType') == 'createschedule' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherAttendence {...props} />}
      {_.get(props, 'userState.subType') == 'viewschedule' && _.get(props, 'userState.type') == 'Teacherpage' && <ViewSchedule {...props} />}
      {_.get(props, 'userState.subType') == 'createnotification' && _.get(props, 'userState.type') == 'Teacherpage' && <CreateNotification {...props} />}
      {_.get(props, 'userState.subType') == 'viewnotification' && _.get(props, 'userState.type') == 'Teacherpage' && <ViewNotification {...props} />}
      {_.get(props, 'userState.subType') == 'createattendence' && _.get(props, 'userState.type') == 'Teacherpage' && <CreateAttendence {...props} />}
      {_.get(props, 'userState.subType') == 'viewattendence' && _.get(props, 'userState.type') == 'Teacherpage' && <ViewAttendence {...props} />}
      {_.get(props, 'userState.subType') == 'Teacherassignment' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherAssignment  {...props} />}
      {_.get(props, 'userState.subType') == 'createnewassignment' && _.get(props, 'userState.type') == 'Teacherpage' && <CreateTeacherAssign  {...props} />}
      {_.get(props, 'userState.subType') == 'viewassignment' && _.get(props, 'userState.type') == 'Teacherpage' && <CreateViewTeachAssign  {...props} />}
      {_.get(props, 'userState.subType') == 'viewstudentlist' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherStudentAssignList  {...props} />}
      {_.get(props, 'userState.subType') == 'viewstudentassignment' && _.get(props, 'userState.type') == 'Teacherpage' && <StudentCompleteAssign  {...props} />}
      {_.get(props, 'userState.subType') == 'Teachersubject' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherSubject  {...props} />}
      {_.get(props, 'userState.subType') == 'addsubject' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherAddSubject  {...props} />}
      {_.get(props, 'userState.subType') == 'TeacherChats' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherChat  {...props} />}
      {_.get(props, 'userState.subType') == 'addresource' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherResource  {...props} />}
      {_.get(props, 'userState.subType') == 'ViewSubject' && _.get(props, 'userState.type') == 'Teacherpage' && <ViewDetails  {...props} />}
      {_.get(props, 'userState.subType') == 'createCoupon' && _.get(props, 'userState.type') == 'Teacherpage' && <CreateTeacherCoupon {...props} />}
      {_.get(props, 'userState.subType') == 'Teacherstudent' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherStudent {...props} />}
      {_.get(props, 'userState.subType') == 'subjectstudentdetails' && _.get(props, 'userState.type') == 'Teacherpage' && <SubjectStudentDetails {...props} />}
      {_.get(props, 'userState.subType') == 'createbatch' && _.get(props, 'userState.type') == 'Teacherpage' && <CreateTeacherBatch {...props} />}
      {_.get(props, 'userState.subType') == 'teacherstudentview' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherBatchView {...props} />}
      {_.get(props, 'userState.subType') == 'Teacherwallet' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherWallet {...props} />}
      {_.get(props, 'userState.subType') == 'requestwithdrawl' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherWithdrawl {...props} />}
      {_.get(props, 'userState.subType') == 'bankaccountdetails' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherBankDetails {...props} />}
      {_.get(props, 'userState.subType') == 'addteacherbank' && _.get(props, 'userState.type') == 'Teacherpage' && <AddAnotherBankAccnt {...props} />}
      {_.get(props, 'userState.subType') == 'TeacherQuotation' && _.get(props, 'userState.type') == 'Teacherpage' && <QuotationRequest {...props} />}
      {_.get(props, 'userState.subType') == 'QuoteJobDetails' && _.get(props, 'userState.type') == 'Teacherpage' && <QuoteJobeDetails {...props} />}
      {_.get(props, 'userState.subType') == 'QuoteJobDetails2' && _.get(props, 'userState.type') == 'Teacherpage' && <QuoteJobFreelance {...props} />}
      {_.get(props, 'userState.subType') == 'TeacherTest' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherTest {...props} />}
      {_.get(props, 'userState.subType') == 'Teacherstudentdetails' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherTestStudentList {...props} />}
      {_.get(props, 'userState.subType') == 'Teacherviewstudentdetails' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherStuentQuest {...props} />}
      {_.get(props, 'userState.subType') == 'TeacherAddTest' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherAddTest {...props} />}
      {_.get(props, 'userState.subType') == 'TeacherAddNewQuest' && _.get(props, 'userState.type') == 'Teacherpage' && <SavedQuestTestTeacher {...props} />}
      {_.get(props, 'userState.subType') == 'addquest' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherAddQuest {...props} />}
      {_.get(props, 'userState.subType') == 'TeacherViewTest' && _.get(props, 'userState.type') == 'Teacherpage' && <TestTeacherView {...props} />}
      {_.get(props, 'userState.subType') == 'studentlistcoupon' && _.get(props, 'userState.type') == 'Teacherpage' && <CouponStudentList {...props} />}
      {_.get(props, 'userState.subType') == 'ViewCoupon' && _.get(props, 'userState.type') == 'Teacherpage' && <TeacherCouponView {...props} />}
      {_.get(props, 'userState.subType') == 'couponusage' && _.get(props, 'userState.type') == 'Teacherpage' && <Couponusage {...props} />}
      {_.get(props, 'userState.subType') == 'subcategory' && _.get(props, 'userState.type') == 'Teacherpage' && <AddSubCategory {...props} />}
      {_.get(props, 'userState.subType') == 'addtopic' && _.get(props, 'userState.type') == 'Teacherpage' && <AddTopic {...props} />}
      {_.get(props, 'userState.subType') == 'review' && _.get(props, 'userState.type') == 'Teacherpage' && <Review {...props} />}
      {_.get(props, 'userState.subType') == 'editsubject' && _.get(props, 'userState.type') == 'Teacherpage' && <EditTeacherSubject {...props} />}



      {_.get(props, 'userState.subType') == 'Teacherresource' && _.get(props, 'userState.type') == 'Teacherpage' && <AddResource {...props} />}
      {_.get(props, 'userState.subType') == 'DashboardAdmin' && _.get(props, 'userState.type') == 'admin' && <AdminDashboard {...props} />}
      {_.get(props, 'userState.subType') == 'AdminSubject' && _.get(props, 'userState.type') == 'admin' && <AdminSubjectComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminTeacher' && _.get(props, 'userState.type') == 'admin' && <AdminTeacherComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminStudent' && _.get(props, 'userState.type') == 'admin' && <AdminStudentComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminFreelancer' && _.get(props, 'userState.type') == 'admin' && <AdminFreelancerComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminInsUpload' && _.get(props, 'userState.type') == 'admin' && <AdminInstructorUploadsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminWallet' && _.get(props, 'userState.type') == 'admin' && <AdminWalletComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminReport' && _.get(props, 'userState.type') == 'admin' && <AdminReportComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminAddCategory' && _.get(props, 'userState.type') == 'admin' && <AddCategoryComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminAddTax' && _.get(props, 'userState.type') == 'admin' && <AdminAddTaxcomponent {...props} />}
      {_.get(props, 'userState.subType') == 'admincreditdetails' && _.get(props, 'userState.type') == 'admin' && <AdminWalletCreditComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminteachercreditdetails' && _.get(props, 'userState.type') == 'admin' && <AdminTeacherWalletDetail {...props} />}
      {_.get(props, 'userState.subType') == 'adminstudentcreditdetails' && _.get(props, 'userState.type') == 'admin' && <AdminStudentWallet {...props} />}

      {_.get(props, 'userState.subType') == 'adminAddSubCategory' && _.get(props, 'userState.type') == 'admin' && <AddSubCategoryComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminAddTopic' && _.get(props, 'userState.type') == 'admin' && <AddTopicComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminDetailsPage' && _.get(props, 'userState.type') == 'admin' && <SubjectDetailsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminTeacherDetails' && _.get(props, 'userState.type') == 'admin' && <AdminTeacherDetailsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminTeacherDocument' && _.get(props, 'userState.type') == 'admin' && <AdminTeacherDocumentComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminRecordVideo' && _.get(props, 'userState.type') == 'admin' && <AdminRecordVideoComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminVideoView' && _.get(props, 'userState.type') == 'admin' && <AdminVideoViewComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminStudentDetails' && _.get(props, 'userState.type') == 'admin' && <AdminStudentDetailsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminFreelancerDetails' && _.get(props, 'userState.type') == 'admin' && <FreelancerDetailComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminInstructorDetails' && _.get(props, 'userState.type') == 'admin' && <InstructorDetailsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminInstructorDocument' && _.get(props, 'userState.type') == 'admin' && <InstructorDocumentDetailsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminInstructorVideo' && _.get(props, 'userState.type') == 'admin' && <InstructorVideoTableComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminMessages' && _.get(props, 'userState.type') == 'admin' && <AdminMessageComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminMessageDetails' && _.get(props, 'userState.type') == 'admin' && <MessageDetailComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminSystemSettings' && _.get(props, 'userState.type') == 'admin' && <AdminSystemSettingsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminWebsiteSettings' && _.get(props, 'userState.type') == 'admin' && <WebsiteSettingsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminPaymentSettings' && _.get(props, 'userState.type') == 'admin' && <AdminPaymentSettingsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminTaxSettings' && _.get(props, 'userState.type') == 'admin' && <AdminTaxSettingsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminSMTPSettings' && _.get(props, 'userState.type') == 'admin' && <AdminSMTPSettingsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'CategoryDetailsPage' && _.get(props, 'userState.type') == 'admin' && <CategoryDetailsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminProfilePage' && _.get(props, 'userState.type') == 'admin' && <AdminProfileComponent {...props} />}
      {_.get(props, 'userState.subType') == 'viewsubcategory' && _.get(props, 'userState.type') == 'admin' && <ViewSubCategorycomponent {...props} />}
      {_.get(props, 'userState.subType') == 'viewtopic' && _.get(props, 'userState.type') == 'admin' && <ViewTopiccomponent {...props} />}
      {_.get(props, 'userState.subType') == 'TeacherCourse' && _.get(props, 'userState.type') == 'admin' && <AdminTeacherCoursecomponent {...props} />}
      {_.get(props, 'userState.subType') == 'adminbatchstudent' && _.get(props, 'userState.type') == 'admin' && <AdminBatchwiseStudent {...props} />}

      {_.get(props, 'userState.subType') == 'DashboardFreelance' && _.get(props, 'userState.type') == 'Freelance' && <FreelanceDashboardComponent {...props} />}
      {_.get(props, 'userState.subType') == 'FreelanceJob' && _.get(props, 'userState.type') == 'Freelance' && <FreelanceJobComponent {...props} />}
      {_.get(props, 'userState.subType') == 'FreelanceSubject' && _.get(props, 'userState.type') == 'Freelance' && <FreelanceSubjectPageComponent {...props} />}
      {_.get(props, 'userState.subType') == 'FreelanceWallet' && _.get(props, 'userState.type') == 'Freelance' && <FreelanceWalletComponent {...props} />}
      {_.get(props, 'userState.subType') == 'FreelanceQuotedJobs' && _.get(props, 'userState.type') == 'Freelance' && <FreelanceQuotedJobsPageComponent {...props} />}
      {_.get(props, 'userState.subType') == 'FreelanceNotification' && _.get(props, 'userState.type') == 'Freelance' && <FreelanceNotificationComponent {...props} />}
      {_.get(props, 'userState.subType') == 'freelanceSubjectDetails' && _.get(props, 'userState.type') == 'Freelance' && <FreelanceSubjectDetailsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'freelanceJobDetails' && _.get(props, 'userState.type') == 'Freelance' && <FreelanceJobDetailsComponent {...props} />}
      {_.get(props, 'userState.subType') == 'freelanceAddSubject' && _.get(props, 'userState.type') == 'Freelance' && <AddSubjectFreelanceComponent {...props} />}
      {_.get(props, 'userState.subType') == 'FreelanceViewDetailsPage' && _.get(props, 'userState.type') == 'Freelance' && <QuoteJobViewComponent {...props} />}

      {_.get(props, 'userState.subType') == 'DashboardUpload' && _.get(props, 'userState.type') == 'InstructorUpload' && <UploaderDashboardComponent {...props} />}
      {_.get(props, 'userState.subType') == 'AnnouncementUploader' && _.get(props, 'userState.type') == 'InstructorUpload' && <AnnouncementComponent {...props} />}
      {_.get(props, 'userState.subType') == 'StudentsUploader' && _.get(props, 'userState.type') == 'InstructorUpload' && <StudentUploaderComponent {...props} />}
      {_.get(props, 'userState.subType') == 'WalletUploader' && _.get(props, 'userState.type') == 'InstructorUpload' && <WalletUploaderComponent {...props} />}
      {_.get(props, 'userState.subType') == 'CourseUpload' && _.get(props, 'userState.type') == 'InstructorUpload' && <CourseUploaderComponent {...props} />}
      {_.get(props, 'userState.subType') == 'UploaderCourseUpload' && _.get(props, 'userState.type') == 'InstructorUpload' && <CourseUploadComponent {...props} />}
      {_.get(props, 'userState.subType') == 'uploaderAddSection' && _.get(props, 'userState.type') == 'InstructorUpload' && <AddSectionComponent {...props} />}
      {_.get(props, 'userState.subType') == 'UploaderViewCourse' && _.get(props, 'userState.type') == 'InstructorUpload' && <RecorderCourseComponent {...props} />}
      {_.get(props, 'userState.subType') == 'NotificationUploader' && _.get(props, 'userState.type') == 'InstructorUpload' && <UploaderNotificationComponent {...props} />}
    </div>
  )
}

export default StudentMainContent
