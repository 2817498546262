import React, { useState, } from "react"
import { useEffect } from "react";
import _ from 'lodash';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { MdOutlineLogout, MdOutlineVerified } from 'react-icons/md';
import img2 from '../../../../../../images/logos_linkedin-icon.jpg';
import facebook from '../../../../../../images/logos_facebook.jpg';
import twitter from '../../../../../../images/skill-icons_twitter.jpg';
import instagram from '../../../../../../images/skill-icons_instagram.jpg';
import youtube from '../../../../../../images/logos_youtube-icon.jpg';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { ProfileData, getLanguages, getLanguagesData, getUserProfile, listCountries, listCountriesData, listStates, listStatesData, updateProfile } from "../../../../../../redux/slice/studentSlice";
import { useDispatch, useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import { FaPlus } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from '../../../../../../redux/slice/SubjectSlice';
import Axios from "../../../../../../util/apiUtil";
import { RiDeleteBinLine } from "react-icons/ri";

function TeacherProfile(props) {

    const SubjectValidationSchema = Yup.object().shape({
        phone_number: Yup.string().required('Phone number is required'),
        country_id: Yup.string().required('country_id is required'),
        bio: Yup.string().required('bio is required'),
        category: Yup.string().required('category is required'),
        experience: Yup.string().required('experience is required'),
        qualification: Yup.string().required('qualification is required'),
        sub_category: Yup.string().required('sub_category is required'),
        topic: Yup.string().required('topic is required'),

    });
    const dispatch = useDispatch()
    const data = localStorage.getItem('user');
    const userData = JSON.parse(data);
    const navigate = useNavigate();
    const ProfileDetails = useSelector(ProfileData)

    const countryListData = useSelector(listCountriesData);
    const stateListData = useSelector(listStatesData);
    const languagesData = useSelector(getLanguagesData);
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [errors, setErrors] = useState([""]);
    const [uploadedFile, setUploadedFile] = useState(null);

    const Backdashboard = () => {
        navigate("/teacher", { state: { type: 'Teacherpage', subType: 'DashboardTeacher' } });
    }
    const goToTeacherAddDocument = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'addsubjectcategory' })
        }
    }

    const [category, setCategory] = useState([{
        category_id: "", qualification: {
            name: '',
            document: "",
            experience: '',
            exp_document: "",
            course_name: '',
            duration: '',
            org_name: '',
        }
    }]);
    //add category
    const handleAddCategory = () => {
        const newCategoryGroup = {
            category_id: "", qualification: {
                name: '',
                document: "",
                experience: '',
                exp_document: "",
                course_name: '',
                duration: '',
                org_name: '',
            }
        }
        setCategory([...category, newCategoryGroup]);
    }
    // Education
    const [education, setEducation] = useState([{ degree: "", organization: "", field_of_study: "", year_completed: "" }]);
    //add education
    const addEducationGroup = () => {
        const newEducationGroup = { degree: "", field_of_study: "", organization: "", year_completed: "" };
        setEducation([...education, newEducationGroup]);
    };

    //remove education
    const removeEducationGroup = (indexToRemove) => {
        setEducation(education.filter((_, index) => index !== indexToRemove));
    };
    const [profile_Details, setProfile_Details] = useState({
        firstname: "",
        lastname: "",
        phone_num: '',
        bio: "",
        category: '',
        sub_category: "",
        topic: "",
        document: "",
        country_id: "",
        state_id: "",
        teacher: {
            education: '',
            teaching_background: "",
        }
    })

    useEffect(() => {

        dispatch(getUserProfile())
        dispatch(listCountries());
        dispatch(listStates());
        // dispatch(getLanguages());
    }, []);
    const baseUrl = "http://tuitionguruji.com/backend/public/";

    const countryList = Object.keys(countryListData).map((key) => ({
        key: key,
        country_name: countryListData[key],
    }));

    const stateList = Object.keys(stateListData).map((key) => ({
        id: key,                           // Keep the 'id' property as it is
        name: stateListData[key].name,     // Assuming the state name is stored under 'name' property
        abbreviation: stateListData[key].abbreviation, // Include additional property like 'abbreviation'
    }));


    const cate = useSelector(CSTData);

    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
    }, []);

    const categoryOptions = cate?.map((e) => ({
        value: e.id,
        label: e.name
    }));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile_Details({ ...profile_Details, [name]: value });
    };
    const [profileImage, setProfileImage] = useState(null);


    const handleImgUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setProfileImage(reader.result);
        };
        if (file) {
            setFileName(file.name);
            reader.readAsDataURL(file);
        }
    };
    const handleClearImage = () => {
        setProfileImage(null);
        setFileName("");
        // Reset the input field to allow selecting a new file
        const profilePicInput = document.getElementById("profilePicInput");
        if (profilePicInput) {
            profilePicInput.value = "";
        }
    };
    // const [panImage, setPanImage] = useState(null);


    // const handlepanImgUpload = (e) => {
    //     const file = e.target.files[0];
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setPanImage(reader.result);
    //     };
    //     if (file) {
    //         setFileName(file.name);
    //         reader.readAsDataURL(file);
    //     }
    // };

    // const handlepanClearImage = () => {
    //     setPanImage(null);
    //     setFileName("");
    //     // Reset the input field to allow selecting a new file
    //     const profilePicInput = document.getElementById("profilePicInput");
    //     if (profilePicInput) {
    //         profilePicInput.value = "";
    //     }
    // };
    const handlePdfUpload = (e, setFieldValue, fieldName) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            // Set the file in Formik's state
            setFieldValue(fieldName, file);
            console.log('PDF file uploaded:', file);
        } else {
            // Handle cases where the uploaded file is not a PDF
            console.error('Please upload a PDF file.');
        }
    };




    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };
    const [showQualification, setShowQualification] = useState(false);
    const [showWork, setWorkExperience] = useState(false);
    const handleToggleQualification = () => {
        setShowQualification(!showQualification);
    };
    const handleToggleWorkexperience = () => {
        setWorkExperience(!showWork);
    };

    const validateForm = (data) => {

        if (!data.bio.trim()) {
            errors.bio = 'bio is required';
        }

        if (!data.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Email is invalid';
        }

        if (!data.password) {
            errors.password = 'Password is required';
        } else if (data.password.length < 8) {
            errors.password = `Password must be at 
            least 8 characters long`;
        }

        if (data.confirmPassword !== data.password) {
            errors.confirmPassword = 'Passwords do not match';
        }
    }

    const firstname = ProfileDetails.firstname
    const lastname = ProfileDetails.lastname
    console.log("firstname", firstname);
    const [hasGstNumber, setHasGstNumber] = useState(false);
    const handleGstChange = (e) => {
        const value = e.target.value === 'yes';
        setHasGstNumber(value);
    };

    const validateBio = (value) => {
        let error;
        if (!value) {
            error = 'Bio is required';
        }
        return error;
    }
    const validateImage = (value) => {
        let error;
        if (!value) {
            error = 'Image is required';
        }
        return error;
    }
    const validateMobile = (value) => {
        let error;
        if (!value) {
            error = 'Mobile is required';
        }
        return error;
    }
    const validateDegree = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validateField = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validateOrganization = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validateYear = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validateExperience = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validatecategory = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validatecategoryQualification = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validatecategoryorganization = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validatecategoryDuration = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validatecategorycourse = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validatecategoryexperience = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validateCountry = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validateState = (value) => {
        let error;
        if (!value) {
            error = 'Degree is required';
        }
        return error;
    }
    const validatecategoryQualificationDocument = (value) => {
        if (!value || !value.name) {
            return "Please select a Qualification document.";
        }
        return undefined; // No validation error
    };
    return (
        <div>
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Profile</div>
            </div>
            <div class="container ">
                <Formik
                    initialValues={{
                        firstname: ProfileDetails?.firstname,
                        lastname: ProfileDetails?.lastname,
                        experience: "",
                        bio: "",
                        phone_num: "",
                        country_id: "",
                        state_id: "",
                        profile_pic: "",
                        linkedin: "",
                        facebook: "",
                        twitter: "",
                        instagram: "",
                        youtube: "",
                        house_no: "",
                        area: "",
                        district: "",
                        pincode: "",
                        pan_no: "",
                        pan_image: "",
                        gst_no: "",

                        education: [{ degree: "", organization: "", field_of_study: "", year_completed: "" }],
                        category: [{
                            category_id: '',
                            qualification: {
                                name: '',
                                document: null,
                                experience: '',
                                exp_document: null,
                                course_name: '',
                                duration: '',
                                org_name: '',
                            }
                        }]
                    }}
                    // validationSchema={SubjectValidationSchema}

                    onSubmit={(values, { resetForm }) => {
                        const formData = new FormData();
                        formData.append('firstname', firstname);
                        formData.append('lastname', lastname);
                        formData.append('experience', values.experience);
                        formData.append('phone_num', values.phone_num);
                        formData.append('country_id', values.country_id);
                        formData.append('state_id', values.state_id);
                        formData.append('topic', values.topic || ''); // Handling undefined values
                        formData.append('sub_category', values.sub_category || ''); // Handling undefined values
                        formData.append('bio', values.bio);
                        formData.append('linkedin', values.linkedin || '');
                        formData.append('facebook', values.facebook || '');
                        formData.append('twitter', values.twitter || '');
                        formData.append('instagram', values.instagram || '');
                        formData.append('youtube', values.youtube || '');
                        formData.append('profile_pic', values.profile_pic);
                        formData.append('house_no', values.house_no);
                        formData.append('area', values.area);
                        formData.append('district', values.district);
                        formData.append('pincode', values.pincode);
                        formData.append('pan_no', values.pan_no);
                        formData.append('pan_image', values.pan_image);
                        formData.append('gst_no', values.gst_no);


                        formData.append('professional', "1");
                        values.education.forEach((education, index) => {
                            formData.append(`education[${index}][degree]`, education.degree);
                            formData.append(`education[${index}][organization]`, education.organization);
                            formData.append(`education[${index}][field_of_study]`, education.field_of_study);
                            formData.append(`education[${index}][year_completed]`, education.year_completed);
                        });

                        values.category.forEach((category, index) => {
                            formData.append(`category[${index}][category_id]`, category.category_id);
                            formData.append(`category[${index}][qualification][name]`, category.qualification.name);
                            formData.append(`category[${index}][qualification][org_name]`, category.qualification.org_name);
                            formData.append(`category[${index}][qualification][duration]`, category.qualification.duration);
                            formData.append(`category[${index}][qualification][course_name]`, category.qualification.course_name);
                            const document = category.qualification.document || null;
                            formData.append(`category[${index}][qualification][document]`, document);
                            formData.append(`category[${index}][qualification][experience]`, category.qualification.experience);
                            formData.append(`category[${index}][qualification][exp_document]`, category.qualification.exp_document);
                        });

                        dispatch(updateProfile(formData));
                        resetForm();







                    }} >
                    {({ errors, touched, setFieldValue, values, handleChange }) => (
                        <Form>
                            <div class="row mt-4">
                                <div class="col ">
                                    <div class='admin-card py-4 text-center'>
                                        <div>
                                            <div className="d-flex flex-sm-column align-items-center justify-content-between">
                                                {profileImage ? (
                                                    <>
                                                        <img
                                                            src={profileImage}
                                                            className="img-fluid teacher_profile_pic"
                                                            alt={fileName}
                                                        />
                                                        <button className="btn btn-danger" onClick={handleClearImage}>
                                                            Change Image
                                                        </button>
                                                    </>
                                                ) : (
                                                    <div className="upload-btn-wrapper mb-4 ms-5 ms-sm-0">
                                                        <input
                                                            type="file"
                                                            id="profilePicInput"
                                                            name="photo"
                                                            accept="image/*"
                                                            onChange={handleImgUpload}
                                                            style={{ display: 'none' }}
                                                        />
                                                        {/* <label htmlFor="profilePicInput" className="btn pro-upload-btn">
                                                            Upload<span><FaCloudDownloadAlt style={{ fontSize: "30px" }} /></span>
                                                        </label> */}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p class='fw-bold fs-5 mt-3' >{ProfileDetails?.firstname} <span><MdOutlineVerified class='admin-verified-btn' /></span></p>
                                        {/* <div class="card-body teacher-profile-card">
                                            <p class="card-title fw-bold fs-5 ">Have A Question</p>
                                            <p class="card-text fs-6">Here you can send a direct request to <br /> the site owner</p>
                                        </div> */}
                                        {/* <button type="button" class="btn admin-navbar-search-btn text-light px-4 py-1 mt-5 mb-3 " onClick={() => navigate('/user/sign_in')}> <span class='me-2'><MdOutlineLogout /></span>Log Out</button> */}
                                    </div>
                                    <div class='admin-card mt-3 p-4'>
                                        <p class='fs-5 fw-semibold '>Social Network</p>
                                        <p>Add your social profiles links, they will be shown on your public profile.</p>
                                        <div>
                                            <label class='fw-semibold'>Linkedin</label>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text bg-light" id="basic-addon1">
                                                    <img src={img2} class="img-fluid " alt="" />
                                                </span>
                                                <Field type="text" class="form-control input-border-teacher" aria-label="Username" aria-describedby="basic-addon1"
                                                    name="linkedin" onChange={(e) => {
                                                        setFieldValue("linkedin", e.target.value)
                                                        handleInputChange(e)
                                                    }} />
                                            </div>
                                            <label class='fw-semibold'>Facebook</label>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text bg-light" id="basic-addon1">
                                                    <img src={facebook} class="img-fluid " alt="" />
                                                </span>
                                                <Field type="text" class="form-control input-border-teacher" aria-label="Username" aria-describedby="basic-addon1"
                                                    name="facebook"
                                                    onChange={(e) => {
                                                        setFieldValue("facebook", e.target.value)
                                                        handleInputChange(e)
                                                    }} />
                                            </div>
                                            <label class='fw-semibold'>Twitter</label>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text bg-light" id="basic-addon1">
                                                    <img src={twitter} class="img-fluid " alt="" />
                                                </span>
                                                <Field type="text" class="form-control input-border-teacher" aria-label="Username"
                                                    aria-describedby="basic-addon1" name="twitter" onChange={(e) => {
                                                        setFieldValue("twitter", e.target.value)
                                                        handleInputChange(e)
                                                    }} />
                                            </div>
                                            <label class='fw-semibold'>Instagram</label>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text bg-light" id="basic-addon1">
                                                    <img src={instagram} class="img-fluid " alt="" />
                                                </span>
                                                <Field type="text" class="form-control input-border-teacher" aria-label="Username" aria-describedby="basic-addon1"
                                                    name="instagram"
                                                    onChange={(e) => {
                                                        setFieldValue("instagram", e.target.value)
                                                        handleInputChange(e)
                                                    }} />
                                            </div>
                                            <label class='fw-semibold'>Youtube</label>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text bg-light" id="basic-addon1">
                                                    <img src={youtube} class="img-fluid " alt="" />
                                                </span>
                                                <Field type="text" class="form-control input-border-teacher" aria-label="Username" aria-describedby="basic-addon1"
                                                    name="youtube" onChange={(e) => {
                                                        setFieldValue("youtube", e.target.value)
                                                        handleInputChange(e)
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class='admin-card p-4'>
                                        <p class='fs-5 fw-semibold'>Profile Info</p>
                                        <div class="container text-start">
                                            <div class="row">
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>First Name</label>
                                                    <Field type="text" class="form-control input-border-teacher"
                                                        aria-label="Username" aria-describedby="basic-addon1"
                                                        value={firstname} name="firstname" />
                                                </div>
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>Last Name</label>
                                                    <Field type="text" class="form-control input-border-teacher"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" name="lastname"
                                                        value={lastname}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label className='mt-4 fw-semibold'>Bio</label><span className="star_req">*</span>
                                                    <Field
                                                        className={`form-control input-border-teacher ${errors.bio && touched.bio ? 'is-invalid' : ''}`}
                                                        component="textarea"
                                                        placeholder="Leave a comment here"
                                                        id="floatingTextarea"
                                                        name="bio"
                                                        validate={validateBio}
                                                        onChange={(e) => {
                                                            setFieldValue("bio", e.target.value);
                                                            handleInputChange(e);
                                                        }}
                                                        maxLength={150} // Adding maxLength attribute

                                                    />


                                                    <div><p>{profile_Details.bio.length}/ {150 - profile_Details.bio.length}</p></div>
                                                    <div className="invalid-feedback">
                                                        Please enter the Bio.
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <label className="mt-4 fw-semibold">Profile Photo</label>
                                                    <span className="star_req">*</span>
                                                    <input
                                                        type="file"
                                                        className="form-control input-border-teacher"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        name="profile_pic"
                                                        validate={validateImage}
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            setFieldValue("profile_pic", file);
                                                            handleInputChange(e);
                                                        }}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please enter the Bio.
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>Email Address</label>
                                                    <Field type="text" class="form-control input-border-teacher"
                                                        aria-label="Username" aria-describedby="basic-addon1 "
                                                        value={ProfileDetails?.email} name="email" onChange={(e) => handleInputChange(e)} />
                                                </div>
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>Mobile</label><span className="star_req">*</span>
                                                    <Field type="number"
                                                        className={`form-control input-border-teacher ${errors.phone_num && touched.phone_num ? 'is-invalid' : ''}`}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" name="phone_num"
                                                        validate={validateMobile}
                                                        onChange={(e) => {
                                                            setFieldValue("phone_num", e.target.value)
                                                            handleInputChange(e)
                                                        }} />
                                                    <div className="invalid-feedback">
                                                        Please enter the Mobile number.
                                                    </div>
                                                </div>
                                            </div>
                                            {education.map((edu, index) => (
                                                <div key={index}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <label className="mt-4 fw-semibold">Degree</label><span className="star_req">*</span>
                                                            <Field
                                                                type="text"
                                                                className={`form-control input-border-teacher ${errors.education?.[index]?.degree && touched.education?.[index]?.degree ? 'is-invalid' : ''}`}
                                                                aria-label="degree"
                                                                aria-describedby="basic-addon1"
                                                                name={`education[${index}].degree`}
                                                                validate={validateDegree}
                                                            />
                                                            {errors.education?.[index]?.degree && touched.education?.[index]?.degree ? (
                                                                <div className="invalid-feedback">
                                                                    Please enter the Degree.
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        <div className="col">
                                                            <label className="mt-4 fw-semibold">Field of Study</label><span className="star_req">*</span>
                                                            <Field
                                                                type="text"
                                                                className={`form-control input-border-teacher ${errors.education?.[index]?.field_of_study && touched.education?.[index]?.field_of_study ? 'is-invalid' : ''}`}
                                                                aria-label="field_of_study"
                                                                aria-describedby="basic-addon1"
                                                                name={`education[${index}].field_of_study`}
                                                                validate={validateField}
                                                            />
                                                            {errors.education?.[index]?.field_of_study && touched.education?.[index]?.field_of_study ? (
                                                                <div className="invalid-feedback">
                                                                    Please enter the Field of Study.
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <label className="mt-4 fw-semibold">Organization</label><span className="star_req">*</span>
                                                            <Field
                                                                type="text"
                                                                className={`form-control input-border-teacher ${errors.education?.[index]?.organization && touched.education?.[index]?.organization ? 'is-invalid' : ''}`}
                                                                aria-label="organization"
                                                                aria-describedby="basic-addon1"
                                                                name={`education[${index}].organization`}
                                                                validate={validateOrganization}
                                                            />
                                                            {errors.education?.[index]?.organization && touched.education?.[index]?.organization ? (
                                                                <div className="invalid-feedback">
                                                                    Please enter the Organization.
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col">
                                                            <label className="mt-4 fw-semibold">Completed Year</label><span className="star_req">*</span>
                                                            <Field
                                                                type="text"
                                                                className={`form-control input-border-teacher ${errors.education?.[index]?.year_completed && touched.education?.[index]?.year_completed ? 'is-invalid' : ''}`}
                                                                aria-label="year_completed"
                                                                aria-describedby="basic-addon1"
                                                                name={`education[${index}].year_completed`}
                                                                validate={validateYear}
                                                            />
                                                            {errors.education?.[index]?.year_completed && touched.education?.[index]?.year_completed ? (
                                                                <div className="invalid-feedback">
                                                                    Please enter the Field of Study.
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row d-flex justify-content-end mt-3">
                                                        <div className="col-1">
                                                            {index !== 0 && (
                                                                <div className="">
                                                                    <button type="button" onClick={() => removeEducationGroup(index)} className="btn btn-danger" title="Delete"><RiDeleteBinLine /></button></div>

                                                            )}
                                                        </div>
                                                        <div className="col-1">
                                                            <div className="">
                                                                <button type="button" onClick={addEducationGroup} className="btn admin-navbar-search-btn text-light" title="Add Another Qualification"> +</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}




                                            <div class="row">
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>Role</label>
                                                    <Field type="text" class="form-control input-border-teacher"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" name="professional"
                                                        value="Teacher" />
                                                </div>
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>Total Years Of Experience</label><span className="star_req">*</span>
                                                    <Field type="number"
                                                        className={`form-control input-border-teacher ${errors.experience && touched.experience ? 'is-invalid' : ''}`}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" name="experience"
                                                        validate={validateExperience}
                                                        onChange={(e) => {
                                                            setFieldValue("experience", e.target.value)
                                                            handleInputChange(e)
                                                        }} />
                                                    <div className="invalid-feedback">
                                                        Please enter the Total Experience.
                                                    </div>
                                                </div>

                                            </div>


                                            {values.category.map((category, categoryIndex) => (
                                                <div>
                                                    <div key={categoryIndex} className="row">
                                                        <div className="col">
                                                            <label className='mt-4 fw-semibold'>Category</label><span className="star_req">*</span>
                                                            <Field
                                                                as="select"
                                                                className={`form-control input-border-teacher ${errors.category?.[categoryIndex]?.category_id && touched.category?.[categoryIndex]?.category_id ? 'is-invalid' : ''}`}
                                                                name={`category.${categoryIndex}.category_id`}
                                                                value={category.category_id}
                                                                validate={validatecategory}
                                                                onChange={(e) => {
                                                                    setFieldValue(`category.${categoryIndex}.category_id`, e.target.value);
                                                                    handleInputChange(e);
                                                                }}
                                                            >
                                                                <option value="">Select an option</option>
                                                                {categoryOptions?.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            {errors.category?.[categoryIndex]?.category_id && touched.category?.[categoryIndex]?.category_id ? (
                                                                <div className="invalid-feedback">
                                                                    Please select the category.
                                                                </div>
                                                            ) : null}
                                                        </div>


                                                        <div>

                                                            <div className="col">
                                                                <label className='mt-4 fw-semibold'>Qualification in this category</label><span className="star_req">*</span>
                                                                <Field
                                                                    type="text"
                                                                    className={`form-control input-border-teacher ${errors.category?.[categoryIndex]?.qualification?.name && touched.category?.[categoryIndex]?.qualification?.name ? 'is-invalid' : ''}`}
                                                                    name={`category.${categoryIndex}.qualification.name`}
                                                                    value={category.qualification.name}
                                                                    validate={validatecategoryQualification}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`category.${categoryIndex}.qualification.name`, e.target.value);
                                                                        handleInputChange(e);
                                                                    }}
                                                                />
                                                                {errors.category?.[categoryIndex]?.qualification?.name && touched.category?.[categoryIndex]?.qualification?.name ? (
                                                                    <div className="invalid-feedback">
                                                                        Please enter the Qualification.
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <label className='mt-4 fw-semibold'>Name of the Organisation</label><span className="star_req">*</span>
                                                                <Field
                                                                    type="text"
                                                                    className={`form-control input-border-teacher ${errors.category?.[categoryIndex]?.qualification?.org_name && touched.category?.[categoryIndex]?.qualification?.org_name ? 'is-invalid' : ''}`}
                                                                    name={`category.${categoryIndex}.qualification.org_name`}
                                                                    value={category.qualification.org_name}
                                                                    validate={validatecategoryorganization}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`category.${categoryIndex}.qualification.org_name`, e.target.value);
                                                                        handleInputChange(e);
                                                                    }}
                                                                />
                                                                {errors.category?.[categoryIndex]?.qualification?.org_name && touched.category?.[categoryIndex]?.qualification?.org_name ? (
                                                                    <div className="invalid-feedback">
                                                                        Please enter the Oraganisation.
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="col">
                                                                <label className='mt-4 fw-semibold'>Duration of the course</label><span className="star_req">*</span>
                                                                <Field
                                                                    type="number"
                                                                    className={`form-control input-border-teacher ${errors.category?.[categoryIndex]?.qualification?.duration && touched.category?.[categoryIndex]?.qualification?.duration ? 'is-invalid' : ''}`}
                                                                    name={`category.${categoryIndex}.qualification.duration`}
                                                                    value={category.qualification.duration}
                                                                    validate={validatecategoryDuration}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`category.${categoryIndex}.qualification.duration`, e.target.value);
                                                                        handleInputChange(e);
                                                                    }}
                                                                />
                                                                {errors.category?.[categoryIndex]?.qualification?.duration && touched.category?.[categoryIndex]?.qualification?.duration ? (
                                                                    <div className="invalid-feedback">
                                                                        Please enter the Duration.
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <label className='mt-4 fw-semibold'>Course Name</label><span className="star_req">*</span>
                                                                <Field
                                                                    type="text"
                                                                    className={`form-control input-border-teacher ${errors.category?.[categoryIndex]?.qualification?.course_name && touched.category?.[categoryIndex]?.qualification?.course_name ? 'is-invalid' : ''}`}
                                                                    name={`category.${categoryIndex}.qualification.course_name`}
                                                                    value={category.qualification.course_name}
                                                                    validate={validatecategorycourse}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`category.${categoryIndex}.qualification.course_name`, e.target.value);
                                                                        handleInputChange(e);
                                                                    }}

                                                                />
                                                                {errors.category?.[categoryIndex]?.qualification?.course_name && touched.category?.[categoryIndex]?.qualification?.course_name ? (
                                                                    <div className="invalid-feedback">
                                                                        Please enter the course.
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="col">
                                                                <label className="mt-4 fw-semibold">Certificate</label><span className="star_req">*</span>
                                                                <input
                                                                    type="file"
                                                                    className={`form-control input-border-teacher ${errors.category?.[categoryIndex]?.qualification?.document && touched.category?.[categoryIndex]?.qualification?.document ? 'is-invalid' : ''}`}
                                                                    name={`category.${categoryIndex}.qualification.document`}
                                                                    validate={validatecategoryQualificationDocument}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`category.${categoryIndex}.qualification.document`, e.target.files[0]); // Set field value to file object
                                                                        handleInputChange(e); // Optionally, handle other changes if needed
                                                                    }}
                                                                />
                                                                {errors.category?.[categoryIndex]?.qualification?.document && touched.category?.[categoryIndex]?.qualification?.document ? (
                                                                    <div className="invalid-feedback">
                                                                        Please enter the Qualification document.
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <label className='mt-4 fw-semibold'>Working Experience in this Qualification</label><span className="star_req">*</span>
                                                                <Field
                                                                    type="number"
                                                                    className={`form-control input-border-teacher ${errors.category?.[categoryIndex]?.qualification?.experience && touched.category?.[categoryIndex]?.qualification?.experience ? 'is-invalid' : ''}`}
                                                                    name={`category.${categoryIndex}.qualification.experience`}
                                                                    value={category.qualification.experience}
                                                                    validate={validatecategoryexperience}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`category.${categoryIndex}.qualification.experience`, e.target.value);
                                                                        handleInputChange(e);
                                                                    }}
                                                                />
                                                                {errors.category?.[categoryIndex]?.qualification?.experience && touched.category?.[categoryIndex]?.qualification?.experience ? (
                                                                    <div className="invalid-feedback">
                                                                        Please enter the Experience .
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="col">
                                                                <label className="mt-4 fw-semibold">Experience Certificate</label><span className="star_req">*</span>
                                                                <input
                                                                    type="file"
                                                                    className="form-control input-border-teacher"
                                                                    name={`category.${categoryIndex}.qualification.exp_document`}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`category.${categoryIndex}.qualification.exp_document`, e.target.files[0]); // Set field value to file object
                                                                        handleInputChange(e); // Optionally, handle other changes if needed
                                                                    }}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end me-4 mt-2">
                                                        {categoryIndex !== 0 && ( // Render remove button for all categories except the first one
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger me-2" title="Delete"
                                                                onClick={() => {
                                                                    // Remove the category at index categoryIndex
                                                                    const updatedCategories = [...values.category];
                                                                    updatedCategories.splice(categoryIndex, 1);
                                                                    setFieldValue('category', updatedCategories);
                                                                }}
                                                            >
                                                                <RiDeleteBinLine />
                                                            </button>
                                                        )}
                                                        <button
                                                            type="button"
                                                            className="  btn admin-navbar-search-btn text-light"
                                                            onClick={() => {
                                                                // Add a new category object to the array
                                                                setFieldValue('category', [
                                                                    ...values.category,
                                                                    {
                                                                        category_id: '',
                                                                        qualification: {
                                                                            name: '',
                                                                            org_name: '',
                                                                            duration: '',
                                                                            course_name: '',
                                                                            document: null,
                                                                            experience: '',
                                                                            exp_document: null,
                                                                        },
                                                                    },
                                                                ]);
                                                            }}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="d-flex justify-content-end me-4 mt-2">

                                            </div>
                                            <p>Personal Details</p>
                                            <div className="row">
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>House number</label>
                                                    <Field type="text" class="form-control input-border-teacher"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" name="house_no"
                                                        onChange={(e) => {
                                                            setFieldValue("house_no", e.target.value)
                                                            handleInputChange(e)
                                                        }} />
                                                </div>
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>City</label>
                                                    <Field type="text" class="form-control input-border-teacher"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" name="area"
                                                        onChange={(e) => {
                                                            setFieldValue("area", e.target.value)
                                                            handleInputChange(e)
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>District</label>
                                                    <Field type="text" class="form-control input-border-teacher"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" name="district"
                                                        onChange={(e) => {
                                                            setFieldValue("district", e.target.value)
                                                            handleInputChange(e)
                                                        }} />
                                                </div>
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>Pincode</label>
                                                    <Field type="number" class="form-control input-border-teacher"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" name="pincode"
                                                        onChange={(e) => {
                                                            setFieldValue("pincode", e.target.value)
                                                            handleInputChange(e)
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div class="col">
                                                    <label class=' mt-4 fw-semibold'>PAN Number</label>
                                                    <Field type="text" class="form-control input-border-teacher"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" name="pan_no"
                                                        onChange={(e) => {
                                                            setFieldValue("pan_no", e.target.value)
                                                            handleInputChange(e)
                                                        }} />
                                                </div>


                                            </div>
                                            <div className="col">
                                                <label className="mt-4 fw-semibold mb-4">Do you have a GST Number?</label>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        name="hasGstNumber"
                                                        value="yes"
                                                        onChange={handleGstChange}
                                                    /> Yes
                                                    <input
                                                        type="radio"
                                                        className="ms-3"
                                                        name="hasGstNumber"
                                                        value="no"
                                                        onChange={handleGstChange}
                                                    /> No
                                                </div>
                                            </div>

                                            {hasGstNumber && (
                                                <div className="col">
                                                    <label className="mt-4 fw-semibold">GST Number</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control input-border-teacher"
                                                        aria-label="GST Number"
                                                        name="gst_no"
                                                        onChange={(e) => {
                                                            setFieldValue("gst_no", e.target.value);
                                                            handleInputChange(e);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div class="row">
                                                <div className="col">
                                                    <label class=' mt-4 fw-semibold'>Country</label><span className="star_req">*</span>
                                                    <Field
                                                        as="select"
                                                        type="text"
                                                        aria-label="Country"
                                                        aria-describedby="basic-addon1"
                                                        name="country_id"
                                                        className={`form-control input-border-teacher ${errors.country_id && touched.country_id ? 'is-invalid' : ''}`}
                                                        validate={validateCountry}
                                                        onChange={(e) => {
                                                            setFieldValue("country_id", e.target.value)
                                                            handleInputChange(e)
                                                        }}>
                                                        <option value="" >Select a country</option>
                                                        {Array.isArray(countryList,) &&
                                                            countryList.map(({ key, country_name }) => (
                                                                <option key={key} value={key} >
                                                                    {country_name}
                                                                </option>
                                                            ))}
                                                    </Field>
                                                    {errors.country_id && touched.country_id ? (
                                                        <div className="invalid-feedback">
                                                            Please select Country.
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="col">
                                                    <label class=' mt-4 fw-semibold'>State</label><span className="star_req">*</span>
                                                    <Field
                                                        as="select"
                                                        type="text"
                                                        aria-label="State"
                                                        aria-describedby="basic-addon1"
                                                        name="state_id"
                                                        className={`form-control input-border-teacher ${errors.state_id && touched.state_id ? 'is-invalid' : ''}`}
                                                        validate={validateState}
                                                        onChange={(e) => {
                                                            setFieldValue("state_id", e.target.value);
                                                            handleInputChange(e);
                                                        }}
                                                    >
                                                        <option value="">Select a state</option>
                                                        {stateList.map(state => (
                                                            <option key={state.id} value={state.id}>{state.name}</option>
                                                        ))}
                                                    </Field>

                                                    {errors.state_id && touched.state_id ? (
                                                        <div className="invalid-feedback">
                                                            Please select State.
                                                        </div>
                                                    ) : null}

                                                </div>
                                            </div>
                                            <div className="row">
                                            </div>
                                        </div>
                                        <div className="row mt-4 d-flex justify-content-center">
                                            <div className="col-2">
                                                <button
                                                    type="submit"
                                                    className="btn admin-signIn-btn"
                                                    disabled={loading}>
                                                    <span className="admin-signIn-btn-text">{loading ? <div className='d-flex justify-content-center'><RotatingLines
                                                        strokeColor="white"
                                                        strokeWidth="5"
                                                        animationDuration="0.75"
                                                        width="40"
                                                        height="40"
                                                        visible={true}
                                                    />
                                                    </div> : "Submit"}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    )
}

export default TeacherProfile