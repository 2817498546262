import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DotsIcon from '../../../Sidebar/icons/DotsIcon.png'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import DataTable from 'react-data-table-component';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './TeacherNewAttendence.scss'
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function TeacherNewAttendence(props) {
    const params = useParams()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const [isactions, setActions] = useState(false)
    const [activeMenu, setActiveMenu] = useState(null)
    const [hasMore, setHasMore] = useState(true);
    const [view, setView] = useState(false)
    const [loading, setLoading] = useState()
    const [row, setRows] = useState()
    const [data, setData] = useState([{
        date: '',
        category: '',
        sub_category: '',
        topic: '',
        batch_id: ''
    }]);




    const handleIconClick = () => {
        setView(!view);
    };

    const handleClickActions = (event, rowId) => {
        setAnchorEl(event.currentTarget);
        setActions((prevState) => (prevState === rowId ? null : rowId))
    };

    const handleClose = () => {
        setAnchorEl(null)
    }
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherattendence' })
        }
    }

    const goToTeacherViewAttendence = (e, row, batch_id, date) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewattendence', row: row })
        }
        setActions(false)
        setActiveMenu(null)
    }

    const goToTeacherAttendence = (e, row, batch_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'createattendence', row: row, batch_id: batch_id })
        }
        setActions(false)
        setActiveMenu(null)
    }
    // Data table structure
    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.date,

        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true

        },
        {
            name: 'Subcategory',
            selector: row => row.sub_category,
            sortable: true

        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true

        },
        {
            name: 'Batch',
            selector: row => row.batch_id,
            sortable: true
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, batch_id, date, rowIndex) => (
                <div className={`Teacher test-actions ${isactions == row.id ? '' : 'active'}`}>
                    <Button className="action-subj d-flex" id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e) => handleClickActions(e, row.id)}>
                        <MoreVertIcon />
                    </Button>

                    <div >

                        {isactions == row.id &&

                            <Menu
                                className="dots-actions"
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >

                                <MenuItem onClick={(e) => goToTeacherViewAttendence(e, row, batch_id, date)} disableRipple className="ul-for-options pt0">
                                    View
                                </MenuItem>
                                <MenuItem onClick={(e) => goToTeacherAttendence(e, row, batch_id)} disableRipple className="ul-for-options">
                                    Edit
                                </MenuItem>

                            </Menu>
                        }
                    </div>



                </div>
                // <div>
                //     {/* Add your icons here */}

                //     {/* <FontAwesomeIcon icon={faTrash}  />&nbsp;&nbsp; */}
                //     <FontAwesomeIcon icon={faEdit} onClick={handleIconClick} />
                //     {view && (
                //         <div className='dropbox-action-assign-schedule'>
                //             <div className='show-view'>
                //                 <label onClick={(e) => goToTeacherViewAttendence(e, row, batch_id, date)} >View</label><br />
                //                 <label onClick={(e) => goToTeacherAttendence(e, row, batch_id)}>Edit</label><br />
                //             </div>
                //         </div>
                //     )}

                // </div>
            )

        }

    ];

    useEffect(() => {
        getRows();
        fetchAttendence();
    }, [])

    const fetchAttendence = () => {

        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/listAttendance', undefined, { headers: headers })
            .then((response) => {

                console.log(response)
                if (_.get(response, 'data.data')) {
                    setData(response.data.data);
                    setLoading(false);
                }
                const formattedData = response.data.data.map((item) => ({
                    ...item,
                    date: format(new Date(item.date), 'dd-MM-yyyy'),
                }));

                setData(formattedData);
                setLoading(false);

            })
            .catch((error) => {
                console.error('Error fetching Subjects:', error);
                setLoading(false);

            })
    }

    const getRows = () => {
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        let res = [];
        res = data.map((item, index) => {
            return ({
                date: item.date,
                category: item.category,
                sub_category: item.sub_category,
                topic: item.des,
                batch_id: item.batch_id
            })
        })
        setRows(res);
    }
    const deleteRow = (batchId) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }
    }

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Attendence</div>
            </div>

            <button className='btn-create-Attendence' onClick={(e) => goToTeacherAttendence(e)}>Mark Attendence</button>

            <div class="col-11 mt-3 ms-5 justify-content-center">
                <DataTable
                    className='dataTable'
                    data={data}
                    columns={columns}
                    customStyles={customStyles}
                    pagination
                    fixedHeader={true}
                    fixedHeaderScrollHeight="600px"
                    striped
                    persistTableHead
                />
                <InfiniteScroll
                    dataLength={data.length} // This is important to track the length of data
                    next={fetchAttendence}
                    hasMore={hasMore}
                ></InfiniteScroll>

            </div>



        </div>
    )
}

export default TeacherNewAttendence