import React, { useEffect, useState, } from "react"
import { useNavigate, } from "react-router-dom";
import "./Navtution.scss";
import { showErrorToast, showSuccessToast } from './../../../../../../../util/toastUtils'
import _ from "lodash";
import axios from "axios";
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from "../../../../../../../redux/slice/SubjectSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { IoIosCloseCircle } from "react-icons/io";
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";
function NavtutionOnline({ successCallback, ...props }) {
  const navigate = useNavigate();
  const Backdashboard = () => {
    if (_.get(props, "setUserState")) {
      props.setUserState({ ...props.userState, subType: "Dashboard" });
    }
  };


  const [fields, setFields] = useState(['']); // Initialize with one empty field
console.log({fields});
  // Function to add a new input field
  const addField = () => {
    setFields([...fields, '']);
  };






  // Function to handle changes in input fields
  const handleFieldChange = (index, value) => {
    const updatedFields = [...fields];
    updatedFields[index] = value;
    setFields(updatedFields);
  };
  const handleDownload = () => {
    // Logic to generate or fetch the file URL
    const fileUrl = 'https://example.com/file.pdf';

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'filename.pdf';

    // Dispatch a click event on the link to trigger the download
    link.dispatchEvent(new MouseEvent('click'));
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = () => {
    // Perform upload logic with the selected file
    // For example, you can use the 'selectedFile' state to access the file data
    console.log(selectedFile);
  };
  const [loading, setLoading] = useState({});
  const QuotationFreevalidationSchema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
    sub_category: Yup.string().required('Sub Category is required'),
    topic: Yup.string().required('Topic is required'),
    details: Yup.string().required('Details is required'),
    closing_date: Yup.string().required('Closing date is required'),
    currency: Yup.string().required('currency is required'),
    price: Yup.string().required('price is required'),
    term: Yup.string().required('term is required'),
    file: Yup.string().required('file is required'),

    // Add more validations for other fields
  });
  const [QuotationFreelance, setQuotationFreelance] = useState({
    category: "",
    sub_category: "",
    details: "",
    topic: "",
    closing_date: null,
    student_id: "",
    type: "",
    currency: "",
    price: " ",
    term: "",
    file: "",
    status: "",
    updated_at: "",
    created_at: "",
    id: "",
  })
  const QuotationStudent = () => {

    if (!localStorage.getItem('token')) {
      Backdashboard();
    }
    const formData = new FormData();
    formData.append("category", QuotationFreelance.category);
    formData.append("sub_category", QuotationFreelance.sub_category);
    formData.append("details", QuotationFreelance.details);
    formData.append("topic", QuotationFreelance.topic);
    formData.append("closing_date", QuotationFreelance.closing_date);
    formData.append("student_id", QuotationFreelance.student_id);
    formData.append(" type", QuotationFreelance.type);
    formData.append("currency", QuotationFreelance.currency);
    formData.append("price", QuotationFreelance.price);
    formData.append("term", QuotationFreelance.term);
    formData.append("file", QuotationFreelance.file);
    formData.append("status", QuotationFreelance.status);

    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')), }
    Axios
      .post(
        '/api/createQuotationFreelancing', formData,
        { headers: headers },
      )
      .then((res) => {
        if (res.data.success) {
          let user = _.get(res, "data.data.user")
            ? JSON.stringify(res.data.data.user)
            : undefined;
          let token = _.get(res, "data.data.token")
            ? JSON.stringify(res.data.data.token)
            : undefined;
          if (user) {
            localStorage.setItem("user", JSON.stringify(res.data.data.user));
          }
          if (token) {
            localStorage.setItem("token", (res.data.data.token));
          }
          showSuccessToast("Freelance Quotation created successfully");

          setTimeout(() => {
            // Update your table or perform other actions here
            setQuotationFreelance(res.data.data);
            updateDataTable();
            if (successCallback) {
              successCallback(res.data.data);
            }
          }, 2000); // Delay for 2 seconds          
        }
      })
      .catch((error) => {
        showErrorToast("Something Went Wrong");
        setLoading(false);
      });
  }
  const [dataTableData, setDataTableData] = useState([]); // State to store data for the data table

  // Function to update the data table
  const updateDataTable = (newData) => {
    setDataTableData([...dataTableData, newData]);
  };
  const handleInput = (e) => {
    // const selectedValue = e.target.value;
    // const payload = {
    //   "type": 2,
    //   "category_id": selectedValue,
    // };
    // dispatch(getSubCategoryData(payload));

    const { name, value } = e.target;

    console.log(name, value)
    // Update other fields in QuotationFreelance
    setQuotationFreelance({ ...QuotationFreelance, [name]: value });
  };
  useEffect(() => {
    if (_.get(props, "userState.row")) {
      initializeObject();
    }
  }, []);
  const initializeObject = () => {
    setQuotationFreelance({ ...QuotationFreelance, ...props.userState.row });
  };

  const data = useSelector(CSTData);
  const dispatch = useDispatch();
  const subCatData = useSelector(SubData);
  const TopicData = useSelector(topicData);


  useEffect(() => {
    const payload = {
      type: 1
    };
    dispatch(getCSTData(payload));
  }, []);


  const categoryOptions = data?.map((e) => ({
    value: e.id,
    label: e.name
  }));

  const SubCategoryOptions = subCatData?.map((e) => ({
    value: e.id,
    label: e.name
  }));

  const TopicOptions = TopicData?.map((e) => ({
    value: e.id,
    label: e.name
  }));



  const removeField = (index) => {
    // Remove the field at the specified index from the state
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };



  return (
    <div>
      {/* <div class="container text-start">
        <div class="row">
          <div className="d-flex justify-content-center">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-11 col" >
              <h4 className="student-quote-head mb-0">Get a Quote <span className="fw-normal">( Exam, Projects, Homework, etc)</span></h4>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            category: '',
            sub_category: '',
            topic: '',
            details: '',
            closing_date: null,
            currency: "",
            price: " ",
            term: "",
            file: "",
          }}
          validationSchema={QuotationFreevalidationSchema}
          onSubmit={(values, { resetForm }) => {
            const payload = {
              "category": values.category,
              "sub_category": values.sub_category,
              "topic": values.topic,
              "details": values.details,
              "closing_date": values.closing_date,
              "currency": values.currency,
              "price": values.price,
              "term": values.term,
              "file": values.file,

            }
            QuotationStudent();
            resetForm();

          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div class="row">
                <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-center ">
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6 col mt-3 me-xxl-3 me-xl-3 me-lg-3 me-md-3 me-sm-3 me-0">
                    <label className="stu-free-quote">Category</label>
                    <Field
                      className="form-select admin-dropdown "
                      // id="categoryDropdown"
                      name="category"
                      as="select"
                      value={QuotationFreelance.category}
                      onChange={(e) => {
                        console.log("e", e.target.value);
                        const selectedValue = e.target.value;
                        const payload = {
                          "type": 2,
                          "category_id": selectedValue,
                        };
                        dispatch(getSubCategoryData(payload));
                        setFieldValue("category", e.target.value)
                        handleInput(e);
                      }}
                    >
                      <option value=""></option>
                      {categoryOptions?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="category"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6 col mt-3">
                    <label className="stu-free-quote">Sub Category</label>
                    <Field
                      className="form-select admin-dropdown"
                      id="categoryDropdown"
                      name="sub_category"
                      as="select"
                      value={QuotationFreelance.sub_category}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        const payload = {
                          "type": 3,
                          "sub_category_id": selectedValue,
                        };
                        dispatch(getSubTopicData(payload));
                        setFieldValue("sub_category", e.target.value)
                        handleInput(e);
                      }}
                    >
                      <option value=""></option>
                      {SubCategoryOptions?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="sub_category"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-center">
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6 col mt-3 me-xxl-3 me-xl-3 me-lg-3 me-md-3 me-sm-3 me-0">
                    <label className="stu-free-quote">Topic</label>
                    <Field
                      className="form-select admin-dropdown"
                      as="select"
                      id="categoryDropdown"
                      name="topic"
                      value={QuotationFreelance.topic}
                      onChange={(e) => {
                        setFieldValue("topic", e.target.value)
                        handleInput(e)
                      }
                      }                      >
                      <option value=""></option>
                      {TopicOptions?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="topic"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6 col  mt-3">
                    <label className="stu-free-quote">Quote closing Date:</label>
                    <div>
                      <Field className="col-12 p-1 stu-free-date" type="Date"
                        required
                        autoFocus
                        value={QuotationFreelance.closing_date}
                        name="closing_date"
                        onChange={(e) => {
                          setFieldValue("closing_date", e.target.value)
                          handleInput(e)
                        }
                        } />
                      <ErrorMessage
                        name="closing_date"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-center">
                  <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-6 col mt-3  me-3">
                    <label className="stu-free-quote"> Describe your profile:</label>
                    <div>
                      <Field className="form-control" type="textarea"
                        component="textarea"
                        required
                        autoFocus
                        value={QuotationFreelance.details}
                        name="details"
                        onChange={(e) => {
                          setFieldValue("details", e.target.value)
                          handleInput(e)
                        }
                        } />
                      <ErrorMessage
                        name="details"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-center mt-2">
                  <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-10 col">

                    <div className="row ">
                      {fields.map((field, index) => (
                        <div key={index}
                          className="col">

                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-8 col-sm-8 col  ">
                            <label className="stu-file-quote m-0 "> Attach Files</label>
                    
                            <input
                              type="file"
                              id="fileInput"
                              className="choose-file"
                              name="file"
                              accept=".pdf, .doc, .docx"
                              onChange={(event) => {
                                handleFieldChange(index, event.target.value);
                                setFieldValue('file', event.currentTarget.files[0]);
                              }}
                            />

                            <span className="mt-1 ms-3"> <button onClick={addField} className="button-4">
                              +
                            </button>
                            <button className="btn" ><IoIosCloseCircle style={{backgroundColor:"#5c5ab6"}}  onClick={() => removeField(index)}/></button>
                            </span>
                            <div class="mb-xxl-1 mb-xl-1 mb-lg-1 mb-md-1 md-sm-1 row">
                              <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-between">
                                <div class="col">
                                  <p class="file-size fw-semibold">
                                    File size (pdf,jpg) maximum 3MB
                                  </p>
                                </div>
                              </div>
                            </div>
                            <ErrorMessage name="file" component="div" className="text-danger" />
                          </div>


                        </div>
                      ))}






                    </div>



                  </div>
                </div>
              </div>

              <div className="d-xxl-flex d-xl-flex d-lg-flex d-md-flex  justify-content-center mt-2">
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-10 col">
                  <div className="row">

                    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-sm-2 col ">
                      <label className="stu-free-quote mb-0">Currency</label>
                      <Field type="text" name="currency" className="form-control" placeholder="INR/USD"
                        onChange={(e) => {
                          setFieldValue("currency", e.target.value)
                          handleInput(e)
                        }}
                      />
                      <ErrorMessage
                        name="currency"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-2 col ">
                      <label className="stu-free-quote mb-0">Price</label>
                      <Field type="text" className="form-control text-end" name="price" onChange={(e) => {
                        setFieldValue("price", e.target.value)
                        handleInput(e)
                      }
                      } />
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-3 col ">
                      <label className="stu-free-quote mb-0">Hourly/Fixed </label>
                      <Field as="select" className="form-select" name="term">
                        <option onChange={(e) => {
                          setFieldValue("term", e.target.value)
                          handleInput(e)
                        }
                        } selected></option>
                        <option value="Hourly">Hourly</option>
                        <option value="Fixed">Fixed</option>
                      </Field>
                      <ErrorMessage
                        name="term"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col text-end" >
                  <div class="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-sm-12 col">
                    <div className="align-submit-btn mt-5 d-xxl-flex d-xl-flex d-lg-flex d-md-flex ">
                      <button type="submit"
                        className="btn btn-primary button-3 p-xxl-2 p-xl-2 p-lg-2 p-md-2 p-sm-2 "
                        onClick={() => QuotationStudent()} >                    
                        Request Quotes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div> */}
    </div>
  );
}

export default NavtutionOnline;
