import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import axios from 'axios'
import './AddAnotherBankAccnt.scss'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from '../../../../../../util/toastUtils';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';


function AddAnotherBankAccnt(props) {

    const BankOptions = ['YES Bank', ' Tamilnad Mercantile Bank', 'South Indian Bank', 'RBL Bank', 'Nainital Bank', 'IDBI Bank', 'Kotak Mahindra Bank', 'Karur Vysya Bank',
        'Karnataka Bank', 'ammu & Kashmir Bank', 'IDFC FIRST Bank', ' IndusInd Bank', 'ICICI Bank', 'HDFC Bank', 'Federal Bank', ' Dhanlaxmi Bank',
        'DCB Bank', 'City Union Bank', ' CSB Bank', 'Bandhan Bank', 'State Bank of India', 'Bank of Baroda', ' Bank of India', ' Bank of Maharashtra',
        'Canara Bank', 'Central Bank of India', ' Indian Bank', 'Indian Overseas Bank', 'Punjab and Sind Bank', 'Punjab National Bank', ' UCO Bank', 'Union Bank of India']

    const StateOption = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka',
        'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana',
        'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal']

    const CurrencyOption = ['INR']
    const [errors, setErrors] = useState([""]);
    const WalletEdit = props.userState.viewEdit || {};
    const [edit, setEdit] = useState()

    const validationSchema = Yup.object().shape({
        bank_name: Yup.string().required('Bank name is required'),
        branch_name: Yup.string().required('Branch name is required'),
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last name is required'),
        account_number: Yup.string().required('Account number is required'),
        zipcode: Yup.string().required('Zip code is required'),
        ifsc_code: Yup.string().required('IFSC code is required')
    });


    const [wallet, setWallet] = useState(_.get(props, 'userState.selectedAccount') || {
        bank_name: '',
        branch_name: '',
        firstname: '',
        lastname: '',
        account_number: '',
        zipcode: '',
        country: '',
        currency: '',
        state: '',
    })
    const ToSubmit = (values, { setSubmitting, resetForm }) => {
        const formData = new FormData();
        formData.append('bank_name', wallet.bank_name)
        formData.append('branch_name', wallet.branch_name)
        formData.append('firstname', wallet.firstname)
        formData.append('lastname', wallet.lastname)
        formData.append('account_number', wallet.account_number)
        formData.append('zipcode', wallet.zipcode)
        formData.append('country', wallet.country)
        formData.append('currency', wallet.currency)
        formData.append('state', wallet.state)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/addAccountDetails',
            formData,
            { headers: headers })
            .then((res) => {
                if (res.data.success) {
                    goToTeacherWithdrawl();
                    resetForm();
                    showSuccessToast("Account details Added Successfully");
                }
                else {
                    showErrorToast("Account details Added Failed")
                }
            })
            .catch((error) => {

                console.error('Error fetching Coupons:', error)
            })
            .finally(() => {
                setSubmitting(false); // Ensure setSubmitting is called in any case
            });
    };
    const ToEdit = () => {
        const id = parseInt(_.get(WalletEdit.id) || 2);
        const formData = new FormData();
        formData.append('bank_name', wallet.bank_name)
        formData.append('branch_name', wallet.bank_name)
        formData.append('firstname', wallet.firstname)
        formData.append('lastname', wallet.lastname)
        formData.append('account_number', wallet.account_number)
        formData.append('zipcode', wallet.zipcode)
        formData.append('country', wallet.country)
        formData.append('currency', wallet.currency)
        formData.append('state', wallet.state)
        const headers = { 'Authorization': 'Bearer ' +(localStorage.getItem('token')) }

        Axios.post('/api/editAccountDetails',
            formData,
            { headers: headers })
            .then((res) => {
                console.log(res)
                const updateData = res.data
                setEdit(updateData)
                Backdashboard()
            });
    }

    useEffect(() => {
        if (_.get(props, "userState.id")) {
            initializeObject();
        }
    }, [])
    const initializeObject = () => {
        setWallet({ ...wallet, ...props.userState.id })
    }

    const handleChange = (e) => {
        setWallet({ ...wallet, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: " " });
    };
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'requestwithdrawl' })
        }
    }

    const goToTeacherWithdrawl = (e, id, row) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'requestwithdrawl', id: id, row: row })
        }
    }


    const handleInput = (e) => {
        const { name, value } = e.target;
        setWallet({ ...wallet, [name]: value });

    };

    return (
        <div className='main-divHead' >

            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Add Bank Account Details</div>
            </div>
            <Formik
                initialValues={{
                    bank_name: '',
                    branch_name: '',
                    firstname: '',
                    lastname: '',
                    account_number: '',
                    zipcode: '',
                    ifsc_code: '',
                    currency: '',
                    state: '',
                    country: '',
                }}
                validationSchema={validationSchema}
                onSubmit={ToSubmit}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className='container'>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="bank_name" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Bank Name</label><br />
                                    <Field
                                        as="select"
                                        id="bank_name"
                                        name="bank_name"
                                        value={wallet.bank_name}
                                        onChange={(e) => {
                                            setFieldValue("bank_name", e.target.value)
                                            handleInput(e);
                                        }}
                                        className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                    >
                                        <option value="">--select a Bank--</option>
                                        {BankOptions.map((bankname, index) => (
                                            <option key={index} value={bankname}>{bankname}</option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="bank_name" component="div" className="text-danger ms-5 error_accnt" />
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="branch_name" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Branch Name</label><br />
                                    <Field
                                        type="text"
                                        id="branch_name"
                                        name="branch_name"
                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                        onChange={(e) => {
                                            setFieldValue("branch_name", e.target.value)
                                            handleInput(e);
                                        }}

                                    />
                                    <ErrorMessage name="branch_name" component="div" className="text-danger ms-5 error_accnt" />
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="firstname" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">First Name</label><br />
                                    <Field
                                        type="text"
                                        id="firstname"
                                        name="firstname"
                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"

                                        onChange={(e) => {
                                            setFieldValue("firstname", e.target.value)
                                            handleInput(e);
                                        }}
                                    />
                                    <ErrorMessage name="firstname" component="div" className="text-danger error_accnt ms-5" />
                                </div>
                            </div>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="lastname" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Lastname</label><br />
                                    <Field
                                        type="text"
                                        id="lastname"
                                        name="lastname"
                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                        onChange={(e) => {
                                            setFieldValue("lastname", e.target.value)
                                            handleInput(e);
                                        }}
                                    />
                                    <ErrorMessage name="lastname" component="div" className="text-danger ms-5 error_accnt" />
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="account_number" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Account Number</label><br />
                                    <Field
                                        type="number"
                                        id="account_number"
                                        name="account_number"
                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"

                                        onChange={(e) => {
                                            setFieldValue("account_number", e.target.value)
                                            handleInput(e);
                                        }}
                                    />
                                    <ErrorMessage name="account_number" component="div" className="text-danger error_accnt ms-5" />
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="zipcode" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Zipcode</label><br />
                                    <Field
                                        type="text"
                                        id="zipcode"
                                        name="zipcode"
                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                        onChange={(e) => {
                                            setFieldValue("zipcode", e.target.value)
                                            handleInput(e);
                                        }}
                                    />
                                    <ErrorMessage name="zipcode" component="div" className="text-danger error_accnt ms-5" />
                                </div>
                            </div>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="ifsc_code" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">IFSC code</label><br />
                                    <Field
                                        type="text"
                                        id="ifsc_code"
                                        name="ifsc_code"
                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                        onChange={(e) => {
                                            setFieldValue("ifsc_code", e.target.value)
                                            handleInput(e);
                                        }}
                                    />
                                    <ErrorMessage name="ifsc_code" component="div" className="text-danger ms-5 error_accnt" />
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="currency" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Currency</label><br />
                                    <Field
                                        type="text"
                                        id="currency"
                                        name="currency"
                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                        onChange={(e) => {
                                            setFieldValue("currency", e.target.value)
                                            handleInput(e);
                                        }}
                                    />
                                    <ErrorMessage name="currency" component="div" className="text-danger" />
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="state" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">State</label><br />
                                    <Field
                                        type="text"
                                        id="state"
                                        name="state"
                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                        onChange={(e) => {
                                            setFieldValue("state", e.target.value)
                                            handleInput(e);
                                        }}
                                    />
                                    <ErrorMessage name="state" component="div" className="text-danger " />
                                </div>
                            </div>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <label htmlFor="country" className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">country</label><br />
                                    <Field
                                        type="text"
                                        id="country"
                                        name="country"
                                        className="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                        onChange={(e) => {
                                            setFieldValue("country", e.target.value)
                                            handleInput(e);
                                        }}
                                    />
                                    <ErrorMessage name="country" component="div" className="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div className="container text-end">
                            <div className="row">
                                <div className="col">
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-4 rounded-pill py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3 me-5"
                                        disabled={isSubmitting}
                                    >
                                        {!_.get(props, 'userState.id') ? "Save" : "Update"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>


        </div>
    )
}

export default AddAnotherBankAccnt

