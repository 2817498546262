import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { initial } from "lodash";
import { FaPlus } from "react-icons/fa";
import "./TeacherAddSubject.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData, viewCategoryData } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from "../../../../../../util/toastUtils"
import React, { useEffect, useState, } from "react"
import { getLanguages, getLanguagesData } from "../../../../../../redux/slice/studentSlice";
import { RiArrowDropDownLine } from "react-icons/ri";
import img1 from '../../../../../../images/Ellipse 797.png'
import BASE_URL from "../../../../../../BaseUrl";
import Axios from "../../../../../../util/apiUtil";
import { MdAdd, MdDelete } from "react-icons/md";
import { Modal } from 'bootstrap';

function TeacherAddSubject(props) {
    const [showTopicFields, setShowTopicFields] = useState(false);
    const initialValues = {
        category: "",
        sub_category: "",
        name: '',
        subject: "",
        level: "",
        price: "",
        language: "",
        discount_per: "",
        video_link: "",
        description: "",
        discount_amount: "",
        payment: "",
        currency: "",
        image: "",
        period: "",
        duration: "",
        name: '', duration: '', description: '', topics: [{ title: '', duration: '', description: '' }]

    };
    let [errors, setErrors] = useState([""]);
    const [isEditSubject, setIsEditSubject] = useState(false);
    const [loading, setLoading] = useState(true);
    const [collapseOpen, setCollapseOpen] = useState(false);




    const [topicName, setTopicName] = useState('')
    const [topicdes, setTopicdes] = useState('')
    const [topicduration, setTopicduration] = useState('')
    const [chapterduration, setchapterduration] = useState('')

    const [collapsedChapters, setCollapsedChapters] = useState([]);




    const [topics, setTopic] = useState([]);
    const [chapterName, setChapterName] = useState('')
    const [chapterdes, setChapterdes] = useState('')


    const [containers, setContainers] = useState([{ id: 1 }]);

    const categoryData = useSelector(viewCategoryData);
    const [chapters, setChapters] = useState([
        {
            name: '',
            duration: '',
            description: '',
            topics: [
                {
                    title: '',
                    description: '',
                    duration: ''
                }
            ]
        }
    ]);



    const [visibleTopics, setVisibleTopics] = useState([[]]);

    const handleChange = (e, index, field, subField) => {
        const updatedChapters = [...chapters];
        if (subField !== undefined) {
            updatedChapters[index][field][subField] = e.target.value;
        } else {
            updatedChapters[index][field] = e.target.value;
        }
        setChapters(updatedChapters);
    };


    const handleTopicChange = (e, chapterIndex, topicIndex, property) => {
        const { value } = e.target;

        // Update the topic property
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].topics[topicIndex][property] = value;
        setChapters(updatedChapters);

        // Recalculate chapter duration
        const newChapterDuration = calculateChapterDuration(chapterIndex);
        const updatedChapter = { ...updatedChapters[chapterIndex], duration: newChapterDuration };
        updatedChapters[chapterIndex] = updatedChapter;
        setChapters(updatedChapters);
    };


    const addChapter = () => {
        setChapters([...chapters, { name: '', duration: '', description: '', topics: [{ title: '', description: '', duration: '' }] }]);
        setVisibleTopics([...visibleTopics, []]);
    };
    const addTopic = (chapterIndex) => {
        // Add a new topic to the specified chapter
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].topics.push({
            title: '',
            duration: '',
            description: ''
        });
        setChapters(updatedChapters);

        // Recalculate chapter duration
        const newChapterDuration = calculateChapterDuration(chapterIndex);
        const updatedChapter = { ...updatedChapters[chapterIndex], duration: newChapterDuration };
        updatedChapters[chapterIndex] = updatedChapter;
        setChapters(updatedChapters);
    };
    const calculateChapterDuration = (chapterIndex) => {
        let totalDuration = 0;
        chapters[chapterIndex].topics.forEach((topic) => {
            if (!isNaN(parseInt(topic.duration))) {
                totalDuration += parseInt(topic.duration);
            }
        });
        return totalDuration;
    };


    const deleteTopic = (chapterIndex, topicIndex) => {
        console.log('Deleting topic:', chapterIndex, topicIndex); // Add this line
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].topics.splice(topicIndex, 1);
        setChapters(updatedChapters);

        const updatedVisibleTopics = [...visibleTopics];
        // Reset visibility to true if the first topic is deleted
        if (topicIndex === 0) {
            updatedVisibleTopics[chapterIndex][topicIndex] = true;
        } else {
            updatedVisibleTopics[chapterIndex].splice(topicIndex, 1);
        }
        setVisibleTopics(updatedVisibleTopics);
    };


    const deleteChapter = (chapterIndex) => {
        const updatedChapters = [...chapters];
        updatedChapters.splice(chapterIndex, 1);
        setChapters(updatedChapters);

        const updatedVisibleTopics = [...visibleTopics];
        updatedVisibleTopics.splice(chapterIndex, 1);
        setVisibleTopics(updatedVisibleTopics);
    };


    const onClickEditSubject = () => {
        setIsEditSubject(false);
    };
    const Backdashboard = () => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "Teachersubject" });
        }
    };

    const goToTeacherAddSubjectCategory = (e) => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "addsubjectcategory" });
        }
    };

    const goToTeacherAddSubCategory = () => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "addsubcategory" });
        }
    };

    const goToTeacherAddTopic = (e) => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "addtopic" });
        }
    };

    const goToSubCategory = () => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "subcategory", });
        }
    };

    const goToTopic = (e) => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "addtopic" });
        }
    };

    const goToTeacherAddLanguage = (e) => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "addlanguage" });
        }
    };

    const subject_id = useParams();

    const languagesData = useSelector(getLanguagesData);
    const languageList = languagesData.map(language => ({
        key: language.id, // Assuming `id` is unique for each language
        name: language.name, // Accessing the name of the language
    }));

    // Function to get language name by ID
    const getLanguageNameById = (id) => {
        const language = languagesData.find(lang => lang.id === parseInt(id));
        return language ? language.name : '';
    };


    useEffect(() => {
        dispatch(getLanguages());
    }, []);
    const TopicSchema = Yup.object().shape({
        title: Yup.string().required('Topic title is required'),
        description: Yup.string().required('Topic description is required'),
        duration: Yup.string().required('Topic duration is required')
    });

    const ChapterSchema = Yup.object().shape({
        name: Yup.string().required('Chapter name is required'),
        duration: Yup.string().required('Chapter duration is required'),
        description: Yup.string().required('Chapter description is required'),
        topics: Yup.array().of(TopicSchema).min(1, 'At least one topic is required')
    });
    const SubjectValidationSchema = Yup.object().shape({
        category: Yup.string().required('Category is required'),
        sub_category: Yup.string().required('Sub Category is required'),
        subject: Yup.string().required('Subject is required'),
        batch_id: Yup.string().required('Batch is required'),
        level: Yup.string().required('Level is required'),
        price: Yup.string().required('Price is required'),
        language: Yup.string().required('Language is required'),
        video_link: Yup.string().required('Video link is required'),
        description: Yup.string().max(150, 'Description must be at most 150 characters').required('Description is required'),
        discount: Yup.string().required('Discount amount is required'),
        payment: Yup.string().required('Payment is required'),
        duration: Yup.string().required('day and duration  is required'),
        image: Yup.string().required('Image is required'),
        dates: Yup.string().required('dates is required'),
        period: Yup.string().required('Period is required'),
        discount_per: Yup.string().required('Discount is required'),
        name: Yup.string().required('Name is required'),
        chapters: Yup.array().of(ChapterSchema).min(1, 'At least one chapter is required')
    });

    //to store the data in state variable to use in api

    const [subcat, setSubcat] = useState({
        name: '',  // Initialize name with an empty string
        category_id: ''
    });
    const handleSave = () => {
        const payload = {
            "type": 2,
            "category_id": stateObject.category,
        };
        console.log("Submitting data:", subcat);

        // Check if the "name" field is empty before making the API call
        if (!subcat.name) {
            console.error("Name field is empty");
            return;
        }

        const formData = new FormData();
        formData.append('name', subcat.name);
        formData.append('category_id', stateObject.category);

        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
        Axios.post('/api/addSubCategory', formData, { headers: headers })
            .then((res) => {
                console.log("API response:", res.data);
                if (res.data.success) {
                    // If the API call is successful, show success message
                    showSuccessToast("Subcategory Added Successfully");
                    dispatch(getSubCategoryData(payload));



                } else {
                    // If the API call fails, check if the name is already taken
                    if (res.data.error && res.data.error.name && res.data.error.name.length > 0) {
                        showErrorToast(res.data.error.name[0]); // Show the error message returned by the server
                    } else {
                        showErrorToast("Failed to add subcategory");
                    }
                }
            })
            .catch((error) => {
                console.error('Error adding subcategory:', error);
                showErrorToast("An error occurred while adding subcategory");
            });
    };

    const [topictea, setTopictea] = useState({
        name: '',  // Initialize name with an empty string
        sub_category_id: ''
    });
    const handleSaveTopic = () => {
        console.log("Submitting data:", subcat);

        // Check if the "name" field is empty before making the API call
        if (!topictea.name) {
            console.error("Name field is empty");
            return;
        }

        const formData = new FormData();
        formData.append('name', topictea.name);
        formData.append('sub_category_id', stateObject.sub_category);

        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
        Axios.post('/api/addtopic', formData, { headers: headers })
            .then((res) => {
                console.log("API response:", res.data);
                if (res.data.success) {
                    // If the API call is successful, show success message
                    showSuccessToast("Topic Added Successfully");
                    const payload = {
                        "type": 3,
                        "sub_category_id": stateObject.sub_category,
                    };
                    dispatch(getSubTopicData(payload));

                } else {
                    // If the API call fails, check if the name is already taken
                    if (res.data.error && res.data.error.name && res.data.error.name.length > 0) {
                        showErrorToast(res.data.error.name[0]); // Show the error message returned by the server
                    } else {
                        showErrorToast("Failed to add Topic");
                    }
                }
            })
            .catch((error) => {
                console.error('Error adding subcategory:', error);
                showErrorToast("An error occurred while adding subcategory");
            });
    };



    const [days, setDays] = useState({
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
    });
    const [stateObject, setStateObject] = useState({
        category: "",
        sub_category: "",
        name: '',
        subject: "",
        level: "",
        price: "",
        language: "",
        discount_per: "",
        video_link: "",
        description: "",
        discount_amount: "",
        payment: "",
        currency: "",
        image: "",
        period: '',
        duration: "",
        chapter: [{
            name: "",
            description: "",
            duration: "",
            topics: [
                {
                    title: "",
                    description: "",
                    duration: ""
                }
            ]
        }]

        // Logic to provide chapter data through the callback function


    });




    const baseUrl = Axios;
    const handleInputImage = (e) => {
        const { name, type, files } = e.target;
        if (type === "file" && files.length > 0) {
            const selectedFile = files[0];
            setStateObject({
                ...stateObject,
                [name]: selectedFile,
            });
        } else {
            setStateObject({
                ...stateObject,
                [name]: e.target.value,
            });
        }
        setErrors({ ...errors, [name]: " " });
    };



    const handleDayChange = (day, checked) => {
        setDays(prevDays => ({
            ...prevDays,
            [day]: checked ? 1 : 0
        }));
    };



    const levelOptions = ["1", "2", "3"];
    const labels = ["Beginner", " Intermediate", "Expert"];
    const paymentOptions = ["Monthly", "OneTime"];

    const mapBackendValueToFrontendLabel = (backendValue) => {
        const mapping = {
            1: "OneTime",
            2: "Monthly",
            // Add more mappings as needed
        };

        return mapping[backendValue] || "";
    };


    const currencyOptions = "INR";
    //    const handleChange = (e, chapterIndex, topicIndex, fieldName) => {
    //     const { name, value } = e.target;
    //     setFieldValue(`chapters[${chapterIndex}].topics[${topicIndex}].${fieldName}`, value);
    // };


    const candidateCreateSub = () => {

        const studentdetails = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : {};

        let formData = new FormData();
        formData.append(
            "email",
            _.get(studentdetails, "email") || _.get(studentdetails, "data.email")
        );
        formData.append("category", stateObject.category);
        formData.append("sub_category", stateObject.sub_category);
        formData.append("subject", stateObject.subject);
        formData.append("level", stateObject.level);
        formData.append("language", getLanguageNameById(stateObject.language));
        formData.append("price", stateObject.price);
        formData.append("payment", stateObject.payment);
        formData.append("period", stateObject.period);
        formData.append("duration", stateObject.duration);
        formData.append("currency", currencyOptions);
        formData.append("discount", stateObject.discount);
        formData.append("description", stateObject.description);
        formData.append("video_link", stateObject.video_link);
        formData.append("teacher_id", stateObject.teacher_id);
        formData.append("image", stateObject.image);
        formData.append("name", stateObject.name);
        formData.append("dates[monday]", days.monday);
        formData.append("dates[tuesday]", days.tuesday);
        formData.append("dates[wednesday]", days.wednesday);
        formData.append("dates[thursday]", days.thursday);
        formData.append("dates[friday]", days.friday);
        formData.append("dates[saturday]", days.saturday);
        formData.append("dates[sunday]", days.sunday);

        for (let i = 0; i < chapters.length; i++) {
            const chapter = chapters[i];
            formData.append(`chapter[${i}][name]`, chapter.name);
            formData.append(`chapter[${i}][duration]`, chapter.duration);
            formData.append(`chapter[${i}][description]`, chapter.description);

            for (let j = 0; j < chapter.topics.length; j++) {
                const topic = chapter.topics[j];

                if (topic.title || topic.description || topic.duration) {
                    formData.append(`chapter[${i}][topics][${j}][title]`, topic.title);
                    formData.append(`chapter[${i}][topics][${j}][description]`, topic.description);
                    formData.append(`chapter[${i}][topics][${j}][duration]`, topic.duration);
                }
            }
        }

        const headers = {
            Authorization: "Bearer " + localStorage.getItem("token"),
        };

        Axios.post('/api/createSubject', formData, { headers: headers })
            .then((res) => {
                if (res?.data?.success) {
                    let user = _.get(res, "data.data.user")
                        ? JSON.stringify(res.data.data.user)
                        : undefined;
                    let token = _.get(res, "data.data.token")
                        ? JSON.stringify(res.data.data.token)
                        : undefined;
                    if (user) {
                        localStorage.setItem("user", JSON.stringify(res.data.data.user));
                    }
                    if (token) {
                        localStorage.setItem("token", res.data.data.token);
                    }
                    showSuccessToast("Subject created successfully");
                    // Move to backdashboard
                    Backdashboard();
                }
            })
            .catch((error) => {
                console.error("There was an error creating the subject!", error);
            });

        console.log("formData", formData);
    };




    const handleInput = (e) => {

        const { name, value } = e.target;

        if (name.startsWith('dates.monday')) {
            // Update the nested 'dates.monday' object
            // Parse and format time input values
            const formattedValue = value.replace(/(\d{2})(\d{2})/, '$1:$2:00'); // Append ":00" for seconds

            // Update the nested object dynamically
            setStateObject(prevState => ({
                ...prevState,
                dates: {
                    ...prevState.dates,
                    [name.split('.')[1]]: {
                        ...prevState.dates[name.split('.')[1]],
                        [name.split('.').pop()]: formattedValue,
                    },
                },
            }));
        }
        else if (name.startsWith('dates.tuesday')) {
            // Update the nested 'dates.tuesday' object
            setStateObject({
                ...stateObject,
                dates: {
                    ...stateObject.dates,
                    tuesday: {
                        ...stateObject.dates.tuesday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }
        else if (name.startsWith('dates.wednesday')) {
            // Update the nested 'dates.wednesday' object
            setStateObject({
                ...stateObject,
                dates: {
                    ...stateObject.dates,
                    wednesday: {
                        ...stateObject.dates.wednesday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }
        else if (name.startsWith('dates.thursday')) {
            // Update the nested 'dates.thursday' object
            setStateObject({
                ...stateObject,
                dates: {
                    ...stateObject.dates,
                    thursday: {
                        ...stateObject.dates.thursday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }
        else if (name.startsWith('dates.friday')) {
            // Update the nested 'dates.friday' object
            setStateObject({
                ...stateObject,
                dates: {
                    ...stateObject.dates,
                    friday: {
                        ...stateObject.dates.friday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }
        else if (name.startsWith('dates.saturday')) {
            // Update the nested 'dates.saturday' object
            setStateObject({
                ...stateObject,
                dates: {
                    ...stateObject.dates,
                    saturday: {
                        ...stateObject.dates.saturday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }

        else if (name.startsWith('dates.sunday')) {
            // Update the nested 'dates.sunday' object
            setStateObject({
                ...stateObject,
                dates: {
                    ...stateObject.dates,
                    sunday: {
                        ...stateObject.dates.sunday,
                        [name.split('.').pop()]: value,// Update the specific field
                    },
                },
            });
        }


        else {

            // Update other fields in Quotation
            setStateObject({ ...stateObject, [name]: value });
        }
    };
    const candidateUpdateSub = () => {
        const studentdetails = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : {};
        let formData = new FormData();
        formData.append(
            "email",
            _.get(studentdetails, "email") || _.get(studentdetails, "data.email")
        );
        formData.append("category", stateObject.category);
        formData.append("sub_category", stateObject.sub_category);
        formData.append("subject", stateObject.subject);
        formData.append("level", stateObject.level);
        formData.append("language", stateObject.language);
        formData.append("price", stateObject.price);
        formData.append("payment", stateObject.payment);
        formData.append("currency", stateObject.currency);
        formData.append("discount", stateObject.discount);
        formData.append("description", stateObject.description);
        formData.append("video_link", stateObject.video_link);
        formData.append("teacher_id", stateObject.teacher_id);
        formData.append("image", stateObject.image);
        formData.append("subject_id", stateObject.id);
        formData.append("name", stateObject.name);
        formData.append("period", stateObject.period);
        formData.append("duration", stateObject.duration);

        const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        let isValid = true;

        for (const day of days) {
            const isChecked = stateObject.dates[day] && stateObject.dates[day].isChecked;

            if (isChecked) {
                const from = stateObject.dates[day].from;
                const to = stateObject.dates[day].to;

                if (!from || !to) {
                    alert(`Please fill ${day} timing`);
                    isValid = false;
                    break;
                }

                formData.append(`dates[${day}][from]`, from);
                formData.append(`dates[${day}][to]`, to);
            }
        }

        if (!isValid) {
            return;
        }
        const headers = {
            Authorization: "Bearer " + (localStorage.getItem("token")),
        };
        Axios
            .post(
                '/api/editSubject',
                formData,
                { headers: headers }
            )
            .then((response) => {
                const fetchedData = response.data;
                setStateObject(fetchedData);
                Backdashboard();
                setChapters(response.data);
                // Move the schedule validation here
                if (isScheduleValid()) {
                    // Your existing code for submitting the form
                    candidateCreateSub();
                } else {
                    // Display an error message or handle the validation failure
                    console.log("Please select at least one day and time for each day.");
                }
                showSuccessToast("Subject Edit created successfully");
            })
            .catch((error) => {
                console.error("Error fetching editsubjects:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }

    }, [subject_id]);

    const initializeObject = () => {
        setStateObject({ ...stateObject, ...props.userState.row });
    };


    const isScheduleValid = () => {
        const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

        for (const day of days) {
            const from = _.get(stateObject, `dates.${day}.from`, '');
            const to = _.get(stateObject, `dates.${day}.to`, '');

            if (!from || !to) {
                // If either 'from' or 'to' is not selected for any day, return false
                return false;
            }
        }

        // If all days have at least one day and time selected, return true
        return true;
    };

    const dispatch = useDispatch();
    const data = useSelector(CSTData);
    const subCatData = useSelector(SubData);
    const TopicData = useSelector(topicData);

    useEffect(() => {
        const payload = { type: 1 };
        dispatch(getCSTData(payload)).then((response) => {
            if (response.success) {
                const options = response.data.map(category => ({
                    value: category.id,
                    label: category.name
                }));
                setCategoryOptions(options);
            }
        });
    }, [dispatch]);

   

    //  categoryOptions = data?.map((e) => ({
    //     value: e.id,
    //     label: e.name
    // }));
    const [categoryOptions, setCategoryOptions] = useState([]);

    const SubCategoryOptionsList = subCatData?.map((e) => ({
        value: e.id,
        label: e.name
    }));

    const TopicOptionsList = TopicData?.map((e) => ({
        value: e.id,
        label: e.name
    }));



    const periodValue = stateObject.payment === "1" ? "Months" : "Days";



    const calculateModuleDuration = (topics) => {
        return topics.reduce((total, topic) => total + (parseFloat(topic.duration) || 0), 0);
    }





    const teachercategory = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('api/listTeacherCategory', {}, { headers: headers })
            .then((res) => {
                if (res.data.success) {
                    // Extract category IDs from the response and update the state
                    const categories = res.data.data.map(item => ({
                        value: item.category_id,
                        label:  item.name
                    }));
                    setCategoryOptions(categories);
                } else {
                    // showErrorToast("Account details Added Failed")
                }
            })
            .catch((error) => {
                console.error('Error fetching Coupons:', error);
            });
    };

    useEffect(() => {
        teachercategory();
    }, []);
    const getCategoryNameById = (id) => {
        const category = categoryData.find(cat => cat.id === id);
        console.log("category", category);
        return category ? category.name : 'Unknown Category';

    };

    const Modulenmae = (value) => {
        if (!value || !value.name) {
            return "Please select a Qualification document.";
        }
        return undefined; // No validation error
    };
    return (
        <div className="sidebar_content">
            <div className="main-divHead">
                <div className="sub-Head1" onClick={Backdashboard}>
                    {" "}
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <div className="Sub-Head2">Course</div>
                </div>
                <div className="teacher_edit_subject">
                    <div>
                        {!isEditSubject &&
                            <div className='container mt-3'>
                                <div className="row">
                                    <div className='col-12'>
                                        {!_.get(props, 'userState.row') && <div className='main-schedule-head ms-5' ><p className='Title-main-head'>Create Courses</p></div>}
                                        {_.get(props, 'userState.row') && <div className='main-schedule-head ml-2'><p className='Title-main-head'>Edit Courses</p></div>}
                                    </div>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={SubjectValidationSchema}
                                    onSubmit={
                                        candidateCreateSub

                                    }
                                // onClick={candidateCreateSub}
                                >

                                    {({ errors, touched, setFieldValue, handleSubmit, isSubmitting, values, setValues,
                                    }) => (
                                        <Form >
                                            <div class="row">
                                                <div className='col-xxl-4 col-xl-5 col-lg-8 col-md-10 col-sm-10 subtitle-headings'>
                                                    {!_.get(props, 'userState.row') && (
                                                        
                                                        <>
                                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Choose Category</label> <span className="star_req">*</span>
                                                            
                                                            <Field
                                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.category}
                                                                name="category"
                                                                as="select"
                                                                onChange={(e) => {
                                                                    const selectedValue = e.target.value;
                                                                    const payload = {
                                                                        type: 2,
                                                                        category_id: selectedValue,
                                                                    };
                                                                    dispatch(getSubCategoryData(payload));

                                                                    setFieldValue("category", selectedValue);
                                                                    handleInput(e);
                                                                }}
                                                            >
                                                                <option value="">Select an option</option>
                                                                {categoryOptions.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}

                                                                <ErrorMessage
                                                                    name="category"
                                                                    component="div"
                                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                                />
                                                            </Field>


                                                        </>
                                                    )}
                                                    {_.get(props, 'userState.row') && (
                                                        <>
                                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Choose Category</label>
                                                            <Field
                                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.category}
                                                                name="category"
                                                                as="select"
                                                                onChange={(e) => {
                                                                    const selectedValue = e.target.value;
                                                                    const payload = {
                                                                        "type": 2,
                                                                        "category_id": selectedValue,
                                                                    };
                                                                    dispatch(getSubCategoryData(payload));

                                                                    setFieldValue("category", e.target.value)
                                                                    handleInput(e);
                                                                }}
                                                                disabled>
                                                                <option value="" >Select an option</option>
                                                                {categoryOptions?.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage
                                                                name="category"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />
                                                        </>
                                                    )}
                                                    <div className='col-12 subtitle-headings ms-5'>
                                                        {!_.get(props, 'userState.row') &&
                                                            <label className="form-label mt-4  mb-0 teacher-font-label">Sub Category <span className="star_req">*</span></label>} <br />
                                                        {!_.get(props, 'userState.row') &&

                                                            <div className="d-flex align-item-center position-relative">
                                                                <Field
                                                                    className="form-select teacher-font-field d-flex"
                                                                    value={stateObject.sub_category}
                                                                    name="sub_category"
                                                                    as="select"
                                                                    onChange={(e) => {
                                                                        const selectedValue = e.target.value;
                                                                        const payload = {
                                                                            "type": 3,
                                                                            "sub_category_id": selectedValue,
                                                                        };
                                                                        dispatch(getSubTopicData(payload));
                                                                        setFieldValue("sub_category", e.target.value);
                                                                        handleInput(e);
                                                                    }}
                                                                >
                                                                    <option>select an option</option>
                                                                    {SubCategoryOptionsList ? SubCategoryOptionsList.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    )) : null}



                                                                </Field>

                                                                <button className="btn btn-primary position-absolute top-50 end-0 translate-middle-y" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                                    <FaPlus />
                                                                </button>

                                                                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                    <div class="modal-dialog">
                                                                        <div class="modal-content">

                                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                                                            <div class="modal-body">

                                                                                <div class="mb-3">
                                                                                    <label for="nameInput" class="form-label">Enter your new subcategory</label>
                                                                                    <input type="text" class="form-control" id="nameInput" placeholder="Enter name" name="name"
                                                                                        onChange={(e) => setSubcat({ ...subcat, name: e.target.value })
                                                                                        } />
                                                                                </div>

                                                                            </div>
                                                                            <div class="modal-footer">

                                                                                <button type="button" class="btn btn-primary" onClick={handleSave} data-bs-dismiss="modal">Save</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                        }
                                                        <ErrorMessage
                                                            name="sub_category"
                                                            component="div"
                                                            className="text-danger teacher-font-field "
                                                        />
                                                        {_.get(props, 'userState.row') && <label className="form-label mt-4  mb-0 teacher-font-label">Sub Category / class</label>} <br />
                                                        {_.get(props, 'userState.row') &&
                                                            <>
                                                                <div className="d-flex align-item-center">
                                                                    <Field
                                                                        className="form-select teacher-font-field d-flex"
                                                                        value={stateObject.sub_category}
                                                                        name="sub_category"
                                                                        as="select"
                                                                        disabled
                                                                        onChange={(e) => {
                                                                            const selectedValue = e.target.value;
                                                                            const payload = {
                                                                                "type": 3,
                                                                                "sub_category_id": selectedValue,
                                                                            };
                                                                            dispatch(getSubTopicData(payload));
                                                                            handleInput(e);
                                                                        }}>
                                                                        <option>select an option</option>
                                                                        {SubCategoryOptionsList.map((option) => (
                                                                            <option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </Field>
                                                                    &nbsp; <FaPlus className=" mt-2" />
                                                                </div>
                                                            </>
                                                        }
                                                        {_.get(props, 'userState.row') && <ErrorMessage
                                                            name="sub_category"
                                                            component="div"
                                                            className="text-danger teacher-font-field "
                                                        />}
                                                    </div>
                                                    <div className='col-12 col-sm-12 subtitle-headings ms-5'>
                                                        {!_.get(props, 'userState.row') && <label className="form-label mt-4  mb-0 teacher-font-label">Topic <span className="star_req">*</span> </label>} <br />
                                                        {!_.get(props, 'userState.row') &&
                                                            <>
                                                                <div className="d-flex align-item-center position-relative">
                                                                    <Field
                                                                        className="form-select  teacher-font-field"
                                                                        value={stateObject.subject}
                                                                        name="subject"
                                                                        as="select"
                                                                        onChange={(e) => {
                                                                            setFieldValue("subject", e.target.value)
                                                                            handleInput(e)
                                                                        }}>
                                                                        <option>select an option</option>
                                                                        {TopicOptionsList.map((option) => (
                                                                            <option key={option.vale} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </Field>
                                                                    <button className="btn btn-primary position-absolute top-50 end-0 translate-middle-y" data-bs-toggle="modal" data-bs-target="#staticBack">
                                                                        <FaPlus />
                                                                    </button>
                                                                    <div class="modal fade" id="staticBack" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                        <div class="modal-dialog">
                                                                            <div class="modal-content">

                                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                                                                <div class="modal-body">

                                                                                    <div class="mb-3">
                                                                                        <label for="nameInput" class="form-label">Enter your new Topic</label>
                                                                                        <input type="text" class="form-control" id="nameInput" placeholder="Enter name" name="name"
                                                                                            onChange={(e) => setTopictea({ ...topictea, name: e.target.value })
                                                                                            } />
                                                                                    </div>

                                                                                </div>
                                                                                <div class="modal-footer">

                                                                                    <button type="button" class="btn btn-primary" onClick={handleSaveTopic} data-bs-dismiss="modal">Save</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        {!_.get(props, 'userState.row') && <ErrorMessage
                                                            name="subject"
                                                            component="div"
                                                            className="text-danger  teacher-font-field "
                                                        />}
                                                        {_.get(props, 'userState.row') && <label className="form-label mt-4 mb-0 teacher-font-label">Subject / Topic</label>} <br />
                                                        {_.get(props, 'userState.row') &&
                                                            <div className="d-flex align-item-center">
                                                                <Field
                                                                    className="form-select teacher-font-field"
                                                                    value={stateObject.subject}
                                                                    name="subject"
                                                                    as="select"
                                                                    onChange={(e) => {
                                                                        setFieldValue("subject", e.target.value)
                                                                        handleInput(e)
                                                                    }}
                                                                    disabled >
                                                                    <option>select an option</option>
                                                                    {TopicOptionsList.map((option) => (
                                                                        <option key={option.vale} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                &nbsp; <FaPlus className=" mt-2" />
                                                            </div>
                                                        }
                                                        {_.get(props, 'userState.row') && <ErrorMessage
                                                            name="subject"
                                                            component="div"
                                                            className="text-danger teacher-font-field "
                                                        />}
                                                    </div>
                                                    <div class="row">
                                                        <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 subtitle-headings'>
                                                            {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Course Name <span className="star_req">*</span></label>}  <br />
                                                            {!_.get(props, 'userState.row') && <Field
                                                                type="text"
                                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label"
                                                                value={stateObject.name}
                                                                name="name"
                                                                onChange={(e) => {
                                                                    setFieldValue("name", e.target.value)
                                                                    handleInput(e)
                                                                }}
                                                            />}
                                                            {!_.get(props, 'userState.row') && <ErrorMessage
                                                                name="name"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                            {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Course Name</label>}  <br />
                                                            {_.get(props, 'userState.row') && <Field
                                                                type="text"
                                                                className="form-select ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.name}
                                                                name="name"
                                                                onChange={(e) => {
                                                                    setFieldValue("name", e.target.value)
                                                                    handleInput(e)
                                                                }}
                                                            />}
                                                            {_.get(props, 'userState.row') && <ErrorMessage
                                                                name="name"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                        </div>



                                                    </div>



                                                    <div class="row">
                                                        <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 subtitle-headings'>
                                                            {!_.get(props, 'userState.row') && <label className="form-label  mt-4  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Price <span className="star_req">*</span></label>} <br />
                                                            {!_.get(props, 'userState.row') && <Field
                                                                type="number"
                                                                step="0.01" // Allow decimal values
                                                                inputMode="decimal" // Tell mobile browsers to use decimal input mode
                                                                className="form-control ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.price}
                                                                name="price"
                                                                onChange={(e) => {
                                                                    setFieldValue("price", e.target.value)
                                                                    handleInput(e)
                                                                }}
                                                            />}
                                                            {!_.get(props, 'userState.row') && <ErrorMessage
                                                                name="price"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                            {_.get(props, 'userState.row') && <label className="form-label  mt-4  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Price</label>} <br />
                                                            {_.get(props, 'userState.row') && <Field
                                                                type="number"
                                                                className="form-control ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.price || "0.00"}
                                                                name="price"
                                                                onChange={(e) => {
                                                                    setFieldValue("price", e.target.value)
                                                                    handleInput(e)
                                                                }}
                                                            />}
                                                            {_.get(props, 'userState.row') && <ErrorMessage
                                                                name="price"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                        </div>
                                                        <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 subtitle-headings'>
                                                            {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label"> <span className="star_req">*</span>Currency </label>}
                                                            {!_.get(props, 'userState.row') &&
                                                                <Field
                                                                    type="text"
                                                                    className="form-select ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                    value="INR"
                                                                    name="currency"

                                                                >

                                                                </Field>}
                                                            {!_.get(props, 'userState.row') && <ErrorMessage
                                                                name="currency"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                            {_.get(props, 'userState.row') && <label className="form-label mt-5 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Currency </label>}
                                                            {_.get(props, 'userState.row') && <Field
                                                                className="form-select  ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                // value={stateObject.currency}
                                                                name="currency"
                                                                value="INR"
                                                            >
                                                                {/* <option value="">Select an option</option>
                                                                {
                                                                    Options.map((currency, index) => (
                                                                    <option key={index} value={currency}>
                                                                        {currency}
                                                                    </option>
                                                                ))} */}
                                                            </Field>}
                                                            {_.get(props, 'userState.row') && <ErrorMessage
                                                                name="currency"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                        </div>




                                                    </div>
                                                    <div class="row">
                                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6  subtitle-headings'>
                                                            {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Levels  <span className="star_req">*</span></label>}<br />
                                                            {!_.get(props, 'userState.row') && <Field
                                                                className="form-select ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.level}
                                                                name="level"
                                                                as="select"
                                                                onChange={(e) => {
                                                                    setFieldValue("level", e.target.value)
                                                                    handleInput(e)
                                                                }}>
                                                                <option value="">Select an option</option>
                                                                {levelOptions.map((level, index) => (
                                                                    <option key={index} value={level}>
                                                                        {labels[index]}
                                                                    </option>
                                                                ))}
                                                            </Field>}

                                                            {!_.get(props, 'userState.row') && <ErrorMessage
                                                                name="level"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                            {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Levels</label>}  <br />
                                                            {_.get(props, 'userState.row') && <Field
                                                                className="form-select ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.level}
                                                                name="level"
                                                                as="select"
                                                                onChange={(e) => {
                                                                    setFieldValue("select", e.target.value)
                                                                    handleInput(e)
                                                                }}
                                                            >
                                                                <option value="">Select an option</option>
                                                                {levelOptions.map((level, index) => (
                                                                    <option key={index} value={level}>
                                                                        {labels[index]}
                                                                    </option>
                                                                ))}
                                                            </Field>}
                                                            {/* {_.get(props, 'userState.row') && <ErrorMessage
                                                                name="level"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />} */}
                                                        </div>
                                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 subtitle-headings'>
                                                            {!_.get(props, 'userState.row') && <label className="form-label mt-4  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label"> Language <span className="star_req">*</span></label>} <br />
                                                            {!_.get(props, 'userState.row') && <Field
                                                                className="form-select  ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.language}
                                                                name="language"
                                                                as="select"
                                                                onChange={(e) => {
                                                                    setFieldValue("language", e.target.value)
                                                                    handleInput(e)
                                                                }} >
                                                                <option value="">Select an option</option>
                                                                {Array.isArray(languageList) &&
                                                                    languageList.map(({ key, name }) => (
                                                                        <option key={key} value={key}>
                                                                            {name}
                                                                        </option>
                                                                    ))}
                                                            </Field>}
                                                            {!_.get(props, 'userState.row') && <ErrorMessage
                                                                name="language"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                            {_.get(props, 'userState.row') && <label className="form-label mt-4  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Language</label>} <br />
                                                            {_.get(props, 'userState.row') && <Field
                                                                className="form-select  ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.language}
                                                                name="language"
                                                                as="select"
                                                                onChange={(e) => {
                                                                    setFieldValue("language", e.target.value)
                                                                    handleInput(e)
                                                                }}>
                                                                <option value="">Select an option</option>
                                                                {Array.isArray(languageList) &&
                                                                    languageList.map(({ key, name }) => (
                                                                        <option key={key} value={key}>
                                                                            {name}
                                                                        </option>
                                                                    ))}
                                                            </Field>}
                                                            {_.get(props, 'userState.row') && <ErrorMessage
                                                                name="language"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col">
                                                            <div className='col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 subtitle-headings'>
                                                                {!_.get(props, 'userState.row') && <label className="form-label  mt-4  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label"> <span className="star_req">*</span>Payment</label>} <br />
                                                                {!_.get(props, 'userState.row') && <Field
                                                                    className="form-select ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                    value={stateObject.payment}
                                                                    name="payment"
                                                                    as="select"
                                                                    onChange={(e) => {
                                                                        setFieldValue("payment", e.target.value)
                                                                        handleInput(e)
                                                                    }}  >
                                                                    <option value="">Select an option</option>
                                                                    {paymentOptions.map((payment, index) => (
                                                                        <option key={index} value={index + 1}>
                                                                            {mapBackendValueToFrontendLabel(index + 1)}
                                                                        </option>
                                                                    ))}
                                                                </Field>}
                                                                {!_.get(props, 'userState.row') && <ErrorMessage
                                                                    name="payment"
                                                                    component="div"
                                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                                />}
                                                                {_.get(props, 'userState.row') && <label className="form-label  mt-4  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Payment </label>} <br />
                                                                {_.get(props, 'userState.row') && <Field
                                                                    className="form-select ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                    value={stateObject.payment}
                                                                    name="payment"
                                                                    as="select"
                                                                    onChange={(e) => {
                                                                        setFieldValue("payment", e.target.value)
                                                                        handleInput(e)
                                                                    }}  >
                                                                    <option value="">Select an option</option>
                                                                    {paymentOptions.map((payment, index) => (
                                                                        <option key={index} value={index + 1}>
                                                                            {mapBackendValueToFrontendLabel(index + 1)}
                                                                        </option>
                                                                    ))}
                                                                </Field>}
                                                                {_.get(props, 'userState.row') && <ErrorMessage
                                                                    name="payment"
                                                                    component="div"
                                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                                />}
                                                            </div>
                                                        </div>
                                                        <div className='col-xxl-4
                                                         col-xl-4 col-lg-4 col-md-4 col-sm-4 subtitle-headings'>
                                                            {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Period</label>} <br />
                                                            {!_.get(props, 'userState.row') && <Field
                                                                type="text"
                                                                className="form-control ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-4 ms-sm-4  mb-0 teacher-font-label"
                                                                value={stateObject.period}
                                                                name="period"
                                                                onChange={(e) => {
                                                                    setFieldValue("period", e.target.value)
                                                                    handleInput(e)
                                                                }}
                                                                {...(stateObject.payment === "1" ?
                                                                    { placeholder: "In Months" } :
                                                                    { placeholder: "In Days" })
                                                                }
                                                            />}
                                                            {!_.get(props, 'userState.row') && <ErrorMessage
                                                                name="period"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                            {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Period</label>} <br />
                                                            {_.get(props, 'userState.row') && <Field
                                                                type="text"
                                                                className="form-control ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                value={stateObject.period}
                                                                name="period"
                                                                onChange={(e) => {
                                                                    setFieldValue("period", e.target.value)
                                                                    handleInput(e)
                                                                }}
                                                                {...(stateObject.payment === "1" ?
                                                                    { placeholder: "In Months" } :
                                                                    { placeholder: "In Days" })
                                                                }
                                                            />}
                                                            {_.get(props, 'userState.row') && <ErrorMessage
                                                                name="period"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                        </div>
                                                        <div className='col-xxl-3
                                                         col-xl-3 col-lg-3 col-md-3 col-sm-3 subtitle-headings'>
                                                            {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label"></label>} <br />
                                                            {!_.get(props, 'userState.row') && <Field
                                                                type="text"
                                                                className="form-control ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 mt-3 mb-0 teacher-font-label"
                                                                value={periodValue}
                                                                name="period"


                                                            />}
                                                            {!_.get(props, 'userState.row') && <ErrorMessage
                                                                name="period"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                            {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label"></label>} <br />
                                                            {_.get(props, 'userState.row') && <Field
                                                                type="text"
                                                                className="form-control ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field mt-3"
                                                                value={periodValue}
                                                                name="period"
                                                            // onChange={(e) => {
                                                            //     setFieldValue("period", e.target.value)
                                                            //     handleInput(e)
                                                            // }}
                                                            />}

                                                            {_.get(props, 'userState.row') && <ErrorMessage
                                                                name="period"
                                                                component="div"
                                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                            />}
                                                        </div>

                                                    </div>



                                                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 subtitle-headings '>
                                                        {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Attach promotional video Link</label>}{" "}
                                                        {!_.get(props, 'userState.row') && <Field
                                                            type="text"
                                                            className="form-control ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                            name="video_link"
                                                            value={stateObject.video_link}
                                                            onChange={(e) => {
                                                                setFieldValue("video_link", e.target.value)
                                                                handleInput(e)
                                                            }}

                                                        />}

                                                        {!_.get(props, 'userState.row') && <ErrorMessage
                                                            name="video_link"
                                                            component="div"
                                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                        />}

                                                        {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Attach promotional video Link</label>}{" "}
                                                        {_.get(props, 'userState.row') && <Field
                                                            type="text"
                                                            className="form-control ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                            name="video_link"
                                                            value={stateObject.video_link}
                                                            onChange={(e) => {
                                                                setFieldValue("video_link", e.target.value)
                                                                handleInput(e)
                                                            }}
                                                        />}

                                                        {_.get(props, 'userState.row') && <ErrorMessage
                                                            name="video_link"
                                                            component="div"
                                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                        />}

                                                    </div>

                                                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 subtitle-headings'>
                                                        {!_.get(props, 'userState.row') && <label className="form-label mt-4 mt-4  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Description <span className="star_req">*</span></label>}  <br />
                                                        {!_.get(props, 'userState.row') && <Field name="description">
                                                            {({ field }) => (
                                                                <div>
                                                                    <textarea
                                                                        {...field}
                                                                        className="form-control ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                        id="floatingTextarea"
                                                                        value={stateObject.description}
                                                                        onChange={(e) => {
                                                                            const description = e.target.value.slice(0, 150); // Limiting to 150 characters
                                                                            setFieldValue("description", description);
                                                                            handleInput(e);
                                                                        }}
                                                                        maxLength={150} // Adding maxLength attribute
                                                                        style={{ height: '100px', cursor: 'auto' }} // Added cursor: 'auto' to override any custom cursor
                                                                    />
                                                                </div>
                                                            )}
                                                        </Field>

                                                        }
                                                        <div className="d-flex justify-content-between">
                                                            {!_.get(props, 'userState.row') && (
                                                                <div>
                                                                    <ErrorMessage
                                                                        name="description"
                                                                        component="div"
                                                                        className="text-danger ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                                    />
                                                                </div>
                                                            )}
                                                            <div><p>{stateObject.description.length}/ {150 - stateObject.description.length}</p></div>
                                                        </div>
                                                        {_.get(props, 'userState.row') && <label className="form-label mt-4 mt-4  ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Description <span className="star_req">*</span></label>} <br />
                                                        {_.get(props, 'userState.row') && <Field
                                                            as="textarea"
                                                            className="form-control ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                            name="description"
                                                            id="floatingTextarea"
                                                            value={stateObject.description}
                                                            onChange={(e) => {
                                                                const description = e.target.value.slice(0, 150); // Limiting to 150 characters
                                                                setFieldValue("description", description);
                                                                handleInput(e);
                                                            }}
                                                            maxLength={150} // Adding maxLength attribute
                                                            style={{ height: '100px', cursor: 'auto' }} // Added cursor: 'auto' to override any custom cursor
                                                        />}
                                                        {_.get(props, 'userState.row') &&
                                                            <div className="error-container">
                                                                <ErrorMessage
                                                                    name="description"
                                                                    component="div"
                                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                                />

                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                                <div className='col-xxl-6 col-xl-6 col-lg-10 col-md-10 col-sm-12 col-12 ms-xxl-5 ms-xl-4 ms-lg-3 ms-md-3 ms-sm-0 mt-5 '>
                                                    {!_.get(props, 'userState.row') && <div className="admin-card admin-dotted-border mt text-center ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 mb-0">
                                                        {stateObject.image && (
                                                            <img
                                                                src={stateObject.image instanceof File ? URL.createObjectURL(stateObject.image) : stateObject.image}
                                                                className="img-fluid rounded-top img_up"
                                                                alt="Uploaded"
                                                            />
                                                        )}
                                                        <p className="fs-6 mt-2" id="fileLabel">Upload course image <span className="star_req">*</span></p>
                                                        <label htmlFor="fileInput" className="btn btn-primary px-4 py-1 bootBtn">
                                                            Browse File
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="fileInput"
                                                            name="image"
                                                            style={{ display: 'none' }}
                                                            onChange={(event) => {
                                                                const selectedFile = event.currentTarget.files[0];
                                                                const fileName = selectedFile ? selectedFile.name : "No file chosen";
                                                                document.getElementById('fileLabel').textContent = fileName;
                                                                setFieldValue('image', selectedFile);
                                                                handleInputImage(event);
                                                            }}
                                                        />
                                                        <ErrorMessage name="image" component="div" className="text-danger" />
                                                    </div>}
                                                    {_.get(props, 'userState.row') && (
                                                        <div className="admin-card admin-dotted-border mt text-center ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 mb-0">
                                                            {stateObject.image && (
                                                                <img
                                                                    src={stateObject.image instanceof File ? URL.createObjectURL(stateObject.image) : `${baseUrl}${stateObject.image}`}
                                                                    className="img-fluid rounded-top img_up"
                                                                    alt="Uploaded"
                                                                />
                                                            )}
                                                            <p className="fs-6 mt-2" id="fileLabel">Upload course image <span className="star_req">*</span> </p>
                                                            <label htmlFor="fileInput" className="btn btn-primary px-4 py-1 bootBtn">
                                                                Browse File
                                                            </label>
                                                            <input
                                                                type="file"
                                                                id="fileInput"
                                                                name="image"
                                                                style={{ display: 'none' }}
                                                                onChange={(event) => {
                                                                    const selectedFile = event.currentTarget.files[0];
                                                                    const fileName = selectedFile ? selectedFile.name : "No file chosen";
                                                                    document.getElementById('fileLabel').textContent = fileName;
                                                                    setFieldValue('image', selectedFile);
                                                                    handleInputImage(event);
                                                                }}
                                                            />
                                                            <ErrorMessage name="image" component="div" className="text-danger" />
                                                        </div>
                                                    )}
                                                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 ms-xxl-5 ms-xl-4 ms-lg-3 ms-md-3 ms-sm-0 mt-5 teacher-time-box container">
                                                        <div className='row main_row mt-5'>
                                                            <div className='col'>
                                                                <div className='row text-center'>
                                                                    <div className='col'>
                                                                        {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-4 teacher-font-label"><strong>Scheduling Days</strong> <span className="star_req">*</span></label>}
                                                                        {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-4 teacher-font-label"> Edit Scheduling Days <span className="star_req">*</span></label>}
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    {!_.get(props, 'userState.row') && (
                                                                        <>
                                                                            <div className='col'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    onChange={(e) => handleDayChange('monday', e.target.checked)}
                                                                                />
                                                                            </div>
                                                                            <div className='col'>
                                                                                <p className='dates_sched'>Monday</p>
                                                                            </div>

                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className='row'>
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={(e) => handleDayChange('tuesday', e.target.checked)}
                                                                        />
                                                                    </div>}
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <p className='dates_sched'>Tuesday</p>
                                                                    </div>}
                                                                </div>
                                                                <div className='row'>
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={(e) => handleDayChange('wednesday', e.target.checked)}
                                                                        />
                                                                    </div>}
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <p className='dates_sched'>Wednesday</p>
                                                                    </div>}
                                                                </div>
                                                                <div className='row'>
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={(e) => handleDayChange('thursday', e.target.checked)}
                                                                        />
                                                                    </div>}
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <p className='dates_sched'>Thursday</p>
                                                                    </div>}

                                                                </div>
                                                                <div className='row'>
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={(e) => handleDayChange('friday', e.target.checked)}
                                                                        />
                                                                    </div>}
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <p className='dates_sched'>Friday</p>
                                                                    </div>}


                                                                </div>
                                                                <div className='row'>
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={(e) => handleDayChange('saturday', e.target.checked)}
                                                                        />
                                                                    </div>}
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <p className='dates_sched'>Saturday</p>
                                                                    </div>}


                                                                </div>
                                                                <div className='row'>
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={(e) => handleDayChange('sunday', e.target.checked)}
                                                                        />
                                                                    </div>}
                                                                    {!_.get(props, 'userState.row') && <div className='col'>
                                                                        <p className='dates_sched'>Sunday</p>
                                                                    </div>}


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className='col-xxl-6 col-xl-7 col-lg-6 col-md-4 col-sm-4 subtitle-headings '>
                                                                {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-7 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label col-12">Duration in each day:</label>} <br />
                                                                {!_.get(props, 'userState.row') && <Field
                                                                    type="text"
                                                                    className="form-control ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label"
                                                                    value={stateObject.duration}
                                                                    name="duration"
                                                                    onChange={(e) => {
                                                                        setFieldValue("duration", e.target.value)
                                                                        handleInput(e)
                                                                    }}
                                                                />}
                                                                {!_.get(props, 'userState.row') && <ErrorMessage
                                                                    name="duration"
                                                                    component="div"
                                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                                />}
                                                                {_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label">Duration</label>} <br />
                                                                {_.get(props, 'userState.row') && <Field
                                                                    type="text"
                                                                    className="form-control ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field"
                                                                    value={stateObject.duration}
                                                                    name="duration"
                                                                    onChange={(e) => {
                                                                        setFieldValue("duration", e.target.value)
                                                                        handleInput(e)
                                                                    }}
                                                                />}
                                                                {_.get(props, 'userState.row') && <ErrorMessage
                                                                    name="duration"
                                                                    component="div"
                                                                    className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                                />}
                                                            </div>
                                                            <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 subtitle-headings mt-3'>
                                                                {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-7 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label"></label>} <br />
                                                                {!_.get(props, 'userState.row') && <Field
                                                                    type="text"
                                                                    className="form-control ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5  mb-0 teacher-font-label"
                                                                    value="Hours"
                                                                    name="Hour"

                                                                />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="dates" component="div" className="text-danger" />
                                                    <div>
                                                        <h5 className="mt-5 ms-5">Course Syllabus</h5>
                                                        <div style={{ backgroundColor: "white" }}>
                                                            {chapters.map((chapter, chapterIndex) => (
                                                                <div key={chapterIndex}>
                                                                    <p className="teacher-font-label d-flex justify-content-center mt-3">Module</p>
                                                                    <input
                                                                        className="form-control teacher-font-field mt-3"
                                                                        type="text"
                                                                        value={chapter.name}
                                                                        onChange={(e) => handleChange(e, chapterIndex, 'name')}
                                                                        placeholder="Module Name"
                                                                        validate={Modulenmae}
                                                                    />
                                                                  {errors.qualification && <p>{errors.qualification.message}</p>}

                                                                    {chapter.topics.length === 0 ? (
                                                                        <input
                                                                            className="form-control teacher-font-field mt-2"
                                                                            type="text"
                                                                            value={chapter.duration}
                                                                            onChange={(e) => handleChange(e, chapterIndex, 'duration')}
                                                                            placeholder="Module Duration"
                                                                        />
                                                                    ) : (
                                                                        <p className="form-control teacher-font-field mt-2">
                                                                            {`Module Duration: ${calculateModuleDuration(chapter.topics)} hours`}
                                                                        </p>
                                                                    )}
                                                                    <input
                                                                        className="form-control teacher-font-field mt-2 mb-3"
                                                                        type="text"
                                                                        value={chapter.description}
                                                                        onChange={(e) => handleChange(e, chapterIndex, 'description')}
                                                                        placeholder="Module Description"
                                                                    />
                                                                    <ErrorMessage
                                                                        name="chapter"
                                                                        component="div"
                                                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                                                    />
                                                                    
                                                                    <div className="d-flex justify-content-center">
                                                                        <div className="border border-4 col-10 d-flex justify-content-center">
                                                                            <div>
                                                                                {chapter.topics.map((topic, topicIndex) => (
                                                                                    <div key={topicIndex}>
                                                                                        <div className="d-flex justify-content-center mt-3">
                                                                                            <input
                                                                                                className="col-12 ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field mt-2 px-2 py-1 d-flex justify-content-center"
                                                                                                type="text"
                                                                                                value={topic.title}
                                                                                                onChange={(e) => handleTopicChange(e, chapterIndex, topicIndex, 'title')}
                                                                                                placeholder="Chapter Title"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="d-flex justify-content-center">
                                                                                            <input
                                                                                                className="col-12 ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field mt-2 px-2 py-1"
                                                                                                type="text"
                                                                                                value={topic.duration}
                                                                                                onChange={(e) => handleTopicChange(e, chapterIndex, topicIndex, 'duration')}
                                                                                                placeholder="Chapter Duration"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="d-flex justify-content-center">
                                                                                            <input
                                                                                                className="col-12 ms-lg-5 ms-md-5 ms-sm-5 teacher-font-field mt-2 px-2 py-1"
                                                                                                type="text"
                                                                                                value={topic.description}
                                                                                                onChange={(e) => handleTopicChange(e, chapterIndex, topicIndex, 'description')}
                                                                                                placeholder="Chapter Description"
                                                                                            />

                                                                                        </div>
                                                                                        <div className="d-flex justify-content-center">
                                                                                            <button onClick={() => deleteTopic(chapterIndex, topicIndex)} className="btn ms-5"><MdDelete /></button>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                               
                                                                            </div>
                                                                           
                                                                        </div>
                                                                    </div>
                                                                  
                                                                    <div className="d-flex justify-content-end">
                                                                       
                                                                        <button onClick={addChapter} className="btn ms-2 btn-success"><MdAdd /></button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>








                                                </div>

                                            </div>
                                            <div class="row px-5">
                                                <div clas="col-12 d-flex justify-content-center">
                                                    {!_.get(props, 'userState.row') && (
                                                        <button
                                                            type="button" // Use type="button" to prevent form submission
                                                            className="btn text-light px-4 py-1 bootBtn my-5"
                                                            onClick={async (e) => {
                                                                e.preventDefault(); // Prevent default form submission behavior
                                                                // alert("hello"); // Optional: Display an alert
                                                                try {
                                                                    await candidateCreateSub(); // Wait for the API call to finish
                                                                    // If you need to do something after the API call is successful, you can add it here
                                                                } catch (error) {
                                                                    console.error(error); // Log any errors that occur during the API call
                                                                    // You can handle errors here, such as displaying an error message to the user
                                                                }
                                                            }}
                                                        >
                                                            Submit
                                                        </button>
                                                    )}

                                                    {_.get(props, 'userState.row') && (<button
                                                        type="submit"
                                                        className="btn text-light px-4 py-1 bootBtn my-5"
                                                        onClick={(e) => candidateUpdateSub()} >
                                                        Update
                                                    </button>)}
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}

export default TeacherAddSubject;
