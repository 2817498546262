
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSTData, SubData, addTopic, getCSTData, getSubCategoryData, viewCategoryData, viewCategoryDetails } from '../../../../../../redux/slice/SubjectSlice';
import { Formik,Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { AiOutlineLeft } from 'react-icons/ai';
import { subjectLoading } from '../../../../../../redux/slice/SubjectSlice';
import { RotatingLines } from 'react-loader-spinner'
import { FaPlus } from 'react-icons/fa';
function AddTopicComponent(props) {

    const [selectedItem, setSelectedItem] = useState(props?.userState?.categoryName?.subCategory || '')
    const [rowSelected, setRowSelected] = useState(props?.userState?.rowSelected);
    const currentCategoryName = props.userState.categoryName?.category;
    const categoryData = useSelector(viewCategoryData);

    // const currentCategoryId = props.userState.categoryName?.category?.id;
    const categoryId = parseInt(currentCategoryName.id);
    const dispatch = useDispatch();
    const data = useSelector(CSTData);
    const subData = useSelector(SubData);
    const loading = useSelector(subjectLoading);
    const validationSchema = Yup.object({
        // adminCategory: Yup.string().required('category is required'),
        // subCategory: Yup.string().required('sub category / class is required'),
        // subjectTopic: Yup.string().required('subject / Topic is required'),
    });

    const goToSubCategoryDetailsPage = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewsubcategory', rowSelected: rowSelected }) // passing this to useSate so sending in riwSelected
        }
    }
    const goToCategoryDetailsPage = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'CategoryDetailsPage', });
        }
        const payload = {
            type: 2
        };
        dispatch(getCSTData(payload));

    };

    const goToSubjectTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'AdminSubject' })
        }
    }

    // const handleSubmit = (values, { setSubmitting, resetForm }) => {
    //     const payload = {
    //         "name": values.subjectTopic,
    //         "sub_category_id": selectedItem.id
    //     };    
    //    resetForm();      
    //     dispatch(addTopic(payload))
    //         .then(() => {
    //             setSubmitting(false);
    //             setTimeout(() => {
    //                 dispatch(viewCategoryDetails({ ...payload, category_id: selectedItem.category_id }));
    //                 goToSubCategoryDetailsPage();
    //             }, 2000);
    //         })
    //         .catch(error => {
    //             // Handle error from addTopic action
    //             console.error("Add topic failed:", error);
    //             // Optionally, you can inform the user about the failure
    //             // For example:
    //             // alert("Failed to add topic. Please try again.");
    //             setSubmitting(false);
    //             // Stay on the current page
    //         });
    // };

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        const valueToSend = {
            name: values.subjectTopic,
            sub_category_id: rowSelected.id
        }
        dispatch(addTopic(valueToSend))
        const payload = {
            type: 1
        };
        setTimeout(() => {
            dispatch(viewCategoryDetails({ ...payload, category_id: rowSelected.category_id }));
            goToSubCategoryDetailsPage()
            // resetForm();
            // goToAddSubCategory()  
            // dispatch(getCSTData(payload));
        }, 2000);
    };

    // const handleSave = () => {
    //     const payload = {
    //         type: 2
    //     };
    //     dispatch(viewCategoryDetails({ ...payload, category_id: rowSelected.category_id }));
    //     goToSubCategoryDetailsPage();

    //     // goToSubjectTeacher();
    // }
    return (
        <div>
            <div className="container m-xxl-5 m-xl-5 m-lg-5 m-md-5 ">
                <div className="row">
                    <div className='d-flex justify-content-center'>
                        <div className="col-xxl-8 col-xl-8 col-lg-10">
                            <div className='main-divHead m-xxl-5 m-xl-5 m-lg-5 m-md-5 m-0'>
                                <div className='sub-Head1'>
                                    <div className="Sub-Head2" onClick={(e) => goToCategoryDetailsPage(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>Topic</div>
                                </div>
                                <div className='p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-4'>
                                    <h4 className='fw-bold'>Add Topic</h4>
                                    <Formik initialValues={{ category: categoryId || '', subCategory: selectedItem.id || '', subjectTopic: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}>
                                        <Form>
                                            <div className="container ">
                                                <div className="row">
                                                    <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3'>
                                                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                                            <div className="form-group">
                                                                <label className='admin-label mt-4' htmlFor="adminCategory">Category</label>
                                                                <Field
                                                                    type="text"
                                                                    name="category"
                                                                    value={currentCategoryName?.name} // Set the value to the categoryName                                                             
                                                                    className="form-control admin-input"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3'>
                                                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                                            <div className="form-group">
                                                                <label className='admin-label' htmlFor="subCategory">Sub Category / Class</label>
                                                                <Field
                                                                    type="text"
                                                                    name="subCategory"
                                                                    value={rowSelected.name} // Set the value to the categoryName                                                             
                                                                    className="form-control admin-input"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3 ms-4 '>
                                                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                                            <label className='admin-label'>Subject / Topic</label>
                                                            <Field type="text" name="subjectTopic" className="form-control admin-input" />
                                                            <ErrorMessage name="subjectTopic" component="div" className="text-danger fs-6" />
                                                        </div>
                                                        <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 ms-2'  >
                                                            <button className='plus-button p-3 mt-4' type="submit"><FaPlus className='ms-1' /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container text-center">
                                                <div className="row">
                                                    <div className="col">
                                                        <button
                                                            // onClick={handleSave}
                                                            type="submit"
                                                            className="btn btn-primary text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                                                            disabled={loading}>
                                                            {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                                                                strokeColor="white"
                                                                strokeWidth="5"
                                                                animationDuration="0.75"
                                                                width="25"
                                                                height="25"
                                                                visible={true}
                                                            /></div> : "Save"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTopicComponent;
