import React, { useState } from "react"
import subjectsWhiteIcon from "../../../icons/subjectsWhiteIcon.png"
import subjectsBlueIcon from "../../../icons/subjectsBlueIcon.png"
import _ from 'lodash';

function Courses(props) {
  const [hoverEffect, setHoverEffect] = useState(false)

  const goToCoursrUpl = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'instructorcours' })
    }
  }

  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'instructorcours' ? 'active' : ''} `}

    >
      <img
        src={_.get(props, 'userState.subType') != 'instructorcours' ? subjectsWhiteIcon : subjectsBlueIcon}
        alt='Courses'
      />
      <p className='side_heading' onClick={(e) => goToCoursrUpl(e)}>Course Upload</p>
    </div>
  )
}

export default Courses
