import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react'
import DataTable from 'react-data-table-component';
import { BsFillEyeFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { viewTeacherData } from '../../../../../../redux/slice/teacherSlice';
import { format } from 'date-fns';
import { AiOutlineLeft } from 'react-icons/ai';

function AdminRecordVideoComponent(props) {

    const teacherData = useSelector(viewTeacherData);

    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };


    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.created_at,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.created_at ? dateFunction(row.created_at) : ""}
                    </div>
                );
            }
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true
        },
        {
            name: 'Sub Category',
            selector: row => row.sub_category,
            sortable: true
        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.status === "1" ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}
                    </div>
                )
            }
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <button className="btn outline-btn-admin px-xxl-3 px-xl-3 px-lg-1 fw-bold" onClick={(e) => goToVideoView()}>View<span ><BsFillEyeFill class='adminEyeIcon ms-xxl-3 ms-xl-3 ms-lg-1' /></span></button>
                </div>
            )
        }
    ];


    const goToVideoView = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminVideoView' })
        }
    }

    const goToAdminTeacherDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminTeacherDetails' })
        }
    }

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >
                <div className="Sub-Head2" onClick={(e) => goToAdminTeacherDetails(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>Teacher</div>
            </div>
            <div>
                <div class="container text-start">
                    <div className='row'>
                        <div class="d-flex justify-content-center"><div className='col-xxl-8 col-xl-8 col-lg-11'>
                            <p class='admin-sub-head my-5 '>Recorded Videos</p>
                        </div></div>
                    </div>
                    <div class="row mt-3">
                        <div class='d-flex justify-content-center'>
                            <div class='col-xxl-8 col-xl-8 col-lg-11'>
                                <DataTable
                                    className='dataTable mt-4 '
                                    data={teacherData?.videouploads}
                                    columns={columns}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    striped
                                    customStyles={customStyles}
                                    persistTableHead
                                    pagination
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminRecordVideoComponent