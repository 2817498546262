import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { enrolledCourses } from "../../../../../../../redux/displayer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import profileimg2 from "../../../../../../../images/landing_page_images/profileimg2.png"
import { RotatingLines } from 'react-loader-spinner';
import bookimg from "../../../../../../../images/landing_page_images/bookimg.png";
import _ from 'lodash';
import Studentchatside from "./Subjects";
import StarRatings from 'react-star-ratings';
import { ActiveSubject } from "../../../../../../../redux/slice/studentSlice";
import EnrolledSubject from "./EnrolledSubject";
import RatingsComponent from "./Rating";
import './Course1.css'
import { IoIosArrowBack } from "react-icons/io";
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";
function Courses({ ViewCoursesDetail, showOrderParent, setshowOrderParent, ...props }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const basePath = "http://tuitionguruji.com/backend/public/";
  const [showOrder, setshowOrder] = useState(false);
  useEffect(() => {
    setshowOrder(showOrderParent);
  }, [showOrderParent, ViewCoursesDetail])
  const ShowClass = (item) => {
    // navigate("/enrolldsubject")
    dispatch(ActiveSubject(item.subject_id))
    setshowOrder(item);
    setshowOrderParent(item);
    handleOpen(item)
  }




  const getUserState = () => {
    return { ...props.userState, ...showOrder }
  }

  const [loader, setLoader] = useState(true);
  const [ListCourses, setListCourses] = useState([{
    id: "",
    subject_id: "",
    amount: "",
    code: "",
    code_details: "",
    offer_amount: "",
    course_amount: "",
    purchase_id: "",
    description: "",
    paid_status: "",
    user_id: "",
    created_at: "",
    updated_at: ""
  }])
  const Backdashboard = () => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Dashboard' })
    }
  }
  // function for list Subject after purchase
  const StudentViewSubjects = () => {
    if (!localStorage.getItem('token')) {
      Backdashboard();
    }

    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
    Axios
      .post('api/enrolledsubjects',
        undefined, { headers: headers }
      )
      .then((response) => {
        if (_.get(response, 'data.data')) {

          setListCourses(response.data.data);
          setLoading(false);

        }
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error);
        setLoading(false);
      });
  }
  useEffect(() => {
    StudentViewSubjects();
    // Simulate loading delay with a setTimeout
    setTimeout(() => {
      setLoader(false);
      ; // Set Loader to false when data is loaded
    }, 500); // 2 seconds delay
  }, []);
  const handleOpen = (item) => {
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
    const formData = new FormData();
    formData.append('subject_id', item.subject_id);
    Axios
      .post(
        '/api/viewSubject',
        formData, { headers: headers }
      )
      .then((response) => {
        if (_.get(response, 'data.data')) {

          setListCourses(response.data.data);
          setLoading(false);

        }
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error);
        setLoading(false);
      });
  }
  const StuBackdashboard = () => {
    navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
  }
  return (
   <>
      <div className="main-divHead">
        <div
          className="profile-main-head d-flex py-2"
          onClick={() => StuBackdashboard()}
        >
          <div className="fa-chaveron">
            <IoIosArrowBack className="mb-1" />{" "}
          </div>
          <div className="profile-head">My Courses</div>
        </div>
      </div>
       <div>
      {!showOrder && <div onClick={() => dispatch(enrolledCourses())}>
        <form class=" d-flex row gy-3 gx-3 align-items-center ms-10">
          <div class="ms-auto p-4" style={{ width: "330px" }}>
            <div class="input-group d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-flex">
              <input type="text" class="form-control-search " id="autoSizingInputGroup" placeholder="Search" />
              <div class="input-group-text student-inp-grp-bg">
                <FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: "white", }} /></div>
            </div>
          </div>
        </form>
        {loading ? (<div className='d-flex justify-content-center'><RotatingLines
          strokeColor="white"
          strokeWidth="5"
          animationDuration="0.75"
          width="25"
          height="25"
          visible={true}
        />
        </div>
        ) : ListCourses.length > 0 ? (
          <div class="container all-featured-test ">
            <div class="row">
              {ListCourses?.map(item => (
                <div class="col-6 col-xxl-4 col-xl-4 col-lg-4 col mb-4">
                  <div class="card p-2 card-course">
                    <div class="book">
                      <img class="rounded img-fluid"
                        src={item.image ? (basePath + item.image) : ''}
                        // src={bookimg}
                        alt="API Image"
                        style={{ width: '300px', height: '250px' }} />
                      <div class="english-profile d-flex">
                        <div>
                          <div class="head-english">
                            <p className="student-sub-card-head mb-0 fw-bold mt-1">{item.name}</p>
                            {/* {item.subject_id} */}
                          </div>
                          <div class="english-rs">
                            {/* 130  */}
                            {/* {item.description} */}
                          </div>
                        </div>
                        {/* <div className="ms-xxl-0 ms-xl-0 ms-lg-0 ms-md-0 ms-2">
                          <p className="font-rating"> rating</p>
                          <StarRatings
                            className="sub-rating-star"
                            starRatedColor={"#F2DE25"}
                            starDimension={"10px"}
                            rating={2.403}
                            starHoverColor='black'






                  
                            starSpacing="1px"
                          />
                        </div> */}
                        {/* <p class="rate-number pt-2">(2.1)</p> */}
                        {/* <div class="ms-auto p-xxl-2 p-xl-2 p-lg-2 p-md-0">
                          <img class="profile-img img-fluid" src={profileimg2} />
                        </div> */}
                      </div>
                      <div class="progress my-1" role="progressbar" aria-label="Success example" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} style={{ height: "5px" }}>
                        <div class="progress-bar-student" style={{ width: '100%' }}></div>
                      </div>
                      {/* <div class="percent">45% complete</div> */}
                      <div class="my-2 open-button d-flex">
                        <button class="subject-open" onClick={() => ShowClass(item)} >Open</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div class="col-12 text-center">
            <p>No subject purchased</p>
          </div>
        )}
      </div>}
     
      {/*  view subject deatils  after purchase */}
      {showOrder && <div> <EnrolledSubject showOrder={showOrder} >
      </EnrolledSubject></div>}
      </div>
      </>
  )
}

export default Courses
