import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";
import Calendar from "react-calendar";
import moment from "moment";
import imgArrow from "../../../../../../../images/Arrow 2.png";
import imgArrow1 from "../../../../../../../images/Arrow 2 (1).png";
import imgArrow2 from "../../../../../../../images/Arrow 2 (2).png";
import bookimg from "../../../../../../../images/landing_page_images/bookimg.png";
import LoadingSpinner from "../../../../../../Reactloader/LoadingSpinner";
import { BiSolidMessageSquare } from "react-icons/bi";
import img from "../../../../../../../images/Circle 2.png";
import img1 from "../../../../../../../images/Circle 2 (1).png";
import img2 from "../../../../../../../images/Circle 2 (2).png";
import { FaCalendar, FaClock } from "react-icons/fa";
import DataTable from "react-data-table-component";
import axios from "axios";
import { BsArrowRight } from "react-icons/bs";
import profileimg2 from "../../../../../../../images/landing_page_images/profileimg2.png";
import Studentchatside from "./Subjects";
import { useDispatch } from "react-redux";
import { ActiveSubject } from "../../../../../../../redux/slice/studentSlice";
import { fetchSubjectList } from "../../../../../../../redux/slice/SubjectSlice";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";


const Dashboard = ({ ...props }) => {
  // Add a media query state to track the screen width
  const [mediaQuery, setMediaQuery] = useState(window.innerWidth);
  // Add a function to update the mediaQuery state
  const updateMediaQuery = () => {
    setMediaQuery(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMediaQuery);

    // Check for the presence of the token in local storage
    if (!localStorage.getItem("token")) {
      // If token is not present, redirect to login or handle as needed
      Backdashboard();
    } else {
      // Token is present, fetch dashboard data
      fetchDashboard();
    }

    return () => {
      window.removeEventListener("resize", updateMediaQuery);
    };
  }, []);

  // Define breakpoints for responsiveness
  const isMobile = mediaQuery < 768; // Example breakpoint for mobile

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    className: "center",
    centerMode: true,
    centerPadding: "0px",
  };
  const [loader, setLoader] = useState(true);
  const [value, onChange] = useState(new Date());
  const [Notifi, setNotifi] = useState([{}]);
  const formatDate = (dateStr) => {
    if (!dateStr) return ''; // Handle case when dateStr is not provided or invalid
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    const formattedDate = new Date(dateStr).toLocaleDateString('en-US', options);
    return formattedDate;
  };
  const [currentCourse, setCurrentCourse] = useState(null);
  const Backdashboard = () => {
    if (_.get(props, "setUserState")) {
      props.setUserState({ ...props.userState, subType: "Teachersubject" });
    }
  };
  const [ViewCourses, setViewCourses] = useState([
    {
      id: "",
      category: "",
      sub_category: "",
      subject: "",
      level: "",
      language: "",
      video_link: "",
      price: "",
      payment: "",
      currency: "",
      discount: "",
      descriptio: "",
      image: "",
      teacher_id: "",
      uploader_id: null,
      period: ""
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [Offeredcourses, setOfferedcourses] = useState([{}]);
  const [OffcoursesDe, setOffcoursesDe] = useState([{}]);
  const [Upcomingclass, setUpcomingclass] = useState([{}]);
  const basePath = "http://tuitionguruji.com/backend/public/";
  const fetchDashboard = () => {
    if (!localStorage.getItem("token")) {
      Backdashboard();
    }
    const headers = {
      Authorization: "Bearer " + (localStorage.getItem("token")),
    };
    Axios
      .post(
        '/api/student/dashboard',
        undefined,
        { headers: headers }
      )
      .then((response) => {
        if (_.get(response, "data.data")) {
          setViewCourses(response.data.data.courses);
          setNotifi(response.data.data.notifications);
          setOfferedcourses(response.data.data.offeredCourses);
          setOffcoursesDe(response.data.data.myCourseDetails);
          setUpcomingclass(response.data.data.upcomingClasses);

          // Format the created_at property of items in the notifications array
          const formattedNotifications = response.data.data.notifications.map(
            (item) => ({
              ...item,
              created_at: moment(item.created_at).format("DD-MM-YYYY"),
            })
          );

          const formattedData = response.data.data.myCourseDetails.map((item) => ({
            ...item,
            created_at: moment(item.created_at).format("DD-MM-YYYY"),
          }));
          setOffcoursesDe(formattedData);
          console.log(formattedData)
          setNotifi(formattedNotifications);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error);
        setLoading(false);
      });
  };
  const customStyles = {
    headRow: {
      style: {
        color: "#202224",
        backgroundColor: "#ffffff",
        fontSize: "13px",
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#ffffff",
      },
    },
  };

  // Define the columns configuration for the DataTable
  const columns = [
    {
      name: "Course Name",
      selector: (row) => row.name,
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
      format: (row) => formatDate(row.created_at),
    },
    // {
    //   name: "Timing",
    //   selector: (row) => row.timing,
    //   sortable: true,
    // },
    {
      name: "Amount",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          className={`details-table ps-3 pe-3 pt-1 pb-1 rounded-pill ${row.status === 1 ? 'active-btn' : 'inactive-btn'}`}
          style={{ backgroundColor: row.status === "1" ? '#00B69B' : '#f54b42' }}
        >
          {row.status === "1" ? "Active" : "Inactive"}
        </button>
      ),
    },
  ];
  useEffect(() => {
    fetchDashboard();
    // Simulate loading delay with a setTimeout
    setTimeout(() => {
      setLoader(false); // Set Loader to false when data is loaded
    }, 500); // 2 seconds delay
  }, []);
  
  const goToNotification = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Notification' })
    }
  }
  const [showView, setShowView] = useState(false);
  const dispatch = useDispatch();
  const liveClass = (item) => {
    console.log({ item });
    dispatch(ActiveSubject(item.id))
    setShowView(item);
  };

const navigate=useNavigate()
 
  return (
    <div className="sidebar_content">
      {loader ? (
        <LoadingSpinner />
      ) : (
        <div className="dashbord-container">
          <div className="main-dashboard">
            <p className="student-dashboard-head">Student Dashboard</p>
          </div>
          <div class="container text-start">
            <div class="row ">
              {ViewCourses.length > 0 ? (

                ViewCourses.map((course) => (
                  <div class="col-3" key={course.id}>
                    <div className="admin-card p-3 admin-card-course" style={{ backgroundColor: course.backgroundColor }}>
                      <div class="container text-start">
                        <div class="row">
                          <div class="col">
                            <p className="student-card-tittle">{course?.name ? course.name : "No Name Available"}</p>
                          </div>
                          <div class="col text-end">
                            <img
                              src={img}
                              class="img-fluid rounded-top"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col">
                            <div
                              class="progress"
                              role="progressbar"
                              aria-label="Example 1px high"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ height: "10px" }}
                            >
                              <div
                                class="progress-bar"
                                style={{ width: "50%", background: "#8280ff" }}
                              ></div>
                            </div>
                            <p class="text-end student-percentage-txt mb-0"></p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col text-end">
                            <img
                              src={imgArrow}
                              class="img-fluid rounded-top"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) :
                (
                  <>
                    <div class="col-3">
                      <div className="admin-card p-3 ">
                        <div class="container text-start">
                          <div class="row">
                            <div class="col">
                              <p className="student-card-tittle">Maths</p>
                            </div>
                            <div class="col text-end">
                              <img
                                src={img1}
                                class="img-fluid rounded-top"
                                alt="..."
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <div
                                class="progress"
                                role="progressbar"
                                aria-label="Example 1px high"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ height: "10px" }}
                              >
                                <div
                                  class="progress-bar"
                                  style={{ width: "50%", background: "#fec53d" }}
                                ></div>
                              </div>
                              <p class="text-end student-percentage-txt mb-0">50%</p>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col text-end">
                              <img
                                src={imgArrow2}
                                class="img-fluid rounded-top"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div className="admin-card p-3 ">
                        <div class="container text-start">
                          <div class="row">
                            <div class="col">
                              <p className="student-card-tittle">Computer</p>
                            </div>
                            <div class="col text-end">
                              <img
                                src={img2}
                                class="img-fluid rounded-top"
                                alt="..."
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <div
                                class="progress"
                                role="progressbar"
                                aria-label="Example 1px high"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ height: "10px" }}
                              >
                                <div
                                  class="progress-bar"
                                  style={{ width: "50%", background: "#4ad991" }}
                                ></div>
                              </div>
                              <p class="text-end student-percentage-txt mb-0">50%</p>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col text-end">
                              <img
                                src={imgArrow1}
                                class="img-fluid rounded-top"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div className="admin-card p-3 ">
                        <div class="container text-start">
                          <div class="row">
                            <div class="col">
                              <p className="student-card-tittle">Chemistry</p>
                            </div>
                            <div class="col text-end">
                              <img
                                src={img}
                                class="img-fluid rounded-top"
                                alt="..."
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <div
                                class="progress"
                                role="progressbar"
                                aria-label="Example 1px high"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ height: "10px" }}
                              >
                                <div
                                  class="progress-bar"
                                  style={{ width: "50%", background: "#8280ff" }}
                                ></div>
                              </div>
                              <p class="text-end student-percentage-txt mb-0">50%</p>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col text-end">
                              <img
                                src={imgArrow}
                                class="img-fluid rounded-top"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>

                )}




              <div class="col-3">
                <div className="admin-card p-3 ">
                  <div class="container text-start">
                    <div class="row">
                      <div class="col">
                        <p className="fw-semibold">Notifications</p>
                      </div>
                      <div class="col text-end" onClick={(e) => goToNotification(e)}>
                        <p class="mb-0">
                          See all{" "}
                          <span>
                            <BsArrowRight className="ms-2" />
                          </span>
                        </p>
                      </div>
                      <hr></hr>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p className="fw-bold mb-0">{Notifi?.category}</p>
                        <p className="mb-0">
                          <span>
                            <FaCalendar className="mb-1 student-card-icon-color me-2" />
                          </span>
                          Saturday, 28th January
                        </p>
                        {/* <p className="mb-0">
                          <span>
                            <FaClock className="mb-1 student-card-icon-color me-2" />
                          </span>
                          6:00 -7:00 PM
                        </p> */}
                        <p className="mb-0">
                          <span>
                            <BiSolidMessageSquare className="mb-1 student-card-icon-color me-2" />
                          </span>
                          {Notifi?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col">
                <div className="card">
                  <div class="row ">
                    <div class="col-6">
                      <p className="fw-semibold offered_head">Offered courses</p>
                    </div>
                    <div class="col-6 text-end">
                      <p class="mb-0"  onClick={() => navigate("/ShowSubjects")}>
                        See all{" "}
                        <span>
                          <BsArrowRight className="ms-2" />
                        </span>
                      </p>
                    </div>
                  </div>

                  {Offeredcourses.length > 0 ? (
                    <div className="mx-auto dash-card d-flex">
                      <div class="container text-center">
                        <div className="row">
                          <div className="col-12">
                            <div id="carouselExampleDark" className="carousel carousel-dark slide">
                              <div className="carousel-inner">
                                {Offeredcourses.map((card, index) => (
                                  <div key={card.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                    <div className="d-sm-block d-md-block d-lg-flex justify-content-around">
                                      {Offeredcourses.slice(index, index + 3).map((card) => (
                                        <div className="admin-card p-2 card-item-min-w">
                                          <div class="row">
                                            <div class="col">
                                              <img
                                                className="dash-img p-2"
                                                //  src={item.image ? (basePath + item.image) : ''}
                                                src={card.image ? (basePath + card.image) : bookimg}
                                                alt="API Image"
                                                style={{ width: '300px', height: '200px' }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row text-start p-2">
                                            <div className="col">
                                              <p className="fw--bold dash-offered-course"> {card.name}  </p>
                                            </div>
                                            {/* <div className="col text-end">
                                              <img
                                                className="profile-img img-fluid me-2"
                                                src={profileimg2}
                                              />
                                            </div> */}
                                          </div>
                                          <div className="row text-start">
                                            <div className="col-8">
                                              <p className="ms-2 dash-price" ><span className="dash-live">class in {card.language}</span></p>
                                            </div>
                                            <div className="col-6">
                                              <p className="  dash-price1">
                                                {card.price}RS
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col text-start">
                                              {/* <p className="ms-2 dash-actual-price" >ratings</p> */}
                                            </div>
                                            <div className="col text-end">
                                              <p className="me-3 dash-actual-price"></p>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col text-start ms-2">
                                              {/* <button className="view-course  p-2 fw-semibold" onClick={() =>  liveClass(card.id)}>
                                                View Course
                                              </button> */}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className="text-center offered_head1">
                        No Available Offered courses
                      </p>
                    </div>
                  )}
                  {/* Move these buttons outside of the card */}
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev" >
                    <span className="carousel-control-prev-icon" aria-hidden="true" style={{ color: 'black' }}></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>

                </div>
              </div>
              <div class="col-3">
                <div className="admin-card">
                  <Calendar
                    className="calender-teacher"
                    onChange={onChange}
                    value={value}
                  />
                </div>
              </div>
            </div>
            <div class="row mt-5  ">
              <div class="col table-view-student p-0">
                <h6 class="fw-bold mt-5 dashboard-course mb-2">My Course Details</h6>
                <div className="admin-card">
                  <DataTable
                    columns={columns}
                    data={OffcoursesDe}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="600px"
                    striped
                    customStyles={customStyles}
                    persistTableHead
                    pagination
                  />
                </div>
              </div>
              <div class="col-3">
                <div className="admin-card p-3 ">
                  <div class="container text-start">
                    <div class="row">
                      <div class="col">
                        <p className="fw-semibold text-nowrap">
                          Upcoming Class
                        </p>
                      </div>
                      <div class="col text-end">
                        <p class="mb-0">
                          See all{" "}
                          <span>
                            <BsArrowRight className="ms-2" />
                          </span>
                        </p>
                      </div>
                    </div>
                    {Upcomingclass.map(item => (
                      <div class="row">
                        <div class="col">
                          <p className="fw-bold mb-0">  {item?.description}</p>
                          <p className="mb-0">
                            <span>
                              <FaCalendar className="mb-1 student-card-icon-color me-2" />
                            </span>
                            {item?.day}
                          </p>
                          <p className="mb-0">
                            <span>
                              <FaClock className="mb-1 student-card-icon-color me-2" />
                            </span>
                            {item?.from} -{item?.to}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}







      {currentCourse && (
        <div>
          {" "}
          <Studentchatside
            Backdashboard={Backdashboard}
          ></Studentchatside>{" "}
        </div>

      )}
    </div>
  );
};

export default Dashboard;
