import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import _ from 'lodash';

function AdminTeacherWalletDetail(props) {
  const goToAdminWallet = () => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'adminWallet' });
    }
  };

  return (
    <>
      <div className='main-divHead'>
        <div className='sub-Head1'>
          <FontAwesomeIcon icon={faChevronLeft} />
          <div className='Sub-Head2' onClick={(e) => goToAdminWallet()}>Teacher Credit Details</div>
        </div>

        <div className='d-flex justify-content-center'>
                    <div class="card border-0 shadow mt-5 col-11 ">
                        <div class="card-body">
                            <div class="container text-start">
                                <div class="row ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Teacher Name:</p>
                                        </div>
                                        <div class="col-5">
                                            <p class='admin-sub-head-card'></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row  ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Total Sale:</p>
                                        </div>
                                        <div class="col-5">
                                            <p class='admin-sub-head-card'></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row  ms-3 ">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Debit : </p>
                                        </div>
                                        <div class="col-5">
                                            <p class='admin-sub-head-card'></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Credit : </p>
                                        </div>
                                        <div class="col-5">
                                            <p class='admin-sub-head-card'></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Initial Amount : </p>
                                        </div>
                                        <div class="col-5">
                                            <span class=''> <p class='admin-sub-head-card'></p></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Last Month Sale Report :  </p>
                                        </div>
                                        <div class="col-5">
                                            <span class=''> <p class='admin-sub-head-card'></p></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Commission:  </p>
                                        </div>
                                        <div class="col-5">
                                            <span class=''> <p class='admin-sub-head-card'></p></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Dducted tax:  </p>
                                        </div>
                                        <div class="col-5">
                                            <p class='admin-sub-head-card'><span class=''></span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Total number of Students : </p>
                                        </div>
                                        <div class="col-5">
                                            <p class='admin-sub-head-card'><span class=''></span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Tax Percentage : </p>
                                        </div>
                                        <div class="col-5">
                                            <p class='admin-sub-head-card'><span class=''></span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ms-3">
                                    <div class='d-flex justify-content-between'>
                                        <div class="col">
                                            <p class='admin-sub-head-card'>Currency :  </p>
                                        </div>
                                        <div class="col-5">
                                            <p class='admin-sub-head-card'><span class=''></span></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </div>
      </>
      )
}

      export default AdminTeacherWalletDetail