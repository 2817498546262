import { configureStore } from '@reduxjs/toolkit';
import pageDisplay from "./displayer";
import counterReducer from './slice/counterSlice';
import dashboardReducer from './slice/dashboardSlice'
import authSliceReducer from './slice/AuthSlice';
import subjectSlice from "./slice/SubjectSlice";
import studentSlice from './slice/studentSlice'
import teacherSlice from './slice/teacherSlice'
import uploadSlice from './slice/UploadSlice'
import settingSlice  from './slice/settingsSlice';
import freelanceSlice from './slice/freelanceSlice';
import  chatSlice  from './slice/chatSlice';


export const store = configureStore({
  reducer: {
    pageDisplay: pageDisplay,
    common: counterReducer,
    counter: counterReducer,
    auth: authSliceReducer,
    subject: subjectSlice,
    dashboard: dashboardReducer,
    student: studentSlice,
    teacher: teacherSlice,
    settings: settingSlice,
    freelance: freelanceSlice,
    upload: uploadSlice,
    chat: chatSlice,
  },
});

export default store;
