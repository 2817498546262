
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { CSTData, addSubCategory, getCSTData, viewCategoryDetails } from '../../../../../../redux/slice/SubjectSlice';
import { AiOutlineLeft } from 'react-icons/ai';
import _ from 'lodash';
import { subjectLoading } from '../../../../../../redux/slice/SubjectSlice';
import { RotatingLines } from 'react-loader-spinner';
import { getCategoryListData } from '../../../../../../redux/slice/studentSlice';
import { FaPlus } from 'react-icons/fa';
import img from '../../../../../../images/tick.jpg'
function AddSubCategoryComponent(props) {
    const [addAnother, setAddAnother] = useState(false); // State to track if the user wants to add another category
    const [formSubmitted, setFormSubmitted] = useState(false); 
    const currentCategoryName = props.userState.categoryName?.category?.name;
    const currentCategoryId = props.userState.categoryName?.category?.id;
    const dispatch = useDispatch();
    const data = useSelector(CSTData);
    const loading = useSelector(subjectLoading);
    const validationSchema = Yup.object({
        category: Yup.string().required('category is required'),
        subCategory: Yup.string().required('sub category is required'),
    });

    useEffect(() => {
        const payload = {
            "type": 1
        }
        dispatch(getCSTData(payload));
    }, []);


    const categoryOptions = data?.map((e) => ({
        value: e.id,
        label: e.name
    }));
    const goToCategoryDetailsPage = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'CategoryDetailsPage', });
        }
    };
    const goToAddSubCategory = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminAddSubCategory' });
        }
    };
    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        const valueToSend = {
            "name": values.subCategory,
            "category_id": currentCategoryId
        }
        resetForm();
        dispatch(addSubCategory(valueToSend));
        setSubmitting(false);
            setAddAnother(true); // Show the confirmation message
            setFormSubmitted(true);
        const payload = {
            type: 2
        };

        // setTimeout(() => {
        //     dispatch(viewCategoryDetails({ ...payload, category_id: currentCategoryId }));
        //     goToCategoryDetailsPage();
        //     // goToAddSubCategory()  
        //     // dispatch(getCSTData(payload));
        // }, 2000);
    };


    useEffect(() => {
        const payload = {
            type: 1 || 2,
        };
        dispatch(getCSTData(payload));
        // dispatch(getCategoryListData());
    }, []);



    const handleAddAnother = () => {
        setAddAnother(false); // Hide the confirmation message
        setFormSubmitted(false); // Show the save button
    };

    return (
        <div>
            <div className="container  m-xxl-5 m-xl-5 m-lg-5 m-md-5">
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <div className="col-xxl-8 col-xl-8 col-lg-10">
                            <div className="main-divHead  m-xxl-5 m-xl-5 m-lg-5 m-md-5 m-0  ">
                                <div className="sub-Head1">
                                    <div className="Sub-Head2" onClick={(e) => goToCategoryDetailsPage(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>SubCategory</div>
                                </div>
                                <div className=" p-5">
                                    <h4 className="fw-bold">Add Sub Category</h4>
                                    <Formik
                                        initialValues={{ category: currentCategoryId || '', subCategory: '' }} // Set the initial value of category field
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}>
                                             {({ isSubmitting }) => (
                                        <Form>
                                                {!addAnother && (
                                            <div className="container">
                                                <div className="row">
                                                    <div className="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3">
                                                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                                            <div className="form-group p-0">
                                                                <label className="admin-label mt-4">Category</label>
                                                                <Field
                                                                    type="text"
                                                                    name="category"
                                                                    value={currentCategoryName} // Set the value to the categoryName                                                             
                                                                    className="form-control admin-input"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="d-flex justify-content-center mb-3 ms-1">
                                                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                                            <label className="admin-label">Sub Category / Class</label>
                                                            <Field
                                                                type="text"
                                                                name="subCategory"
                                                                className="form-control admin-input"
                                                            />
                                                            <ErrorMessage
                                                                name="subCategory"
                                                                component="div"
                                                                className="text-danger fs-6 mt-1"
                                                            />
                                                        </div>
                                                        {/* <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 ms-2 mt-3'  >
                                                            <button className='plus-button p-3' type="submit"><FaPlus className='ms-1' /></button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                              )}
                                            <div className="container text-center">
                                                <div className="row">
                                                    <div className="col">
                                                    {!formSubmitted && (
                                                        <button
                                                            type="submit"
                                                            // onClick={handleSave}
                                                            className="btn btn-primary text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                                                            disabled={isSubmitting}>
                                                            {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                                                                strokeColor="white"
                                                                strokeWidth="5"
                                                                animationDuration="0.75"
                                                                width="25"
                                                                height="25"
                                                                visible={true}
                                                            /></div> : "Save"}
                                                        </button>
                                                              )}
                                                    </div>
                                                </div>
                                                {addAnother && (
                                                        <div className="row mt-3 card bordr-0 ">
                                                            <div className="col ">
                                                                <div className=''>
                                                                <h2 className=''> Sub Category Added Successfully! </h2>
                                                                <img src={img} alt="" className='img-fluid' style={{width:"170px" ,height:"170px"}}/>
                                                               <p>Do you want to add one more Sub Category
                                                               </p>
                                                                <button onClick={handleAddAnother} className="btn btn-primary mx-2">yes</button>
                                                                <button type="submit" className="btn btn-primary mx-2">No</button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </Form>
                                         )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSubCategoryComponent;
