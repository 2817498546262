import React, { useState, useEffect } from 'react'
import { Grid } from "@mui/material";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBCardImage, MDBRipple } from 'mdb-react-ui-kit';
import moment from "moment";
import Clock from '../../../../../../DashImage/Clock.png'
import Calendar from 'react-calendar';
import './DashboardTeacher.scss'
import axios from 'axios'
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import TeacherGraph from './TeacherGraph'
import { RotatingLines } from 'react-loader-spinner';
import img from '../../../../../../DashImage/GroupImg.png';
import img1 from '../../../../../../DashImage/Graph.png'
import img2 from '../../../../../../DashImage/GraphDown.png'
import img3 from '../../../../../../DashImage/Quires.png'
import img4 from '../../../../../../DashImage/Sales.png'
import ChartComponent from '../adminPage/chart.component';
import CalenderComponent from '../adminPage/calender.component';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';




function DashboardTeacher() {

    const [value, onChange] = useState(new Date());
    const [chartData, setChartData] = useState({});
    const [isactions, setIsActions] = useState(false);
    const [subjectList, setSubjectList] = useState([{}])

    const [data, setData] = useState({

    })
    const [rows, setRows] = useState()
    const [course, setCourse] = useState([{}])
    const [Upcomingclass, setUpcomingclass] = useState([{}]);
    const [loading, setLoading] = useState(true)

    const getStatusCellStyle = (status) => {
        if (status === '1') {
            return { color: 'green' }
        } else if (status === '0') {
            return { color: 'red' }
        }
        return {}
    }

    useEffect(() => {
        fetchDashboard()
    }, [])

    const fetchDashboard = () => {
        if (!localStorage.getItem('token')) {
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        Axios.post('/api/teacher/dashboard', undefined, { headers: headers })
            .then((response) => {
                const responseData = response?.data?.data;
                setData(responseData)
                setCourse(response.data.data.myCourseDetails);
                setUpcomingclass(response.data.data.upcomingClasses);
                setLoading(false)


                const formattedData = response?.data?.data.map(
                    (item) => ({
                        ...item,
                        created_at: moment(item.created_at).format("DD-MM-YYYY"),
                    })
                );
                setCourse(formattedData)
                setUpcomingclass(formattedData);
            })
            .catch((error) => {
                console.error('Error fetching dashboard:', error)
                setLoading(false);
            })
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#202224',
                backgroundColor: '#fff',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#202224",
                backgroundColor: "#fff",
            }
        },
    }
    const formatDate = (dateStr) => {
        if (!dateStr) return ''; // Handle case when dateStr is not provided or invalid
        const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        const formattedDate = new Date(dateStr).toLocaleDateString('en-US', options);
        return formattedDate;
    };
    const columns = [
        {
            name: 'subject',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Start Date',
            selector: (row) => row.created_at,
            sortable: true,
            format: (row) => formatDate(row.created_at),
        },
        {
            name: 'Amount',
            selector: row => row.price,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => {
                const color = row.status === 1 ? 'green' : 'red';
                return (
                    <>{row.status === 1 ? <button type="button" className="footer-sub-dash rounded-pill p-2 px-4 py-2"
                        onClick={() => {
                            // Handle button click logic here
                            console.log('Button clicked');
                        }}
                    >
                        {row.status === 1 ? 'Active' : 'Inactive'}
                    </button> : <button type="button" className="footer-sub-dash-cancel rounded-pill p-2 px-4 py-2"
                        onClick={() => {
                            // Handle button click logic here
                            console.log('Button clicked');
                        }}
                    >
                        {row.status === '1' ? 'Active' : 'Inactive'}
                    </button>}</>

                );
            },
        },

    ]
    useEffect(() => {
        getRows();
    }, []);


    const getRows = () => {
        let res = _.find(course, sub => sub.id == isactions)
        setRows([res])
    }

    const renderUpcomingClasses = () => {
        const currentDate = new Date();
        const dayOfWeek = currentDate?.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
        const classesForToday = data?.upcomingClasses[dayOfWeek] || [];

        return (
            <MDBCard key={currentDate?.toDateString()} className='DashBoard'>
                <MDBCardBody>
                    <MDBCardTitle className='mdbcard-head'> Top Rated Classes <br /> <br /> </MDBCardTitle>
                    {classesForToday.map((classInfo) => (
                        <div key={classInfo?.id}>
                            <MDBCardText className='mdbcard-title'>{classInfo?.name}</MDBCardText>
                            <MDBCardText className='mdbcard-title-3'>
                                &nbsp;&nbsp;
                                {formatTime(classInfo?.from)} - {formatTime(classInfo?.to)}
                            </MDBCardText>
                            <MDBCardImage src={Clock} fluid alt='clock-icon' className='Group-icon-2' />
                        </div>
                    ))}
                </MDBCardBody>
            </MDBCard>
        );
    };

    // Function to format time to 12-hour format
    const formatTime = (time) => {
        const parts = time.split(":");
        const hours = parseInt(parts[0]);
        const minutes = parts[1];
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert 0 to 12

        return `${formattedHours}:${minutes} ${ampm}`;
    };


    return (
        <div className='dash-mainHead1'>
            <div className='Main_headDash'>
                <p className='teacher-main-head ms-3'>Teacher DashBoard</p>
            </div>
            {loading && (
                <div className="loader-container">
                    {/* <RotatingLines type="TailSpin" color="#00BFFF" height={80} width={80} /> */}
                </div>
            )}
            {!loading && (
                <div>

                    <div class="container text-start">
                        <div class="row mt-4 ">
                            <div class="col-lg-3 col-12">
                                <div className='admin-card p-3 '>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <p class='admin-gray-text'>Total Students</p>
                                                <h4 class='fw-bold'>{data?.totalStudents}</h4>
                                            </div>
                                            <div class="col-4">
                                                <img src={img} class="img-fluid rounded-top " alt="" />
                                            </div>
                                        </div>
                                        <div class="row mt-3 ">
                                            <div class="col">
                                                <p class='mb-0'><span><img src={img1} class="img-fluid rounded-top" alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-12 mt-3 mt-lg-0">
                                <div className='admin-card p-3 '>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <p class='admin-gray-text'>Total Course</p>
                                                <h4 class='fw-bold'>{data?.totalCourses}</h4>
                                            </div>
                                            <div class="col-4">
                                                <img src={img3} class="img-fluid rounded-top " alt="" />
                                            </div>
                                        </div>
                                        <div class="row mt-3 ">
                                            <div class="col">
                                                <p class='mb-0'><span><img src={img1} class="img-fluid rounded-top" alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-12  mt-3 mt-lg-0">
                                <div className='admin-card p-3 '>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <p class='admin-gray-text'>Total Sales</p>
                                                <h4 class='fw-bold'>{data?.totalSales}</h4>
                                            </div>
                                            <div class="col-4">
                                                <img src={img} class="img-fluid rounded-top " alt="" />
                                            </div>
                                        </div>
                                        <div class="row mt-3 ">
                                            <div class="col">
                                                <p class='mb-0'><span><img src={img1} class="img-fluid rounded-top" alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-12  mt-3 mt-lg-0">
                                <div className='admin-card p-3 '>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div class='d-flex'>
                                                    <div>
                                                        <p class='admin-gray-text '>Total Quotes</p>
                                                        <h4 class='fw-bold'>{data?.totalEnquires}</h4>
                                                    </div>
                                                    <div class='ms-1'>
                                                        <img src={img4} class="img-fluid  " alt="" />
                                                    </div>
                                                </div>
                                            </div>                 
                                        </div>
                                        <div class="row mt-3 ">
                                            <div class="col">
                                                <p class='mb-0'><span><img src={img2} class="img-fluid " alt="" /></span><span class='admin-red-dash'> 8.5%</span> <span class='admin-gray-text' >Down from yesterday</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-lg-8 col">
                                {/* <ChartComponent /> */}
                                <TeacherGraph />
                            </div>
                            <div class="col-lg-4 col mt-3 mt-lg-0">
                                <CalenderComponent />
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-lg-8 mt-4 col teacher-course-back">
                                <h6 class='fw-bold mt-5'>New Course</h6>
                                <DataTable
                                    columns={columns}
                                    data={course}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    striped
                                    customStyles={customStyles}
                                    persistTableHead
                                    pagination
                                />
                            </div>
                            <div class="col-lg-4 col mt-3 mt-lg-0">
                                {renderUpcomingClasses()}
                            </div>
                        </div>


                    </div>

                    <br />
                    {/* <Grid container spacing={{ xs: 12, md: 6 }} columns={{ xs: 12, sm: 8, md: 10 }}>
                        <Grid item xs={10} sm={6} md={6}>
                            <div className='card'>
                                course Sale Graph
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <TeacherGraph />
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={4} sm={4} md={3.5}>
                            <MDBCard className='DashBoard'>
                                <MDBCardBody>
                                    <MDBCardTitle className='mdbcard-head'>{`${value.getDate()} ${value.toLocaleString('default', { month: 'long' })}`}</MDBCardTitle><br />
                                    <Calendar className='calender-teacher' onChange={onChange} value={value} />
                                </MDBCardBody>
                            </MDBCard>
                        </Grid>
                    </Grid> */}

                    {/* <Grid className='grid-container' container spacing={{ xs: 8, md: 6 }} columns={{ xs: 10, sm: 8, md: 10 }}>
                        <Grid item xs={10} sm={6} md={6} >
                            <div className='main-teacher-table'>
                                <MDBCardTitle className='mdbcard-head'>Course Details
                                </MDBCardTitle>
                                <DataTable
                                    columns={columns}
                                    data={course}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    striped
                                    customStyles={customStyles}
                                    persistTableHead
                                    pagination
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3}>
                            {renderUpcomingClasses()}
                        </Grid>
                    </Grid> */}
                </div>
            )}
        </div>

    )
}

export default DashboardTeacher
