import React from 'react'
import './cart.css'
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import img from '../../../../../../../images/emptycart.png'
import img1 from '../../../../../../../images/python.png'
import { green } from '@mui/material/colors';
function Cart() {
    const navigate = useNavigate()
    const StuBackdashboard = () => {
        navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
    }
    return (
        <>

            <div className="main-divHead">
                <div
                    className="profile-main-head d-flex py-2"
                    onClick={StuBackdashboard}
                >
                    <div className="fa-chaveron">
                        <IoIosArrowBack className="mb-1" />{" "}
                    </div>
                    <div className="profile-head">Cart</div>
                </div>
            </div>
            <div className='row'>
            <div className='col-4'>
                <div className="card mb-3 mt-5 ms-5" style={{ maxWidth: '540px' }}>
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={img1} className="img-fluid rounded-start" alt="Python Logo" />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">Python</h5>
                                <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                <p className="card-text">Rs 2000.00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div className="card mb-3 mt-5 ms-5" style={{ maxWidth: '540px' }}>
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={img1} className="img-fluid rounded-start" alt="Python Logo" />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">Python</h5>
                                <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                <p className="card-text">Rs 2000.00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-3 ms-5'>
                <div className="card mb-3 mt-5 border-0" style={{ maxWidth: '540px' }}>
                    <div className="row g-0">
                        
                        <div className="">
                            <div className="card-body">
                                <h5 className="card-title pricedetails ">PRICE DETAILS</h5><hr/>
                                <div className='row'>
                                    <div className='col-5'>
                                    <p className="">Price{""}</p>
                                    </div>
                                    <div className='col'>
                                    <p className="">Rs 4000.00</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                    <p className="">Discount{""}</p>
                                    </div>
                                    <div className='col'>
                                    <p className=""style={{color:"green"}}>Rs 1000.00</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                    <p className="bold">Total Amount{""}</p>
                                    </div>
                                    <div className='col'>
                                    <p className="bold">Rs 3000.00</p>
                                    </div>
                                </div><hr/>
                                <div className='d-flex justify-content-center mb-3'>
                                <button type="button" class="btn btn-success ">Purchase Now</button>
                                </div>
                              
                                <p style={{color:"green"}}>You will save Rs 1000 in this purchase</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Cart