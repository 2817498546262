import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import "./Quotation.scss";
import { useNavigate } from "react-router-dom";
import NavTution from "./Navtution"
import LoadingSpinner from "../../../../../../Reactloader/LoadingSpinner";
import { format } from "date-fns";
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";
function QuotationDetailsComponent({ setLoading, ...props }) {
    const navigate = useNavigate();
    const [displayTable, setdisplayTable] = useState(false);
    const [QuotationView, setQuotationView] = useState(props.QuotationView)
    const [QuotationDetails, setQuotationDetails] = useState(props.QuotationDetails)
    const [Quotation, setQuotation] = useState(props.Quotation)
    const [loader, setLoader] = useState(props.loader)
    const [details, setDetails] = useState(props.details)
    const [selectedOption, setSelectedOption] = useState(props.selectedOption)
    const [showTable, setshowTable] = useState(props.showTable);
    const [id, setid] = useState(props.id);
    const [fileUrl, setFileUrl] = useState(null);
    const basePath = "http://tuitionguruji.com/backend/public/";
    const handleInput = (e) => {
        setQuotation({ ...Quotation, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        setLoader(props.loader);
        setQuotationDetails(props.QuotationDetails)
        setQuotation(props.Quotation)
        setLoader(props.loader)
        setshowTable(props.showTable);
        setDetails(props.details)
        setSelectedOption(props.selectedOption)
        setid(props.id)
    }, [props.loader, props.QuotationDetails, props.loader, props.Quotation, props.details, props.selectedOption, props.showTable, props.id])
    const [Status, setStatus] = useState([{
        status: "",
        id: ""
    }])
    const customStyles = {
        headRow: {
            style: {
                color: "#fff",
                backgroundColor: "#5C5AB6",
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
            },
        },
    };

    const Backdashboard = () => {
        if (_.get(props, "setUserState")) {
            props.setUserState({ ...props.userState, subType: "Quotation" });
        }
    };
    const QuotationChangeStatus = (value, id) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const formData = new FormData();
        // Assuming Status is correctly defined and status is a number between 0 and 1
        const status = value.toString(); // Make sure this is correctly set

        if (status === "0" && status === "1") {
            console.error('Invalid status value: Status.status must be 0 or 1');
            return;
        }

        // if (!isNaN(quoteid) && Number.isInteger(quoteid)) {
        formData.append("id", id);
        formData.append("status", status);
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/changeStatusQuotation', formData, { headers: headers })
            .then((res) => {
                if (_.get(res, 'data.data')) {

                    setStatus(res.data.data.status);
                    setLoading(false)
                    setQuotationDetails(!details);
                    setid(id);
                    const formattedData = res.data.data.map((item) => ({
                        ...item,
                        closing_date: format(new Date(item.closing_date), "dd-MM-yyyy"),
                    }));
                    setQuotationView({ ...QuotationView, quotation: res.data.data });
                    setQuotationView(formattedData);
                    // Replace the previous data with the new data
                }
            })
            .catch((error) => {
                console.error('Error fetching Subjects:', error);
                setLoading(false);
            });
    }
    const handleDownload = () => {
        // Assuming that QuotationView.quotation.file contains the URL of the file to download
        if (QuotationView.quotation.file) {
            setFileUrl(QuotationView.quotation.file);
        }
    };
    return (
        <div className="sidebar_content">
            {loader ? <LoadingSpinner /> : <div className="chat-container p-2 ms-5 me-5 mt-3">
                {QuotationDetails && (
                    <div className="data-table">
                        {!showTable && selectedOption !== "online" && selectedOption !== "freelance" &&
                            < div className="">
                                <div className="job-details-head mb-3">Job Details</div>

                                <div className=" p-4 adminSubCard">
                                    <div className="container text-start">
                                        <div className="row">
                                            <div className="d-flex justify-content-between">
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        Closing Date :
                                                    </p>
                                                </div>
                                                <div className="col-xxl-8 col-xl-9 col-lg-10 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        {_.get(QuotationView, 'quotation.closing_date')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="d-flex justify-content-between">
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        Student Name:{" "}
                                                    </p>
                                                </div>
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        {_.get(QuotationView, 'quotation.student_id')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="d-flex justify-content-between">
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        Category :{" "}
                                                    </p>
                                                </div>
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        {_.get(QuotationView, 'quotation.category')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="d-flex justify-content-between">
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        Sub Category:{" "}
                                                    </p>
                                                </div>
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        {_.get(QuotationView, 'quotation.sub_category')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="d-flex justify-content-between">
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        Topic:{" "}
                                                    </p>
                                                </div>
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        {_.get(QuotationView, 'quotation.topic')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row d-flex">
                                            <div className="d-flex justify-content-between">
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        Details :{" "}
                                                    </p>
                                                </div>
                                                {/* <div className="row"> */}
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        {_.get(QuotationView, 'quotation.details')}
                                                    </p>
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="d-flex justify-content-between">
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p className="student-font-label">
                                                        Status :{" "}
                                                    </p>
                                                </div>
                                                <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-11">
                                                    <p
                                                        className="student-font-label"
                                                        style={{
                                                            color: QuotationView.status
                                                                ? "green"
                                                                : "red",
                                                        }}
                                                    >
                                                        {QuotationView.status
                                                            ? "Active"
                                                            : " Inactive"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col">
                                                <p className="student-font-label">Schedule:</p>
                                                {QuotationView.scheduleTime ? (
                                                    QuotationView.scheduleTime.map((item, index) => (
                                                        <div key={index} class="row">
                                                            <div class="row ps-3">
                                                                <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col p-0 m-0 mt-3 ">
                                                                    <p className="stud-online-day"> <span className="me-2">
                                                                        <input type="checkbox" /></span>{item.day}</p>
                                                                </div>
                                                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col p-0 d-flex align-self-center">
                                                                    <p className=" stud-online-from mt-2">From</p>
                                                                    <input type="time" id="appt" name="appt" value={item.from} className="time-box mx-1" />
                                                                    <input className='time-box'
                                                                        placeholder="AM/PM"
                                                                        disabled />
                                                                </div>
                                                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col p-0 mt-1 d-flex">
                                                                    <p className="stud-online-from mt-2">To</p>
                                                                    <input type="time" id="appt" name="appt" value={item.to} className="time-box mx-1" />
                                                                    <input className='time-box'
                                                                        placeholder="AM/PM"
                                                                        disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div>No schedule time available</div>
                                                )}
                                            </div>
                                        </div>



                                    </div>
                                </div>


                                <div className="d-flex justify-content-end">
                                    <div className="mt-2">Status:</div>
                                    <div>
                                        <select
                                            className="free-quote mt-2" onChange={(e) => QuotationChangeStatus(e.target.value, QuotationView.quotation.id)}>
                                            <option value="1" className="quote2">
                                                Inactive
                                            </option>
                                            <option value="0" className="quote3">
                                                Active
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="save-btn d-flex">
                                    <button className="save-btn-quote">Save</button>
                                </div>
                                {QuotationView.scheduleTime && details && displayTable && <div className="quotation-details-table">
                                    <div container className="quotation-details">
                                        <NavTution  {...props} >online</NavTution>
                                        <div className="save-btn d-flex">
                                            <button className="save-btn-quote">Save</button>
                                        </div>

                                    </div>
                                </div>
                                }
                            </div>
                        }
                    </div>
                )}
            </div>}
        </div >
    );
}
export default QuotationDetailsComponent;
