import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import axios from 'axios';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function CouponStudentList(props) {
    const [data, setData] = useState({})
    const fetchstudentlist = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const createStudentList = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {}
        const formData = new FormData();
        formData.append("email", _.get(createStudentList, 'email'));
        Axios.post('/api/listCoupon',

            formData, { headers })
            .then((response) => {
                if (_.get(response, 'data.data')) {
                    setData(response.data.data)
                }
            })
            .catch((error) => {
                console.error('Error fetching Coupons:', error)
            });
    };
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [data])

    const initializeObject = () => {
        setData({ ...data, ...props.userState.row })
    }

    useEffect(() => {
        fetchstudentlist()
    }, [])


    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",

            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const columns = [
        {
            name: 'Student Id',
            selector: row => row.starting_at,
            sortable: true
        },
        {
            name: 'Student Name',
            selector: row => row.category,
            sortable: true
        },
    ]
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachersubject' })
        }
    }
    return (
        <div className='main-divHead' >

            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Coupon</div>
            </div>
            <div className='Main-head-viewschedule'>Student List </div>
            <div class="container text-start">
                <div class="row mt-5">
                    <div className='d-flex justify-content-center'>
                        <div class="col-2">
                            <p className='techer-card-typo'>Category : </p>
                        </div>
                        <div class="col-4">
                            <p className='techer-card-typo'>{data.category}</p><br />
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div className='d-flex justify-content-center'>
                        <div class="col-2">
                            <p className='techer-card-typo'>sub Category : </p>
                        </div>
                        <div class="col-4">
                            <p className='techer-card-typo'>{data.sub_category}</p><br />
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div className='d-flex justify-content-center'>
                        <div class="col-2">
                            <p className='techer-card-typo'>Discount : </p>
                        </div>
                        <div class="col-4">
                            <p className='techer-card-typo'>{data.value}</p><br />
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div className='d-flex justify-content-center'>
                        <div class="col-2">
                            <p className='techer-card-typo'>Subject : </p>
                        </div>
                        <div class="col-4">
                            <p className='techer-card-typo'>topic</p>
                        </div>
                    </div>
                </div>
                <div class="col-8 ms-5 justify-content-center">
                    <DataTable
                        className='dataTable'
                        data={data.coupon}
                        columns={columns}
                        customStyles={customStyles}
                        pagination
                        fixedHeaderScrollHeight="600px"
                        striped
                        persistTableHead
                    />
                </div>
            </div>
        </div >
    )
}

export default CouponStudentList