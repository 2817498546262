import React, { useState } from "react"
import membershipWhiteIcon from "../../../icons/membershipWhiteIcon.png"
import membershipBlueIcon from "../../../icons/membershipBlueIcon.png"
import _ from "lodash"
import { MdOutlineRequestQuote } from "react-icons/md";


function QuotationTeacher(props) {
    const [hoverEffect, setHoverEffect] = useState(false)

    const goToTeacherQuotation = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherQuotation' })
        }
    }
    const determineClassName = () => {
        const { userState } = props;
        switch (userState?.subType) {
            case 'TeacherQuotation':
            case 'QuoteJobDetails':
                return 'sidebar_content active';
            default: return 'sidebar_content';
        }
    };
    return (
        <div  className={determineClassName()}>
            <MdOutlineRequestQuote class="ms-3" />
            <p className='head-dashboard ms-2' onClick={(e) => goToTeacherQuotation(e)}>Quotation Request</p>
        </div>
    )
}

export default QuotationTeacher