
import React, { useEffect, useState } from 'react'
import axios from "axios";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import _ from 'lodash';
import './Placeorder.scss';
import { showErrorToast, showSuccessToast } from './../../../../../../../util/toastUtils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import visa from '../../../../../../../images/visa.png';
import paypal from './../../../../../../../images/paypal.png';
import mastercard from './../../../../../../../images/mastercard.png';
import Gpay from './../../../../../../../images/Gpay.png';
import Select from 'react-select';
import Confetti from 'react-confetti'
import BASE_URL from '../../../../../../../BaseUrl';
import Axios from '../../../../../../../util/apiUtil';
import { useSelector } from 'react-redux';
import { listStatesData } from '../../../../../../../redux/slice/studentSlice';



function PlaceOrder({ ...props }) {

    const data = localStorage.getItem('user');
    const userData = JSON.parse(data);
    const formatDate = (dateStr) => {
        if (!dateStr) return ''; // Handle case when dateStr is not provided or invalid
        const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        const formattedDate = new Date(dateStr).toLocaleDateString('en-US', options);
        return formattedDate;
    };
    const BackTopage = () => {
        if (showNext) {
            setShowNext(false);
        } else {
            navigate('/Showsubjects');
        }
    }
    const navigate = useNavigate()
    const location = useLocation();

    const PurchaseCourses = _.get(location, 'state') || {};
    const [formData, setFormData] = useState(PurchaseCourses);
    console.log("ddddddd***", formData);
    const [loading, setLoading] = useState(true)
    const [showNext, setShowNext] = useState(false);
    const [countries, setCountries] = useState([]);
   
    const listCountries = () => {

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/listCountries', undefined, { headers: headers })

            .then((response) => {
                if (_.get(response, "data.data")) {
                    const countriesObject = response.data.data;
                    // Convert the object into an array of country names
                    const countriesArray = Object.values(countriesObject);
                    setCountries(countriesArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                showErrorToast('Error fetching countries:', error);
                setLoading(false);
            })
    }
  

    const [selectedMonths, setSelectedMonths] = useState(1);

    const [states, setStates] = useState([]);
    const stateListData = useSelector(listStatesData);
    const stateList = Object.keys(stateListData).map((key) => ({
        id: key,                           // Keep the 'id' property as it is
        name: stateListData[key].name,     // Assuming the state name is stored under 'name' property
        abbreviation: stateListData[key].abbreviation, // Include additional property like 'abbreviation'
    }));
    // const onclickpay = () => {
    //     openPayModal(options)
    //     // navigate('/Paymethod')
    // }
    // const options = {
    //     key: "rzp_test_HJG5Rtuy8Xh2NB",
    //     amount: "100", //  = INR 1fp
    //     name: "Acme shop",
    //     description: "some description",
    //     image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",
    //     handler: function (response) {
    //         alert(response.razorpay_payment_id);
    //     },
    //     prefill: {
    //         name: "Gaurav",
    //         contact: "9999999999",
    //         email: "demo@demo.com"
    //     },
    //     notes: {
    //         address: "some address"
    //     },
    //     theme: {
    //         color: "#F37254",
    //         hide_topbar: false
    //     }
    // };

    const openPayModal = options => {
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    // Display the data on the web page
    const customStyles = {
        headRow: {
            style: {
                color: "#000",
                backgroundColor: "#FAFAFA",
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
            },
        },
    };
    const columns = [
        {
            name: "Date",
            selector: (formData) => formData.selectedCourse.created_at,
        },
        {
            name: "Subject",
            selector: (formData) => formData.selectedCourse.subject,
            sortable: true,
        },
        {
            name: "Expiration",
            selector: (formData) => formData.selectedCourse.subject,
            sortable: true,
        },
        {
            name: "Subtotal",
            selector: (formData) => formData.selectedCourse.subject,
            sortable: true,
        },
        {
            name: "Clear Cart",
            selector: <div>
                <RiDeleteBin6Line style={{ color: "red" }} />
            </div>

        },

    ];

    console.log("formdata", formData);
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: '1%', label: '1 ' },
        { value: '2%', label: '2' },
        { value: '3%', label: '3' },
        { value: '4%', label: '4' },
        { value: '5%', label: '5' },
    ];
    const [showConfetti, setShowConfetti] = useState(true); // State to control Confetti visibility

    useEffect(() => {
        // Hide Confetti after 3 seconds
        const timeout = setTimeout(() => {
            setShowConfetti(false);
        }, 6000);

        // Cleanup function
        return () => clearTimeout(timeout);
    }, []);
    const width = 300; // Define the width
    const height = 600; // Define the height

    const subtotal = parseFloat(_.get(formData, "selectedCourse.price")) || 0;
    const tenPercentOfSubtotal = 0.1 * subtotal;
    console.log("tensubtotal", tenPercentOfSubtotal);
    const total = subtotal + tenPercentOfSubtotal

    const [totaldis, setTotaldis] = useState(0);
    const calculateDiscount = (months) => {
        if (months > 1) {
            return (months - 1) * 0.5
        }
        return 0;
    }

    const handleMonthsChange = (e) => {
        const months = parseInt(e.target.value);
        setSelectedMonths(months);
        const discount = calculateDiscount(months);
        console.log("discount", discount);
        const newTotal = subtotal - discount;
        setTotaldis(newTotal);
        const remainingAmount = newTotal;
        console.log("Remaining Amount after discount:", remainingAmount);
    }

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [couponCode, setCouponCode] = useState('');
    const [discountAmount, setDiscountAmount] = useState(0);

    // Function to check coupon code
    const checkCouponCode = () => {
        if (!couponCode) {
            showErrorToast('Please enter a coupon code.');
            return;
        }
        const subjectId = formData.selectedCourse.id;
        const headers = { Authorization: 'Bearer ' + (localStorage.getItem('token')) };
        Axios.post('/api/checkCoponCodeAmount', { code: couponCode, subject_id: subjectId }, { headers })
            .then((response) => {
                const data = response.data;
                if (data.success) {
                    // Coupon code is valid, update discount amount
                    setDiscountAmount(data.data.discount_value);
                    showSuccessToast('Coupon code applied successfully.');
                } else {
                    // Coupon code is invalid
                    showErrorToast('Invalid coupon code.');
                    setDiscountAmount(0); // Reset discount amount
                }
            })
            .catch((error) => {
                showErrorToast('Error checking coupon code:', error);
                setDiscountAmount(0); // Reset discount amount
            });
    };

    const [useReferralCredit, setUseReferralCredit] = useState(false);

    // Handler for radio button change
    const handleReferralCreditChange = (event) => {
        setUseReferralCredit(event.target.value === 'yes');
    };

    const handleApplyCredit = () => {
        const subjectId = _.get(formData, "selectedCourse.id");
        const headers = { Authorization: 'Bearer ' + localStorage.getItem('token') };
    
        Axios.post(
            '/api/checkCoinWallet',
            { subject_id: subjectId },
            { headers }
        )
        .then((response) => {
            // Handle success
            console.log("Credit applied successfully:", response.data);
            showSuccessToast("Credit applied successfully.");
            // You can perform further actions here if needed
        })
        .catch((error) => {
            // Handle error
            showErrorToast("Error applying credit:", error);
        });
    };

    // tax calculate
    const [finalAmount, setFinalAmount] = useState(0);
    const goToPlaceordernext = () => {
        // Make API call to calculate tax
        const subjectId = _.get(formData, "selectedCourse.id");
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
    
        Axios.post('/api/calculateTax', { subject_id: subjectId }, { headers })
        .then((response) => {
            const taxData = response.data.data;
            const { finalAmount } = taxData;
            setFinalAmount(finalAmount);
          
        })
        .catch((error) => {
            showErrorToast('Error calculating tax:', error);
        });
        
        setShowNext(true);
    };
  const final=finalAmount
  console.log("final",final);
    const Listsubject = () => {
        const subjectId = _.get(formData, "selectedCourse.id");
        const amount = final; // Assuming `total` is the total amount including taxes
        const description = "purchasing this course";
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };

        Axios.post('/api/purchaseCourse', { subject_id: subjectId, amount, description }, { headers })
            .then((response) => {
                // Handle successful response
                console.log("Purchase successful:", response.data);
                showSuccessToast("Purchase successful");
                // Navigate to the desired page
                navigate("/student", { state: { type: 'Student', subType: 'EnrolledCourses' } });
            })
            .catch((error) => {
                // Handle error
                showErrorToast('Error purchasing course:', error);
            });
    };

    const payment = _.get(formData, "selectedCourse.payment");

    const  periodUnit = payment === "1" ? "Days" : "Months";
    const  plan = payment === "1" ? "Onetime Plan" : "Monthly Plan";
    const planmonth = payment === "1" ? "Onetime Plan" : (selectedPlan === 'monthly' ? "Monthly Plan" : "Annual Plan");
  
    return (

        <div>
            <div className="student-main-head d-flex  " >
                <div className="fa-chaveron"><ArrowBackIosIcon onClick={BackTopage} /></div>
                <div className=" profile-head">Cart</div>
            </div>
            {!showNext && <div className=' showsubject-container'>
                <p className='place-order-head px-5 ms-5 mb-5'>Place Order</p>
                <div className='row '>
                    <div className='col-1 ms-5 '> <label className="form-label teacher-font-label">Date:</label></div>
                    <div className='col-3 background-placeholder'><p>{formatDate(_.get(formData, "selectedCourse.created_at"))} </p></div>
                </div>
                <div className='row mt-3'>
                    <div className='col-1 ms-5'><label className="form-label teacher-font-label "> Teacher Name:</label></div>
                    <div className='col-3 background-placeholder'><p>{_.get(formData, "selectedCourse.teacher")} </p></div>
                </div>
                <div className='row mt-3'>
                    <div className='col-1 ms-5'><label className="form-label teacher-font-label"> Course:</label></div>
                    <div className='col-3 background-placeholder'><p>{_.get(formData, "selectedCourse.name")} </p></div>
                </div>
                <div className='row mt-3'>
                    <div className='col-1 ms-5'><label className="form-label teacher-font-label"> category:</label></div>
                    <div className='col-3 background-placeholder'><p>{_.get(formData, "selectedCourse.category_name")} </p></div>
                </div>
                <div className='row mt-3'>
                    <div className='col-1 ms-5'><label className="form-label teacher-font-label"> Duration:</label></div>
                    <div className='col-3 background-placeholder'><p>{_.get(formData, "selectedCourse.period")} {periodUnit} </p></div>
                </div>
                <div className='row mt-3'>
                    <div className='col-1 ms-5'><label className="form-label teacher-font-label"> Price:</label></div>
                    <div className='col-3 background-placeholder'><p>Rs{" "}{_.get(formData, "selectedCourse.price")}.00  </p></div>
                </div>
                <div className='row mt-3'>
                    <div className='col-1 ms-5'><label className="form-label teacher-font-label"> Course Plan:</label></div>
                    {/* <div className='col-3 '>
                        <div className='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                type='radio'
                                name='inlineRadioOptions'
                                id='inlineRadio1'
                                value='monthly'
                                onChange={() => setSelectedPlan('monthly')}
                            />
                            <label className='form-check-label' htmlFor='inlineRadio1'>
                                One time Plan
                            </label>
                        </div>
                        <div className='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                type='radio'
                                name='inlineRadioOptions'
                                id='inlineRadio2'
                                value='annual'
                                onChange={() => setSelectedPlan('annual')}
                            />
                            <label className='form-check-label' htmlFor='inlineRadio2'>
                                Monthly Plan
                            </label>
                        </div>
                    </div> */}

                <div className='col-3 background-placeholder'><p> {plan} </p></div>
                </div>
                {plan === "Monthly Plan" && (
            <div className='row mt-3'>
                <div className='col-1 ms-5'>
                    <label className="form-label teacher-font-label">Number of Months:</label>
                </div>
                <div className='col-3'>
                    <input
                        type='number'
                        className='form-control profile-field rounded-pill border-0'
                        aria-label='Username'
                        aria-describedby='basic-addon1'
                        onChange={handleMonthsChange}
                    />
                    <p className='p-placeholder'>(if you buy a course for more than 1 month you get a discount)</p>
                </div>
            </div>
        )}
                <div className=' mt-3'>
                    <div className='col-5 ms-5'><label className="form-label teacher-font-label"><h5>Do you have any  Cuopon code:</h5></label></div>
                    <div className='row'>
                        <div className='col-3 '> <input
                            type="text"
                            className="form-control profile-field rounded-pill ms-5 border-0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            placeholder='Enter your coupon code'
                        />
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-5 col-md-2 col-sm-2  ">
                            <button
                                className="btn btn-success applycoupon ms-5"
                                onClick={checkCouponCode}
                            >
                                Apply Coupon
                            </button>
                        </div>
                    </div>
                </div>

                <div className='row '>
                    <div className='col-xxl-5 col-xl-5 col-lg-4 col-md-3 col-sm-3 mt-xxl-2 mt-xl-2 mt-lg-2 mt-md-2 mt-sm-2'>
                        <label className="form-label teacher-font-label mt-3 ms-5"><h5>Do you want to use Referral Credit</h5></label>
                        <div className="form-check form-check-inline ">
                            <input
                                className="form-check-input row ms-3"
                                type="radio"
                                name="referralCredit"
                                id="referralYes"
                                value="yes"
                                checked={useReferralCredit}
                                onChange={handleReferralCreditChange}
                            />
                            <label className="form-check-label ms-3" htmlFor="referralYes">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input ms-5"
                                type="radio"
                                name="referralCredit"
                                id="referralNo"
                                value="no"
                                checked={!useReferralCredit}
                                onChange={handleReferralCreditChange}
                            />
                            <label className="form-check-label" htmlFor="referralNo">No</label>
                        </div>
                        {useReferralCredit && (
                            <div className='col d-flex justify-content-center'>
                                <Select
                                    className="border-secondary col-5"
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                />
                                 <div class="col-xxl-4 col-xl-4 col-lg-5 col-md-2 col-sm-2  ">
                            <button
                                className="btn btn-success applycoupon ms-5"
                                onClick={handleApplyCredit}
                            >
                                Apply credit
                            </button>
                        </div>
                            </div>
                            
                        
                        )}
                    </div>
                    
                </div>


                <div class="row ">
                    <div class="px-5 col-xxl-12  col-xl-12  col-lg-12  col-md-12 ms-5 col-sm-12  mt-xxl-5 mt-xl-5 mt-lg-2 mt-md-2 mt-sm-4 mb-5">
                        <button className='px-5 place-next-btn ms-5 ps-xxl-3 ps-xl-3 ps-lg-3 ps-md-3  pe-xxl-3 pe-xl-3 pe-lg-3 pe-md-3' onClick={() => goToPlaceordernext()}>Next <FontAwesomeIcon icon={faArrowRightLong} /></button>
                    </div>
                </div>
            </div>


            }
            {showNext && <div>
                <div class="container text-center ">
                    <div class="row d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-flex justify-content-between">

                        <div className='col-xxl-6 col-xl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6  text-start'>
                            <div className='row'>
                                <div className='col'>
                                    <p className='billing-head mt-3'> Billing Address</p>
                                </div>
                            </div>
                            <div class="row">
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-xxl-2 mt-xl-2 mt-lg-2 mt-md-2 mt-sm-2'>
                                    <label className="form-label teacher-font-label">Name:</label>
                                    <input type="text" className="form-control profile-field" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" value={_.get(userData, "firstname")} />
                                </div>
                            </div>
                            <div className='row'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-xxl-2 mt-xl-2 mt-lg-2 mt-md-2 mt-sm-2">
                                    <label className="form-label teacher-font-label">  Email Address:</label>
                                    <input type="text" className="form-control profile-field" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" value={userData?.email} />
                                </div>
                            </div>
                            <div class="row">
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-xxl-2 mt-xl-2 mt-lg-2 mt-md-2 mt-sm-2'>
                                    <label className="form-label teacher-font-label">  Country:</label>
                                    <select
                                        className="form-select  teacher-font-field profile-field"
                                        name="category" onClick={listCountries} required>
                                        <option value="" >Select an option</option>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-xxl-2 mt-xl-2 mt-lg-2 mt-md-2 mt-sm-2">
                                    <label className="form-label teacher-font-label"> State </label>
                                    <select
                                        className="form-select  teacher-font-field profile-field"
                                        name="category" required>
                                        <option value="" >Select an option</option>
                                        {stateList.map(state => (
                                                            <option key={state.id} value={state.id}>{state.name}</option>
                                                        ))}
                                       
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-xxl-2 mt-xl-2 mt-lg-2 mt-md-2 mt-sm-2">
                                    <label className="form-label teacher-font-label"> Pin code:</label>
                                    <input type="text" className="form-control profile-field" placeholder="PinCode" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                            </div>
                            {/* <div className='row mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-4'>
                                <DataTable
                                    columns={columns}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    striped
                                    customStyles={customStyles}
                                    persistTableHead
                                    pagination
                                />
                            </div> */}
                        </div>

                        <div class="col-4 text-start">
                            <div className='container'>
                                <div className='row'>
                                    <div className='col'>
                                        <p className='billing-main-head mt-3'> Payment Method</p>
                                    </div>
                                </div>
                                <div className='student-card p-4 card-payment text-light'>
                                    <div className='row'>
                                        <p className='payment-head'>Card details</p>
                                    </div>
                                    <div className='row mt-4 '>
                                        <p className='payment-subhead'>card type</p>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 me-xxl-2 me-xl-2 me-lg-2 me-md-2'>
                                            <img src={visa} alt="visaimg" className=' vis-img p-1 ' />
                                        </div>
                                        <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2'>
                                            <img src={mastercard} alt="visaimg" className=' master-img p-1' />
                                        </div>
                                        <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 ms-2' >
                                            <img src={paypal} alt="visaimg" className=' master-img  p-1' />
                                        </div>
                                        <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 d-flex'>
                                            <img src={Gpay} alt="visaimg" className=' master-img p-1' />
                                        </div>


                                    </div>
                                    <div className='row mt-3'>
                                        <input type="email" className="form-control payment-input-field" id="exampleFormControlInput1" placeholder='Cardholder s Name' />
                                    </div>
                                    <div className='row  mt-3'>
                                        <input type="text" className="form-control payment-input-field" id="exampleFormControlInput1" placeholder='Card Number' />
                                    </div>
                                    <div className='row  mt-3 '>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 px-0 '>
                                            <input type="text" className="form-control payment-input-field me-2" id="exampleFormControlInput1" placeholder='Expiration' />
                                        </div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 ps-3'>
                                            <input type="text" className="form-control payment-input-field" id="exampleFormControlInput1" placeholder='Cvv' />
                                        </div>
                                    </div>
                                    <div className='row mt-4' >
                                        <hr />
                                    </div>
                                    <div className='row mt-4' >
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 Sub-total' >Subtotal</div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 Sub-total text-end'>Rs{" "}{_.get(formData, "selectedCourse.price")}.00</div>
                                    </div>
                                    <div className='row mt-4' >
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 Sub-total'>Total(Incl. taxes)</div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 Sub-total text-end'>Rs{" "}{finalAmount}.00</div>
                                    </div>
                                    <div className='row mt-4 d-flex justify-content-center' >
                                        <div className='col-xxl-10 col-xl-10 col-lg-10 col-md-11 col-sm-12 '>
                                            <button class="btn payment-button col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 p-2" type="button" onClick={() => Listsubject()}>Proceed to pay</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

            
        </div>






    )
}

export default PlaceOrder