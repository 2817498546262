import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import { viewTeacherData } from '../../../../../../redux/slice/teacherSlice';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

function AdminTeacherCoursecomponent(props) {
    const teacherData = useSelector(viewTeacherData);
    const namesArray = teacherData?.videouploads
    const [teacherCourse, setTeacherCourse] = useState(props?.userState?.rowSelected || '')
    const goToTTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminTeacher' })
        }
    }
    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    function handledetails() {
        goToTeacherBatchDetails();
        // dispatch(ActiveTeacherID(row.id));
    }


    const goToTeacherBatchDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminbatchstudent' })
        }
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
                textAlign: "center"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontWeight: "bold"
            }
        },
    };

    const columns = [
        {
            name: 'Batch Name',
            selector: row => row.batch,
            sortable: true,
        },
        {
            name: 'Student List',
            sortable: false,
            cell: (row, subData, rowIndex, id) => (
                <div>
                    <button type="button" class="btn text-light px-xxl-3 px-xl-3 px-lg-3 px-md-2 py-1 btnStudent" onClick={() => handledetails(row)} >Details</button>
                </div>
            )
        },
        {
            name: 'Action',
            sortable: false,
            cell: (row, subData, rowIndex, id) => (
                <div>
                    <button type="button" class="btn text-light px-xxl-3 px-xl-3 px-lg-3 px-md-2 py-1 btnActive_in" data-bs-toggle="modal" data-bs-target="#exampleModalActive" >Change Status</button>
                </div>
            )
        }

    ]

    return (
        <div className='main-divHead'>
            <div className='sub-Head1'>
                <FontAwesomeIcon icon={faChevronLeft} onClick={(e) => goToTTeacher(e)} />
                <div className='Sub-Head2'>Course Details</div></div>
            <div className="container text-start">
                <div className='row'>
                    <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center"><div className='col-xxl-9 col-xl-9 col-lg-12'>
                        <p class='admin-sub-head my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3'>Course Details</p>
                    </div></div>
                </div>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <div className="col-xxl-9 col-xl-9 col-lg-12">
                            <div className=" adminSubCard p-3">
                                <div>
                                    <div class="container text-start">
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Date : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'>{teacherCourse?.created_at ? dateFunction(teacherCourse?.created_at) : ""}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row  ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Course Name : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'>{teacherCourse?.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row  ms-3 ">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Price : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'>{teacherCourse?.price}.00</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Phone : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'></p>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Category :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <span class=''> <p class='admin-sub-head-card'>{teacherCourse?.category}</p></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Sub Category :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <span class=''> <p class='admin-sub-head-card'>{teacherCourse?.sub_category}</p></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Topic :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'><span class=''>{teacherCourse?.subject}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Teaching Language : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'><span class=''>{teacherCourse?.language}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Status : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'><span class=''>{teacherCourse?.status=== 0 ? "InActive":"Active"}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>No of Students : </p>
                                                </div>
                                                <div class="col-5">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <DataTable
                        className='dataTable mt-5'
                        data={namesArray}
                        columns={columns}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="600px"
                        striped
                        customStyles={customStyles}
                        persistTableHead
                        pagination
                    />
                </div>
            </div>
            <div class="modal fade" id="exampleModalActive" tabindex="-1" aria-labelledby="exampleModal" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content p-5">
                        <div class=" justify-content-center ms-5">
                            <h1 class="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want Active?</h1>
                            <button type="button" class="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal" >close</button>
                            <button type="button" class="btn btn-primary mt-3 ms-5 px-4" data-bs-dismiss="modal">Active</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminTeacherCoursecomponent