

import React, { useState, forwardRef, useEffect } from "react";
import axios from "axios";
import imgsearch from '../../../../../../../../src/images/landing_page_images/search.jpg'
import { IoIosArrowBack } from "react-icons/io";
import _ from "lodash";
import "./ShowSubjects.scss";
import { IoList } from "react-icons/io5";
import { FaGraduationCap, FaRupeeSign, FaShoppingCart, FaVideo } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { BsGrid3X3Gap } from "react-icons/bs";
import Paper from "@mui/material/Paper";
import Studentchatside from "./Subjects";
import { BsCashCoin } from "react-icons/bs";
import { TiContacts } from "react-icons/ti";
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Form, ErrorMessage, } from 'formik';
import {
  useWindowSize,

} from '@react-hook/window-size'
import { ActiveSubject } from "../../../../../../../redux/slice/studentSlice";
import {
  showErrorToast,
  showSuccessToast,
} from "./../../../../../../../util/toastUtils";
import { useDispatch, useSelector } from "react-redux";
import { CSTData, SubjectData, fetchSubjectList, getCSTData } from "../../../../../../../redux/slice/SubjectSlice";
import ReactDatePicker from "react-datepicker";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Confetti from 'react-confetti'
import StarRatings from "react-star-ratings";
import './ShowSubjects.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from "../../../../../../../BaseUrl";
import Axios from "../../../../../../../util/apiUtil";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function ShowSubjects({ selectedCategory }) {


  const [searchResults, setSearchResults] = useState([]);
  const [showView, setShowView] = useState(false);
  const dispatch = useDispatch();
  const liveClass = (item) => {
    dispatch(ActiveSubject(item.id))
    setShowView(item);
  };
  const navigate = useNavigate();
  const basePath = "http://tuitionguruji.com/backend/public/";
  const [subPath, setSubPath] = useState([{}]);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(true);

  const [ViewCourses, setViewCourses] = useState([
    {
      id: "",
      category: "",
      sub_category: "",
      subject: "",
      level: "",
      language: "",
      video_link: "",
      price: "",
      payment: "",
      currency: "",
      discount: "",
      description: "",
      image: "",
      created_at: "",
      updated_at: "",
      teacher_id: "",
      uploader_id: null,
    },
  ]);

  const data = useSelector(CSTData);

  useEffect(() => {
    const payload = {
      type: 1
    };
    dispatch(getCSTData(payload));
  }, []);


  console.log({ getCSTData });

  const categoryOptions = data?.map((e) => ({
    value: e.id,
    label: e.name
  }));


  const StuBackdashboard = () => {
    navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
  }

  const [searchQuery, setSearchQuery] = useState('');
  const gotosearch = async () => {
    setLoading(true);
    try {
      const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
      const response = await Axios.post('/api/globalSearch', { search: searchQuery }, { headers });

      if (response.status !== 200) {
        throw new Error('Search failed');
      }

      const result = response.data;
      setSearchResults(result.data.subject); // Update search results with the nested data
      setLoading(false);
    } catch (error) {
      console.error('Error during search:', error);
      setLoading(false);
    }
  };
  const [ViewCoursesDetail, setViewCoursesDetail] = useState({
    subject: {},
    teacherProfile: {},
  });
  console.log(ViewCoursesDetail);
  const [gridViewActive, setGridViewActive] = useState(true);

  const toggleViews = () => {
    setGridViewActive(!gridViewActive);
  };

  const getContainerClassName = () => {
    return gridViewActive ? "row grid-view" : "row list-view";
  };

  const getColumnClassName = () => {
    return gridViewActive ? "column grid-column" : "column list-column";
  };

  const Backdashboard = (event, forceful) => {
    if (showView && !forceful) {
      setShowView(false);
    } else {
      navigate("/student", {
        state: { type: "Student", subType: "Dashboard" },
      });
    }
  };
  const [initialSubjects, setInitialSubjects] = useState([]);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Backdashboard(false, true);
    } else {
      fetchInitialSubjects();
    }
  }, []);
  const fetchInitialSubjects = async () => {
    setLoading(true);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    try {
      const response = await Axios.post('/api/listSubject', undefined, { headers });
      const subjects = response.data.data;
      setInitialSubjects(subjects);
      setSearchResults(subjects);
      setLoading(false);
    } catch (error) {
      showErrorToast("Error fetching subjects:", error);
      setLoading(false);
    }
  };
  const [subject_id, setsubject_id] = useState();
  // useEffect(() => {
  //   StudentViewSubjects();
  // }, []);
  const getUserState = () => {
    return { selectedCourse: showView };
  };

  const [gridView, setGridView] = useState(true); // State to track the view mode true means => Grid

  // Toggle between List View and Grid View
  const toggleView = (flag) => {
    setGridView(flag);
  };

  const validationSchema = Yup.object({
    date: Yup.date().nullable().label('Date'),
    category: Yup.string().label('Category'),
    subCategory: Yup.string().label('Sub Category'),
    topic: Yup.string().label('Topic'),
  });

  const initialValues = {
    date: null,
    category: '',
    subCategory: '',
    topic: '',
  };
  const getSubjectName = (id) => {
    switch (id) {
      case 1:
        return "English";
      case 2:
        return "Hindi";
      case 3:
        return "Malayalam";
      case 4:
        return "Spanish";
      case 5:
        return "French";
      case 6:
        return "German";
      case 7:
        return "Chineese";
      default:
        return "English";
    }
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      onClick={onClick}
      className="form-control"
      value={value}
      ref={ref}
      placeholder=""
    />
  ));


  // countdown

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too late...</div>;
    }

    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;
   
    return (



      <div className="timer">
        <div className="text">Hurry Up</div>
        {/* <div className="text">Remaining</div> */}
        <div className="value">{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</div>
      </div>
    );
  };
  const { width, height } = useWindowSize()

  // subcategory
  const courselist = useSelector(SubjectData);
  console.log("www", courselist);

  useEffect(() => {
    dispatch(fetchSubjectList())

  }, []);

  const [amount, setAmount] = useState({})

  const fetchCouponCode = () => {
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
    const formData = new FormData();
    formData.append("subject_id", ViewCourses.id);
    formData.append("code", amount.code);

    Axios.post(
      '/api/checkCoponCodeAmount',
      formData,
      { headers: headers })
      .then((response) => {
        console.log(response);

      })
      .catch((error) => {
        console.error("Error fetching subject data:", error);
        // Handle the error here, such as displaying an error message to the user.
      });
  }


  const [filterText, setFilterText] = useState('');
  const [categoryData, setCategoryData] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filteredSubjects = ViewCourses.filter(subject => {
      return categoryData === '' || subject.category.toLowerCase() === categoryData.toLowerCase();
    });

    // Function to handle category selection
    const handleCategoryChange = (e) => {
      setCategoryData(e.target.value);

    };
    setFilteredData(filteredSubjects);
  }, [filterText, categoryData, ViewCourses])


  const [cartedCourses, setCartedCourses] = useState([]);
  const handleCartClick = (course) => {
    setCartedCourses([...cartedCourses, course]);
    toast.success(`Course has been added to the cart.`, {
      position: "top-center", // Set the position to top-center


    });
  };
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  useEffect(() => {
    if (searchQuery === '') {
      setFilteredSubjects(subjects); // Show all subjects if search query is empty
    } else {
      gotosearch(searchQuery); // Perform search if query is not empty
    }
  }, [searchQuery, subjects]);

  // const payment = _.get("selectedCourse.payment");
  // console.log("payment",payment);
  // const  plan = payment === "1" ? "Days" : "Months";
  return (
    <>


      <div className="main-divHead">
        {!showView && (
          <div className=" showsubject-container  p-0">
            <div
              className="profile-main-head d-flex py-2"
              onClick={() => StuBackdashboard()}
            >
              <div className="fa-chaveron">
                <IoIosArrowBack className="mb-1" />{" "}
              </div>
              <div className="profile-head">Profile</div>
            </div>
            <div className=" mt-5 ms-5">


              <Formik initialValues={initialValues} validationSchema={validationSchema}  >
                {({ touched, errors, handleSubmit, resetForm, isSubmitting, setFieldValue, values, handleChange, handleBlur }) => (
                  <>
                    <div className="row d-flex justify-content-between">
                      <div className="col-7">
                        <div class="container text-center">
                          <div class="row">
                            {/* <div class="col">
                              <div className="form-group p-md-0">
                                <label htmlFor="exampleDropdown" className="admin-cst-label mb-0">SORT BY :</label>
                                <ReactDatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={values?.date}
                                  onChange={(date) => setFieldValue('date', date)}
                                  customInput={<ExampleCustomInput />}
                                  className='admin-dropdown'
                                />
                              </div>
                            </div> */}


                            <Form >
                              <div className="row">
                                <div className="col-5 me-5 ">
                                  <div className='d-flex mb-0'>
                                    <input
                                      type="text"
                                      className="form-control rounded-pill"
                                      placeholder="search ... "
                                      value={searchQuery}
                                      onChange={(e) => setSearchQuery(e.target.value)}

                                    />
                                    {/* <button
                                      type="button"
                                      className="btn admin-navbar-search-btn text-light px-3 ms-3"
                                      onClick={gotosearch}
                                    >
                                      {loading ? 'Searching...' : 'Search'}
                                    </button> */}
                                  </div>
                                  {/* {searchResults.length > 0 && (
                                    <ul className="list-group position-absolute w-100 mt-2" style={{ zIndex: 1000 }}>
                                      {searchResults.map((result) => (
                                        <li key={result.id} className="list-group-item">
                                          {result.name}
                                        </li>
                                      ))}
                                    </ul>
                                  )} */}

                                </div>
                                {/* <div className="col-4 ms-5">
                                  <select
                                    name="category"
                                    className="form-select rounded-pill main-search-category "
                                    placeholder="Select Category"
                                    onChange={(e) => setCategoryData(e.target.value)}
                                    value={categoryData}
                                  >
                                    <option value="">Select Category</option>
                                    {categoryOptions?.map((option) => (
                                      <option key={option.value} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div> */}
                              </div>
                            </Form>
                            <div className="row grid-show">
                              <div class="col-1 ms-5">
                                <div className="list-btn ">
                                  <button
                                    className={`btn ${!gridView ? "active" : ""}`}
                                    onClick={() => toggleView(false)}
                                  >
                                    <BsGrid3X3Gap />
                                  </button>
                                </div>
                              </div>
                              <div className="col-1">
                                <div className="grid-btn">
                                  <button
                                    className={`btn ${gridView ? "active" : ""}`}
                                    onClick={() => toggleView(true)}
                                  >
                                    <IoList />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>)}
              </Formik>
            </div>

            {/* listview */}





            {gridView && (
              <div>

                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-primary px-4 py-1 pt-1 bootBtn purchase-view-btn me-5"
                    onClick={() => navigate("/cart")}
                  ><FaShoppingCart className="me-2" />
                    cart
                  </button>

                </div>
                {searchResults.length ? (
                  searchResults.map((item) => (
                    <div key={item.id} className="container text-start mt-3">
                      <div className="row">
                        <div className=" col-xxl-8 col-xl-12 col-lg-10 col-md-12 col-sm-12">
                          <div className="card border-0">
                            <div className="row">
                              <div className="col-2 ms-4 px-0">
                                <div className="container col-12 img-container-show">
                                  <img
                                    className=" mt-3"
                                    alt="API Image"
                                    src={item?.image ? `${basePath}${item.image}` : ''}
                                    
                                  />
                                </div>
                              </div>
                              <div className="col-7 ms-5">
                                <div className="row">
                                  <div className="col fw-bold fs-5 mt-3">
                                    <h3>{item?.name}</h3>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="row mt-3">
                                    <div className="col">
                                      <p className="mb-0 fs-6 fw-bold  col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <span className="me-1"><BsCashCoin className="student-icon-clr" /></span>
                                        Rs {item?.price}.00
                                      </p>
                                      <p className="mb-0 student-pgrh col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <span className="me-1"><FaGraduationCap className="student-icon-clr" /></span>
                                        Total Students: {item?.total_student}
                                      </p>
                                      <p className="mb-0 student-pgrh col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <span className="me-1"><TiContacts className="student-icon-clr" />{item?.category_name}</span>
                                      </p>
                                      <p className="mb-0 student-pgrh col-xxl-12 col-xl-12  col-lg-12 col-md-12 col-sm-12">
                                        <span className="me-1"><TiContacts className="student-icon-clr" />Medium: {item?.language}</span>
                                      </p>
                                      <p className="mb-0 student-pgrh col-xxl-12 col-xl-12  col-lg-12 col-md-12 col-sm-12 ">
                                        <span className="me-1 col-12"><TiContacts className="student-icon-clr" /> Duration: {item?.period} {item?.payment== 1?"days":"Months"}</span>
                                      </p>
                                      <p className="mb-0 student-pgrh col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12l-12 ">
                                        <span className="me-1"><TiContacts className="student-icon-clr" />Tutor: {item?.teacher}</span>
                                      </p>
                                      <p className="text-light fw-semibold fs-6 mt-5">{item?.teacher}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="ms-1">
                                      <div className="timer-wrapper">
                                        <CountdownCircleTimer
                                          isPlaying
                                          duration={120}
                                          colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                          colorsTime={[10, 6, 3, 0]}
                                          onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                                          size={85}
                                          strokeWidth={3}
                                        >
                                          {renderTime}
                                        </CountdownCircleTimer>
                                      </div>
                                      <div className="position-absolute top-0 end-0 mt-2 me-3">
                                        <FaShoppingCart className="cart-icon FaShoppingCart" onClick={() => handleCartClick(item)} />
                                      </div>
                                      <div className="col fw-bold fs-5 ms-5">
                                        <button
                                          type="button"
                                          className="btn-primary px-4 py-1 pt-2 bootBtn purchase-view-btn my-5"
                                          onClick={() => liveClass(item)}
                                        >
                                          View
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-1 d-flex justify-content-end">
                                <div className="card card-offer">
                                  <div className="card-body"></div>
                                  <p className="off-p vertical-text ms-3"><b>NOW {(((item.price - item.discount) / item.price) * 100).toFixed(2)}% OFF</b></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="container text-center mt-5 py-5">
                      <div className="card col-12 admin-card">
                        <div className="card-body">
                          <img src={imgsearch} style={{ width: '300px', height: '300px' }} />
                          <h2>Sorry!!</h2>
                          <p style={{ fontSize: '30px' }}>There is no course available now</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* gridview */}
            {!gridView && (
              <div>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-primary px-4 py-1 pt-1 bootBtn purchase-view-btn me-5"
                    onClick={() => navigate("/cart")}
                  ><FaShoppingCart className="me-2" />
                    cart
                  </button>

                </div>
                <div className={`grid grid-view d-flex-center-column`}>
                  <div className="row">
                    {searchResults.length ? (
                      searchResults.map((item) => (
                        <div key={item.id} className="col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                          <div className="container text-start m-3">
                            <div className="card ms-5  card-showsub " style={{ width: '400px', height: '460px' }}>
                              <div className=" position-absolute top-0 end-0 mt-2 me-3">
                                <FaShoppingCart className="cart-icon FaShoppingCart"
                                  onClick={() => handleCartClick(item)} />

                              </div>
                              {/* <div className="timer-wrapper d-flex justify-content-end mt-3">
                              <CountdownCircleTimer
                                isPlaying
                                duration={120}
                                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                colorsTime={[10, 6, 3, 0]}
                                onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                                size={85}
                                strokeWidth={3}
                              >
                                {renderTime}
                              </CountdownCircleTimer>
                            </div> */}

                              <p className="card-body fw-bold fs-5 d-flex just">{item?.name} </p>
                              <div className="col-7 ms-4 px-0">
                                <div>
                                  <div>
                                    {" "}
                                    <img
                                      className="img-fluid "
                                      alt="API Image"
                                      src={item?.image ? (basePath + item?.image) : ''}
                                      style={{ width: '400px', height: '180px' }}
                                    />
                                  </div>
                                  <div
                                    className=" text-center"
                                    style={{ background: "#5C5AB6", borderRadius: "0px" }}
                                  >
                                    <p className="text-light fw-semibold fs-6 d-flex justify-content-center">
                                      {item?.teacher}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <p className="mb-0 fw-bold">
                                {" "}
                                <span className="me-1">
                                  <BsCashCoin className="student-icon-clr" />{" "}
                                </span>
                                Rs{""}{item?.price}.00
                              </p>
                              <p className="mb-0 student-pgrh">
                                {" "}
                                <span className="me-1">
                                  <FaGraduationCap className="student-icon-clr" />
                                </span>
                                Total Students: {item?.total_student}
                              </p>
                              <p className="mb-0 student-pgrh">
                                <span className="me-1">
                                  {" "}
                                  <TiContacts className="student-icon-clr" />
                                </span>
                                {item?.category_name}
                              </p>
                              <p className="mb-0 student-pgrh">
                                <span className="me-1">
                                  <FaVideo className="student-icon-clr" />
                                </span>{" "}
                                Medium:{item?.language}
                              </p>
                              <p className="mb-0 student-pgrh">
                                <span className="me-1"><TiContacts className="student-icon-clr" />Course Duration:{item?.period} {""} Months</span>

                              </p>
                              <div className="text-center mt-2">
                                <div className="row">
                                  <div className="col">
                                    <button
                                      type="button"
                                      className="btn-primary px-4 py-1 pt-2 bootBtn purchase-view-btn me-5"
                                      onClick={() => liveClass(item)}
                                    >
                                      View
                                    </button>
                                  </div>
                                </div>

                              </div>

                            </div>

                          </div>

                        </div>
                      ))
                    ) : (
                      <div className="d-flex justify-content-center">
                        <div className="container text-center mt-5 py-5">
                          <div className="card col-12 admin-card">
                            <div className="card-body">
                              <img src={imgsearch} style={{ width: "300px", height: "300px" }} alt="No courses available" />
                              <h2>Sorry!!</h2>
                              <p>There are no courses available now.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

          </div >
        )}
      </div >
      {showView && (
        <div>
          {" "}
          <Studentchatside
            userState={getUserState()}
            Backdashboard={Backdashboard}
            ViewCoursesDetail={ViewCoursesDetail}
          ></Studentchatside>{" "}
        </div>
      )
      }
      {/* modal content */}
      {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">

          <div class="modal-content offer-modal">

            <div class="modal-body">
              <Confetti
                width={300}
                height={height}
              />
          
              <p className="d-flex justify-content-center offer-p1"><b>You Saved </b></p>
              <p className="d-flex justify-content-center"><FaRupeeSign />200</p>
              <p className="d-flex justify-content-center"> Congratulations</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ShowSubjects;