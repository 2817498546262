import React, { useState } from "react";
import ChooseWhoYouAre from "./ChooseWhoYouAre/ChooseWhoYouAre";
import student from "../../../images/landing_page_images/student.png";
import teacher from "../../../images/landing_page_images/teachericon.png";

function SelectWho() {
  const [profession, setProfession] = useState(null);

  // Function to handle profession selection
  const handleSelect = (professionValue) => {
    setProfession(professionValue);
  };

  return (
    <div className="">
      <div className="container text-center">
        <div className="row">
          <div className="d-flex justify-content-center gap-5">
            <div className="col-3 mt-5">
              <ChooseWhoYouAre
                img={student}
                context="Student"
                profession={profession} // Pass the profession state as a prop
                onClick={() => handleSelect(2)} // Set profession as 2 for students
              />
            </div>
            <div className="col-3 mt-5">
              <ChooseWhoYouAre
                img={teacher}
                context="I'm a Teacher"
                profession={profession} // Pass the profession state as a prop
                onClick={() => handleSelect(1)} // Set profession as 1 for teachers
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SelectWho;
