import React from 'react'
import assignmentWhiteIcon from "../../../icons/assignmentWhiteIcon.png"
import assignmentBlueIcon from "../../../icons/assignmentBlueIcon.png"
import _ from "lodash";
import { BiMessageAltDetail } from 'react-icons/bi';

function MessagesComponent(props) {
    const goToTeacherChat = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminMessages' })
        }
    }
    return (
        <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'adminMessages' ? 'active' : ''} `} onClick={(e) => goToTeacherChat(e)}>
            {/* <img
                src={_.get(props, 'userState.subType') != 'adminMessages' ? assignmentWhiteIcon : assignmentBlueIcon}
                alt='Chats'
            /> */}
            <BiMessageAltDetail class='ms-3'/>
            <p className='head-assignment ms-2' >Message</p>
            {/* */}
        </div>
    )
}

export default MessagesComponent