import React, { useState } from "react"
import membershipWhiteIcon from "../../../icons/membershipWhiteIcon.png"
import membershipBlueIcon from "../../../icons/membershipBlueIcon.png"
import _ from 'lodash';


function Wallet({ ...props }) {
  const [hoverEffect, setHoverEffect] = useState(false)
  const goToWalletCours = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'walletinstrcourse' })
    }
  }
  return (
    <div
      className={`sidebar_content ${_.get(props, 'userState.subType') == 'walletinstrcourse' ? 'active' : ''} `}
    >
      <img
        src={_.get(props, 'userState.subType') != 'walletinstrcourse' ? membershipWhiteIcon : membershipBlueIcon}
        alt='Membership'
      />
      <p className='side_heading' onClick={(e) => goToWalletCours(e)}>Wallet</p>
    </div>
  )
}

export default Wallet
