import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { store } from "./redux/store.js"
import { Provider } from "react-redux"
import ErrorBoundary from './ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from "./AuthContext.js"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ErrorBoundary>
          <AuthProvider>
          <App />
          </AuthProvider>
          <ToastContainer />
        </ErrorBoundary>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
