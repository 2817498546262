import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import axios from 'axios'
import DataTable from 'react-data-table-component';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function SubjectStudentDetails(props) {
    const [view, setView] = useState([{}])
    const ViewBatch = props.userState.row.id || {};
    
    const [viewSub, setViewSub] = useState([{
        email: '',
        category: '',
        sub_category: '',
        subject: "",
        price: '',
        subject_id: '',
    }])


    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    
    const columns = [
        {
            name: 'Student Id',
            selector: row => row.student_id,

        },
        {
            name: 'Student Name',
            selector: row => row.firstname,
            sortable: true

        },
    ]

    const fetchSubject = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const formData = new FormData();
        formData.append("subject_id", ViewBatch); // Remove the square brackets and just append the single value

        Axios.post('/api/listStudent',
            formData,
            { headers: headers })
            .then((response) => {
                console.log(response);
                const updateData = response.data.data;
                setView(updateData);
            })
            .catch((error) => {
                console.error("Error fetching subject data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });
    }

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])

    const initializeObject = () => {
        setViewSub({ ...viewSub, ...props.userState.row })
    }

    useEffect(() => {
        fetchSubject();
    }, []);


    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachersubject' })
        }
    }


    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} onClick={Backdashboard} />
                <div className='Sub-Head2'>Course</div>
            </div>

            <div className='Main-head-viewschedule'>Student List</div>
            <div class="container text-start">
                <div class="card col-8 ms-5 mt-3">
                    <div class="card-body ">

                    <div class="row mt-5">
                            <div className='d-flex justify-content-center'>
                                <div class="col-4">
                                    <p className='techer-card-typo'>Course Name : </p>
                                </div>
                                <div class="col-4">
                                    <p className='techer-card-typo'>{viewSub.name} </p>

                                </div>
                            </div>
                        </div>


                        {/* <div class="row mt-5">
                            <div className='d-flex justify-content-center'>
                                <div class="col-4">
                                    <p className='techer-card-typo'>Category : </p>
                                </div>
                                <div class="col-4">
                                    <p className='techer-card-typo'>{viewSub.category} </p>

                                </div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div className='d-flex justify-content-center'>
                                <div class="col-4">
                                    <p className='techer-card-typo'>Sub Category : </p>
                                </div>
                                <div class="col-4">
                                    <p className='techer-card-typo'>{viewSub.sub_category} </p>

                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div className='d-flex justify-content-center'>
                                <div class="col-4">
                                    <p className='techer-card-typo'>Topic : </p>
                                </div>
                                <div class="col-4">
                                    <p className='techer-card-typo'>{viewSub.subject}</p>
                                </div>
                            </div>
                        </div> */}

                        <div class="row mt-5">
                            <div className='d-flex justify-content-center'>
                                <div class="col-4">
                                    <p className='techer-card-typo'>Price : </p>
                                </div>
                                <div class="col-4">
                                    <p className='techer-card-typo'>Rs{" "}{viewSub.price}.00</p>
                                </div>
                            </div>
                        </div>


                        <div class="row mt-5">
                            <div className='d-flex justify-content-center'>
                                <div class="col-4">
                                    <p className='techer-card-typo'>Language : </p>
                                </div>
                                <div class="col-4">
                                    <p className='techer-card-typo'>{viewSub.language}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-8 ms-5 justify-content-center">

                <DataTable
                    className='dataTable'
                    data={view?.student_list || []} // Use optional chaining and provide a default empty array if view is null
                    columns={columns}
                    customStyles={customStyles}
                    striped
                    persistTableHead
                    pagination
                    paginationPerPage={10}
                />

            </div>


        </div>

    )
}

export default SubjectStudentDetails