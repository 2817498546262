import React from 'react'
import assignmentWhiteIcon from "../../../icons/assignmentWhiteIcon.png"
import assignmentBlueIcon from "../../../icons/assignmentBlueIcon.png"
import _ from "lodash";
import { TbReportMedical } from 'react-icons/tb';


function ReportsComponent(props) {
    const goToTTeacherWallet = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminReport' })
        }
    }
    return (
        <div
            className={`sidebar_content ${_.get(props, 'userState.subType') == 'adminReport' ? 'active' : ''} `}
            onClick={(e) => goToTTeacherWallet(e)}>
            <div className="dash d-flex">
            <TbReportMedical class='ms-3'/>
                <p className='head-dashboard ms-2' onClick={(e) => goToTTeacherWallet(e)}> Reports
                </p>
            </div>
        </div>
    )
}

export default ReportsComponent