import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, subjectLoading } from '../../../../../../redux/slice/SubjectSlice';
import { RotatingLines } from 'react-loader-spinner';
import { AiOutlineLeft } from 'react-icons/ai';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";
import './addCategory.css'
import img from '../../../../../../images/tick.jpg'

function AddCategoryComponent(props) {
    const [addAnother, setAddAnother] = useState(false); // State to track if the user wants to add another category
    const [formSubmitted, setFormSubmitted] = useState(false); // State to track if the form has been submitted successfully
    const validationSchema = Yup.object({
        category: Yup.string().required('category is required'),
    });
    const dispatch = useDispatch();
    const loading = useSelector(subjectLoading);
    const navigate = useNavigate();

    const goToSubjectTeacher = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'AdminSubject' });
        }
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const payload = {
            "name": values.category
        };

        try {
            // Dispatch the addCategory action and wait for the response
            await dispatch(addCategory(payload));

            // If successful, reset the form, show the confirmation message, and hide the save button
            resetForm();
            setSubmitting(false);
            setAddAnother(true); // Show the confirmation message
            setFormSubmitted(true); // Hide the save button
        } catch (error) {
            // If there's an error, handle it appropriately
            console.error("Add Category Failed:", error);
            // You can choose to show a different type of toast here if needed
        }
    };

    const handleAddAnother = () => {
        setAddAnother(false); // Hide the confirmation message
        setFormSubmitted(false); // Show the save button
    };

    return (
        <div>
            <div className="container ">
                <div className="row">
                    <div className="">
                        <div className="">
                            <div className="main-divHead ">
                                <div className="sub-Head1">
                                    <div className="Sub-Head2" onClick={goToSubjectTeacher}><span><AiOutlineLeft className='mb-1 me-2' /></span>Category</div>
                                </div>
                                <div className="p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-2">
                                    {/* <h4 className="fw-bold">Add Category</h4> */}
                                    <Formik
                                        initialValues={{ category: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                {!addAnother && (
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center mb-3">
                                                                <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 ">
                                                                    <label className="admin-label mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-4">Category</label>
                                                                    <Field
                                                                        type="text"
                                                                        name="category"
                                                                        className="form-control admin-input"
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="container text-center">
                                                    <div className="row">
                                                        <div className="col">
                                                            {!formSubmitted && ( // Render the save button only if the form has not been submitted
                                                                <button
                                                                    className="btn btn-primary text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                                                                    type="submit"
                                                                    disabled={isSubmitting}>
                                                                    {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                                                                        strokeColor="white"
                                                                        strokeWidth="5"
                                                                        animationDuration="0.75"
                                                                        width="25"
                                                                        height="25"
                                                                        visible={true}
                                                                    /></div> : "Save"}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {addAnother && (
                                                        <div className="row mt-3 card bordr-0 ">
                                                            <div className="col ">
                                                                <div className=''>
                                                                <h2 className=''> Category Added Successfully! </h2>
                                                                <img src={img} alt="" className='img-fluid' style={{width:"170px" ,height:"170px"}}/>
                                                               <p>Do you want to add one more Category
                                                               </p>
                                                                <button onClick={handleAddAnother} className="btn btn-primary mx-2">yes</button>
                                                                <button type="submit" className="btn btn-primary mx-2">No</button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCategoryComponent;
