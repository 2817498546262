import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import './ViewSchedule.scss';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function ViewSchedule(props) {
    const [rows, setRows] = useState()
    const ViewBatch = props.userState.row.batch_id || {};
    

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const formatTime = (dateTimeString) => {
        if (!dateTimeString || typeof dateTimeString !== 'string') {
            return ''; // Return an empty string if dateTimeString is not a valid string
        }

        const dateTime = new Date(dateTimeString);
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };

        return dateTime.toLocaleTimeString(undefined, options);
    };



    const [scheduleValue, setScheduleValue] = useState([{

    }])

    const fetchSchedule = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        // const requestData = {
        //     batch_id: '',
        //     date: '',
        // };
        const formData = new FormData();
        formData.append("batch_id", ViewBatch)
        Axios.post('/api/viewSchedule', formData,
            { headers: headers })
            .then((response) => {
                console.log(response)
                const updateData = response.data.data
                setScheduleValue(updateData)
            })
            .catch((error) => {
                console.error("Error fetching view schedule data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });
    }
    useEffect(() => {
        // Fetch data and get rows after the component mounts
        fetchSchedule();
    }, []);
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])
    const initializeObject = () => {
        setScheduleValue({ ...scheduleValue, ...props.userState.row })
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'student ID',
            selector: row => row.student_id,
            sortable: true,

        },
        {
            name: 'student Name',
            selector: row => row.firstname,
            sortable: true,
        },

    ]



    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherschedule' })
        }
    }


    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Schedule</div>
            </div>
            <div className='Main-head-viewschedule'>View Schedule</div>




            <div class="container text-start">
                <div class="row mt-5">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{scheduleValue.category}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Sub Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{scheduleValue.sub_category}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Topic : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{scheduleValue.topic}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Batch : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{scheduleValue.batch_id}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Total student : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{scheduleValue.totalStudents}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Mesage for students : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{scheduleValue.message}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Scheduled Date : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{formatDate(scheduleValue.from)}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Time (from) : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>{formatTime(scheduleValue.from)}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Time (to) : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>{formatTime(scheduleValue.to)}</p>
                        </div>
                    </div>
                </div>

                <div class="row ">

                    <div class="d-flex justify-content-center">
                        <div class="col-xxl-8   col-xl-10 col-lg-12   justify-content-center">
                            <div class="table-responsive">
                                {_.get(scheduleValue, 'students') && (
                                    <DataTable
                                        className='dataTable'
                                        data={scheduleValue.students}
                                        columns={columns}
                                        customStyles={customStyles}
                                        striped
                                        persistTableHead
                                        pagination
                                        paginationPerPage={10}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ViewSchedule