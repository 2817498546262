import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { ActiveFreelancerID, fetchFreelancerList, freelanceList, freelanceLoading } from '../../../../../../redux/slice/freelanceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import CSTComponent from './CST.component';
import { RotatingLines } from 'react-loader-spinner';


function AdminFreelancerComponent(props) {

    const dispatch = useDispatch();
    const freelancerList = useSelector(freelanceList);
    const loading = useSelector(freelanceLoading);

    useEffect(() => {
        dispatch(fetchFreelancerList());
    }, []);

    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const columns = [
        {
            name: 'Date',
            selector: row => row.created_at,
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.created_at ? dateFunction(row.created_at) : ""}
                    </div>
                );
            }
        },
        {
            name: 'Freelancers',
            selector: row => row.firstname,
            sortable: true
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.status === "1" ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}
                    </div>
                )
            }
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <button type="button" class="btn text-light px-3 py-1 bootBtn" onClick={() => handleClick(row)} >Details</button>
                </div>
            )
        }
    ];

    function handleClick(row) {
        goToAdminFreelancerDetails();
        dispatch(ActiveFreelancerID(row.id));
    }


    const goToAdminFreelancerDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminFreelancerDetails' })
        }
    }


    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Freelancers</div>
            </div>
            <div>
                <div class="container text-start my-5">
                    <div class="row mt-5">
                        <CSTComponent />
                    </div>
                    <div class="row ">
                        {loading ? <div className='d-flex justify-content-center' style={{ height: '40vh' }}><RotatingLines
                            strokeColor="#5C5AB6"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            height="50"
                            visible={true}
                        /></div> :
                            <DataTable
                                className='dataTable mt-5 '
                                data={freelancerList}
                                columns={columns}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                striped
                                customStyles={customStyles}
                                persistTableHead
                                pagination
                            />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminFreelancerComponent