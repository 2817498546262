// counterSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from '../../util/apiUtil';
import { showErrorToast, showSuccessToast } from '../../util/toastUtils';


export const fetchStudentList = createAsyncThunk('student/fetchStudentList', async (payload) => {
    try {
        const response = await Axios.post('/api/listStudentsAdmin', payload);
        return response?.data?.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const viewStudent = createAsyncThunk('student/viewStudent', async (payload) => {
    try {
        const response = await Axios.post('/api/viewStudent', payload);
        return response?.data?.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const getCategoryListData = createAsyncThunk('student/getCategoryListData', async (payload) => {
    try {
        const response = await Axios.post('/api/listCategory', payload);
        return response?.data?.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});


export const changeStudent = createAsyncThunk('student/changeStudent', async (payload, thunkApi) => {

    try {
        const response = await Axios.post('/api/changeStatusStudent', payload.payload);
        showSuccessToast("change status successfully");
        await thunkApi.dispatch(viewStudent(payload.viewStudent));
        return response?.data?.data;
    } catch (error) {
        showErrorToast("change status Failed");
        console.log("axios error", error);
        throw error;
    }
});

export const viewTest = createAsyncThunk('student/viewTest', async (payload, thunkApi) => {
    try {
        const response = await Axios.post(`api/viewTestStudent?question_id=${payload.id}`,);
        return response?.data?.data;
    } catch (error) {
        showErrorToast("change status Failed");
        console.log("axios error", error);
        throw error;
    }
});


export const viewSubject = createAsyncThunk('student/viewSubject', async (payload, thunkApi) => {
    try {
        const response = await Axios.post('api/viewSubject', payload);
        return response?.data?.data;
    } catch (error) {
        showErrorToast("change status Failed");
        console.log("axios error", error);
        throw error;
    }
});


export const listCountries = createAsyncThunk('student/listCountries', async () => {
    try {
        const response = await Axios.post('/api/listCountries',);
        console.log("response",response);
        return response?.data?.data;
     
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const listStates = createAsyncThunk('student/listStates', async () => {
    try {
        const response = await Axios.post('/api/getStates',);
        console.log("response",response);
        return response?.data?.data;
     
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});
export const getLanguages = createAsyncThunk('student/getLanguages', async () => {
    try {
        const response = await Axios.post('/api/getLanguages',);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});




export const updateProfile = createAsyncThunk('student/updateProfile', async (formData) => {
    try {
        console.log("Initial formData:", formData);
        const headers = { 
            'Authorization': 'Bearer ' + (localStorage.getItem('token')),
            'Content-Type': 'multipart/form-data'
        };

        const response = await Axios.post('/api/updateProfile', formData, { headers });

        console.log("Server response:", response); // Log the whole response object

        if (response.data.success) {
            console.log("FormData after successful update:", formData);
            showSuccessToast("Waiting for Admin Approval");
        } else {
            console.log("Response data on failure:", response.data); // Log response data when success is false

            // If success is false and data is null, show an error toast
            if (!response.data.data) {
                showErrorToast("Update Profile Failed");
            } else {
                // If data exists and success is false, show error message from response in toast
                showErrorToast(response.data.message || "Update Profile Failed");
            }
        }

        return response.data.data;
    } catch (error) {
        showErrorToast("All fields are required");
        console.error("Axios error:", error.response); // Log error.response for detailed error info
        throw error;
    }
});




export const getUserProfile = createAsyncThunk('student/getUserProfile', async () => {
    try {
        const response = await Axios.post('/api/getUserProfile',);
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});

export const CreateQuotationTuition = createAsyncThunk('student/CreateQuotationTuition', async (payload, thunkApi) => {
    try {
        const response = await Axios.post('/api/createQuotationTuition', payload);
        if (response.data.success) {
            showSuccessToast("website Settings Successfully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast(" all field is required")
        console.log("axios error", error);
        throw error;
    }
});
export const studentSlice = createSlice({
    name: 'student',
    initialState: {
        isLoading: false,
        isStatusLoading: false,
        student: [],
        activeStudent: [],
        ViewStudent: [],
        categoryList: [],
        viewData: [],
        listCountries: [],
        listStates: [],
        getLanguages: [],
        subjectData: [],
        currentSubject: [],
        getUserProfile: [],
        updateProfile: []
    },

    reducers: {
        ActiveStudent: (state, action) => {
            return {
                ...state,
                activeStudent: action.payload
            }
        },
        ActiveSubject: (state, action) => {
            return {
                ...state,
                currentSubject: action.payload
            }
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchStudentList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchStudentList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.student = action.payload;
            })
            .addCase(fetchStudentList.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(changeStudent.pending, (state) => {
                state.isStatusLoading = true;
            })
            .addCase(changeStudent.fulfilled, (state, action) => {
                state.isStatusLoading = false;
            })
            .addCase(changeStudent.rejected, (state) => {
                state.isStatusLoading = false;
            })
            .addCase(viewStudent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(viewStudent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.ViewStudent = action.payload;
            })
            .addCase(viewStudent.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getCategoryListData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCategoryListData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.categoryList = action.payload;
            })
            .addCase(getCategoryListData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(viewTest.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(viewTest.fulfilled, (state, action) => {
                state.isLoading = false;
                state.viewData = action.payload;
            })
            .addCase(viewTest.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(listCountries.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(listCountries.fulfilled, (state, action) => {
                state.isLoading = false;
                state.listCountries = action.payload;
            })
            .addCase(listCountries.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(listStates.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(listStates.fulfilled, (state, action) => {
                state.isLoading = false;
                state.listStates = action.payload;
            })
            .addCase(listStates.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getLanguages.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLanguages.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getLanguages = action.payload;
            })
            .addCase(getLanguages.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(updateProfile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateProfile.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(viewSubject.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(viewSubject.fulfilled, (state, action) => {
                state.isLoading = false;
                state.subjectData = action.payload;
            })
            .addCase(viewSubject.rejected, (state) => {
                state.isLoading = false;
            })

            .addCase(getUserProfile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserProfile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getUserProfile = action.payload;
            })
            .addCase(getUserProfile.rejected, (state) => {
                state.isLoading = false;
            })

            .addCase(CreateQuotationTuition.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(CreateQuotationTuition.fulfilled, (state, action) => {
                console.log("action", action.payload);
                {
                    state.isLoading = false;
                }
            })
            .addCase(CreateQuotationTuition.rejected, (state) => {
                state.isLoading = false;
            })


    },
});


export const { ActiveStudent, ActiveSubject } = studentSlice.actions;


export const studentData = (state) => state?.student?.student;
export const CurrentStudent = (state) => state.student.activeStudent;
export const viewStudentData = (state) => state.student.ViewStudent;
export const categoryListData = (state) => state.student.categoryList;
export const studentLoading = (state) => state.student.isLoading;
export const studentStatusLoading = (state) => state.student.isStatusLoading;
export const viewTestData = (state) => state.student.viewData;
export const listCountriesData = (state) => state.student.listCountries;
export const listStatesData = (state) => state.student.listStates;
export const getLanguagesData = (state) => state.student.getLanguages;
export const userProfileData = (state) => state.student.updateProfile;
export const ProfileData = (state) => state.student.getUserProfile;
export const viewSubjectData = (state) => state.student.subjectData;
export const activeSubjectId = (state) => state.student.currentSubject;
export default studentSlice.reducer;

