import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { GrFormAdd } from 'react-icons/gr';
import { MdDelete } from 'react-icons/md';
import img from '../../../../../../images/Ellipse 797.png'

function AddSectionComponent() {

    const [containers, setContainers] = useState([{ id: 1 }]);
    const [Content, setContent] = useState(false);
    const [Assignment, setAssignment] = useState(false);
    const [Resources, setResources] = useState(false);


    const addContainer = () => {
        const newContainer = { id: containers.length + 1 };
        setContainers([...containers, newContainer]);
    };

    const removeContainer = (id) => {
        const updatedContainers = containers.filter((container) => container.id !== id);
        setContainers(updatedContainers);
    };


    return (
        <div>
            <div className='main-divHead' >
                <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Course Upload</div>
                </div>
                <h5 className='fw-bold m-4'>Upload Video</h5>
                <div>
                    {containers.map((container) => (
                        <div className="container text-start mt-5" key={container.id}>
                            <div class="container text-start">
                                <div className='mx-5'>
                                    <div class="row">
                                        <div className='d-flex'>
                                            <div class="col-11">
                                                <button type="button" className="btn btn-primary px-4 py-1 bootBtn-section mt-1" >Section : {container.id} </button>
                                            </div>
                                            <div>
                                                {containers.length > 1 && (
                                                    <img src={img} class="img-fluid rounded-top " alt="..." onClick={() => removeContainer(container.id)} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-11">
                                            <div className='admin-card-section p-5'>
                                                <div className='d-flex'>
                                                    <label className='fw-bold mt-1'>Title </label>
                                                    <input type="text" class="form-control ms-3" />
                                                </div>
                                                {Content && <div>
                                                    <label className='fw-bold mt-4'>Upload Video</label>
                                                    <div class="input-group mb-3">
                                                        <input type="file" class="form-control" aria-label="Upload a file" aria-describedby="basic-addon2" />
                                                        <span class="input-group-text chooseFile-uploader" id="basic-addon2">Choose File</span>
                                                    </div>
                                                    <hr className='my-5  admin-horizontal-line'></hr>
                                                </div>}
                                                {Assignment && <div>
                                                    <h4 className='fw-bold'>Assignment :</h4>
                                                    <div className='d-flex mt-4'>
                                                        <label className='fw-bold mt-1'>Assignment Title  </label>
                                                        <input type="text" class="form-control ms-3" />
                                                    </div>
                                                    <div className='d-flex mt-4'>
                                                        <label className='fw-bold mt-1'>Description  </label>
                                                        <textarea class="form-control ms-3" placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>
                                                    </div>
                                                    <label className='fw-bold mt-4'>Upload Video</label>
                                                    <div class="input-group mb-3">
                                                        <input type="file" class="form-control" aria-label="Upload a file" aria-describedby="basic-addon2" />
                                                        <span class="input-group-text chooseFile-uploader" id="basic-addon2">Choose File</span>
                                                    </div>
                                                    <hr className='my-5  admin-horizontal-line'></hr>
                                                </div>}
                                                {Resources && <div>
                                                    <h4 className='fw-bold'>Resources :</h4>
                                                    <label className='fw-bold mt-4'>Upload Video</label>
                                                    <div class="input-group mb-3">
                                                        <input type="file" class="form-control" aria-label="Upload a file" aria-describedby="basic-addon2" />
                                                        <span class="input-group-text chooseFile-uploader" id="basic-addon2">Choose File</span>
                                                    </div>
                                                    <label className='fw-bold mt-4'>URL</label>
                                                    <input class="form-control" placeholder='https:' />
                                                </div>}
                                                <div className='d-flex gap-5'>
                                                    {!Content && <button onClick={() => setContent(true)} className='my-5 px-3 py-1 addButton-upload'><span className='addButton-upload-text'><span><GrFormAdd /></span> Content </span></button>}
                                                    {!Assignment && <button onClick={() => setAssignment(true)} className='my-5 px-3 py-1 addButton-upload'><span className='addButton-upload-text'><span><GrFormAdd /></span> Assignment </span></button>}
                                                    {!Resources && <button onClick={() => setResources(true)} className='my-5 px-3 py-1 addButton-upload'><span className='addButton-upload-text'><span><GrFormAdd /></span> Resources </span></button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <button type="button" className="btn btn-primary px-4 py-1 bootBtn m-5" onClick={addContainer} >Add Section </button>
                </div>
                <div className="container text-end me-5">
                    <div className="row">
                        <div className="col-11">
                            <button
                                type="submit"
                                className="btn btn-primary px-4 py-1 bootBtn my-5"
                            >
                                Publish
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddSectionComponent