import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import { currentUploaderId, uploaderData, viewUploaderData } from '../../../../../../redux/slice/freelanceSlice'
import { format } from 'date-fns'
import { AiOutlineLeft } from 'react-icons/ai'

function InstructorDetailsComponent(props) {

    const dispatch = useDispatch();
    const uploaderID = useSelector(currentUploaderId)
    const Data = useSelector(viewUploaderData)
    const date = Data?.created_at != undefined ? format(new Date(Data?.created_at), 'dd-MM-yyyy') : "";

    useEffect(() => {
        const payload = {
            "uploaderID": uploaderID
        }
        dispatch(uploaderData(payload));
    }, []);

    const goToRecordVideo = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminInstructorVideo' })
        }
    }

    const goToDocument = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminInstructorDocument' })
        }
    }

    const goToTTeacherWallet = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminInsUpload' })
        }
    }

    return (
        <div class='main-divHead'>
            <div className='sub-Head1' >
                <div className="Sub-Head2" onClick={(e) => goToTTeacherWallet(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>Instructor</div>
            </div>
            <div class='mx-5'>
                <div class="container text-start">
                    <div className='row'>
                        <div class="d-flex justify-content-center"><div className='col-xxl-8 col-xl-8 col-lg-11'>
                            <p class='admin-sub-head my-5 '>Instructor Details</p>
                        </div></div>
                    </div>
                    <div class="row">
                        <div class='d-flex justify-content-center'>
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-10">
                                <div class=' adminSubCard p-3'>
                                    <div class="container text-start">
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Date : </p>
                                                </div>
                                                <div class="col-xxl-5 col-xl-5 col-xl-lg-5 col-md-3 col-sm-3">
                                                    <p class='admin-font-message'>{date}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row  ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Instructor Name : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-font-message'>{Data?.firstname}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row  ms-3 ">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Email : </p>
                                                </div>
                                                <div class="col-5 overflow-y-auto">
                                                    <p class='admin-font-message'>{Data?.email}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Phone : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-font-message'>{Data?.phone_number}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Country : </p>
                                                </div>
                                                <div class="col-5">
                                                    <span class=''> <p class='admin-font-message'>{Data?.country}</p></span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fw-semibold fs-5'>Category :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <span class=''> <p class='fw-semibold fs-5'>category</p></span>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fw-semibold fs-5'>Sub Category :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <span class=''> <p class='fw-semibold fs-5'>subcategory</p></span>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fw-semibold fs-5'>Topic :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='fw-semibold fs-5'><span class=''>topic</span></p>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Teaching Language : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-font-message'><span class=''>English</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Teaching Experience : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-font-message'><span class=''>{Data?.experience}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Qualification :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-font-message'><span class=''>{Data?.qualification}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Status : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-font-message'>{Data?.status === "1" ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>INactive</span>}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Supporting Documents :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-font-message'>{Data?.doc_status === "1" ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>INactive</span>} <span><button onClick={(e) => goToDocument()} type="button" class="btn btn-outline adminOPbtn ms-xxl-4 ms-xl-4 ms-lg-2 px-xxl-4 px-xl-4 px-lg-2" >View <span class='ms-2'><FaArrowRightLong /></span></button></span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-font-message'>Recorded Videos :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-font-message'>{Data?.doc_status === "1" ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>INactive</span>} <span><button onClick={(e) => goToRecordVideo()} type="button" class="btn btn-outline adminOPbtn ms-xxl-4 ms-xl-4 ms-lg-2 px-xxl-4 px-xl-4 px-lg-2" >View <span class='ms-2'><FaArrowRightLong /></span></button></span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row my-4 ms-lg-5 ms-xxl-0 ms-xl-0 ps-lg-5 ps-xxl-0 ps-xl-0">
                            <div class='d-flex justify-content-end '>
                                <div class='col-xxl-1 col-xl-1 col-lg-2 mt-1 '>
                                    <p className='admin-sub-head-card mt-1'>Status :</p> </div>
                                <div class='col-xxl-3 col-xl-3 col-lg-5'>
                                    <div className="form-group ">
                                        <select className="form-select" id="exampleDropdown">
                                            <option value="Active">Active</option>
                                            <option value="option1">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                                <div class='col-1'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container text-center">
                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn text-light px-5 py-1 bootBtn my-5" >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstructorDetailsComponent