import React from "react"
import Announcement from "./components/Announcement"
import CourseUpload from "./components/CourseUpload"
import Student from "./components/Student"
import WalletCourse from "./components/WalletCourse"
import ChatCourse from './components/ChatCourse'
import DashboardCourse from "./components/DashboardCourses"
import NotificationUpload from "./components/NotificationUpload"

function CourseUploadMain({ ...props }) {

  return (
    <div>
      <DashboardCourse {...props} />
      <Announcement {...props} />
      <CourseUpload  {...props} />
      <Student {...props} />
      <WalletCourse {...props} />
      <ChatCourse {...props} />
      <NotificationUpload {...props} />
    </div>

  )
}

export default CourseUploadMain
