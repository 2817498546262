import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  enrolledCourses: false
}

export const reducer = createSlice({
  name: "pageDisplay",
  initialState,
  reducers: {
    enrolledCourses: (state) => {
      console.log(state.enrolledCourses)
      state.enrolledCourses = true
    }
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, enrolledCourses } = reducer.actions

export default reducer.reducer
