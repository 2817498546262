import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import Chat from "../../adminPage/chat";
function Chats({ ...props }) {
  const navigate = useNavigate();
  const [view, setView] = useState(false);
  const [loader, setLoader] = useState(true);
  const Backdashboard = () => {
    if (_.get(props, "setUserState")) {
      props.setUserState({ ...props.userState, subType: "Dashboard" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);


  return (
    <div className='main-divHead'>
      <div className='sub-Head1' onClick={Backdashboard}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <div className='Sub-Head2'>Chats</div>
      </div>
      <Chat />
    </div>
  );
}

export default Chats;
