import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserMail, currentEmail, studentRegistration } from "../../../../redux/slice/AuthSlice";
import { showErrorToast, showSuccessToast } from "../../../../util/toastUtils";
import _ from "lodash";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { subjectLoading } from "../../../../redux/slice/SubjectSlice";
import Axios from "../../../../util/apiUtil";




function ChooseWhoYouAre({ profession, ...props }) {
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+}{"':;?/>.<,])(?=.{7,})/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 7 characters long"
      ),
    confirm_pass: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    education: Yup.string().required("Education is required"),
    email: Yup.string().required("Email is required"),
    other_relevant_courses: Yup.string().required("other_relevant_courses is required"),
    teaching_background: Yup.string().required("teaching_background is required"),
    document: Yup.mixed().required("Document is required"),
  });
  console.log("loaded");

  const mail = useSelector(UserMail);
  console.log("mail", mail);
  const initialValues = {
    firstname: "",
    lastname: "",
    password: "",
    confirm_pass: "",
    email: "",
    professional: "",
    education: "",
    other_relevant_courses: "",
    teaching_background: "",
    document: ""
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(subjectLoading);

  const ToSubmitteacher = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    formData.append('firstname', values.firstname)
    formData.append('lastname', values.lastname)
    formData.append('password', values.password)
    formData.append('confirm_pass', values.confirm_pass)
    formData.append('email', mail.email)
    formData.append('professional', 1)

    Axios.post('/api/login',
      formData,
    )
      .then((res) => {
        if (res.data.success) {

          resetForm();
          showSuccessToast("Account details Added Successfully");
        }
        else {
          showErrorToast("Sub topic is not added")
        }
      })
      .catch((error) => {

        console.error('Error fetching Coupons:', error)
      })
      .finally(() => {
        setSubmitting(false); // Ensure setSubmitting is called in any case
      });
  };
  const handleSubmit = async (values) => {
    try {
      console.log("Submitting form with values:", values);
      const payload = {
        firstname: values.firstname,
        lastname: values.lastname,
        password: values.password,
        confirm_pass: values.confirm_pass,
        email: mail.email,
        professional: values.professional
      };

      console.log("Payload sent to studentRegistration action:", payload);

      const response = await dispatch(studentRegistration(payload));

      if (response.payload) {
     
        showSuccessToast("Register Successfully");
      
      } else {
        showErrorToast(response.error.message);
      }

      console.log("Registration response:", response);
    } catch (error) {
      console.error("Error during registration:", error);
    } finally {
      console.log("Submission finished.");
    }
  };






  // const handleIdentityClick = () => {
  //   navigate("/user/sign_in");
  // };
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
  
    >
      {({ errors, touched }) => (

        <Form>
          <div className='identity_container'
            data-bs-toggle="modal" data-bs-target={props.context === "Student" ? "#studentModal" : "#teacherModal"}
          >
            <img src={props.img} alt={props.context} />
            <h3>
              {props.context === "Student" ? (
                <div className='multiple_user_selection'>
                  <p className="mb-0">I'm a Student</p>
                </div>
              ) : props.context === "Teacher" ? (
                <div className='multiple_user_selection'>
                  <p className="mb-0">I'm a Teacher</p>
                </div>
              ) : (
                props.context
              )}
            </h3>
          </div>
          {/* Student Modal */}

          {props.context === "Student" && (

            <div className="modal fade" id="studentModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="sub-Head1 rounded">
                    <div className="Sub-Head2" >Profile</div>
                  </div>
                  <div className="modal-body">
                    <div className="container text-start mt-4">
                      <p className="signup_toggle_head">Student Details</p>
                      <div className="row ms-5">
                        <div className="col-10">
                          <div className="row mt-5">
                            <div className="col-5">
                              <p className="signIn_label">First Name</p>
                            </div>
                            <div className="col">
                              <Field
                                type="text"
                                name="firstname"

                                className={`form-control admin-signIn-input-tea ${errors.firstname && touched.firstname ? 'is-invalid' : ''}`}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                              <ErrorMessage name="firstname" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-5">
                              <p className="signIn_label">Last Name </p>
                            </div>
                            <div className="col">
                              <Field
                                type="text"
                                name="lastname"
                                className={`form-control admin-signIn-input-tea ${errors.lastname && touched.lastname ? 'is-invalid' : ''}`}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                              <ErrorMessage name="lastname" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-5">
                              <p className="signIn_label">Professional</p>
                            </div>
                            <div className="col">
                              <Field
                                as="select"
                                name="professional"
                                className={`form-control admin-signIn-input-tea ${errors.professional && touched.professional ? 'is-invalid' : ''}`}
                                aria-label="Professional"
                                aria-describedby="basic-addon1"
                              >
                                <option value="">Select Professional</option>
                                <option value="1">Teacher</option>
                                <option value="2">Student</option>
                              </Field>
                              <ErrorMessage name="professional" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-5">
                              <p className="signIn_label">Email</p>
                            </div>
                            <div className="col">
                              <Field
                                type="text"

                                value={mail.email}
                                className={`form-control admin-signIn-input-tea ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                              <ErrorMessage name="email" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-5">
                              <p className="signIn_label">Create a password </p>
                            </div>
                            <div className="col">
                              <Field
                                type="password"
                                name="password"
                                className={`form-control admin-signIn-input-tea ${errors.password && touched.confirm_pass ? 'is-invalid' : ''}`}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                              <ErrorMessage name="password" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-5">
                              <p className="signIn_label">Re-type password </p>
                            </div>
                            <div className="col">
                              <Field
                                type="password"
                                name="confirm_pass"
                                className={`form-control admin-signIn-input-tea ${errors.confirm_pass && touched.confirm_pass ? 'is-invalid' : ''}`}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                              <ErrorMessage name="confirm_pass" component="div" className="text-danger" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container text-center">
                      <div className="row">
                        <div className="col">
                          <button
                            type="submit"
                            className="btn btn-primary text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"

                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}


          {/* Teacher Modal */}
          {/* {props.context === "Teacher" && ( */}
          <div className="modal fade" id="teacherModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="sub-Head1 rounded">
                  <div className="Sub-Head2">Profile</div>
                </div>
                <div className="modal-body">
                  <div className="container text-start mt-4">
                    <p className="signup_toggle_head">Create Teacher Profile,</p>
                    <div className="row ms-5">
                      <div className="col-10">
                        <div className="row mt-5">
                          <div className="col-5">
                            <p className="signIn_label_tea">First Name</p>
                          </div>
                          <div className="col">
                            <Field
                              type="text"
                              name="firstname"
                              className={`form-control admin-signIn-input-tea ${errors.firstname && touched.firstname ? 'is-invalid' : ''}`}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                            <ErrorMessage name="firstname" component="div" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">
                            <p className="signIn_label_tea">Last Name </p>
                          </div>
                          <div className="col">
                            <Field
                              type="text"
                              name="lastname"
                              className={`form-control admin-signIn-input-tea ${errors.email && touched.email ? 'is-invalid' : ''}`}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                            <ErrorMessage name="lastname" component="div" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">
                            <p className="signIn_label">Professional</p>
                          </div>
                          <div className="col">
                            <Field
                              as="select"
                              name="professional"
                              className={`form-control admin-signIn-input-tea ${errors.professional && touched.professional ? 'is-invalid' : ''}`}
                              aria-label="Professional"
                              aria-describedby="basic-addon1"
                            >
                              <option value="">Select Professional</option>
                              <option value="1">Teacher</option>
                              <option value="2">Student</option>
                            </Field>
                            <ErrorMessage name="professional" component="div" className="text-danger" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">
                            <p className="signIn_label_tea">Email </p>
                          </div>
                          <div className="col">
                            <Field
                              type="text"
                              value={mail.email}
                              className={`form-control admin-signIn-input-tea ${errors.lastname && touched.lastname ? 'is-invalid' : ''}`}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                            <ErrorMessage name="lastname" component="div" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">
                            <p className="signIn_label_tea">Create a password </p>
                          </div>
                          <div className="col">
                            <Field
                              type="password"
                              name="password"
                              className={`form-control admin-signIn-input-tea ${errors.password ? 'is-invalid' : ''}`}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                            <ErrorMessage name="password" component="div" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">
                            <p className="signIn_label_tea">Re-type password </p>
                          </div>
                          <div className="col">
                            <Field
                              type="password"
                              name="confirm_pass"
                              className={`form-control admin-signIn-input-tea ${errors.confirm_pass && touched.confirm_pass ? 'is-invalid' : ''}`}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                            <ErrorMessage name="confirm_pass" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="container text-center">
                    <div className="row">
                      <div className="col">
                        <button
                          type="submit"
                          className="btn btn-primary text-light px-4 py-1 bootBtn my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3"
                        >
                          {loading ? <div className='d-flex justify-content-center' ><RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="25"
                            height="25"
                            visible={true}
                          /></div> : "Save"}
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* )}  */}
        </Form>
      )}

    </Formik>
  );
}

export default ChooseWhoYouAre;
