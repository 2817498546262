import { HiDotsVertical } from "react-icons/hi";
import { format } from 'date-fns';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ActiveCategoryID, ActiveSub, CSTData, SubData, SubjectData, currentCategoryID, deleteCategory, fetchSubjectList, getCSTData, getSubCategoryData, getSubTopicData, subjectLoading, topicData, viewCategoryDetails } from '../../../../../../redux/slice/SubjectSlice';
import { categoryListData, getCategoryListData } from '../../../../../../redux/slice/studentSlice';
import { RotatingLines } from 'react-loader-spinner';
import { showErrorToast } from "../../../../../../util/toastUtils";

function AdminSubjectComponent({ ...props}) {
    const dispatch = useDispatch();
    const subData = useSelector(SubjectData);
    const data = useSelector(CSTData);
    const currentId = useSelector(ActiveCategoryID)

    const subCatData = useSelector(SubData);
    const TopicData = useSelector(topicData);
    const categoryList = useSelector(categoryListData);
    const loading = useSelector(subjectLoading);
    const [isActiveSubject, setIsActiveSubject] = useState(true);

    const [isActiveCategory, setIsActiveCategory] = useState(false);
    const categoryOptions = data?.map((e) => ({
        value: e.id,
        label: e.name
    }));

    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
        // dispatch(getCategoryListData());
    }, []);

    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    function handleClick(row) {
        goToDetailsPage();
        dispatch(ActiveSub(row.id));
    }

    function handleClickCategory(row) {
        const payload = {
            // id: row.id,
            category_id: row.id,
            type: 1
        };
        goToCategoryDetailsPage(row.id);
        dispatch(viewCategoryDetails(payload));
    }
    const handleDelete = (row) => {
        // Check if category has associated subcategories or topics
        if (categoryData?.subCategory?.length > 0 || categoryData?.topic?.length > 0) {
            // Display error message
            showErrorToast('Cannot delete category with associated subcategories or topics.');
        } else {
            const payload = {
                id: row.id,
                type: 1
            };
            // Dispatch deleteCategory action
            dispatch(deleteCategory(payload))
                .then(() => {
                    // If deletion is successful, call getCategoryListData to update the table
                    const payload = {
                        type: 1
                    };
                    dispatch(getCSTData(payload));
                })
                .catch((error) => {
                    // Handle any errors, such as failed deletion
                    console.error('Error deleting category:', error);
                });
        }
    };

    const handleButtonClick = (buttonName) => {
        if (buttonName === 'subject') {
            setIsActiveSubject(true);
            setIsActiveCategory(false);
        } else if (buttonName === 'category') {
            setIsActiveCategory(true);
            setIsActiveSubject(false);
        }
    };


    const goToAddCategory = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminAddCategory' });
        }
    };

    const goToAddSubCategory = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminAddSubCategory' });
        }
    };

    const goToAddTopic = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminAddTopic' });
        }
    };

    const goToDetailsPage = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminDetailsPage' });
        }
    };

    const goToCategoryDetailsPage = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'CategoryDetailsPage', });
        }
    };
    const [showModal, setShowModal] = useState(false);


    const handleCloseModal = () => {
        setShowModal(false); // Close modal
    };
    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
                textAlign: "center"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontWeight: "bold"
            }
        },
    };

    const columns = [
        {
            name: 'Date',
            selector: row => row.created_at,
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.created_at ? dateFunction(row.created_at) : ""}
                    </div>
                );
            }
        },
        {
            name: 'Category',
            selector: row => row.name,
            sortable: true
        },
        // {
        //     name: 'Status',
        //     sortable: true,
        //     cell: (row, rowIndex) => {
        //         return (
        //             <div>
        //                 {row.
        //                     category_status
        //                     === "1" ? <span className='text-success fw-bold'>Active</span> : <span className='text-danger fw-bold'>Inactive</span>}
        //             </div>
        //         );
        //     }
        // },
        {
            name: 'Action',
            sortable: true,
            cell: (row, subData, rowIndex, id) => (
                <div>
                    <div>
                        <button type="button" class="btn text-light px-xxl-3 px-xl-3 px-lg-3 px-md-2 py-1 bootBtn" onClick={() => handleClickCategory(row)}>Details</button>
                    </div>
                    {/* <HiDotsVertical class='admin-nav-icon' data-bs-toggle="dropdown" aria-expanded="false" />
                    <div class="dropdown">
                        <ul class="dropdown-menu mt-3">
                            <li><a class="dropdown-item" onClick={() => handleClickCategory(row)}>Details</a></li>
                            <li><a class="dropdown-item" onClick={() => handleDelete(row)}>Delete</a></li>
                        </ul>
                    </div> */}
                </div>
            )
        }

    ];
    const categoryColumns = [
        {
            name: 'Category',
            selector: row => row.category_name,
            sortable: true
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.category_status === "1" ? <span className='text-success fw-bold'>Active</span> : <span className='text-danger fw-bold'>Inactive</span>}
                    </div>
                );
            }
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row,) => (
                <div>
                    <HiDotsVertical /><button type="button" className="btn btn-primary px-xxl-3 px-xl-3 px-lg-2 py-1 bootBtn" onClick={() => handleClickCategory(row)}>Details</button>
                </div>
            )
        }
    ];
    const validationSchema = Yup.object({
        date: Yup.date().nullable().label('Date'),
        category: Yup.string().label('Category'),
        subCategory: Yup.string().label('Sub Category'),
        topic: Yup.string().label('Topic'),
    });

    const initialValues = {
        date: null,
        category: '',
        subCategory: '',
        topic: '',
    };
    const onSubmit = (values) => {
        const valueToSend = {};
        if (values.category !== "") {
            valueToSend.category = values?.category;
        }
        if (values.subCategory !== "") {
            valueToSend.sub_category = values?.subCategory;
        }
        if (values.topic !== "") {
            valueToSend.topic = values?.topic;
        }
        if (values.date !== null) {
            valueToSend.date = dateFunction(values?.date);
        }
        dispatch(fetchSubjectList(valueToSend));
    };

    const [filterText, setFilterText] = useState('');
    const [categoryData, setCategoryData] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const filteredData1 = data?.filter(item => {
            let res = false;

            // Check if the item's created_at field matches the filterText (date)
            if (item.created_at) {
                const date = new Date(item.created_at);
                res = date.getMonth() >= 0 && item.created_at.toLowerCase().includes(filterText.toLowerCase());
            }
            // Check if the item's id (category) matches the categoryData
            if (item.id) {
                res = res && item.id.toString().toLowerCase().includes(categoryData.toLowerCase());
            }
            return res;
        });
        setFilteredData(filteredData1);
    }, [filterText, categoryData, data])




    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >
                <div className='Sub-Head2'>Category</div>
            </div>
            <div>
                <div className="container text-start my-5">
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} >
                        {({ touched, errors, handleSubmit, resetForm, isSubmitting, setFieldValue, values, handleChange, handleBlur }) => (
                            <>
                                <div class="container ps-0 pb-3">
                                    <div class="row">
                                        <div className="col">
                                            {/* <button className={` ${isActiveSubject ? 'admin-switch-btn ' : 'admin-switch-btn-deactivate'} px-5 py-1`} onClick={() => handleButtonClick('subject')}>
                                                Subject
                                            </button> */}
                                            {/* <button className={` ${isActiveCategory ? 'admin-switch-btn' : 'admin-switch-btn-deactivate '} px-5 py-1`} onClick={() => handleButtonClick('category')}>
                                                Category
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-xxl-5 mt-xl-5 mt-lg-4'>
                                    <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mb-2 d-flex justify-content-center'>
                                        <button type="button" className="btn text-light px-xxl-4 px-xl-4 px-lg-4 px-md-4 px-sm-2 py-1 bootBtn " onClick={(e) => goToAddCategory()}>Add Category</button>
                                    </div>
                                    {/* <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 px-lg-0 mt-3 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0 me-sm-3 mb-2 d-flex justify-content-center'>
                                        <button type="button" className="btn text-light px-xxl-4 px-xl-4 px-lg-2 px-md-4 px-sm-2 py-1 bootBtn" onClick={(e) => goToAddSubCategory()}> </button>
                                    </div>
                                    <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12 ps-lg-0 mt-3 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0 mb-2 d-flex justify-content-center'>
                                        <button type="button" className="btn text-light  px-xxl-4 px-xl-4 px-lg-2 px-md-4 px-sm-2 py-1 bootBtn" onClick={(e) => goToAddTopic()}>Add Topic</button>
                                    </div> */}
                                </div>
                                <div className='mt-4'>
                                    <div className="row">
                                        <div className="col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 ms-1">
                                            <div>
                                                <div className="container ">
                                                    <div className='row'>
                                                        <div className='col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 '>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleDropdown" className="fs-5 fw-semibold">Date</label><br />
                                                                <input
                                                                    type='date'
                                                                    className=' admin-dropdown p-2'
                                                                    id="categoryDropdown"
                                                                    onChange={(e) => setFilterText(e.target.value)}
                                                                    value={filterText}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 ms-1">
                                            <div className="container ">
                                                <div className="form-group">
                                                    <label htmlFor="categoryDropdown" className="fs-5 fw-semibold">Category</label>
                                                    <select
                                                        className="form-select admin-dropdown"
                                                        id="categoryDropdown"
                                                        name="category"
                                                        // value={values?.category}
                                                        // onBlur={handleBlur}
                                                        onChange={(e) => setCategoryData(e.target.value)}
                                                        value={categoryData}>       
                                                        <option value=""></option>
                                                        {categoryOptions?.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <CSTComponent /> */}
                                </div>
                            </>)}
                    </Formik>
                    <div className="row mt-xxl-5 mt-xl-5 mt-lg-4 ">
                        {loading ? (<div className='d-flex justify-content-center' style={{ height: '40vh' }}><RotatingLines
                            strokeColor="#5C5AB6"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            height="50"
                            visible={true}
                        /></div>) : (
                            <DataTable
                                className='dataTable mt-5'
                                data={filteredData || []}
                                columns={isActiveCategory ? categoryColumns : columns}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                striped
                                customStyles={customStyles}
                                persistTableHead
                                pagination
                            />)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSubjectComponent;
