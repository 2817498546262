import React from 'react'
import UploadDashboardComponent from './component/uploadDashboard.component'
import AnnouncementComponent from './component/Announcement.component'
import CourseUploadComponent from './component/CourseUpload.component'
import StudentsComponent from './component/Students.component'
import WalletComponent from './component/Wallet.component'
import ChatsComponent from './component/Chats.component'
import NotificationComponent from './component/notification.component'

function InstructorUploadSideBar(props) {
    return (
        <div>
            <UploadDashboardComponent {...props} />
            <AnnouncementComponent {...props} />
            <CourseUploadComponent {...props} />
            <StudentsComponent {...props} />
            <WalletComponent  {...props} />
            <ChatsComponent {...props} />
            <NotificationComponent {...props} />
        </div>
    )
}

export default InstructorUploadSideBar