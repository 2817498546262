import React from 'react'
import DataTable from 'react-data-table-component';
import { DashboardData } from '../../../../../../redux/slice/SubjectSlice';
import { useSelector } from 'react-redux';
import { BsArrowRight } from 'react-icons/bs';

function DashboardSubjectTableComponent() {


    const dashboardData = useSelector(DashboardData)
    const newCourses = dashboardData?.newCourses || [];
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };
   

    const sample = [
        {
            Date: "20-02-2024",
            sale_month: "January",
            sale_report: "12,000 ₹",
            approved_pending: "pending"
        }
    ]
    const customStyles = {
        headRow: {
            style: {
                color: '#000',
                backgroundColor: '#F1F4F9',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const columns = [
        {
            name: 'Last Month ',
            selector: row => row.sale_month, 
            sortable: true
        },
        {
            name: 'Sale report',
            selector: row => row.sale_report,
            sortable: true

        },
        {
            name: 'pending for Approvel',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        <button type="button" class="btn btn-success px-4">{row.approved_pending}</button> 
                    </div>
                )
            }
        },
    ];


    return (
        <div >
            <div class='admin-card p-4'>
                <div class="container text-start">
                    <div class="row">
                        <div class="d-flex justify-content-between">
                            <div class="col">
                                <h4 class='fw-bold '>Notice Board</h4>
                            </div>
                            {/* <div class="col-2">
                                See all <span><BsArrowRight className='ms-2' /></span>
                            </div> */}
                        </div>
                    </div>
                </div>
                <DataTable
                    className='dataTable mt-3 '
                    data={sample}
                    columns={columns}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="600px"
                    striped
                    customStyles={customStyles}
                    persistTableHead
                    pagination
                    />
            </div>
        </div>
    )
}

export default DashboardSubjectTableComponent