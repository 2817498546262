import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import img from '../../../../../../images/Rectangle 53.jpg'


function UploaderNotificationComponent() {
    return (
        <div>
            <div className='main-divHead' >
                <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Notification</div>
                </div>
                <div>
                    <div class="container text-center mt-5">
                        <div class="row mt-2">
                            <div class="d-flex justify-content-center">
                                <div class="col-2">
                                    <div>
                                        <img src={img} class="img-fluid rounded-top" alt="..." />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <p class='fs-6'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                </div>
                            </div>
                            <div className="container text-center">
                                <div className="row">
                                    <div className="col">
                                        <div class="d-flex justify-content-center">
                                            <hr class='admin-horizontalLine mt-2'></hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="d-flex justify-content-center">
                                <div class="col-2">
                                    <div>
                                        <img src={img} class="img-fluid rounded-top" alt="..." />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <p class='fs-6'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                </div>
                            </div>
                            <div className="container text-center">
                                <div className="row">
                                    <div className="col">
                                        <div class="d-flex justify-content-center">
                                            <hr class='admin-horizontalLine mt-2'></hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="d-flex justify-content-center">
                                <div class="col-2">
                                    <div>
                                        <img src={img} class="img-fluid rounded-top" alt="..." />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <p class='fs-6'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                </div>
                            </div>
                            <div className="container text-center">
                                <div className="row">
                                    <div className="col">
                                        <div class="d-flex justify-content-center">
                                            <hr class='admin-horizontalLine mt-2'></hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploaderNotificationComponent