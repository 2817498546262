import React, { useState } from 'react'
import _ from "lodash"
import { RxDashboard } from 'react-icons/rx'

function TeacherDashboard(props) {
    const goToDashboardTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'DashboardTeacher' })
        }
    }
    return (
        <div onClick={(e) => goToDashboardTeacher(e)} className={`sidebar_content ${_.get(props, 'userState.subType') == 'DashboardTeacher' ? 'active' : ''} `}>
            <div className="dash d-flex">
                <RxDashboard class='ms-3' />
                <p className='head-dashboard ms-2' > Dashboard
                </p>
            </div>
        </div>
    )
}

export default TeacherDashboard