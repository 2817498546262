import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import TuitionImage from "../../../images/TuitionImage.png";
import "./Section1_Navbar.scss";
import { FaArrowRight } from "react-icons/fa";

function Navbar() {
  const navigate = useNavigate();





  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Your navigation logic here based on the selected value
    if (selectedValue === 'saab') {
      navigate('/user/sign_in');
    } else if (selectedValue === 'opel') {
      navigate('/user/sign_in');
    } else if (selectedValue === 'audi') {
      navigate('/user/sign_in');
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: adds smooth scrolling behavior
    });
  };

  return (
    <div>
      <nav class="navbar navbar-expand-lg lading-navbar navbar-dark fixed-top">
        <div class="container-fluid">


        <a class="navbar-brand">
            <div>
              <img
                src={TuitionImage}
                alt="main-logo"
                className="img-fluid logo"
              />
            </div>

          </a>


          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class=" d-lg-flex justify-content-end  collapse navbar-collapse  " id="navbarNavDropdown">
            <ul class="navbar-nav  gap-lg-5 px-5">
              <li class="nav-item">
                <a class="nav-link signup-btn-on-black" aria-current="page" onClick={scrollToTop}>HOME</a>
              </li>
              <select
                name="cars"
                id="cars"
                className="nav-drop px-2 btn-on-black"
                style={{ backgroundColor: "transparent", width: "125px" }}
                onChange={handleOptionChange}
                value={selectedOption}
              >
                <option value="" className="nav-h1">
                  SERVICES
                </option>
                <option value="saab" className="nav-h2">
                  Online Tuition
                </option>
              </select>
              <li class="nav-item">
                <a class="nav-link signup-btn-on-black" aria-current="page" onClick={() => navigate("/home/contact_us")}>CONTACT US</a>
              </li>
              <li class="nav-item">
                <a class="nav-link signup-btn-on-black" aria-current="page"  onClick={() => navigate("/user/sign_in")}>LOGIN</a>
              </li>
              <li class="nav-item">
                <a class="nav-link signup-btn-on-black" aria-current="page" onClick={() => navigate("/user/sign_up")}> SIGN UP</a>
              </li>
            </ul>       
          </div>
        </div>
      </nav>
      {/* <nav class="navbar lading-navbar bg-body-tertiary py-0" >
        <div class="container-fluid">
          <a class="navbar-brand">
            <div>
              <img
                src={TuitionImage}
                alt="main-logo"
                className="img-fluid logo"
              />
            </div>

          </a>
          <div class="d-flex gap-xxl-4 gap-xl-4 " role="search">
            <button className="nav-btn btn mx-2 btn-on-black" onClick={scrollToTop}>HOME</button>
            <select
              name="cars"
              id="cars"
              className="nav-drop px-2 btn-on-black"
              style={{ backgroundColor: "transparent", width: "125px" }}
              onChange={handleOptionChange}
              value={selectedOption}
            >
              <option value="" className="nav-h1">
                SERVICES
              </option>
              <option value="saab" className="nav-h2">
                Online Tuition
              </option>
            </select>
            <button onClick={() => navigate("/home/contact_us")} className="nav-btn btn  px-3 mx-2 btn-on-black">CONTACT US</button>
            <select
              onChange={(e) => (window.location.href = e.target.value)}
              className="nav1-drop px-2"
              style={{ width: "140px" }}
            >
              <option value="" className="nav-h1">
                GET QUOTE
              </option>
              <option value="/navtution" className="nav1_h2">
                Online Tution
              </option>
            </select>
            <button
              onClick={() => navigate("/user/sign_in")}
              className="nav-btn btn  px-3 mx-2 btn-on-black"
            >
              LOGIN
            </button>
            <button
              onClick={() => navigate("/user/sign_up")}
              className="btn  nav-btn px-3 mx-2 signup-btn-on-black"
            >
              SIGN UP
            </button>
          </div>
        </div>
      </nav> */}
    </div>
  );
}

export default Navbar;
