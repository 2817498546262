import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function QuoteJobViewComponent() {

    return (
        <div class='main-divHead'>
            <div className='sub-Head1'>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Freelancer Jobs</div>
            </div>
            <div class='mx-5'>
                <div class="container mt-5">
                    <div class="row">
                        <div class='d-flex justify-content-center'>
                            <div class="col-8">
                                <div class='card adminSubCard'>
                                    <div class="container text-start">
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Closing Date : </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>10-02-2023</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Student Name : </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>Student-1</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Category : </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>category</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Sub Category : </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>sub category</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Topic :  </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>topic</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Description :  </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>description for the course category about doubts.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Price :  </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>$130</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Payment :   </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>hourly / milestone</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Currency : </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>INR / USD</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Attachments :  </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class='fs-6 fw-semibold'>INR / USD</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='fs-5 fw-semibold'>Status :</p>
                                                </div>
                                                <div class="col-3">
                                                    {false ? <span class='text-danger fw-bold'>Inactive</span> : <span class='text-success fw-bold'>Active</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuoteJobViewComponent