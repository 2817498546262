import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react'
import DataTable from 'react-data-table-component';
import { BsFillEyeFill } from 'react-icons/bs';
import { AiOutlineLeft } from 'react-icons/ai';

function InstructorVideoTableComponent(props) {

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.starting_at,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true
        },
        {
            name: 'Sub Category',
            selector: row => row.sub_category,
            sortable: true

        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true

        },
        {
            name: 'Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.status === 'Active' ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}
                    </div>
                )
            }
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                <button className="btn outline-btn-admin px-xxl-3 px-xl-3 px-lg-1 fw-bold" onClick={(e) => goToVideoView()}>View<span ><BsFillEyeFill class='adminEyeIcon ms-xxl-3 ms-xl-3 ms-lg-1' /></span></button>
            </div>
            )
        }
    ];

    const data = [
        {
            starting_at: '2023-09-28',
            category: 'Category 1',
            sub_category: 'Sub Category 1',
            topic: 'Topic 1',
            status: 'Active',
            batch_id: 'Batch 101',
        },
        {
            starting_at: '2023-10-10',
            category: 'Category 2',
            sub_category: 'Sub Category 2',
            topic: 'Topic 2',
            status: 'Inactive',
            batch_id: 'Batch 102',
        },
    ];

    const goToVideoView = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminVideoView' })
        }
    }


    const goToAdminInstructorDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminInstructorDetails' })
        }
    }

    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >
                <div className="Sub-Head2" onClick={(e) => goToAdminInstructorDetails(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>Instructor</div>
            </div>
            <div>
                <p class='admin-sub-head my-3 fw-semibold ms-5 ps-xxl-5 ps-xl-5 ps-lg-0 mt-5'>Recorded Videos</p>
                <div class="container text-start my-5">
                    <div class="row mt-2">
                        <div class='d-flex justify-content-center'>
                            <div class='col-xxl-9 col-xl-9 col-lg-11'>
                                <DataTable
                                    className='dataTable mt-4 '
                                    data={data}
                                    columns={columns}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    striped
                                    customStyles={customStyles}
                                    persistTableHead
                                    pagination
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstructorVideoTableComponent