import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import { format } from 'date-fns';
import axios from 'axios'
import DataTable from 'react-data-table-component';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import './TeacherStudent.scss'
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function TeacherStudent({ ...props }) {
    const [isInactiveModalVisible, setInactiveModalVisible] = useState(false)
    const [forceRerender, setForceRerender] = useState(false);
    const [menuVisibility, setMenuVisibility] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [activeMenu, setActiveMenu] = useState(null)
    const [openMenuIndex, setOpenMenuIndex] = useState(null)
    const [rows, setRows] = useState()
    const [loading, setLoading] = useState(true)
    const [isactions, setActions] = useState(false)
    const [data, setData] = useState([{}]);

    const handleClickActions = (event, rowId, action) => {
        setAnchorEl(event.currentTarget);
        setActions((prevState) => (prevState === rowId ? null : rowId))
        if (action === 'delete') {
            setActiveMenu(rowId)
        } else {
            setActiveMenu(null)
        }
    };

    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleMenuToggle = (rowIndex) => {
        const newMenuVisibility = [...menuVisibility]
        newMenuVisibility[rowIndex] = !newMenuVisibility[rowIndex]
        setMenuVisibility(newMenuVisibility)
    };
    const showInactiveModal = () => {
        setInactiveModalVisible(true)
    };

    const hideInactiveModal = () => {
        setInactiveModalVisible(false)
    };

    const handleInactiveAction = async (row) => {
        try {
            const newData = data.map(item => {
                if (item.id === row.id) {
                    return {
                        ...item,
                        status: item.status === 1 ? 0 : 1
                    };
                }
                return item;
            });

            const response = await changeBatchStatus(row.id, newData.find(item => item.id === row.id).status);
            console.log('Batch status changed successfully');
            setData(newData);
            hideInactiveModal();
        } catch (error) {
            console.error('Error changing batch status:', error);
        }
    };

    const goToTeacherResource = (e, row, subject_id, id) => {
        console.log('subject_id', subject_id)
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'addresource', row: row, subject_id: subject_id, id: id })
        }
    }

    const handleInactiveConfirm = () => {
        hideInactiveModal()
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",

            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const columns = [
        {
            name: 'Date',
            selector: row => row.starting_at,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.category_name,
            sortable: true
        },
        {
            name: 'Sub category',
            selector: row => row.sub_category_name,
            sortable: true
        },
        {
            name: 'Topic',
            selector: row => row.topic_name,
            sortable: true
        },
        {
            name: 'Batch Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, id, rowIndex, subject_id) => (
                <div className={`Teacher test-actions ${isactions === row.id ? '' : 'active'}`}>
                    <Button className="action-subj d-flex" id="basic-button"
                        aria-controls={anchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                        onClick={(e) => handleClickActions(e, row.id, 'menu')}>
                        <MoreVertIcon />
                    </Button>
                    <div>
                        <Menu
                            className="dots-actions"
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={isactions === row.id}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}>
                            <MenuItem onClick={(e) => goToTeacherStudentsView(e, row, id, subject_id)} disableRipple className="ul-for-options pt0">
                                View
                            </MenuItem>
                            <MenuItem onClick={(e) => goToTeacherCreateBatch(e, row, id)} disableRipple className="ul-for-options">
                                Edit
                            </MenuItem>
                            <MenuItem onClick={(e) => goToTeacherResource(e, row, subject_id, id)} disableRipple className="ul-for-options">
                                Add Resource
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        fetchBatchList()
    }, [])

    useEffect(() => {
        const visibility = new Array(data.length).fill(false)
        setMenuVisibility(visibility);
    }, [data]);

    useEffect(() => {
        const storedStatus = localStorage.getItem('batchStatus')
        if (storedStatus !== null) {
            const newData = [...data]
            newData.forEach((item) => {
                item.status = parseInt(storedStatus);
            });
            setData(newData);
        }
    }, []);

    const fetchBatchList = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/listBatch', undefined, { headers: headers })
            .then((response) => {
                const newData = response.data.data.batch.map((item) => ({
                    ...item,
                    starting_at: format(new Date(item.starting_at), 'dd-MM-yyyy'),
                }));
                setData(newData);
                setLoading(false); // Set loading state to false after fetching
            })
            .catch((error) => {
                console.error('Error fetching Batch List:', error);
                setLoading(false); // Set loading state to false in case of error
            });
    };

    const changeBatchStatus = (id, status) => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const formData = new FormData()
        formData.append('id', id)
        formData.append('status', status)
        Axios.post('/api/changeStatus', formData, { headers: headers })
            .then(() => {
                console.log('Batch status changed successfully')
            })
            .catch((error) => {
                console.error('Error changing batch status:', error)
            })
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherstudent' })
        }
    }

    const goToTeacherCreateBatch = (e, row, id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'createbatch', row: row, id: id })
        }
    }

    const goToTeacherStudentsView = (e, row, id, subject_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'teacherstudentview', row: row, id: id, subject_id: subject_id })
        }
    }

    return (
        <div className='main-divHead'>
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Students</div>
            </div>
            <button className='btn-create-schedule mb-5' onClick={(e) => goToTeacherCreateBatch(e)}>Create Batch</button>
            <div className="col-11 mt-3 ms-5 justify-content-center">
                <DataTable
                    data={data}
                    columns={columns}
                    customStyles={customStyles}
                    pagination
                    fixedHeader={true}
                    fixedHeaderScrollHeight="600px"
                    striped
                    persistTableHead
                />
            </div>
        </div>
    )
}

export default TeacherStudent
