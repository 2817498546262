import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { AnnouncementList, listAnnouncementData } from '../../../../../../redux/slice/UploadSlice';

function AnnouncementComponent(props) {

    const dispatch = useDispatch();
    const announcementData = useSelector(listAnnouncementData);

    console.log("announcementData", announcementData);

    useEffect(() => {
        dispatch(AnnouncementList());
    }, []);





    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const columns = [
        {
            name: 'Date',
            selector: row => row.starting_at,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true
        },
        {
            name: 'Sub Category',
            selector: row => row.sub_category,
            sortable: true
        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.status === 'Active' ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}
                    </div>
                )
            }
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <button type="button" class="btn btn-primary px-3 py-1 bootBtn" onClick={() => goToDetails()}>Details</button>
                </div>
            )
        }
    ];

    const data = [
        {
            starting_at: '2023-09-28',
            category: 'Category 1',
            sub_category: 'Sub Category 1',
            topic: 'Topic 1',
            status: 'Active',
            batch_id: 'Batch 101',
        },
        {
            starting_at: '2023-10-10',
            category: 'Category 2',
            sub_category: 'Sub Category 2',
            topic: 'Topic 2',
            status: 'Inactive',
            batch_id: 'Batch 102',
        },
    ];

    const goToDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'freelanceSubjectDetails' });
        }
    };

    const goToAddCreateAnnouncement = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'uploaderCreateAnnouncement' });
        }
    };

    return (
        <div>
            <div className='main-divHead' >
                <div className='sub-Head1' >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Announcement</div>
                </div>
                <div>
                    <div className="container text-start my-5">
                        <>
                            <div className='row mt-5'>
                                <div className='col-3'>
                                    <button type="button" className="btn btn-primary px-4 py-1 bootBtn" onClick={() => goToAddCreateAnnouncement()}>Create Announcement</button>
                                </div>
                            </div>
                        </>
                        <div className="row mt-5">
                            <DataTable
                                className='dataTable mt-5'
                                data={data}
                                columns={columns}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                striped
                                customStyles={customStyles}
                                persistTableHead
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnnouncementComponent