import React from 'react'
import SignInComponent from '../components/signInSignUp/SignIn.component'
import img from '../images/signup.png'
import SignUpComponent from '../components/signInSignUp/signUp.component'
import SignUpVerificationComponent from '../components/signInSignUp/signUpverification.component'


function LandingSignup() {
    return (
        <div>
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col px-0 d-none d-sm-block">
                            <img src={img} alt="Login image" className="img-fluid w-100 vh-100" />
                        </div>
                        <div className="col d-flex justify-content-center align-items-center">
                            <SignUpComponent />
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default LandingSignup