import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import ViewIcon from '../../../Sidebar/icons/ViewIcon.png'
import axios from 'axios'
import DataTable from 'react-data-table-component';
import { RiBankFill } from "react-icons/ri";
import './TeacherWithdrawl.scss'
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';
function TeacherWallet(props) {
    const [errors, setErrors] = useState([""]);
    const [lists, setList] = useState([{}])
    const [wallet, setWallet] = useState([{}])
    const [walletlist, setWalletlist] = useState([{}]);
    // const handleChange = (e) => {
    //     setList({ ...lists, [e.target.name]: e.target.value });
    //     setErrors({ ...errors, [e.target.name]: " " });
    // }
    const fetchWallet = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }

        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
        Axios.post('/api/wallet', undefined, { headers: headers })
            .then((response) => {
                console.log("Wallet API Response:", response.data); // Log the response data
                const responseData = response.data.data;
                setWallet(responseData);
                setLoading(false);
                const transactions = wallet.transactions;
                setWalletlist(transactions);
                console.log("Walletlist:", transactions);

            })
            .catch((error) => {
                console.error('Error fetching wallet:', error);
                setLoading(false);
            });
    };

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
                WidthFull: "600px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: 'date', // Assuming 'date' is the property name for the date in your transaction object
            sortable: true,
        },
        {
            name: 'Wallet Amount',
            selector: 'total_amount', // Assuming 'total_amount' is the property name for the wallet amount
            sortable: true,
        },
        {
            name: 'Credit',
            selector: 'credit', // Accessing the 'credit' property directly
            sortable: true,
        },
        {
            name: 'Debit',
            selector: 'debit', // Assuming 'debit' is the property name for the debit amount
            sortable: true,
        },
        {
            name: 'Available Balance',
            selector: 'initial_amount', // Assuming 'initial_amount' is the property name for the initial amount
            sortable: true,
        },
        {
            name: 'Credit Details',
            cell: (row) => (row.creditDetails), // Assuming 'creditDetails' is a function to display details
        },
        {
            name: 'Action',
            cell: (row) => (
                <img
                    src={ViewIcon}
                    className='view-assignment'
                    alt="viewicon"
                    onClick={(e) => goToTeacherCreditDetails(e)}
                />
            ),
        },
    ];

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        fetchWallet()
    }, [])



    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherwallet' })
        }
    }

    const goToTeacherCreditDetails = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'creditdetails' })
        }
    }

    const goToTeacherDetails = (e, id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'requestwithdrawl', id: id })
        }
    }

    const goToTeacherAddBank = (e, id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'addteacherbank', id: id })
        }
    }

    return (
        <div className='sidebar_content'>
            <div className='main-divHead ' >
                <div className='sub-Head1' onClick={Backdashboard}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Wallet</div>
                </div>
                <div class="container text-start mt-3">
                    <div class="row">
                        <div class="row ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 mt-4">
                            <div class='col-xxl-2 col-xl-2 col-lg-4 '>
                                <div class='card adminCardBGW ms-0 py-xxl-3 py-xl-3 py-lg-2'>
                                    <p className='mb-0 text-capitalize fs-xxl-5 fs-xl-5 fs-lg-6 fw-semibold'>Gross Total :  {wallet?.totalAmount}₹</p>
                                </div>
                            </div>
                            <div class='col-xxl-2 col-xl-2 col-lg-4 ps-0 '>
                                <div class='card adminCardBGW ms-0 py-xxl-3 py-xl-3 py-lg-2'>
                                    <p className='mb-0 text-capitalize fs-xxl-5 fs-xl-5 fs-lg-6 fw-semibold'>Charges (10%) : {wallet?.charges?.charging_amount}₹</p>
                                </div>
                            </div>
                            <div class='col-xxl-2 col-xl-2 col-lg-4 '>
                                <div class='card adminCardBGR ms-0 text-light py-xxl-3 py-xl-3 py-lg-2'>
                                    <p className='mb-0 text-capitalize fs-xxl-5 fs-xl-5 fs-lg-6 fw-semibold'> NET AMOUNT :{wallet?.net_amount}₹</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5 mt-5 p-xxl-4 p-xl-4 p-md-4 p-sm-4">
                    <DataTable
                        columns={columns}
                        data={walletlist?.transactions}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="600px"
                        striped
                        customStyles={customStyles}
                        persistTableHead
                        pagination
                    />
                </div>
                <div className="container text-center my-5">
                    <div className="row">
                        <div className="col">
                            <button className='btn-req-drawal mb-5 py-1 px-3' onClick={(e) => goToTeacherDetails(e)}><span>Request To Withdrawal </span><span className='ms-1'><RiBankFill /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeacherWallet