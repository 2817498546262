import React from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import './CreateTeacherBatch.scss'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { Formik, Field, Form, ErrorMessage, useFormik } from 'formik';
import { CSTData, SubData, getCSTData, getSubCategoryData, getSubTopicData, topicData } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from "../../../../../../util/toastUtils"
import { InputGroup } from 'react-bootstrap';
import Select from "react-select";
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';
import { format } from 'date-fns';

function CreateTeacherBatch(props) {
    const [errors, setErrors] = useState([""]);
    const [edit, setEdit] = useState()
   

    const handleInput = (e) => {

        const { name, value } = e.target;

        if (name.startsWith('dates.monday')) {
            // Update the nested 'dates.monday' object
            // Parse and format time input values
            const formattedValue = value.replace(/(\d{2})(\d{2})/, '$1:$2:00'); // Append ":00" for seconds

            // Update the nested object dynamically
            setBatch(prevState => ({
                ...prevState,
                dates: {
                    ...prevState.dates,
                    [name.split('.')[1]]: {
                        ...prevState.dates[name.split('.')[1]],
                        [name.split('.').pop()]: formattedValue,
                    },
                },
            }));
        }
        else if (name.startsWith('dates.tuesday')) {
            // Update the nested 'dates.tuesday' object
            setBatch({
                ...batch,
                dates: {
                    ...batch.dates,
                    tuesday: {
                        ...batch.dates.tuesday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }
        else if (name.startsWith('dates.wednesday')) {
            // Update the nested 'dates.wednesday' object
            setBatch({
                ...batch,
                dates: {
                    ...batch.dates,
                    wednesday: {
                        ...batch.dates.wednesday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }
        else if (name.startsWith('dates.thursday')) {
            // Update the nested 'dates.thursday' object
            setBatch({
                ...batch,
                dates: {
                    ...batch.dates,
                    thursday: {
                        ...batch.dates.thursday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }
        else if (name.startsWith('dates.friday')) {
            // Update the nested 'dates.friday' object
            setBatch({
                ...batch,
                dates: {
                    ...batch.dates,
                    friday: {
                        ...batch.dates.friday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }
        else if (name.startsWith('dates.saturday')) {
            // Update the nested 'dates.saturday' object
            setBatch({
                ...batch,
                dates: {
                    ...batch.dates,
                    saturday: {
                        ...batch.dates.saturday,
                        [name.split('.').pop()]: value, // Update the specific field
                    },
                },
            });
        }

        else if (name.startsWith('dates.sunday')) {
            // Update the nested 'dates.sunday' object
            setBatch({
                ...batch,
                dates: {
                    ...batch.dates,
                    sunday: {
                        ...batch.dates.sunday,
                        [name.split('.').pop()]: value,// Update the specific field
                    },
                },
            });
        }


        else {

            // Update other fields in Quotation
            setBatch({ ...batch, [name]: value });
            setErrors({ ...errors, [e.target.name]: " " });
        }
        if (e.target.name === 'subject_id') {
            listStudents(e.target.value);

        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBatch({ ...batch, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: " " });

        // If the field being changed is 'subject_id', call listStudents with the selected subject_id
        if (e.target.name === 'subject_id') {
            listStudents(e.target.value);

        }
    };

    const [loading, setLoading] = useState(true)
    const [subject, setSubject] = useState([{}])
    const [studentList, setStudentList] = useState([{}]);
    const [totalList, setTotalList] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [topicOptions, setTopicOptions] = useState([]);
    const convertedData = studentList.map(student => ({
        label: student.firstname,
        value: student.student_id
    }));


    useEffect(() => {
        listSubject()
    }, [])

    const listSubject = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }
        const formData = new FormData();
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };

        Axios.post('/api/listSubject', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data")) {
                    const SubjectObject = response.data.data;
                    const SubjectArray = Object.values(SubjectObject);
                    setSubject(SubjectArray);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching subject:', error);
                setLoading(false);
            });
    };


    const listStudents = (subjectId) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const formData = new FormData();
        formData.append('subject_id', subjectId || 0);
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        Axios.post('/api/listStudent', formData, { headers: headers })
            .then((response) => {
                if (_.get(response, "data.data.student_list")) {
                    const studentListData = response.data.data.student_list;
                    setStudentList(studentListData);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching students:', error);
                setLoading(false);
            });
    };


    const BatchValidationSchema = Yup.object().shape({
        category: Yup.string().required('category is required'),
        sub_category: Yup.string().required('sub Category is required'),
        topic: Yup.string().required('topic is required'),
        subject_id: Yup.string().required('subject is required'),
        name: Yup.string().required('name  is required'),
        student_id: Yup.string().required('student is required'),
        starting_at: Yup.string().required('start date is required'),

    });

    const [batch, setBatch] = useState({
        category: '',
        sub_category: '',
        topic: '',
        name: '',
        starting_at: '',
        student_id: '',
        subject_id: 2,
        subject: '',
        id: '',
        dates: {
            monday: { isChecked: false, from: "", to: "" },
            tuesday: { isChecked: false, from: "", to: "" },
            wednesday: { isChecked: false, from: "", to: "" },
            thursday: { isChecked: false, from: "", to: "" },
            friday: { isChecked: false, from: "", to: "" },
            saturday: { isChecked: false, from: "", to: "" },
            sunday: { isChecked: false, from: "", to: "" },
        },
    })


    const handleCheckboxChange = (day) => {
        setBatch(prevState => ({
            ...prevState,
            dates: {
                ...prevState.dates,
                [day]: {
                    ...prevState.dates[day],
                    isChecked: !prevState.dates[day].isChecked,
                },
            },
        }));
    };
    const ToSubmit = () => {
        const CreateBatch = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("email", _.get(CreateBatch, 'data.email'));
        formData.append("category", selectedSubject.category);
        formData.append("sub_category", selectedSubject.sub_category);
        formData.append("topic", selectedSubject.subject);
        formData.append('name', batch.name)
        formData.append('starting_at', batch.starting_at)
        // formData.append('student_id[]', batch.student_id)

        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("student_id[]", record.value || []);
        }
        formData.append('subject_id', batch.subject_id)
        const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        let isValid = true;

        for (const day of days) {
            const isChecked = batch.dates[day] && batch.dates[day].isChecked;

            if (isChecked) {
                const from = batch.dates[day].from;
                const to = batch.dates[day].to;
                if (!from || !to) {
                    alert(`Please fill ${day} timing`);
                    isValid = false;
                    break;
                }
                formData.append(`dates[${day}][from]`, from);
                formData.append(`dates[${day}][to]`, to);
            }
        }

        if (!isValid) {
            return;
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/createBatch',
            formData,
            { headers: headers })

            .then((res) => {



                if (res?.data?.success) {
                    let user = _.get(res, "data.data.user")
                        ? JSON.stringify(res.data.data.user)
                        : undefined;
                    let token = _.get(res, "data.data.token")
                        ? JSON.stringify(res.data.data.token)
                        : undefined;
                    if (user) {
                        localStorage.setItem("user", JSON.stringify(res.data.data.user));
                    }
                    if (token) {
                        localStorage.setItem("token", (res.data.data.token));
                    }
                    showSuccessToast("Batch created successfully");
                    //move to backdashboard
                    Backdashboard();
                }



            });
    };

    const ToEdit = () => {
        // const createAssignment = localStorage.getItem("createData") ?(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        // formData.append("email", _.get(createAssignment, 'email'));
        formData.append("id", batch.id)
        formData.append("category", batch.category)
        formData.append("sub_category", batch.sub_category)
        formData.append('topic', batch.topic)
        formData.append('name', batch.name)
        formData.append('starting_at', batch.starting_at)
        // formData.append('student_id[]', batch.student_id)

        for (let i = 0; i < totalList.length; i++) {
            const record = totalList[i];
            formData.append("student_id[]", record.value || []);
        }

        formData.append('subject_id', batch.subject_id)
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        Axios.post('/api/editbatch',
            formData,
            { headers: headers })
            .then((res) => {
                console.log(res)
                const updateData = res.data
                setEdit(updateData)
                Backdashboard()
                showSuccessToast("Batch Edit Successfully");
            });
    }
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, [])
    const initializeObject = () => {
        setBatch({ ...batch, ...props.userState.row })
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherstudent', })
        }
    }
    const handleStudentChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions).map((option) => parseInt(option.value, 10)); // Parse the value to an integer
        setBatch({ ...batch, student_id: selectedOptions });
    }


    const dispatch = useDispatch();
    const data = useSelector(CSTData);
    const subCatData = useSelector(SubData);
    const TopicData = useSelector(topicData);
    useEffect(() => {
        const payload = {
            type: 1
        };
        dispatch(getCSTData(payload));
    }, []);
    // const categoryOptions = data.map((e) => ({
    //     value: e.id,
    //     label: e.name
    // }));

    const SubCategoryOptionsList = subCatData.map((e) => ({
        value: e.id,
        label: e.name
    }));

    const TopicOptionsList = TopicData.map((e) => ({
        value: e.id,
        label: e.name
    }));

    // useEffect(() => {
    //     // Fetch student list when a subject is selected
    //     if (batch.subject_id) {
    //         listStudents(batch.subject_id);
    //     }
    // }, [batch.subject_id]);
    const handleMultipleChange = (selectList) => {
        setTotalList(selectList || []);
    };

    const handleSubjectChange = (e) => {
        const subjectId = parseInt(e.target.value);
        const selectedSubject = subject.find(sub => sub.id === subjectId);
        setSelectedSubject(selectedSubject);

        // Populate category options
        if (selectedSubject) {
            const categories = [{ value: selectedSubject.category, label: selectedSubject.category_name }];
            setCategoryOptions(categories);

            // Populate subcategory options
            const subCategories = [{ value: selectedSubject.sub_category, label: selectedSubject.sub_category_name }];
            setSubCategoryOptions(subCategories);

            // Populate topic options
            const topics = [{ value: selectedSubject.subject, label: selectedSubject.topic_name }];
            setTopicOptions(topics);
        }
    };
    const newArray = studentList.filter(mainItem => !totalList.some(selectItem => selectItem.value === mainItem.value && selectItem.label === mainItem.label));


  
    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Students</div>
            </div>
            <div className='container mt-3'>
                <div class="row">
                    {!_.get(props, 'userState.row') && <div className='main-schedule-head ms-3' ><p className='Title-main-head'>Create Batch</p></div>}
                    {_.get(props, 'userState.row') && <div className='main-schedule-head ms-3'><p className='Title-main-head'>Edit Batch</p></div>}
                </div>
                <Formik
                    initialValues={{
                        category: '',
                        sub_category: '',
                        topic: '',
                        batch_id: '',
                        student_id: '',
                        course_link: '',
                        message: '',
                        from: '',
                    }}
                    validationSchema={BatchValidationSchema}
                    onSubmit={(values, { resetForm }) => {
                        ToSubmit();
                        resetForm();
                    }} >
                    {({ errors, touched, setFieldValue }) => (
                        <Form>
                            <div className='row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose subject</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={batch.subject_id}
                                                name="subject_id"
                                                as="select"
                                                onChange={(e) => {
                                                    handleSubjectChange(e); // Call handleSubjectChange here
                                                    const selectedSubjectId = parseInt(e.target.value, 10);
                                                    setFieldValue("subject_id", selectedSubjectId);
                                                    handleChange(e);
                                                    listStudents(selectedSubjectId);
                                                }}
                                                required=""
                                            >
                                                 <option value="">--select an option--</option>
                                                {subject.map((subject, index) => (
                                                    <option key={index} value={subject.id}>
                                                        {subject.name}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="subject_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Subject</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={batch.subject_id}
                                                name="subject_id"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedSubjectId = parseInt(e.target.value, 10);
                                                    setFieldValue("subject_id", selectedSubjectId);
                                                    handleChange(e);
                                                    listStudents(selectedSubjectId);
                                                }}

                                                onClick={listSubject}
                                                required=""
                                            >
                                                <option value="">--select an option--</option>
                                                {subject.map((subject, index) => (
                                                    <option key={index} value={subject.id}>
                                                        {subject.name}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="subject_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Category</label>
                                            <Field
                                                className="form-select  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={batch.category}
                                                name="category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 2,
                                                        "category_id": selectedValue,
                                                    };
                                                    dispatch(getSubCategoryData(payload));
                                                    setFieldValue("category", e.target.value)
                                                    handleChange(e);
                                                }}
                                            >

                                                {categoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2  mb-0 teacher-font-label">Choose Category</label>
                                            <Field
                                                className="form-select  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={batch.category}
                                                name="category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 2,
                                                        "category_id": selectedValue,
                                                    };
                                                    dispatch(getSubCategoryData(payload));
                                                    setFieldValue("category", e.target.value)
                                                    handleChange(e);
                                                }}
                                            >
                                                <option value="" >Select an option</option>
                                                {categoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Sub Category</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={batch.sub_category}
                                                name="sub_category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 3,
                                                        "sub_category_id": selectedValue,
                                                    };
                                                    dispatch(getSubTopicData(payload));
                                                    setFieldValue("sub_category", e.target.value)
                                                    handleChange(e);
                                                }}
                                            >

                                                {subCategoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="sub_category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label mb-0 teacher-font-label">Choose Sub Category</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={batch.sub_category}
                                                name="sub_category"
                                                as="select"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const payload = {
                                                        "type": 3,
                                                        "sub_category_id": selectedValue,
                                                    };
                                                    dispatch(getSubTopicData(payload));
                                                    setFieldValue("sub_category", e.target.value)
                                                    handleChange(e);
                                                }}
                                            >
                                                <option value="" >Select an option</option>
                                                {SubCategoryOptionsList.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="sub_category"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>
                                {/* <div class="row "> */}
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Topic</label>
                                            <Field
                                                className="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field"
                                                value={batch.topic}
                                                name="topic"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("topic", e.target.value)
                                                    handleChange(e)
                                                }}
                                            >

                                                {topicOptions.map((option) => (
                                                    <option key={option.vale} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="topic"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4  ms-xxl-5 ms-xl-5 ms-lg-2 mb-0  mb-0 teacher-font-field">Choose Topic</label>
                                            <Field
                                                className="form-select ms-5 form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-label"
                                                value={batch.topic}
                                                name="topic"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue("topic", e.target.value)
                                                    handleChange(e)
                                                }}

                                            >
                                                <option value="" >Select an option</option>
                                                {TopicOptionsList.map((option) => (
                                                    <option key={option.vale} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage
                                                name="topic"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                </div>
                            </div>

                            <div className='row row px-xxl-5 px-xl-5 px-lg-3 px-md-0'>
                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {/* <> */}
                                    {!_.get(props, 'userState.row') && <label className="form-label mt-4  ms-xxl-5 ms-xl-5 ms-lg-2 mb-0  mb-0 teacher-font-field" >Batch Name</label>}<br />
                                    {!_.get(props, 'userState.row') && <Field type='text' class="form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field" value={batch.name} name='name' onChange={(e) => {
                                        setFieldValue("name", e.target.value)
                                        handleChange(e)
                                    }} required="" />}

                                    {!_.get(props, 'userState.row') && <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                    />}

                                    {/* </> */}
                                    {_.get(props, 'userState.row') && <label className="form-label  ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >Batch Name</label>}<br />
                                    {_.get(props, 'userState.row') && <Field type='text' className='form-control ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field' value={batch.name} name='batch' onChange={(e) => {
                                        setFieldValue("name", e.target.value)
                                        handleChange(e)
                                    }} />}
                                    {_.get(props, 'userState.row') && <ErrorMessage
                                        name="category"
                                        component="div"
                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                    />}
                                </div>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    <>
                                        {!_.get(props, 'userState.row') && <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >Start Date</label>}<br />
                                        {!_.get(props, 'userState.row') && <Field type='date' class="form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field" placeholder="Leave a comment here" value={batch.starting_at} name='starting_at' onChange={(e) => {
                                            setFieldValue("starting_at", e.target.value)
                                            handleChange(e)
                                        }} required="" />}
                                        {!_.get(props, 'userState.row') && <ErrorMessage
                                            name="starting_at"
                                            component="div"
                                            className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                        />}
                                    </>
                                    {_.get(props, 'userState.row') && <label className="form-label ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label" >start Date</label>}<br />
                                    {_.get(props, 'userState.row') && <input type='date' className='form-select ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field' value={batch.starting_at} name='starting_at' onChange={(e) => {
                                        setFieldValue("starting_at", e.target.value)
                                        handleChange(e)
                                    }} />}
                                    {_.get(props, 'userState.row') && <ErrorMessage
                                        name="starting_at"
                                        component="div"
                                        className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                    />}
                                </div>

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 subtitle-headings'>
                                    {!_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Student</label>
                                            <InputGroup>
                                                <Select
                                                    value={totalList}
                                                    onChange={handleMultipleChange}
                                                    options={convertedData}
                                                    className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                    isMulti
                                                />
                                            </InputGroup>
                                            <ErrorMessage
                                                name="student_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />

                                        </>
                                    )}
                                    {_.get(props, 'userState.row') && (
                                        <>
                                            <label className="form-label mt-4 ms-xxl-5 ms-xl-5 ms-lg-2 mb-0 teacher-font-label">Choose Student</label>
                                            <InputGroup>
                                                <Select
                                                    value={totalList}
                                                    onChange={handleMultipleChange}
                                                    options={convertedData}
                                                    className="ms-xxl-5 ms-xl-5 ms-lg-2 "
                                                    isMulti
                                                />
                                            </InputGroup>
                                            <ErrorMessage
                                                name="student_id"
                                                component="div"
                                                className="text-danger  ms-xxl-5 ms-xl-5 ms-lg-2 teacher-font-field "
                                            />
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className='row main_row ms-5 mt-5'>
                                <div className='d-flex justify-content-center'>
                                    <div className='col main_row'>
                                        <div className='row text-start'>
                                            <div className='col'>
                                                {!_.get(props, 'userState.row') && <p>Scheduling days & Timings</p>}
                                                {_.get(props, 'userState.row') && <p>Edit Scheduling days & Timings</p>}
                                            </div>
                                        </div>

                                        <div className='row'>
                                            {!_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.monday.isChecked}
                                                            onChange={() => handleCheckboxChange('monday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Monday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1 '>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.monday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.monday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("Monday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.monday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.monday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.monday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("Monday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.monday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}


                                            {_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.monday.isChecked}
                                                            onChange={() => handleCheckboxChange('monday')}
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Monday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1 '>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.monday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.monday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("Monday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.monday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.monday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.monday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("Monday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.monday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className='row'>
                                            {!_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.tuesday.isChecked}
                                                            onChange={() => handleCheckboxChange('tuesday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Tuesday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.tuesday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.tuesday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("tuesday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.tuesday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.tuesday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.tuesday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("tuesday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.tuesday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.tuesday.isChecked}
                                                            onChange={() => handleCheckboxChange('tuesday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Tuesday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.tuesday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.tuesday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("tuesday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.tuesday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.tuesday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.tuesday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("tuesday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.tuesday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className='row'>
                                            {!_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.wednesday.isChecked}
                                                            onChange={() => handleCheckboxChange('wednesday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Wednesday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.wednesday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.wednesday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("wednesday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.wednesday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.wednesday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.wednesday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("wednesday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.wednesday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.wednesday.isChecked}
                                                            onChange={() => handleCheckboxChange('wednesday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Wednesday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.wednesday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.wednesday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("wednesday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.wednesday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.wednesday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.wednesday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("wednesday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.wednesday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className='row'>
                                            {!_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.thursday.isChecked}
                                                            onChange={() => handleCheckboxChange('thursday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Thursday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.thursday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.thursday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("thursday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.thursday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.thursday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.thursday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("thursday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.thursday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.thursday.isChecked}
                                                            onChange={() => handleCheckboxChange('thursday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Thursday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.thursday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.thursday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("thursday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.thursday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.thursday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.thursday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("thursday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.thursday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className='row'>
                                            {!_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.friday.isChecked}
                                                            onChange={() => handleCheckboxChange('friday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Friday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.friday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.friday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("friday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.friday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.friday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.friday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("friday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.friday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.friday.isChecked}
                                                            onChange={() => handleCheckboxChange('friday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Friday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.friday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.friday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("friday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.friday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.friday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.friday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("friday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.friday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className='row'>
                                            {!_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.saturday.isChecked}
                                                            onChange={() => handleCheckboxChange('saturday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Saturday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.saturday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.saturday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("saturday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.saturday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.saturday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.saturday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("saturday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.saturday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.saturday.isChecked}
                                                            onChange={() => handleCheckboxChange('saturday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Saturday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.saturday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.saturday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("saturday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.saturday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.saturday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.saturday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("saturday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.saturday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className='row'>
                                            {!_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.sunday.isChecked}
                                                            onChange={() => handleCheckboxChange('sunday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Sunday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.sunday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.sunday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("sunday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.sunday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.sunday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.sunday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("sunday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.sunday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {_.get(props, 'userState.row') && (
                                                <>
                                                    <div className='col-1'>
                                                        <input type='checkbox'
                                                            checked={batch.dates.sunday.isChecked}
                                                            onChange={() => handleCheckboxChange('sunday')}
                                                        />
                                                    </div>
                                                    <div className='col-1'>
                                                        <p className='dates_sched'>Sunday</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>From</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.sunday.from}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.sunday.from"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("sunday_from", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.sunday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>

                                                    <div className='col-1'>
                                                        <p className='dates_sched'>To</p>
                                                    </div>

                                                    <div className='col-1'>
                                                        <input
                                                            type='time'
                                                            className='inp_schedule'
                                                            value={batch.dates.sunday.to}
                                                            placeholder="HH:MM:SS"
                                                            name="dates.sunday.to"
                                                            onChange={(e) => {
                                                                const formattedValue = e.target.value + ':00'; // Append seconds
                                                                e.target.value = formattedValue; // Update input value
                                                                setFieldValue("sunday_to", formattedValue); // Update Formik field value
                                                                handleInput(e); // Call handleInput function with formatted value
                                                            }}
                                                            disabled={!batch.dates.sunday.isChecked} // Disable if checkbox is not checked
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    {!_.get(props, 'userState.row') && <button type='submit' className='btn text-light px-4 py-1 bootBtn my-5' onClick={(e) => ToSubmit()} >Submit</button>}
                                    {_.get(props, 'userState.row') && <button type='submit' className='btn text-light px-4 py-1 bootBtn my-5' onClick={(e) => ToEdit()} >update</button>}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    )
}

export default CreateTeacherBatch