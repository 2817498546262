import React from 'react'
import img from '../../../../../../DashImage/GroupImg.png';
import img1 from '../../../../../../DashImage/Graph.png'
import img2 from '../../../../../../DashImage/GraphDown.png'
import img3 from '../../../../../../DashImage/Quires.png'
import img4 from '../../../../../../DashImage/Sales.png'
import CalenderComponent from '../adminPage/calender.component';
import DashboardSubjectTableComponent from '../adminPage/DashboardSubjectTable.component';
import DashboardNotificationComponent from '../adminPage/dashboardNotification.component';

function FreelanceDashboardComponent() {
    return (
        <div>

            <div >
                <h5 class='fw-bold px-4 pt-4 pb-2'>Freelancer Dashboard</h5>
                <div class="container m-lg-4">
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div className='admin-card p-3 '>
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <p class='admin-gray-text'>Total Students</p>
                                            <h4 class='fw-bold'>5</h4>
                                        </div>
                                        <div class="col-4">
                                            <img src={img} class="img-fluid rounded-top " alt="" />
                                        </div>
                                    </div>
                                    <div class="row mt-3 ">
                                        <div class="col">
                                            <p class='mb-0'><span><img src={img1} class="img-fluid rounded-top" alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div className='admin-card p-3 '>
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <p class='admin-gray-text'>Total Students</p>
                                            <h4 class='fw-bold'>5</h4>
                                        </div>
                                        <div class="col-4">
                                            <img src={img3} class="img-fluid rounded-top " alt="" />
                                        </div>
                                    </div>
                                    <div class="row mt-3 ">
                                        <div class="col">
                                            <p class='mb-0'><span><img src={img1} class="img-fluid rounded-top" alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div className='admin-card p-3 '>
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <p class='admin-gray-text'>Total Students</p>
                                            <h4 class='fw-bold'>5</h4>
                                        </div>
                                        <div class="col-4">
                                            <img src={img4} class="img-fluid rounded-top " alt="" />
                                        </div>
                                    </div>
                                    <div class="row mt-3 ">
                                        <div class="col">
                                            <p class='mb-0'><span><img src={img1} class="img-fluid rounded-top" alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-8 col">
                            <DashboardSubjectTableComponent />
                        </div>
                        <div class="col-lg-4 col mt-3 mt-lg-0">
                            <CalenderComponent />
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col">
                            <DashboardNotificationComponent />
                        </div>
                        <div class="col mt-3 mt-lg-0">
                            <DashboardNotificationComponent />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FreelanceDashboardComponent