import React, { useState, useEffect } from 'react';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import axios from 'axios';
import './TeacherBatchView.scss';
import DataTable from 'react-data-table-component';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';
import { format } from 'date-fns';

function TeacherBatchView(props) {
    const [data, setData] = useState([{}]);
const BasePath='http://tuitionguruji.com/backend/public/'
    const ViewBatch = props.userState.row.id || {};
    const [view, setView] = useState({
        id: '',
        resources: []
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchBatch();
    }, []);

    const fetchBatch = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        const CreateNotifi = localStorage.getItem("createData") ? JSON.parse(localStorage.getItem('createData')) : {};
        const formData = new FormData();
        formData.append("id", ViewBatch);

        Axios.post('/api/viewBatch',
            formData,
            { headers: headers })
            .then((response) => {
                const updateData = response.data.data;
                // Parse the resources JSON string
                updateData.batch.resources = JSON.parse(updateData.batch.resources);
                setView(updateData);
            })
            .catch((error) => {
                console.error("Error fetching view batch data:", error);
            });
    }

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
    }, []);

    const fetchBatchList = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        Axios.post('/api/listBatch', undefined, { headers: headers })
            .then((response) => {
                const newData = response.data.data.batch.map((item) => ({
                    ...item,
                    starting_at: format(new Date(item.starting_at), 'dd-MM-yyyy'),
                }));
                setData(newData);
                setLoading(false); // Set loading state to false after fetching
            })
            .catch((error) => {
                console.error('Error fetching Batch List:', error);
                setLoading(false); // Set loading state to false in case of error
            });
    };

    useEffect(() => {
        fetchBatchList();
    }, []);

    const initializeObject = () => {
        setView({ ...view, ...props.userState.row });
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherstudent' });
        }
    }

    const customStyle = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    };

    const columns = [
        {
            name: 'Days',
            selector: row => row.day,
            sortable: true,
        },
        {
            name: 'From',
            selector: row => row.from, // Display time with AM/PM
            sortable: true,
        },
        {
            name: 'To',
            selector: row => row.to, // Display time with AM/PM
            sortable: true,
        },
    ];

    function formatTime(timeString) {
        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true, // Use 12-hour format with AM/PM
        };
        const formattedTime = new Date(timeString).toLocaleTimeString(undefined, options);
        return formattedTime;
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    };

    const column = [
        {
            name: 'Student Id',
            selector: row => row.student_id,
            sortable: true,
        },
        {
            name: 'Student Name',
            selector: row => row.firstname,
            sortable: true,
        },
    ];

    const colum = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
        },
        {
            name: 'Link',
            selector: row => (
                <a href={BASE_URL + row.link} target="_blank" rel="noopener noreferrer">
                    View
                </a>
            ),
            sortable: true,
        },
    ];

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }

    return (
        <div className='main-divHead'>
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Students</div>
            </div>

            <div className="container text-start teacher-cardss">
                <div className="card col-12 mt-5">
                    <div className="card-body">
                        <div className="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo'>Course Name :</p>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo'>{view?.subject?.name}</p>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo'>Batch Name :</p>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo'>{view?.batch?.name}</p>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className='d-flex justify-content-center'>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo'>Batch Date :</p>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <p className='techer-card-typo'>{formatDate(view?.batch?.starting_at)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col ms-5 mt-5">
                    <p className='batch_text'>Batch Schedule</p>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="col-xxl-8 col-xl-10 col-lg-12 justify-content-center">
                    <div className="table-responsive">
                        <DataTable
                            className='dataTable'
                            data={view?.scheduledtime}
                            columns={columns}
                            customStyles={customStyles}
                            striped
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col ms-5 mt-5">
                    <p className='batch_text'>Student List</p>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="col-xxl-8 col-xl-10 col-lg-12 justify-content-center">
                    <div className="table-responsive">
                        <DataTable
                            className='dataTable'
                            data={view?.students}
                            columns={column}
                            customStyles={customStyle}
                            striped
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col ms-5 mt-5">
                    <p className='batch_text'>Resources</p>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="col-xxl-8 col-xl-10 col-lg-12 justify-content-center">
                    <div className="table-responsive">
                        <DataTable
                            className='dataTable'
                            data={view?.batch?.resources}
                            columns={colum}
                            customStyles={customStyle}
                            striped
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeacherBatchView;
