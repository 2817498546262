import React, { useEffect, useState } from 'react'
import { RiArrowRightLine } from "react-icons/ri";
import { PiMonitorPlayBold } from "react-icons/pi"; import _ from 'lodash';
import axios from 'axios';
import StarRatings from "react-star-ratings";
import { MdVideoCameraBack } from "react-icons/md";
import { FaFacebook, FaImage, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import RatingsComponent from './Rating';
import { IoIosArrowBack } from 'react-icons/io';
import { showErrorToast, showSuccessToast } from '../../../../../../../util/toastUtils';
import './EnrolledSubjects.css'
import BASE_URL from '../../../../../../../BaseUrl';
import Axios from '../../../../../../../util/apiUtil';
function EnrolledSubject({ ...props }) {


    const ViewCourses = props.showOrder || {};
    console.log("fff", ViewCourses);
    const [subjectData, setSubjectData] = useState(null);
    const [error, setError] = useState(null);
    const subjectId = props.showOrder && props.showOrder.subject_id;



    useEffect(() => {
        if (subjectId) {
            const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
            Axios.post('/api/viewSubject', { subject_id: subjectId }, { headers: headers })
                .then(response => {
                    setSubjectData(response.data.data); // Assuming response.data.data contains the required subject data
                })
                .catch(error => {
                    console.error('Error fetching subject data:', error);
                    setError(error);
                });
        }
    }, [subjectId]);


    const [review, setReview] = useState('');
    const [rating, setRating] = useState(0);

    const changeRating = (newRating) => {
        setRating(newRating);
    };

    const handleReviewChange = (event) => {
        setReview(event.target.value);
    };
    const StuBackdashboard = () => {

        navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
    }

    const [showNext, setShowNext] = useState(false);
    const goToPlaceordernext = (e, link) => {
        setShowNext(true);
    }


    const [showView, setShowView] = useState(false);
    const [loading, setLoading] = useState(true);
    const getUserState = () => {
        return { selectedCourse: showView };
    };
    const basePath = "http://tuitionguruji.com/backend/public/";

    const getTimeForday = (day) => {
        if (subjectData && subjectData.schedule_day) {
            const schedule = subjectData.schedule_day;
            const matchingSchedule = schedule.find(item => item.day.toLowerCase() === day.toLowerCase());
            if (matchingSchedule) {
                return `${matchingSchedule.from} - ${matchingSchedule.to}`;
            }
        }
        return "-";
    };

    const handlefacebook = () => {
        if (ViewCourses.teacherProfile && ViewCourses.teacher.facebook) {
            window.open(ViewCourses.teacher.facebook, '_blank');
        } else {
            console.error("Facebook URL is not available.");
            // Optionally, you can handle this case in another way (e.g., display a message to the user)
        }
    };
    const handletwitter = () => {
        if (ViewCourses.teacher && ViewCourses.teacher.twitter) {
            window.open(ViewCourses.teacher.twitter, '_blank');
        } else {
            console.error("twitter URL is not available.");
            // Optionally, you can handle this case in another way (e.g., display a message to the user)
        }
    };
    const handlelinkedin = () => {
        if (ViewCourses.teacher && ViewCourses.teacher.linkedin) {
            window.open(ViewCourses.teacher.linkedin, '_blank');
        } else {
            console.error("linkedin URL is not available.");
            // Optionally, you can handle this case in another way (e.g., display a message to the user)
        }
    };
    const handleinstagram = () => {
        if (ViewCourses.teacher && ViewCourses.teacher.instagram) {
            window.open(ViewCourses.teacher.instagram, '_blank');
        } else {
            console.error("instagram  URL is not available.");
            // Optionally, you can handle this case in another way (e.g., display a message to the user)
        }
    };
    const handleyoutube = () => {
        if (ViewCourses.teacher && ViewCourses.teacher.youtube) {
            window.open(ViewCourses.teacher.youtube, '_blank');
        } else {
            console.error("youtube  URL is not available.");
            // Optionally, you can handle this case in another way (e.g., display a message to the user)
        }
    };
    const navigate = useNavigate()

    const handleSubmit = () => {
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
        const formData = new FormData();
        formData.append('rating', rating);
        formData.append('subject_id', ViewCourses.id);
        formData.append('review', review); // Add review data to form data
        console.log("formData", formData); // Log formData here
        console.log("headers", headers);
        Axios.post('/api/markRating', formData, { headers: headers }) // Replace '<your_api_endpoint>' with your actual API endpoint
            .then(() => {
                showSuccessToast("Rating submit successfully");
            })
            .catch((error) => {
                console.error('Error in rating:', error);
                setLoading(false);
                showErrorToast("Failed to submit rating");
            });
    };















    // const handlePreviewClick = () => {
    //     window.open(subData.subject.video_link, '_blank');
    //   };

    const [selectedChapter, setSelectedChapter] = useState(null);
    const toggleCollapse = (index) => {
        if (selectedChapter === index) {
            setSelectedChapter(null); // Collapse if already clicked
        } else {
            setSelectedChapter(index); // Expand if not clicked
        }
    };

    return (
        <>

            {!showNext &&

                <div class="container ">
                    <div class="row">

                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-7 col  mt-4">
                            <div class="admin-card-sub text-center">
                                <div class="row">
                                    <div class="col">
                                        <img
                                            className="img-fluid mt-2"
                                            src={ViewCourses && ViewCourses.image ? (basePath + ViewCourses.image) : ''}
                                            alt="API Image"
                                            style={{ width: '400px', height: '400px' }}
                                        />
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col p-0">
                                        <p class=" sub-sidehead-course">About Course</p>
                                    </div>
                                    {/* <div class="col ">
                                        <div class="d-flex justify-content-center">
                                            <button className="outline-btn-admin px-2 fw-bold mt-2"
                                            //    onClick={handlePreviewClick}
                                            >
                                                <span>
                                                    <MdVideoCameraBack class="adminEyeIcon me-1 mb-1" />
                                                </span>{" "}
                                                Preview course{" "}
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                                <div class="row">
                                    <div className="col ">
                                        <p className="text-start sub-des-about mx-2"> {_.get(ViewCourses, 'description')}</p>
                                    </div>
                                </div>
                                {/* <div class="row">
                                    <div class="col">
                                        <p class="fs-6 text-start ms-1">
                                            Ratings{" "}
                                            <span>
                                                <StarRatings
                                                    className="sub-rating-star"
                                                    starRatedColor={"#F2DE25"}
                                                    starDimension={"10px"}
                                                    rating={2.403}
                                                    starHoverColor="black"
                                                    starSpacing="1px"
                                                />
                                            </span>
                                            <span class="fw-bold">5.0</span> (240){" "}
                                            <span class="fw-bold">View All</span>
                                        </p>
                                    </div>
                                </div> */}
                                <div class="row my-2">
                                    <div class="col">
                                        <hr></hr>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="container text-start">

                                            <div class="row">
                                                <div className="d-flex justify-content-between"></div>
                                                <div class="col">
                                                    <p class="fs-5 fw-semibold">Course Name</p>
                                                </div>
                                                <div class="col">
                                                    <p class="fs-5 "><b>{_.get(ViewCourses, 'name')}</b></p>
                                                </div>
                                            </div>


                                            {/* <div class="row">
                                                <div className="d-flex justify-content-between"></div>
                                                <div class="col">
                                                    <p class="fs-5 fw-semibold">Description</p>
                                                </div>
                                                <div class="col">
                                                    <p class="fs-5">{_.get(ViewCourses, 'description')}</p>
                                                </div>
                                            </div> */}

                                            <div class="row">
                                                <div className="d-flex justify-content-between"></div>
                                                <div class="col">
                                                    <p class="fs-5 fw-semibold"> Price </p>
                                                </div>
                                                <div class="col">
                                                    <p class="fs-5 ">{_.get(ViewCourses, 'price')}</p>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div className="d-flex justify-content-between"></div>
                                                <div class="col">
                                                    <p class="fs-5 fw-semibold">Teaching Language </p>
                                                </div>
                                                <div class="col">
                                                    <p class="fs-5 ">{_.get(ViewCourses, 'language')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col">
                                        <hr></hr>
                                    </div>
                                </div>
                                <div className="row text-center">
                                    <div className="col">
                                        <a href={subjectData && subjectData.video_link} target="_blank" rel="noopener noreferrer" className="btn outline-btn-admin px-2 fw-bold mt-2 mb-3">
                                            <span>
                                                <PiMonitorPlayBold className="adminEyeIcon me-1 mb-1" />
                                            </span>{" "}
                                            Resources{" "}
                                            <span>
                                                <RiArrowRightLine className="adminEyeIcon ms-2" />
                                            </span>
                                        </a>
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-7 col mt-4">
                            <div class="admin-card">
                                <h4 class="fs-5 fw-bold p-3">Live Class</h4>
                                <div class="container text-start">
                                    <div class="row">
                                        <div class="col-3" style={{ display: 'grid', placeItems: 'center' }}>
                                            <div>
                                                {subjectData && subjectData.teacherProfile && subjectData.teacherProfile.profile_pic ? (
                                                    <img
                                                        className="img-fluid mt-2"
                                                        src={subjectData.teacherProfile.profile_pic}
                                                        alt="Teacher Profile Picture"
                                                        style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                                    />
                                                ) : (
                                                    <FaImage size={60} />
                                                )}
                                            </div>

                                            <div className="social-media-links ">

                                                <FaFacebook className="social-media-icon fafacebook ms-3" onClick={handlefacebook} />

                                                <FaTwitter className="social-media-icon ms-3" onClick={handletwitter} />
                                                <FaLinkedin className="social-media-icon ms-3" onClick={handlelinkedin} />
                                                <FaInstagram className="social-media-icon ms-3" onClick={handleinstagram} />
                                                <FaYoutube className="social-media-icon ms-3" onClick={handleyoutube} />
                                            </div>
                                        </div>

                                        <div class="col">
                                            <div className="row">
                                                <div className="col">
                                                    {" "}
                                                    <p className="teacher-course-detail-head">
                                                        {subjectData && subjectData.teacherProfile && subjectData.teacherProfile.user && subjectData.teacherProfile.user.firstname}
                                                    </p>
                                                </div>
                                                <div className="col">
                                                    <button
                                                        className="outline-btn-admin px-4 py-1 fw-bold"
                                                        onClick={() => goToPlaceordernext()}>
                                                        Rate this course
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col text-start fw-bold  mt-5 ms-3">



                                                <div class="">
                                                    <div class="row  ms-5 ">
                                                        <div class="col-4 ">
                                                            <p>Bio :  </p></div>

                                                        <div class="col-8"><p > {subjectData && subjectData.teacherProfile && subjectData.teacherProfile.teacher && subjectData.teacherProfile.teacher.education &&
                                                subjectData.teacherProfile.teacher.education.map((edu, index) => (
                                                    <div key={index} className="education-item">
                                                        <p>{edu.degree}{" "}{edu.field_of_study}{" "}From{" "} {edu.organization}</p>

                                                    </div>
                                                ))
                                            }<br /></p><br /></div>
                                                    </div>
                                                </div>

                                                <div class="">
                                                    <div class="row  ">
                                                        <div class="col-4 ms-5 ">
                                                            <p >Education : </p>
                                                        </div>

                                                        <div class="col">
                                                            {_.get(subjectData, 'teacherProfile.teacher.education') ? (
                                                                <p>
                                                                    {" "}
                                                                    <div>
                                                                        <div className="col-1 ms-3">

                                                                        </div>
                                                                        {subjectData && subjectData.teacherProfile && subjectData.teacherProfile.teacher && subjectData.teacherProfile.teacher.education &&
                                                                            subjectData.teacherProfile.teacher.education.map((edu, index) => (
                                                                                <div key={index} className="education-item">
                                                                                    <p>{edu.degree}{" "}{edu.field_of_study}{" "}From{" "} {edu.organization}</p>

                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>

                                                                </p>
                                                            ) : (
                                                                <p>No Education available</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="row  ms-4 ">
                                                        <div class="col-4 ">
                                                            <p>Working Experiences :  </p></div>

                                                        <div class="col-4 ms-3"><p > {subjectData && subjectData.teacherProfile && subjectData.teacherProfile.teacher && subjectData.teacherProfile.teacher.teaching_background}years<br /></p><br /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                             
                                    <div className="col">
                                        <div>
                                            <h2 className="mt-2">Course Syllabus<hr className="col-4 px-3" /></h2>

                                        </div>

                                        <div className="col ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 mt-3 ">
                                            <div className="row ms-3">
                                                {subjectData?.subjectChapter && subjectData.subjectChapter.map((chapter, index) => (
                                                    <div key={index} className="col-12 mt-4   mt-3 mb-3 fw-bold "
                                                        onClick={() => toggleCollapse(index)}
                                                    >
                                                        <h4 className="bold">{`${index + 1}. ${chapter.name}`}</h4><p className="d-flex justify-content-end">{chapter.duration}{" "}hours</p>
                                                        <p>{chapter.description}</p>
                                                        <div className="d-flex justify-content-end">
                                                            <button
                                                                className="outline-btn-admin px-4 py-1 fw-bold "

                                                            >
                                                                chapters
                                                            </button>
                                                        </div>

                                                        {selectedChapter === index ? (
                                                            chapter.topics && chapter.topics.length > 0 ? (
                                                                chapter.topics.map((topic, topicIndex) => (
                                                                    <div className="mb-3" key={topicIndex}>
                                                                        <div>
                                                                            <h5>{`${index + 1}.${topicIndex + 1} ${topic.title || "Topic Title"}`}</h5>
                                                                            <p className="d-flex justify-content-end">{topic.duration} hours</p>
                                                                            <p>{topic.description || "Topic Description"}</p>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="mb-3">
                                                                    <p> Chapter details are not available</p>
                                                                </div>
                                                            )
                                                        ) : null}

                                                    </div>
                                                ))}
                                            </div>




                                        </div>
                                    </div>

                                    {/* <div class="container text-start">
                                        <div class="row text-start">
                                            <div class="col">
                                                <p> Days</p>
                                            </div>
                                            <div class="col">
                                                <p> Timing</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p> Monday</p>
                                            </div>
                                            <div class="col">
                                                <p>{getTimeForday('monday')}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p> Tuesday</p>
                                            </div>
                                            <div class="col">
                                                <p>{getTimeForday('tuesday')}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p> Wednesday</p>
                                            </div>
                                            <div class="col">
                                                <p>{getTimeForday('wednesday')}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p> Thursday</p>
                                            </div>
                                            <div class="col">
                                                <p>{getTimeForday('thursday')}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p> Friday</p>
                                            </div>
                                            <div class="col">
                                                <p>{getTimeForday('friday')}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p> Saturday</p>
                                            </div>
                                            <div class="col">
                                                <p>{getTimeForday('saturday')}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p> Sunday</p>
                                            </div>
                                            <div class="col">
                                                <p>{getTimeForday('sunday')}</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="row mt-4">
                    <div className="col">
                      <p className="fs-5 fw-semibold">Reviews</p>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col">
                      <div class="admin-card admin-video-personCard p-3 m-4">
                        <div class="container mx-3">
                          <div class="row">
                            <div class="col-3">
                              <img
                                src={img1}
                                class="img-fluid rounded-top "
                                alt=""
                              />
                            </div>
                            <div class="col">
                              <h5>Abilash</h5>
                              <p>1 week ago</p>
                            </div>
                          </div>
                          <div class="row text-start">
                            <div class="col">
                              <p class=" ">
                                Amet minim mollit non deserunt ullamco est sit
                                aliqua dolor do amet sint. Velit officia
                                consequat duis enim velit mollit. Exercitation
                                veniam consequat sunt nostrud amet.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div class="admin-card admin-video-personCard p-3 m-4">
                        <div class="container mx-3">
                          <div class="row">
                            <div class="col-3">
                              <img
                                src={img1}
                                class="img-fluid rounded-top "
                                alt=""
                              />
                            </div>
                            <div class="col">
                              <h5>Abilash</h5>
                              <p>1 week ago</p>
                            </div>
                          </div>
                          <div class="row text-start">
                            <div class="col">
                              <p class=" ">
                                Amet minim mollit non deserunt ullamco est sit
                                aliqua dolor do amet sint. Velit officia
                                consequat duis enim velit mollit. Exercitation
                                veniam consequat sunt nostrud amet.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }

            {showNext &&
                <div className="main-divHead">
                    <div className='mt-5 d-flex justify-content-center'>
                        <div className=" col-8 border-0 mb-5">
                            <div className="card-body">
                                <h1 className='d-flex justify-content-center'>Put Your Review</h1><hr />
                                <p className='d-flex justify-content-center'>How much you Rate this Course</p>
                                <div className='d-flex justify-content-center'>
                                    <StarRatings
                                        rating={rating}
                                        starRatedColor="#FFC700"
                                        changeRating={changeRating}
                                        numberOfStars={5}
                                        name='rating'
                                        starDimension="60px"
                                        halfStars={true} // Enable half stars
                                    />

                                </div>

                                <h4 className='d-flex justify-content-center mt-5'>Share Your Experience about this Course and Tutor</h4>
                                <div className='d-flex justify-content-center'>
                                    <div className='col-8 '>
                                        <textarea
                                            className="form-control"
                                            placeholder="Leave experience here"
                                            value={review}
                                            onChange={handleReviewChange}
                                            maxLength={150}
                                        ></textarea>
                                        <div className='text-left'>
                                            {150 - review.length}/150
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center mt-3'>
                                    <button className="btn btn-primary" onClick={handleSubmit}>Submit Review</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='row'>

                        {/* Mapping through each rating */}
                        {subjectData && subjectData.ratings && subjectData.ratings.length > 0 ? (
                            subjectData.ratings.map((rating, index) => (
                                <div key={index} className="col-4 mb-4 ">
                                    <div class="card custom-card ">
                                        <div class="card-body">
                                            <div className=''>

                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <h4>{rating.firstname}</h4>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <StarRatings
                                                    rating={parseFloat(rating.ratings)}
                                                    starRatedColor="#FFC700"
                                                    numberOfStars={5}
                                                    name={`rating_${index}`}
                                                    starDimension="38px"
                                                    starSpacing="3px"
                                                />
                                            </div>
                                            <div className='review_para'>
                                                {rating.review ? rating.review : "No review available"}
                                            </div>
                                            <p className="p-rating-date mt-2 text-end text-muted">
                                                {rating.updated_at ? new Date(rating.updated_at).toLocaleDateString() : ''}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No ratings available</p>
                        )}
                    </div>
                </div>



            }


        </>

    )
}


export default EnrolledSubject