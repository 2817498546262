import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import assignmentWhiteIcon from "../../../icons/myassignmentWhiteIcon.png"
import assignmentBlueIcon from "../../../icons/myassignmentBlueIcon.png"
import _ from 'lodash';
import CreateIcon from '@mui/icons-material/Create';
import { BsPenFill } from "react-icons/bs";



function MyAssignments({ ...props }) {
  const navigate = useNavigate()
  // const [hoverEffect, setHoverEffect] = useState(false)
  const goToassignment = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Assignment' })
    }
  }
  return (
    <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'Assignment' ? 'active' : ''} `} onClick={(e) => goToassignment(e)} >
      <div className="dash d-flex">
        <BsPenFill className="ms-3" />
        <p className='head-dashboard ms-2' onClick={(e) => goToassignment(e)}>My Assignments</p>
      </div>
    </div>
  )
}

export default MyAssignments;
