import React from 'react'

function PartnerLanding() {
  return (
    <div>
      <div class='d-flex justify-content-center py-2   bg-black text-white'>
        <h4 class='fw-normal'>
        {/* <p className="landing-online-txt mb-0 mt-1">Want to run your own online school?</p> */}
        </h4>
        {/* <button className="bottom-btn ms-4" > Partner with us</button> */}
      </div>
    </div>
  )
}

export default PartnerLanding