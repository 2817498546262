import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from '@mui/material/Box';
import { ToastContainer, toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import _ from 'lodash';
import './QuoteJobFreelance.scss'
import axios from 'axios';
import { Grid } from "@mui/material";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
function QuoteJobFreelance({ ...props }) {
    const Viewquote = props.userState.row.id

    const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal
    const [quotations, setQuotation] = useState({})
    const [quote, setQuote] = useState({
        message: "",
        term: "",
        currency: "",
        price: "",
        days: ""
    })
    // Update the fetchViewQuotation function
    const fetchViewQuotation = () => {
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        const formData = new FormData();
        formData.append('id', Viewquote); // Use quotationss.quotation.id

        axios.post("http://tuition.techiesmarkets.com/backend/public/api/viewQuotation", formData, { headers: headers })
            .then((response) => {

                setQuotation(response.data.data.quotation);
                // Update the quotation state
            })
            .catch((error) => {
                console.error("Error fetching view quotation:", error);
            });
    };
    const handleInput = (e) => {
        const { name, value } = e.target;
        // Update other fields in QuotationFreelance
        setQuote({ ...quote, [name]: value });
    };
    const QuotationTeacher = () => {
        setOpen(false);
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const formData = new FormData();
        formData.append("id", Viewquote);
        formData.append("message", quote.message);
        formData.append("term", quote.term);
        formData.append("currency", quote.currency);
        formData.append("price", quote.price);
        formData.append("days", quote.days);
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')), }
        axios
            .post(
                "http://tuition.techiesmarkets.com/backend/public/api/quote", formData,
                { headers: headers },
            )
            .then((res) => {
                if (res.data.success) {
                    let user = _.get(res, "data.data.user")
                        ? JSON.stringify(res.data.data.user)
                        : undefined;
                    let token = _.get(res, "data.data.token")
                        ? JSON.stringify(res.data.data.token)
                        : undefined;
                    if (user) {
                        localStorage.setItem("user", JSON.stringify(res.data.data.user));
                    }
                    if (token) {
                        localStorage.setItem("token", (res.data.data.token));
                    }
                    // Show a success toast message
                    toast.success("Teacher Quote Successfully");
                    Backdashboard();
                    setTimeout(() => {
                        // Update your table or perform other actions here
                        setQuote(res.data.data);
                    }, 2000); // Delay for 2 seconds

                }

            })
            .catch((error) => {
                // Show an error toast message
                toast.error("Something went wrong");

            });
    }
    // Update the useEffect for fetching data
    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
        fetchViewQuotation(); // Call fetchViewQuotation to load data from the API.
    }, []);

    const initializeObject = () => {
        setQuotation({ ...quotations, ...props.userState.row })
    }

    useEffect(() => {
        fetchViewQuotation();
    }, []);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherQuotation' })
        }
    }
    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'> Quotation Request</div>
            </div>
            <div className='main-quote-head-div1'>Freelance Job details</div>

            <div className='quote-job-details-div1'>

                <div className="container quotation-details">
                    <div className="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-head">
                            Closing Date:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-content">
                            {quotations.closing_date}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-head">
                            Student Name:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-content">
                            {quotations.student_id}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-labelquote-head">
                            Category:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-content">
                            {quotations.category}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-head">
                            Sub Category:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-labelquote-content">
                            {quotations.sub_category}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-labelquote-head">
                            Topic:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-content">
                            {quotations.topic}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-head">
                            Details:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-content">
                            {quotations.details}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-head">
                            Price:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-content">
                            {quotations.price}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-head">
                            Payment:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-content">
                            {quotations.price}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-head">
                            Currency:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-content">
                            {quotations.currency}
                        </div>
                    </div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3"> <div className="col-5 quote-head">
                        Attachments:
                    </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-labelquote-content">
                            {quotations.file}
                        </div></div>

                    <div class="row justify-content-center mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-head">
                            Status:
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-8 teacher-font-label quote-content">
                            <span style={{ color: quotations.status === '0' ? 'green' : 'red' }}>
                                {quotations.status === '0' ? "Active" : "Inactive"}
                            </span>
                        </div>
                    </div>


                </div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <div className="sub-Head1">
                                <FontAwesomeIcon icon={faChevronLeft} />
                                <div className="Sub-Head2 ms-5">Teachers</div>
                            </div>
                        </Typography>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="container">
                                        <div class="col">
                                            <label for="message">Message</label>
                                            <textarea class="form-control quote-msg" id="message" rows="3" value={quote.message} name="message" onChange={handleInput}></textarea>
                                        </div>
                                        <div class="col">
                                            <label for="days">Days for Completion</label>
                                            <input type="text" class="form-control" id="days" value={quote.days} name="days" onChange={handleInput} />
                                        </div>
                                        <div class="col">
                                            <label for="price">Price</label>
                                            <input type="text" class="form-control" id="price" value={quote.price} name="price" onChange={handleInput} />
                                        </div>
                                        <div class="col">
                                            <label for="term">Term</label>
                                            <input type="text" class="form-control" id="term" value={quote.term} name="term" onChange={handleInput} />
                                        </div>
                                        <div class="col">
                                            <label for="currency">Currency</label>
                                            <input type="text" class="form-control" id="currency" value={quote.currency} name="currency" onChange={handleInput} />
                                        </div>
                                        <div class="col">
                                            <label for="payment">Payment</label>
                                            <input type="text" class="form-control" id="payment" value={quote.payment} name="payment" onChange={handleInput} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='quote-send d-flex'>
                            <button className='btn btn-primary search-btn px-4'  onClick={QuotationTeacher}>
                                send
                            </button>
                        </div>

                    </Box>
                </Modal>
            </div>
            <Grid item xs={5} className="quote-content1">
                <button className='quote-btn' onClick={handleOpen}>
                    Quote
                </button>
            </Grid>

        </div>
    )
}
export default QuoteJobFreelance;