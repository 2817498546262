import React, { useState } from 'react'
import { GoArrowLeft } from "react-icons/go";
import { RotatingLines } from 'react-loader-spinner';
import { TiTick } from "react-icons/ti";
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { EmailVerification, UserMail, currentEmail, sendEmail } from '../../redux/slice/AuthSlice';

function SignUpVerificationComponent({...setEmail }) {


    const navigate = useNavigate();
    const mail = useSelector(UserMail);
    const dispatch = useDispatch();
    console.log("mail",mail.email);

    const validationSchema = Yup.object({
        otp: Yup.string().required('Please enter the OTP').length(4, 'OTP must be 4 characters long'),
    });  
    const handleResendCode = () => {
        const payload = {
            email: mail.email,
          
        };
        console.log("payload",payload);
        dispatch(sendEmail(payload));
        dispatch(currentEmail(payload));
    };


    return (
        <Formik
            initialValues={{ otp: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const payload = {
                    "otp": values.otp,
                    "email": mail.email
                }

                const valueToSend = {
                    payload,
                    navigate
                }


                dispatch(EmailVerification(valueToSend));
                resetForm();
                setSubmitting(false);
            }}
        >
            <Form>
                <div>
                    <p className='verification_back_txt text-start' onClick={() => navigate('/user/sign_up')} ><span className=' me-1'><GoArrowLeft /></span> Go Back</p>
                    <h2 className="admin-signIn-text my-4">Enter Code</h2>
                    <p className='verification_back_txt_2 mb-5'>Please enter the received code. </p>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <Field name="otp">
                            {({ field, form }) => (
                                <OtpInput
                                    value={field.value}

                                    onChange={(otp) => form.setFieldValue('otp', otp)}
                                    numInputs={4}
                                    inputStyle={{ width: "45px", height: "45px", marginLeft: "10px", marginRight: "10px", borderRadius: "10px" }}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props, index) => (
                                        <input
                                            {...props}
                                            name={`otp${index}`}
                                        />
                                    )}
                                />
                            )}
                        </Field>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <button
                            type="submit"
                            className="btn admin-signIn-btn mt-5"
                        >
                            <span className="admin-signIn-btn-text">
                                {false ? (
                                    <div className='d-flex justify-content-center'>
                                        <RotatingLines
                                            strokeColor="white"
                                            strokeWidth="5"
                                            animationDuration="0.75"
                                            width="40"
                                            height="40"
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <>Submit <span className='mb-1'><TiTick /></span></>
                                )}
                            </span>
                        </button>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col">
                        <p className="admin-signIn-account">
                            Didn’t receive the code ? {' '}
                        </p>
                    </div>
                </div>

                <div className="row ">
                    <div className="col">
                        <p className="admin-signIn-account-signUp text-center" onClick={handleResendCode} >
                            Re-send code
                        </p>
                    </div>
                </div>
            </Form>
        </Formik>
    );

}

export default SignUpVerificationComponent