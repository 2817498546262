import React from "react"
import './Section4.1_Partner.scss'


function section4_1_Partner(props) {


  return (
    <div className='partner_container'>
      <div class="container">
        <div class="row">
          <div class="col=xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className='partner_section'>
              <div className='d-flex justify-content-center landing-page-font py-4 '>Partner With Us</div>
              <p className=" d-flex justify-content-center landing-page-font1 mb-0 mx-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempos Lorem ipsum dolor sitamet,
                consectetur adipiscing elit, sed do eiusmod tempor
              </p>
              <div className=" d-flex justify-content-center my-5"><button className=" landing-page-button px-5 py-2">Join Now</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default section4_1_Partner
