import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react'
import DataTable from 'react-data-table-component';
import { BsThreeDotsVertical } from 'react-icons/bs';

function FreelanceQuotedJobsPageComponent(props) {



    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.starting_at,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true
        },
        {
            name: 'Sub Category',
            selector: row => row.sub_category,
            sortable: true
        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true
        },
        {
            name: 'Status',
            sortable: true,
            cell: (row, rowIndex) => {
                return (
                    <div>
                        {row.status === 'Active' ? <span class='text-success fw-bold'>Active</span> : <span class='text-danger fw-bold'>Inactive</span>}
                    </div>
                )
            }
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <div class="dropdown dropend">
                        <BsThreeDotsVertical class=" dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        </BsThreeDotsVertical>
                        <ul class="dropdown-menu z-3 freelance-dropdownMenu" >
                            <li><a class="dropdown-item fw-semibold" href="#" onClick={() => goToViewDetailsPage()}>View</a></li>
                            <li><a class="dropdown-item fw-semibold" href="#">Edit</a></li>
                            <li><a class="dropdown-item fw-semibold" href="#">Delete</a></li>
                        </ul>
                    </div>
                </div>
            )
        }
    ];

    const data = [
        {
            starting_at: '2023-09-28',
            category: 'Category 1',
            sub_category: 'Sub Category 1',
            topic: 'Topic 1',
            status: 'Active',
            batch_id: 'Batch 101',
        },
        {
            starting_at: '2023-10-10',
            category: 'Category 2',
            sub_category: 'Sub Category 2',
            topic: 'Topic 2',
            status: 'Inactive',
            batch_id: 'Batch 102',
        },
    ];

    const goToViewDetailsPage = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'FreelanceViewDetailsPage' });
        }
    };


    return (
        <div>
            <div className='main-divHead' >
                <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Students</div>
                </div>
                <div>
                    <div class="container text-start my-5">
                        <div class="row mt-5">
                            <div class='d-flex justify-content-start'>

                                <div class="col ms-1">
                                    <div>
                                        <div className="container ">
                                            <div className="form-group">
                                                <label class=" fs-5 fw-semibold">Date</label>
                                                <select className="form-select" id="exampleDropdown">
                                                    <option value=""></option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                    <option value="option4">Option 4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div></div>
                                <div class="col ms-1">
                                    <div>
                                        <div className="container ">
                                            <div className="form-group">
                                                <label class=" fs-5 fw-semibold">Category</label>
                                                <select className="form-select" id="exampleDropdown">
                                                    <option value=""></option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                    <option value="option4">Option 4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div></div>
                                <div class="col ms-1">
                                    <div>
                                        <div className="container ">
                                            <div className="form-group">
                                                <label class=" fs-5 fw-semibold">Sub Category</label>
                                                <select className="form-select" id="exampleDropdown">
                                                    <option value=""></option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                    <option value="option4">Option 4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div></div>
                                <div class="col ms-1">
                                    <div>
                                        <div className="container ">
                                            <div className="form-group">
                                                <label class=" fs-5 fw-semibold">Topic</label>
                                                <select className="form-select" id="exampleDropdown">
                                                    <option value=""></option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                    <option value="option4">Option 4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> </div>


                                <div class="col align-self-end ms-5 ">
                                    <button type="button" class="btn btn-primary px-5 py-2 bootBtn">Filter</button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <DataTable
                                className='dataTable mt-5 '
                                data={data}
                                columns={columns}
                                customStyles={customStyles}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FreelanceQuotedJobsPageComponent