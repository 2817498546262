import _ from "lodash"
import React from 'react'
import { MdOutlineOndemandVideo } from "react-icons/md"

function CourseUploadComponent(props) {
    const goToDashboardTeacher = (e) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'CourseUpload' })
        }
    }
    return (
        <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'CourseUpload' ? 'active' : ''} `} onClick={(e) => goToDashboardTeacher(e)}>
            <div className="dash d-flex">
                <MdOutlineOndemandVideo class='ms-3' />
                <p className='head-dashboard ms-2' > Course Upload
                </p>
            </div>
        </div>
    )
}

export default CourseUploadComponent