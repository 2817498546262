// counterSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import Axios from '../../util/apiUtil';




export const fetchDashboardData = createAsyncThunk('subject/dashboardList', async (thunkApi) => {
    try {
        // const response = await Axios.post('/api/admin/dashboard');
        // console.log("response", response);
        // thunkApi.dispatch(DashboardDataState(response.data));
        // return response.data;
        return null
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});



const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        isLoading: false,
        adminDashboardData: []
    },
    reducers: {
        DashboardDataState: (state, action) => {
            return {
                ...state,
                adminDashboardData: action.payload,
            };
        },
    },

});

export const { DashboardDataState } = dashboardSlice.actions;

// export const MainDashboardData = (state) => state.dashboard.adminDashboardData;

export default dashboardSlice.reducer;
