import React from 'react'
import Navbar from './Section1_Navbar'
import Contact from '../../../images/Contact.png'
import './Contact_us.scss'
import Footer from './Section5_footer'



function Contact_us() {
    return (
        <div>
            <Navbar />
            <img src={Contact} class="img-fluid" />

            <div class="container">
                <div class="row">
                    <div class=""></div>
                    <div class="Col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-sm-5 ms-5 card-contactus mb-4">
                        <p>Call us</p>
                        <p>(91)+ 8967452312</p>
                    </div>

                    <div class="Col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-sm-5 ms-5 card-contactus mb-4">
                        <p>Mail us</p>
                        <p>info@company.com</p>
                    </div>

                    <div class="Col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-sm-5 ms-5 card-contactus mb-4">
                        <p>Our Location</p>
                        <p>30/2 Street,India</p>
                    </div>
                </div>

                <div class="container">
                    <div class="row">

                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 get-in-touch mb-5 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-3 ms-sm-1">
                            <p class="label-contact-us mt-5 ms-3" >Get in touch</p>

                            <div class="row">
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <label class="form-label label-contact-us ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-0 ms-sm-0 mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">Name</label>
                                    <input
                                        type="text"
                                        className="form-text ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-0 ms-sm-0  mb-0 teacher-font-label "

                                        name=""

                                    />
                                </div>

                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <label class="form-label label-contact-us ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-0 ms-sm-0  mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">Email</label>
                                    <input
                                        type="text"
                                        className="form-text ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-0 ms-sm-0  mb-0 teacher-font-label "

                                        name=""

                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <label class="form-label label-contact-us ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-0 ms-sm-0  mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">phone</label>
                                    <input
                                        type="text"
                                        className="form-text ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-0 ms-sm-0  mb-0 teacher-font-label  "

                                        name=""

                                    />
                                </div>

                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <label class="form-label label-contact-us ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-0 ms-sm-0  mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3">subject</label>
                                    <input
                                        type="text"
                                        className="form-text ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-0 ms-sm-0  mb-0 teacher-font-label"

                                        name=""

                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-11">
                                    <label class="form-label label-contact-us ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-sm-3  mt-xxl-3 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3"> message</label>
                                    <textarea class="form-control mx-xxl-3 mx-xl-3 mx-lg-3 mx-md-3 mx-sm-3 " placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>
                                </div>

                            </div>

                            <div class="row mb-3">
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center mt-5">
                                <button type="button" class="btn btn-primary px-3 py-1 rounded-pill   landng_contact      ">Send message </button>
                                </div>
                            </div>

                        </div>



                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  location-contact ms-xxl-2 ms-xl-2 ms-lg-2 ms-md-3 ms-sm-3 mb-5">
                           <p class="label-contact-us">Location</p>
                        </div>

                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}

export default Contact_us