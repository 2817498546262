import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bookimg from '../../../../../../images/landing_page_images/bookimg.png'
import pic from '../../../../../../images/pic.jpeg'
import './ViewDetails.scss'
import axios from 'axios';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { BsFillEyeFill } from 'react-icons/bs';
import { CiVideoOff } from "react-icons/ci";
import { MdVideoCameraBack } from "react-icons/md";
import { FaImage } from "react-icons/fa";
import StarRatings from "react-star-ratings";
import { RxAvatar } from "react-icons/rx";
import profile from '../../../../../../images/profile.png'
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';
import { getLanguages, getLanguagesData } from '../../../../../../redux/slice/studentSlice';
import { useDispatch, useSelector } from 'react-redux';


function ViewDetails({ ...props }) {
  const [view, setView] = useState()
  const ViewBatch = props.userState.row.id || {};

  
  const [schedule, setSchedule] = useState([]);
  const baseUrl = "http://tuitionguruji.com/backend/public/";
  const [viewSub, setViewSub] = useState([{

  }])

  console.log("ViewRate*****", view);


  const getTimeForday = (day) => {
    let res = "-";
    _.forEach(_.get(view, 'subject.schedule_day.schedule_time'), time => {
      if (time.day == day) {
        res = time.from + "-" + time.to
      }
    })
    return res
  }

  const fetchSubject = () => {
    const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
    const CreateNotifi = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
    const formData = new FormData();
    formData.append("subject_id", ViewBatch)
    Axios.post('/api/viewSubject',
      formData,
      { headers: headers })
      .then((response) => {
        const updateData = response.data.data
        setView(updateData)
      })
      .catch((error) => {
        console.error("Error fetching subject data:", error);
        // Handle the error here, such as displaying an error message to the user.
      });
  }
  const education = _.get(view, 'teacherProfile.teacher.education');

  useEffect(() => {
    if (_.get(props, "userState.row")) {
      initializeObject();
    }
  }, [])

  const initializeObject = () => {
    setViewSub({ ...viewSub, ...props.userState.row })
  }

  useEffect(() => {
    fetchSubject();
  }, []);

  const Backdashboard = () => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Teachersubject' })
    }
  }

  const Review = () => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'review' })
    }
  }

  const handlePreviewClick = () => {
    window.open(viewSub.video_link, '_blank');
  };


  const hasClass = (day) => {
    // Add your condition to determine if the day should have a class
    // For example, I'm assuming that if there's a schedule for the day, it should have a class
    return _.some(_.get(view, 'subject.schedule_day.schedule_time'), (time) => time.day === day);
  };

  const ratings = _.get(view, 'ratings', []);
  const subject = _.get(view, 'subjectChapters', []);
  console.log("subject", subject);


  let rating = 0; // Define the rating variable and initialize it with a default value

  // Calculate average rating
  let totalRating = 0;
  let numberOfRatings = ratings.length;

  if (numberOfRatings > 0) {
    for (let i = 0; i < numberOfRatings; i++) {
      totalRating += parseFloat(ratings[i].ratings);
    }
    const averageRating = totalRating / numberOfRatings;
    rating = parseFloat(averageRating.toFixed(2)); // Keep only two decimal places
  }
  console.log("Rating", rating);

  const [showNext, setShowNext] = useState(false);
  const goToAllRating = (e, link) => {
    setShowNext(true);
  }

  const languagesData = useSelector(getLanguagesData);
  const languageList = languagesData.map(language => ({
    key: view?.subject?.language, // Assuming `id` is unique for each language
    name: language.name, // Accessing the name of the language
  }));


  useEffect(() => {
    console.log("languagesData has changed:", languagesData);
  }, [languagesData]);

  const [selectedChapter, setSelectedChapter] = useState(null);
  const toggleCollapse = (index) => {
    if (selectedChapter === index) {
      setSelectedChapter(null); // Collapse if already clicked
    } else {
      setSelectedChapter(index); // Expand if not clicked
    }
  };


  const subjectChapters = viewSub?.subjectChapter || [];

  // Use effect to debug the data
  useEffect(() => {
    console.log('viewSub:', viewSub);
    console.log('subjectChapters:', subjectChapters);
  }, [viewSub, subjectChapters]);

  const EducationDetails = ({ education }) => {
    if (typeof education === 'string') {
      return <p>{education}</p>;
    } else if (typeof education === 'object') {
      return (
        <div>
          <p>Degree: {education.degree}</p>
          <p>Organization: {education.organization}</p>
          <p>Field of Study: {education.field_of_study}</p>
          <p>Year Completed: {education.year_completed}</p>
        </div>
      );
    } else {
      return <p>Not available</p>;
    }
  };
  console.log("viewSub.image", viewSub.image);
  return (
    <div className='main-divHead' >

      <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} onClick={Backdashboard} />
        <div className='Sub-Head2'>Courses</div>
      </div>

      <div class="container text-start">
        <div class="row">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-sm-0 mt-4 div-head-view">
            <div class='admin-card'>
              <img src={`${baseUrl}/${viewSub.image}`} className="img-fluid admin-view-image rounded-top" alt="Course" />

              <div>
                <div class="container text-start mt-5">
                  <div class="row">
                    <div class="col">
                      <p class='para-abt1 d-flex '>About Course</p>
                    </div>
                    <div class="col">

                      <button className="btn outline-btn-Teacher para-abt1 px-2 fw-bold mt-2" onClick={handlePreviewClick}>Preview course<span ><MdVideoCameraBack class='adminEyeIcon ms-3' /></span></button>
                    </div>
                  </div>
                </div>
                <div className='des-para mt-4 col-2'>
                  <p className=''>{viewSub.description}</p>
                </div>
              </div>

              <div>
                Rating{" "}
                <span>
                  <StarRatings
                    className="sub-rating-star"
                    starRatedColor={"#F2DE25"}
                    starDimension={"15px"}
                    rating={parseFloat(rating)}
                    starHoverColor="black"
                    starSpacing="1px"
                  />
                </span>
                <span className="fw-bold ms-2">{Number.isInteger(rating) ? rating.toFixed(1) : rating}</span>
                {" "}
                <span class="fw-bold ms-2 viewall" onClick={() => goToAllRating()}>View All</span>
              </div>
              <hr />

              <div class="row">
                <div className='col'>
                  <p>  Course Name</p><br />
                </div>
                <div class="col text-start"><p class="ms-5">{view?.subject?.name}</p></div>
              </div>
              <div class="row">
                <div className='col'>
                  <p>  Category</p><br />
                </div>
                <div class="col text-start"><p class="ms-5">{view?.subject?.category}</p></div>
              </div>

              <div class="row">
                <div className='col'>
                  <p>  Subcategory</p>
                </div>
                <div class="col text-start">
                  <p class="ms-5 text-start">   {view?.subject?.sub_category}</p> </div>
              </div>
              <div class="row">
                <div className='col'>
                  <p>  Topic</p>
                </div>
                <div class="col text-start">
                  <p class="ms-5 text-start">   {view?.subject?.topic}</p> </div>
              </div>



              <div class="row">
                <div className='col'>
                  <p>    Price</p><br />
                </div>
                <div class="col"><p class="ms-5"> Rs{" "} {view?.subject?.price}.00</p></div>
              </div>

              <div class="row">
                <div className='col'>
                  <p className=''>  Language</p><br />
                </div>
                <div class="col"><p class="ms-5 para-abt1">  {view?.subject?.language}</p></div>
              </div>
            </div>
          </div>

          <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 ms-3 mt-4 div-head-view2">
            <div>
              <p className='para-head mt-4 ms-4 d-flex justify-content-center'>{viewSub.firstname || view?.teacherProfile?.user?.firstname} {""} {viewSub.laststname || view?.teacherProfile?.user?.lastname}</p>
            </div>
            <div className='row '>
              <div className='col-2  mb-5'>
                {view?.teacherProfile?.user?.profile_pic ? (
                  <img src={baseUrl + view?.teacherProfile?.user?.profile_pic} className='pic' alt=' profile' />
                ) : (
                  <FaImage size={50} />
                )}
              </div>
          


            <div class="col text-start fw-bold  mt-5 ms-3">



              <div class="">
                <div class="row  ms-5 ">
                  <div class="col-4 ">
                    <p>Bio :  </p></div>

                  <div class="col-8"><p >{viewSub.bio || _.get(view, 'teacherProfile.teacher.bio', 'Not available')}<br /></p><br /></div>
                </div>
              </div>

              <div class="">
                <div class="row  ">
                  <div class="col-4 ms-5 ">
                    <p >Education : </p>
                  </div>

                  <div class="col">
                    {_.get(view, 'teacherProfile.teacher.education') ? (
                      <p>
                        {" "}
                        <div>
                          <div className="col-1 ms-3">

                          </div>
                          {education.map((edu, index) => (
                            <div className="row">
                              <div key={index} className=" col">
                                <p>{edu.degree}{" "}{edu.field_of_study}{" "}From{" "} {edu.organization}</p>

                              </div>
                            </div>
                          ))}
                        </div>

                      </p>
                    ) : (
                      <p>No Education available</p>
                    )}
                  </div>
                </div>
              </div>
              <div class="">
                <div class="row  ms-4 ">
                  <div class="col-4 ">
                    <p>Working Experiences :  </p></div>

                  <div class="col-4 ms-3"><p >{viewSub.experience || _.get(view, 'teacherProfile.teacher.experience', 'Not available')}years<br /></p><br /></div>
                </div>
              </div>
            </div>

            <br />
            </div>

            <div className="col">
              <div>
                <h2 className="mt-2">Course Syllabus<hr className="col-4 px-3" /></h2>

              </div>

              <div className="col ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 mt-3 ">
                <div className="row ms-3">
                  {view?.subjectChapter && view.subjectChapter.map((chapter, index) => (
                    <div key={index} className="col-12 mt-4   mt-3 mb-3 fw-bold "
                      onClick={() => toggleCollapse(index)}
                    >
                      <h4 className="bold">{`${index + 1}. ${chapter.name}`}</h4><p className="d-flex justify-content-end">{chapter.duration}{" "}hours</p>
                      <p>{chapter.description}</p>
                      <div className="d-flex justify-content-end">
                        <button
                          className="outline-btn-admin px-4 py-1 fw-bold "

                        >
                          chapters
                        </button>
                      </div>

                      {selectedChapter === index ? (
                        chapter.topics && chapter.topics.length > 0 ? (
                          chapter.topics.map((topic, topicIndex) => (
                            <div className="mb-3" key={topicIndex}>
                              <div>
                                <h5>{`${index + 1}.${topicIndex + 1} ${topic.title || "Topic Title"}`}</h5>
                                <p className="d-flex justify-content-end">{topic.duration} hours</p>
                                <p>{topic.description || "Topic Description"}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="mb-3">
                            <p> Chapter details are not available</p>
                          </div>
                        )
                      ) : null}

                    </div>
                  ))}
                </div>




              </div>
            </div>

            {/* <div className="col ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5 mt-3 ">
              <div className="row ms-3">
                {view?.subjectChapter && view.subjectChapter.map((chapter, index) => (
                  <div key={index} className="col-12 mt-4 text-center syllabus_btn mt-3 mb-3" onClick={() => toggleCollapse(index)}>
                    <h5 className="">{chapter.name}</h5>
                    {selectedChapter === index && chapter.topics && chapter.topics.map((topic, topicIndex) => (
                      <div className="card mb-3
                      ">
                        <div key={topicIndex}>
                          <h6 className="">{topic.title || "Topic Title"}</h6>
                          <p>{topic.description || "Topic Description"}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>




            </div> */}



            {/* <div className="rating-bg " style={{ background: "#f0f2f5" }}>
              <h1 className=" d-flex justify-content-center mt-5">Review and Ratings</h1>
              <div className="row">
                {ratings.slice(0, 2).map((rating, index) => ( // Show only the first two ratings
                  <div key={index} className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="card mt-5 border-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4>{rating.firstname}</h4>
                            <span>
                              <StarRatings
                                className="sub-rating-star"
                                starRatedColor={"#F2DE25"}
                                starDimension={"20px"}
                                rating={parseFloat(rating.ratings)}
                                starHoverColor="black"
                                starSpacing="1px"
                              />
                              <span className="fw-bold ms-2">{parseFloat(rating.ratings).toFixed(1)}</span>
                            </span>
                            <div className='col'>
                              <p className="p-rating-date mt-2">{rating.created_at ? new Date(rating.created_at).toLocaleDateString() : ''}</p>
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col">
                              <p className='p_review'>{rating.review || "No review available"}</p>
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='row text-center'>
              <div className='col'>
                <button className='btn btn_review ' onClick={Review}>Show All Review</button>
              </div>
            </div> */}
          </div>
        </div>
      </div>


    </div >
  )
}

export default ViewDetails