import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DotsIcon from '../../../Sidebar/icons/DotsIcon.png'
import './TeacherAssignment.scss'
import DataTable from 'react-data-table-component';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { format } from 'date-fns';
// import Delete from '../../../../../../images/Delete.png'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';


function TeacherAssignment(props) {
    const [hasMore, setHasMore] = useState(true);
    const [menuVisibility, setMenuVisibility] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const [isactions, setActions] = useState(false)
    const [activeMenu, setActiveMenu] = useState(null)
    const [rows, setRows] = useState()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([{

    }]);


    const handleMenuToggle = (rowIndex) => {
        const newMenuVisibility = [...menuVisibility];
        newMenuVisibility[rowIndex] = !newMenuVisibility[rowIndex];
        setMenuVisibility(newMenuVisibility);
    };

    const [assignments, setAssignments] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleClickActions = (event, rowId, action) => {
        setAnchorEl(event.currentTarget);
        setActions((prevState) => (prevState === rowId ? null : rowId))
        if (action === 'delete') {
            setActiveMenu(rowId);
        } else {
            setActiveMenu(null);
        }
    };

    const handleClose = () => {
        setAnchorEl(null)
    }

    // Data table structure
    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",

            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Date starts',
            selector: row => row.starting_at,
            sortable: true
        },
        {
            name: 'Date End',
            selector: row => row.ending_at,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true

        },
        {
            name: 'Sub category',
            selector: row => row.sub_category,
            sortable: true

        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true

        },
        {
            name: 'Batch',
            selector: row => row.batch_id,
            sortable: true
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div className={`Teacher test-actions ${isactions == row.id ? '' : 'active'}`}>
                    <Button className="action-subj d-flex" id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e) => handleClickActions(e, row.id, 'menu')}>
                        <MoreVertIcon />
                    </Button>
                    <div>
                        <Menu
                            className="dots-actions"
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}>                        
                            <MenuItem onClick={(e) => goToTeacherViewAssignment(e, row, batch_id)} disableRipple className="ul-for-options pt0">
                                View
                            </MenuItem>
                            <MenuItem onClick={(e) => goToTeacherCreateAssignment(e, row, batch_id)} disableRipple className="ul-for-options">
                                Edit
                            </MenuItem>
                            <MenuItem onClick={(e) => goToTeacherViewStudentList(e, row, batch_id)} disableRipple className="ul-for-options">
                                Student List
                            </MenuItem>
                            <MenuItem disableRipple className="ul-for-options" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                Delete
                            </MenuItem>

                        </Menu>
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content p-5">                                
                                    <div class=" justify-content-center ms-5">
                                        <h1 class="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want Delete ?</h1>
                                        <button type="button" class="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal" >Close</button>
                                        <button type="button" class="btn btn-primary mt-3 ms-5 px-4" data-bs-dismiss="modal" onClick={() => { deleteRow(row.batch_id) }}>Delete</button>
                                    </div>
                                </div>                           
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    ]
    useEffect(() => {       
        fetchAssignment()
    }, [])
    useEffect(() => {
        const visibility = new Array(data.length).fill(false);
        setMenuVisibility(visibility);
    }, [data]);

    const fetchAssignment = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        Axios.post('/api/listAssignments', undefined, { headers: headers })
            .then((response) => {
                const newData = response.data.data.map((item) => ({
                    ...item,
                    starting_at: format(new Date(item.starting_at), 'dd-MM-yyyy'),
                    ending_at: format(new Date(item.ending_at), 'dd-MM-yyyy'),
                }));
                setData(newData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Assignments:', error);
                setLoading(false);
            });
    };

    const deleteRow = (batch_id) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        Axios.post('/api/deleteAssignment', { batch_id }, { headers: headers })
            .then(() => {
                setData(prevData => prevData.filter(item => item.batch_id !== batch_id));
            })
            .catch((error) => {
                console.error('Error deleting row:', error);
            });
    };

    const goToTeacherCreateAssignment = (e, row, batch_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'createnewassignment', row: row, batch_id: batch_id })
        }
    }
    const goToTeacherViewAssignment = (e, row, batch_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewassignment', row: row, batch_id: batch_id })
        }
    }

    const goToTeacherViewStudentList = (e, row, batch_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'viewstudentlist', row: row, batch_id: batch_id })
        }
    }

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherassignment' })
        }
    }


    // Data table structure
    return (
        <div className='main-divHead ' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Assignments</div>
            </div>
            <button className='btn-addAssign-subject mb-4' onClick={(e) => goToTeacherCreateAssignment(e)}>Add New Assignment</button>
            <div class="col-xxl-11 col-xl-12 col-lg-12 mt-3 ms-5 justify-content-center">

                <DataTable
                    data={data}
                    columns={columns}
                    customStyles={customStyles}
                    pagination
                    fixedHeader={true}
                    fixedHeaderScrollHeight="600px"
                    striped
                    persistTableHead
                />
                <InfiniteScroll
                    dataLength={data.length} // This is important to track the length of data
                    next={fetchAssignment}
                    hasMore={hasMore}
                ></InfiniteScroll>
            </div>
        </div>
    )
}

export default TeacherAssignment