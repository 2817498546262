import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import _ from "lodash"
import { BiMessageAltDetail } from 'react-icons/bi';

function Chats({ ...props }) {
  const navigate = useNavigate()
  // const [hoverEffect, setHoverEffect] = useState(false)
  const goToChat = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Chats' })
    }
  }
  return (
    <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'Chats' ? 'active' : ''} `} onClick={(e) => goToChat(e)}>
      <div className="dash d-flex">
        <BiMessageAltDetail class='ms-3' />
        <p className='head-dashboard ms-2 mb-0' >Chats</p>
      </div>
    </div>
  )
}

export default Chats;
