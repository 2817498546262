import React from "react"
// import SidebarUserProfile from "../MainSection/components/navbar/student_Profile"
import Dashboard from "./SidebarComponents/1.Dashboard"
import StudentSidebar from "./SidebarComponents/2.Student_Section_Components"
import TeacherSidebar from "./SidebarComponents/3.Teacher_Section_Components"
import Logout from "./SidebarComponents/4.Logout"
import Freelancer from "./SidebarComponents/freelaner"
import CourseUploadSidebarMain from "./SidebarComponents/sidebar_main_headers/CourseUploadSidebarMain"
import FreelanceSidebarMain from "./SidebarComponents/sidebar_main_headers/FreelanceSidebarMain"
import StudentSidebarMain from "./SidebarComponents/sidebar_main_headers/StudentSidebarMain"
import TeacherSidebarMain from "./SidebarComponents/sidebar_main_headers/TeacherSidebarMain"
import './sidebarStudent.scss'
import _ from 'lodash';
import CourseUploadMain from "./SidebarComponents/courseUpload"
import AdminSideBar from "./SidebarComponents/admin"
import FreelanceSidebar from "./SidebarComponents/freelance"
import InstructorUploadSideBar from "./SidebarComponents/InstructorUpload"
const Sidebar = ({ ...props }) => {

  return (
    <div className='sidebar_container admin-sidebar '>
      <div className="oval d-flex">
        <div><h1 className="sidebar-head" > <div className="header-oval"> {_.get(props, 'userState.type')} </div></h1></div>
      </div>
      <div className="side-1" >
        {_.get(props, 'userState.type') == 'Student' && <StudentSidebar {...props} />}
        {_.get(props, 'userState.type') == 'Teacher' && <TeacherSidebarMain />}
        {_.get(props, 'userState.type') == 'Teacherpage' && <TeacherSidebar {...props} />}
        {_.get(props, 'userState.type') == 'freelance' && <FreelanceSidebarMain />}
        {_.get(props, 'userState.type') == 'Freelancepage' && <Freelancer {...props} />}
        {_.get(props, 'userState.type') == 'Course' && <CourseUploadSidebarMain />}
        {_.get(props, 'userState.type') == 'courseupload' && <CourseUploadMain {...props} />}
        {_.get(props, 'userState.type') == 'admin' && <AdminSideBar {...props} />}
        {_.get(props, 'userState.type') == 'Freelance' && <FreelanceSidebar {...props} />}
        {_.get(props, 'userState.type') == 'InstructorUpload' && <InstructorUploadSideBar {...props} />}
      </div>
    </div>
  )
}

export default Sidebar
