import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import './TeacherTest.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import DataTable from 'react-data-table-component';
import axios from "axios"
import { format } from 'date-fns';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
// import Delete from '../../../../../../images/Delete.png'
import Modal from 'react-modal';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';


function TeacherTest(props) {

    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState()
    const [rows, setRows] = useState()
    const [dele, setDele] = useState({})
    const [data, setData] = useState([{

    }])

    const handleClickActions = (event, rowId, action) => {
        setAnchorEl(event.currentTarget);
        setActions((prevState) => (prevState === rowId ? null : rowId))
        if (action === 'delete') {
            setActiveMenu(rowId);
        } else {
            setActiveMenu(null);
        }
    };

    const handleClose = () => {
        setAnchorEl(null)
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const [isactions, setActions] = useState(false)
    const [activeMenu, setActiveMenu] = useState(null)

    useEffect(() => {
        // Load data from localStorage when the component mounts
        const savedData = (localStorage.getItem('testData'));
        if (savedData) {
            setData(savedData);
        }

        fetchTest();
    }, []);

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherTest' })
        }
    }

    const goToTeacherTestStudent = (e, row, question_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherstudentdetails', row: row, })
        }
    }

    const goToTeacherNewQuest = (e, row, question_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherAddNewQuest', row: row, question_id: question_id })
        }
    }

    const goToTeacherTestView = (e, row,) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherViewTest', row: row })
        }
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [menuVisibility, setMenuVisibility] = useState([]);
    const [deleteQuestionId, setDeleteQuestionId] = useState(null); // Define deleteQuestionId

    const openModal = (id, question_id) => {
        setModalIsOpen(true);
        setDeleteQuestionId(id); // Set the id in state
        setDele({ id, question_id }); // Set id and question_id in state
    };


    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleMenuToggle = (rowIndex) => {
        const newMenuVisibility = [...menuVisibility];
        newMenuVisibility[rowIndex] = !newMenuVisibility[rowIndex];
        setMenuVisibility(newMenuVisibility);
    };

    const [remainingData, setRemainingData] = useState([]);

    const goToTeacherAddTest = (e, row, question_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherAddTest', row: row, question_id: question_id })
        }
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.date,

        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true

        },
        {
            name: 'Subcategory',
            selector: row => row.sub_category,
            sortable: true

        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true

        },
        {
            name: 'Batch',
            selector: row => row.batch_id,
            sortable: true
        },
        {
            name: 'No of Questions',
            selector: row => row.noofquestions,
            sortable: true
        },
        {
            name: 'Actions',
            sortable: true,
            cell: (row, batch_id, date, rowIndex, question_id, id) => (


                <div className={`Teacher test-actions ${isactions == row.id ? '' : 'active'}`}>
                    <Button className="action-subj d-flex" id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e) => handleClickActions(e, row.id, 'menu')}>
                        <MoreVertIcon />
                    </Button>

                    <div>
                        <Menu
                            className="dots-actions"
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >

                            <MenuItem onClick={(e) => goToTeacherTestView(e, row, question_id)} disableRipple className="ul-for-options pt0">
                                View
                            </MenuItem>
                            <MenuItem onClick={(e) => goToTeacherAddTest(e, row, question_id)} disableRipple className="ul-for-options">
                                Edit
                            </MenuItem>
                            <MenuItem onClick={(e) => goToTeacherTestStudent(e, row, question_id)} disableRipple className="ul-for-options">
                                Student List
                            </MenuItem>
                            <MenuItem disableRipple className="ul-for-options" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                Delete
                            </MenuItem>

                        </Menu>
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content p-5">
                                    <div class=" justify-content-center ms-5">
                                        <h1 class="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want Delete ?</h1>
                                        <button type="button" class="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal" >Close</button>
                                        <button type="button" class="btn btn-primary mt-3 ms-5 px-4" data-bs-dismiss="modal" onClick={() => { deleteRow(row.id, row.question_id) }}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    ]
    //             <div>
    //                 <FontAwesomeIcon icon={faEdit} onClick={() => handleMenuToggle(rowIndex)} />
    //                 {menuVisibility[rowIndex] && (
    //                     <div className='dropbox-action-assign-schedule'>
    //                         <div className='show-view'>
    //                             <label onClick={(e) => goToTeacherTestView(e, row, row.question_id, batch_id)}>View</label><br />
    //                             <label onClick={(e) => goToTeacherAddTest(e, row, question_id)}>Edit</label><br />
    //                             <label onClick={(e) => goToTeacherTestStudent(e, row,question_id)}>student list</label><br />
    //                             <label onClick={() => openModal(row.id, row.question_id)}>Delete</label>
    //                             <Modal
    //                                 isOpen={modalIsOpen}
    //                                 onRequestClose={closeModal}
    //                                 contentLabel="Example Modal"
    //                             >
    //                                 <h2 className='para-delete'>Are you sure ?</h2>
    //                                 <img className='delete-img' src={Delete} /> <br />
    //                                 <button className='yes-btn' onClick={() => { deleteRow(row.id, row.question_id) }}>Delete</button>
    //                                 <button className='cancel-btn' onClick={closeModal}>Cancel</button>
    //                             </Modal>
    //                         </div>
    //                     </div>
    //                 )}
    //             </div>
    //         )
    //     }
    // ]

    useEffect(() => {
        getRows()
        fetchTest()
    }, [])

    useEffect(() => {
        const visibility = new Array(data.length).fill(false)
        setMenuVisibility(visibility)
    }, [data])

    const fetchTest = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }


        Axios.post('/api/listTest', undefined, { headers: headers })
            .then((response) => {
                console.log(response)
                const newData = response.data.data.map((item) => ({
                    ...item,
                    date: format(new Date(item.date), 'dd-MM-yyyy'),
                    question_id: item.id,
                }))
                setData(newData)
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching Assignments:', error)
                setLoading(false);
            });
    };

    const getRows = () => {
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        let res = [];
        res = data.map((item, index) => {
            return ({

                date: item.date,
                category: item.category,
                sub_category: item.sub_category,
                topic: item.topic,
                batch_id: item.batch_id,
                marks_question: item.marks_question,
                question_id: item.question_id,
                noofquestions: item.noofquestions
            })
        })
        setRows(res)
    }

    const deleteRow = (id, question_id) => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
            return;
        }

        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const formData = new FormData()
        formData.append('id', id)
        formData.append('question_id', question_id);

        Axios
            .post('/api/deleteTest', formData, { headers: headers })
            .then(() => {
                setData(prevData => prevData.filter(item => item.question_id !== question_id))
            })
            .catch((error) => {
                console.error('Error deleting row:', error)
            })
    }


    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Test</div>
            </div>

            <div>
                <button className='btn-add-test ms-5 mt-5 px-3' onClick={(e) => goToTeacherAddTest(e)}>Add Test</button>
            </div>
            <div className='teacher-table-test'>
                <DataTable
                    data={data}
                    columns={columns}
                    customStyles={customStyles}
                    pagination
                    fixedHeader={true}
                    fixedHeaderScrollHeight="600px"
                    striped
                    persistTableHead
                />
                <InfiniteScroll
                    dataLength={data.length}
                    next={fetchTest}
                    hasMore={hasMore}
                ></InfiniteScroll>
            </div>
        </div>
    )
}



export default TeacherTest