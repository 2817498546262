import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import _ from 'lodash';
import DataTable from 'react-data-table-component';

function AdminBatchwiseStudent(props) {


    const goToTeacherCourseDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherCourse' })
        }
    }


    const sample = [
        {
            student_list: "student 1",
            action: "Active"
        }
    ]

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
                textAlign: "center"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontWeight: "bold"
            }
        },
    };

    const columns = [
        {
            name: 'Student Name',
            selector: row => row.student_list,
            sortable: true,
        },
        {
            name: 'Action',
            sortable: false,
            cell: (row, subData, rowIndex, id) => (
                <div>
                    <button type="button" class="btn text-light px-xxl-3 px-xl-3 px-lg-3 px-md-2 py-1 btnActive_in" data-bs-toggle="modal" data-bs-target="#exampleModalActive" >{row.action}</button>
                </div>
            )
        }

    ]
    return (
        <div className='main-divHead'>
            <div className='sub-Head1'>
                <FontAwesomeIcon icon={faChevronLeft} onClick={goToTeacherCourseDetails} />
                <div className='Sub-Head2' >Batch Details</div>
            </div>

            <div className="container text-start">
                <div className='row'>
                    <div class="d-xxl-flex d-xl-flex d-lg-flex d-md-flex justify-content-center"><div className='col-xxl-9 col-xl-9 col-lg-12'>
                        <p class='admin-sub-head my-xxl-5 my-xl-5 my-lg-5 my-md-5 my-3'>Batch Details</p>
                    </div></div>
                </div>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <div className="col-xxl-9 col-xl-9 col-lg-12">
                            <div className=" adminSubCard p-3">
                                <div>
                                    <div class="container text-start">
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Date : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row  ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Instructor Name : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row  ms-3 ">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Email : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Phone : </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Category :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <span class=''> <p class='admin-sub-head-card'></p></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Sub Category :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <span class=''> <p class='admin-sub-head-card'></p></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Topic :  </p>
                                                </div>
                                                <div class="col-5">
                                                    <p class='admin-sub-head-card'><span class=''></span></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Status : </p>
                                                </div>
                                                <div class="col-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>Batch Started: </p>
                                                </div>
                                                <div class="col-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ms-3">
                                            <div class='d-flex justify-content-between'>
                                                <div class="col">
                                                    <p class='admin-sub-head-card'>No of Students: </p>
                                                </div>
                                                <div class="col-5">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <DataTable
                        className='dataTable mt-5'
                        data={sample}
                        columns={columns}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="600px"
                        striped
                        customStyles={customStyles}
                        persistTableHead
                        pagination
                    />
                </div>
            </div>

            <div class="modal fade" id="exampleModalActive" tabindex="-1" aria-labelledby="exampleModal" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content p-5">
                        <div class=" justify-content-center ms-5">
                            <h1 class="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want Active?</h1>
                            <button type="button" class="btn btn-secondary mt-3 px-4 ms-4" data-bs-dismiss="modal" >close</button>
                            <button type="button" class="btn btn-primary mt-3 ms-5 px-4" data-bs-dismiss="modal">Active</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdminBatchwiseStudent