import React from 'react'
import { useState } from 'react';
import Calendar from 'react-calendar';


function CalenderComponent() {

    const [value, onChange] = useState(new Date());

    return (
        <div class='m-1'>
            <Calendar onChange={onChange} value={value} />
        </div>
    );
}

export default CalenderComponent