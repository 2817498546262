import React, { useState, useEffect } from 'react';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import axios from 'axios';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function TestTeacherView(props) {
    const ViewBatch = props.userState.row.question_id || {};
    const [view, setView] = useState()
    const [testTeacher, setTestTeacher] = useState([{
    }])

    const fetchViewDetailas = () => {
        debugger
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }
        const createViewTest = localStorage.getItem("createData") ? (localStorage.getItem('createData')) : {};
        const formData = new FormData();
        console.log("Question ID:", testTeacher.question_id);
        formData.append("question_id", ViewBatch);

        Axios.post('/api/viewTest', formData,
            { headers: headers })
            .then((response) => {
                console.log(response)
                const updateData = response.data
                setView(updateData)
            })
            .catch((error) => {
                console.error("Error fetching test data:", error);
                // Handle the error here, such as displaying an error message to the user.
            });

    }

    useEffect(() => {
        if (_.get(props, "userState.row")) {
            initializeObject();
        }
        fetchViewDetailas();
    }, [])

    const initializeObject = () => {
        const questionId = _.get(props, 'userState.row.question_id');
        console.log('question_id:', questionId); // Check the value in the console
        setTestTeacher({
            ...testTeacher,
            ...props.userState.row,
            question_id: questionId,

        });
    }




    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'TeacherTest' });
        }
    };

    return (
        <div className='main-divHead'>
            <div className='sub-Head1' onClick={Backdashboard}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Test</div>
            </div>

            <div className='Main-head-viewschedule'>View Schedule</div>
            
            <div class="container text-start">
                <div class="row mt-5">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo '>Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo ' >{testTeacher.category}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Sub Category : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{testTeacher.sub_category}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Topic : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{testTeacher.topic}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Batch : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{testTeacher.batch_id}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Date : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{testTeacher.date}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Total student : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{testTeacher.totalStudents}</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div className='d-flex justify-content-center'>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo'>Marks per Question : </p>
                        </div>
                        <div class="col-xxl-4 col-xl-6 col-lg-4">
                            <p className='techer-card-typo' >{testTeacher.marks_question}</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default TestTeacherView;
