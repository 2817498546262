import React from 'react';
import AdminDashboardComponent from './component/adminDashboard.component';
import SubjectComponent from './component/subject.component';
import StudentComponent from './component/student.component';
import WalletComponent from './component/wallet.component';
import TeacherComponent from './component/teacher.component';
import FreelancersComponent from './component/freelancers.component';
import InstructorUploadsComponent from './component/instructorUploads.component';
import ReportsComponent from './component/reports.component';
import MessagesComponent from './component/Messages.component';
import SettingsComponent from './component/settings.component';
import AdminWebsiteSettingsComponent from './component/adminWebsiteSettings.component';
import PaymentSettingsComponent from './component/paymentSettings.component';
import SMTPSettingsComponent from './component/SMTPSettings.component';

function AdminSideBar({ ...props }) {

    return (
        <div>
            <AdminDashboardComponent {...props} />
            <SubjectComponent {...props} />
            <TeacherComponent {...props} />
            <StudentComponent {...props} />
            <FreelancersComponent {...props} />
            <InstructorUploadsComponent {...props} />
            <WalletComponent {...props} />
            <ReportsComponent {...props} />
            <MessagesComponent {...props} />
            <SettingsComponent {...props} />
        </div>
    )
}

export default AdminSideBar