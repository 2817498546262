// counterSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from '../../util/apiUtil';
import { showSuccessToast, showErrorToast } from '../../util/toastUtils';


export const fetchTeacherList = createAsyncThunk('teacher/fetchTeacherList', async () => {
    try {
        const response = await Axios.post('/api/listTeacher');
        return response.data.data;
    } catch (error) {
        console.log("axios error", error);
        throw error;
    }
});
export const  viewTeacherList = createAsyncThunk('teacher/viewTeacherList', async (payload) => {
    try {
        const response = await Axios.post('/api/viewTeacher', payload);
        return response.data.data;
    } catch (error) {
        showErrorToast("teacher role already created for this user")
        console.log("axios error", error);
        throw error;
    }
});




export const teacherStatusChange = createAsyncThunk('teacher/teacherStatusChange', async (payload, thunkApi) => {
    console.log("payload", payload);
    try {
        const response = await Axios.post('/api/changeTeacherStatus', payload.payload);
        if (response.data.success) {
            showSuccessToast("Approve The  Teacher  SuccessFully");
        }
        await thunkApi.dispatch(viewTeacherList(payload.teacher));
        return response.data.data;
    } catch (error) {
        showErrorToast("change status Failed");
        console.log("axios error", error);
        throw error;
    }
});


export const teacherDocumentStatusChange = createAsyncThunk('teacher/teacherDocumentStatusChange', async (payload, thunkApi) => {

    try {
        const response = await Axios.post('/api/changeTeacherDocStatus', payload);
        if (response.data.success) {
            showSuccessToast("Teacher Status Changed SuccessFully");
        }
        return response.data.data;
    } catch (error) {
        showErrorToast("change status Failed");
        console.log("axios error", error);
        throw error;
    }
});


export const teacherSlice = createSlice({
    name: 'teacher',
    initialState: {
        isLoading: false,
        teacher: [],
        TeacherID: [],
        viewTeacher: [],
        TeacherVidData: [],

    },

    reducers: {
        ActiveTeacherID: (state, action) => {
            return {
                ...state,
                TeacherID: action.payload
            }
        },
        TeacherVideoRec: (state, action) => {
            return {
                ...state,
                TeacherVidData: action.payload
            }
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchTeacherList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchTeacherList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.teacher = action.payload;
            })
            .addCase(fetchTeacherList.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(teacherStatusChange.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(teacherStatusChange.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(teacherStatusChange.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(viewTeacherList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(viewTeacherList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.viewTeacher = action.payload;
            })
            .addCase(viewTeacherList.rejected, (state) => {
                state.isLoading = false;
            })


    },
});


export const { ActiveTeacherID, TeacherVideoRec } = teacherSlice.actions;



export const teacherList = (state) => state.teacher.teacher;
export const activeTeacherID = (state) => state.teacher.TeacherID;
export const viewTeacherData = (state) => state.teacher.viewTeacher;
export const TeacherVideo = (state) => state.teacher.TeacherVidData;
export const teacherLoading = (state) => state.teacher.isLoading;


export default teacherSlice.reducer;

