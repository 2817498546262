import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react'
import DataTable from 'react-data-table-component';

function AdminMessageComponent(props) {

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const columns = [
        {
            name: 'Date',
            selector: row => row.starting_at,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true
        },
        {
            name: 'Sub Category',
            selector: row => row.sub_category,
            sortable: true
        },
        {
            name: 'Topic',
            selector: row => row.topic,
            sortable: true
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, rowIndex, batch_id) => (
                <div>
                    <button type="button" class="btn btn-primary px-3 py-1 bootBtn"  onClick={(e) => goToAdminMessageDetails()} >Details</button>
                </div>
            )
        }
    ];

    const data = [
        {
            starting_at: '2023-09-28',
            category: 'Category 1',
            sub_category: 'Sub Category 1',
            topic: 'Topic 1',
            batch_id: 'Batch 101',
        },
        {
            starting_at: '2023-10-10',
            category: 'Category 2',
            sub_category: 'Sub Category 2',
            topic: 'Topic 2',
            batch_id: 'Batch 102',
        },
    ];


    const goToAdminMessageDetails = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminMessageDetails' })
        }
    }



    return (
        <div className='main-divHead' >
            <div className='sub-Head1' >  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'>Messages</div>
            </div>
            <div>
                <div class="container text-start my-5">
                    <div class="row ">
                        <div class='d-flex justify-content-start'>
                            <div class="col ms-1">
                                <div>
                                    <div className="container ">
                                        <div className="form-group">
                                            <label htmlFor="exampleDropdown fs-5 fw-semibold">Date</label>
                                            <select className="form-select" id="exampleDropdown">
                                                <option value=""></option>
                                                <option value="option1">Option 1</option>
                                                <option value="option2">Option 2</option>
                                                <option value="option3">Option 3</option>
                                                <option value="option4">Option 4</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col ms-1">
                                <div>
                                    <div className="container ">
                                        <div className="form-group">
                                            <label htmlFor="exampleDropdown fs-5 fw-semibold">Role</label>
                                            <select className="form-select" id="exampleDropdown">
                                                <option value=""></option>
                                                <option value="option1">Option 1</option>
                                                <option value="option2">Option 2</option>
                                                <option value="option3">Option 3</option>
                                                <option value="option4">Option 4</option>
                                            </select>
                                        </div>
                                    </div>
                                </div></div>
                            
                            <div class="col align-self-end ms-5 ">
                                <button type="button" class="btn text-light px-5 py-2 bootBtn">Filter</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <DataTable
                            className='dataTable mt-5 '
                            data={data}
                            columns={columns}
                            fixedHeader={true}
                            fixedHeaderScrollHeight="600px"
                            striped
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminMessageComponent