import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
// import img from '../../../../../../images/Rectangle 120.jpg'
// import img1 from '../../../../../../images/Frame 135.jpg'
import { BsFillEyeFill } from 'react-icons/bs'
import { AiOutlineLeft } from 'react-icons/ai'
import _ from 'lodash'

function AdminVideoViewComponent(props) {


    const [checkbox1Checked, setCheckbox1Checked] = useState(false);
    const [checkbox2Checked, setCheckbox2Checked] = useState(false);

    const handleCheckbox1Change = () => {
        setCheckbox1Checked(true);
        setCheckbox2Checked(false);
    };

    const handleCheckbox2Change = () => {
        setCheckbox1Checked(false);
        setCheckbox2Checked(true);
    };

    const goToRecordVideo = (data) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'adminRecordVideo' });
        }
    };
    return (
        <div >
            <div class='main-divHead'>
                <div className='sub-Head1' >
                    <div className="Sub-Head2" onClick={(e) => goToRecordVideo(e)}><span><AiOutlineLeft className='mb-1 me-2' /></span>Teacher</div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-4 m-4">
                            <div class='admin-card'>
                                {/* <img src={img} class="img-fluid admin-view-image rounded-top" alt="" /> */}
                                <div class="container">
                                    <div class="row text-end">
                                        <div class="col">
                                            <button className="btn outline-btn-admin px-2 fw-bold mt-2" >Preview course<span ><BsFillEyeFill class='adminEyeIcon ms-3' /></span></button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h5 class='fw-bold mb-3'>Mathematics</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p class='fs-6'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p class='fs-6'>Ratings <span class='fw-bold'>5.0</span> (240) <span class='fw-bold'>View All</span></p>
                                        </div>
                                    </div>
                                    <div class="row my-2">
                                        <div class="col">
                                            <hr></hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="container ">
                                                <div class="row">
                                                    <div className='d-flex justify-content-between'></div>
                                                    <div class="col">
                                                        <p class='fs-5 fw-semibold'>Students</p>
                                                    </div>
                                                    <div class="col">
                                                        <p class='fs-5 '>0</p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className='d-flex justify-content-between'></div>
                                                    <div class="col">
                                                        <p class='fs-5 fw-semibold'>Category</p>
                                                    </div>
                                                    <div class="col">
                                                        <p class='fs-5 '>category</p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className='d-flex justify-content-between'></div>
                                                    <div class="col">
                                                        <p class='fs-5 fw-semibold'>Sub Category</p>
                                                    </div>
                                                    <div class="col">
                                                        <p class='fs-5'>sub category</p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className='d-flex justify-content-between'></div>
                                                    <div class="col">
                                                        <p class='fs-5 fw-semibold'>Topic</p>
                                                    </div>
                                                    <div class="col">
                                                        <p class='fs-5 '>topic</p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className='d-flex justify-content-between'></div>
                                                    <div class="col">
                                                        <p class='fs-5 fw-semibold'>Price </p>
                                                    </div>
                                                    <div class="col">
                                                        <p class='fs-5 '>250$</p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className='d-flex justify-content-between'></div>
                                                    <div class="col">
                                                        <p class='fs-5 fw-semibold'>Teaching Language  </p>
                                                    </div>
                                                    <div class="col">
                                                        <p class='fs-5 '>English</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col mt-4">
                            <div class='admin-card'>
                                <h4 class='fs-5 fw-bold p-3 pt-5'>Video Course Content</h4>
                                <div class="container   mt-4">
                                    <div class="row">
                                        <div class="col-10 ">
                                            <div className="container ">
                                                <div className="form-group ">
                                                    <select className="form-select admin-input-view" id="exampleDropdown">
                                                        <option value="Get Started">Get Started</option>
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                        <option value="option4">Option 4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input custom-green "
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault1"
                                                    checked={checkbox1Checked}
                                                    onChange={handleCheckbox1Change}
                                                />
                                                <input
                                                    class="form-check-input custom-red ms-3 text-success"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault2"
                                                    checked={checkbox2Checked}
                                                    onChange={handleCheckbox2Change}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <div className="container ">
                                                <div className="form-group ">
                                                    <select className="form-select admin-input-view" id="exampleDropdown">
                                                        <option value="Content 1">Content 1</option>
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                        <option value="option4">Option 4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-check">
                                                <input class="form-check-input custom-green" type="checkbox" value="" id="flexCheckDefault" />
                                                <input class="form-check-input custom-red ms-3 text-success" type="checkbox" value="" id="flexCheckDefault" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <div className="container ">
                                                <div className="form-group ">
                                                    <select className="form-select admin-input-view" id="exampleDropdown">
                                                        <option value="Content 1">Content 2</option>
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                        <option value="option4">Option 4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-check">
                                                <input class="form-check-input custom-green" type="checkbox" value="" id="flexCheckDefault" />
                                                <input class="form-check-input custom-red ms-3 text-success" type="checkbox" value="" id="flexCheckDefault" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <div className="container ">
                                                <div className="form-group ">
                                                    <select className="form-select admin-input-view" id="exampleDropdown">
                                                        <option value="Content 1">Content 3</option>
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                        <option value="option4">Option 4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-check">
                                                <input class="form-check-input custom-green" type="checkbox" value="" id="flexCheckDefault" />
                                                <input class="form-check-input custom-red ms-3 text-success" type="checkbox" value="" id="flexCheckDefault" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <div className="container ">
                                                <div className="form-group ">
                                                    <select className="form-select admin-input-view" id="exampleDropdown">
                                                        <option value="Content 1">Content 4</option>
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                        <option value="option4">Option 4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-check">
                                                <input class="form-check-input custom-green" type="checkbox" value="" id="flexCheckDefault" />
                                                <input class="form-check-input custom-red ms-3 text-success" type="checkbox" value="" id="flexCheckDefault" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <div className="container ">
                                                <div className="form-group ">
                                                    <select className="form-select admin-input-view" id="exampleDropdown">
                                                        <option value="Content 1">End Course</option>
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                        <option value="option4">Option 4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-check">
                                                <input class="form-check-input custom-green" type="checkbox" value="" id="flexCheckDefault" />
                                                <input class="form-check-input custom-red ms-3 text-success" type="checkbox" value="" id="flexCheckDefault" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-10'>
                                        <div class='admin-card admin-video-personCard p-3 m-4'>
                                            <div class="container mx-3">
                                                <div class="row">
                                                    <div class="col-2">
                                                        {/* <img src={img1} class="img-fluid rounded-top " alt="" /> */}
                                                    </div>
                                                    <div class="col">
                                                        <h5>Subject Topic</h5>
                                                        <p class='fs-6'>Amet minim mollit non deserunt ullamco est <br></br>sit aliqua dolor do amet sint.</p>
                                                    </div>
                                                </div>
                                                <div class="row text-end">
                                                    <div class="col">
                                                        <p class='fs-6 mb-0 fw-bold '>View Profile</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container  ">
                                    <div class="row">
                                        <div class="col ">
                                            <button className="btn outline-btn-admin px-2 fw-bold mt-2 ms-4 px-4" >Ask Clarification</button>
                                        </div>
                                    </div>
                                    <div class="row text-end">
                                        <div class="col">
                                            <button type="button" class="btn text-light px-4 py-1 bootBtn  me-5 my-3" >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminVideoViewComponent




