import { ErrorMessage, Field, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import fb from '../../images/Fb.png';
import fb1 from '../../images/Fb (1).png';
import fb2 from '../../images/Fb (2).png'
import { LuMail } from "react-icons/lu";
import { currentEmail, sendEmail } from '../../redux/slice/AuthSlice';

function SignUpComponent({ setEmail }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        Email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
    });

    const initialValues = {
        Email: '',
    };

    const handleSubmit = async (values) => {   
        const payload = {
            email: values.Email,
            navigate
        }

        const valueToSend = {
            payload,
            navigate
        }
        dispatch(sendEmail(payload));
        dispatch(currentEmail(payload));

        // Pass email through props
        setEmail(values.Email);
    };

    return (
        <div>
            <div className="container text-start">
                <h2 className="admin-signup-text">Register with</h2>
                <div className='row my-4'>
                    <div className='col'>
                        <img src={fb} class="img-fluid " alt="" />
                    </div>
                    <div className='col'> <img src={fb1} class="img-fluid " alt="" /></div>
                    <div className='col'> <img src={fb2} class="img-fluid " alt="" /></div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <hr />
                    </div>
                    <div className='col-1'>
                        or
                    </div>
                    <div className='col'>
                        <hr />
                    </div>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="row mt-5">
                                <div className="col">
                                    <Field
                                        type="Email"
                                        name="Email"
                                        className={`form-control admin-signIn-input ${errors.Email && touched.Email ? 'is-invalid' : ''
                                            }`}
                                        placeholder="Email"
                                    />
                                    <ErrorMessage
                                        name="Email"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <button
                                        type="submit"
                                        className="btn admin-signIn-btn"

                                    >
                                        <span className="admin-signIn-btn-text">{false ? <div className='d-flex justify-content-center'><RotatingLines
                                            strokeColor="white"
                                            strokeWidth="5"
                                            animationDuration="0.75"
                                            width="40"
                                            height="40"
                                            visible={true}
                                        /></div> : <>Send code <span className='me-2'><LuMail /></span></>}</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="row mt-5">
                    <div className="col">
                        <p className="admin-signIn-account">
                            Already have an account? {' '}
                            <span className="admin-signIn-account-signUp" onClick={() => navigate('/user/sign_in')}>Sign in</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpComponent;
