
import React, { forwardRef } from 'react'
import { CSTData, SubData, getSubCategoryData, getSubTopicData, topicData } from '../../../../../../redux/slice/SubjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

function CSTComponent(props) {

    const data = useSelector(CSTData);
    const subCatData = useSelector(SubData);
    const TopicData = useSelector(topicData);
    const dispatch = useDispatch();
    const categoryOptions = data.map((e) => ({
        value: e.id,
        label: e.name
    }));
    const SubCategoryOptions = subCatData.map((e) => ({
        value: e.id,
        label: e.name
    }));
    const TopicOptions = TopicData.map((e) => ({
        value: e.id,
        label: e.name
    }));
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            type="text"
            onClick={onClick}
            className="form-control "
            value={value}
            ref={ref}
            placeholder=""
        />
    ));

    const dateFunction = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    };

    const validationSchema = Yup.object({
        date: Yup.date().nullable().label('Date'),
        category: Yup.string().label('Category'),
        subCategory: Yup.string().label('Sub Category'),
        topic: Yup.string().label('Topic'),
    });

    const initialValues = {
        date: null,
        category: '',
        subCategory: '',
        topic: '',
    };

    const onSubmit = (values) => {
        props.setFilters(values);
        const valueToSend = {};
        if (values.category !== "") {
            valueToSend.category = values.category;
        }
        if (values.subCategory !== "") {
            valueToSend.sub_category = values.subCategory;
        }
        if (values.topic !== "") {
            valueToSend.topic = values.topic;
        }
        if (values.date !== null) {
            valueToSend.date = dateFunction(values.date);
        }
        console.log("valueToSend", valueToSend);
    };

   
    return (
        <div>
            <div className="container text-start">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} >
                    {({ touched, errors, handleSubmit, resetForm, isSubmitting, setFieldValue, values, handleChange, handleBlur }) => (
                        <>
                            <div className="row">
                                <div className='d-flex justify-content-start'>
                                    {/* <div className="col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 ms-1">
                                        <div>
                                            <div className="container ">
                                                <div className='row'>
                                                    <div className='col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleDropdown" className="fs-5 fw-semibold">Date</label><br />
                                                            <input
                                                                type='date'
                                                                className='admin-dropdown_date'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 ms-1">
                                        <div className="container ">
                                            <div className="form-group">
                                                <label htmlFor="categoryDropdown" className=" fw-semibold">Category</label>
                                                <select
                                                    className="form-select admin-dropdown"
                                                    id="categoryDropdown"
                                                    name="category"
                                                    value={values?.category}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        const payload = {
                                                            "type": 2,
                                                            "category_id": selectedValue,
                                                        };
                                                        dispatch(getSubCategoryData(payload));
                                                        setFieldValue('category', selectedValue);
                                                    }}
                                                >
                                                    <option value=""></option>
                                                    {categoryOptions?.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 ms-1">
                                        <div>
                                            <div className="container ">
                                                <div className="form-group">
                                                    <label htmlFor="subCategoryDropdown" className=" subCategoryDropdown">Sub Category</label>
                                                    <select
                                                        className="form-select admin-dropdown"
                                                        id="subCategoryDropdown"
                                                        name="subCategory"
                                                        disabled={values.category == "" ? true : false}
                                                        value={values?.subCategory}
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            const payload = {
                                                                "type": 3,
                                                                "sub_category_id": selectedValue,
                                                            };
                                                            dispatch(getSubTopicData(payload));
                                                            setFieldValue('subCategory', selectedValue);
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <option value=""></option>
                                                        {SubCategoryOptions?.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 ms-1">
                                        <div>
                                            <div className="container ">
                                                <div className="form-group">
                                                    <label htmlFor="topicDropdown" className=" fw-semibold">Topic</label>
                                                    <select
                                                        className="form-select admin-dropdown"
                                                        id="topicDropdown"
                                                        name="topic"
                                                        value={values.topic}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={values.subCategory == "" ? true : false}
                                                    >
                                                        <option value=""></option>
                                                        {TopicOptions?.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col ms-1">
                                        <div>
                                            <div className="container ">
                                                <div className="form-group">
                                                    <label htmlFor="topicDropdown" className="fs-5 fw-semibold">Subjects</label>
                                                    <select
                                                        className="form-select admin-dropdown"
                                                        id="topicDropdown"
                                                        name="topic"
                                                        value={values.topic}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={values.subCategory == "" ? true : false}
                                                    >
                                                        <option value=""></option>
                                                        {TopicOptions.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-3 col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 align-self-end ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-sm-5">
                                        <button type="submit" onClick={handleSubmit} className="btn btn-primary px-5 py-2 bootBtn">Filter</button>
                                    </div>
                                </div>
                            </div>
                        </>)}
                </Formik>
            </div>
        </div>
    )
}

export default CSTComponent