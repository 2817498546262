import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Rating } from '@mui/material';
import StarRatings from 'react-star-ratings';
import axios from 'axios'; // Import axios for making HTTP requests
import BASE_URL from '../../../../../../../BaseUrl';
import Axios from '../../../../../../../util/apiUtil';

function RatingsComponent({...props}) {
    const location = useLocation(); 
    const ViewCourses = props.showOrder || {};
    console.log("fff", ViewCourses);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const navigate = useNavigate();

    const StuBackdashboard = () => {
    
        navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
    }

    // Function to handle submission of review
    const handleSubmit = async () => {
        try {
            const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
            const data = {
                rating: rating,
                subject_id: ViewCourses.id,
                review: review
            };
    
            console.log("Request Data:", data);
    
            const response = await Axios.post('/api/markRating', data, { headers });
    
            console.log("Response Data:", response.data);
    
            // Optionally, handle success response here
            // For example, show a success message to the user
    
            navigate("/dashboard");
        } catch (error) {
            console.error('Error submitting review:', error);
    
            // Optionally, handle error here
            // For example, show an error message to the user
        }
    };
    

    const changeRating = (newRating) => {
        setRating(newRating);
    };

    const handleReviewChange = (event) => {
        setReview(event.target.value);
    };

    return (
        <>
            <div className="main-divHead">
                <div className="showsubject-container p-0">
                    <div className="profile-main-head d-flex " onClick={() => StuBackdashboard()}>
                        <div className="fa-chaveron">
                            <IoIosArrowBack className="mb-1" />{" "}
                        </div>
                        <div className="profile-head">Ratings</div>
                    </div>
                    <div className="container mt-5">
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <div className="card col-8 border-0 mb-5">
                        <div className="card-body">
                            <h1 className='d-flex justify-content-center'>Put Your Reviews</h1><hr />
                            <p className='d-flex justify-content-center'>How much you Rate this Courses</p>
                            <div className='d-flex justify-content-center'>
                                <StarRatings
                                    rating={rating}
                                    starRatedColor="#FFC700"
                                    changeRating={changeRating}
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="60px"
                                />
                            </div>

                            <h4 className='d-flex justify-content-center mt-5'>Share Your Experience about this Course and Tutor</h4>
                            <div className='d-flex justify-content-center'>
                                <div className='col-8 '>
                                    <textarea
                                        className="form-control"
                                        placeholder="Leave experience here"
                                        value={review}
                                        onChange={handleReviewChange}
                                    ></textarea>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center mt-3'>
                                <button className="btn btn-primary" onClick={handleSubmit}>Submit Review</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RatingsComponent;
