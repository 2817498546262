import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineLogout, MdOutlineVerified } from "react-icons/md";
import img from "../../../../../../../images/Ellipse 2 (2).jpg";
import "./StudentProfile.scss";
import _ from "lodash";
import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  updateProfile,
  listCountries,
  listCountriesData,
  userProfileData,
  ProfileData,
  getUserProfile,
} from "../../../../../../../redux/slice/studentSlice";
import { userLogout } from "../../../../../../../redux/slice/AuthSlice";
import { RotatingLines } from "react-loader-spinner";

function StudentProfile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const countryListData = useSelector(listCountriesData);
  const userProfile = useSelector(userProfileData);
  const ProfileDetails = useSelector(ProfileData)
  const authToken = useSelector((state) => state.auth.Token);
  const basePath = "http://tuitionguruji.com/backend/public/";

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogout({ authToken, navigate }));
  };
  useEffect(() => {
    dispatch(getUserProfile())
    dispatch(listCountries());
    // dispatch(updateProfile())
  }, []);
  const [profile, setProfile] = useState({

    phone_num: "",
    country_id: "",
    // bio: "",
    // category: "",
    // experience: "",
    // qualification: "",
    // sub_category: "",
    // topic: ""
  })
  const Backdashboard = () => {
    navigate("/student", { state: { type: 'Student', subType: 'Dashboard' } });
  }

  const validationSchema = Yup.object({
    phone_number: Yup.string().required('Phone number is required')
      .matches(/^\+?[0-9\s-]+$/, 'Invalid phone number format'),
    country_id: Yup.string().required('country_id is required'),
    /* bio: Yup.string().required('bio is required'),
 category: Yup.string().required('category is required'),
 experience: Yup.string().required('experience is required'),
 qualification: Yup.string().required('qualification is required'),
 sub_category: Yup.string().required('sub_category is required'),
 topic: Yup.string().required('topic is required'), */

  });
  const handleInput = (e, setFieldValue) => {
    const { name, value } = e.target;

    setProfile({ ...profile, [name]: value });
  };

  const initialValues = {
    phone_num: "",
    country_id: "",
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setLoading(true);

      const payload = {
        phone_number: values.phone_num,
        country_id: values.country_id,
      };

      await dispatch(updateProfile(payload));

      // Reset form and perform any other necessary actions after successful submission
      resetForm();
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };


  const countryList = Object?.keys(countryListData).map((key) => ({
    key: key,
    country_name: countryListData[key],
  }));
  return (
    <div>
      <div
        className="profile-main-head d-flex py-2"
        onClick={() => Backdashboard()} >
        <div className="fa-chaveron">
          <IoIosArrowBack className="mb-1" />{" "}
        </div>
        <div className="profile-head">Profile</div>
      </div>
      <div class="container ">
        <div class="row mt-4">
          <div class="col-4  ">
            <div class="admin-card student-pro-card  py-4 text-center">
              <div>
                <img src={ProfileDetails?.profile_pic ? basePath + ProfileDetails?.profile_pic : ""} class="img-fluid " alt="" />
              </div>
              <p class="fw-bold fs-5 mt-3">
                {ProfileDetails?.firstname}{" "}
                <span>
                  <MdOutlineVerified class="admin-verified-btn" />
                </span>
              </p>
              <button
                type="button"
                class="btn admin-navbar-search-btn text-light px-4 py-1 mt-5 mb-3 "
                onClick={() => handleLogout()}>
                {" "}
                <span class="me-2">
                  <MdOutlineLogout />
                </span>
                Log Out
              </button>
              {/* <div class="row d-flex justify-content-center">
                <div class="col-10 m-0 ">
                  <div class="card profile-card m-0 p-0">
                    <div class="card-body ">
                      <h5 class="card-title">Have a question?</h5>
                      <p class="card-text">
                        Here you can send a direct request to the site owner.
                      </p>
                      <button
                        type="button"
                        className=" rounded stu-profile-req pe-3 ps-3 pt-1 pb-1"
                      >
                        Send Request
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div class="col-8">
            <div class="admin-card p-4">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit} >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <p class="fs-5 fw-semibold">Profile Info</p>
                    <div class="container text-start">
                      <div class="row">
                        <div class="col">
                          <label class=" mt-4 fw-semibold">First Name</label>
                          <div>
                            <Field
                              type="text"
                              name="firstname"
                              className={`form-control admin-signIn-input ${errors.firstname && touched.firstname ? 'is-invalid' : ''}`}
                              value={ProfileDetails?.firstname}
                              onChange={(e) => {
                                setFieldValue("firstname", e.target.value)
                                handleInput(e)
                              }} />

                          </div>
                        </div>
                        <div class="col">
                          <label class=" mt-4 fw-semibold">Last Name</label>
                          <Field
                            type="text"
                            name="lastname"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className={`form-control admin-signIn-input ${errors.lastname && touched.lastname ? 'is-invalid' : ''
                              }`}
                            value={ProfileDetails?.lastname}
                            onChange={(e) => {
                              setFieldValue("lastname", e.target.value)
                              handleInput(e)
                            }}
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <label class=" mt-4 fw-semibold">Email Address</label>
                          <Field
                            type="text"
                            className={`form-control admin-signIn-input ${errors.email && touched.email ? 'is-invalid' : ''}`}
                            value={ProfileDetails?.email}
                            onChange={(e) => {
                              setFieldValue("email", e.target.value)
                              handleInput(e)
                            }}
                            placeholder="Email"
                            aria-label="Username"
                            aria-describedby="basic-addon1" />
                        </div>
                        <div className="col">
                          <label className="mt-4 fw-semibold">Referral code</label>
                          <Field

                            type="text"
                            aria-label="Referral Code"
                            aria-describedby="basic-addon1"
                            name="referal_code"
                            className="form-control admin-signIn-input"
                            placeholder="Referral Code"
                            value={ProfileDetails?.student?.referal_code}
                            readOnly
                          >

                          </Field>
                          <p  className='p-placeholder'>You can share this code ,Then you get wallet coins</p>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div class="col">
                          <label class=" mt-4 fw-semibold">Mobile</label>
                          <Field
                            type="number"
                            name="phone_num"
                            className={`form-control admin-signIn-input ${errors.phone_num && touched.phone_num ? 'is-invalid' : ''}`}
                            placeholder="phone number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            // value={ProfileDetails?.phone_num}
                            onChange={(e) => {
                              setFieldValue("phone_num", e.target.value)
                              handleInput(e)
                            }}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col"></div>
                      </div> */}
                    </div>
                    {/* <div className="row mt-4 d-flex justify-content-center">
                      <div className="col-2">
                        <button
                          type="submit"
                          className="btn admin-signIn-btn"
                          disabled={loading}>
                          <span className="admin-signIn-btn-text">{loading ? <div className='d-flex justify-content-center'><RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="40"
                            height="40"
                            visible={true}
                          /></div> : "Submit"}</span>
                        </button>
                      </div>
                    </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StudentProfile;
