import React, { useEffect } from 'react';
import img from '../../../../../../DashImage/GroupImg.png';
import img1 from '../../../../../../DashImage/Graph.png'
import img2 from '../../../../../../DashImage/GraphDown.png'
import img3 from '../../../../../../DashImage/Quires.png'
import img4 from '../../../../../../DashImage/Sales.png'

import { useDispatch, useSelector } from 'react-redux';
import { DashboardListData, MainDashboardData, } from '../../../../../../redux/slice/dashboardSlice';
import { DashboardData, fetchDashboardData } from '../../../../../../redux/slice/SubjectSlice';
import { showSuccessToast } from '../../../../../../util/toastUtils';
import ChartComponent from '../adminPage/chart.component';
import CalenderComponent from '../adminPage/calender.component';
import DashboardSubjectTableComponent from '../adminPage/DashboardSubjectTable.component';
import DashboardNotificationComponent from '../adminPage/dashboardNotification.component';

function UploaderDashboardComponent() {

    const dispatch = useDispatch();
    const data = useSelector(DashboardData)

    useEffect(() => {
        dispatch(fetchDashboardData());
    }, [dispatch]);

    return (
        <div >
            <h5 class='fw-bold px-4 pt-4 pb-2'>Course Upload Dashboard</h5>
            <div class="container m-lg-4">
                <div class="row">
                    <div class="col-lg-3 col-12">
                        <div className='admin-card p-3 '>
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <p class='admin-gray-text'>Total Students</p>
                                        <h4 class='fw-bold'>2</h4>
                                    </div>
                                    <div class="col-4">
                                        <img src={img} class="img-fluid rounded-top " alt="" />
                                    </div>
                                </div>
                                <div class="row mt-3 ">
                                    <div class="col">
                                        <p class='mb-0'><span><img src={img1} class="img-fluid rounded-top" alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-12 mt-3 mt-lg-0">
                        <div className='admin-card p-3 '>
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <p class='admin-gray-text'>Total Teachers</p>
                                        <h4 class='fw-bold'>2</h4>
                                    </div>
                                    <div class="col-4">
                                        <img src={img3} class="img-fluid rounded-top " alt="" />
                                    </div>
                                </div>
                                <div class="row mt-3 ">
                                    <div class="col">
                                        <p class='mb-0'><span><img src={img1} class="img-fluid rounded-top" alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-12  mt-3 mt-lg-0">
                        <div className='admin-card p-3 '>
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <p class='admin-gray-text'>Total Freelancers</p>
                                        <h4 class='fw-bold'>2</h4>
                                    </div>
                                    <div class="col-4">
                                        <img src={img} class="img-fluid rounded-top " alt="" />
                                    </div>
                                </div>
                                <div class="row mt-3 ">
                                    <div class="col">
                                        <p class='mb-0'><span><img src={img1} class="img-fluid rounded-top" alt="" /></span><span class='admin-green-dash'> 8.5%</span> <span class='admin-gray-text' >Up from yesterday</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-12  mt-3 mt-lg-0">
                        <div className='admin-card p-3 '>
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <div class='d-flex'>
                                            <div>
                                                <p class='admin-gray-text '>Total Course Uploaders</p>
                                                <h4 class='fw-bold'>2</h4>
                                            </div>
                                            <div class='ms-1'>
                                                <img src={img4} class="img-fluid rounded-top " alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col-4">
                  </div> */}
                                </div>
                                <div class="row mt-3 ">
                                    <div class="col">
                                        <p class='mb-0'><span><img src={img2} class="img-fluid rounded-top" alt="" /></span><span class='admin-red-dash'> 8.5%</span> <span class='admin-gray-text' >Down from yesterday</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-8 col">
                        <ChartComponent />
                    </div>
                    <div class="col-lg-4 col mt-3 mt-lg-0">
                        <CalenderComponent />
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-8 col">
                        <DashboardSubjectTableComponent />
                    </div>
                    <div class="col-lg-4 col mt-3 mt-lg-0">
                        <DashboardNotificationComponent />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploaderDashboardComponent