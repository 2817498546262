import React, { useState } from "react"
import announcementWhiteIcon from "../../../icons/announcementWhiteIcon.png"
import announcementBlueIcon from "../../../icons/announcementBlueIcon.png"
import _ from 'lodash';

function Announcement(props) {

  const [hoverEffect, setHoverEffect] = useState(false)
  const goToAnnouncement = (e) => {
    if (_.get(props, 'setUserState')) {
      props.setUserState({ ...props.userState, subType: 'Announcement' })
    }
  }
  return (
    <div className={`sidebar_content ${_.get(props, 'userState.subType') == 'Announcement' ? 'active' : ''} `}

    >
      <img
        src={_.get(props, 'userState.subType') != 'Announcement' ? announcementWhiteIcon : announcementBlueIcon}
        alt='Announcement'
      />
      <p className='side_heading' onClick={(e) => goToAnnouncement(e)}> Announcement


      </p>

    </div>
  )
}

export default Announcement
