import React, { useEffect, useState } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import './TeacherSubject.scss'
import DataTable from 'react-data-table-component';
import axios from "axios"
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate, } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu, { } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ViewDetails from './ViewDetails';
import { format } from 'date-fns';
import { isAfter } from 'date-fns';
import { showErrorToast, showSuccessToast } from '../../../../../../util/toastUtils';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';

function TeacherSubject(props) {

    const getStatus = (endDate) => {
        const currentDate = new Date();
        if (isAfter(currentDate, new Date(endDate))) {
            return 'Inactive';
        }
        return 'Active';
    };


    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [view, setView] = useState(false)
    const [loading, setLoading] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);
    const [isView, setIsView] = useState(false);
    const [isStudentList, setIsStudentList] = useState(false);
    const [rowId, setRowId] = useState(false);
    const [issubject, setIsSubject] = useState(false);
    const [isactions, setIsActions] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const [isEditSubject, setIsEditSubject] = useState(false);

   
     
    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teachersubject' })
        }
    }

    const goToTeacherAddSubject = (e, row, subjectId) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'addsubject', row: row, subject_id: subjectId })
            setIsEditSubject(true)
        }
    }

    const goToTeacherViewSubject = (e, row, subject_id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'ViewSubject', row: row })
            setIsEditSubject(true)
        }
    }

    const goToTeacherAddSubjectList = (e, row, code) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'studentlistcoupon', row: row, code: code })
            setIsStudentList(false)
        }
    }
    const goToTeacherCouponUsage = (e, row, code) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'couponusage', row: row, code: code })
            setIsStudentList(false)
        }
    }

    const goToTeacherCreateCoupon = (e, row, status) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'createCoupon', row: row, status: status, })
        }
    }

    const goToTeacherViewCoupon = (e, row, code,) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'ViewCoupon', row: row, code: code, })
        }
    }

    const goToTeacherSubjectStudentDetails = (e, row,) => {

        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'subjectstudentdetails', row: row })
        }
    }

    const goToTeachereditSubject = (e, row,) => {

        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'editsubject', row: row })
        }
    }
    const goToTeacherResource = (e, row, subject_id) => {

        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherresource', row: row, subject_id: subject_id })
        }
    }

    const onclicksuject = () => {
        setIsSubject(false)
    }
    const onclickCoupon = () => {
        setIsSubject(true)
    }
    const handleClickEdit = (rowId) => {
        setIsEditSubject(true)
        navigate('/TeacherEditSubject')
        setRowId(rowId)
    }

    const handleCloseActions = () => {
        setIsActions(false);
        setActiveMenu(null);
    };
    const handleClickActions = (event, rowId) => {
        setAnchorEl1(event.currentTarget);
        setIsActions((prevState) => (prevState === rowId ? null : rowId));

        setView(false)
    };

    // const handleClickActions = (event, rowId) => {
    //     setAnchorEl1(event.currentTarget);
    //     setIsActions((prevState) => (prevState === rowId ? null : rowId));

    //     setView(false)
    // };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [forceRerender, setForceRerender] = useState(false);

    const handleInactiveAction = async (row) => {
        try {
            const newStatus = row.status === "1" ? "0" : "1";
            console.log('New status:', newStatus);

            // Change subject status
            await changeSubjectStatus(row.id, newStatus);

            setSubjects(prevSubjects => prevSubjects.map(subject => {
                if (subject.id === row.id) {
                    return { ...subject, status: newStatus };
                }
                return subject;
            }));

        } catch (error) {
            console.error('Error changing Subject status:', error);
        }
    };

    // const handleInactiveAction = async (row) => {
    //     try {
    //         const newStatus = row.status === "1" ? "0" : "1";
    //         console.log('New status:', newStatus);

    //         // Change subject status
    //         await changeSubjectStatus(row.id, newStatus);

    //         setSubjects(prevSubjects => prevSubjects.map(subject => {
    //             if (subject.id === row.id) {
    //                 return { ...subject, status: newStatus };
    //             }
    //             return subject;
    //         }));

    //         localStorage.setItem('subjectStatus', newStatus);

    //     } catch (error) {
    //         console.error('Error changing Subject status:', error);
    //     }
    // };




    const [subjects, setSubjects] = useState([{
        created_at: "",
        category: "",
        sub_category: "",
        subject: "",
        price: "",
        id: '',
    }]);
    useEffect(() => {
        getRows();
        fetchSubjects();
    }, []);

    useEffect(() => {

        const storedStatus = localStorage.getItem('subjectStatus')
        if (storedStatus !== null) {
            const newData = [...data]
            newData.forEach((item) => {
                item.status = parseInt(storedStatus);
            });
            setData(newData);
        }
    }, [setData]);

    const fetchSubjects = () => {

        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) }

        Axios.post('/api/listSubject', undefined, { headers: headers })
            .then((response) => {
                const storedStatus = localStorage.getItem('subjectStatus');
                const formattedData = response.data.data.map((item) => ({
                    ...item,
                    created_at: format(new Date(item.created_at), 'dd-MM-yyyy'),
                }));
                setSubjects(formattedData);
                setLoading(false)

            })

            .catch((error) => {
                console.error('Error fetching Subjects:', error)
                setLoading(false)
            })
    }

    const changeSubjectStatus = (subject_id, status) => {
        return new Promise((resolve, reject) => {
            const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
            const formData = new FormData();
            formData.append('subject_id', subject_id);
            formData.append('status', status);
            Axios.post('/api/changeSubjectStatus', formData, { headers: headers })
                .then(() => {
                    console.log('Subject status changed successfully');
                    resolve();
                })
                .catch((error) => {
                    console.error('Error changing subject status:', error);
                    reject(error);
                });
        });
    };

    const customStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#5C5AB6',
                fontSize: "13px",
                WidthFull: "600px"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff"
            }
        },
    }

    const columns = [
        {
            name: ' Date',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: ' Course Name',
            selector: row => row.name,
            cell: row => <div className='table_course' title={row.name}>{row.name}</div>,
        },
        {
            name: ' Category',
            selector: row => row.category_name
            ,
            sortable: true,
        },
        {
            name: ' Sub Category',
            selector: row => row.sub_category_name,
            sortable: true,
        },
        {
            name: 'Topic',
            selector: row => row.topic_name,
            sortable: true,

        },

        {
            name: 'Status',
            selector: row => {
                const color = row.status === 1 ? 'green' : 'red';
                return (
                    <span style={{ color }}>
                        {row.status === 1 ? 'Active' : 'Inactive'}
                    </span>
                );
            },
            sortable: true,
        },

        {
            name: 'Action',
            width: "120px",
            cell: (row, subject_id, id) => (
                <div className={`Teacher subject-actions ${isactions == row.id ? '' : 'active'}`}>
                    <Button className="action-subj d-flex" id="basic-button-sub"
                        aria-controls={open ? 'basic-menu-sub' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e) => handleClickActions(e, row.id)}>
                        <MoreVertIcon />
                    </Button>
                    <div >
                        {isactions == row.id &&
                            <Menu
                                className="action-bar"
                                id="basic-menu-sub"
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button-sub',
                                }}>
                                <MenuItem onClick={(e) => goToTeacherViewSubject(e, row, id)} disableRipple className="ul-for-options ">
                                    View
                                </MenuItem>
                                <MenuItem 
                                onClick={(e) => 
                                    goToTeachereditSubject(e, row, id)} 
                                    disableRipple className="ul-for-options">
                                    Edit
                                </MenuItem>
                                <MenuItem onClick={(e) => goToTeacherSubjectStudentDetails(e, row, subject_id)} disableRipple className="ul-for-options">
                                    Studentlist
                                </MenuItem>
                                <MenuItem onClick={(e) => goToTeacherResource(e, row, subject_id)} disableRipple className="ul-for-options">
                                    Add Resource
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    className="ul-for-options"
                                    // data-bs-toggle="modal" data-bs-target="#exampleModal"
                                    onClick={(e) => handleInactiveAction(row)}
                                >
                                    {row.status === "1" ? 'In Active' : 'Active'}
                                </MenuItem>


                            </Menu>
                        }
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content p-5">
                                    <div class=" justify-content-center ms-5">
                                        <h1 class="modal-title fs-5 ms-5" id="exampleModalLabel">Do you want   {row.status === "1" ? 'In Active' : 'Active'} ?</h1>
                                        <button type="button" class="btn btn-primary mt-3 px-4 ms-4" data-bs-dismiss="modal" onClick={() => handleInactiveAction(row)}>Active</button>
                                        <button type="button" class="btn btn-danger mt-3 ms-5 px-4" data-bs-dismiss="modal" onClick={() => handleInactiveAction(row)}>Deactive</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
    ];

    const columns1 = [

        {
            name: 'Start Date',
            selector: row => {
                // Parse the date and format it as just the date
                const date = new Date(row.start_date)
                return format(date, 'dd/MM/yyyy')
            },
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => {
                // Parse the date and format it as just the date
                const date = new Date(row.end_date)
                return format(date, 'dd/MM/yyyy')
            },
            sortable: true,
        },
        {
            name: ' Discount Amount %',
            selector: row => row.value,
            sortable: true,

        },

        {
            name: ' Coupon Limit',
            selector: row => row.limit,
            sortable: true,

        },
        {
            name: 'Coupon Name',
            selector: row => row.offer_name,
            sortable: true,

        },

        {
            name: 'Status',
            selector: (row) => row.end_date, // Change this to your end date field
            sortable: true,
            cell: (row) => (
                <span style={{ color: getStatus(row.end_date) === 'Active' ? 'green' : 'red' }}>
                    {getStatus(row.end_date)}
                </span>
            ),
        }, ,

        {
            name: 'Action',
            sortable: true,
            cell: (row, code, status, rowIndex) => (
                <div className={`Teacher subject-actions ${isactions == row.id ? '' : 'active'}`}>
                    <Button className="action-subj d-flex" id="basic-button-sub"
                        aria-controls={open ? 'basic-menu-sub' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e) => handleClickActions(e, row.id)}>
                        <MoreVertIcon />
                    </Button>
                    <div >
                        {isactions == row.id &&
                            <Menu
                                className="action-bar"
                                id="basic-menu-sub"
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button-sub',
                                }}>
                                <MenuItem onClick={(e) => goToTeacherViewCoupon(e, row, code)} disableRipple className="ul-for-options ">
                                    view
                                </MenuItem>
                                <MenuItem onClick={(e) => goToTeacherCreateCoupon(e, row, status)} disableRipple className="ul-for-options">
                                    Edit
                                </MenuItem>
                                <MenuItem onClick={(e) => goToTeacherAddSubjectList(e, row, code)} disableRipple className="ul-for-options ">
                                    Student List
                                </MenuItem>
                                <MenuItem onClick={(e) => goToTeacherCouponUsage(e, row, code)} disableRipple className="ul-for-options ">
                                    Coupon Usage
                                </MenuItem>
                            </Menu>
                        }
                    </div>
                </div>
            )
        }
    ]

    const fetchCoupons = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard()
            return
        }

        const headers = {
            'Authorization': 'Bearer ' + (localStorage.getItem('token'))
        }

        Axios.post('/api/listCoupon', undefined, { headers })
            .then((response) => {
                if (_.get(response, 'data.data')) {
                    setData(response.data.data)
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.error('Error fetching Coupons:', error)
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchCoupons()
    }, [])


    const [rows, setRows] = useState([])

    const getRows = () => {

        let res = _.find(subjects, sub => sub.id == isactions)

        setRows([res])

    }

    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'courses');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('activeTab', tab);
    };

    return (
        <div className="sidebar_content">
            <div className='main-divHead'>
                <div className='sub-Head1' onClick={Backdashboard}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <div className='Sub-Head2'>Courses</div>
                </div>
                <div className='buttons d-flex'>
                    <Grid item className={`Teacher subject-Teacher ${activeTab === 'courses' ? '' : 'active'}  d-flex`}>
                        <Button className="btn1-teacher-subj d-flex" onClick={() => handleTabClick('courses')}>
                            <div className="teacher-subject">My Courses</div>
                        </Button>
                    </Grid>&nbsp;&nbsp;
                    <Grid item className={`Teacher coupon-Teacher ${activeTab === 'coupons' ? 'active' : ''}  d-flex`}>
                        <Button className="btn1-teacher-subj d-flex" onClick={() => handleTabClick('coupons')}>
                            <div className="teacher-coupon">Coupon List</div>
                        </Button>
                    </Grid>
                </div>
                {activeTab === 'courses' && (
                    <div className='tea-add-subject'>
                        <div className='subject-container'>
                            <button className='btn-addsub-teacher' onClick={goToTeacherAddSubject}>Add Courses</button>
                        </div>
                        <div class="col-11 ms-5 justify-content-center">
                            <DataTable
                                columns={columns}
                                data={subjects}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                striped
                                customStyles={customStyles}
                                persistTableHead
                                pagination
                            />
                        </div>
                    </div>
                )}
                {activeTab === 'coupons' && (
                    <div className='tea-add-subject'>
                        <div className='subject-container'>
                            <button className='btn-addsub-teacher' onClick={goToTeacherCreateCoupon}>Create Coupon</button>
                        </div>
                        <div class="col-11 ms-5 justify-content-center">
                            <DataTable
                                columns={columns1}
                                data={data}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                striped
                                customStyles={customStyles}
                                persistTableHead
                                pagination
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
}

export default TeacherSubject