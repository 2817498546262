import React, { useState, useEffect } from 'react'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import './TeacherWithdrawl.scss'
// import { AiOutlineLeft } from 'react-icons/ai';
import axios from 'axios'
import { Row } from 'react-bootstrap';
import { format } from 'date-fns';
import AddAnotherBankAccnt from './AddAnotherBankAccnt'
import { useDispatch, useSelector } from 'react-redux';
import { StateUpdate, decrement, increment } from '../../../../../../redux/slice/counterSlice';
import BASE_URL from '../../../../../../BaseUrl';
import Axios from '../../../../../../util/apiUtil';


function TeacherWithdrawl(props) {
    const count = useSelector((state) => state.counter.value);
    const dispatch = useDispatch();
    const [rows, setRows] = useState()
    const [loading, setLoading] = useState(true)
    const [view, setView] = useState()
    const [selectedAccount, setSelectedAccount] = useState({});

    console.log("selectedAccount", selectedAccount);

    const [wallet, setWallet] = useState([{
    }])

    console.log("wallet", wallet);


    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);

    };
    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        fetchWalletList()
    }, [])

    const fetchWalletList = () => {
        if (!localStorage.getItem('token')) {
            Backdashboard();
        }
        const headers = { 'Authorization': 'Bearer ' + (localStorage.getItem('token')) };
        Axios.post('/api/listAccountDetails', undefined, { headers: headers })
            .then((response) => {
                console.log(response.data);
                const responseData = response.data.data;
                setWallet(responseData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching wallet list:', error);
                setLoading(false);
            })
    };

    const Backdashboard = () => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'Teacherwallet' })
        }
    }

    const goToTeacherBankDetail = (e, id) => {
        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'bankaccountdetails', id: id })
        }
    }

    const goToTeacherAddBank = (item, selectedAccount) => {

        if (_.get(props, 'setUserState')) {
            props.setUserState({ ...props.userState, subType: 'addteacherbank', viewEdit: item, selectedAccount: selectedAccount })
        }
    }
    const handleMoreDetailsClick = (account) => {
        setSelectedAccount(account);
    }
    const handleEditClick = () => {
        goToTeacherAddBank();
    }
    return (
        <div className='main-divHead' >
            <div className='sub-Head1' onClick={Backdashboard}>  <FontAwesomeIcon icon={faChevronLeft} />
                <div className='Sub-Head2'> Wallet </div>
            </div>
            <button className='Add-another-accnt mt-4 ms-4 ps-3 pe-3' onClick={(e) => goToTeacherAddBank(e)} disabled={wallet.length === 1}>Add Another Account</button>
            {wallet.length === 1 && (
                <div className="validation-message mt-2 ms-4 text-danger">You can add only one account.</div>
            )}
            <div className='container my-5'>
                <div className='row justify-content-center mt-4'>
                    <div className='col-6'>
                        {wallet.map((item, index) => (
                            <div key={index} className='admin-card'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col  account-head rounded-top'>
                                            <p className='text-light fw-semibold fs-5 mb-0 py-2 ps-3'>Account</p>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col '>
                                            <p className='account-number fs-6'> Account Number:</p>
                                        </div>
                                        <div className='col '>
                                            <p className='account-number  fs-6'>{item.account_number}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col '>
                                            <p className='account-number  fs-6'>Branch Name:</p>
                                        </div>
                                        <div className='col '>
                                            <p className='account-number  fs-6'>{item.branch_name}</p>
                                        </div>
                                    </div>
                                    <div className='row text-end' >
                                        <p className="more-details fs-6" onClick={() => handleMoreDetailsClick(item)} data-bs-toggle="modal" data-bs-target="#exampleModal">More details</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div class="modal " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">
                            <div className="sub-Head1">
                                <div className="Sub-Head2"><span><faChevronLeft className='mb-1 me-2' />
                                </span>Account Details</div>
                            </div></h1>
                        <div class="modal-body">
                            <div class="container text-start">
                                <div class="row">
                                    <div class="col">
                                        <p>Bank Name :</p>
                                    </div>
                                    {selectedAccount && (
                                        <div class="col">
                                            <p>{selectedAccount.bank_name}</p>
                                        </div>)}
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>Account Number :</p>
                                    </div>
                                    <div class="col">
                                        <p>{selectedAccount.account_number}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>Branch Name:</p>
                                    </div>
                                    <div class="col">
                                        <p>{selectedAccount.branch_name}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>First Name:</p>
                                    </div>
                                    <div class="col">
                                        <p>{selectedAccount.firstname}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>Last Name:</p>
                                    </div>
                                    <div class="col">
                                        <p>{selectedAccount.lastname}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>Zip Code:</p>
                                    </div>
                                    <div class="col">
                                        <p>{selectedAccount.zipcode}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>Country</p>
                                    </div>
                                    <div class="col">
                                        <p>{selectedAccount.country}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>City:</p>
                                    </div>
                                    <div class="col">
                                        <p>{selectedAccount.state}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container text-center">
                            <div className="row py-2">
                                <div className="col py-3">
                                    {wallet.map((item, index) => (<button key={index} type="button" className="btn btn-primary px-4 rounded-pill bootBtn " data-bs-dismiss="modal" onClick={() => goToTeacherAddBank(item, selectedAccount)}>Edit</button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* 

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
            >

                <div className='main-divHead-pop11' >
                    <div className='sub-Head1-pop'>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <div className='Sub-Head2'>Account Details</div>

                    </div>

                    {selectedAccount && (
                        <div className='col'>
                            <p >Account Number : </p> <p>{selectedAccount.account_number}</p>
                        </div>
                    )}

                    {selectedAccount && (
                        <div className='view-schedule-details22'>
                            <p className='p1-view-schedule'>Branch Name : </p> <p className='p2-view-schedule'>{selectedAccount.branch_name}</p>
                        </div>
                    )}

                    {selectedAccount && (
                        <div className='view-schedule-details22'>
                            <p className='p1-view-schedule'>First Name : </p> <p className='p2-view-schedule'>{selectedAccount.firstname}</p>
                        </div>
                    )}

                    {selectedAccount && (
                        <div className='view-schedule-details22'>
                            <p className='p1-view-schedule'>Last Name : </p> <p className='p2-view-schedule'>{selectedAccount.lastname}</p>
                        </div>
                    )}

                    {selectedAccount && (
                        <div className='view-schedule-details22'>
                            <p className='p1-view-schedule'>Zip Code : </p> <p className='p2-view-schedule'>{selectedAccount.zipcode}</p>
                        </div>
                    )}

                    {selectedAccount && (
                        <div className='view-schedule-details22'>
                            <p className='p1-view-schedule'>Country  : </p> <p className='p2-view-schedule'>{selectedAccount.country}</p>
                        </div>
                    )}

                    {selectedAccount && (
                        <div className='view-schedule-details33'>
                            <p className='p1-view-schedule'>State : </p> <p className='p2-view-schedule'>{selectedAccount.state}</p>
                        </div>
                    )}
                    {wallet.map((item, index) => (
                        <button key={index} className='btn-edit' onClick={() => goToTeacherAddBank(item, selectedAccount)}>Edit</button>
                    ))}

                </div>
            </Modal> */}


        </div>
    )
}

export default TeacherWithdrawl