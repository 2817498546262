import React from 'react'
import FreelanceDashboardComponent from './components/freelanceDashboard.component'
import FreelancerJobsComponent from './components/freelancerJobs.component'
import FreelanceSubjectComponent from './components/freelanceSubject.component'
import FreelanceWalletComponent from './components/freelanceWallet.component'
import FreelanceQuotedJobsComponent from './components/freelanceQuotedJobs.component'
import FreelanceChatComponent from './components/freelanceChat.component'
import FreelanceNotificationComponent from './components/freelanceNotification.component'

function FreelanceSidebar({ ...props }) {


    return (
        <div>
            <FreelanceDashboardComponent {...props} />
            <FreelancerJobsComponent {...props} />
            <FreelanceSubjectComponent  {...props} />
            <FreelanceWalletComponent  {...props} />
            <FreelanceQuotedJobsComponent  {...props} />
            <FreelanceChatComponent  {...props} />
            <FreelanceNotificationComponent  {...props} />

        </div>
    )
}

export default FreelanceSidebar