import React, { useState } from "react"
import dashboardWhiteIcon from "../../icons/dashboardWhiteIcon.png"
import dashboardBlueIcon from "../../icons/dashboardBlueIcon.png"
const CourseUploadSidebarMain = () => {
  const [hoverEffect, setHoverEffect] = useState(false)

  return (
    <div
      className='side'

    >
      <img
        src={hoverEffect ? dashboardBlueIcon : dashboardWhiteIcon}
        alt='Dashboard'
      />
      <p className='side_heading'>CourseUploadDashboard</p>
    </div>
  )
}

export default CourseUploadSidebarMain
